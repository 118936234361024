import { useParams } from 'react-router-dom';
import { getUserInfo } from 'services';
import { useCustomQuery } from 'reactQuery';

const useUserProfile = () => {
  const { username } = useParams();

  const query = useCustomQuery({
    queryKey: [`user-profile-${username}`],
    queryFn: () => getUserInfo(username),
    options: { enabled: !!username },
  });

  return { ...query };
};

export default useUserProfile;
