import { Typography, Box } from '@mui/material';
import PropTypes from 'prop-types';

const DataPlaceholder = (props) => {
  const {
    icon = '',
    header = '',
    description = '',
    mt = 25,
  } = props;

  return (
    <Box sx={{ mt }}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
      }}
      >
        {icon}
        <Typography
          variant="bodyMediumMedium"
          sx={{ mt: 7 }}
        >
          {header}
        </Typography>
        {description
        && (
        <Typography
          variant="bodySmallRegular"
          sx={{ mt: 3 }}
        >
          {description}
        </Typography>
        )}
      </Box>
    </Box>
  );
};

DataPlaceholder.propTypes = {
  icon: PropTypes.node,
  header: PropTypes.string,
  description: PropTypes.string,
};

DataPlaceholder.defaultProps = {
  icon: null,
  header: '',
  description: '',
};

export default DataPlaceholder;
