import {
  Box, Typography, Button, Link,
} from '@mui/material';
import PERMISSIONS from 'assets/constants/permissions';
import { PATHS } from 'routes';
import { useLocale, usePermission } from 'util/hooks';

const UsersHeader = (props) => {
  const { header = '', showInviteButton = true } = props;
  const { t } = useLocale();

  const canInvite = usePermission(PERMISSIONS.userInvite);

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: {
        xs: 'column',
        md: 'row',
      },
      mb: 12,
    }}
    >
      <Typography variant="h5">
        {header}
      </Typography>
      {canInvite && showInviteButton && (
        <Box sx={{ mt: { xs: 3, md: 0 } }}>
          <Button
            component={Link}
            variant="contained"
            size="regular"
            to={`/${PATHS.invite}`}
          >
            {t('invitation.inviteMembers')}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default UsersHeader;
