import { useTheme } from '@mui/styles';

const CoursesIconOutlined = (props) => {
  const { palette } = useTheme();

  const {
    fill = 'none',
    stroke = palette.text.secondary,
    rectFill = palette.common.white,
  } = props;

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_2540_104012)">
        <path d="M10.8333 19.0886C12.9273 17.3158 15.4912 16.1896 18.2133 15.847C18.5341 15.8047 18.8286 15.6472 19.0419 15.4039C19.2551 15.1606 19.3726 14.848 19.3725 14.5245V1.97113C19.3728 1.77881 19.3318 1.58868 19.2523 1.41356C19.1728 1.23845 19.0567 1.08243 18.9117 0.956041C18.7668 0.829654 18.5964 0.735841 18.4121 0.680939C18.2278 0.626037 18.0338 0.611323 17.8433 0.637792C15.2573 1.03828 12.8317 2.14408 10.8333 3.83363C10.5946 4.01797 10.3016 4.11796 10 4.11796C9.69842 4.11796 9.40535 4.01797 9.16667 3.83363C7.16739 2.14427 4.74093 1.03902 2.15417 0.639459C1.96382 0.61301 1.77002 0.627682 1.58583 0.682487C1.40164 0.737292 1.23134 0.830955 1.08641 0.957161C0.941492 1.08337 0.825316 1.23918 0.74572 1.41409C0.666124 1.58901 0.624958 1.77895 0.625 1.97113V14.5245C0.624857 14.848 0.742372 15.1606 0.955642 15.4039C1.16891 15.6472 1.46338 15.8047 1.78417 15.847C4.50718 16.1891 7.07205 17.3154 9.16667 19.0886C9.40517 19.2734 9.69831 19.3736 10 19.3736C10.3017 19.3736 10.5948 19.2734 10.8333 19.0886V19.0886Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M10 4.11523V19.3744" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_2540_104012">
          <rect width="20" height="20" fill={rectFill} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CoursesIconOutlined;
