import { useParams } from 'react-router-dom';
import { getFlagyardUserInfo } from 'services';
import { useCustomQuery } from 'reactQuery';

const useUserFlagyardInfo = (queryProps = {}) => {
  const { username } = useParams();

  const query = useCustomQuery({
    queryKey: [`flagyard-user-info-${username}`],
    queryFn: () => getFlagyardUserInfo(username),
    ...queryProps,
  });

  return { ...query };
};

export default useUserFlagyardInfo;
