import { memo } from 'react';
import { useController } from 'react-hook-form';
import { TextField } from 'components/molecules';
import PropTypes from 'prop-types';
import { useLocale } from 'util/hooks';

const FormTextField = (props) => {
  const {
    showCharCount,
    ...rest
  } = props;

  const { field, fieldState } = useController(props);
  const { error } = fieldState;
  const { inputProps } = rest;

  const { value } = field;
  const hasErrored = Boolean(error);

  const { t } = useLocale();

  const helperText = () => {
    if (hasErrored) {
      return error?.message;
    }
    if (showCharCount) {
      return `${value?.length}/${inputProps?.maxLength} ${t('common.characters', { count: value?.length || 0 })}`;
    }
    if (rest.helperText) {
      return rest.helperText;
    }
    return '';
  };

  return (
    <TextField
      {...field}
      {...rest}
      error={hasErrored}
      helperText={helperText()}
    />
  );
};

FormTextField.propTypes = {
  showCharCount: PropTypes.bool,
};

FormTextField.defaultProps = {
  showCharCount: false,
};

export default memo(FormTextField);
