import { useTheme } from '@mui/styles';

const TopicIcon = (props) => {
  const { palette } = useTheme();

  const {
    fill = 'none',
    stroke = palette.common.couchGrey,
  } = props;

  return (
    <svg width="23" height="24" viewBox="0 0 23 24" fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M19 17H14L6.5 22V17H4C3.33696 17 2.70107 16.7366 2.23223 16.2678C1.76339 15.7989 1.5 15.163 1.5 14.5V4.5C1.5 3.83696 1.76339 3.20107 2.23223 2.73223C2.70107 2.26339 3.33696 2 4 2H19C19.663 2 20.2989 2.26339 20.7678 2.73223C21.2366 3.20107 21.5 3.83696 21.5 4.5V14.5C21.5 15.163 21.2366 15.7989 20.7678 16.2678C20.2989 16.7366 19.663 17 19 17Z" stroke={stroke} strokeWidth="2.46875" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.4375 6.9375H16.3125" stroke={stroke} strokeWidth="2.46875" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.4375 11.875H16.3125" stroke={stroke} strokeWidth="2.46875" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default TopicIcon;
