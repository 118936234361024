import { useTheme } from '@mui/styles';

const JobOpportunities = (props) => {
  const { palette } = useTheme();

  const {
    stroke1 = palette.common.lightCyan,
    stroke2 = palette.common.emeraldGreen,
  } = props;

  return (
    <svg width="51" height="57" viewBox="0 0 51 57" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M20.2556 12.9057H30.8216C32.2227 12.9057 33.5664 13.4623 34.5572 14.453C35.5479 15.4438 36.1045 16.7875 36.1045 18.1887V23.4716H14.9727V18.1887C14.9727 16.7875 15.5293 15.4438 16.52 14.453C17.5107 13.4623 18.8545 12.9057 20.2556 12.9057Z" stroke={stroke1} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M36.1045 23.47V55.1678" stroke={stroke1} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14.9727 23.47V55.1678" stroke={stroke1} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M44.0293 23.47H7.04859C4.13089 23.47 1.76562 25.8353 1.76562 28.753V49.8849C1.76562 52.8026 4.13089 55.1678 7.04859 55.1678H44.0293C46.947 55.1678 49.3123 52.8026 49.3123 49.8849V28.753C49.3123 25.8353 46.947 23.47 44.0293 23.47Z" stroke={stroke1} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <line x1="0.5" y1="-0.5" x2="5.95276" y2="-0.5" transform="matrix(0.579829 -0.814738 0.848187 0.529697 37.4043 6.25714)" stroke={stroke2} strokeLinecap="round" />
      <line x1="0.5" y1="-0.5" x2="7.19114" y2="-0.5" transform="matrix(0.684571 -0.728946 0.771716 0.635967 41.4932 10.1111)" stroke={stroke2} strokeLinecap="round" />
      <line x1="0.5" y1="-0.5" x2="6.83095" y2="-0.5" transform="matrix(0.765555 -0.643371 0.691668 0.722215 44.8867 14.4789)" stroke={stroke2} strokeLinecap="round" />
      <line x1="0.5" y1="-0.5" x2="5.95276" y2="-0.5" transform="matrix(-0.579829 -0.814738 -0.848187 0.529697 13.5957 6.25714)" stroke={stroke2} strokeLinecap="round" />
      <line x1="0.5" y1="-0.5" x2="7.19114" y2="-0.5" transform="matrix(-0.684571 -0.728946 -0.771716 0.635967 9.50684 10.1111)" stroke={stroke2} strokeLinecap="round" />
      <line x1="0.5" y1="-0.5" x2="6.83095" y2="-0.5" transform="matrix(-0.765555 -0.643371 -0.691668 0.722215 6.11328 14.4789)" stroke={stroke2} strokeLinecap="round" />
    </svg>

  );
};

export default JobOpportunities;
