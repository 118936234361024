import END_POINTS from '../endPoints';
import METHODS from '../methods';
import api from '../api';

const createCourseChapter = (data, courseId) => api(
  END_POINTS.courseChapters(courseId),
  {
    method: METHODS.POST,
    data,
  },
)
  .then((response) => Promise.resolve(response))
  .catch((error) => Promise.reject(error));

export default createCourseChapter;
