const END_POINTS = {
  //  Authentication
  login: '/auth',
  logout: '/auth/logout',
  nafathToken: '/nafath/verify',
  resendCode: '/auth/resend-code',
  updatePassword: '/auth/password',
  verifyEmail: '/auth/verify-email',
  emailVerification: '/auth/send-code',
  codeVerification: '/auth/verify-code',
  resetPassword: '/auth/reset-password',
  forgotPassword: '/auth/forgot-password',
  validateVerificationToken: '/auth/validate-token',
  verifyEmailVerificationCode: '/auth/verify-v1-email',
  sendEmailVerificationCode: '/auth/send-email-verification',

  //  Invitations
  invitations: '/invitations',
  getInvites: '/invitations',
  acceptInvitation: '/invitations/accept',
  resendInvitation: (id) => `/invitations/resend/${id}`,
  cancelInvitation: (id) => `/invitations/cancel/${id}`,
  validateInvitationToken: '/invitations/validate-token',

  //  Courses
  courses: '/courses',
  course: (id) => `/courses/${id}`,
  publicCourses: '/courses/public',
  myCourses: '/courses/my-courses',
  cancelCourse: (id) => `/courses/${id}/cancel`,
  publicCourse: (id) => `/courses/public/${id}`,
  rejectCourse: (id) => `/courses/${id}/reject`,
  revokeStudent: (id) => `/courses/${id}/revoke`,
  approveCourse: (id) => `/courses/${id}/publish`,
  publishCourse: (id) => `/courses/${id}/publish`,
  enrollToCourse: (id) => `/courses/${id}/enroll`,
  registerToCourse: (id) => `/courses/${id}/register`,
  withdrawCourse: (id) => `/courses/${id}/withdraw`,
  completeCourse: (id) => `/courses/${id}/complete`,
  courseRegistrants: (id) => `/courses/${id}/registrants`,
  courseEnrollments: (id) => `/courses/${id}/enrollments`,
  setStudentAsEnrolled: (id) => `/courses/${id}/re-enroll`,
  courseRecordings: (courseId) => `/courses/${courseId}/recordings`,
  exportAttendanceList: (id) => `/courses/${id}/enrollments/export`,
  requestCourseApproval: (id) => `/courses/${id}/request-approval`,
  deleteAttachment: (courseId, id) => `/courses/${courseId}/attachment/${id}`,
  createSession: (id) => `/courses/${id}/sessions`,
  updateSession: (courseId, id) => `/courses/${courseId}/sessions/${id}`,
  getSession: (courseId, sessionId) => `/courses/${courseId}/sessions/${sessionId}`,
  startSession: (courseId, sessionId) => `/courses/${courseId}/sessions/${sessionId}/start`,
  sessionRecording: (courseId, sessionId) => `/courses/${courseId}/sessions/${sessionId}/recordings`,
  courseChapter: (courseId, chapterId) => `/courses/${courseId}/chapters/${chapterId}`,
  courseChapters: (courseId) => `/courses/${courseId}/chapters`,
  courseChapterMaterial: (courseId, chapterId, materialId) => `/courses/${courseId}/chapters/${chapterId}/materials/${materialId}`,
  createCourseMaterial: (courseId, chapterId) => `/courses/${courseId}/chapters/${chapterId}/materials`,
  courseMaterial: (courseId, chapterId, materialId) => `/courses/${courseId}/chapters/${chapterId}/materials/${materialId}`,
  acceptCourseRegistrant: (courseId) => `/courses/${courseId}/accept`,
  rejectCourseRegistrant: (courseId) => `/courses/${courseId}/reject-all`,
  courseTracks: '/courses/list/tracks',
  courseLevels: '/courses/list/levels',
  courseProviders: '/courses/list/providers',
  courseTopics: '/courses/list/topics',

  //  User management
  getUsers: '/users/',
  students: '/students',
  getMyInfo: '/users/me',
  deleteAvatar: '/users/avatar',
  exportStudents: '/users/export',
  userInfo: (username) => `/users/${username}`,
  contentCreators: (id) => `/providers/${id}/users`,
  updateUserProfile: (username) => `/users/update/${username}`,
  setAsGraduate: (username) => `/students/graduate/${username}`,
  suspendUserByAdmin: (username) => `/users/suspend/${username}`,
  activateUserByAdmin: (username) => `/users/activate/${username}`,
  unverifyMobile: (username) => `/users/verify-mobile/${username}`,
  updateStudentProfile: (username) => `/students/update/${username}`,
  unverifyNafath: (username) => `/students/verify-nafath/${username}`,
  setAsLeader: (clubId, useId) => `/clubs/${clubId}/set-leader/${useId}`,
  suspendUserByClubManager: (username) => `/students/suspend/${username}`,
  activateUserByClubManager: (username) => `/students/activate/${username}`,
  transferUniversityForStudent: (username) => `/students/club-transfer/${username}`,
  transferUniversityForClubManager: (username) => `/users/club-transfer/${username}`,

  //  Posts
  posts: '/posts',
  clubPosts: '/posts/my-club',
  publicPosts: '/posts/public',
  post: (id) => `/posts/${id}`,
  privatePosts: '/posts/non-public',
  bookMarkedPosts: '/posts/bookmarks',
  rejectPost: (id) => `/posts/${id}/reject`,
  revisePost: (id) => `/posts/${id}/revised`,
  publishPost: (id) => `/posts/${id}/publish`,
  likeAPost: (id) => `/posts/like-toggle/${id}`,
  unpublishPost: (id) => `/posts/${id}/unpublish`,
  getPostShareCount: (id) => `/posts/${id}/share`,
  bookmarkPost: (id) => `/posts/bookmark-toggle/${id}`,
  requestPostApproval: (id) => `/posts/${id}/request-approval`,
  toggleCommentStatus: (id) => `/posts-review-comment/${id}`,
  mostViewedPosts: (username) => `/profile/most-viewed-posts/${username}`,

  //  Profile
  updateOwnProfile: '/users/update',
  profileCTFs: (username) => `/profile/ctfs/${username}`,
  profileRank: (username) => `/profile/rank/${username}`,
  clubProfileRank: (id) => `/clubs-profile/points/${id}`,
  clubProfileInfo: (id) => `/clubs-profile/general-info/${id}`,
  profilePoints: (username) => `/profile/points/${username}`,
  clubProfilePostStats: (id) => `/clubs-profile/posts-numbers/${id}`,
  clubProfileMostViewedPosts: (id) => `/clubs-profile/recent-posts/${id}`,
  profileCourses: (username) => `/courses/registered/${username}`,
  profilePostStats: (username) => `/profile/posts-numbers/${username}`,
  profilePersonalInfo: (username) => `/profile/personal-info/${username}`,
  connectFlagyardAccount: '/flagyard/request-temp-token',
  validateConnectToken: '/flagyard/validate-connect-token',
  flagyardUserInfo: (username) => `/profile/flagyard/${username}`,

  //  Platform Settings
  tags: '/tags',
  topics: '/topics',
  interests: '/interests',
  providers: '/providers',
  tag: (id) => `/tags/${id}`,
  generalSettings: '/settings',
  topic: (id) => `/topics/${id}`,
  provider: (id) => `/providers/${id}`,
  interest: (id) => `/interests/${id}`,
  toggleTagVisibility: (id) => `/tags/toggle-visibility/${id}`,
  toggleSettingStatus: (key) => `/settings/setting-toggle/${key}`,
  toggleTopicVisibility: (id) => `/topics/toggle-visibility/${id}`,
  toggleProviderVisibility: (id) => `/providers/toggle-visibility/${id}`,
  toggleInterestVisibility: (id) => `/interests/toggle-visibility/${id}`,

  //  Certificates
  certificatesLevels: '/levels',
  certificates: '/certificates',
  userCertificates: '/user-certificates',
  certificateNames: '/certificate-names',
  certificate: (id) => `/certificates/${id}`,
  verifyUserCertificate: (id) => `/user-certificates/${id}/verify`,
  rejectUserCertificate: (id) => `/user-certificates/${id}/reject`,
  cancelUserCertificate: (id) => `/user-certificates/${id}/cancel`,
  profileCertificates: (username) => `/user-certificates/user/${username}`,
  userCertificate: (certificateId) => `/user-certificates/${certificateId}`,
  toggleCertificateVisibility: (id) => `/certificates/toggle-visibility/${id}`,

  // Clubs
  getClubs: '/clubs',
  topClubs: '/leaderboard/clubs',
  club: (id) => `/clubs/${id}`,
  toggleClubStatus: (id) => `/clubs/${id}/toggle-status`,
  clubMembers: (id) => `/clubs-profile/members/${id}`,
  clubManager: (id) => `/clubs-profile/manager/${id}`,
  clubLeader: (id) => `/clubs-profile/leader/${id}`,
  clubs: '/clubs',
  cities: '/cities',

  // Others
  uploads: '/uploads',
  discord: '/discord/oauth',
  getCountries: '/countries',
  landingStatistics: '/landing/statistics',
  topStudents: '/leaderboard/students',
};

export default END_POINTS;
