import { useTheme } from '@mui/styles';

const ShareIcon = (props) => {
  const { palette } = useTheme();

  const {
    fill = 'none',
    stroke = palette.common.berylliumGrey,
  } = props;

  return (
    <svg width="19" height="20" viewBox="0 0 19 20" fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M14.4937 6.98584H15.9354C16.7316 6.98584 17.377 7.5217 17.377 8.18272V17.7577C17.377 18.4187 16.7316 18.9546 15.9354 18.9546H2.96057C2.16437 18.9546 1.51892 18.4187 1.51892 17.7577V8.18272C1.51892 7.5217 2.16437 6.98584 2.96057 6.98584H4.40221" stroke={stroke} strokeWidth="1.42517" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.448 1.00146V9.37959" stroke={stroke} strokeWidth="1.42517" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.84399 3.99365L9.44811 1.00146L13.0522 3.99365" stroke={stroke} strokeWidth="1.42517" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  );
};
export default ShareIcon;
