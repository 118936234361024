import { Box, Stack, Typography } from '@mui/material';
import { statisticsBoxAr, statisticsBoxEn } from 'assets/images';
import { StatisticsCounter } from 'components/organisms';
import { useStatistics } from 'reactQuery/queries';
import { useLocale } from 'util/hooks';

const Statistics = () => {
  const { t, isAr } = useLocale();

  const {
    data: statistics = [],
  } = useStatistics();

  const headerSection = (
    <Stack direction="column" rowGap={5}>
      <Typography variant="h5">
        {t('landing.statisticsSection.header')}
      </Typography>
      <Typography variant="bodyMediumRegular">
        {t('landing.statisticsSection.description')}
      </Typography>
    </Stack>
  );

  const numbersSection = (
    <Box
      display="flex"
      columnGap={{ xs: 10, lg: 20 }}
      rowGap={15}
      flexWrap={{ xs: 'wrap', md: 'nowrap' }}
      justifyContent="center"
    >
      <Box>
        <img
          src={isAr ? statisticsBoxAr : statisticsBoxEn}
          alt="statisticsBox"
          height="212"
          width="211"
        />
      </Box>
      <Box
        sx={{
          display: 'grid',
          rowGap: 23,
          width: 1,
          justifyContent: 'space-between',
          gridTemplateColumns: 'repeat(3, auto)',
          px: { sm: 5, lg: 0 },
        }}
      >
        {statistics?.map((statisticsData) => (
          <StatisticsCounter
            key={statisticsData.label}
            {...statisticsData}
          />
        ))}
      </Box>
    </Box>
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      rowGap={20}
    >
      {headerSection}
      {numbersSection}
    </Box>
  );
};

export default Statistics;
