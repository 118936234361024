import { useTheme } from '@mui/styles';

const DateGreenIcon = (props) => {
  const { palette } = useTheme();

  const {
    fill = 'none',
    innerFill = palette.common.turquoiseSeaweedGreen,
    rectFill = palette.common.turquoiseSeaweedGreen,
  } = props;

  return (
    <svg width="34" height="35" viewBox="0 0 34 35" fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect y="0.899902" width="33.686" height="33.7477" rx="16.843" fill={rectFill} fillOpacity="0.2" />
      <path fillRule="evenodd" clipRule="evenodd" d="M9.3457 13.7739C9.3457 12.9455 10.0173 12.2739 10.8457 12.2739H22.8457C23.6741 12.2739 24.3457 12.9455 24.3457 13.7739V23.7739C24.3457 24.6024 23.6741 25.2739 22.8457 25.2739H10.8457C10.0173 25.2739 9.3457 24.6024 9.3457 23.7739V13.7739Z" stroke={innerFill} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.3457 16.2739H24.3457" stroke={innerFill} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M13.3457 13.7739V10.2739" stroke={innerFill} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M20.3457 13.7739V10.2739" stroke={innerFill} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
export default DateGreenIcon;
