import END_POINTS from '../endPoints';
import api from '../api';

const getClubMembers = (clubId, options) => api(
  END_POINTS.clubMembers(clubId),
  options,
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default getClubMembers;
