import END_POINTS from '../endPoints';
import downloadApi from '../downloadApi';

const exportStudents = (options) => downloadApi({
  path: END_POINTS.exportStudents,
  fileName: 'CyberHub_Student_List.csv',
  ...options,
})
  .then((response) => Promise.resolve(response))
  .catch((error) => Promise.reject(error));

export default exportStudents;
