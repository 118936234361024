import { useTheme } from '@mui/styles';

const WhiteEnglishLogo = (props) => {
  const { palette } = useTheme();

  const {
    fill = 'none',
    innerFill = palette.common.white,
  } = props;

  return (
    <svg width="230" height="55" viewBox="0 0 230 55" fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M2.33994 12.4361C1.05115 12.4361 0 11.3941 0 10.0962V3.09466C0 1.80586 1.04201 0.754715 2.33994 0.754715C3.62874 0.754715 4.67989 1.79672 4.67989 3.09466V10.0962C4.67989 11.3941 3.62874 12.4361 2.33994 12.4361Z" fill={innerFill} />
      <path d="M9.35069 12.4361C8.06189 12.4361 7.01074 11.3941 7.01074 10.0962V3.09466C7.01074 1.80586 8.05275 0.754715 9.35069 0.754715C10.6395 0.754715 11.6906 1.79672 11.6906 3.09466V10.0962C11.6906 11.3941 10.6395 12.4361 9.35069 12.4361Z" fill={innerFill} />
      <path d="M16.3614 12.4361C15.0726 12.4361 14.0215 11.3941 14.0215 10.0962V3.09466C14.0215 1.80586 15.0635 0.754715 16.3614 0.754715C17.6502 0.754715 18.7014 1.79672 18.7014 3.09466V10.0962C18.6922 11.3941 17.6502 12.4361 16.3614 12.4361Z" fill={innerFill} />
      <path d="M44.4864 12.4361C43.1976 12.4361 42.1465 11.3941 42.1465 10.0962V3.09466C42.1465 1.80586 43.1885 0.754715 44.4864 0.754715C45.7752 0.754715 46.8264 1.79672 46.8264 3.09466V10.0962C46.8172 11.3941 45.7752 12.4361 44.4864 12.4361Z" fill={innerFill} />
      <path d="M23.3722 47.389C22.0834 47.389 21.0322 46.347 21.0322 45.0491V38.0476C21.0322 36.7588 22.0742 35.7076 23.3722 35.7076C24.661 35.7076 25.7121 36.7496 25.7121 38.0476V45.0491C25.703 46.347 24.661 47.389 23.3722 47.389Z" fill={innerFill} />
      <path d="M23.3722 26.4118C22.0834 26.4118 21.0322 25.3698 21.0322 24.0719V10.1053C21.0322 8.81655 22.0742 7.7654 23.3722 7.7654C24.661 7.7654 25.7121 8.80741 25.7121 10.1053V24.0719C25.703 25.3698 24.661 26.4118 23.3722 26.4118Z" fill={innerFill} />
      <path d="M16.3614 54.4455C15.0726 54.4455 14.0215 53.4034 14.0215 52.1055V38.139C14.0215 36.8502 15.0635 35.799 16.3614 35.799C17.6502 35.799 18.7014 36.841 18.7014 38.139V52.1055C18.6922 53.4034 17.6502 54.4455 16.3614 54.4455Z" fill={innerFill} />
      <path d="M28.061 38.1298C28.061 36.841 29.103 35.7899 30.401 35.7899H44.3675C45.6563 35.7899 46.7075 36.8319 46.7075 38.1298C46.7075 39.4186 45.6655 40.4698 44.3675 40.4698H30.401C29.1122 40.4606 28.061 39.4186 28.061 38.1298Z" fill={innerFill} />
      <path d="M37.3844 26.4667C36.0956 26.4667 35.0444 25.4247 35.0444 24.1267V17.1252C35.0444 15.8364 36.0864 14.7852 37.3844 14.7852C38.6732 14.7852 39.7243 15.8272 39.7243 17.1252V24.1267C39.7243 25.4155 38.6732 26.4667 37.3844 26.4667Z" fill={innerFill} />
      <path d="M39.7151 31.046C39.7151 32.3348 38.6731 33.386 37.3752 33.386H30.3736C29.0848 33.386 28.0337 32.344 28.0337 31.046C28.0337 29.7572 29.0757 28.7061 30.3736 28.7061H37.3752C38.6731 28.7152 39.7151 29.7572 39.7151 31.046Z" fill={innerFill} />
      <path d="M39.7151 45.1497C39.7151 46.4384 38.6731 47.4896 37.3752 47.4896H30.3736C29.0848 47.4896 28.0337 46.4476 28.0337 45.1497C28.0337 43.8609 29.0757 42.8097 30.3736 42.8097H37.3752C38.6731 42.8188 39.7151 43.8609 39.7151 45.1497Z" fill={innerFill} />
      <path d="M2.33994 33.4682C1.05115 33.4682 0 32.4262 0 31.1283V24.1267C0 22.8379 1.04201 21.7868 2.33994 21.7868C3.62874 21.7868 4.67989 22.8288 4.67989 24.1267V31.1283C4.67989 32.4262 3.62874 33.4682 2.33994 33.4682Z" fill={innerFill} />
      <path d="M9.35069 33.4682C8.06189 33.4682 7.01074 32.4262 7.01074 31.1283V24.1267C7.01074 22.8379 8.05275 21.7868 9.35069 21.7868C10.6395 21.7868 11.6906 22.8288 11.6906 24.1267V31.1283C11.6906 32.4262 10.6395 33.4682 9.35069 33.4682Z" fill={innerFill} />
      <path d="M9.35069 47.4896C8.06189 47.4896 7.01074 46.4476 7.01074 45.1497V38.1481C7.01074 36.8593 8.05275 35.8082 9.35069 35.8082C10.6395 35.8082 11.6906 36.8502 11.6906 38.1481V45.1497C11.6906 46.4385 10.6395 47.4896 9.35069 47.4896Z" fill={innerFill} />
      <path d="M2.33994 19.456C3.63226 19.456 4.67989 18.4084 4.67989 17.116C4.67989 15.8237 3.63226 14.7761 2.33994 14.7761C1.04763 14.7761 0 15.8237 0 17.116C0 18.4084 1.04763 19.456 2.33994 19.456Z" fill={innerFill} />
      <path d="M9.35069 19.456C10.643 19.456 11.6906 18.4084 11.6906 17.116C11.6906 15.8237 10.643 14.7761 9.35069 14.7761C8.05837 14.7761 7.01074 15.8237 7.01074 17.116C7.01074 18.4084 8.05837 19.456 9.35069 19.456Z" fill={innerFill} />
      <path d="M16.3614 19.456C17.6537 19.456 18.7014 18.4084 18.7014 17.116C18.7014 15.8237 17.6537 14.7761 16.3614 14.7761C15.0691 14.7761 14.0215 15.8237 14.0215 17.116C14.0215 18.4084 15.0691 19.456 16.3614 19.456Z" fill={innerFill} />
      <path d="M23.3722 33.4774C24.6645 33.4774 25.7121 32.4297 25.7121 31.1374C25.7121 29.8451 24.6645 28.7975 23.3722 28.7975C22.0799 28.7975 21.0322 29.8451 21.0322 31.1374C21.0322 32.4297 22.0799 33.4774 23.3722 33.4774Z" fill={innerFill} />
      <path d="M23.3722 54.5094C24.6645 54.5094 25.7121 53.4618 25.7121 52.1695C25.7121 50.8772 24.6645 49.8295 23.3722 49.8295C22.0799 49.8295 21.0322 50.8772 21.0322 52.1695C21.0322 53.4618 22.0799 54.5094 23.3722 54.5094Z" fill={innerFill} />
      <path d="M23.3722 5.44374C24.6645 5.44374 25.7121 4.39611 25.7121 3.1038C25.7121 1.81148 24.6645 0.763855 23.3722 0.763855C22.0799 0.763855 21.0322 1.81148 21.0322 3.1038C21.0322 4.39611 22.0799 5.44374 23.3722 5.44374Z" fill={innerFill} />
      <path d="M30.3736 26.4667C31.6659 26.4667 32.7136 25.419 32.7136 24.1267C32.7136 22.8344 31.6659 21.7868 30.3736 21.7868C29.0813 21.7868 28.0337 22.8344 28.0337 24.1267C28.0337 25.419 29.0813 26.4667 30.3736 26.4667Z" fill={innerFill} />
      <path d="M30.3736 19.456C31.6659 19.456 32.7136 18.4084 32.7136 17.116C32.7136 15.8237 31.6659 14.7761 30.3736 14.7761C29.0813 14.7761 28.0337 15.8237 28.0337 17.116C28.0337 18.4084 29.0813 19.456 30.3736 19.456Z" fill={innerFill} />
      <path d="M30.3736 12.4453C31.6659 12.4453 32.7136 11.3977 32.7136 10.1053C32.7136 8.81303 31.6659 7.7654 30.3736 7.7654C29.0813 7.7654 28.0337 8.81303 28.0337 10.1053C28.0337 11.3977 29.0813 12.4453 30.3736 12.4453Z" fill={innerFill} />
      <path d="M44.3951 33.4682C45.6874 33.4682 46.7351 32.4206 46.7351 31.1283C46.7351 29.836 45.6874 28.7883 44.3951 28.7883C43.1028 28.7883 42.0552 29.836 42.0552 31.1283C42.0552 32.4206 43.1028 33.4682 44.3951 33.4682Z" fill={innerFill} />
      <path d="M44.3951 26.4575C45.6874 26.4575 46.7351 25.4099 46.7351 24.1176C46.7351 22.8253 45.6874 21.7776 44.3951 21.7776C43.1028 21.7776 42.0552 22.8253 42.0552 24.1176C42.0552 25.4099 43.1028 26.4575 44.3951 26.4575Z" fill={innerFill} />
      <path d="M44.3951 19.456C45.6874 19.456 46.7351 18.4084 46.7351 17.116C46.7351 15.8237 45.6874 14.7761 44.3951 14.7761C43.1028 14.7761 42.0552 15.8237 42.0552 17.116C42.0552 18.4084 43.1028 19.456 44.3951 19.456Z" fill={innerFill} />
      <path d="M30.3736 5.44374C31.6659 5.44374 32.7136 4.39611 32.7136 3.1038C32.7136 1.81148 31.6659 0.763855 30.3736 0.763855C29.0813 0.763855 28.0337 1.81148 28.0337 3.1038C28.0337 4.39611 29.0813 5.44374 30.3736 5.44374Z" fill={innerFill} />
      <path d="M37.3844 12.4453C38.6767 12.4453 39.7243 11.3977 39.7243 10.1053C39.7243 8.81303 38.6767 7.7654 37.3844 7.7654C36.0921 7.7654 35.0444 8.81303 35.0444 10.1053C35.0444 11.3977 36.0921 12.4453 37.3844 12.4453Z" fill={innerFill} />
      <path d="M37.3844 5.44374C38.6767 5.44374 39.7243 4.39611 39.7243 3.1038C39.7243 1.81148 38.6767 0.763855 37.3844 0.763855C36.0921 0.763855 35.0444 1.81148 35.0444 3.1038C35.0444 4.39611 36.0921 5.44374 37.3844 5.44374Z" fill={innerFill} />
      <path d="M16.3614 26.4667C17.6537 26.4667 18.7014 25.419 18.7014 24.1267C18.7014 22.8344 17.6537 21.7868 16.3614 21.7868C15.0691 21.7868 14.0215 22.8344 14.0215 24.1267C14.0215 25.419 15.0691 26.4667 16.3614 26.4667Z" fill={innerFill} />
      <path d="M16.3614 33.4774C17.6537 33.4774 18.7014 32.4297 18.7014 31.1374C18.7014 29.8451 17.6537 28.7975 16.3614 28.7975C15.0691 28.7975 14.0215 29.8451 14.0215 31.1374C14.0215 32.4297 15.0691 33.4774 16.3614 33.4774Z" fill={innerFill} />
      <path d="M2.33994 40.4789C3.63226 40.4789 4.67989 39.4313 4.67989 38.139C4.67989 36.8467 3.63226 35.799 2.33994 35.799C1.04763 35.799 0 36.8467 0 38.139C0 39.4313 1.04763 40.4789 2.33994 40.4789Z" fill={innerFill} />
      <path d="M53.7363 26.6586V26.5764C53.7363 18.807 59.5496 12.4453 67.8217 12.4453C71.7521 12.4453 74.4668 13.5147 76.7153 15.1783C77.739 15.9369 77.9036 17.4085 77.0626 18.3683L76.825 18.6425C76.0846 19.4925 74.805 19.6571 73.9001 18.9807C72.136 17.6736 70.1891 16.8601 67.776 16.8601C62.5842 16.8601 58.7635 21.1561 58.7635 26.5032V26.5855C58.7635 31.9326 62.5477 36.2652 67.776 36.2652C70.4176 36.2652 72.3005 35.4425 74.1286 34.0349C75.0152 33.3494 76.2674 33.4042 77.0535 34.1995L77.1266 34.2817C78.0407 35.2049 77.9401 36.6948 76.9438 37.5266C74.5216 39.5192 71.7064 40.7166 67.6206 40.7166C59.6685 40.7074 53.7363 34.5011 53.7363 26.6586Z" fill={innerFill} />
      <path d="M96.1751 19.6296C97.7655 19.6296 98.8624 21.2292 98.2865 22.7191L91.285 40.8262C89.6488 45.04 87.8116 46.603 84.4937 46.603C83.4242 46.603 82.501 46.4659 81.6418 46.2099C80.5176 45.8717 79.9509 44.6195 80.4353 43.5592L80.4536 43.5227C80.8558 42.6452 81.8247 42.2339 82.757 42.4715C83.1592 42.5721 83.5705 42.6269 83.9909 42.6269C85.3163 42.6269 86.1389 42.0419 86.9159 40.287L79.6675 22.8471C79.0368 21.3206 80.152 19.6388 81.8064 19.6388C82.7661 19.6388 83.6345 20.2329 83.9727 21.1378L89.2192 34.9398L94.0362 21.1561C94.3562 20.2421 95.2062 19.6296 96.1751 19.6296Z" fill={innerFill} />
      <path d="M105.627 37.033V37.8739C105.627 39.181 104.566 40.2321 103.268 40.2321C101.961 40.2321 100.91 39.1718 100.91 37.8739V14.1088C100.91 12.8018 101.97 11.7506 103.268 11.7506C104.575 11.7506 105.627 12.8109 105.627 14.1088V23.0664C107.153 20.9184 109.292 19.2 112.656 19.2C117.491 19.2 122.216 23.0207 122.216 29.8943V29.9766C122.216 36.8045 117.537 40.6709 112.656 40.6709C109.21 40.6709 107.071 38.9525 105.627 37.033ZM117.409 29.9766V29.8943C117.409 25.9091 114.712 23.2949 111.513 23.2949C108.314 23.2949 105.499 25.9457 105.499 29.8943V29.9766C105.499 33.9161 108.305 36.576 111.513 36.576C114.758 36.5668 117.409 34.0349 117.409 29.9766Z" fill={innerFill} />
      <path d="M123.587 30.0131V29.4739C123.587 23.9439 127.966 19.2823 133.496 19.2092C140.159 19.1178 143.532 24.3095 143.532 30.2965C143.532 30.7261 143.495 31.1557 143.449 31.6219H128.304C128.816 34.9764 131.192 36.8502 134.236 36.8502C135.982 36.8502 137.353 36.3566 138.623 35.4151C139.364 34.8667 140.378 34.885 141.064 35.4974C141.95 36.2835 141.914 37.6819 141 38.4223C139.236 39.8482 137.006 40.7074 134.163 40.7074C128.194 40.7074 123.587 36.3749 123.587 30.0131ZM138.843 28.4867C138.532 25.4429 136.731 23.0573 133.614 23.0573C130.726 23.0573 128.697 25.2784 128.267 28.4867H138.843Z" fill={innerFill} />
      <path d="M148.312 19.6296C149.619 19.6296 150.671 20.6899 150.671 21.9879V24.273C151.511 22.2621 152.809 20.6717 154.619 19.849C156.191 19.1361 157.965 20.3609 157.965 22.0793C157.965 23.231 157.151 24.1907 156.036 24.4466C152.883 25.1779 150.671 27.8103 150.671 32.3897V37.8739C150.671 39.181 149.61 40.2321 148.312 40.2321C147.005 40.2321 145.954 39.1718 145.954 37.8739V21.9879C145.954 20.6899 147.014 19.6296 148.312 19.6296Z" fill={innerFill} />
      <path d="M162.233 12.9206C163.558 12.9206 164.637 13.9992 164.637 15.3245V24.2821H177.671V15.3245C177.671 13.9992 178.75 12.9206 180.075 12.9206C181.4 12.9206 182.479 13.9992 182.479 15.3245V37.8465C182.479 39.1718 181.4 40.2504 180.075 40.2504C178.75 40.2504 177.671 39.1718 177.671 37.8465V28.7335H164.637V37.8465C164.637 39.1718 163.558 40.2504 162.233 40.2504C160.908 40.2504 159.829 39.1718 159.829 37.8465V15.3154C159.838 13.99 160.908 12.9206 162.233 12.9206Z" fill={innerFill} />
      <path d="M186.601 32.7461V21.9879C186.601 20.6808 187.661 19.6296 188.959 19.6296C190.266 19.6296 191.318 20.6899 191.318 21.9879V31.3385C191.318 34.5376 192.917 36.3383 195.723 36.3383C198.456 36.3383 200.367 34.4645 200.367 31.2654V21.9879C200.367 20.6808 201.427 19.6296 202.725 19.6296H202.761C204.068 19.6296 205.12 20.6899 205.12 21.9879V37.8739C205.12 39.181 204.059 40.2321 202.761 40.2321H202.725C201.418 40.2321 200.367 39.1718 200.367 37.8739V37.033C199.041 38.9799 197.131 40.6617 193.923 40.6617C189.288 40.6709 186.601 37.5448 186.601 32.7461Z" fill={innerFill} />
      <path d="M213.41 37.033V37.8739C213.41 39.181 212.35 40.2321 211.052 40.2321C209.745 40.2321 208.693 39.1718 208.693 37.8739V14.1088C208.693 12.8018 209.754 11.7506 211.052 11.7506C212.359 11.7506 213.41 12.8109 213.41 14.1088V23.0664C214.936 20.9184 217.075 19.2 220.439 19.2C225.274 19.2 230 23.0207 230 29.8943V29.9766C230 36.8045 225.32 40.6709 220.439 40.6709C217.002 40.6709 214.854 38.9525 213.41 37.033ZM225.201 29.9766V29.8943C225.201 25.9091 222.505 23.2949 219.305 23.2949C216.106 23.2949 213.291 25.9457 213.291 29.8943V29.9766C213.291 33.9161 216.097 36.576 219.305 36.576C222.541 36.5668 225.201 34.0349 225.201 29.9766Z" fill={innerFill} />
    </svg>

  );
};
export default WhiteEnglishLogo;
