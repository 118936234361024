const SparklingFilledIcon = (props) => (
  <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="0.5" y="0.34375" width="45" height="45" rx="7.125" fill="#AEFCCA" fillOpacity="0.25" />
    <path d="M18.5735 25.3438C18.5663 27.2286 19.4817 28.3038 21.5611 28.3438C19.6307 28.3366 18.6715 29.3766 18.5735 31.3438C18.5568 29.4878 17.729 28.3438 15.5859 28.3438C17.5036 28.3174 18.5663 27.3878 18.5735 25.3438Z" stroke="#1DBB56" strokeWidth="1.53751" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M17.4876 13.3438C17.4804 15.2286 18.3958 16.3038 20.4751 16.3438C18.5448 16.3366 17.5856 17.3766 17.4876 19.3438C17.4708 17.4878 16.6431 16.3438 14.5 16.3438C16.4176 16.3174 17.4804 15.3878 17.4876 13.3438Z" stroke="#1DBB56" strokeWidth="1.53751" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M26.7628 16.6172C26.7487 20.2368 28.5011 22.2899 32.5011 22.3805C28.7911 22.3663 26.9508 27.0201 26.7628 30.7991C26.727 27.234 25.1398 22.375 21.0234 22.3805C24.7063 22.3281 26.7487 20.5434 26.7628 16.6172Z" stroke="#1DBB56" strokeWidth="2.0966" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default SparklingFilledIcon;
