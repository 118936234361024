import { useTheme } from '@mui/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import {
  useShare,
  useLocale,
  useSnackbar,
} from 'util/hooks';
import {
  ShareIcon,
  DiscordIcon,
  XPlatformIcon,
  LocationIcon,
} from 'assets/icons';

import { capitalizeFirstLetter } from 'util/helpers';
import { discord } from 'services';
import ShareProfileMenu from '../ShareProfileMenu/ShareProfileMenu';

const SocialMediaAccounts = ({
  link,
  icon,
  clubDiscord,
}) => (
  <IconButton
    target="_blank"
    component={clubDiscord ? 'button' : 'a'}
    href={clubDiscord ? undefined : link}
    onClick={clubDiscord ? () => clubDiscord() : undefined}
    sx={{
      border: ({ palette }) => `1px solid ${palette.common.silverGrey}`,
      backgroundColor: 'common.white',
      '&:hover': {
        backgroundColor: 'common.white',
      },
    }}
  >
    {icon}
  </IconButton>
);

const ClubProfileHeader = (props) => {
  const {
    clubDetails = {},
  } = props;

  const snack = useSnackbar();
  const { palette } = useTheme();
  const { t, isAr } = useLocale();

  const {
    city = {},
    logo = null,
    twitter = '',
    discordRoleId = '',
    nameEn: clubNameEn = '',
    nameAr: clubNameAr = '',
  } = clubDetails || {};

  const {
    nameAr: cityNameAr = '',
    nameEn: cityNameEn = '',
  } = city || {};

  const clubName = isAr ? clubNameAr : clubNameEn;
  const cityName = isAr ? cityNameAr : cityNameEn;

  const {
    anchorEl,
    handleOpenMenu,
    handleCloseMenu,
  } = useShare({
    title: clubName,
    text: clubName,
  });

  const clubCity = (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <LocationIcon />
      <Box ml={2}>
        <Typography>{cityName}</Typography>
      </Box>
    </Box>
  );

  const navigateToDiscord = async () => {
    try {
      const win = window.open();
      win.location = await discord();
    } catch (error) {
      snack({
        message: error.message || t('common.somethingWrong'),
        severity: 'error',
      });
    }
    return null;
  };

  const twitterButtonIcon = (
    twitter && (
      <SocialMediaAccounts
        link={twitter}
        icon={<XPlatformIcon height="17" width="17" />}
      />
    )
  );

  const discordButtonIcon = (
    discordRoleId && (
      <SocialMediaAccounts
        icon={<DiscordIcon height="17" width="17" />}
        clubDiscord={navigateToDiscord}
      />
    )
  );

  return (
    <Stack spacing={5}>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: { xs: 'center', sm: 'start' },
      }}
      >
        <Stack
          width={1}
          direction={{ xs: 'column', sm: 'row' }}
          alignItems="center"
          justifyContent="center"
          spacing={2.5}
        >
          <Avatar
            src={logo}
            alt={capitalizeFirstLetter(clubNameEn)}
            sx={{
              width: { xs: 110, sm: 55 },
              height: { xs: 110, sm: 55 },
            }}
          />
          <Stack width={1}>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: { xs: 'center', sm: 'space-between' },
            }}
            >
              <Typography variant="bodyStandardMedium">
                {clubName}
              </Typography>
            </Box>
          </Stack>
        </Stack>
      </Box>
      <Box sx={{
        whiteSpace: 'pre-wrap',
        overflowWrap: 'anywhere',
        display: 'flex',
        justifyContent: { xs: 'center', sm: 'start' },
      }}
      >
        <Typography variant="bodyStandardRegular">
          {t('profile.clubOfficialAccount')}
        </Typography>
      </Box>
      <Box sx={{
        display: 'flex',
        justifyContent: { xs: 'center', sm: 'start' },
      }}
      >
        <Typography variant="bodyStandardRegular">
          {clubCity}
        </Typography>
      </Box>
      <Stack
        direction="row"
        spacing={2}
        sx={{
          justifyContent: { xs: 'center', sm: 'start' },
        }}
      >
        {discordButtonIcon}
        {twitterButtonIcon}
        <IconButton
          onClick={handleOpenMenu}
          sx={{
            border: `1px solid ${palette.common.silverGrey}`,
            backgroundColor: 'common.white',
            '&:hover': {
              backgroundColor: 'common.white',
            },
          }}
        >
          <ShareIcon
            height="16"
            width="16"
            stroke={palette.common.darkGray}
          />
        </IconButton>
      </Stack>
      <ShareProfileMenu
        anchorEl={anchorEl}
        handleCloseMenu={handleCloseMenu}
      />
    </Stack>
  );
};

export default ClubProfileHeader;
