import {
  Typography,
  Box,
  Alert,
  Link,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import { PATHS } from 'routes';
import { loginService } from 'services';
import { FormPasswordField, FormTextField } from 'components/form';
import { useCallback, useMemo, useState } from 'react';
import { useFastForm, useLocale, useRecaptcha } from 'util/hooks';
import { RecaptchaNotice } from 'components/helpers';

const LoginForm = (props) => {
  const { onLoginSuccess } = props;

  const { executeRecaptcha } = useRecaptcha({ action: 'LOGIN' });
  const [loginError, setLoginError] = useState('');
  const { t } = useLocale();

  const onSubmit = useCallback(async (values) => {
    try {
      const recaptchaToken = await executeRecaptcha();
      const data = await loginService(values, { recaptchaToken });
      onLoginSuccess(data);
    } catch (error) {
      setLoginError(error.message || error.errors[0].message || t('common.somethingWrong'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const defaultValues = {
    username: '',
    password: '',
  };

  const validationSchema = useMemo(() => (Yup.object({
    username: Yup.string()
      .required(t('login.enterEmail/username')),
    password: Yup.string()
      .required(t('password.enterPassword')),
  })), [t]);

  const {
    control,
    handleSubmit,
    formState: {
      isSubmitting,
      isDirty,
    },
  } = useFastForm({
    defaultValues,
    validationSchema,
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{
        py: 6,
        px: {
          sm: 20,
          xs: 6,
        },
      }}
      >
        <Typography
          sx={{
            mb: 21,
            display: 'flex',
            justifyContent: 'center',
          }}
          variant="h4"
        >
          {t('login.loginHeader')}
        </Typography>
        {loginError
          && (
            <Alert
              severity="error"
              sx={{ width: 1, mb: 8 }}
            >
              {loginError}
            </Alert>
          )}
        <Box sx={{
          display: 'grid',
          rowGap: 7,
        }}
        >
          <Box>
            <FormTextField
              name="username"
              control={control}
              autoComplete="username"
              label={t('login.email/username')}
              disabled={isSubmitting}
              fullWidth
            />
          </Box>
          <Box>
            <FormPasswordField
              name="password"
              control={control}
              showHelper={false}
              autoComplete="current-password"
              label={t('common.password')}
              fullWidth
            />
          </Box>
        </Box>
        <LoadingButton
          sx={{
            my: 8,
            display: 'flex',
            alignSelf: 'flex-start',
          }}
          type="submit"
          variant="contained"
          size="fixed"
          loading={isSubmitting}
          disabled={isSubmitting || !isDirty}
        >
          {t('common.login')}
        </LoadingButton>
        <Box
          sx={{
            display: 'flex',
            flexFlow: 'row wrap',
            justifyContent: 'center',
            textAlign: 'center',
            mt: 15,
          }}
        >
          <Typography variant="bodyStandardRegular">
            {t('login.dontHaveAccount')}
            &nbsp;
            <Link
              to={`/${PATHS.register}`}
            >
              {t('common.signup')}
            </Link>
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexFlow: 'row wrap',
            textAlign: 'center',
            justifyContent: 'center',
            mt: 4,
          }}
        >
          <Link
            to={`/${PATHS.forgotPassword}`}
          >
            {t('login.forgotPassword')}
            &nbsp;
          </Link>

        </Box>
        <RecaptchaNotice />
      </Box>
    </form>
  );
};

export default LoginForm;
