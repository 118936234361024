import { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Box, Typography, useMediaQuery,
} from '@mui/material';

import { CounterUp } from 'components/molecules';
import { capitalizeFirstLetter } from 'util/helpers';
import { useLocale } from 'util/hooks';

const StatisticsCounter = (props) => {
  const {
    label,
    number,
  } = props;

  const { t } = useLocale();
  const mobileView = useMediaQuery(({ breakpoints }) => breakpoints.down('sm'));

  const isNumberType = typeof number === 'number';
  const numberUnit = useMemo(() => {
    if (number > 999 && number < 1000000) {
      return t('common.thousand');
    }
    if (number > 1000000) {
      return t('common.million');
    }
    return '';
  }, [number, t]);

  return (
    <Box sx={{
      display: 'flex',
      width: { xs: 1, sm: 'fit-content' },
      flexDirection: 'column',
      color: 'common.gadgetGray',
      alignItems: {
        xs: 'center',
        sm: 'flex-start',
      },
    }}
    >
      <Typography
        sx={{ display: 'flex', color: 'common.limeGreen' }}
        variant={mobileView ? 'bodyMediumMedium' : 'h4'}
      >
        {isNumberType
          ? (
            <>
              <CounterUp endNumber={number} />
              {number > 0 && (
                <>
                  {numberUnit}
                  <Box variant="h4" color="common.gadgetGray">+</Box>
                </>
              )}
            </>
          )
          : 0}
      </Typography>
      <Typography
        variant={mobileView ? 'bodyStandardRegular' : 'bodyLargeRegular'}
      >
        {capitalizeFirstLetter(t(`common.${label}`))}
      </Typography>
    </Box>
  );
};

StatisticsCounter.propTypes = {
  label: PropTypes.string,
};

StatisticsCounter.defaultProps = {
  label: 'none',
};

export default StatisticsCounter;
