import { useTheme } from '@mui/styles';

const AddIcon = (props) => {
  const { palette } = useTheme();

  const {
    fill = palette.common.offWhite,
    circleFill = palette.common.explosiveGrey,
  } = props;

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="12" cy="12" r="12" fill={circleFill} />
      <path d="M11.4167 11.4167V7.91669H12.5834V11.4167H16.0834V12.5834H12.5834V16.0834H11.4167V12.5834H7.91669V11.4167H11.4167Z" fill={fill} />
    </svg>
  );
};

export default AddIcon;
