import { useTheme } from '@mui/styles';

const EventsIconOutlined = (props) => {
  const { palette } = useTheme();

  const {
    fill = 'none',
    stroke = palette.text.secondary,
    rectFill = palette.common.white,
  } = props;

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_1265_6770)">
        <path d="M18.127 3.125H1.87695C1.1866 3.125 0.626953 3.68464 0.626953 4.375V18.125C0.626953 18.8154 1.1866 19.375 1.87695 19.375H18.127C18.8173 19.375 19.377 18.8154 19.377 18.125V4.375C19.377 3.68464 18.8173 3.125 18.127 3.125Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M0.626953 8.125H19.377" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M5.62695 5V0.625" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M14.377 5V0.625" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_1265_6770">
          <rect width="20" height="20" fill={rectFill} />
        </clipPath>
      </defs>
    </svg>
  );
};
export default EventsIconOutlined;
