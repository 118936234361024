import END_POINTS from 'services/endPoints';
import api from 'services/api';

const getCourseRecordings = (courseId) => api(
  END_POINTS.courseRecordings(courseId),
)
  .then((response) => Promise.resolve(response))
  .catch((error) => Promise.reject(error));

export default getCourseRecordings;
