import { useTheme } from '@mui/styles';

const VerifiedIcon = (props) => {
  const { palette } = useTheme();
  const {
    fill = palette.common.turquoiseSeaweedGreen,
    ...rest
  } = props;

  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path fill={fill} d="M17.5008 9.41667C17.5008 8.1 16.7717 6.95833 15.7108 6.41667C15.8392 6.05417 15.9092 5.6625 15.9092 5.25C15.9092 3.40833 14.4842 1.91833 12.7275 1.91833C12.3358 1.91833 11.9608 1.98833 11.6142 2.12667C11.0992 1.0125 10.0092 0.25 8.75083 0.25C7.4925 0.25 6.40417 1.01417 5.88667 2.125C5.54083 1.9875 5.165 1.91667 4.77333 1.91667C3.015 1.91667 1.59167 3.40833 1.59167 5.25C1.59167 5.66167 1.66083 6.05333 1.78917 6.41667C0.729167 6.95833 0 8.09833 0 9.41667C0 10.6625 0.651667 11.7483 1.61833 12.3217C1.60167 12.4633 1.59167 12.605 1.59167 12.75C1.59167 14.5917 3.015 16.0833 4.77333 16.0833C5.165 16.0833 5.54 16.0117 5.88583 15.875C6.4025 16.9867 7.49083 17.75 8.75 17.75C10.01 17.75 11.0983 16.9867 11.6142 15.875C11.96 16.0108 12.335 16.0817 12.7275 16.0817C14.4858 16.0817 15.9092 14.59 15.9092 12.7483C15.9092 12.6033 15.8992 12.4617 15.8817 12.3208C16.8467 11.7483 17.5008 10.6625 17.5008 9.4175V9.41667ZM11.9875 6.63833L8.37583 12.055C8.255 12.2358 8.0575 12.3333 7.855 12.3333C7.73583 12.3333 7.615 12.3 7.50833 12.2283L7.4125 12.15L5.4 10.1375C5.15583 9.89333 5.15583 9.4975 5.4 9.25417C5.64417 9.01083 6.04 9.00917 6.28333 9.25417L7.75833 10.7267L10.9458 5.94333C11.1375 5.65583 11.5258 5.58 11.8125 5.77083C12.1008 5.9625 12.1792 6.35083 11.9875 6.6375V6.63833Z" />
    </svg>
  );
};

export default VerifiedIcon;
