import React from 'react';

const {
  REACT_APP_RECAPTCHA_KEY,
} = process.env;

const SCRIPT_ID = 'google-recaptcha-script';

const GoogleRecaptcha = () => {
  React.useEffect(() => {
    if (!document.getElementById(SCRIPT_ID) && REACT_APP_RECAPTCHA_KEY) {
      const script = document.createElement('script');
      script.id = SCRIPT_ID;
      script.src = `https://www.google.com/recaptcha/api.js?render=${REACT_APP_RECAPTCHA_KEY}`;
      document.body.appendChild(script);
    }
  }, []);

  return (
    <div
      className="g-recaptcha"
      data-sitekey={REACT_APP_RECAPTCHA_KEY}
      data-size="invisible"
    />
  );
};

export default GoogleRecaptcha;
