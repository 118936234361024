import { atom } from 'recoil';

const platformSettingsHeaderAtom = atom({
  key: 'platformSettingsHeader',
  default: {
    headerName: '',
    buttonLabel: '',
    headerLink: '',
    showButton: false,
  },
});

export default platformSettingsHeaderAtom;
