import { Box, Typography } from '@mui/material';
import { UsersIcon } from 'assets/icons';

const TableHeader = (props) => {
  const { tableHeaderProps = {} } = props;

  const {
    title = '',
    count = '',
  } = tableHeaderProps;

  return (
    <Box sx={{
      width: 1,
      px: 5,
      mb: 1,
      py: 8.5,
      rowGap: 2,
      display: 'flex',
      borderRadius: 1,
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: 'common.white',
    }}
    >
      <Typography variant="h5">
        {title}
      </Typography>
      <Box sx={{
        p: 2,
        gap: 1,
        borderRadius: 1,
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'common.winterDaysGray',
      }}
      >
        <UsersIcon width="16" height="16" />
        <Typography variant="bodySmallRegular">
          {count}
        </Typography>
      </Box>
    </Box>
  );
};

export default TableHeader;
