import axios from 'axios';
import METHODS from 'services/methods';

const uploadFileToS3 = async (
  url,
  file,
) => {
  const urlObj = new URL(url);
  const xAmzAcl = urlObj.searchParams.get('x-amz-acl');

  const options = {
    method: METHODS.PUT,
    url,
    headers: {
      'Content-Type': file.type,
      'x-amz-acl': xAmzAcl,
    },
    data: file,
  };

  await axios(options)
    .then((res) => Promise.resolve(res))
    .catch((error) => Promise.reject(error));
};

export default uploadFileToS3;
