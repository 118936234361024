import { useCustomQuery } from 'reactQuery';
import { getInvites } from 'services';

const useInvitations = (queryProps) => {
  const { queryKeyOptions = {}, ...rest } = queryProps;

  const query = useCustomQuery({
    queryKey: [
      'invitations',
      queryKeyOptions,
    ],
    queryFn: getInvites,
    ...rest,
  });

  return { ...query };
};

export default useInvitations;
