import { useTheme } from '@mui/styles';

const NoPostsWithCreateIcon = (props) => {
  const { palette } = useTheme();

  const {
    fill = 'none',
    stroke = palette.primary.main,
    innerStroke = palette.common.berylliumGrey,
    rectFill = palette.common.white,
  } = props;

  return (
    <svg width="131" height="131" viewBox="0 0 131 131" fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_3596_115894)">
        <path d="M120.114 83.6769L80.752 123.039L61.4062 126.908L65.2754 107.562L104.638 68.2003C105.65 67.1858 106.853 66.3809 108.178 65.8318C109.502 65.2827 110.922 65 112.355 65C113.789 65 115.208 65.2827 116.533 65.8318C117.857 66.3809 119.06 67.1858 120.073 68.2003L120.114 68.2416C121.129 69.2545 121.933 70.4575 122.483 71.7818C123.032 73.1061 123.314 74.5256 123.314 75.9592C123.314 77.3929 123.032 78.8124 122.483 80.1367C121.933 81.461 121.129 82.664 120.114 83.6769V83.6769Z" stroke={stroke} strokeWidth="10.3177" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M25 37H70" stroke={innerStroke} strokeWidth="10.9167" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M24 61H69" stroke={innerStroke} strokeWidth="10.9167" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M25 84H51" stroke={innerStroke} strokeWidth="10.9167" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M40.9119 112.094H12.2756C10.1056 112.094 8.02454 111.218 6.49015 109.66C4.95576 108.102 4.09375 105.989 4.09375 103.786V12.4014C4.09375 10.1981 4.95576 8.08501 6.49015 6.52702C8.02454 4.96902 10.1056 4.09375 12.2756 4.09375H70.2519C72.4204 4.09422 74.4999 4.96873 76.0338 6.52513L91.6992 22.4316C93.232 23.989 94.0933 26.1006 94.0938 28.3024V45.6322" stroke={innerStroke} strokeWidth="10.9167" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_3596_115894">
          <rect width="131" height="131" fill={rectFill} />
        </clipPath>
      </defs>
    </svg>
  );
};
export default NoPostsWithCreateIcon;
