import {
  Box,
  Typography,
  Alert,
  Button,
} from '@mui/material';
import * as Yup from 'yup';
import {
  FormTextField,
} from 'components/form';
import { LoadingButton } from '@mui/lab';
import { codeVerification, resendCode } from 'services';
import { useState } from 'react';
import {
  useFastForm, useLocale, useRecaptcha, useSnackbar,
} from 'util/hooks';
import { AUTHENTICATION_SNACKBAR_POSITION } from 'assets/constants/snackbar';

const RegistrationStepTwo = (props) => {
  const { userInfo, setUserInfo, handleNextStep } = props;
  const {
    email,
  } = userInfo;

  const [verificationError, setVerificationError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { executeRecaptcha } = useRecaptcha({ action: 'REGISTRATION_STEP_2' });
  const snack = useSnackbar();
  const { t } = useLocale();

  const onSubmit = async (values) => {
    const emailCode = { token: values.code };
    try {
      const recaptchaToken = await executeRecaptcha();
      const response = await codeVerification({
        ...emailCode,
        email,
      }, { recaptchaToken });
      setUserInfo({
        ...userInfo,
        verificationId: response.data,
      });
      setVerificationError(false);
      handleNextStep();
    } catch (error) {
      const { errors } = error;
      if (!Array.isArray(errors) || !errors?.length) {
        setVerificationError(false);
        snack({
          severity: 'error',
          message: error.message || t('common.somethingWrong'),
        });
        return;
      }

      setVerificationError(true);
      errors?.forEach((err) => setErrorMessage(err?.message || t('common.somethingWrong')));
    }
  };

  const resendVerification = async () => {
    try {
      const recaptchaToken = await executeRecaptcha();
      await resendCode({ email }, { recaptchaToken });
      setVerificationError(false);
      snack({
        severity: 'success',
        message: t('registration.successfulResend'),
        snackbarProps: AUTHENTICATION_SNACKBAR_POSITION,
      });
    } catch (error) {
      setVerificationError(true);
      setErrorMessage(error.message || t('common.somethingWrong'));
    }
  };

  const defaultValues = { code: '' };

  const validationSchema = Yup.object({
    code: Yup.string()
      .required(t('registration.validation.enterCode')),
  });

  const {
    control,
    handleSubmit,
    formState: {
      isSubmitting,
      isValid,
    },
  } = useFastForm({
    defaultValues,
    validationSchema,
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{
        py: 10,
        px: {
          sm: 15,
          xs: 5,
        },
      }}
      >
        {verificationError && (
          <Alert
            sx={{ mb: 8, width: 1 }}
            severity="error"
          >
            {errorMessage}
          </Alert>
        )}
        <Box sx={{
          width: 1,
          textAlign: 'start',
          mb: 7,
        }}
        >
          <Typography
            sx={{
              display: 'flex',
              justifyContent: 'start',
              mb: 3,
            }}
            variant="bodyStandardRegular"
          >
            {t('registration.codeVerification')}
          </Typography>
          <Typography
            sx={{ textAlign: 'left', overflowWrap: 'break-word' }}
            variant="bodyStandardBold"
          >
            <div dir="ltr">
              {email}
            </div>
          </Typography>
        </Box>
        <Box sx={{
          display: 'grid',
          rowGap: 7,
        }}
        >
          <Box>
            <FormTextField
              name="code"
              control={control}
              label={t('registration.code')}
              fullWidth
            />
          </Box>
        </Box>
        <LoadingButton
          sx={{
            my: 7,
            alignSelf: 'start',
          }}
          type="submit"
          variant="contained"
          size="regular"
          loading={isSubmitting}
          disabled={isSubmitting || !isValid}
        >
          {t('registration.verifyEmail')}
        </LoadingButton>
        <Box
          sx={{
            display: 'flex',
            flexFlow: 'row wrap',
            alignSelf: 'start',
            alignItems: 'center',
          }}
        >
          <Typography variant="bodyStandardRegular">
            {t('registration.codeNotReceived')}
            &nbsp;
          </Typography>
          <Button
            disableRipple
            sx={{ p: 0 }}
            onClick={() => resendVerification()}
          >
            {t('registration.resendCode')}
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default RegistrationStepTwo;
