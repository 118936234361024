import { useMemo } from 'react';

import {
  Box,
} from '@mui/material';

import { Tabs } from 'components/molecules';
import { useLocale } from 'util/hooks';
import { POST_STATUSES } from 'assets/constants/posts';
import PostListWrapper from './PostListWrapper';

const ManagePostsContainer = () => {
  const { t } = useLocale();

  const tabs = useMemo(() => (
    [
      {
        key: 'underReview',
        label: t('statuses.underReview'),
        component: <PostListWrapper status={POST_STATUSES.UNDER_REVIEW} />,
      },
      {
        key: 'revised',
        label: t('statuses.revised'),
        component: <PostListWrapper status={POST_STATUSES.REVISED} />,
      },
      {
        key: 'rejected',
        label: t('statuses.rejected'),
        component: <PostListWrapper status={POST_STATUSES.REJECTED} />,
      },
      {
        key: 'published',
        label: t('statuses.published'),
        component: <PostListWrapper status={POST_STATUSES.PUBLISHED} />,
      },
      {
        key: 'unpublished',
        label: t('statuses.unpublished'),
        component: <PostListWrapper status={POST_STATUSES.UNPUBLISHED} />,
      },
    ]
  ), [t]);

  return (
    <Box>
      <Tabs
        tabs={tabs}
        sx={{
          mb: 7,
          maxWidth: { xs: 320, sm: 1 },
        }}
      />
    </Box>
  );
};

export default ManagePostsContainer;
