import { useCustomInfiniteQuery } from 'reactQuery';
import { getMyPosts } from 'services';

const useInfiniteMyPosts = (queryProps = {}) => {
  const { queryKeyOptions = {}, ...rest } = queryProps;

  const query = useCustomInfiniteQuery({
    queryKey: ['infinite-my-posts', queryKeyOptions],
    queryFn: getMyPosts,
    ...rest,
  });

  return { ...query };
};

export default useInfiniteMyPosts;
