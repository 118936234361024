import { useParams } from 'react-router-dom';
import { getMostViewedPosts } from 'services';
import { useCustomQuery } from 'reactQuery';

const useMostViewedPosts = (queryProps = {}) => {
  const { username } = useParams();

  const query = useCustomQuery({
    queryKey: [`most-viewed-posts-${username}`],
    queryFn: () => getMostViewedPosts(username),
    ...queryProps,
  });

  return { ...query };
};

export default useMostViewedPosts;
