import {
  Box, Button, Stack, Typography,

} from '@mui/material';

import { useLocale, useModal } from 'util/hooks';
import { ChapterCard } from 'components/organisms';
import MODAL_KEYS from 'assets/constants/modalKeys';

const OnDemandCourseDetails = (props) => {
  const { addModal } = useModal();
  const { t } = useLocale();

  const {
    courseDetails = {},
  } = props;

  const {
    managedById = '',
    createdBy = '',
    chapters = [],
  } = courseDetails;

  const onClickCreateCourse = () => {
    addModal({
      key: MODAL_KEYS.courseChapter,
      props: {
        createMode: true,
      },
    });
  };

  return (
    <Stack>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
      >
        <Typography
          variant="bodyStandardRegular"
        >
          {t('courses.createChapterNote')}
        </Typography>
        <Button variant="outlined" size="regular" onClick={onClickCreateCourse}>
          {t('courses.createChapter')}
        </Button>
      </Box>
      <Box>
        {chapters?.map((chapter, index) => (
          <Box
            key={chapter.id}
            sx={{ mt: 5 }}
          >
            <ChapterCard
              chapterDetails={{
                ...chapter,
                chapterNumber: index + 1,
              }}
              managedById={managedById}
              createdBy={createdBy}
            />
          </Box>
        ))}
      </Box>
    </Stack>
  );
};

export default OnDemandCourseDetails;
