import DOMPurify from 'dompurify';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Chip,
  Menu,
  Card,
  Link,
  Grid,
  Alert,
  Button,
  MenuItem,
  CardMedia,
  IconButton,
  Typography,
  CardActions,
} from '@mui/material';

import { PATHS } from 'routes';
import { MoreActionsIcon } from 'assets/icons';
import { ActionIconWithCount } from 'components/molecules';
import { AUTH_ROLES } from 'assets/constants/userRoles';

import {
  likePost,
  deletePost,
  bookmarkPost,
  unpublishPost,
  getSharePostCount,
  requestPostApproval,
} from 'services';

import {
  useAuth,
  useModal,
  useShare,
  useLocale,
  useSnackbar,
} from 'util/hooks';
import { postStatus } from 'assets/data';
import { POST_TYPES } from 'assets/constants/posts';
import { CommentCard, RejectAlertMessage } from 'components/organisms';

import { replaceURLWithHTMLLinks, withHttps } from 'util/helpers';
import { useGeneralSettings, usePostDetails } from 'reactQuery/queries';
import SETTINGS_KEYS from 'assets/constants/settingsKeys';

import MODAL_KEYS from 'assets/constants/modalKeys';
import PostHeader from '../PostHeader/PostHeader';
import SharePostMenu from '../PostCardContainer/SharePostMenu';

const PostDetails = () => {
  const {
    data: postDetails = {},
    refetch: refetchPostDetails,
  } = usePostDetails();

  const {
    tags,
    type,
    title,
    video,
    cover,
    author,
    status,
    content,
    authorId,
    comments,
    id: postId,
    references,
    trimmedContent,
    likes: postLikes,
    shares: postShares,
    isLiked: isPostLiked,
    bookmarks: postBookmarks,
    isBookmarked: isPostBookmarked,
  } = postDetails || {};

  const [anchorElUser, setAnchorElUser] = useState(null);

  // likes
  const [likesCount, setLikesCount] = useState(postLikes);
  const [updateIsLiked, setUpdateIsLiked] = useState(isPostLiked);
  // bookmarks
  const [bookmarksCount, setBookmarksCount] = useState(postBookmarks);
  const [updateIsBookmarked, setUpdateIsBookmarked] = useState(isPostBookmarked);
  // share
  const [shareCount, setShareCount] = useState(postShares);

  const { currentUser, isAdmin, isLoggedIn } = useAuth();
  const { isAr, dir, t } = useLocale();
  const { addModal } = useModal();
  const navigate = useNavigate();
  const snack = useSnackbar();

  const {
    data = [],
  } = useGeneralSettings();

  // Status
  const isPostDraft = status === postStatus.DRAFT;
  const isPostRevised = status === postStatus.REVISED;
  const isPostRejected = status === postStatus.REJECTED;
  const isPostPublished = status === postStatus.PUBLISHED;
  const isPostUnderReview = status === postStatus.UNDER_REVIEW;

  // Permissions
  const isEditable = isPostDraft || isPostRevised;
  const isAuthor = currentUser.username === author.username;
  const isSkipPostReview = isAdmin
    || data?.find((setting) => setting.key === SETTINGS_KEYS.skipPostReview)?.value
    || false;

  // Post type
  const isArticle = type === POST_TYPES.article;
  const isKnowledgeShare = type === POST_TYPES.knowledgeShare;

  // Content
  const hasCover = isArticle;
  const hasTitle = !!title;
  const hasComments = comments?.length > 0;

  // User roles
  const isAdminOrLeaderLoggedIn = isAdmin
    || currentUser.userRole === AUTH_ROLES.leader;
  const AdminAndLeaderCanTakeAction = (isPostUnderReview
    || status === postStatus.PENDING) && isAdminOrLeaderLoggedIn;

  const contentWithClickableLinks = useMemo(() => (isKnowledgeShare
    ? replaceURLWithHTMLLinks(content)
    : content), [content, isKnowledgeShare]);

  const {
    anchorEl,
    handleOpenMenu,
    handleCloseMenu,
  } = useShare({
    title,
    text: trimmedContent.slice(0, 64),
    url: window.location.href,
  });

  const userMenuItems = [
    {
      id: 'edit',
      label: t('common.edit'),
      onClick: () => [handleCloseUserMenu(),
        navigate(`/${PATHS.posts}/${postId}/${PATHS.edit}`)],
    },
    {
      id: 'delete',
      label: t('common.delete'),
      onClick: () => {
        onClickDelete();
        handleCloseUserMenu();
      },
      style: { color: 'common.pinkishRed' },
    },
  ];

  const adminMenuItems = [
    {
      id: 'unpublish',
      label: t('posts.adminActions.unpublish'),
      onClick: () => {
        onClickUnpublish();
        handleCloseUserMenu();
      },
      style: { color: 'common.pinkishRed' },
    },
  ];

  const handleOpenJoinUsModal = () => {
    addModal({
      key: MODAL_KEYS.joinUs,
    });
  };

  const onClickLike = async () => {
    try {
      if (isLoggedIn) {
        const { likes, isLiked } = await likePost(postId);
        setLikesCount(likes);
        setUpdateIsLiked(isLiked);
      } else {
        handleOpenJoinUsModal();
      }
    } catch (error) {
      snack({
        message: error?.message || error?.errors?.[0]?.message || t('common.somethingWrong'),
        severity: 'error',
      });
    }
  };

  const onClickBookmark = async () => {
    try {
      if (isLoggedIn) {
        const { bookmarks, isBookmarked } = await bookmarkPost(postId);
        setBookmarksCount(bookmarks);
        setUpdateIsBookmarked(isBookmarked);
        if (isBookmarked) {
          snack({
            message: t('posts.postIsBookmarked'),
            severity: 'success',
          });
        }
      } else {
        handleOpenJoinUsModal();
      }
    } catch (error) {
      snack({
        message: error?.message || error?.errors?.[0]?.message || t('common.somethingWrong'),
        severity: 'error',
      });
    }
  };
  const onClickShare = async () => {
    try {
      const { shares } = await getSharePostCount(postId);
      setShareCount(shares);
    } catch (error) {
      snack({
        message: error?.message || error?.errors?.[0]?.message || t('common.somethingWrong'),
        severity: 'error',
      });
    }
  };

  const onClickSend = async () => {
    try {
      await requestPostApproval(postId);
      snack({
        severity: 'success',
        message: isSkipPostReview
          ? t('posts.postIsPublished')
          : t('posts.postSentForApproval'),
      });
      navigate(`/${PATHS.posts}`);
    } catch (error) {
      snack({
        message: error?.message || error?.errors?.[0]?.message || t('common.somethingWrong'),
        severity: 'error',
      });
    }
  };

  const onClickDelete = async () => {
    try {
      await deletePost(postId);
      snack({
        message: t('posts.deletedSuccessfully'),
        severity: 'success',
      });
      navigate(`/${PATHS.posts}`);
    } catch (error) {
      snack({
        message: error?.message || error?.errors?.[0]?.message || t('common.somethingWrong'),
        severity: 'error',
      });
    }
  };

  const onClickUnpublish = async () => {
    try {
      await unpublishPost(postId);
      snack({
        message: t('posts.adminActions.unpublishedSuccessfully'),
        severity: 'success',
      });
      navigate(`/${PATHS.posts}`);
    } catch (error) {
      snack({
        message: error?.message || error?.errors?.[0]?.message || t('common.somethingWrong'),
        severity: 'error',
      });
    }
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenPostModal = (key) => {
    addModal({
      key,
      props: {
        postId,
        refetchPostDetails,
      },
    });
  };
  const renderAdminActionButtons = () => (
    <Box sx={{
      display: 'flex',
      justifyContent: 'flex-end',
      width: 1,
      px: 0,
      mb: 5,
    }}
    >
      <Button
        disableRipple
        sx={{
          mx: 2,
          color: 'common.pinkishRed',
          '&.MuiButton-text': {
            '&:hover': {
              backgroundColor: 'transparent',
              color: 'common.pinkishRed',
            },
          },
        }}
        onClick={() => handleOpenPostModal(MODAL_KEYS.rejectPost)}
      >
        {t('actions.reject')}
      </Button>
      <Button
        variant="outlined"
        size="regular"
        sx={{ mx: 2 }}
        onClick={() => handleOpenPostModal(MODAL_KEYS.revisePost)}
      >
        {t('actions.return')}
      </Button>
      <Button
        variant="contained"
        size="regular"
        sx={{ mx: 2 }}
        onClick={() => handleOpenPostModal(MODAL_KEYS.approvePost)}
      >
        {t('actions.approve')}
      </Button>
    </Box>
  );

  const renderActionList = () => (
    <Box sx={{
      display: 'flex',
      justifyContent: 'flex-end',
      width: 1,
      px: 3,
      mb: 5,
    }}
    >
      <IconButton
        id="menu-button"
        aria-controls={anchorElUser ? 'actions-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={anchorElUser ? 'true' : undefined}
        sx={{ p: 0 }}
        disableRipple
        onClick={handleOpenUserMenu}
      >
        <MoreActionsIcon />
      </IconButton>
      <Menu
        sx={{
          mt: 3,
          '& .MuiPaper-root': {
            boxShadow: (theme) => `0 4px 4px 0px ${theme.palette.common.boxShadow2}`,
          },
        }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {((isAdmin && isPostPublished) ? adminMenuItems : userMenuItems).map((menuItem) => (
          <Box key={menuItem.id} sx={{ width: '150px' }}>
            <MenuItem onClick={menuItem.onClick} dir={dir}>
              <Typography variant="bodySmallMedium" sx={{ ...menuItem.style }}>{menuItem.label}</Typography>
            </MenuItem>
          </Box>
        ))}
      </Menu>
    </Box>
  );

  return (
    <Box>
      {AdminAndLeaderCanTakeAction && renderAdminActionButtons()}
      {((isPostDraft && isAuthor) || (isAdmin && isPostPublished)) && renderActionList()}

      {(isAdminOrLeaderLoggedIn && hasComments && isPostUnderReview)
        && (
          <Box sx={{ my: 12 }}>
            <CommentCard withSubmission />
          </Box>
        )}
      {(hasComments && isPostRejected && isAuthor)
        && (
          <Box sx={{ mb: 12 }}>
            <RejectAlertMessage comments={comments} />
          </Box>
        )}
      {(hasComments && isPostRevised && isAuthor)
        && (
          <Box sx={{ my: 10 }}>
            <Alert
              severity="info"
              sx={{ width: 1, color: 'common.softBlue' }}
            >
              <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
              >
                <Typography variant="bodySmallRegular">
                  {t('posts.comments.commentAlert')}
                </Typography>
                <Link
                  to={`/${PATHS.posts}/${postId}/${PATHS.edit}`}
                  sx={{ color: 'common.softBlue' }}
                >
                  <Box sx={{ px: 2 }}>
                    <Typography variant="bodySmallBold">
                      {t('posts.comments.updatePost')}
                    </Typography>
                  </Box>
                </Link>
              </Box>
            </Alert>
          </Box>
        )}
      <Box sx={{
        backgroundColor: 'common.white',
        borderRadius: 1,
        px: 6,
        py: 3,
      }}
      >
        <PostHeader
          postDetails={postDetails}
        />
        {hasCover
          && (
            <Card
              sx={{
                width: '100%',
                mt: 5,
              }}
            >
              <CardMedia
                component="img"
                src={cover}
                alt="cover"
              />
            </Card>
          )}
        {video
          && (
            <Card
              sx={{
                width: '100%',
                mt: 5,
              }}
            >
              <CardMedia
                component="video"
                src={video}
                alt="video"
                controls
                autoPlay
              />
            </Card>
          )}
        {hasTitle
          && (
            <Typography
              dir="auto"
              variant="h5"
              sx={{
                pt: {
                  xs: 10,
                  sm: 17,
                },
              }}
            >
              {title}
            </Typography>
          )}
        <Box
          dir="auto"
          sx={{
            '& img': {
              width: 1,
            },
            overflowWrap: 'anywhere',
            pt: {
              xs: 4,
              sm: 12,
            },
            pb: {
              xs: 4,
              sm: 12,
            },
            lineHeight: 2,
          }}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(contentWithClickableLinks),
          }}
        />
        {(tags.length > 0)
          && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                pb: 6,
              }}
            >
              <Box>
                <Typography
                  variant="h6"
                  sx={{
                    mr: {
                      xs: 4,
                      sm: 8,
                    },
                  }}
                >
                  {`${t('common.tags')}:`}
                </Typography>
                {tags?.map((tag) => (
                  <Chip
                    key={tag.id}
                    label={isAr ? tag.nameAr : tag.nameEn}
                    color="tags"
                    sx={{ mr: 2, mt: 4 }}
                  />
                ))}
              </Box>
            </Box>
          )}
        {(references.length > 0)
          && (
            <Box
              sx={{ pb: 8 }}
            >
              <Typography
                variant="h6"
                sx={{
                  mt: {
                    xs: 6,
                    sm: 10,
                  },
                  mb: 4,
                }}
              >
                {`${t('common.references')}:`}
              </Typography>
              <Grid
                container
                direction="column"
              >
                {references?.map((reference) => (
                  <Grid
                    key={reference.id}
                    sx={{ ml: 1 }}
                  >
                    <Link
                      target="_blank"
                      href={withHttps(reference.url)}
                      component="a"
                    >
                      <Typography
                        key={reference.id}
                        variant="bodyStandardMedium"
                        sx={{ color: 'common.softBlue' }}
                      >
                        {`• ${reference.label}`}
                      </Typography>
                    </Link>
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}
        {isEditable && isAuthor
          && (
            <Box>
              <Button
                variant="contained"
                size="regular"
                onClick={onClickSend}
                sx={{
                  my: {
                    xs: 5,
                    sm: 10,
                  },
                }}
              >
                {isSkipPostReview ? t('common.send') : t('common.sendForApproval')}
              </Button>
            </Box>
          )}
        {isPostPublished
          && (
            <Box sx={{
              display: 'flex',
              justifyContent: {
                xs: 'center',
                sm: 'flex-start',
              },
            }}
            >
              <CardActions
                sx={{
                  pt: 8,
                  display: 'flex',
                  width: {
                    xs: 1,
                    sm: 0.25,
                  },
                  justifyContent: {
                    xs: 'space-between',
                    sm: 'flex-start',
                  },
                }}
              >
                <ActionIconWithCount
                  count={likesCount}
                  filled={updateIsLiked}
                  type="like"
                  onClick={onClickLike}
                />
                <ActionIconWithCount
                  count={bookmarksCount}
                  filled={updateIsBookmarked}
                  type="bookmark"
                  onClick={onClickBookmark}
                />
                <ActionIconWithCount
                  count={shareCount}
                  type="share"
                  onClick={handleOpenMenu}
                />
              </CardActions>
            </Box>
          )}
      </Box>
      <SharePostMenu
        postId={postId}
        authorId={authorId}
        anchorEl={anchorEl}
        sharePost={onClickShare}
        handleCloseMenu={handleCloseMenu}
      />
    </Box>
  );
};

export default PostDetails;
