import { useParams } from 'react-router-dom';
import { getCourseEnrollments } from 'services';
import { useCustomQuery } from 'reactQuery';

const useCourseEnrollments = () => {
  const { courseId } = useParams();

  const query = useCustomQuery({
    queryKey: [`course-enrollments-${courseId}`],
    queryFn: (options) => getCourseEnrollments(courseId, options),
  });

  return { ...query };
};

export default useCourseEnrollments;
