import { getTopStudents } from 'services';
import { useCustomQuery } from 'reactQuery';

const useTopStudents = (queryProps = {}) => {
  const query = useCustomQuery({
    queryKey: ['topStudents'],
    queryFn: getTopStudents,
    ...queryProps,
  });

  return { ...query };
};

export default useTopStudents;
