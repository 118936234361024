import { useTheme } from '@mui/styles';

const EditIcon = (props) => {
  const { palette } = useTheme();

  const {
    fill = 'none',
    stroke = palette.common.darkGray,
  } = props;

  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M7 15.8637V17.9468H9.08304L15.2266 11.8032L13.1436 9.72014L7 15.8637ZM16.8375 10.1923C17.0542 9.97566 17.0542 9.62571 16.8375 9.40907L15.5377 8.10925C15.3211 7.89262 14.9711 7.89262 14.7545 8.10925L13.738 9.12578L15.821 11.2088L16.8375 10.1923Z" fill={stroke} fillOpacity="0.34" />
      <circle cx="12" cy="12.9468" r="11.5" stroke={stroke} strokeOpacity="0.34" />
    </svg>
  );
};

export default EditIcon;
