import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { useNavigate, useParams } from 'react-router-dom';

import { Box, Typography, Stack } from '@mui/material';

import {
  FormCheckbox,
  FormTextField,
  FormDatePicker,
  FormUploadMedia,
  FormAutocomplete,
} from 'components/form';

import {
  getAllCertificates,
  createUserCertificate,
  updateUserCertificate,
} from 'services';

import {
  useModal,
  useLocale,
  useSnackbar,
  useFastForm,
} from 'util/hooks';

import { getDirtyObject, REGEX } from 'util/helpers';
import MODAL_KEYS from 'assets/constants/modalKeys';
import MEDIA_TYPES from 'assets/constants/media';

const ProfileCertificateDetailsForm = (props) => {
  const {
    certificateDetails = {},
    createMode = false,
  } = props;

  const { t } = useLocale();
  const snack = useSnackbar();
  const navigate = useNavigate();
  const { addModal } = useModal();
  const { username } = useParams();
  const { certificateId } = useParams();

  const onSubmit = async (values) => {
    let message = '';

    try {
      if (createMode) {
        const payload = {
          ...values,
          certificateLink: values.certificateLink || null,
          certificate: { id: Number(values.certificate.id) },
        };
        await createUserCertificate(payload);
        message = t('certificates.forms.certificateAdded');
      } else {
        const dirtyValues = getDirtyObject(values, defaultValues);
        const dirtyPayload = {
          ...dirtyValues,
          certificateLink: dirtyValues.certificateLink || null,
        };
        await updateUserCertificate(dirtyPayload, certificateId);
        message = t('certificates.forms.certificateEdited');
      }

      navigate(`/profile/${username}`);
      snack({
        severity: 'success',
        message,
      });
    } catch (error) {
      error.errors?.forEach((err) => {
        setError(err.property, {
          type: 'api',
          message: err.message,
        });

        if (!err.property) {
          snack({
            severity: 'error',
            message: err.message || t('common.somethingWrong'),
          });
        }
      });
    }
  };

  const defaultValues = {
    certificate: certificateDetails?.certificate || '',
    certificateNumber: certificateDetails?.certificateNumber || '',
    certificateLink: certificateDetails?.certificateLink || '',
    issueDate: certificateDetails?.issueDate || new Date(),
    expiryDate: certificateDetails?.expiryDate || null,
    image: certificateDetails?.image || '',
    sendAnnouncementPost: certificateDetails?.sendAnnouncementPost || false,
  };

  const validationSchema = Yup.object().shape(
    {
      certificate: Yup
        .object()
        .nullable(true)
        .required(t('certificates.forms.validation.enterName')),
      certificateNumber: Yup.string()
        .trim()
        .required(t('certificates.forms.validation.enterId'))
        .matches(REGEX.alphaNumericEnSpace, t('certificates.forms.validation.enterId')),
      certificateLink: Yup.string()
        .when('certificateLink', (value) => {
          if (value?.length) {
            return Yup.string().matches(REGEX.url, t('certificates.forms.validation.enterLink'));
          }
          return Yup.string().notRequired();
        }),
      issueDate: Yup
        .date()
        .typeError(t('common.enterValidDate'))
        .nullable(true)
        .required(t('certificates.forms.validation.enterIssueDate')),
      expiryDate: Yup
        .date()
        .typeError(t('common.enterValidDate'))
        .nullable(true)
        .default(null)
        .when('issueDate', (issueDate, schema) => issueDate
          && schema.min(issueDate, t('certificates.forms.validation.expiryDateValidation'))),
      // date validation sucks when it comes to being optional
      // ref: https://github.com/jquense/yup/issues/434
      image: Yup
        .string()
        .nullable()
        .required(t('certificates.forms.validation.uploadImage')),
      sendAnnouncementPost: Yup.bool(),
    },
    [
      ['certificateLink', 'certificateLink'],
    ], // cyclic dependency list
  );

  const {
    control,
    handleSubmit,
    watch,
    setError,
    formState: {
      isSubmitting,
      isValid,
    },
  } = useFastForm({
    defaultValues,
    validationSchema,
  });

  const handleModal = () => {
    addModal({
      key: MODAL_KEYS.confirmAddUserCertificate,
      props: {
        handleConfirm: handleSubmit(onSubmit),
      },
    });
  };

  return (
    <form>
      <Stack spacing={6}>
        <Box>
          <FormAutocomplete
            name="certificate"
            control={control}
            label={t('certificates.forms.certificateName')}
            selectFunc={getAllCertificates}
            groupBy={(option) => option.provider.name}
            disabled={isSubmitting}
            fullWidth
          />
        </Box>
        <Box>
          <FormTextField
            name="certificateNumber"
            control={control}
            label={t('certificates.forms.certificateId')}
            disabled={isSubmitting}
            fullWidth
          />
        </Box>
        <Box>
          <FormTextField
            name="certificateLink"
            control={control}
            label={t('certificates.forms.certificateLink')}
            disabled={isSubmitting}
            fullWidth
          />
        </Box>
        <Box sx={{
          display: 'flex',
          columnGap: 4,
        }}
        >
          <FormDatePicker
            name="issueDate"
            control={control}
            inputProps={{
              fullWidth: true,
              label: t('common.issueDate'),
            }}
            pickerProps={{ disableFuture: true }}
            disabled={isSubmitting}
          />
          <FormDatePicker
            name="expiryDate"
            control={control}
            inputProps={{
              fullWidth: true,
              label: `${t('common.expiryDate')} (${t('common.optional')})`,
            }}
            pickerProps={{ minDate: watch('issueDate') }}
            disabled={isSubmitting}
          />
        </Box>
        <Box>
          <FormUploadMedia
            name="image"
            src={certificateDetails?.image}
            disabled={isSubmitting}
            control={control}
            setError={(errorProps) => setError('image', errorProps)}
            type={MEDIA_TYPES.image}
            required
            helperText={(
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                <Typography variant="bodySmallRegular">
                  {t('uploads.uploadByDrag')}
                </Typography>
                <Typography variant="bodySmallMedium">
                  {t('uploads.size1mb')}
                </Typography>
                <Typography variant="bodySmallMedium">
                  {t('uploads.recommendedDimensions')}
                </Typography>
              </Box>
            )}
          />
        </Box>
        {createMode && (
          <Box>
            <FormCheckbox
              name="sendAnnouncementPost"
              control={control}
              disabled={isSubmitting}
              checkboxProps={{
                checked: watch('sendAnnouncementPost'),
              }}
              label={(
                <Box>
                  <Typography variant="bodySmallRegular">
                    {t('certificates.forms.announce')}
                  </Typography>
                </Box>
              )}
            />
          </Box>
        )}
        <Box sx={{
          mt: 8,
          display: 'flex',
          justifyContent: 'end',
        }}
        >
          <LoadingButton
            variant="contained"
            size="fixed"
            onClick={handleModal}
            loading={isSubmitting}
            disabled={isSubmitting || !isValid}
          >
            {createMode ? t('common.submit') : t('common.update')}
          </LoadingButton>
        </Box>
      </Stack>
    </form>
  );
};

export default ProfileCertificateDetailsForm;
