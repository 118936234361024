import { useTheme } from '@mui/styles';

const DashboardIcon = (props) => {
  const { palette } = useTheme();

  const {
    fill = 'none',
    stroke = palette.text.secondary,
    rectFill = palette.common.offWhite,
  } = props;

  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="32" height="32" rx="10.6667" fill={rectFill} />
      <rect x="6.75" y="6.75" width="7.38889" height="7.38889" rx="1.25" stroke={stroke} strokeWidth="1.5" />
      <rect x="17.8613" y="6.75" width="7.38889" height="7.38889" rx="1.25" stroke={stroke} strokeWidth="1.5" />
      <rect x="6.75" y="17.8613" width="7.38889" height="7.38889" rx="1.25" stroke={stroke} strokeWidth="1.5" />
      <rect x="17.8613" y="17.8613" width="7.38889" height="7.38889" rx="1.25" stroke={stroke} strokeWidth="1.5" />
    </svg>
  );
};

export default DashboardIcon;
