import {
  mainLayout,
  landingLayout,
  managementLayout,
  publicLayout,
  coursesPublicLayout,
  streamLayout,
  dashboard,
  landing,
  login,
  noMatch,
  register,
  resetPassword,
  forgotPassword,
  authenticationLayout,
  acceptInvitation,
  certificates,
  users,
  students,
  invite,
  courses,
  manageCourses,
  publicCourseManagement,
  publicViewCourseManagement,
  home,
  profile,
  addProfileCertificate,
  editProfileCertificate,
  managePosts,
  platformSettings,
  addTopic,
  editTopic,
  addProvider,
  editProvider,
  addTag,
  editTag,
  addInterest,
  editInterest,
  addCertificate,
  editCertificate,
  updatePost,
  createVideo,
  createShareKnowledge,
  createPost,
  createArticle,
  viewPost,
  clubPosts,
  bookmarks,
  myPosts,
  viewCourseManagement,
  editCourse,
  viewSession,
  createCourse,
  createOnDemandCourse,
  createLiveCourse,
  nafath,
  upcomingCourses,
  myCourses,
  viewCourse,
  emailAuthentication,
  emailVerification,
  viewRecordedSession,
  redirectEmailVerification,
  clubMembers,
  leaderboard,
  profileSettings,
  clubProfile,
  clubProfileMembers,
  accountConnectionValidation,
  clubleadersForm,
  cyberhubAramcoWaitingListForm,
  cyberhubAramcoConfirmationForm,
  cyberhubAramcoCtfForm,
  manageClubs,
} from './routes';

const forms = {
  children: [
    clubleadersForm,
    cyberhubAramcoCtfForm,
    cyberhubAramcoConfirmationForm,
    cyberhubAramcoWaitingListForm,
  ],
};

const landingRouteConfigs = {
  ...landingLayout,
  children: [
    landing,
  ],
};

const streamLayoutConfigs = {
  ...streamLayout,
  children: [
    viewSession,
    viewRecordedSession,
    noMatch,
  ],
};

const managementRouteConfigs = {
  ...managementLayout,
  children: [
    dashboard,
    profile,
    certificates,
    platformSettings,
    addTopic,
    editTopic,
    addProvider,
    editProvider,
    addTag,
    editTag,
    addInterest,
    editInterest,
    addCertificate,
    editCertificate,
    viewPost,
    managePosts,
    manageCourses,
    createCourse,
    createOnDemandCourse,
    createLiveCourse,
    invite,
    users,
    viewCourseManagement,
    editCourse,
    viewSession,
    clubProfile,
    clubProfileMembers,
    manageClubs,
    noMatch,
  ],
};

const publicRouteConfigs = {
  ...publicLayout,
  children: [
    home,
    leaderboard,
    profile,
    addProfileCertificate,
    editProfileCertificate,
    createCourse,
    createOnDemandCourse,
    createLiveCourse,
    publicCourseManagement,
    publicViewCourseManagement,
    viewPost,
    myPosts,
    bookmarks,
    clubPosts,
    students,
    clubMembers,
    editCourse,
    nafath,
    profileSettings,
    createPost,
    updatePost,
    createArticle,
    createVideo,
    createShareKnowledge,
    clubProfile,
    clubProfileMembers,
    accountConnectionValidation,
    manageClubs,
    noMatch,
  ],
};

const coursesPublicRouteConfigs = {
  ...coursesPublicLayout,
  children: [
    courses,
    upcomingCourses,
    myCourses,
    viewCourse,
    noMatch,
  ],
};

const authenticationRouteConfigs = {
  ...authenticationLayout,
  children: [
    login,
    register,
    resetPassword,
    forgotPassword,
    acceptInvitation,
  ],
};

export const unverifiedStudentRouteConfigs = [
  {
    ...authenticationLayout,
    children: [
      emailAuthentication,
      emailVerification,
      redirectEmailVerification,
    ],
  },
];

export const mainRouteConfigs = [
  {
    ...mainLayout,
    children: [
      forms,
      landingRouteConfigs,
      publicRouteConfigs,
      coursesPublicRouteConfigs,
      authenticationRouteConfigs,
      streamLayoutConfigs,
    ],
  },
];

export const managementLayoutRouteConfigs = [
  {
    ...mainLayout,
    children: [
      landingRouteConfigs,
      streamLayoutConfigs,
      managementRouteConfigs,
    ],
  },
];
