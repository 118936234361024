import { useTheme } from '@mui/styles';

const SessionTimePickerIcon = (props) => {
  const { palette } = useTheme();

  const {
    fill = 'none',
    innerFill = palette.common.darkGray,
    circleFill = palette.common.turquoiseSeaweedGreen,
  } = props;

  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M12 22.3647C6.477 22.3647 2 17.8877 2 12.3647C2 6.84175 6.477 2.36475 12 2.36475C17.523 2.36475 22 6.84175 22 12.3647C22 17.8877 17.523 22.3647 12 22.3647ZM12 20.3647C14.1217 20.3647 16.1566 19.5219 17.6569 18.0216C19.1571 16.5213 20 14.4865 20 12.3647C20 10.243 19.1571 8.20818 17.6569 6.70789C16.1566 5.2076 14.1217 4.36475 12 4.36475C9.87827 4.36475 7.84344 5.2076 6.34315 6.70789C4.84285 8.20818 4 10.243 4 12.3647C4 14.4865 4.84285 16.5213 6.34315 18.0216C7.84344 19.5219 9.87827 20.3647 12 20.3647ZM13 12.3647H17V14.3647H11V7.36475H13V12.3647Z" fill={innerFill} />
      <circle cx="18" cy="5.36475" r="3" fill={circleFill} />
    </svg>
  );
};

export default SessionTimePickerIcon;
