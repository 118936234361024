import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { REGEX } from 'util/helpers';
import { useTheme } from '@mui/styles';
import { useLocale } from 'util/hooks';

const PasswordHelper = ({
  password = '',
  onValidationEnd = () => { },
}) => {
  const { palette } = useTheme();
  const { common } = palette;

  const failStyle = { color: common.error };
  const successStyle = { color: common.success };
  const { t } = useLocale();

  const [validPassword, setValidPassword] = useState({
    isNumeric: false,
    isLowercase: false,
    isUppercase: false,
    isSpecialCharacter: false,
    isLong: false,
  });

  const {
    isNumeric,
    isLowercase,
    isUppercase,
    isSpecialCharacter,
    isLong,
  } = validPassword;

  useEffect(() => {
    const checkPasswordValidity = () => {
      const validState = {
        isNumeric: REGEX.numbers.test(password),
        isLowercase: REGEX.lowercase.test(password),
        isUppercase: REGEX.uppercase.test(password),
        isSpecialCharacter: REGEX.specialCharacter.test(password),
        isLong: password.length >= 8,
      };

      setValidPassword(validState);

      onValidationEnd(
        validState.isNumeric
        && validState.isLowercase
        && validState.isUppercase
        && validState.isSpecialCharacter
        && validState.isLong,
      );
    };

    checkPasswordValidity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password]);

  const getStyle = (isValid) => (isValid ? successStyle : failStyle);

  return (
    <Typography
      variant="bodySmallRegular"
      sx={{
        mt: 4,
      }}
    >
      {t('password.passwordAtLeast')}
      &nbsp;
      <span style={getStyle(isLong)}>
        {t('password.numberOfCharacters')}
        &nbsp;
      </span>
      {t('password.contain')}
      &nbsp;
      <span style={getStyle(isUppercase)}>
        {t('password.uppercase')}
      </span>
      {t('common.comma')}
      &nbsp;
      <span style={getStyle(isLowercase)}>
        {t('password.lowercase')}
      </span>
      {t('common.comma')}
      &nbsp;
      <span style={getStyle(isNumeric)}>
        {t('password.number')}
        &nbsp;
      </span>
      {t('common.and')}
      &nbsp;
      <span style={getStyle(isSpecialCharacter)}>
        {t('password.symbol')}
      </span>
      .
    </Typography>
  );
};

export default PasswordHelper;
