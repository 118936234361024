import {
  Box,
  Typography,
  Alert,
} from '@mui/material';
import * as Yup from 'yup';
import { PATHS } from 'routes';
import {
  FormTextField,
  FormPasswordField,
  FormNumberField,
} from 'components/form';
import { LoadingButton } from '@mui/lab';
import { REGEX } from 'util/helpers';
import { register } from 'services';
import { useNavigate } from 'react-router-dom';
import { useCallback, useMemo, useState } from 'react';
import {
  useFastForm, useSnackbar, useAuth, useLocale, useRecaptcha,
} from 'util/hooks';
import { RecaptchaNotice } from 'components/helpers';

const RegistrationStepThree = (props) => {
  const { userInfo } = props;
  const {
    club,
    verificationId,
  } = userInfo;

  const [verificationError, setVerificationError] = useState('');

  const { t } = useLocale();
  const snack = useSnackbar();
  const { login } = useAuth();
  const navigate = useNavigate();
  const { executeRecaptcha } = useRecaptcha({ action: 'REGISTRATION_STEP_3' });

  const userLogin = (data) => {
    login(data);
    navigate(`/${PATHS.posts}`);
  };

  const onSubmit = useCallback(async (values) => {
    const studentInfo = {
      ...values,
      firstNameEn: values.firstName,
      lastNameEn: values.lastName,
      club: { id: club },
      verificationId: verificationId.verificationId,
    };

    try {
      const recaptchaToken = await executeRecaptcha();
      const data = await register(studentInfo, { recaptchaToken });
      snack({
        snackbarProps: { autoHideDuration: 4000 },
        severity: 'success',
        message: t('registration.successfulSignup'),
      });
      userLogin(data);
    } catch (error) {
      const { errors } = error;
      if (!Array.isArray(errors) || !errors?.length) {
        snack({
          severity: 'error',
          message: error.message || t('common.somethingWrong'),
        });
        return;
      }

      errors?.forEach((err) => {
        setError(err.property, {
          type: 'api',
          message: err.message,
        });
        if (!err.property) {
          const [firstError] = errors;
          setVerificationError(firstError?.message || t('common.somethingWrong'));
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const defaultValues = {
    studentId: '',
    firstName: '',
    lastName: '',
    username: '',
    mobile: '',
    password: '',
  };

  const validationSchema = useMemo(() => (Yup.object({
    studentId: Yup.string()
      .matches(REGEX.alphaNumeric, t('registration.validation.enterValidStudentId'))
      .required(t('registration.validation.enterStudentId')),
    firstName: Yup.string()
      .matches(REGEX.alphabetEnWithSpace, t('registration.validation.enterEnglishLetters'))
      .required(t('registration.validation.enterFirstName'))
      .min(3, t('registration.validation.minFirstName')),
    lastName: Yup.string()
      .matches(REGEX.alphabetEnWithSpace, t('registration.validation.enterEnglishLetters'))
      .required(t('registration.validation.enterLastName'))
      .min(3, t('registration.validation.minLastName')),
    username: Yup.string()
      .matches(REGEX.alphaNumericUnderscore, t('username.validation'))
      .required(t('registration.validation.enterUsername'))
      .min(6, t('username.minLength')),
    mobile: Yup.string()
      .matches(REGEX.saudiMobileNumberWZero, t('registration.validation.mobileValidation'))
      .min(10, t('registration.validation.mobileLengthValidation'))
      .max(10, t('registration.validation.mobileLengthValidation'))
      .required(t('registration.validation.enterMobile')),
    password: Yup.string()
      .max(35, t('password.passwordMaxLength'))
      .matches(REGEX.password, t('password.weakPassword'))
      .required(t('password.enterPassword')),
  })), [t]);

  const {
    control,
    handleSubmit,
    setError,
    formState: {
      isSubmitting,
      isValid,
    },
  } = useFastForm({
    defaultValues,
    validationSchema,
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{
        py: 10,
        px: {
          sm: 15,
          xs: 5,
        },
      }}
      >
        {verificationError && (
          <Alert
            sx={{ mb: 8, width: 1 }}
            severity="error"
          >
            {verificationError}
          </Alert>
        )}
        <Box sx={{
          width: 1,
          display: 'flex',
          alignSelf: 'start',
          mb: 7,
        }}
        >
          <Typography
            sx={{
              display: 'flex',
              justifyContent: 'start',
            }}
            variant="bodyStandardRegular"
          >
            {t('registration.completeInformation')}
          </Typography>
        </Box>
        <Box sx={{
          display: 'grid',
          rowGap: 7,
        }}
        >
          <Box>
            <FormTextField
              name="studentId"
              control={control}
              label={t('registration.studentId')}
              loading={isSubmitting}
              disabled={isSubmitting}
              fullWidth
            />
          </Box>
          <Box>
            <FormTextField
              name="firstName"
              control={control}
              label={t('registration.firstName')}
              disabled={isSubmitting}
              fullWidth
            />
          </Box>
          <Box>
            <FormTextField
              name="lastName"
              control={control}
              label={t('registration.lastName')}
              disabled={isSubmitting}
              fullWidth
            />
          </Box>
          <Box>
            <FormNumberField
              name="mobile"
              control={control}
              commaSeparated={false}
              startWithZero
              label={t('registration.mobile')}
              disabled={isSubmitting}
              fullWidth
            />
          </Box>
          <Box>
            <FormTextField
              name="username"
              control={control}
              label={t('common.username')}
              disabled={isSubmitting}
              fullWidth
            />
          </Box>
          <Box>
            <FormPasswordField
              name="password"
              control={control}
              autoComplete="current-password"
              label={t('common.password')}
              disabled={isSubmitting}
              fullWidth
            />
          </Box>
        </Box>
        <LoadingButton
          sx={{
            my: 7,
            display: 'flex',
            alignSelf: 'start',
          }}
          type="submit"
          variant="contained"
          size="fixed"
          loading={isSubmitting}
          disabled={!isValid}
        >
          {t('common.signup')}
        </LoadingButton>
        <RecaptchaNotice />
      </Box>
    </form>
  );
};

export default RegistrationStepThree;
