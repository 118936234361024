import React from 'react';
import PropTypes from 'prop-types';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';

const defaultPagination = {
  page: 1,
  limit: 15,
};

const defaultOptions = {
  keepPreviousData: true,
};

const requestWrapper = async (request, params) => {
  const { queryKey, signal } = params;
  const options = queryKey?.[1] || {};

  const combinedOptions = {
    signal,
    ...options,
    pagination: {
      ...defaultPagination,
      ...options.pagination,
      page: params.pageParam || 1,
    },
  };

  const res = await request(combinedOptions);

  return res;
};

const useCustomInfiniteQuery = (props) => {
  const {
    queryKey = [''],
    queryFn = () => { },
    options = {},
  } = props;

  const [searchParams] = useSearchParams();

  const getNextPageParam = (lastPage, allPages) => {
    const { currentPage, totalPages } = lastPage.meta;

    if (currentPage < totalPages) {
      return currentPage + 1;
    }

    return false; // to stop fetching next page
  };

  const combinedQueryKey = React.useMemo(() => {
    const isArrayKey = Array.isArray(queryKey);
    const keyId = isArrayKey ? queryKey[0] : queryKey;
    const keyOptions = isArrayKey
      ? queryKey[1] || {}
      : {};

    const getCustomFilterOptions = () => {
      // To get filter keys and values from urlParams
      const combinedFilterOptions = {};
      for (const key of searchParams.keys()) {
        if (key.startsWith('filter.')) {
          combinedFilterOptions[key.substring(7)] = searchParams.getAll(key);
        }
      }
      return combinedFilterOptions;
    };

    const customFilterOptions = options.withFilterParams && getCustomFilterOptions();

    const pagination = {
      ...defaultPagination,
      ...keyOptions.pagination,
    };
    const searchOptions = {
      term: searchParams.get('q') || '',
      ...keyOptions.searchOptions,
    };
    const filterOptions = {
      ...keyOptions.filterOptions,
      ...customFilterOptions,
    };
    const relationFilterOptions = {
      ...keyOptions.relationFilterOptions,
    };

    return [
      keyId,
      {
        pagination,
        searchOptions,
        filterOptions,
        relationFilterOptions,
      }];
  }, [options.withFilterParams, queryKey, searchParams]);

  const combinedQueryFn = React.useCallback(
    (params) => requestWrapper(queryFn, params),
    [queryFn],
  );

  const combinedOptions = React.useMemo(() => ({
    ...defaultOptions,
    getNextPageParam,
    ...options,
  }), [options]);

  const query = useInfiniteQuery(
    combinedQueryKey,
    combinedQueryFn,
    combinedOptions,
  );

  return {
    ...query,
    hasData: query.data?.pages?.[0].items.length > 0,
  };
};

useCustomInfiniteQuery.propTypes = {
  queryKey: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  queryFn: PropTypes.func,
  options: PropTypes.oneOfType([PropTypes.object]),
};

useCustomInfiniteQuery.defaultProps = {
  queryKey: [''],
  queryFn: () => { },
  options: {},
};

export default useCustomInfiniteQuery;
