import { LoadingButton } from '@mui/lab';
import { Box, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';

import {
  FormAutocomplete, FormCheckbox,
  FormTextField,
} from 'components/form';
import { PATHS } from 'routes';
import {
  createCertificate, getLevels, getProviders, getTopics, updateCertificate,
} from 'services';
import { getDirtyObject, REGEX } from 'util/helpers';
import { useFastForm, useLocale, useSnackbar } from 'util/hooks';
import { LevelIcon, TopicIcon } from 'assets/icons';

const CertificateDetailsForm = (props) => {
  const {
    certificateDetails = {},
    createMode = false,
  } = props;

  const { certificateId } = useParams();
  const snack = useSnackbar();
  const navigate = useNavigate();
  const { t } = useLocale();

  const onSubmit = async (values) => {
    const payload = {
      ...values,
      level: Number(values.level.id),
      provider: { id: Number(values.provider.id) },
      topic: { id: Number(values.topic.id) },
    };
    const dirtyPayload = getDirtyObject(payload, defaultValues);
    let message = '';

    try {
      if (createMode) {
        await createCertificate(payload);
        message = t('certificates.forms.certificateAdded');
      } else {
        await updateCertificate(dirtyPayload, certificateId);
        message = t('certificates.forms.certificateEdited');
      }

      navigate(`/${PATHS.platformSettings}/${PATHS.certificatesSettings}`);
      snack({
        severity: 'success',
        message,
      });
    } catch (error) {
      error.errors?.forEach((err) => {
        setError(err.property, {
          type: 'api',
          message: err.message,
        });
        if (!err.property) {
          snack({
            severity: 'error',
            message: err.message || t('common.somethingWrong'),
          });
        }
      });
    }
  };

  const defaultValues = {
    name: certificateDetails?.name || '',
    level: certificateDetails?.level || '',
    provider: certificateDetails?.provider || '',
    topic: certificateDetails?.topic || '',
    visibility: certificateDetails?.visibility || false,
  };

  const validationSchema = yup.object({
    name: yup.string()
      .trim()
      .required(t('certificates.forms.validation.enterName'))
      .matches(REGEX.alphaNumericSpaceSpecialCharacter, t('certificates.forms.validation.enterNameEn')),
    level: yup
      .object()
      .nullable(true)
      .required(t('certificates.forms.validation.selectLevel')),
    provider: yup
      .object()
      .nullable(true)
      .required(t('certificates.forms.validation.selectProvider')),
    topic: yup
      .object()
      .nullable(true)
      .required(t('certificates.forms.validation.selectTopic')),
    visibility: yup
      .bool(),
  });

  const {
    control,
    handleSubmit,
    watch,
    setError,
    formState: {
      isSubmitting,
      isDirty,
      isValid,
    },
  } = useFastForm({
    defaultValues,
    validationSchema,
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box>
        <Box sx={{
          display: 'grid',
          rowGap: 5,
        }}
        >
          <Box>
            <FormTextField
              name="name"
              control={control}
              label={t('certificates.forms.certificateName')}
              disabled={isSubmitting}
              fullWidth
            />
          </Box>
          <Box>
            <FormAutocomplete
              name="level"
              control={control}
              icon={<LevelIcon />}
              label={t('certificates.forms.level')}
              fullWidth
              selectFunc={getLevels}
            />
          </Box>
          <Box>
            <FormAutocomplete
              name="provider"
              control={control}
              label={t('certificates.forms.provider')}
              fullWidth
              selectFunc={getProviders}
            />
          </Box>
          <Box>
            <FormAutocomplete
              name="topic"
              control={control}
              fullWidth
              icon={<TopicIcon />}
              label={t('certificates.forms.topic')}
              selectFunc={getTopics}
            />
          </Box>
          <Box>
            <FormCheckbox
              name="visibility"
              control={control}
              disabled={isSubmitting}
              checkboxProps={{
                checked: watch('visibility'),
              }}
              label={(
                <Box>
                  <Typography variant="bodyStandardRegular">
                    {t('certificates.forms.setAsVisible')}
                  </Typography>
                </Box>
              )}
            />
          </Box>
        </Box>
        <Box sx={{
          my: 8,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        >
          <LoadingButton
            type="submit"
            variant="contained"
            size="fixed"
            loading={isSubmitting}
            disabled={isSubmitting || !isDirty || !isValid}
          >
            {createMode ? t('certificates.addCertificate') : t('common.saveChanges')}
          </LoadingButton>
        </Box>
      </Box>
    </form>
  );
};

export default CertificateDetailsForm;
