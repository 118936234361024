import { useTheme } from '@mui/styles';

const CourseSessionIcon = (props) => {
  const { palette } = useTheme();

  const {
    fill = 'none',
    stroke = palette.text.secondary,
  } = props;

  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M9.66776 16.272C11.3431 14.8536 13.3945 13.9526 15.5725 13.6785C15.8292 13.6446 16.0648 13.5187 16.2354 13.324C16.4061 13.1293 16.5001 12.8792 16.5 12.6204V2.57698C16.5002 2.42312 16.4674 2.271 16.4038 2.1309C16.3402 1.99079 16.2473 1.86597 16.1313 1.76485C16.0153 1.66373 15.879 1.58868 15.7316 1.54475C15.5841 1.50083 15.4289 1.48906 15.2765 1.51023C13.2074 1.83065 11.2667 2.71535 9.66776 4.06709C9.47678 4.21457 9.2423 4.29458 9.001 4.29458C8.7597 4.29458 8.52522 4.21457 8.33424 4.06709C6.73461 2.7155 4.79319 1.83124 2.7235 1.51157C2.5712 1.49041 2.41614 1.50215 2.26877 1.54599C2.12139 1.58984 1.98513 1.66478 1.86918 1.76575C1.75323 1.86672 1.66027 1.99138 1.59659 2.13132C1.5329 2.27126 1.49997 2.42323 1.5 2.57698V12.6204C1.49989 12.8792 1.59391 13.1293 1.76455 13.324C1.93519 13.5187 2.1708 13.6446 2.42746 13.6785C4.60616 13.9522 6.65833 14.8533 8.33424 16.272C8.52507 16.4198 8.75962 16.5 9.001 16.5C9.24238 16.5 9.47692 16.4198 9.66776 16.272Z" stroke={stroke} strokeWidth="1.20715" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default CourseSessionIcon;
