import { useTheme } from '@mui/styles';

const LeaderboardIconFilled = (props) => {
  const { palette } = useTheme();

  const {
    fill = palette.common.darkGray,
    stroke = palette.common.darkGray,
    ...rest
  } = props;

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g clipPath="url(#clip0_312_25315)">
        <path d="M1.5 10L5.5 10V21H1.5C1.23478 21 0.98043 20.8946 0.792893 20.7071C0.605357 20.5196 0.5 20.2652 0.5 20V11C0.5 10.7348 0.605357 10.4804 0.792893 10.2929C0.980429 10.1054 1.23478 10 1.5 10Z" fill={fill} stroke={stroke} />
        <path d="M18.5 14H22.5C22.7652 14 23.0196 14.1054 23.2071 14.2929C23.3946 14.4804 23.5 14.7348 23.5 15V20C23.5 20.2652 23.3946 20.5196 23.2071 20.7071C23.0196 20.8946 22.7652 21 22.5 21L18.5 21L18.5 14Z" fill={fill} stroke={stroke} />
        <path d="M9 3H15C15.2652 3 15.5196 3.10536 15.7071 3.29289C15.8946 3.48043 16 3.73478 16 4V21L8 21L8 4C8 3.73478 8.10536 3.48043 8.29289 3.29289C8.48043 3.10536 8.73478 3 9 3Z" fill={fill} stroke={stroke} />
      </g>
      <defs>
        <clipPath id="clip0_312_25315">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LeaderboardIconFilled;
