import { useCustomInfiniteQuery } from 'reactQuery';
import { getPostsByAdmin } from 'services';

const useInfiniteAdminPosts = (queryProps = {}) => {
  const { queryKeyOptions = {}, ...rest } = queryProps;

  const query = useCustomInfiniteQuery({
    queryKey: ['infinite-admin-posts', queryKeyOptions],
    queryFn: getPostsByAdmin,
    ...rest,
  });

  return { ...query };
};

export default useInfiniteAdminPosts;
