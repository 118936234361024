import END_POINTS from '../../endPoints';
import api from '../../api';

const getGeneralSettings = () => api(
  END_POINTS.generalSettings,
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default getGeneralSettings;
