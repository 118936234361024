import {
  FormControl,
  FormHelperText,
} from '@mui/material';
import { Autocomplete } from 'components/molecules';
import { useController } from 'react-hook-form';

const FormAutocomplete = (props) => {
  const { field, fieldState } = useController(props);
  const { onChange } = field;
  const { error } = fieldState;

  const hasErrored = Boolean(error);

  const onFieldChange = (event, newValue) => {
    onChange(newValue || '');
  };

  return (
    <FormControl
      sx={{ width: 1 }}
      error={hasErrored}
    >
      <Autocomplete
        {...field}
        onChange={onFieldChange}
        error={hasErrored}
        {...props}
      />
      {hasErrored && <FormHelperText>{error?.message}</FormHelperText>}
    </FormControl>
  );
};

export default FormAutocomplete;
