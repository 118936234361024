import { useParams } from 'react-router-dom';

import { getClubRank } from 'services';
import useCustomQuery from 'reactQuery/useCustomQuery';

const useClubProfileRank = (queryProps = {}) => {
  const { clubId } = useParams();

  const query = useCustomQuery({
    queryKey: [`club-profile-rank-${clubId}`],
    queryFn: () => getClubRank(clubId),
    ...queryProps,
  });

  return { ...query };
};

export default useClubProfileRank;
