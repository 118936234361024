import {
  Box, Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import {
  FormAvatar,
  FormNumberField,
  FormTextField,
} from 'components/form';
import PropTypes from 'prop-types';
import { updateUserProfile, updateStudentProfile } from 'services';
import * as Yup from 'yup';
import { useSnackbar, useFastForm, useLocale } from 'util/hooks';
import { getDirtyObject, REGEX } from 'util/helpers';
import { useCallback, useMemo, useState } from 'react';
import { AUTH_ROLES } from 'assets/constants/userRoles';

const EditUserForm = (props) => {
  const {
    user,
  } = props;

  const { username } = user;

  const { t } = useLocale();
  const snack = useSnackbar();
  const [userData, setUserData] = useState(user);
  const isStudent = user.roleName === AUTH_ROLES.member
    || user.roleName === AUTH_ROLES.leader;

  const onSaveForm = useCallback(async (values) => {
    try {
      const dirtyPayload = getDirtyObject(values, defaultValues);
      if (isStudent) {
        const newUserData = await updateStudentProfile(dirtyPayload, userData.username);
        setUserData(newUserData);
      } else {
        const newUserData = await updateUserProfile(dirtyPayload, userData.username);
        setUserData(newUserData);
      }
      snack({
        message: t('editProfile.profileUpdated'),
        severity: 'success',
      });
    } catch (error) {
      snack({
        message: error.errors[0].message || t('common.errorMessage'),
        severity: 'error',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, userData.username]);

  const defaultValues = useMemo(() => {
    const {
      avatar,
      studentId,
      firstNameEn,
      lastNameEn,
      mobile,
    } = userData;
    return {
      avatar: avatar || null,
      studentId: studentId || '',
      firstNameEn: firstNameEn || '',
      lastNameEn: lastNameEn || '',
      mobile: mobile || '',
    };
  }, [userData]);

  const validationSchema = useMemo(() => Yup.object({
    avatar: Yup.string()
      .nullable(),
    studentId: Yup.string()
      .when('isStudent', {
        is: (value) => isStudent,
        then: Yup.string().required(t('editProfile.validation.enterStudentId')),
      }),
    firstNameEn: Yup.string()
      .required(t('editProfile.validation.enterFirstName'))
      .matches(REGEX.alphabetEnWithSpace, t('registration.validation.enterEnglishLetters'))
      .min(3, t('registration.validation.minFirstName')),
    lastNameEn: Yup.string()
      .required(t('editProfile.validation.enterLastName'))
      .matches(REGEX.alphabetEnWithSpace, t('registration.validation.enterEnglishLetters'))
      .min(3, t('registration.validation.minLastName')),
    mobile: Yup.string()
      .required(t('editProfile.validation.enterMobile'))
      .matches(REGEX.saudiMobileNumberWZero, t('registration.validation.mobileValidation'))
      .length(10, t('registration.validation.mobileLengthValidation')),
  }), [isStudent, t]);

  const {
    control,
    handleSubmit,
    formState: {
      isSubmitting,
      isDirty,
      isValid,
    },
  } = useFastForm({
    defaultValues,
    validationSchema,
  });

  const personalInformationForm = (
    <form onSubmit={handleSubmit(onSaveForm)}>
      <FormAvatar
        name="avatar"
        avatar={defaultValues.avatar}
        username={username}
        disabled={isSubmitting}
        control={control}
        uploadButtonLabel={t('common.upload')}
      />
      <Box>
        {isStudent
          && (
            <Box sx={{ mb: 7 }}>
              <FormTextField
                label={t('registration.studentId')}
                name="studentId"
                autoComplete="studentId"
                disabled={isSubmitting}
                fullWidth
                control={control}
              />
            </Box>
          )}
        <Box sx={{ mb: 7 }}>
          <FormTextField
            label={t('registration.firstName')}
            name="firstNameEn"
            autoComplete="firstNameEn"
            disabled={isSubmitting}
            fullWidth
            control={control}
          />
        </Box>
        <Box sx={{ mb: 7 }}>
          <FormTextField
            label={t('registration.lastName')}
            name="lastNameEn"
            autoComplete="lastNameEn"
            disabled={isSubmitting}
            fullWidth
            control={control}
          />
        </Box>
        <Box sx={{ mb: 7 }}>
          <FormNumberField
            label={t('registration.mobile')}
            name="mobile"
            autoComplete="mobile"
            disabled={isSubmitting}
            fullWidth
            startWithZero
            control={control}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <LoadingButton
          type="submit"
          variant="contained"
          size="medium"
          disabled={!isDirty || isSubmitting || !isValid}
          loading={isSubmitting}
        >
          {t('common.saveChanges')}
        </LoadingButton>
      </Box>
    </form>
  );

  return (
    <>
      <Typography
        variant="h6"
        sx={{
          color: 'common.darkGray',
          display: 'flex',
          justifyContent: 'center',
          my: 7,
        }}
      >
        {t('editProfile.editProfile')}
      </Typography>
      {personalInformationForm}
    </>
  );
};

EditUserForm.propTypes = {
  user: PropTypes.oneOfType([PropTypes.object]),
};

EditUserForm.defaultProps = {
  user: {},
};

export default EditUserForm;
