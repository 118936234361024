import { useParams } from 'react-router-dom';
import { useCustomQuery } from 'reactQuery';
import { getInterestDetails } from 'services';

const useInterestDetails = () => {
  const { interestId } = useParams();

  const query = useCustomQuery({
    queryKey: [`interest-details-${interestId}`],
    queryFn: () => getInterestDetails(interestId),
  });

  return { ...query };
};

export default useInterestDetails;
