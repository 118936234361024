import { useTheme } from '@mui/styles';
import { FlagyardIcon } from 'assets/icons';
import {
  Box,
  Card,
  Stack,
  Button,
  Typography,
} from '@mui/material';

import { connectFlagyardAccount } from 'services';
import { useLocale, useSnackbar } from 'util/hooks';

const ConnectFlagyardAccount = () => {
  const { t } = useLocale();
  const { palette } = useTheme();
  const snack = useSnackbar();

  const onConnect = async () => {
    try {
      const { url } = await connectFlagyardAccount();
      window.open(url, '_self');
    } catch (error) {
      snack({
        message: error?.message || error?.errors?.[0]?.message || t('common.somethingWrong'),
        severity: 'error',
      });
    }
  };

  return (
    <Card sx={{ p: 5, borderRadius: 0 }}>
      <Box mb={5}>
        <Box>
          <Typography variant="h6">
            {t('common.flagyard')}
          </Typography>
        </Box>
      </Box>
      <Stack
        spacing={5}
        alignItems="center"
      >
        <FlagyardIcon />
        <Stack
          spacing={1}
          alignItems="center"
          textAlign="center"
        >
          <Typography variant="bodyStandardMedium">
            {t('flagyard.header')}
          </Typography>
          <Typography variant="bodySmallRegular">
            {t('flagyard.description')}
          </Typography>
        </Stack>
        <Button
          variant="outlined"
          sx={{
            borderRadius: 5,
            color: palette.common.flagyardRed,
            borderColor: palette.common.flagyardRed,
            ':hover': {
              borderColor: palette.common.flagyardRed,
            },
          }}
          size="regular"
          onClick={onConnect}
        >
          {t('flagyard.linkYourAccount')}
        </Button>
      </Stack>
    </Card>
  );
};

export default ConnectFlagyardAccount;
