import { useTheme } from '@mui/styles';

const OutlinedCheckIcon = (props) => {
  const { palette } = useTheme();

  const {
    fill = 'none',
    stroke = palette.common.turquoiseSeaweedGreen,
  } = props;

  return (
    <svg width="21" height="20" viewBox="0 0 14 14" fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="7" cy="7" r="6.65" stroke={stroke} strokeWidth="0.7" />
    </svg>
  );
};

export default OutlinedCheckIcon;
