import { useNavigate } from 'react-router-dom';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { AUTH_ROLES } from 'assets/constants/userRoles';
import { PATHS } from 'routes';
import { getMyInfo, logoutService } from 'services';
import { authAtom, managementModeAtom } from 'recoil/atoms';
import { removeItem, setItem, storageKeys } from 'util/localStorage';

const useAuth = () => {
  const [authState, setAuthState] = useRecoilState(authAtom);
  const resetAuth = useResetRecoilState(authAtom);
  const resetManagementMode = useResetRecoilState(managementModeAtom);
  const { user: currentUser, isLoggedIn } = authState;
  const { userRole } = currentUser;

  const navigate = useNavigate();

  // ==== Login ==== //
  const login = (data) => {
    const {
      user,
      accessToken,
    } = data;

    setAuthState({
      isLoggedIn: !!accessToken,
      accessToken,
      user: {
        ...user,
        avatar: String(user.avatar),
        userRole: user.roleName,
        isActive: user.status === 'active',
      },
    });
    setItem(storageKeys.accessToken, accessToken);
    return user;
  };

  // ==== Refresh current user ==== //
  const refreshUser = async () => {
    if (isLoggedIn) {
      const refreshedUserData = await getMyInfo();

      setAuthState({
        ...authState,
        user: {
          ...authState.user,
          ...refreshedUserData,
          avatar: String(refreshedUserData.avatar),
          userRole: refreshedUserData.roleName,
          isActive: refreshedUserData.status === 'active',
        },
      });
    }
  };

  /**
   *  ==== Update current user data ====
   * @param {*} newUser - new user data
   */
  const updateCurrentUser = (newUser) => {
    setAuthState({
      ...authState,
      user: {
        ...authState.user,
        ...newUser,
      },
    });
  };

  // ==== Logout ==== //
  const logout = () => {
    logoutService();
    removeItem(storageKeys.accessToken);
    resetAuth();
    resetManagementMode();
    navigate(PATHS.root);
  };

  const roleBooleans = {
    isAdmin: userRole === AUTH_ROLES.admin,
    isMember: userRole === AUTH_ROLES.member,
    isStudent: userRole === AUTH_ROLES.member || userRole === AUTH_ROLES.leader,
    isLeader: userRole === AUTH_ROLES.leader,
    isClubManager: userRole === AUTH_ROLES.clubManager,
    isContentCreator: userRole === AUTH_ROLES.contentCreator,
  };

  return {
    authState,
    // setAuthState,
    // resetAuth,
    currentUser,
    isLoggedIn,
    login,
    logout,
    refreshUser,
    updateCurrentUser,
    ...roleBooleans,
  };
};

export default useAuth;
