import DOMPurify from 'dompurify';
import {
  CardContent,
  Typography,
} from '@mui/material';

import Linkify from 'react-linkify';

const ViewShareKnowledgeCard = (props) => {
  const {
    content = '',
  } = props;

  return (
    <CardContent sx={{
      display: 'flex',
      flexFlow: 'column wrap',
      px: 1,
    }}
    >
      <Typography
        variant="bodySmallRegular"
        sx={{
          mb: 1,
          color: 'common.darkGray',
          textTransform: 'none',
        }}

      >
        <Linkify>
          {DOMPurify.sanitize(content)}
        </Linkify>
      </Typography>
    </CardContent>
  );
};

export default ViewShareKnowledgeCard;
