import { useTheme } from '@mui/styles';

const LikeFilledIcon = (props) => {
  const { palette } = useTheme();

  const {
    fill = palette.common.pinkishRed,
  } = props;

  return (
    <svg width="18" height="18" viewBox="0 0 22 20" fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M20.1782 10.794L20.1912 10.808L10.9992 20L1.80724 10.808L1.82024 10.794C0.752801 9.54955 0.195078 7.94768 0.258826 6.30938C0.322573 4.67109 1.00308 3.1174 2.16399 1.95966C3.32489 0.801913 4.88043 0.125644 6.5189 0.0663647C8.15736 0.00708541 9.7577 0.569175 10.9992 1.64C12.2408 0.569175 13.8411 0.00708541 15.4796 0.0663647C17.118 0.125644 18.6736 0.801913 19.8345 1.95966C20.9954 3.1174 21.6759 4.67109 21.7396 6.30938C21.8034 7.94768 21.2457 9.54955 20.1782 10.794Z" />
    </svg>
  );
};
export default LikeFilledIcon;
