import END_POINTS from '../endPoints';
import api from '../api';

const getSharePostCount = (id) => api(
  END_POINTS.getPostShareCount(id),
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default getSharePostCount;
