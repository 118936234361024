import END_POINTS from '../endPoints';
import api from '../api';

const getProfilePoints = (username, options) => api(
  END_POINTS.profilePoints(username),
  options,
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default getProfilePoints;
