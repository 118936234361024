import { useTheme } from '@mui/styles';
import { useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { PATHS } from 'routes';
import { useAuth, useLocale } from 'util/hooks';
import { useProfilePoints, useProfileCertificates } from 'reactQuery/queries';
import { POINT_TYPES } from 'assets/constants/points';

import { PointsWithShadowIcon } from 'assets/icons';
import EmptyCertificatesPlaceholder from './sections/EmptyCertificatesPlaceholder';
import CertificateList from './sections/CertificateList';

const ProfileCertificates = () => {
  const { t } = useLocale();
  const { palette } = useTheme();
  const { username } = useParams();
  const { currentUser = {} } = useAuth();

  const isOwnProfile = username === currentUser.username;

  const { data: { totalPoints: certificatePoints = 0 } = {} } = useProfilePoints({
    queryKeyOptions: {
      searchOptions: {
        type: POINT_TYPES.ADD_CERTIFICATE,
      },
    },
    options: {
      suspense: false, // prevent points from holding render
    },
  });

  const {
    data = {},
    refetch: refetchCertificates = () => { },
  } = useProfileCertificates();

  const { items: certificates = [] } = data;
  const isEmptyCertificates = !certificates?.length;

  return (
    <Card
      sx={{
        p: 5,
        borderRadius: 0,
      }}
    >
      <Box sx={{
        width: 1,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
      >
        <Box>
          <Typography variant="h6">
            {t('common.certificates')}
          </Typography>
        </Box>
        {(isOwnProfile && !isEmptyCertificates) ? (
          <Box>
            <Button
              component={Link}
              to={PATHS.addProfileCertificate}
            >
              {`+ ${t('certificates.addCertificate')}`}
            </Button>
          </Box>
        ) : null}
      </Box>
      {!isEmptyCertificates && (
        <Box>
          <Stack spacing={1} my={5}>
            <Typography variant="bodySmallMedium">
              {t('common.points')}
            </Typography>
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
            >
              <PointsWithShadowIcon
                width="20"
                height="20"
                innerFill={palette.common.vividOrange}
                rectFill={palette.common.vividOrange}
              />
              <Typography variant="bodyStandardRegular">
                {certificatePoints || 0}
              </Typography>
            </Stack>
          </Stack>
        </Box>
      )}
      {isEmptyCertificates
        ? <EmptyCertificatesPlaceholder />
        : (
          <CertificateList
            certificates={certificates}
            refetchCertificates={refetchCertificates}
          />
        )}
    </Card>
  );
};

export default ProfileCertificates;
