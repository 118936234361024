import { getProviders } from 'services';
import { useCustomQuery } from 'reactQuery';

const useProviders = () => {
  const query = useCustomQuery({
    queryKey: ['providers'],
    queryFn: getProviders,
  });

  return { ...query };
};

export default useProviders;
