const PERMISSIONS = {
  // User permissions
  userViewAll: 'userViewAll',
  userViewOwn: 'userViewOwn',
  userUpdateOwn: 'userUpdateOwn',
  userViewOne: 'userViewOne',
  userCreate: 'userCreate',
  userUpdate: 'userUpdate',
  userDelete: 'userDelete',
  userStatus: 'userStatus',
  userInvite: 'userInvite',
  userSetAsGraduate: 'userSetAsGraduate',

  // Country permissions
  cityCreate: 'cityCreate',
  cityUpdate: 'cityUpdate',
  cityDelete: 'cityDelete',

  // Post permissions
  postView: 'postView',
  postViewAll: 'postViewAll',
  postCreate: 'postCreate',
  postUpdate: 'postUpdate',
  postDelete: 'postDelete',
  postPublish: 'postPublish',
  postUnpublish: 'postUnpublish',
  postClubView: 'postClubView',
  postReviewComment: 'postReviewComment',
  postRequestApproval: 'postRequestApproval',
  postReject: 'postReject',
  postRevised: 'postRevised',

  // Club permissions
  clubView: 'clubView',
  clubCreate: 'clubCreate',
  clubUpdate: 'clubUpdate',
  clubDelete: 'clubDelete',

  // Topic permissions
  topicView: 'topicView',
  topicCreate: 'topicCreate',
  topicUpdate: 'topicUpdate',
  topicDelete: 'topicDelete',
  topicControlVisibility: 'topicControlVisibility',

  // Tag permissions
  tagView: 'tagView',
  tagCreate: 'tagCreate',
  tagUpdate: 'tagUpdate',
  tagDelete: 'tagDelete',
  tagControlVisibility: 'tagControlVisibility',

  // Interest permissions
  interestView: 'interestView',
  interestCreate: 'interestCreate',
  interestUpdate: 'interestUpdate',
  interestDelete: 'interestDelete',
  interestControlVisibility: 'interestControlVisibility',

  // Certificate permissions
  certificateView: 'certificateView',
  certificateCreate: 'certificateCreate',
  certificateUpdate: 'certificateUpdate',
  certificateDelete: 'certificateDelete',
  certificateControlVisibility: 'certificateControlVisibility',

  // Provider permissions
  providerView: 'providerView',
  providerCreate: 'providerCreate',
  providerUpdate: 'providerUpdate',
  providerDelete: 'providerDelete',
  providerControlVisibility: 'providerControlVisibility',

  // Setting permissions
  settingView: 'settingView',
  settingCreate: 'settingCreate',
  settingUpdate: 'settingUpdate',
  settingDelete: 'settingDelete',
  settingToggle: 'settingToggle',

  avatarDelete: 'avatarDelete',
  // Role permissions
  roleViewAll: 'roleViewAll',
  roleViewOne: 'roleViewOne',
  roleCreate: 'roleCreate',
  roleUpdate: 'roleUpdate',
  roleDelete: 'roleDelete',
  roleAssignToUser: 'roleAssignToUser',

  // Permission permissions
  permissionViewAll: 'permissionViewAll',
  permissionViewOne: 'permissionViewOne',
  permissionAssignToRole: 'permissionAssignToRole',

  // Courses permissions
  courseViewAll: 'courseViewAll',
  courseViewOne: 'courseViewOne',
  courseViewMy: 'courseViewMy',
  courseViewCreatedBy: 'courseViewCreatedBy',
  courseViewManagedBy: 'courseViewManagedBy',
  courseRegister: 'courseRegister',
  courseCreateLive: 'courseCreateLive',
  courseDelete: 'courseDelete',
  courseUpdate: 'courseUpdate',
  courseAttend: 'courseAttend',
  courseStartSession: 'courseStartSession',
  courseEndSession: 'courseEndSession',
  courseInviteUser: 'courseInviteUser',
  courseAcceptsInvitation: 'courseAcceptsInvitation',
  courseDrop: 'courseDrop',
  courseRevoke: 'courseRevoke',
  courseRegistrantsExportViewMobileNumber: 'courseRegistrantsExportViewMobileNumber',
  registrantsView: 'registrantsView',
  registrantsExport: 'registrantsExport',

  // Nafath permissions
  nafathVerify: 'nafathVerify',

  courseRequestApproval: 'courseRequestApproval',
  courseReject: 'courseReject',
  courseCancel: 'courseCancel',
  courseComplete: 'courseComplete',
  coursePublish: 'coursePublish',
  courseUnpublished: 'courseUnpublished',

  // Sessions permissions
  sessionCreate: 'sessionCreate',
  sessionViewCreatedBy: 'sessionViewCreatedBy',
  attendeesView: 'attendeesView',
  sessionViewHostToken: 'sessionViewHostToken',
};

export default PERMISSIONS;
