import { useState } from 'react';
import { PATHS } from 'routes';
import _ from 'lodash';

import {
  Box,
  Card,
  Alert,
  Button,
  IconButton,
  Typography,
  CardContent,
  CardActions,
} from '@mui/material';
import {
  EditIcon,
  VimeoIcon,
  TrashIcon,
  PlayVideoIcon,
  UpdateLinkIcon,
  SessionDateIcon,
  SessionTimeIcon,
} from 'assets/icons';

import { Status } from 'components/molecules';
import { localizedDate, localizedTime } from 'util/helpers';
import {
  useAuth, useLocale,
  useModal, useSnackbar,
} from 'util/hooks';
import { useTheme } from '@mui/styles';
import { startSession } from 'services';
import { LoadingButton } from '@mui/lab';
import MODAL_KEYS from 'assets/constants/modalKeys';
import { STATUS_NAMES } from 'assets/constants/statuses';

const SessionCard = (props) => {
  const {
    createdById = '',
    managedById = '',
    sessionDetails = {},
    courseDetails = {},
    fetchCourseDetails = () => { },
  } = props;

  const {
    runningSession = {},
    id: courseId = '',
    isCourseUnderReview = false,
    isCoursePublished = false,
    isCourseRunning = false,
    isCourseDraft = false,
  } = courseDetails;

  const [isLoading, setIsLoading] = useState(false);

  const { isAdmin, isContentCreator, currentUser } = useAuth();
  const { language, t } = useLocale();
  const { addModal } = useModal();
  const { palette } = useTheme();
  const snack = useSnackbar();

  const {
    id: sessionId = '',
    title = '',
    endAt = '',
    startAt = '',
    description = '',
    sessionNumber = '',
  } = sessionDetails;

  const isAuthor = currentUser.id === createdById;
  const isManagedBy = currentUser.id === managedById;

  const isAuthorized = isAdmin || (isContentCreator && (isAuthor || isManagedBy));
  const canManage = isAdmin
    || ((isCoursePublished
      || isCourseRunning
      || isCourseDraft
      || isCourseUnderReview) && isAuthorized);

  const canStartSession = (isCoursePublished || isCourseRunning) && isAuthorized;
  const isSessionRunning = (runningSession?.id === sessionId) && runningSession?.isRunning;

  const startDate = localizedDate(language, startAt);
  const startTime = localizedTime(startAt);
  const endTime = localizedTime(endAt);

  const handleUpdateSessionRecording = () => {
    addModal({
      key: MODAL_KEYS.updateSessionRecording,
      props: {
        sessionId,
        refetchCourse: fetchCourseDetails,
      },
    });
  };

  const handleEditSession = () => {
    addModal({
      key: MODAL_KEYS.sessionDetails,
      props: {
        createMode: false,
        sessionDetails,
      },
    });
  };

  const handleDeleteSession = () => {
    addModal({
      key: MODAL_KEYS.deleteSession,
      props: {
        sessionId,
      },
    });
  };

  const onSubmit = async () => {
    setIsLoading(true);

    try {
      await startSession(courseId, sessionId);
      !isSessionRunning
        && (
          snack({
            message: t('sessions.sessionStarted'),
            severity: 'success',
          })
        );
      window.open(`/${PATHS.courses}/${courseId}/session/${sessionId}`, '_blank', 'noopener,noreferrer');
    } catch (error) {
      snack({
        message: error?.message || error?.errors?.[0]?.message || t('common.somethingWrong'),
        severity: 'error',
      });
    }
    setIsLoading(false);
  };

  const renderTime = () => (
    <>
      <Box sx={{ height: 34, width: 34 }}>
        <SessionTimeIcon />
      </Box>
      <Typography
        sx={{ ml: 2 }}
        variant="bodySmallRegular"
      >
        {`${startTime} - ${endTime}`}
      </Typography>
    </>
  );

  const sessionActions = (
    <CardActions sx={{
      display: 'flex',
      flexDirection: { xs: 'column', sm: 'row' },
      justifyContent: { xs: 'start', sm: 'end' },
      alignItems: { xs: 'end', sm: 'start' },
      pb: 0,
    }}
    >
      {canStartSession
        && (
          <Box sx={{
            py: 1,
            mr: { xs: 0, sm: 2 },
            display: 'flex',
            justifyContent: 'center',
          }}
          >
            <LoadingButton
              variant="outlined"
              color="primary"
              onClick={onSubmit}
              loading={isLoading}
              startIcon={(
                <PlayVideoIcon
                  width="10"
                  height="10"
                  fill={palette.common.turquoiseSeaweedGreen}
                />
              )}
              sx={{
                py: 0,
                px: 3,
                fontSize: 13,
                borderRadius: 4,
              }}
            >
              {isSessionRunning
                ? t('common.join') : t('common.start')}
            </LoadingButton>
          </Box>
        )}
      <Box sx={{ p: 1 }}>
        <IconButton
          disableRipple
          onClick={handleDeleteSession}
          aria-label="delete"
          sx={{ p: 0 }}
        >
          <TrashIcon
            width="24"
            height="24"
          />
        </IconButton>
      </Box>
      <Box sx={{ p: 1 }}>
        <IconButton
          disableRipple
          onClick={handleEditSession}
          aria-label="edit"
          sx={{ p: 0 }}
        >
          <EditIcon
            width="24"
            height="24"
          />
        </IconButton>
      </Box>
    </CardActions>
  );

  return (
    <Box>
      <Typography
        variant="bodySmallMedium"
        color="common.couchGrey"
      >
        {`${t('common.sessionNumber')}${sessionNumber}`}
      </Typography>
      {sessionDetails?.vimeoRecord?.status === STATUS_NAMES.failed
        && (
          <Alert
            severity="error"
            icon={false}
            sx={{
              backgroundColor: 'common.transparentRed',
              borderRadius: '15px 15px 0 0',
              display: 'flex',
              justifyContent: 'center',
              py: 0,
              mt: 4,
              mb: -2,
              maxHeight: { xs: 55, md: 40 },
              height: { xs: 55, md: 40 },
            }}
          >
            <Box>
              <Box sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                padding: 0,
              }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography
                    variant="bodySmallRegular"
                    sx={{ color: 'common.pinkishRed' }}
                  >
                    {t('sessions.sessionFailedUploading')}
                  </Typography>
                </Box>
                <Button
                  onClick={handleUpdateSessionRecording}
                  sx={{
                    p: 0,
                    pl: { xs: 0, md: 1 },
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                    display: 'flex',
                    alignItems: 'baseline',
                  }}
                >
                  <Box sx={{ display: 'flex' }}>
                    <UpdateLinkIcon />
                  </Box>
                  <Box sx={{
                    pl: 1,
                    display: 'flex',
                  }}
                  >
                    <Typography
                      variant="bodySmallBold"
                      sx={{ color: 'common.pinkishRed' }}
                    >
                      {t('sessions.updateSessionRecordingId')}
                    </Typography>
                  </Box>
                </Button>
              </Box>
            </Box>
          </Alert>
        )}
      <Card
        sx={{
          px: 4,
          pb: 6,
          border: 'solid 1px',
          borderColor: 'common.silverGrey',
        }}
      >
        <Box sx={{
          display: 'flex',
        }}
        >
          <CardContent
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              pb: 0,
            }}
          >
            <Box>
              {!_.isEmpty(sessionDetails?.vimeoRecord?.status)
                && (
                  <Box sx={{
                    mb: 4,
                    ml: 1,
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    alignItems: { xs: 'start', sm: 'center' },
                  }}
                  >
                    <Box sx={{ display: 'flex' }}>
                      <VimeoIcon />
                      <Box sx={{
                        mx: 1,
                        display: 'flex',
                        alignItems: 'center',
                      }}
                      >
                        <Typography variant="bodySmallRegular">
                          {`${t('sessions.vimeoUploadingStatus')}:`}
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      <Status
                        status={sessionDetails?.vimeoRecord?.status}
                        label={t(`statuses.${_.camelCase(sessionDetails?.vimeoRecord?.status)}`)}
                      />
                    </Box>
                  </Box>
                )}
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                flexFlow: 'row wrap',
                width: 1,
                mb: 6,
              }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mr: 10,
                    mb: 3,
                  }}
                >
                  <Box sx={{ height: 34, width: 34 }}>
                    <SessionDateIcon />
                  </Box>
                  <Typography
                    sx={{ ml: 2 }}
                    variant="bodySmallRegular"
                  >
                    {startDate}
                  </Typography>
                </Box>
                <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  mb: 3,
                }}
                >
                  {renderTime()}
                </Box>
              </Box>
              <Typography
                variant="bodySmallMedium"
                sx={{ display: 'block', mb: 4, mt: 3 }}
              >
                {title}
              </Typography>
              <Typography
                sx={{ display: 'block', pl: 4 }}
                variant="bodySmallRegular"
              >
                {`•  ${description}`}
              </Typography>
            </Box>
          </CardContent>
          {canManage && sessionActions}
        </Box>
      </Card>
    </Box>
  );
};

export default SessionCard;
