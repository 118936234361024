import { useTheme } from '@mui/styles';

const BookmarkFilledIcon = (props) => {
  const { palette } = useTheme();

  const {
    stroke = palette.common.turquoiseSeaweedGreen,
    fill = palette.common.turquoiseSeaweedGreen,
  } = props;
  return (
    <svg width="17" height="20" viewBox="0 0 17 20" fill={fill} stroke={stroke} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M12.0054 3.454C11.3192 3.454 10.7629 4.00454 10.7629 4.68368V18.3332C10.7628 18.5565 10.6403 18.7623 10.4429 18.8706C10.2455 18.979 10.0043 18.9728 9.81279 18.8546L5.79288 16.3657L1.77298 18.8521C1.58186 18.9701 1.34117 18.9765 1.14395 18.8688C0.946722 18.761 0.823869 18.5561 0.822876 18.3332V4.68368C0.822876 2.64627 2.49174 0.994629 4.55038 0.994629H12.0054C14.064 0.994629 15.7329 2.64627 15.7329 4.68368V7.75789C15.7329 8.09746 15.4547 8.37273 15.1116 8.37273H10.7629" strokeWidth="1.26843" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default BookmarkFilledIcon;
