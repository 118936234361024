import {
  Box,
  IconButton,
  Typography,
} from '@mui/material';
import { PlayVideoIcon } from 'assets/icons';
import { courseDefaultImageEn, courseDefaultImageAr } from 'assets/images';
import { PATHS } from 'routes';
import { useLocale } from 'util/hooks';

const SessionStreamContainer = (props) => {
  const { courseDetails = {} } = props;

  const { t, isAr } = useLocale();

  const {
    cover = '',
    id = 0,
    runningSession = {},
  } = courseDetails;

  const coverDefault = isAr ? courseDefaultImageAr : courseDefaultImageEn;

  const joinSession = () => {
    window.open(`/${PATHS.courses}/${id}/session/${runningSession?.id}`, '_blank', 'noopener, noreferrer');
  };

  const renderPlaceholder = (
    <Box sx={{
      height: 360,
      borderRadius: 2,
      display: 'flex',
      justifyContent: 'center',
      backgroundSize: 'cover',
      backgroundImage: `url('${cover || coverDefault}')`,
    }}
    >
      <Box sx={{
        width: 80,
        height: 80,
        borderRadius: 25,
        display: 'flex',
        alignSelf: 'center',
        justifyContent: 'center',
        backgroundColor: 'common.transparentLightGrey',
      }}
      >
        <IconButton
          disableRipple
          aria-label="play"
          onClick={joinSession}
        >
          <PlayVideoIcon width="24" height="26" />
        </IconButton>
      </Box>
      <Box sx={{
        position: 'absolute',
        backgroundColor: 'common.transparentLightGrey',
        borderRadius: 2,
        bottom: 24,
        left: 24,
        py: 2,
        px: 6,
      }}
      >
        <Box>
          <Typography
            variant="bodyMediumRegular"
            color="common.white"
          >
            {runningSession?.title}
          </Typography>
        </Box>
      </Box>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        position: 'absolute',
        backgroundColor: 'common.transparentLightGrey',
        borderRadius: 1,
        left: 24,
        top: 24,
        py: 1,
        px: 4,
      }}
      >
        <Box sx={{
          width: 10,
          height: 10,
          borderRadius: '50%',
          backgroundColor: 'common.turquoiseSeaweedGreen',
        }}
        />
        <Typography
          variant="bodySmallRegular"
          color="common.white"
          sx={{ ml: 1 }}
        >
          {t('sessions.runningNow')}
        </Typography>
      </Box>
    </Box>
  );

  return (
    <Box sx={{ height: 360, position: 'relative' }}>
      {renderPlaceholder}
    </Box>
  );
};

export default SessionStreamContainer;
