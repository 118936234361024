import {
  useState,
} from 'react';
import { useDropzone } from 'react-dropzone';
import { useController } from 'react-hook-form';

import {
  Avatar, Box, CircularProgress, IconButton,
  Typography,
} from '@mui/material';

import { CancelIcon, FilledCheckIcon, UploadIcon } from 'assets/icons';
import { isEmpty } from 'lodash';
import { deleteAttachment, generatePresignedUrl, uploadFileToS3 } from 'services';
import { useLocale, useSnackbar } from 'util/hooks';

const FormUploadMultiMedia = (props) => {
  const {
    attachments = [],
    accept,
    maxFiles = 100,
    courseId = '',
    setError = () => { },
    ...rest
  } = props;

  const { t } = useLocale();
  const snack = useSnackbar();

  const { field, fieldState } = useController(rest);
  const { onChange } = field;
  const { error: fieldError } = fieldState;

  const [uploadedFiles, setUploadedFiles] = useState(attachments);
  const [isUploadLoading, setIsUploadLoading] = useState(false);

  const canUpload = uploadedFiles.length < maxFiles;
  const noUploadedFiles = isEmpty(uploadedFiles);

  const onDeleteAttachment = async (id) => {
    try {
      await deleteAttachment(courseId, id);

      setUploadedFiles(uploadedFiles.filter(
        (file) => file.id !== id,
      ));
      snack({
        message: t('courses.deleteAttachmentMessage'),
        severity: 'success',
      });
    } catch (error) {
      snack({
        message: error?.message || error?.errors?.[0]?.message || t('common.somethingWrong'),
        severity: 'error',
      });
    }
  };

  const uploadToS3 = async (newImage) => {
    setIsUploadLoading(true);
    let result = null;

    try {
      // Step 1 - get url and fileName
      const { uploadUrl, fileName } = await generatePresignedUrl();

      // Step 2 - upload to S3
      await uploadFileToS3(uploadUrl, newImage);

      // Step 3 - update form with fileName
      result = fileName;
    } catch (error) {
      setError({
        type: 'api',
        message: error.message,
      });
      snack({
        message: error.message || t('common.somethingWrong'),
        severity: 'error',
      });
      result = false;
    }
    setIsUploadLoading(false);
    return result;
  };

  // ========= Dropzone =========
  const onDrop = async (acceptedFile, fileRejections) => {
    const reachMax = uploadedFiles.length + acceptedFile.length > maxFiles;
    if (fileRejections?.[0]) {
      setError({
        type: 'dropzone',
        message: fileRejections?.[0]?.errors?.[0]?.message,
      });
    }
    if (reachMax) {
      return;
    }

    const currentFiles = acceptedFile.map(async (file) => {
      const newImage = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });
      const fileName = await uploadToS3(newImage);
      if (fileName) {
        return {
          fileName,
          preview: newImage.preview,
          fileSize: file.size,
          fileOriginalName: file.name,
          fileType: file.type,
        };
      }
      return undefined;
    });

    const filteredCurrentFiles = currentFiles.filter((f) => f);
    const formattedFiles = await Promise.all(filteredCurrentFiles);

    const files = [...uploadedFiles, ...formattedFiles];
    const filteredFiles = files.filter((f) => f);
    if (filteredFiles.length) {
      setUploadedFiles(filteredFiles);
      onChange(filteredFiles);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept,
    maxFiles,
    onDrop,
  });

  const uploadedFilesList = (
    <Box
      sx={{
        pt: 6,
        px: 4,
        borderRadius: canUpload ? ' 0 0 15px 15px' : ' 15px 15px 15px 15px',
        backgroundColor: 'common.ghostlyGrey',
      }}
    >
      {uploadedFiles.map((media) => (
        <Box
          key={media.fileName}
          sx={{
            display: 'flex',
            alignItems: 'center',
            pb: 6,
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
          }}
          >
            <IconButton
              disableRipple
              id={media.fileName}
              onClick={() => onDeleteAttachment(media.id)}
              aria-label="delete"
            >
              <CancelIcon />
            </IconButton>
            <Avatar
              sx={{
                borderRadius: 1,
                width: 70,
                height: 50,
              }}
              variant="square"
              alt={media.fileOriginalName}
              src={media.fileName}
            />
            <Box sx={{ display: 'flex', flexDirection: 'column', mx: 5 }}>
              <Typography variant="bodySmallMedium">
                {media.fileOriginalName.length > 25
                  ? media.fileOriginalName.slice(0, 25).concat('...')
                  : media.fileOriginalName}
              </Typography>
              <Typography variant="bodyMicroRegular">
                {`${media.fileSize} Bytes`}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ mx: 2 }}>
            <FilledCheckIcon />
          </Box>

        </Box>
      ))}
    </Box>
  );

  return (
    <Box sx={(theme) => ({
      border: `1px solid ${fieldError
        ? theme.palette.common.error
        : theme.palette.common.silverGrey}`,
      borderRadius: 2,
    })}
    >
      {canUpload && (
        <Box {...getRootProps()}>
          <input
            {...getInputProps()}
          />
          <Box
            sx={(theme) => ({
              display: 'flex',
              justifyContent: 'center',
              backgroundColor: 'common.white',
              borderRadius: '15px 15px 0 0',
              borderBottom: `1px solid ${theme.palette.common.silverGrey}`,
              p: 5,
            })}
          >
            <IconButton
              disableRipple
              aria-label="upload"
            >
              {isUploadLoading ? <CircularProgress /> : <UploadIcon width="39px" height="37px" />}
            </IconButton>
          </Box>
          <Typography
            variant="bodySmallRegular"
            sx={{
              color: 'text.secondary',
              backgroundColor: 'common.offWhite',
              display: 'flex',
              justifyContent: 'center',
              textAlign: 'center',
              p: 3,
              borderRadius: noUploadedFiles ? ' 0 0 15px 15px' : '0',
            }}
          >
            {t('common.attachmentDescription')}
          </Typography>
        </Box>
      )}
      {!noUploadedFiles && uploadedFilesList}
    </Box>
  );
};

export default FormUploadMultiMedia;
