import END_POINTS from '../../endPoints';
import METHODS from '../../methods';
import api from '../../api';

const createProvider = (data) => api(
  END_POINTS.providers,
  {
    method: METHODS.POST,
    data,
  },
)
  .then((response) => Promise.resolve(response))
  .catch((error) => Promise.reject(error));

export default createProvider;
