import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Card,
  CardActions,
} from '@mui/material';

import {
  useAuth,
  useLocale,
  useModal,
  useShare,
  useSnackbar,
} from 'util/hooks';

import { PATHS } from 'routes';
import { PostHeader } from 'components/organisms';
import { ActionIconWithCount } from 'components/molecules';
import { POST_STATUSES, POST_TYPES } from 'assets/constants/posts';
import { bookmarkPost, getSharePostCount, likePost } from 'services';

import MODAL_KEYS from 'assets/constants/modalKeys';
import SharePostMenu from './SharePostMenu';
import ViewArticleAndVideoCard from './ViewArticleAndVideoCard';
import ViewShareKnowledgeCard from './ViewShareKnowledgeCard';
import CertificateAnnouncementCard from './CertificateAnnouncementCard';

const PostCardContainer = (props) => {
  const {
    postDetails = {},
  } = props;

  const {
    type,
    cover,
    video,
    title,
    status,
    id: postId,
    content,
    authorId,
    likes: postLikes,
    shares: postShares,
    isLiked: isPostLiked,
    bookmarks: postBookmarks,
    trimmedContent,
    isBookmarked: isPostBookmarked,
  } = postDetails;

  const isCertificateAnnouncement = type === POST_TYPES.certificate;
  const isShareKnowledge = type === POST_TYPES.knowledgeShare;
  const isPostPublished = status === POST_STATUSES.PUBLISHED;

  const { isLoggedIn } = useAuth();
  const { addModal } = useModal();
  const navigate = useNavigate();
  const snack = useSnackbar();
  const { t } = useLocale();

  // likes
  const [likesCount, setLikesCount] = useState(postLikes);
  const [updateIsLiked, setUpdateIsLiked] = useState(isPostLiked);
  // bookmarks
  const [bookmarksCount, setBookmarksCount] = useState(postBookmarks);
  const [updateIsBookmarked, setUpdateIsBookmarked] = useState(isPostBookmarked);
  // share
  const [shareCount, setShareCount] = useState(postShares);

  const {
    anchorEl,
    handleOpenMenu,
    handleCloseMenu,
  } = useShare({
    title,
    text: trimmedContent.slice(0, 64),
    url: `${window.location.href}/${postId}`,
  });

  const handleOpenModal = () => {
    addModal({
      key: MODAL_KEYS.joinUs,
    });
  };

  const sharePost = async () => {
    try {
      const { shares } = await getSharePostCount(postId);
      setShareCount(shares);
    } catch ({ errors }) {
      snack({
        message: errors[0].message || t('common.somethingWrong'),
        severity: 'error',
      });
    }
  };

  const toggleLike = async () => {
    try {
      const { likes, isLiked } = await likePost(postId);
      setLikesCount(likes);
      setUpdateIsLiked(isLiked);
    } catch ({ errors }) {
      snack({
        message: errors[0].message || t('common.somethingWrong'),
        severity: 'error',
      });
    }
  };

  const toggleBookmark = async () => {
    try {
      const { bookmarks, isBookmarked } = await bookmarkPost(postId);
      setBookmarksCount(bookmarks);
      setUpdateIsBookmarked(isBookmarked);
      if (isBookmarked) {
        snack({
          message: t('posts.postIsBookmarked'),
          severity: 'success',
        });
      }
    } catch ({ errors }) {
      snack({
        message: errors[0].message || t('common.somethingWrong'),
        severity: 'error',
      });
    }
  };

  const renderCardContent = () => {
    if (isCertificateAnnouncement) {
      return (
        <CertificateAnnouncementCard
          postDetails={postDetails}
        />
      );
    }

    if (isShareKnowledge) {
      return (
        <ViewShareKnowledgeCard
          id={postId}
          content={content}
        />
      );
    }
    return (
      <ViewArticleAndVideoCard
        id={postId}
        type={type}
        cover={cover}
        video={video}
        title={title}
        content={trimmedContent}
      />
    );
  };

  return (
    <Box>
      {/* TODO: allow certificate post to be clickable
         when announcement details is implemented */}
      <Card sx={{
        px: { xs: 4, sm: 6 },
        maxWidth: 1075,
        pt: 2,
        borderRadius: 0,
      }}
      >
        <Box
          onClick={() => {
            if (!isCertificateAnnouncement) {
              navigate(`/${PATHS.posts}/${postId}`);
            }
          }}
          sx={{
            cursor: isCertificateAnnouncement
              ? 'auto'
              : 'pointer',
          }}
        >
          <PostHeader
            postDetails={postDetails}
            isCertificateAnnouncement={isCertificateAnnouncement}
          />
          <Box>
            {renderCardContent()}
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: {
              xs: 'center',
              sm: 'flex-start',
            },
          }}
        >
          {isPostPublished
            && (
              <CardActions sx={{
                width: {
                  xs: 1,
                  sm: 0.25,
                },
              }}
              >
                <ActionIconWithCount
                  count={likesCount}
                  filled={updateIsLiked}
                  type="like"
                  onClick={isLoggedIn ? () => toggleLike()
                    : () => handleOpenModal()}
                />
                <ActionIconWithCount
                  count={bookmarksCount}
                  filled={updateIsBookmarked}
                  type="bookmark"
                  onClick={isLoggedIn ? () => toggleBookmark()
                    : () => handleOpenModal()}
                />
                <ActionIconWithCount
                  count={shareCount}
                  type="share"
                  onClick={handleOpenMenu}
                />
              </CardActions>
            )}
        </Box>
      </Card>
      <SharePostMenu
        postId={postId}
        authorId={authorId}
        anchorEl={anchorEl}
        sharePost={sharePost}
        handleCloseMenu={handleCloseMenu}
      />
    </Box>

  );
};

export default PostCardContainer;
