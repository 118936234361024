/**
 * Stringifies non-string values to string values then stores them
 * @param {*} key item storage key
 * @param {*} value item's value (type: string | object | number | array)
 */
const setItem = (key, value) => {
  try {
    localStorage.setItem(key, stringify(value));
  } catch {
    throw Error(`localStorage error: ${key} was not stored.`);
  }
};

const getItem = (key) => {
  try {
    return localStorage.getItem(key);
  } catch {
    throw Error(`localStorage error: ${key} not found`);
  }
};

const clearLocalStorage = () => {
  try {
    localStorage.clear();
    return true;
  } catch {
    return false;
  }
};

const removeItem = (key) => {
  try {
    localStorage.removeItem(key);
    return true;
  } catch {
    return false;
  }
};

const stringify = (maybeStr) => (typeof maybeStr !== 'string' ? JSON.stringify(maybeStr) : maybeStr);

const storageKeys = {
  user: 'user',
  i18nextLng: 'i18nextLng',
  userDetails: 'userDetails',
  rememberMe: 'rememberMe',
  accessToken: 'accessToken',
  recaptchaToken: 'recaptchaToken',
  twoFactorToken: 'twoFactorToken',
};

export {
  setItem,
  getItem,
  clearLocalStorage,
  removeItem,
  storageKeys,
};
