import { AUTH_ROLES } from 'assets/constants/userRoles';

/**
 * Auth keys
 * 1. null - all users permitted
 * 2. [] - public only, user must be logged out
 * 3. ['role1', 'role2', ...] - only specified roles are permitted
 * */

// All roles
const allRoles = [
  ...Object.values(AUTH_ROLES),
];

const {
  admin,
  clubManager,
  member,
  leader,
  contentCreator,
} = AUTH_ROLES;

const routePermissions = {
  // ** Public - all users permitted
  mainLayout: null,
  landingLayout: null,
  publicLayout: null,
  authenticationLayout: null,
  createPostLayout: null,
  streamLayout: null,
  landing: null,
  verifyEmail: null,
  home: null,
  courses: null,
  leaderboard: null,
  events: null,
  viewPost: null,
  viewSession: null,
  profile: null,
  clubProfile: null,
  clubleadersForm: null,
  cyberhubAramcoCtfForm: null,
  cyberhubAramcoConfirmationForm: null,
  cyberhubAramcoWaitingListForm: null,

  // ** Public - non-users permitted
  login: [],
  register: [],
  acceptInvitation: [],
  forgotPassword: [],
  resetPassword: [],

  // ** Protected (login required)
  article: allRoles,
  video: allRoles,
  updatePost: allRoles,
  createPost: allRoles,
  knowledge: allRoles,
  myPosts: allRoles,
  bookmarks: allRoles,
  addProfileCertificate: [member, leader],
  editProfileCertificate: [member, leader],
  notifications: allRoles,
  viewRecordedSession: allRoles,

  managementLayout: [admin],
  dashboard: [admin],
  invite: [admin],
  users: [admin],
  students: [admin, clubManager],
  clubMembers: [leader],
  admins: [admin],
  clubManagers: [admin],
  contentCreators: [admin],
  platformSettings: [admin],
  generalSettings: [admin],
  topicsSettings: [admin],
  tagsSettings: [admin],
  interestsSettings: [admin],
  providersSettings: [admin],
  certificates: [admin],
  certificatesSettings: [admin],
  addTopic: [admin],
  editTopic: [admin],
  addProvider: [admin],
  editProvider: [admin],
  addTag: [admin],
  editTag: [admin],
  addInterest: [admin],
  editInterest: [admin],
  addCertificate: [admin],
  editCertificate: [admin],
  manageCourses: [admin, contentCreator],
  publicCourseManagement: [contentCreator],
  manageEvents: [admin],
  managePosts: [admin],
  clubPosts: [leader],
  createCourse: [admin, contentCreator],
  courseOverview: [admin, contentCreator],
  nafath: [leader, member],
  emailAuthentication: [leader, member, clubManager],
  emailVerification: [leader, member, clubManager],
  profileSettings: [leader, member],
  accountConnectionValidation: [leader, member],
  manageClubs: [admin, clubManager],
  createClub: [admin],
  editClub: [admin, clubManager],
};

export default routePermissions;
