import { useTheme } from '@mui/styles';

const LinkedInIcon = (props) => {
  const { palette } = useTheme();

  const {
    fill = palette.common.trueBlue,
    ...rest
  } = props;

  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path opacity="0.05" d="M9.3342 12.0554V8.12806C9.3342 7.47122 9.0157 7.07884 8.48176 7.07884C8.16481 7.07884 7.93187 7.25734 7.78992 7.60928C7.78331 7.63417 7.77398 7.73567 7.77787 8.0425L7.77865 12.0554H5.05642V4.66656H7.77865V5.07917C8.17609 4.805 8.66337 4.66656 9.23231 4.66656C11.0006 4.66656 12.056 5.86939 12.056 7.88422L12.0564 12.0554H9.3342ZM1.94531 12.0554V4.66656H3.2897C2.51076 4.66656 1.94531 4.09411 1.94531 3.30506C1.94531 2.51678 2.52009 1.94434 3.31187 1.94434C4.09431 1.94434 4.65159 2.50084 4.66753 3.29728C4.66753 4.09217 4.09237 4.66656 3.30059 4.66656H4.66753V12.0554H1.94531Z" fill={fill} />
      <path d="M14 12.0556C14 13.1297 13.1297 14 12.0556 14H1.94444C0.870722 14 0 13.1297 0 12.0556V1.94444C0 0.870333 0.870722 0 1.94444 0H12.0556C13.1297 0 14 0.870333 14 1.94444V12.0556Z" fill={fill} />
      <path d="M2.33203 5.05523H4.27648V11.6663H2.33203V5.05523ZM3.29842 4.27745H3.28753C2.70731 4.27745 2.33203 3.84501 2.33203 3.30484C2.33203 2.75301 2.71898 2.33301 3.3097 2.33301C3.9012 2.33301 4.26559 2.75301 4.27648 3.30484C4.27648 3.84462 3.9012 4.27745 3.29842 4.27745ZM11.6654 11.6663H9.72092V8.12784C9.72092 7.27306 9.24453 6.68973 8.47959 6.68973C7.89586 6.68973 7.58009 7.08329 7.42686 7.46362C7.37086 7.59973 7.38759 7.97617 7.38759 8.16634V11.6663H5.44314V5.05523H7.38759V6.07256C7.66798 5.63856 8.10703 5.05523 9.23014 5.05523C10.6216 5.05523 11.665 5.93023 11.665 7.88401L11.6654 11.6663Z" fill="white" />
    </svg>
  );
};

export default LinkedInIcon;
