import { useTheme } from '@mui/styles';

const CyberhubMedalIcon = (props) => {
  const { palette } = useTheme();

  const {
    stopColorStart = palette.common.mediumGreen,
    stopColorEnd = palette.common.lightCyan,
    ...rest
  } = props;

  return (
    <svg width="56" height="73" viewBox="0 0 56 73" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path fillRule="evenodd" clipRule="evenodd" d="M44.75 48.6777C50.9942 43.7323 55 36.084 55 27.5C55 12.5883 42.9117 0.5 28 0.5C13.0883 0.5 1 12.5883 1 27.5C1 35.9689 4.89907 43.527 11 48.4773V72.5L27.875 61.25L44.75 72.5V48.6777Z" fill="url(#paint0_linear_1628_22163)" />
      <rect x="1" y="0.5" width="54" height="54" rx="27" fill="white" />
      <rect x="1" y="0.5" width="54" height="54" rx="27" stroke="url(#paint1_linear_1628_22163)" />
      <defs>
        <linearGradient id="paint0_linear_1628_22163" x1="1" y1="0.5" x2="70.6033" y2="44.5538" gradientUnits="userSpaceOnUse">
          <stop stopColor={stopColorStart} />
          <stop offset="1" stopColor={stopColorEnd} />
        </linearGradient>
        <linearGradient id="paint1_linear_1628_22163" x1="1" y1="0.5" x2="57.9372" y2="48.5494" gradientUnits="userSpaceOnUse">
          <stop stopColor={stopColorStart} />
          <stop offset="1" stopColor={stopColorEnd} />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default CyberhubMedalIcon;
