import React from 'react';
import SuspenseLoader from './SuspenseLoader';

const SuspenseWrapper = ({ children, ...rest }) => (
  <React.Suspense fallback={<SuspenseLoader />} {...rest}>
    {children}
  </React.Suspense>
);

export default SuspenseWrapper;
