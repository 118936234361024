import { Box } from '@mui/material';
import { COURSE_PUBLIC_STATUSES } from 'assets/constants/courses';
import { SearchAndFilter } from 'components/helpers';
import { useCourseLevels, useCourseProviders, useCourseTopics } from 'reactQuery/queries';

const PublicCoursesToolBar = () => {
  const filters = [
    {
      filterKey: 'status',
      optionKey: 'name',
      labelKey: 'common.status',
      items: COURSE_PUBLIC_STATUSES,
    },
    {
      filterKey: 'topic.id',
      optionKey: 'id',
      labelKey: 'common.topic',
      async: true,
      getItems: useCourseTopics,
    },
    {
      filterKey: 'provider.id',
      optionKey: 'id',
      labelKey: 'common.provider',
      async: true,
      getItems: useCourseProviders,
    },
    {
      filterKey: 'level.id',
      optionKey: 'id',
      labelKey: 'common.level',
      async: true,
      getItems: useCourseLevels,
    },
  ];

  return (
    <Box>
      <SearchAndFilter filters={filters} />
    </Box>
  );
};

export default PublicCoursesToolBar;
