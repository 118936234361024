import { memo, useRef, useEffect } from 'react';
import { useController } from 'react-hook-form';
import { FormControl, FormHelperText } from '@mui/material';
import { OTPInput } from 'components/molecules';

const FormOTPInput = (props) => {
  const {
    autoFocus = true,
    ...rest
  } = props;

  const { field, fieldState } = useController(rest);
  const { error } = fieldState;

  const hasErrored = Boolean(error);

  const otpFieldRef = useRef(null);
  useEffect(() => {
    if (autoFocus && otpFieldRef.current) {
      // I don't like it either, but I can't think of a better way to get the first input field
      otpFieldRef.current.children?.[0]?.children?.[0]?.children?.[0]?.focus();
    }
  }, [autoFocus, otpFieldRef]);

  return (
    <FormControl error={hasErrored}>
      <OTPInput
        {...field}
        {...rest}
        ref={otpFieldRef}
      />
      {hasErrored && (
        <FormHelperText>
          {hasErrored && error?.message}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default memo(FormOTPInput);
