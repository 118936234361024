import api from 'services/api';
import END_POINTS from 'services/endPoints';

const getLandingStatistics = () => api(
  END_POINTS.landingStatistics,
)
  .then((response) => Promise.resolve(response))
  .catch((error) => Promise.reject(error));

export default getLandingStatistics;
