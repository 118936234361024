const typography = {
  // Base
  fontFamily: 'Montserrat,  sans-serif',

  // Header
  h1: {
    fontSize: '4.625rem',
    fontWeight: 700,
  },
  h2: {
    fontSize: '3.5rem',
    fontWeight: 700,
  },
  h3: {
    fontSize: '2.625rem',
    fontWeight: 600,
  },
  h4: {
    fontSize: '2rem',
    fontWeight: 600,
  },
  h5: {
    fontSize: '1.5rem',
    fontWeight: 600,
  },
  h6: {
    fontSize: '1.125rem',
    fontWeight: 600,
  },

  //  Body
  // Bold
  bodyLargeBold: {
    fontSize: '1.5rem',
    fontWeight: 700,
  },
  bodyMediumBold: {
    fontSize: '1.25rem',
    fontWeight: 700,
  },
  bodyStandardBold: {
    fontSize: '1rem',
    fontWeight: 700,
  },
  bodySmallBold: {
    fontSize: '0.8125rem',
    fontWeight: 700,
    lineHeight: '20px',
  },
  bodyMicroBold: {
    fontSize: '0.625rem',
    fontWeight: 700,
    lineHeight: '20px',
  },
  // Medium
  bodyLargeMedium: {
    fontSize: '1.5rem',
    fontWeight: 600,
  },
  bodyMediumMedium: {
    fontSize: '1.25rem',
    fontWeight: 600,
  },
  bodyStandardMedium: {
    fontSize: '1rem',
    fontWeight: 600,
  },
  bodySmallMedium: {
    fontSize: '0.8125rem',
    fontWeight: 600,
  },
  bodyMicroMedium: {
    fontSize: '0.625rem',
    fontWeight: 600,
  },
  // Regular
  bodyLargeRegular: {
    fontSize: '1.5rem',
    fontWeight: 400,
  },
  bodyMediumRegular: {
    fontSize: '1.25rem',
    fontWeight: 400,
  },
  bodyStandardRegular: {
    fontSize: '1rem',
    fontWeight: 400,
  },
  bodySmallRegular: {
    fontSize: '0.8125rem',
    fontWeight: 400,
  },
  bodyMicroRegular: {
    fontSize: '0.625rem',
    fontWeight: 400,
  },

  // Label
  label1: {
    fontSize: '1.75rem',
    fontWeight: 600,
  },
  label2: {
    fontSize: '1.25rem',
    fontWeight: 600,
  },

  // Button
  button: {
    fontSize: '1rem',
    fontWeight: 600,
    textTransform: 'capitalize',
  },
};

export default typography;
