import { useTheme } from '@mui/styles';

const DurationIcon = (props) => {
  const { palette } = useTheme();

  const {
    fill = 'none',
    stroke = palette.common.darkGray,
  } = props;

  return (
    <svg width="22" height="21" viewBox="0 0 22 21" fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M17.997 10.5869C17.8793 14.9025 14.344 18.3647 10 18.3647C5.58172 18.3647 2 14.783 2 10.3647C2 5.94647 5.58172 2.36475 10 2.36475C13.021 2.36475 15.6509 4.03927 17.0122 6.51077L17.0137 6L19.0025 6.0058C17.3828 2.66683 13.9603 0.364746 10 0.364746C4.47715 0.364746 0 4.8419 0 10.3647C0 15.8876 4.47715 20.3647 10 20.3647C15.4466 20.3647 19.8762 16.0103 19.9975 10.5928L17.997 10.5869Z" fill={stroke} />
      <path d="M18.4485 7.602L14.6481 6.52632L19.7609 3.87674L18.4485 7.602Z" fill={stroke} />
      <line x1="9" y1="5.36475" x2="9" y2="10.3647" stroke={stroke} strokeWidth="2" />
      <line x1="14" y1="11.3647" x2="8" y2="11.3647" stroke={stroke} strokeWidth="2" />
    </svg>
  );
};

export default DurationIcon;
