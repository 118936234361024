import { useCustomInfiniteQuery } from 'reactQuery';
import { getPublicPosts } from 'services';

const useInfinitePublicPosts = (queryProps = {}) => {
  const { queryKeyOptions = {}, ...rest } = queryProps;

  const query = useCustomInfiniteQuery({
    queryKey: ['infinite-public-posts', queryKeyOptions],
    queryFn: getPublicPosts,
    ...rest,
  });

  return { ...query };
};

export default useInfinitePublicPosts;
