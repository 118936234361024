import { useEffect, useState } from 'react';
import {
  Box,
  Link,
  Alert,
  Typography,
} from '@mui/material';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';

import { PATHS } from 'routes';
import { REGEX } from 'util/helpers';
import { emailVerification, getClubs } from 'services';
import { FormAutocomplete, FormClubDomainField } from 'components/form';
import { useFastForm, useLocale, useRecaptcha } from 'util/hooks';

const RegistrationStepOne = (props) => {
  const { userInfo, setUserInfo, handleNextStep } = props;

  const { executeRecaptcha } = useRecaptcha({ action: 'REGISTRATION_STEP_1' });
  const [verificationError, setVerificationError] = useState('');
  const [clubDomains, setClubDomains] = useState([]);

  const { t } = useLocale();

  const { REACT_APP_SUPPORT_EMAIL } = process.env;

  const defaultValues = {
    club: '',
    email: {
      email: '',
      domain: '',
    },
  };

  const validationSchema = Yup.object({
    club: Yup
      .object()
      .nullable(true)
      .required(t('registration.validation.enterUniversity')),
    email: Yup
      .object()
      .shape({
        email: Yup
          .string()
          .matches(REGEX.alphaNumericEnDashDotUnderscore, t('registration.validation.validateEmail')),
      })
      .required(t('email.enterEmail')),
  });

  const {
    control,
    handleSubmit,
    setError,
    watch,
    setValue,
    formState: {
      isSubmitting,
      isValid,
    },
  } = useFastForm({
    defaultValues,
    validationSchema,
  });

  const clubValue = watch('club');

  useEffect(() => {
    // watch club value to update club domains
    setClubDomains(clubValue?.studentDomains);
    setValue('email', { domain: '' });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubValue]);

  const onSubmit = async (values) => {
    const defaultDomain = clubValue.studentDomains[0];
    const emailDomain = values.email.domain || defaultDomain;

    const studentInfo = {
      email: `${values.email.email}@${emailDomain}`,
      clubId: values.club.id,
    };

    try {
      const recaptchaToken = await executeRecaptcha();
      await emailVerification(studentInfo, { recaptchaToken });
      setUserInfo({
        ...userInfo,
        email: studentInfo.email,
        club: studentInfo.clubId,
      });
      setVerificationError(false);
      handleNextStep();
    } catch (error) {
      const { errors } = error;
      if (errors) {
        errors?.forEach((err) => {
          setError(err.property, {
            type: 'api',
            message: err.message,
          });
          if (!err.property) {
            const [firstError] = errors;
            setVerificationError(firstError?.message || t('common.somethingWrong'));
          }
        });
      } else {
        setVerificationError(error.message || t('common.somethingWrong'));
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{
        py: 10,
        px: {
          sm: 15,
          xs: 5,
        },
      }}
      >
        {verificationError && (
          <Alert
            sx={{ mb: 8, width: 1 }}
            severity="error"
          >
            {verificationError}
          </Alert>
        )}
        <Box sx={{
          width: 1,
          textAlign: 'start',
          mb: 7,
        }}
        >
          <Typography
            sx={{
              display: 'flex',
              justifyContent: 'start',
            }}
            variant="bodyStandardRegular"
          >
            {t('registration.enrolledUniversity')}
          </Typography>
        </Box>
        <Box sx={{
          display: 'grid',
          rowGap: 7,
        }}
        >
          <Box>
            <FormAutocomplete
              name="club"
              control={control}
              label={t('registration.university')}
              fullWidth
              selectFunc={getClubs}
            />
          </Box>
          {!!clubValue
            && (
              <Box dir="ltr">
                <FormClubDomainField
                  name="email"
                  control={control}
                  label={t('registration.email')}
                  clubDomains={clubDomains}
                  fullWidth
                />
              </Box>
            )}
        </Box>
        {!!clubValue
          && (
            <LoadingButton
              sx={{
                my: 7,
                display: 'flex',
                alignSelf: 'start',
              }}
              type="submit"
              variant="contained"
              size="regular"
              loading={isSubmitting}
              disabled={isSubmitting || !isValid}
            >
              {t('registration.verifyEmail')}
            </LoadingButton>
          )}
        <Box
          sx={{
            display: 'flex',
            flexFlow: 'row wrap',
            justifyContent: 'center',
            textAlign: 'center',
            mt: 15,
          }}
        >
          <Typography variant="bodyStandardRegular">
            {t('common.haveAccount')}
            &nbsp;
            <Link
              to={`/${PATHS.login}`}
            >
              {t('common.login')}
            </Link>
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexFlow: 'row wrap',
            textAlign: 'center',
            justifyContent: 'center',
            mt: 4,
          }}
        >
          <Typography variant="bodyStandardRegular">
            {t('registration.notListed')}
            &nbsp;
            <Link
              variant="linkButton"
              to="#"
              onClick={(e) => {
                e.preventDefault();
                window.open(`mailto:${REACT_APP_SUPPORT_EMAIL}`, '_blank');
              }}
            >
              {t('registration.contactUs')}
            </Link>
          </Typography>
        </Box>
      </Box>
    </form>
  );
};

export default RegistrationStepOne;
