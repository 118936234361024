import { useEffect, useState } from 'react';

import { SearchField } from 'components/molecules';
import { useDebounce, useLocale } from 'util/hooks';
import { useSearchParams } from 'react-router-dom';

const URLSearchField = (props) => {
  const {
    delay = 300,
    sx,
    ...rest
  } = props;

  const { t } = useLocale();
  const [searchParams, setSearchParams] = useSearchParams();
  const urlSearchValue = searchParams.get('q');

  const [value, setValue] = useState(urlSearchValue || '');
  const debouncedValue = useDebounce({ value, delay });

  useEffect(() => {
    const hasSearchParams = !!searchParams?.toString()?.length;
    if (debouncedValue) {
      searchParams.set('q', debouncedValue);
    } else {
      searchParams.delete('q');
    }
    setSearchParams(searchParams, { replace: hasSearchParams });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  useEffect(() => {
    // Handle when search is updated by other component
    if (urlSearchValue !== value) {
      setValue(urlSearchValue || '');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlSearchValue]);

  const handleClear = () => {
    setValue('');
  };
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <SearchField
      placeholder={t('common.search')}
      sx={{
        width: 1,
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
        ...sx,
      }}
      value={value}
      onChange={handleChange}
      onClear={handleClear}
      {...rest}
    />
  );
};

export default URLSearchField;
