import END_POINTS from '../endPoints';
import METHODS from '../methods';
import api from '../api';

const validateInvitationToken = (data) => api(
  END_POINTS.validateInvitationToken,
  {
    method: METHODS.POST,
    data,
    requireAuth: false,
  },
)
  .then((response) => Promise.resolve(response))
  .catch((error) => Promise.reject(error));

export default validateInvitationToken;
