// eslint-disable-next-line import/prefer-default-export
export const POINT_TYPES = {
  WRITE_POST: 'write-post',
  VERIFY_NAFATH: 'verify-nafath',
  VERIFY_MOBILE: 'verify-mobile',
  COMPLETE_COURSE_SESSION: 'complete-course-session',
  JOIN_CTF: 'join-ctf',
  ADD_CERTIFICATE: 'add_certificate',
  CTF: 'ctf',
};
