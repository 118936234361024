import React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useLocale } from 'util/hooks';
import CertificateCard from '../CertificateCard';

const PREVIEW_LIMIT = 2;

const CertificateList = (props) => {
  const {
    certificates = [],
    refetchCertificates = () => { },
  } = props;

  const [expanded, setExpanded] = React.useState(false);
  const { t } = useLocale();

  const certificateList = certificates?.map((certificate, index) => {
    const isLastElement = index === certificates.length - 1;

    return (
      <Box key={certificate.id}>
        <CertificateCard
          certificate={certificate}
          refetchCertificates={refetchCertificates}
          cardProps={{ sx: { p: 0 } }}
        />
        {!isLastElement && <Divider sx={{ my: 4 }} />}
      </Box>
    );
  });

  const previewList = certificateList?.slice(0, PREVIEW_LIMIT);
  const remainingList = certificateList?.slice(PREVIEW_LIMIT);

  return (
    <Box>
      {previewList}
      {remainingList?.length
        ? (
          <Box>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              {remainingList}
            </Collapse>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                onClick={() => setExpanded((prev) => !prev)}
                sx={{ color: 'common.softBlue' }}
                endIcon={(
                  <ExpandMoreIcon
                    sx={{
                      transform: !expanded ? 'rotate(0deg)' : 'rotate(180deg)',
                      transition: ({ transitions }) => transitions.create('transform', {
                        duration: transitions.duration.shortest,
                      }),
                    }}
                  />
                )}
              >
                {expanded
                  ? t('common.showLess')
                  : t('common.showAll')}
              </Button>
            </Box>
          </Box>
        )
        : null}
    </Box>
  );
};

export default CertificateList;
