import END_POINTS from '../../endPoints';
import METHODS from '../../methods';
import api from '../../api';

const toggleProviderVisibility = (id) => api(
  END_POINTS.toggleProviderVisibility(id),
  {
    method: METHODS.PUT,
  },
)
  .then((response) => Promise.resolve(response))
  .catch((error) => Promise.reject(error));

export default toggleProviderVisibility;
