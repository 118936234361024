import { useTheme } from '@mui/styles';

const PointsWithShadowIcon = (props) => {
  const { palette } = useTheme();

  const {
    fill = 'none',
    innerFill = palette.common.turquoiseSeaweedGreen,
    rectFill = palette.common.turquoiseSeaweedGreen,
    ...rest
  } = props;

  return (
    <svg width="33" height="33" viewBox="0 0 35 35" fill={fill} xmlns="http://www.w3.org/2000/svg" {...rest}>
      <rect x="0.693359" y="0.899902" width="33.686" height="33.7477" rx="16.843" fill={rectFill} fillOpacity="0.2" />
      <path d="M17.5281 9.33691C12.8709 9.33691 9.09961 13.1167 9.09961 17.7738C9.09961 22.431 12.8709 26.2108 17.5281 26.2108C22.1937 26.2108 25.9735 22.431 25.9735 17.7738C25.9735 13.1167 22.1937 9.33691 17.5281 9.33691ZM21.1054 22.836L17.5365 20.6846L13.9677 22.836L14.9127 18.7778L11.7657 16.0527L15.9166 15.6984L17.5365 11.868L19.1564 15.6899L23.3074 16.0443L20.1604 18.7694L21.1054 22.836Z" fill={innerFill} />
    </svg>
  );
};
export default PointsWithShadowIcon;
