import { useState } from 'react';
import { useController } from 'react-hook-form';

import {
  Box, FormControl, FormHelperText,
} from '@mui/material';

import { SelectTags, Tag } from 'components/molecules';
import { useLocale } from 'util/hooks';

const FormTags = (props) => {
  const {
    setError,
    isCourseCompleted,
    ...rest
  } = props;

  const { field, fieldState } = useController(rest);
  const { value: tags, onChange } = field;
  const { error } = fieldState;
  const {
    t, isAr,
  } = useLocale();

  const [tagValue, setTagValue] = useState('');

  const hasErrored = Boolean(error);

  const onFieldChange = (event, newValue) => {
    setTagValue(newValue);
    if (tags?.some((i) => i.id === newValue?.id)) {
      setError(t('common.tagAlreadyAdded'));
    } else if (newValue) {
      tags?.push(newValue);
      onChange(tags);
    }
  };

  const handleDeleteTag = (deletedTagId) => {
    onChange(tags?.filter((tag) => tag.id !== deletedTagId));
  };

  return (
    <Box>
      <FormControl
        sx={{ width: 1 }}
        error={hasErrored}
      >
        <SelectTags
          {...rest}
          onChange={onFieldChange}
          error={hasErrored}
          value={tagValue}
          noOptionsText={t('common.noOptions')}
        />
        {hasErrored && <FormHelperText>{error?.message}</FormHelperText>}
      </FormControl>
      <Box sx={{ mt: 2 }}>
        {tags?.map((tag) => (
          <Tag
            key={tag?.id}
            color="tags"
            label={isAr ? tag?.nameAr : tag?.nameEn}
            onDelete={() => handleDeleteTag(tag?.id)}
            sx={{ mr: 2, mt: 2 }}
            disabled={isCourseCompleted}
          />
        ))}
      </Box>
    </Box>
  );
};

export default FormTags;
