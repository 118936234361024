import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { FormTextField } from 'components/form';
import { useNavigate, useParams } from 'react-router-dom';
import { createCourseChapter, updateCourseChapter } from 'services';
import { useCourseDetails } from 'reactQuery/queries';
import { PATHS } from 'routes';

const { Typography, Button, Box } = require('@mui/material');
const { useLocale, useFastForm, useSnackbar } = require('util/hooks');

const CourseChapterDetailsForm = (props) => {
  const { t } = useLocale();
  const snack = useSnackbar();
  const navigate = useNavigate();
  const { courseId } = useParams();

  const {
    data: courseDetails = {},
    refetch: refetchCourse = () => {},
  } = useCourseDetails();

  const {
    createMode = false,
    handleClickCancel = () => {},
    chapterDetails = {},
  } = props;

  const onSubmit = async (values) => {
    let message = '';

    try {
      if (createMode) {
        await createCourseChapter(values, courseId);
        message = t('courses.chapterCreated');
      } else {
        await updateCourseChapter(values, courseId, chapterDetails.id);
        message = t('courses.chapterUpdated');
      }

      navigate(`/${PATHS.courses}/${courseDetails?.id}`);
      refetchCourse();
      handleClickCancel();

      snack({
        severity: 'success',
        message,
      });
    } catch (error) {
      error.errors?.forEach((err) => {
        setError(err.property, {
          type: 'api',
          message: err.message,
        });

        if (!err.property) {
          snack({
            severity: 'error',
            message: err.message || t('common.somethingWrong'),
          });
        }
      });
    }
  };

  const defaultValues = {
    name: chapterDetails?.name || '',
  };

  const validationSchema = Yup.object().shape(
    {
      name: Yup
        .string()
        .required(t('courses.chapterNameRequired')),
    },
  );

  const {
    control,
    setError,
    handleSubmit,
    formState: {
      isSubmitting,
    },
  } = useFastForm({
    defaultValues,
    validationSchema,
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box
        sx={{
          mb: 12,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Typography variant="h5">
          {createMode ? t('courses.newChapter') : t('courses.updateChapter')}
        </Typography>
      </Box>
      <Box>
        <FormTextField
          name="name"
          control={control}
          label={t('courses.chapterName')}
          disabled={isSubmitting}
          fullWidth
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          mt: 10,
        }}
      >
        <Button
          onClick={handleClickCancel}
          variant="outlined"
          size="regular"
          color="secondary"
        >
          {t('common.cancel')}
        </Button>
        <LoadingButton
          type="submit"
          size="regular"
          loading={isSubmitting}
          disabled={isSubmitting}
          variant="contained"
          sx={{ ml: 5 }}
        >
          {createMode ? t('common.create') : t('common.update')}
        </LoadingButton>
      </Box>
    </form>
  );
};
export default CourseChapterDetailsForm;
