import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { GridActionsCellItem } from '@mui/x-data-grid';

import { Status } from 'components/molecules';
import { useAuth, useLocale, useModal } from 'util/hooks';
import { getStatusByName } from 'assets/constants/statuses';
import { AUTH_ROLES } from 'assets/constants/userRoles';
import MODAL_KEYS from 'assets/constants/modalKeys';
import TABLE_ACTIONS from 'assets/constants/tableActions';

const useTableColumns = (props) => {
  const {
    refetchStudents = () => { },
  } = props;

  const { t, isAr } = useLocale();
  const { addModal } = useModal();
  const {
    isAdmin,
    isClubManager,
    isLeader,
  } = useAuth();

  const handleOpenModal = (actionType, modalProps) => {
    addModal({
      key: MODAL_KEYS.studentManagement,
      props: {
        actionType,
        refetchStudents,
        studentDetails: modalProps.row,
      },
    });
  };

  if (isAdmin) {
    return [
      {
        field: 'fullName',
        headerName: t('common.name'),
        flex: 1,
        minWidth: 280,
        renderCell: ({ row = {} }) => {
          const { fullName, username } = row || {};

          return (
            <Typography
              component={Link}
              to={`/profile/${username}`}
            >
              {fullName}
            </Typography>
          );
        },
      },
      {
        field: 'email',
        headerName: t('common.email'),
        flex: 1,
        minWidth: 280,
      },
      {
        field: 'clubName',
        valueGetter: ({ row }) => {
          const { clubDetails = {} } = row || {};

          return isAr
            ? clubDetails?.nameAr
            : clubDetails?.nameEn;
        },
        headerName: t('common.university'),
        flex: 1,
        minWidth: 300,
      },
      {
        field: 'mobile',
        headerName: t('common.mobile'),
        flex: 1,
        minWidth: 220,
      },
      {
        field: 'status',
        headerName: t('common.status'),
        flex: 1,
        minWidth: 180,
        renderCell: (params) => (
          <Status
            status={params.row.status || ''}
            label={t(getStatusByName(params?.row?.status)?.localizationKey) || ''}
          />
        ),
      },
      {
        field: 'actions',
        headerName: t('common.actions'),
        type: 'actions',
        flex: 1,
        minWidth: 50,
        getActions: (params) => {
          const {
            row: {
              status,
              graduate,
              roleName,
              mobileVerified,
              nafathVerified,
            },
          } = params;
          const isActive = status === getStatusByName('active').name;
          const isPending = status === getStatusByName('pending').name;
          const isSuspended = status === getStatusByName('suspended').name;
          const isClubLeader = roleName === AUTH_ROLES.leader;

          const actions = [];
          if (isActive) {
            actions.push(
              <GridActionsCellItem
                component={Link}
                label={t('actions.updateInfo')}
                to={params?.row?.username}
                showInMenu
              />,
              <GridActionsCellItem
                label={t('actions.suspendStudent')}
                onClick={() => handleOpenModal(
                  TABLE_ACTIONS.userManagement.inactivateByAdmin,
                  params,
                )}
                showInMenu
              />,
            );

            // Mobile
            if (mobileVerified) {
              actions.push(<GridActionsCellItem
                label={t('actions.unverifyMobile')}
                onClick={() => handleOpenModal(
                  TABLE_ACTIONS.userManagement.unverifyMobile,
                  params,
                )}
                showInMenu
              />);
            }

            // Nafath
            if (nafathVerified) {
              actions.push(<GridActionsCellItem
                label={t('actions.unverifyNafath')}
                onClick={() => handleOpenModal(
                  TABLE_ACTIONS.userManagement.unverifyNafath,
                  params,
                )}
                showInMenu
              />);
            }

            // Grads
            if (graduate) {
              actions.push(<GridActionsCellItem
                label={t('actions.setAsUnderGraduate')}
                onClick={() => handleOpenModal(
                  TABLE_ACTIONS.userManagement.setAsUndergraduate,
                  params,
                )}
                showInMenu
              />);
            } else {
              actions.push(<GridActionsCellItem
                label={t('actions.setAsGraduate')}
                onClick={() => handleOpenModal(
                  TABLE_ACTIONS.userManagement.setAsGraduate,
                  params,
                )}
                showInMenu
              />);
            }
          } else if (isPending) {
            actions.push(
              <GridActionsCellItem
                component={Link}
                label={t('actions.updateInfo')}
                to={params?.row?.username}
                showInMenu
              />,
              <GridActionsCellItem
                label={t('actions.activateStudent')}
                onClick={() => handleOpenModal(
                  TABLE_ACTIONS.userManagement.activateByAdmin,
                  params,
                )}
                showInMenu
              />,
            );
          } else if (isSuspended) {
            actions.push(
              <GridActionsCellItem
                label={t('actions.activateStudent')}
                onClick={() => handleOpenModal(
                  TABLE_ACTIONS.userManagement.activateByAdmin,
                  params,
                )}
                showInMenu
              />,
            );
          }

          // Leader management
          if (!isClubLeader) {
            actions.push(
              <GridActionsCellItem
                label={t('actions.setAsLeader')}
                onClick={() => handleOpenModal(
                  TABLE_ACTIONS.userManagement.setAsLeader,
                  params,
                )}
                showInMenu
              />,
            );
          }

          return actions;
        },
      },
    ];
  }

  if (isClubManager) {
    return [
      {
        field: 'fullName',
        headerName: t('common.name'),
        flex: 1,
        minWidth: 280,
        renderCell: ({ row = {} }) => {
          const { fullName, username } = row || {};

          return (
            <Typography
              component={Link}
              to={`/profile/${username}`}
            >
              {fullName}
            </Typography>
          );
        },
      },
      {
        field: 'email',
        headerName: t('common.email'),
        flex: 1,
        minWidth: 280,
      },
      {
        field: 'joinedAt',
        headerName: t('common.joiningDate'),
        flex: 1,
        minWidth: 180,
        valueGetter: (params) => (params.row.joinedAt)?.slice(0, 10) || t('common.none'),
      },
      {
        field: 'studentId',
        headerName: t('common.universityId'),
        flex: 1,
        minWidth: 180,
      },
      {
        field: 'status',
        headerName: t('common.status'),
        flex: 1,
        minWidth: 160,
        renderCell: (params) => (
          <Status
            status={params.row.status || ''}
            label={t(getStatusByName(params?.row?.status)?.localizationKey) || ''}
          />
        ),
      },
      {
        field: 'actions',
        headerName: t('common.actions'),
        type: 'actions',
        flex: 1,
        minWidth: 50,
        getActions: (params) => {
          const { row: { status, graduate, roleName } } = params;
          const isActive = status === getStatusByName('active').name;
          const isPending = status === getStatusByName('pending').name;
          const isSuspended = status === getStatusByName('suspended').name;
          const isClubLeader = roleName === AUTH_ROLES.leader;

          const actions = [];
          if (isActive) {
            actions.push(
              graduate === true
                ? (
                  <GridActionsCellItem
                    label={t('actions.setAsUnderGraduate')}
                    onClick={() => handleOpenModal(
                      TABLE_ACTIONS.userManagement.setAsUndergraduateByClubManager,
                      params,
                    )}
                    showInMenu
                  />
                )
                : (
                  <GridActionsCellItem
                    label={t('actions.setAsGraduate')}
                    onClick={() => handleOpenModal(
                      TABLE_ACTIONS.userManagement.setAsGraduateByClubManager,
                      params,
                    )}
                    showInMenu
                  />
                ),
            );
          } else if (isPending) {
            actions.push(
              graduate === true
                ? (
                  <GridActionsCellItem
                    label={t('actions.setAsUnderGraduate')}
                    onClick={() => handleOpenModal(
                      TABLE_ACTIONS.userManagement.setAsGraduateByClubManager,
                      params,
                    )}
                    showInMenu
                  />
                )
                : (
                  <GridActionsCellItem
                    label={t('actions.setAsGraduate')}
                    onClick={() => handleOpenModal(
                      TABLE_ACTIONS.userManagement.setAsGraduateByClubManager,
                      params,
                    )}
                    showInMenu
                  />
                ),
            );
          } else if (isSuspended) {
            actions.push(
              <GridActionsCellItem
                label={t('actions.activateUser')}
                onClick={() => handleOpenModal(
                  TABLE_ACTIONS.userManagement.activateByClubManager,
                  params,
                )}
                showInMenu
              />,
            );
          }

          // Leader management
          if (!isClubLeader) {
            actions.push(
              <GridActionsCellItem
                label={t('actions.setAsLeader')}
                onClick={() => handleOpenModal(
                  TABLE_ACTIONS.userManagement.setAsLeader,
                  params,
                )}
                showInMenu
              />,
            );
          }

          return actions;
        },
      },
    ];
  }

  if (isLeader) {
    return [
      {
        field: 'fullName',
        headerName: t('common.name'),
        flex: 1,
        minWidth: 180,
        renderCell: ({ row = {} }) => {
          const { fullName, username } = row || {};

          return (
            <Typography
              component={Link}
              to={`/profile/${username}`}
            >
              {fullName}
            </Typography>
          );
        },
      },
      {
        field: 'roleName',
        headerName: t('common.role'),
        flex: 1,
        minWidth: 180,
      },
      {
        field: 'email',
        headerName: t('common.email'),
        flex: 1,
        minWidth: 180,
      },
      {
        field: 'joinedAt',
        headerName: t('common.joiningDate'),
        flex: 1,
        minWidth: 180,
        valueGetter: (params) => (params.row.joinedAt)?.slice(0, 10) || t('common.none'),
      },
      {
        field: 'status',
        headerName: t('common.status'),
        flex: 1,
        minWidth: 160,
        renderCell: (params) => (
          <Status
            status={params.row.status || ''}
            label={t(getStatusByName(params?.row?.status)?.localizationKey) || ''}
          />
        ),
      },

    ];
  }

  return [];
};

export default useTableColumns;
