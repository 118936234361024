import END_POINTS from '../endPoints';
import METHODS from '../methods';
import api from '../api';

const register = (data, options) => api(
  END_POINTS.students,
  {
    method: METHODS.POST,
    data,
    requireAuth: false,
    ...options,
  },
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default register;
