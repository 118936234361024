import END_POINTS from '../../endPoints';
import api from '../../api';

const getAllCertificates = (options = { pagination: { limit: 150 } }) => api(
  END_POINTS.certificates,
  options,
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default getAllCertificates;
