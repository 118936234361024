import {
  Box,
  Button,
  Card,
  Typography,
} from '@mui/material';
import { COURSE_MATERIAL_TYPES } from 'assets/constants/courses';
import MODAL_KEYS from 'assets/constants/modalKeys';
import { AttachmentOutlinedIcon, TextOutlinedIcon, VideoOutlinedIcon } from 'assets/icons';
import { useLocale, useModal } from 'util/hooks';

const AddCourseMaterialCard = (props) => {
  const { chapterId = '' } = props;

  const { t } = useLocale();
  const { addModal } = useModal();

  const onClickVideo = () => {
    addModal({
      key: MODAL_KEYS.courseVideo,
      props: {
        createMode: true,
        chapterId,
      },
    });
  };

  const onClickAttachment = () => {
    addModal({
      key: MODAL_KEYS.courseAttachment,
      props: {
        createMode: true,
        chapterId,
      },
    });
  };

  const onClickText = () => {
    addModal({
      key: MODAL_KEYS.courseText,
      props: {
        createMode: true,
        chapterId,
      },
    });
  };

  const materialsTypes = [
    {
      key: COURSE_MATERIAL_TYPES.video,
      icon: <VideoOutlinedIcon />,
      label: 'common.video',
      onClick: onClickVideo,
    },
    {
      key: COURSE_MATERIAL_TYPES.attachment,
      icon: <AttachmentOutlinedIcon />,
      label: 'common.attachment',
      onClick: onClickAttachment,
    },
    {
      key: COURSE_MATERIAL_TYPES.text,
      icon: <TextOutlinedIcon />,
      label: 'common.text',
      onClick: onClickText,
    },
  ];

  return (
    <Card
      sx={{
        mt: 5,
        py: 5,
        border: 'dashed 1px',
        borderColor: 'common.silverGrey',
      }}
    >
      <Typography
        variant="bodyStandardRegular"
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {t('courses.addNewMaterial')}
      </Typography>
      <Box
        mt={3}
        display="flex"
        justifyContent="center"
        sx={{
          flexDirection: { xs: 'column', md: 'row' },
        }}
      >
        {materialsTypes.map((type) => (
          <Button
            sx={{
              mx: 2,
              my: { xs: 2, md: 0 },
            }}
            key={type.key}
            variant="outlined"
            color="secondary"
            startIcon={type.icon}
            onClick={type.onClick}
          >
            {t(type.label)}
          </Button>
        ))}

      </Box>
    </Card>
  );
};

export default AddCourseMaterialCard;
