import END_POINTS from '../endPoints';
import api from '../api';

const getPostDetails = (id) => api(
  END_POINTS.post(id),
)
  .then((response) => Promise.resolve(response))
  .catch((error) => Promise.reject(error));

export default getPostDetails;
