import { SuspenseWrapper } from 'components/render';
import PostList from './PostList';

const PostListWrapper = (props) => (
  <SuspenseWrapper>
    <PostList {...props} />
  </SuspenseWrapper>
);

export default PostListWrapper;
