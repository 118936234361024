import { useLocale } from 'util/hooks';
import { localizedDate } from 'util/helpers';
import { PointsWithShadowIcon } from 'assets/icons';
import { useTheme } from '@mui/styles';
import IconWithLabelContainer from '../Courses/CourseCard/IconWithLabelContainer';

const useTableColumns = () => {
  const theme = useTheme();
  const {
    t, language,
  } = useLocale();

  const columns = [
    {
      field: 'name',
      headerName: t('common.name'),
      flex: 1,
      minWidth: 250,
      sortable: false,
      valueGetter: (params) => params?.row?.student?.name,
    },
    {
      field: 'club',
      headerName: t('courses.attendanceTable.club'),
      flex: 1,
      minWidth: 250,
      sortable: false,
      valueGetter: (params) => params?.row?.student?.club,
    },
    {
      field: 'email',
      headerName: t('courses.attendanceTable.email'),
      flex: 1,
      minWidth: 250,
      sortable: false,
      valueGetter: (params) => params?.row?.student?.email,
    },
    {
      field: 'createdAt',
      headerName: (t('courses.registrationTable.registrationDate')),
      flex: 1,
      minWidth: 200,
      sortingOrder: ['asc', 'desc'],
      valueGetter: (params) => `${localizedDate(language, params?.row?.createdAt)}`,
    },
    {
      field: 'user.totalPoints',
      headerName: t('courses.registrationTable.points'),
      flex: 1,
      minWidth: 150,
      sortingOrder: ['desc'],
      valueGetter: (params) => params?.row?.points || '-',
      renderCell: (params) => (
        <IconWithLabelContainer
          icon={(
            <PointsWithShadowIcon
              width="20"
              height="20"
              innerFill={theme.palette.common.vividOrange}
              rectFill={theme.palette.common.vividOrange}
            />
)}
          text={params?.row?.points || '-'}
        />
      ),
    },
  ];

  return columns;
};

export default useTableColumns;
