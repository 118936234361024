import { useTheme } from '@mui/styles';

const FilledCheckIcon = (props) => {
  const { palette } = useTheme();

  const {
    fill = 'none',
    innerFill = palette.common.turquoiseSeaweedGreen,
  } = props;

  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M10.5251 20C4.86659 20 0.279724 15.523 0.279724 10C0.279724 4.477 4.86659 0 10.5251 0C16.1837 0 20.7705 4.477 20.7705 10C20.7705 15.523 16.1837 20 10.5251 20ZM9.50366 14L16.7472 6.929L15.2985 5.515L9.50366 11.172L6.60523 8.343L5.15653 9.757L9.50366 14Z" fill={innerFill} />
    </svg>
  );
};

export default FilledCheckIcon;
