import { Stack } from '@mui/material';
import { ClubAdminsContactInfo } from 'components/molecules';
import _ from 'lodash';
import { useClubLeader, useClubManager } from 'reactQuery/queries';

const ClubAdminsList = () => {
  const {
    data: clubManager = {},
  } = useClubManager();

  const {
    data: leader = {},
  } = useClubLeader();

  return (
    <Stack
      spacing={5}
      pt={5}
    >
      {!_.isEmpty(clubManager) && (
      <ClubAdminsContactInfo
        {...clubManager}
      />
      )}
      {!_.isEmpty(leader) && (
      <ClubAdminsContactInfo
        {...leader}
      />
      )}
    </Stack>
  );
};

export default ClubAdminsList;
