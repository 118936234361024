import {
  useMediaQuery,
  Box,
  Link,
  Divider,
  Typography,
  IconButton,
} from '@mui/material';
import { useTheme } from '@mui/styles';
import { ArrowIcon } from 'assets/icons';
import { useLocale } from 'util/hooks';

const OptionsDetailedList = (props) => {
  const {
    t, isAr,
  } = useLocale();

  const theme = useTheme();
  const showOnMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { options } = props;

  const optionsList = options.map((option) => (
    <Box
      key={option.key}
      sx={{
        display: 'flex',
        alignItems: 'start',
        py: 4,
      }}
    >
      <option.icon
        width={showOnMobile ? '45' : '60'}
        height={showOnMobile ? '45' : '60'}
      />
      <Box
        component={Link}
        to={option.to}
        sx={{
          flexDirection: 'column',
          width: 1,
          paddingLeft: 8,
        }}
      >
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: { md: 'center', xs: 'end' },
          paddingRight: 4,
          paddingBottom: 2,
        }}
        >
          <Typography
            variant="bodyStandardBold"
            color="secondary.main"
          >
            {t(option.localizationKey)}
          </Typography>
          <IconButton
            disableRipple
          >
            <ArrowIcon style={{ transform: isAr ? 'scaleX(-1)' : 'none' }} />
          </IconButton>
        </Box>
        <Box sx={{ paddingRight: { sm: 10, xs: 12 } }}>
          <Typography
            variant="bodySmallRegular"
            color="common.gadgetGray"
            sx={{ textTransform: 'none' }}
          >
            {option.description.map((desc) => <li key={desc.text}>{t(desc.text)}</li>)}
          </Typography>
        </Box>
        {option.divider && <Divider sx={{ py: 4 }} />}
      </Box>
    </Box>
  ));

  return optionsList;
};

export default OptionsDetailedList;
