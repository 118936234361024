import {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { useController } from 'react-hook-form';

import {
  Box,
  Avatar,
  Button,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { generatePresignedUrl, uploadFileToS3 } from 'services';
import { capitalizeFirstLetter } from 'util/helpers';
import { useLocale, useSnackbar } from 'util/hooks';

const FormAvatar = (props) => {
  const {
    avatar,
    username,
    disabled,
    avatarProps,
    uploadButtonLabel,
    deleteButtonProps,
    loadingButtonProps,
    ...rest
  } = props;

  const { field } = useController(rest);
  const { onChange } = field;

  const { t } = useLocale();
  const snack = useSnackbar();

  const [currentAvatar, setCurrentAvatar] = useState(avatar);
  const [isUploadLoading, setIsUploadLoading] = useState(false);

  useEffect(() => () => {
    // Make sure to revoke the data uris to avoid memory leaks
    URL.revokeObjectURL(currentAvatar?.preview);
  }, [currentAvatar]);

  const uploadToS3 = async (newAvatar) => {
    setIsUploadLoading(true);
    try {
      // Step 1 - get url and fileName
      const { uploadUrl, fileName } = await generatePresignedUrl();
      // Step 2 - upload to S3
      await uploadFileToS3(uploadUrl, newAvatar);
      // Step 3 - update form with fileName
      onChange(fileName);
    } catch (error) {
      snack({
        message: error.message || t('common.somethingWrong'),
        severity: 'error',
      });
    }
    setIsUploadLoading(false);
  };

  const deleteAvatar = () => {
    setCurrentAvatar(null);
    onChange(null);
  };

  // ========= Dropzone =========

  const onDrop = async (acceptedFile) => {
    const newAvatar = Object.assign(acceptedFile[0], {
      preview: URL.createObjectURL(acceptedFile[0]),
    });

    await uploadToS3(newAvatar);
    setCurrentAvatar(newAvatar?.preview);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png'],
    },
    multiple: false,
    onDrop,
  });

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        my: 7,
      }}
    >
      <Avatar
        src={currentAvatar}
        alt={capitalizeFirstLetter(username)}
        sx={{
          width: 84,
          height: 84,
          fontWeight: 'bold',
          fontSize: 23,
        }}
        {...avatarProps}
      />
      <Box sx={{
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
        justifyContent: { xs: 'start', sm: 'center' },
        alignItems: { xs: 'start', sm: 'center' },
        ml: 5,
      }}
      >
        <Box {...getRootProps()}>
          <input
            {...getInputProps()}
          />
          <LoadingButton
            size="regular"
            variant="contained"
            color="primary"
            disabled={disabled}
            loading={isUploadLoading}
            sx={{
              mb: { xs: 2, sm: 0 },
            }}
            {...loadingButtonProps}
          >
            {uploadButtonLabel}
          </LoadingButton>
        </Box>
        <Button
          onClick={deleteAvatar}
          color="error"
          variant="outlined"
          size="regular"
          disabled={disabled || !currentAvatar}
          sx={{
            ml: { xs: 0, sm: 2 },
          }}
          {...deleteButtonProps}
        >
          {t('common.delete')}
        </Button>
      </Box>
    </Box>
  );
};

FormAvatar.propTypes = {
  avatar: PropTypes.string,
  username: PropTypes.string,
  uploadButtonLabel: PropTypes.string,
  disabled: PropTypes.bool,
  loadingButtonProps: PropTypes.oneOfType([PropTypes.object]),
  deleteButtonProps: PropTypes.oneOfType([PropTypes.object]),
  setUpdateError: PropTypes.func,
};

FormAvatar.defaultProps = {
  avatar: null,
  username: '',
  uploadButtonLabel: '',
  disabled: false,
  loadingButtonProps: {},
  deleteButtonProps: {},
  setUpdateError: () => { },
};

export default FormAvatar;
