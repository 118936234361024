import { useParams } from 'react-router-dom';
import { getCourseChapters } from 'services';
import { useCustomQuery } from 'reactQuery';

const useCourseChapters = (queryProps = {}) => {
  const { courseId } = useParams();

  const query = useCustomQuery({
    queryKey: [`course-chapters-${courseId}`],
    queryFn: () => getCourseChapters(courseId),
    ...queryProps,
  });

  return { ...query };
};

export default useCourseChapters;
