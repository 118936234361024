import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { SuspenseWrapper } from 'components/render';
import Hero from './sections/Hero';
import About from './sections/About';
import Statistics from './sections/Statistics';
import RecentPosts from './sections/RecentPosts';
import UpcomingCourses from './sections/UpcomingCourses';
import WeProvide from './sections/WeProvide';
import SuccessPartners from './sections/SuccessPartners/SuccessPartners';
import Clubs from './sections/Clubs/Clubs';
import Footer from './sections/Footer';
import SponsorJoin from './sections/SponsorJoin';
import LandingSectionWrapper from './helpers/LandingSectionWrapper';
import LandingLeaderboard from './sections/LandingLeaderboard';

const defaultMaxWidth = { maxWidth: 1000 };
const LandingBackground = () => (
  <Box sx={{
    position: 'absolute',
    top: 0,
    right: 0,
    width: 701,
    height: { xs: 550, sm: 701 },
    backgroundImage: 'linear-gradient(130deg, #93D29E 0%, #72C0C3 97.91%)',
    zIndex: -1,
    filter: 'blur(190px)',
    WebkitFilter: 'blur(190px)',
    transform: 'translate3d(0, 0, 0)',
    borderRadius: '50%',
    mr: { md: -20 },
    mt: -10,
  }}
  />
);

const LandingContainer = () => (
  <Box sx={{ pt: 37 }}>
    <LandingSectionWrapper
      width={1}
      sx={{
        position: 'relative',
        mx: 'auto',
      }}
    >
      <LandingBackground />
    </LandingSectionWrapper>
    <Stack
      spacing={20}
      justifyContent="center"
      alignItems="center"
    >
      <LandingSectionWrapper width={1} sx={{ mt: 14 }}>
        <Hero />
      </LandingSectionWrapper>

      <Box width={1}>
        <LandingSectionWrapper sx={{ mx: 'auto' }}>
          <About />
        </LandingSectionWrapper>
      </Box>

      <Box sx={{ width: 1 }}>
        <LandingSectionWrapper sx={{ mx: 'auto' }}>
          <SuspenseWrapper>
            <Statistics />
          </SuspenseWrapper>
        </LandingSectionWrapper>
      </Box>

      <Box sx={{ width: 1, ...defaultMaxWidth }}>
        <SuspenseWrapper>
          <RecentPosts />
        </SuspenseWrapper>
      </Box>

      <Box sx={{ width: 1, ...defaultMaxWidth }}>
        <SuspenseWrapper>
          <UpcomingCourses />
        </SuspenseWrapper>
      </Box>

      <Box sx={{ width: 1, ...defaultMaxWidth }}>
        <SuspenseWrapper>
          <LandingLeaderboard />
        </SuspenseWrapper>
      </Box>

      <LandingSectionWrapper sx={{ width: 1 }}>
        <WeProvide />
      </LandingSectionWrapper>

      <Box width={1}>
        <SuccessPartners />
      </Box>

      <LandingSectionWrapper width={1}>
        <Clubs />
      </LandingSectionWrapper>

      <LandingSectionWrapper>
        <SponsorJoin />
      </LandingSectionWrapper>

      <Box sx={{
        width: 1,
        backgroundColor: 'common.white',
      }}
      >
        <LandingSectionWrapper sx={{ mx: 'auto' }}>
          <Footer />
        </LandingSectionWrapper>
      </Box>

    </Stack>
  </Box>
);

export default LandingContainer;
