import {
  Box,
  Button,
  Card,
  Stack,
  Typography,
} from '@mui/material';
import { FlagyardIcon, SparklingFilledIcon } from 'assets/icons';
import { AvatarWithBadge } from 'components/molecules';
import { useUserFlagyardInfo } from 'reactQuery/queries';
import { capitalizeFirstLetter } from 'util/helpers';
import { useLocale } from 'util/hooks';

const FlagyardAccountDetails = () => {
  const { t } = useLocale();

  const {
    data: flagyardAccountDetails = {},
  } = useUserFlagyardInfo();

  const {
    username = '',
    totalPoints = 0,
    avatar = '',
    level = '',
    country = '',
    url = '',
  } = flagyardAccountDetails;

  return (
    <Card sx={{ py: 5, px: 7, borderRadius: 0 }}>
      <Box mb={5}>
        <FlagyardIcon />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: 'wrap',
          gap: 5,
        }}
      >
        <Box display="flex" alignItems="center">
          <AvatarWithBadge
            avatarProps={{
              src: String(avatar),
              alt: capitalizeFirstLetter(username),
              sx: {
                width: 55,
                height: 55,
                fontWeight: 'bold',
                fontSize: 33,
              },
            }}
            badgeProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
              },
              badgeContent: (
                <img
                  alt={`${country}-flag`}
                  src={`https://flagcdn.com/16x12/${country.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/32x24/${country.toLowerCase()}.png 2x`}
                  width="20"
                  height="15"
                />
              ),
            }}
          />
          <Stack ml={3}>
            <Typography variant="bodyStandardMedium">
              {username}
            </Typography>
            <Typography variant="bodyMicroRegular">
              {level}
            </Typography>
          </Stack>
        </Box>

        <Box display="flex" alignItems="center">
          <SparklingFilledIcon />
          <Stack ml={3}>
            <Typography variant="bodySmallRegular">
              {t('common.totalPoints')}
            </Typography>
            <Typography variant="bodyStandardMedium">
              {`${totalPoints} Points`}
            </Typography>
          </Stack>
        </Box>
        <Box>
          <Button
            variant="linkButton"
            size="regular"
            onClick={() => { window.open(url, '_blank'); }}
            sx={{ p: 0 }}
          >
            {t('flagyard.viewProfile')}
          </Button>
        </Box>
      </Box>
    </Card>
  );
};

export default FlagyardAccountDetails;
