import { LeaderboardList } from 'components/organisms';
import { useTopStudents } from 'reactQuery/queries';

const StudentsLeaderboardContainer = (props) => {
  const {
    data = {},
  } = useTopStudents();

  return (
    <LeaderboardList
      topTen={data}
      {...props}
    />
  );
};

export default StudentsLeaderboardContainer;
