import END_POINTS from '../endPoints';
import api from '../api';

const generatePresignedUrl = () => api(
  END_POINTS.uploads,
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default generatePresignedUrl;
