import { DataGrid } from 'components/core';

import { useLocale, useSnackbar } from 'util/hooks';
import { useCourseEnrollments } from 'reactQuery/queries';
import { exportAttendanceList } from 'services';
import { isEmpty } from 'lodash';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
import useTableColumns from './useTableColumns';

const CourseAttendanceTable = (props) => {
  const { courseDetails = {} } = props;
  const { t } = useLocale();
  const snack = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const {
    data = { items: [], meta: {} },
    isFetching = true,
    refetch: refetchCourseEnrollments = () => { },
  } = useCourseEnrollments();

  const columns = useTableColumns({ refetchCourseEnrollments });
  const { isNomination } = courseDetails || {};
  const tableTitle = isNomination ? t('courses.acceptedStudents') : t('courses.attendance');

  const {
    items: rows = [],
    meta = {},
  } = data || {};

  const downloadAttendance = async () => {
    setIsLoading(true);

    try {
      await exportAttendanceList(courseDetails?.id, courseDetails?.title);
    } catch (error) {
      snack({
        message: error.message || t('common.somethingWrong'),
        severity: 'error',
      });
    }
    setIsLoading(false);
  };

  const exportButton = (
    <LoadingButton
      loading={isLoading}
      variant="outlined"
      onClick={downloadAttendance}
      disabled={isEmpty(rows)}
      sx={{
        minWidth: 130,
        height: 56,
        '&:disabled': {
          borderColor: 'common.berylliumGrey',
          color: 'common.berylliumGrey',
        },
      }}
    >
      {t('course.exportAttendance')}
    </LoadingButton>
  );

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      loading={isFetching}
      pagination={meta}
      withSearch={false}
      withCustomToolbar
      componentsProps={{
        noRowsOverlay: { type: 'courseAttendance', showButton: false },
        toolbar: {
          placeholder: t('common.search'),
          component: exportButton,
          withTableHeader: true,
          tableHeaderProps: {
            title: tableTitle,
            count: t('profile.studentCount.students', { count: meta.totalItems || 0 }),
          },
        },
      }}
    />
  );
};

export default CourseAttendanceTable;
