import { useTheme } from '@mui/styles';

const KnowledgePostIcon = (props) => {
  const { palette } = useTheme();

  const {
    fill = palette.common.vividOrange,
    outerFill = palette.common.blanchedAlmond,
    stroke = palette.common.white,
    ...rest
  } = props;

  return (
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <rect x="3" y="3" width="54" height="54" rx="12.3462" fill={fill} stroke={outerFill} strokeWidth="6" />
      <g clipPath="url(#clip0_2047_12176)">
        <path d="M28.5 40.5H31.5" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M36 30C36.004 28.8769 35.6927 27.7752 35.1015 26.8203C34.5103 25.8653 33.663 25.0955 32.6559 24.5984C31.6488 24.1012 30.5224 23.8967 29.4048 24.0081C28.2873 24.1195 27.2234 24.5424 26.3343 25.2285C25.4451 25.9147 24.7665 26.8366 24.3754 27.8895C23.9844 28.9423 23.8967 30.0838 24.1223 31.184C24.348 32.2842 24.8779 33.299 25.6518 34.1129C26.4257 34.9268 27.4125 35.5072 28.5 35.788V37.5H31.5V35.788C32.7855 35.4585 33.9252 34.7112 34.7397 33.6635C35.5543 32.6158 35.9976 31.3271 36 30Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M30 21V19.5" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M39 28.5H40.5" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M19.5 30H21" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M36.364 35.614L37.425 36.675" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M22.575 21.825L23.636 22.886" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M36.364 22.886L37.425 21.825" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M22.575 36.675L23.636 35.614" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </g>
    </svg>
  );
};

export default KnowledgePostIcon;
