import { useTheme } from '@mui/styles';

const RegisterIcon = (props) => {
  const theme = useTheme();

  const {
    fill = theme.palette.common.ghostlyGrey,
    stroke = theme.palette.text.secondary,
  } = props;

  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="32" height="32" rx="10.6667" fill={fill} />
      <circle cx="12.5224" cy="14.0931" r="3.16304" stroke={stroke} strokeWidth="1.5" />
      <path d="M5.25 23.2283C5.25 23.6425 5.58579 23.9783 6 23.9783C6.41421 23.9783 6.75 23.6425 6.75 23.2283H5.25ZM18.2935 23.2283C18.2935 23.6425 18.6293 23.9783 19.0435 23.9783C19.4577 23.9783 19.7935 23.6425 19.7935 23.2283H18.2935ZM6.75 23.2283C6.75 22.6796 7.17935 22.0085 8.26311 21.4305C9.31465 20.8697 10.819 20.5 12.5217 20.5V19C10.6226 19 8.8661 19.4089 7.55723 20.107C6.28059 20.7879 5.25 21.8559 5.25 23.2283H6.75ZM12.5217 20.5C14.2245 20.5 15.7288 20.8697 16.7804 21.4305C17.8641 22.0085 18.2935 22.6796 18.2935 23.2283H19.7935C19.7935 21.8559 18.7629 20.7879 17.4862 20.107C16.1774 19.4089 14.4209 19 12.5217 19V20.5Z" fill="#909090" />
      <line x1="23.0215" y1="14.8044" x2="23.0215" y2="19.8914" stroke={stroke} strokeLinecap="round" />
      <line x1="20.4121" y1="17.2827" x2="25.4991" y2="17.2827" stroke={stroke} strokeLinecap="round" />
    </svg>
  );
};

export default RegisterIcon;
