const VimeoIcon = (props) => {
  const {
    fill = '#00ADEF',
  } = props;

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="12" cy="12" r="12" fill={fill} />
      <path d="M12.7017 10.3431C12.7831 10.1016 12.8336 9.87697 12.9319 9.6748C13.3812 8.75379 14.1197 8.20904 15.1277 8.03214C15.5602 7.95632 15.9814 8.00967 16.3576 8.24554C16.8125 8.53196 16.9754 8.97842 16.9979 9.48947C17.0175 9.97245 16.8996 10.4358 16.7536 10.8935C16.3015 12.3115 15.4815 13.5049 14.4959 14.6C13.9091 15.2542 13.2689 15.8523 12.5613 16.3802C12.3001 16.574 12.0334 16.7565 11.6908 16.7649C11.2443 16.7762 10.9074 16.5571 10.6069 16.2567C10.2643 15.9141 10.0959 15.4789 9.95265 15.0324C9.59884 13.9148 9.25627 12.7916 8.90247 11.6741C8.807 11.3708 8.69468 11.0732 8.57955 10.7783C8.47846 10.5172 8.35491 10.4442 8.08816 10.5368C7.87475 10.6127 7.681 10.7362 7.47882 10.8429C7.43951 10.8626 7.40301 10.8907 7.35808 10.9215C7.23734 10.7643 7.1194 10.6183 7.01551 10.4638C6.99305 10.433 6.99585 10.3543 7.01832 10.3262C7.73997 9.52036 8.4925 8.74817 9.48372 8.2652C9.66343 8.17815 9.87122 8.1248 10.0706 8.09391C10.4749 8.03494 10.7473 8.24835 10.9411 8.57969C11.1938 9.01212 11.2949 9.49228 11.3791 9.97525C11.5195 10.7811 11.6515 11.5898 11.8873 12.3733C11.9744 12.6625 12.0811 12.9489 12.1794 13.2353C12.1878 13.2578 12.199 13.2774 12.2103 13.2971C12.3591 13.5863 12.5191 13.62 12.7578 13.401C13.0779 13.1061 13.311 12.7411 13.5244 12.3705C13.7153 12.0363 13.895 11.6909 14.0439 11.3343C14.1506 11.076 14.1506 10.7952 14.027 10.5284C13.881 10.2083 13.5553 10.0623 13.1959 10.1662C13.047 10.2055 12.8982 10.2701 12.7017 10.3431Z" fill="white" />
    </svg>
  );
};

export default VimeoIcon;
