import { useParams } from 'react-router-dom';

import { getClubMembers } from 'services';
import useCustomQuery from 'reactQuery/useCustomQuery';

const useClubMembers = (queryProps = {}) => {
  const { clubId } = useParams();

  const query = useCustomQuery({
    queryKey: [`club-members-${clubId}`],
    queryFn: () => getClubMembers(clubId),
    ...queryProps,
  });

  return { ...query };
};

export default useClubMembers;
