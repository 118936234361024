import { useTheme } from '@mui/styles';

const AttachmentOutlinedIcon = (props) => {
  const { palette } = useTheme();

  const {
    fill = palette.common.darkGray,
    ...rest
  } = props;

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d="M6.5 16C4.57 16 3 14.43 3 12.5V5C3 2.24333 5.24333 0 8 0C10.7567 0 13 2.24333 13 5V10.4993C13 10.7753 12.776 10.9993 12.5 10.9993C12.224 10.9993 12 10.7753 12 10.4993V5C12 2.79467 10.2053 1 8 1C5.79467 1 4 2.79467 4 5V12.5C4 13.8787 5.12133 15 6.5 15C7.87867 15 9 13.8787 9 12.5V5C9 4.44867 8.55133 4 8 4C7.44867 4 7 4.44867 7 5V11.5C7 11.776 6.776 12 6.5 12C6.224 12 6 11.776 6 11.5V5C6 3.89733 6.89733 3 8 3C9.10267 3 10 3.89733 10 5V12.5C10 14.43 8.43 16 6.5 16Z" fill={fill} />
    </svg>
  );
};
export default AttachmentOutlinedIcon;
