import DebugObserver from 'recoil/DebugObserver';
import {
  AuthWatcher,
  RemoveTrailingSlash,
  ScrollToTop,
} from 'components/helpers';
import GoogleAnalytics from '../GoogleAnalytics';

const Observers = () => {
  const isDev = process.env.NODE_ENV === 'development';

  return (
    <>
      {isDev && <DebugObserver />}
      <GoogleAnalytics />
      <RemoveTrailingSlash />
      <AuthWatcher />
      <ScrollToTop />
    </>
  );
};

export default Observers;
