import END_POINTS from 'services/endPoints';
import api from 'services/api';
import METHODS from 'services/methods';

const deleteCourseChapter = (courseId, chapterId) => api(
  END_POINTS.courseChapter(courseId, chapterId),
  {
    method: METHODS.DELETE,
  },
)
  .then((response) => Promise.resolve(response))
  .catch((error) => Promise.reject(error));

export default deleteCourseChapter;
