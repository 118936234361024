import { ConnectFlagyardAccount, FlagyardAccountDetails } from 'components/organisms';

const ProfileFlagyardAccount = (props) => {
  const { isConnected } = props;

  return (
    isConnected
      ? <FlagyardAccountDetails />
      : <ConnectFlagyardAccount />
  );
};

export default ProfileFlagyardAccount;
