import { DataGrid } from 'components/core';

import { useLocale } from 'util/hooks';
import { useCertificates } from 'reactQuery/queries';
import useTableColumns from './useTableColumns';

const CertificateTable = () => {
  const { t } = useLocale();

  const {
    data = { items: [], meta: {} },
    isFetching = true,
    refetch: refetchCertificates = () => { },
  } = useCertificates();

  const {
    items: rows = [],
    meta = {},
  } = data || {};
  const columns = useTableColumns({ refetchCertificates });

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      loading={isFetching}
      pagination={meta}
      componentsProps={{
        noRowsOverlay: { type: 'certificate', showButton: true },
        toolbar: {
          placeholder: t('common.search'),
        },
      }}
    />
  );
};

export default CertificateTable;
