import END_POINTS from '../../endPoints';
import METHODS from '../../methods';
import api from '../../api';

const createCertificate = (data) => api(
  END_POINTS.certificates,
  {
    method: METHODS.POST,
    data,
  },
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default createCertificate;
