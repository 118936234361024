import {
  Box, Typography,
} from '@mui/material';

import { UrlPagination } from 'components/molecules';
import { PublicCoursesList } from 'components/organisms';
import { usePublicCourses } from 'reactQuery/queries';
import { useLocale } from 'util/hooks';

/**
 *
 * @param {*} props.filter required filter to have filterOptions/searchOptions and titleKey
 */
const FilteredCoursesContainer = (props) => {
  const { filter = {} } = props;
  const { t } = useLocale();

  const {
    data = {},
  } = usePublicCourses({
    queryKeyOptions: {
      searchOptions: {
        ...filter?.searchOptions,
      },
      filterOptions: {
        ...filter?.filterOptions,
      },
    },
  });
  const {
    items: courses = [],
    meta,
  } = data || {};

  return (

    <Box sx={{
      p: 5,
      mt: 5,
      py: 10,
      borderRadius: 1,
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: 'common.offWhite',
      width: 1,
      minWidth: 'fit-content',
      boxShadow: (theme) => `0px 4px 4px ${theme.palette.common.boxShadow}`,
    }}
    >
      <>
        <Typography
          color="common.cyan"
          variant="bodyStandardMedium"
        >
          {t(filter?.titleKey || 'common.courses')}
        </Typography>
        <PublicCoursesList courses={courses} />
      </>
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
      }}
      >
        <UrlPagination count={meta?.totalPages} />
      </Box>
    </Box>
  );
};

export default FilteredCoursesContainer;
