import { Link } from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid';

import { getStatusByName } from 'assets/constants/statuses';
import { getRoleByName } from 'assets/constants/userRoles';
import TABLE_ACTIONS from 'assets/constants/tableActions';
import { Status } from 'components/molecules';
import { useLocale, useModal } from 'util/hooks';
import MODAL_KEYS from 'assets/constants/modalKeys';

const useTableClubManagersColumns = (props = {}) => {
  const { refetchUsers = () => { } } = props;
  const { t, dir, isAr } = useLocale();
  const { addModal } = useModal();

  const handleOpenModal = (type, username) => {
    addModal({
      key: MODAL_KEYS.userStatus,
      props: {
        type,
        username,
        refetchUsers,
      },
    });
  };

  return (
    [
      {
        field: 'fullName',
        headerName: t('common.name'),
        flex: 1,
        minWidth: 250,
      },
      {
        field: 'email',
        headerName: t('common.email'),
        flex: 1,
        minWidth: 325,
      },
      {
        field: 'clubName',
        valueGetter: ({ row }) => {
          const { clubDetails = {} } = row || {};

          return isAr
            ? clubDetails?.nameAr
            : clubDetails?.nameEn;
        },
        headerName: t('common.university'),
        flex: 1,
        minWidth: 250,
      },
      {
        field: 'type',
        headerName: t('common.role'),
        flex: 1,
        minWidth: 180,
        valueGetter: (params) => t(getRoleByName(params?.row?.type)?.localizationKey),
      },
      {
        field: 'status',
        headerName: t('common.status'),
        flex: 1,
        minWidth: 180,
        renderCell: (params) => (
          <Status
            status={params.row.status || ''}
            label={t(getStatusByName(params?.row?.status)?.localizationKey) || ''}
          />
        ),
      },
      {
        field: 'actions',
        headerName: t('common.actions'),
        type: 'actions',
        flex: 1,
        minWidth: 50,
        getActions: ({ row }) => {
          const { status, username } = row;
          const isActive = status === getStatusByName('active').name;
          const isPending = status === getStatusByName('pending').name;
          const isSuspended = status === getStatusByName('suspended').name;

          const actions = [];
          if (isActive) {
            actions.push(
              <GridActionsCellItem
                component={Link}
                dir={dir}
                label={t('actions.updateInfo')}
                to={username}
                showInMenu
              />,
              <GridActionsCellItem
                dir={dir}
                label={t('actions.suspendUser')}
                onClick={() => handleOpenModal(
                  TABLE_ACTIONS.userManagement.suspend,
                  username,
                )}
                showInMenu
              />,
            );
          } else if (isPending || isSuspended) {
            actions.push(
              <GridActionsCellItem
                dir={dir}
                label={t('actions.activateUser')}
                onClick={() => handleOpenModal(
                  TABLE_ACTIONS.userManagement.activateByAdmin,
                  username,
                )}
                showInMenu
              />,
            );
          }

          return actions;
        },
      },
    ]
  );
};

export default useTableClubManagersColumns;
