import { DataGrid } from 'components/core';
import { useLocale } from 'util/hooks';
import { useTableAdminColumns } from 'components/organisms';

import { ROLE_IDS } from 'assets/constants/userRoles';
import { useUsers } from 'reactQuery/queries';
import useTableClubManagerColumns from './useTableClubManagerColumns';
import useTableContentCreatorColumns from './useTableContentCreatorColumns';

const UsersTable = (props) => {
  const { roles = [] } = props;

  const filterOptions = {
    role: roles,
  };

  const { t } = useLocale();

  const {
    data = { items: [], meta: {} },
    isFetching = true,
    refetch: refetchUsers = () => { },
  } = useUsers({
    queryKeyOptions: { filterOptions },
  });

  const adminColumns = useTableAdminColumns({ refetchUsers });
  const clubManagersColumns = useTableClubManagerColumns({ refetchUsers });
  const contentCreatorsColumns = useTableContentCreatorColumns({ refetchUsers });

  const getUserTableColumns = () => {
    let userColumns = null;
    const role = roles[0];
    if (role === ROLE_IDS.admin) {
      userColumns = adminColumns;
    } if (role === ROLE_IDS.clubManager) {
      userColumns = clubManagersColumns;
    } if (role === ROLE_IDS.contentCreator) {
      userColumns = contentCreatorsColumns;
    }
    return userColumns;
  };

  const columns = getUserTableColumns();

  const {
    items: rows = [],
    meta = {},
  } = data || {};

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      loading={isFetching}
      pagination={meta}
      componentsProps={{
        noRowsOverlay: { type: 'user', showButton: true },
        toolbar: {
          placeholder: t('users.searchUsersPlaceholder'),
        },
      }}
    />
  );
};

export default UsersTable;
