import DOMPurify from 'dompurify';

import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';

import { CardMediaWithFallback } from 'components/molecules';
import { useLocale } from 'util/hooks';
import { capitalizeFirstLetter } from 'util/helpers';
import { STATUS_NAMES } from 'assets/constants/statuses';
import { VerifiedIcon } from 'assets/icons';

const CertificateAnnouncementCard = (props) => {
  const { postDetails = {} } = props;

  const { t } = useLocale();

  const {
    content = '',
    cover = '',
    userCertificate = {},
  } = postDetails;
  const {
    status = '',
    certificate = {},
  } = userCertificate;
  const {
    provider = {},
  } = certificate;

  const isVerified = status === STATUS_NAMES.verified;

  const certificateStatus = (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: 17.5, height: 17.5, mr: 1 }}>
        <VerifiedIcon width="17.5" height="17.5" />
      </Box>
      <Typography variant="bodySmallRegular">
        {t('certificates.verifiedByCyberHub')}
      </Typography>
    </Box>
  );

  return (
    <CardContent
      sx={{
        display: 'flex',
        flexDirection: {
          xs: 'column-reverse',
          sm: 'row',
        },
        justifyContent: 'space-between',
        p: 0,
      }}
    >
      <Box>
        {isVerified && (
          <Box sx={{ mb: 4 }}>
            {certificateStatus}
          </Box>
        )}
        <Box
          dir="auto"
          sx={({ typography }) => ({
            color: 'common.darkGray',
            ...typography.bodySmallRegular,
          })}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(content),
          }}
        />
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
        }}
        >
          <Typography variant="bodySmallMedium">
            {t('common.providedBy')}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              ml: 1,
            }}
          >
            <Avatar
              src={provider?.logo}
              alt={capitalizeFirstLetter(provider?.name)}
              sx={{
                width: 20,
                height: 20,
                mr: 1,
              }}
            />
            <Typography variant="bodySmallRegular">
              {provider?.name}
            </Typography>
          </Box>
        </Box>
      </Box>
      <CardMediaWithFallback
        sx={{
          borderRadius: 1.5,
          minWidth: { sm: 350 },
          width: { sm: 350 },
          alignSelf: 'center',
        }}
        component="img"
        src={cover}
        height="183"
        alt="certificate-image"
      />
    </CardContent>
  );
};

export default CertificateAnnouncementCard;
