import { useParams } from 'react-router-dom';
import { getProfileRank } from 'services';
import { useCustomQuery } from 'reactQuery';

const useProfileRank = (queryProps = {}) => {
  const { username } = useParams();

  const query = useCustomQuery({
    queryKey: [`profile-stats-${username}`],
    queryFn: () => getProfileRank(username),
    ...queryProps,
  });

  return { ...query };
};

export default useProfileRank;
