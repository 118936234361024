import {
  Avatar,
  Box, Card, CardMedia, Link, Typography, useMediaQuery,
} from '@mui/material';
import { POST_TYPES, POST_TYPES_DETAILS } from 'assets/constants/posts';
import { PATHS } from 'routes';
import { capitalizeFirstLetter } from 'util/helpers';
import { useDate, useLocale } from 'util/hooks';

const LandingPostCard = (props) => {
  const { post = {} } = props;

  const { fromNow } = useDate();
  const { t, isAr } = useLocale();
  const isMobileView = useMediaQuery(({ breakpoints }) => breakpoints.down('sm'));

  const maxContentLength = 150;

  const {
    author: {
      fullName = '',
      avatar = '',
      clubName = '',
    } = {},
    topic: {
      nameEn: topicNameEn = '',
      nameAr: topicNameAr = '',
    } = {},
    id,
    type,
    video,
    title,
    content,
    trimmedContent,
    createdAt,
  } = post || {};

  const postType = POST_TYPES_DETAILS.find((availableType) => availableType.key === type) || {};

  const cardTextContent = (text) => (
    <Typography
      sx={{ overflowWrap: 'break-word' }}
      variant={isMobileView
        ? 'bodySmallRegular'
        : 'bodyStandardRegular'}
    >
      {text}
    </Typography>
  );

  const header = (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Avatar
        src={`${avatar}`}
        alt={capitalizeFirstLetter(fullName)}
      />

      <Box sx={{ pl: 2.5, display: 'flex', flexDirection: 'column' }}>
        <Typography variant="bodySmallBold" sx={{ display: 'block' }}>
          {fullName}
        </Typography>
        <Typography variant="bodyMicroRegular">
          {clubName}
        </Typography>
      </Box>
    </Box>
  );

  const renderCardContent = () => {
    switch (type) {
      case POST_TYPES.article:
        return (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="bodyStandardMedium" sx={{ mb: 2 }}>
              {title}
            </Typography>
            <Typography variant="bodyStandardRegular">
              {cardTextContent(trimmedContent?.slice(0, maxContentLength))}
              {trimmedContent?.length > maxContentLength ? '...' : ''}
            </Typography>
          </Box>
        );

      case POST_TYPES.knowledgeShare:
        return cardTextContent(content);

      case POST_TYPES.video:
        return (
          <>
            <CardMedia
              sx={{
                borderRadius: 1.5,
                backgroundColor: 'black',
              }}
              component="video"
              src={video}
              height="150"
              alt="No video found"
              controls
              autoPlay
              muted
            />
            <Box sx={{ mt: 4 }}>
              <Typography variant="bodyStandardMedium">
                {title}
              </Typography>
            </Box>
          </>
        );

      default:
        return '';
    }
  };

  const body = (
    <Box sx={{ flex: 1 }}>
      <Box sx={{
        pt: 6,
        pb: 2,
        display: 'flex',
        justifyContent: 'start',
      }}
      >
        {postType?.smallIcon}
        <Typography variant="bodySmallMedium" sx={{ pl: 1.5 }}>
          {`${t(`postsTypesList.${postType?.shortKey}`)}: ${isAr ? topicNameAr : topicNameEn}`}
        </Typography>
      </Box>
      <Typography variant="bodyStandardRegular">
        {renderCardContent()}
      </Typography>
    </Box>
  );

  const footer = (
    <Typography
      variant="bodySmallRegular"
      color="common.berylliumGrey"
      sx={{ textAlign: 'end' }}
    >
      {fromNow(createdAt)}
    </Typography>
  );

  return (
    <Link to={`/${PATHS.posts}/${id}`}>
      <Card sx={{
        minHeight: 366,
        borderRadius: 1,
        width: 310,
        backgroundColor: 'common.white',
        boxShadow: ({ palette }) => (`0px 0px 35px ${palette.common.boxShadow4}`),
        p: 5,
        display: 'flex',
        flexDirection: 'column',

        transition: 'transform 0.1s ease-in-out',
        ':hover': {
          transform: { lg: 'scale(1.1)' },
        },
      }}
      >
        {header}
        {body}
        {footer}
      </Card>
    </Link>
  );
};
export default LandingPostCard;
