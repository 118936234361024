import {
  Box,
  Button, Card, Typography,
} from '@mui/material';

import { useAuth, useLocale } from 'util/hooks';
import { Link } from 'react-router-dom';
import { FailIcon, SuccessIcon } from 'assets/icons';

const ConnectionMessageCard = (props) => {
  const { t } = useLocale();
  const { currentUser } = useAuth();
  const { username } = currentUser;

  const { isSuccessful = false, error = '' } = props;

  const cardContent = isSuccessful ? {
    icon: <SuccessIcon />,
    header: t('flagyard.linkedSuccessfully'),
    content: t('flagyard.linkedSuccessfullyDesc'),
  } : {
    icon: <FailIcon />,
    header: t('flagyard.connectionFailed'),
    content: error,
  };

  return (
    <Card
      sx={{
        py: 10,
        px: 21,
        backgroundColor: 'common.white',
        textAlign: 'center',
      }}
    >
      <Box mb={9}>
        {cardContent.icon}
      </Box>
      <Typography variant="h5" mb={5}>
        {cardContent.header}
      </Typography>
      <Typography mb={8}>
        {cardContent.content}
      </Typography>
      <Button
        component={Link}
        to={`/profile/${username}`}
        variant="contained"
      >
        {t('flagyard.viewYourProfile')}
      </Button>
    </Card>
  );
};

export default ConnectionMessageCard;
