import { useParams } from 'react-router-dom';
import { useCustomQuery } from 'reactQuery';
import { getProviderDetails } from 'services';

const useProviderDetails = () => {
  const { providerId } = useParams();

  const query = useCustomQuery({
    queryKey: [`provider-details-${providerId}`],
    queryFn: () => getProviderDetails(providerId),
  });

  return { ...query };
};

export default useProviderDetails;
