import { getClubs } from 'services';
import { useCustomQuery } from 'reactQuery';

const useClubs = (queryProps = {}) => {
  const { queryKeyOptions = {}, ...rest } = queryProps;

  const query = useCustomQuery({
    queryKey: ['clubs', queryKeyOptions],
    queryFn: getClubs,
    ...rest,
  });

  return { ...query };
};

export default useClubs;
