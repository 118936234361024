import { useTheme } from '@mui/styles';

const PointsIcon = (props) => {
  const { palette } = useTheme();

  const {
    fill = 'none',
    innerFill = palette.common.turquoiseSeaweedGreen,
  } = props;

  return (
    <svg width="15" height="14" viewBox="0 0 15 14" fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M7.29683 0.196289C3.54122 0.196289 0.5 3.24432 0.5 6.99992C0.5 10.7555 3.54122 13.8036 7.29683 13.8036C11.0592 13.8036 14.1073 10.7555 14.1073 6.99992C14.1073 3.24432 11.0592 0.196289 7.29683 0.196289ZM10.1816 11.0821L7.30364 9.34718L4.4257 11.0821L5.18771 7.80956L2.64995 5.61198L5.99734 5.32623L7.30364 2.23738L8.60993 5.31943L11.9573 5.60518L9.41957 7.80275L10.1816 11.0821Z" fill={innerFill} />
    </svg>
  );
};
export default PointsIcon;
