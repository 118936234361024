import { DataGrid } from 'components/core';
import { useClubs } from 'reactQuery/queries';
import useTableColumns from './useTableColumns';

const ClubsTable = () => {
  const {
    data = { items: [], meta: {} },
    isFetching = true,
    refetch: refetchClubs = () => { },
  } = useClubs({
    queryKeyOptions: {
      pagination: { limit: 15 },
    },
  });

  const {
    items: rows = [],
    meta = {},
  } = data || {};

  const columns = useTableColumns({ refetchClubs });

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      loading={isFetching}
      pagination={meta}
    />
  );
};

export default ClubsTable;
