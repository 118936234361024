import { useTheme } from '@mui/styles';

const UpdateLinkIcon = (props) => {
  const { palette } = useTheme();

  const {
    fill = palette.common.pinkishRed,
  } = props;

  return (
    <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="12" cy="7" r="3" fill={fill} />
      <path d="M14 7.28571H12.2857V9H11.7143V7.28571H10V6.71429H11.7143V5H12.2857V6.71429H14V7.28571Z" fill="white" />
      <path d="M5.12861 9.14237L4.12406 10.1454C3.9118 10.3576 3.62393 10.4769 3.32377 10.4769C3.02361 10.4769 2.73574 10.3576 2.52348 10.1454L1.85505 9.47696C1.74978 9.37196 1.66626 9.24723 1.60927 9.1099C1.55229 8.97258 1.52295 8.82536 1.52295 8.67668C1.52295 8.52799 1.55229 8.38077 1.60927 8.24345C1.66626 8.10612 1.74978 7.98138 1.85505 7.87639L4.72313 5.00755C4.80318 4.92744 4.89824 4.86389 5.00286 4.82054C5.10748 4.77718 5.21962 4.75487 5.33287 4.75487C5.44612 4.75487 5.55826 4.77718 5.66288 4.82054C5.76751 4.86389 5.86256 4.92744 5.94261 5.00755L6.46852 5.53345C6.61332 5.66654 6.80397 5.7385 7.0006 5.73429C7.19724 5.73008 7.38463 5.65002 7.5236 5.51085C7.66258 5.37168 7.74237 5.18417 7.74631 4.98753C7.75024 4.79089 7.67801 4.60035 7.54471 4.45573L7.01881 3.92983C6.56488 3.49541 5.9608 3.25293 5.33249 3.25293C4.70418 3.25293 4.1001 3.49541 3.64617 3.92983L0.778093 6.79867C0.531413 7.04526 0.33573 7.33803 0.202223 7.66027C0.0687161 7.9825 0 8.32788 0 8.67668C0 9.02547 0.0687161 9.37085 0.202223 9.69308C0.33573 10.0153 0.531413 10.3081 0.778093 10.5547L1.44652 11.2239C1.945 11.7209 2.62022 12 3.32415 12C4.02808 12 4.7033 11.7209 5.20178 11.2239L6.20633 10.2208C6.34516 10.0771 6.42199 9.88457 6.42025 9.68473C6.41851 9.48489 6.33836 9.29373 6.19704 9.15241C6.05573 9.0111 5.86457 8.93094 5.66472 8.9292C5.46488 8.92747 5.27236 9.00429 5.12861 9.14313V9.14237Z" fill={fill} />
      <path fillRule="evenodd" clipRule="evenodd" d="M8.47482 0C9.17875 0 9.85397 0.279107 10.3524 0.776137L11.0216 1.44533C11.2683 1.69186 11.464 1.98457 11.5975 2.30674C11.6903 2.53081 11.7518 2.76608 11.7807 3.00591C11.2441 3.03491 10.7357 3.16963 10.2759 3.38963C10.2772 3.36733 10.2779 3.34497 10.2779 3.32258C10.2779 3.17387 10.2485 3.02662 10.1915 2.88929C10.1344 2.75195 10.0508 2.62723 9.94544 2.52229L9.27625 1.8531C9.06399 1.64086 8.77612 1.52163 8.47596 1.52163C8.1758 1.52163 7.88793 1.64086 7.67567 1.8531L6.67189 2.85612C6.52768 2.99174 6.33638 3.06593 6.13844 3.06299C5.94051 3.06006 5.75149 2.98024 5.61137 2.84041C5.47124 2.70059 5.39102 2.51173 5.38767 2.31381C5.38431 2.11588 5.4581 1.92442 5.5934 1.77993L6.59719 0.776137C7.09567 0.279107 7.77089 0 8.47482 0ZM8.14756 5.91979L7.07508 6.9917C6.99508 7.0719 6.90004 7.13553 6.79541 7.17894C6.69078 7.22235 6.57861 7.2447 6.46534 7.2447C6.35206 7.2447 6.23989 7.22235 6.13526 7.17894C6.03063 7.13553 5.9356 7.0719 5.85559 6.9917L5.32969 6.4658C5.26048 6.3886 5.17625 6.32633 5.08215 6.28281C4.98805 6.23928 4.88606 6.21541 4.78242 6.21266C4.67877 6.20991 4.57566 6.22833 4.47938 6.2668C4.3831 6.30527 4.29568 6.36298 4.22247 6.43639C4.14927 6.50981 4.09181 6.59739 4.05361 6.69378C4.01541 6.79016 3.99728 6.89333 4.00033 6.99696C4.00338 7.1006 4.02753 7.20252 4.07133 7.2965C4.11512 7.39048 4.17762 7.47453 4.25502 7.54352L4.78092 8.06942C5.22814 8.51642 5.83456 8.76752 6.46686 8.76752C7.09613 8.76752 7.69976 8.51883 8.14634 8.07585C8.05098 7.73354 8 7.37272 8 7C8 6.6257 8.05141 6.2634 8.14756 5.91979Z" fill={fill} />
    </svg>

  );
};

export default UpdateLinkIcon;
