import { Box, Typography } from '@mui/material';
import { ColoredBox } from 'components/molecules';
import { Fragment, useEffect, useState } from 'react';
import { useLocale } from 'util/hooks';

const CounterDown = (props) => {
  const { date = '' } = props;
  const { t } = useLocale();

  const dateToBeCounted = new Date(date).getTime();

  const [countDown, setCountDown] = useState(
    dateToBeCounted - new Date().getTime(),
  );

  const [timerValues, setTimerValues] = useState([]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(dateToBeCounted - new Date().getTime());
    }, 1000);

    const v = timeCalculator(countDown);
    setTimerValues(v);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countDown]);

  const timeCalculator = (countDownDate) => {
    const days = Math.floor(countDownDate / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (countDownDate % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
    );
    const minutes = Math.floor((countDownDate % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((countDownDate % (1000 * 60)) / 1000);
    return [{ time: days, label: t('common.days') },
      { time: hours, label: t('common.hours') },
      { time: minutes, label: t('common.minutes') },
      { time: seconds, label: t('common.seconds') }];
  };

  if (dateToBeCounted - new Date().getTime() <= 0) {
    return '';
  }

  return (
    <>
      <Typography
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
        variant="bodyStandardRegular"
        color="common.winterDaysGray"
      >
        {t('common.nextSession')}
      </Typography>
      <Box
        sx={{
          mt: 4,
          display: 'flex',
          columnGap: 2,
          flexDirection: 'row',
        }}
      >
        {timerValues?.map((menuItem, index) => (
          <Fragment key={menuItem.label}>
            <Box>
              <ColoredBox
                sx={{
                  width: { xs: 70, sm: 92 },
                  height: { xs: 75, sm: 96 },
                }}
                value={(
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                  >
                    <Typography
                      sx={{ mt: { xs: 2, sm: 4 }, mb: 1 }}
                      variant="bodyMediumRegular"
                      color="common.winterDaysGray"
                    >
                      {menuItem.time}
                    </Typography>
                    <Typography
                      variant="bodySmallRegular"
                      color="common.winterDaysGray"
                    >
                      {menuItem.label}
                    </Typography>
                  </Box>
                )}
              />
            </Box>
            {timerValues.length !== index + 1 && (
              <Typography sx={{
                color: 'common.winterDaysGray',
                display: 'flex',
                alignItems: 'center',
              }}
              >
                :
              </Typography>
            )}
          </Fragment>
        ))}
      </Box>
    </>
  );
};

export default CounterDown;
