import React from 'react';
import Box from '@mui/material/Box';

import { NoPostsIcon } from 'assets/icons';
import { useLocale } from 'util/hooks';
import { PostCardContainer } from 'components/organisms';
import { CustomInfiniteScroll, DataPlaceholder } from 'components/molecules';
import { useInfiniteAdminPosts } from 'reactQuery/queries';

const PostList = (props) => {
  const { status } = props;

  const { t } = useLocale();

  const {
    data: { pages = [] } = {},
    isFetched = false,
    isFetching = false,
    hasData = true,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteAdminPosts({
    queryKeyOptions: {
      filterOptions: { status },
    },
  });

  const posts = React.useMemo(() => pages?.map((page) => {
    const key = `admin-posts-page-${page.meta?.currentPage}`;

    return (
      <div key={key}>
        {page.items?.map((post) => (
          <Box key={post.id} mt={2}>
            <PostCardContainer
              key={post.id}
              postDetails={post}
            />
          </Box>
        ))}
      </div>
    );
  }), [pages]);

  if (isFetched && !hasData) {
    return (
      <DataPlaceholder
        icon={<NoPostsIcon />}
        header={t('posts.placeholder.noPosts')}
      />
    );
  }

  return (
    <CustomInfiniteScroll
      loadMore={fetchNextPage}
      hasMore={hasNextPage}
      isFetching={isFetching}
    >
      {posts}
    </CustomInfiniteScroll>
  );
};

export default PostList;
