import * as Yup from 'yup';
import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { useTheme } from '@mui/styles';
import { Box, Button } from '@mui/material';

import {
  LevelIcon,
  TopicIcon,
  OutlinedLanguageIcon,
  OutlinedTrackIcon,
} from 'assets/icons';

import {
  FormTags,
  FormSelect,
  FormTextField,
  FormAutocomplete,
} from 'components/form';
import {
  getLevels,
  getTopics,
  getProviders,
  getContentCreators,
} from 'services';

import {
  useAuth,
  useLocale,
  useFastForm,
} from 'util/hooks';
import { useCourseTracks } from 'reactQuery/queries';

const CourseFormStepOne = (props) => {
  const {
    courseFormInfo = {},
    setCourseFormInfo = () => { },
    handleNextStep = () => { },
    courseDetails = {},
    isOnDemandCourse = false,
  } = props;

  const { t } = useLocale();
  const { palette } = useTheme();
  const { isAdmin, isContentCreator } = useAuth();

  const {
    isCourseRunning = false,
    isCourseCompleted = false,
    isCoursePublished = false,
  } = courseDetails || {};

  const shouldValidateManagedBy = isAdmin && !isOnDemandCourse;

  const iconColor = isCourseCompleted
    ? palette.text.secondary
    : palette.common.couchGrey;

  const {
    data: tracks = [],
  } = useCourseTracks();

  const languages = [
    { id: 'arabic', label: t('common.arabic') },
    { id: 'english', label: t('common.english') },
  ];

  const defaultDescription = `  • ${t('course.aboutCourse')}: 
  
  • ${t('course.toWhom')}:

  • ${t('course.speakers')}:
  `;

  const onSubmit = (values) => {
    setCourseFormInfo({
      ...courseFormInfo,
      title: values.title,
      topic: values.topic.id,
      level: values.level,
      track: values.track,
      language: values.language,
      description: values.description,
      tags: values.tags,
      provider: values.provider,
      managedBy: values.managedBy,
    }, handleNextStep());
  };

  const courseTags = isEmpty(courseFormInfo?.tags)
    ? courseDetails?.tags : courseFormInfo?.tags;

  const defaultValues = {
    isAdmin,
    shouldValidateManagedBy,
    title: courseFormInfo?.title || courseDetails?.title || '',
    topic: courseFormInfo?.topic || courseDetails?.topic || '',
    level: courseFormInfo?.level || courseDetails?.level || '',
    track: courseFormInfo?.track || courseDetails?.track || '',
    language: courseFormInfo?.language || courseDetails?.language || '',
    description: courseFormInfo?.description || courseDetails?.description || defaultDescription,
    tags: courseTags || [],
    provider: courseFormInfo?.provider || courseDetails?.provider || '',
    managedBy: courseFormInfo?.managedBy || courseDetails?.managedBy || '',
  };

  const validationSchema = Yup.object({
    isAdmin: Yup.bool(),
    shouldValidateManagedBy: Yup.bool(),
    title: Yup
      .string()
      .max(100, t('course.validation.titleLength'))
      .required(t('course.validation.titleValidation')),
    topic: Yup
      .object()
      .nullable(true)
      .required(t('certificates.forms.validation.selectTopic')),
    level: Yup
      .object()
      .nullable(true)
      .required(t('certificates.forms.validation.selectLevel')),
    track: Yup
      .object()
      .nullable(true)
      .required(t('certificates.forms.validation.selectTrack')),
    language: Yup
      .string()
      .required(t('course.validation.selectLanguage')),
    description: Yup
      .string()
      .required(t('course.validation.descriptionValidation')),
    tags: Yup
      .array()
      .nullable(true),
    provider: Yup
      .object()
      .nullable(true)
      .when('isAdmin', {
        is: true,
        then: (schema) => schema.required(t('certificates.forms.validation.selectProvider')),
        otherwise: (schema) => schema.notRequired(),
      }),
    managedBy: Yup
      .object()
      .nullable(true)
      .when('shouldValidateManagedBy', {
        is: true,
        then: (schema) => schema.required(t('course.validation.selectContentCreator')),
        otherwise: (schema) => schema.notRequired(),
      }),
  });

  const {
    handleSubmit,
    getValues,
    setError,
    setValue,
    control,
    watch,
  } = useFastForm({
    defaultValues,
    validationSchema,
  });

  const showProviderManagerField = !!watch('provider') && !isOnDemandCourse;
  const providerId = getValues('provider')?.id;
  const providerValue = watch('provider');

  useEffect(() => {
    !providerValue && setValue('managedBy', '');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providerValue]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: 6,
        py: 10,
        px: {
          sm: 15,
          xs: 5,
        },
        m: 'auto',
        width: 1,
        maxWidth: 682,
      }}
      >
        <FormTextField
          name="title"
          control={control}
          label={t('course.courseTitle')}
          fullWidth
          disabled={isCourseCompleted
            || (isContentCreator && (isCoursePublished || isCourseRunning))}
        />
        <FormAutocomplete
          name="topic"
          control={control}
          icon={(
            <TopicIcon
              width={16}
              height={17}
              stroke={iconColor}
            />
          )}
          label={t('certificates.forms.topic')}
          fullWidth
          selectFunc={getTopics}
          disabled={isCourseCompleted || isCourseRunning || (isContentCreator && isCoursePublished)}
        />
        <FormAutocomplete
          name="level"
          control={control}
          icon={(
            <LevelIcon
              width={19}
              height={20}
              fill={iconColor}
            />
          )}
          label={t('certificates.forms.level')}
          fullWidth
          selectFunc={getLevels}
          disabled={isCourseCompleted || (isContentCreator && isCoursePublished)}
        />
        <FormAutocomplete
          name="track"
          control={control}
          icon={(
            <OutlinedTrackIcon
              width={17}
              height={18}
              fill={iconColor}
            />
          )}
          label={t('certificates.forms.track')}
          fullWidth
          withOptions
          options={tracks}
          disabled={isCourseCompleted || (isContentCreator && isCoursePublished)}
        />
        <FormSelect
          name="language"
          options={languages}
          control={control}
          icon={(
            <OutlinedLanguageIcon
              width={17}
              height={18}
              fill={iconColor}
            />
          )}
          label={t('common.language')}
          fullWidth
          disabled={isCourseCompleted || (isContentCreator
            && (isCoursePublished || isCourseRunning))}
        />
        <FormTextField
          name="description"
          control={control}
          label={t('course.description')}
          fullWidth
          multiline
          minRows={7}
          disabled={isContentCreator
            && (isCourseCompleted || isCoursePublished || isCourseRunning)}
        />
        <FormTags
          name="tags"
          control={control}
          label={t('certificates.forms.optionalTags')}
          fullWidth
          disabled={isCourseCompleted
            || (isContentCreator && (isCoursePublished || isCourseRunning))}
          isCourseCompleted={isCourseCompleted}
          setError={(message) => setError('tags', {
            type: 'manual',
            message,
          })}
        />
        {isAdmin
          && (
            <>
              <FormAutocomplete
                name="provider"
                control={control}
                label={t('common.provider')}
                fullWidth
                selectFunc={getProviders}
                disabled={isCourseCompleted}
              />
              {showProviderManagerField && (
                <FormAutocomplete
                  name="managedBy"
                  control={control}
                  label={t('roles.contentCreator')}
                  fullWidth
                  selectFunc={() => getContentCreators(providerId)}
                  disabled={isCourseCompleted}
                />
              )}
            </>
          )}
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            sx={{ width: 1, maxWidth: 186 }}
            type="submit"
            variant="contained"
            size="large"
          >
            {t('common.next')}
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default CourseFormStepOne;
