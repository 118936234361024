import END_POINTS from '../endPoints';
import api from '../api';

const getUsers = (options) => api(
  END_POINTS.getUsers,
  options,
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default getUsers;
