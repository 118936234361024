import { useEffect } from 'react';
import useLocale from './useLocale';

const { REACT_APP_NAME } = process.env;

const usePageTitle = (title) => {
  const { t } = useLocale();
  const resolvedTitle = title || t('common.cyberHub') || REACT_APP_NAME || 'CyberHub';

  useEffect(() => {
    document.title = resolvedTitle;
  }, [resolvedTitle]);
};

export default usePageTitle;
