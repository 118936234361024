import { Navigate, useLocation } from 'react-router-dom';

const RemoveTrailingSlash = (props) => {
  const { pathname, search } = useLocation();

  // If the last character of the url is '/'
  if (pathname.match('/.*/$')) {
    return (
      <Navigate
        replace
        {...props}
        to={{
          pathname: pathname.replace(/\/+$/, ''),
          search,
        }}
      />
    );
  }

  return null;
};

export default RemoveTrailingSlash;
