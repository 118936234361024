import { Box } from '@mui/material';
import { URLSearchField } from 'components/molecules';
import { SuspenseWrapper } from 'components/render';
import { useLocale } from 'util/hooks';
import UserCertificateList from './UserCertificateList';

const UserCertificatesWrapper = (props) => {
  const { t } = useLocale();

  return (
    <>
      <Box sx={{ mb: 6 }}>
        <URLSearchField placeholder={t('certificates.searchBy')} />
      </Box>
      <SuspenseWrapper>
        <UserCertificateList {...props} />
      </SuspenseWrapper>
    </>
  );
};

export default UserCertificatesWrapper;
