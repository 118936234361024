import { useState } from 'react';
import {
  LocalizationProvider,
  DatePicker as MUIDatePicker,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TextField } from '@mui/material';

const DatePicker = (props) => {
  const {
    pickerProps = {
      onChange: () => { },
      InputAdornmentProps: {},
    },
    inputProps = {},
  } = props;

  const [open, setOpen] = useState(false);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MUIDatePicker
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        renderInput={(params) => (
          <TextField
            autoComplete="off"
            onClick={(e) => setOpen(!open)}
            {...params}
            {...inputProps}
          />
        )}
        {...pickerProps}
      />
    </LocalizationProvider>
  );
};

export default DatePicker;
