import { Box, Chip } from '@mui/material';
import { useTheme } from '@mui/styles';

const Status = (props) => {
  const {
    status = '',
  } = props;

  const theme = useTheme();

  const styles = {
    padding: 0,
    backgroundColor: 'transparent',
    '& .MuiChip-label': {
      pl: 2,
    },
    '& .MuiChip-icon': {
      backgroundColor: theme.palette[status].contrastText,
      m: 0,
    },
  };

  const iconStyle = (
    <Box
      sx={{
        width: 10,
        height: 10,
        borderRadius: '50%',
      }}
    />
  );

  return (
    <Chip
      {...props}
      icon={iconStyle}
      color={status}
      sx={styles}
    />
  );
};

export default Status;
