import React from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

import { useInfiniteUserCertificates } from 'reactQuery/queries';
import { CertificateCard } from 'components/organisms';
import { CustomInfiniteScroll, DataPlaceholder } from 'components/molecules';
import { useLocale } from 'util/hooks';
import { certificatesLogo } from 'assets/images';

const UserCertificateList = (props) => {
  const {
    status,
    orderBy = '',
    order = 'DESC',
  } = props;

  const { t } = useLocale();

  const {
    data: { pages = [] } = {},
    isFetched = false,
    isFetching = false,
    refetch: refetchCertificates = () => { },
    hasData = true,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteUserCertificates({
    queryKeyOptions: {
      filterOptions: { status },
      searchOptions: { order, orderBy },
    },
  });

  const certificateList = React.useMemo(() => pages?.map((page) => {
    const key = `user-certificates-${page.meta?.currentPage}`;

    return (
      <div key={key}>
        {page.items?.map((certificate) => (
          <Box
            key={certificate.id}
            sx={{
              maxWidth: 1000,
              mt: 2,
            }}
          >
            <CertificateCard
              certificate={certificate}
              refetchCertificates={refetchCertificates}
            />
          </Box>
        ))}
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [pages]);

  if (isFetched && !hasData) {
    return (
      <DataPlaceholder
        icon={(
          <img
            src={certificatesLogo}
            alt="certificates-logo"
            width="147"
            height="167"
          />
        )}
        header={t('certificates.noCertificates')}
      />
    );
  }

  return (
    <>
      <Box sx={{
        height: 2,
        mt: -5,
        mb: 4,
      }}
      >
        {isFetching && <LinearProgress sx={{ borderRadius: 8 }} />}
      </Box>
      <CustomInfiniteScroll
        loadMore={fetchNextPage}
        hasMore={hasNextPage}
        isFetching={isFetching}
      >
        {certificateList}
      </CustomInfiniteScroll>
    </>
  );
};

export default UserCertificateList;
