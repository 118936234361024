import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

/**
 * https://react-hook-form.com/api/useform/
 * @param {*} props
 * @returns form methods
 */
const useFastForm = (props) => {
  const { validationSchema, ...rest } = props;

  const form = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    ...rest,
  });

  return form;
};

export default useFastForm;
