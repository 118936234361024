import { Box } from '@mui/material';
import SearchToolbar from './SearchToolbar';
import TableHeader from './TableHeader';

const CustomToolbar = (props) => {
  const {
    component = {},
    filter = null,
    searchSx = {},
    filterSx = {},
    toolbarSx = {},
    tableHeaderProps = {},
    withTableHeader = false,
    containerProps = {},
  } = props;

  return (
    <>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        columnGap: 2,
        rowGap: 4,
        mb: 6,
        ...toolbarSx,
      }}
      >
        <Box
          display="flex"
          alignItems="center"
          flexWrap="wrap"
          columnGap={5}
          rowGap={4}
          sx={{ ...containerProps }}
        >
          <Box sx={{
            width: {
              xs: 1,
              md: '396px',
            },
            ...searchSx,
          }}
          >
            <SearchToolbar sx={{ mb: 0 }} />
          </Box>
          {filter
        && (
        <Box sx={{
          width: {
            xs: 1,
            md: '334px',
          },
          ...filterSx,
        }}
        >
          {filter}
        </Box>
        )}
        </Box>
        <Box>
          {component}
        </Box>
      </Box>
      {withTableHeader
      && <TableHeader tableHeaderProps={tableHeaderProps} />}
    </>
  );
};

export default CustomToolbar;
