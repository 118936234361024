import { useParams } from 'react-router-dom';

import { getClubLeader } from 'services';
import useCustomQuery from 'reactQuery/useCustomQuery';

const useClubLeader = (queryProps = {}) => {
  const { clubId } = useParams();

  const query = useCustomQuery({
    queryKey: [`club-leader-${clubId}`],
    queryFn: () => getClubLeader(clubId),
    ...queryProps,
  });

  return { ...query };
};

export default useClubLeader;
