import END_POINTS from '../endPoints';
import METHODS from '../methods';
import api from '../api';

const revokeStudent = (courseId, data) => api(
  END_POINTS.revokeStudent(courseId),
  {
    method: METHODS.POST,
    data,
  },
)
  .then((response) => Promise.resolve(response))
  .catch((error) => Promise.reject(error));

export default revokeStudent;
