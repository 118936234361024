import {
  Box,
  Link,
  Typography,
  IconButton,
} from '@mui/material';
import { BackArrowIcon } from 'assets/icons';
import { useLocale } from 'util/hooks';

const CreatePostHeader = (props) => {
  const {
    path = '',
    headerTitle = '',
    showBackArrow = true,
  } = props;

  const {
    isAr,
  } = useLocale();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: 1,
      }}
    >
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        pb: 4,
        px: 7,
      }}
      >
        {showBackArrow
         && (
         <Box sx={{ mr: 'auto' }}>
           <IconButton
             disableRipple
             component={Link}
             to={path}
           >
             <BackArrowIcon style={{ transform: isAr ? 'none' : 'scaleX(-1)' }} />
           </IconButton>
         </Box>
         )}
        <Box sx={{ mr: showBackArrow ? 'auto' : 0 }}>
          <Typography
            variant="bodyMediumMedium"
          >
            {headerTitle}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default CreatePostHeader;
