const generalPalette = {
  common: {
    lightCyan: '#72C0C3',
    softBlue: '#51AAF3',
    cyan: '#51888A',
    limeGreen: '#608E77',
    couchGrey: '#606060',
    darkGray: '#3F4144',
    winterDaysGray: '#F6F6F6',
    ghostlyGrey: '#F3F3F3',
    silverGrey: '#E8E8E8',
    berylliumGrey: '#C8C8C8',
    pureGray: '#888888',
    explosiveGrey: '#C4C4C4',
    metalGrey: '#3f414457',
    offWhite: '#F8FAFB',
    black: '#1A1A1A',
    white: '#FFFFFF',
    grassGreen: '#60CC8B',
    success: '#5FB259',
    error: '#DD7373',
    passionFruitRed: '#D32E2E',
    lightGreen: '#E9F6EC',
    disabledGreenColor: '#AFE1AF',
    pinkishRed: '#FF4C61',
    turquoiseSeaweedGreen: '#33D69F',
    metalGray: '#00000040',
    sharkGray: 'rgba(196, 196, 196, 0.02)',
    silver: 'rgba(196, 196, 196, 0.5)',
    carTireBlack: 'rgba(63, 65, 68, 0.34)',
    boxShadow: 'rgba(0, 0, 0, 0.05)',
    boxShadow2: 'rgba(0, 0, 0, 0.06)',
    boxShadow3: 'rgba(0, 0, 0, 0.25)',
    boxShadow4: 'rgba(102, 102, 102, 0.08)',
    dropShadow: 'rgba(0, 0, 0, 0.1)',
    mistySpringGreen: 'rgba(147, 210, 158, 0.2)',
    vividOrange: '#FFB800',
    lightVividOrange: 'rgba(255, 184, 0, 0.8)',
    lightGrey: '#D3D3D3',
    transparentRed: 'rgba(255, 76, 97, 0.2)',
    cyanBlue: '#13A7FF',
    offWhiteTransparent: '#F8FAFB80',
    sapphireBlue: '#7289DA',
    veryLightBlue: '#5865F2',
    buttonBlue: '#1DA1F2',
    dimMintLeafGreen: '#4AD481',
    dimMintLeafGreenTransparent: 'rgba(120, 212, 157, 0.6)',
    mintLeafGreenTransparent: 'rgba(120, 212, 157, 0.2)',
    gadgetGray: '#909090',
    navyBlue: '#1B1B45B8',
    charlestonGreen: '#24292F',
    trueBlue: '#0078D4',
    lightMalachiteGreen: 'rgba(194, 234, 184, 0.5)',
    transparentGrey: 'rgba(63, 65, 68, 0.05)',
    transparentLightGrey: 'rgba(0, 0, 0, 0.2)',
    transparentBlack: 'rgba(0, 0, 0, 0.3)',
    mediumGreen: '#93D29E',
    leaderboardGold: 'rgba(222, 177, 109, 1)',
    leaderboardSilver: 'rgba(210, 211, 218, 1)',
    leaderboardBronze: 'rgba(185, 152, 122, 1)',
    flagyardRed: '#F0004F',
    emeraldGreen: '#4AD481',
    blanchedAlmond: '#FFF1CC',
    brightLightBlue: '#E3F2F3',
    palePink: '#FFDBDF',
  },
};

const basePalette = {
  primary: {
    main: generalPalette.common.dimMintLeafGreen,
    contrastText: generalPalette.common.white,
  },
  secondary: {
    main: generalPalette.common.couchGrey,
    contrastText: generalPalette.common.white,
  },
  background: {
    default: generalPalette.common.offWhite,
    paper: generalPalette.common.white,
  },
  text: {
    primary: generalPalette.common.darkGray,
    secondary: generalPalette.common.gadgetGray,
  },
  divider: generalPalette.common.silverGrey,
  action: {
    disabled: generalPalette.common.white,
    disabledBackground: generalPalette.common.dimMintLeafGreenTransparent,
  },
};

const statusPalette = {
  active: {
    main: generalPalette.common.lightMalachiteGreen,
    contrastText: generalPalette.common.turquoiseSeaweedGreen,
  },
  pending: {
    contrastText: generalPalette.common.vividOrange,
  },
  expired: {
    contrastText: generalPalette.common.pinkishRed,
  },
  visible: {
    contrastText: generalPalette.common.turquoiseSeaweedGreen,
  },
  hidden: {
    contrastText: generalPalette.common.vividOrange,
  },
  cancelled: {
    contrastText: generalPalette.common.pinkishRed,
  },
  suspended: {
    contrastText: generalPalette.common.navyBlue,
  },
  accepted: {
    contrastText: generalPalette.common.turquoiseSeaweedGreen,
  },
  published: {
    contrastText: generalPalette.common.turquoiseSeaweedGreen,
  },
  unpublished: {
    contrastText: generalPalette.common.couchGrey,
  },
  archived: {
    contrastText: generalPalette.common.couchGrey,
  },
  rejected: {
    contrastText: generalPalette.common.pinkishRed,
  },
  draft: {
    contrastText: generalPalette.common.metalGrey,
  },
  revised: {
    contrastText: generalPalette.common.softBlue,
  },
  underReview: {
    contrastText: generalPalette.common.vividOrange,
  },
  tags: {
    main: generalPalette.common.mintLeafGreenTransparent,
    contrastText: generalPalette.common.turquoiseSeaweedGreen,
  },
  running: {
    contrastText: generalPalette.common.turquoiseSeaweedGreen,
  },
  completed: {
    contrastText: generalPalette.common.softBlue,
  },
  revoked: {
    contrastText: generalPalette.common.pinkishRed,
  },
  enrolled: {
    contrastText: generalPalette.common.turquoiseSeaweedGreen,
  },
  withdrawn: {
    contrastText: generalPalette.common.vividOrange,
  },
  failed: {
    contrastText: generalPalette.common.pinkishRed,
  },
  inactive: {
    contrastText: generalPalette.common.pinkishRed,
  },
  'in-progress': {
    contrastText: generalPalette.common.vividOrange,
  },
  '': {
    contrastText: generalPalette.common.explosiveGrey,
  },

};

const appBarPalette = {
  appBar: {
    backgroundColor: basePalette.background.paper,
    color: basePalette.primary.contrastText,
  },
};

const drawerPalette = {
  drawer: {
    paper: {
      backgroundColor: basePalette.background.paper,
      color: basePalette.text.secondary,
    },
  },
};

const alertPalette = {
  alert: {
    success: {
      main: generalPalette.common.white,
      background: generalPalette.common.success,
    },
    error: {
      main: generalPalette.common.white,
      background: generalPalette.common.error,
    },
  },
};

const palette = {
  ...generalPalette,
  ...basePalette,
  ...statusPalette,
  ...appBarPalette,
  ...drawerPalette,
  ...alertPalette,
};

export default palette;
