const CyberHubEnglishLogo = (props) => (
  <svg width="150" height="35" viewBox="0 0 150 35" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M1.52605 7.60585C0.68553 7.60585 0 6.92739 0 6.0823V1.52355C0 0.684408 0.679569 0 1.52605 0C2.36657 0 3.0521 0.678456 3.0521 1.52355V6.0823C3.0521 6.92739 2.36657 7.60585 1.52605 7.60585Z" fill="url(#paint0_linear_1410_7250)" />
    <path d="M6.09832 7.60585C5.2578 7.60585 4.57227 6.92739 4.57227 6.0823V1.52355C4.57227 0.684408 5.25184 0 6.09832 0C6.93884 0 7.62437 0.678456 7.62437 1.52355V6.0823C7.62437 6.92739 6.93884 7.60585 6.09832 7.60585Z" fill="url(#paint1_linear_1410_7250)" />
    <path d="M10.6706 7.60585C9.83006 7.60585 9.14453 6.92739 9.14453 6.0823V1.52355C9.14453 0.684408 9.8241 0 10.6706 0C11.5111 0 12.1966 0.678456 12.1966 1.52355V6.0823C12.1907 6.92739 11.5111 7.60585 10.6706 7.60585Z" fill="url(#paint2_linear_1410_7250)" />
    <path d="M29.0129 7.60585C28.1723 7.60585 27.4868 6.92739 27.4868 6.0823V1.52355C27.4868 0.684408 28.1664 0 29.0129 0C29.8534 0 30.5389 0.678456 30.5389 1.52355V6.0823C30.533 6.92739 29.8534 7.60585 29.0129 7.60585Z" fill="url(#paint3_linear_1410_7250)" />
    <path d="M15.2428 30.3639C14.4023 30.3639 13.7168 29.6854 13.7168 28.8403V24.2816C13.7168 23.4424 14.3964 22.758 15.2428 22.758C16.0834 22.758 16.7689 23.4365 16.7689 24.2816V28.8403C16.7629 29.6854 16.0834 30.3639 15.2428 30.3639Z" fill="url(#paint4_linear_1410_7250)" />
    <path d="M15.2428 16.7055C14.4023 16.7055 13.7168 16.027 13.7168 15.1819V6.08825C13.7168 5.2491 14.3964 4.5647 15.2428 4.5647C16.0834 4.5647 16.7689 5.24315 16.7689 6.08825V15.1819C16.7629 16.027 16.0834 16.7055 15.2428 16.7055Z" fill="url(#paint5_linear_1410_7250)" />
    <path d="M10.6706 34.9583C9.83006 34.9583 9.14453 34.2799 9.14453 33.4348V24.3411C9.14453 23.5019 9.8241 22.8175 10.6706 22.8175C11.5111 22.8175 12.1966 23.496 12.1966 24.3411V33.4348C12.1907 34.2799 11.5111 34.9583 10.6706 34.9583Z" fill="url(#paint6_linear_1410_7250)" />
    <path d="M18.3008 24.3351C18.3008 23.496 18.9804 22.8116 19.8268 22.8116H28.9354C29.776 22.8116 30.4615 23.49 30.4615 24.3351C30.4615 25.1743 29.7819 25.8587 28.9354 25.8587H19.8268C18.9863 25.8527 18.3008 25.1743 18.3008 24.3351Z" fill="url(#paint7_linear_1410_7250)" />
    <path d="M24.381 16.7412C23.5405 16.7412 22.855 16.0627 22.855 15.2176V10.6589C22.855 9.81975 23.5345 9.13535 24.381 9.13535C25.2215 9.13535 25.9071 9.8138 25.9071 10.6589V15.2176C25.9071 16.0568 25.2215 16.7412 24.381 16.7412Z" fill="url(#paint8_linear_1410_7250)" />
    <path d="M25.901 19.7228C25.901 20.562 25.2215 21.2464 24.375 21.2464H19.8088C18.9682 21.2464 18.2827 20.5679 18.2827 19.7228C18.2827 18.8837 18.9623 18.1993 19.8088 18.1993H24.375C25.2215 18.2052 25.901 18.8837 25.901 19.7228Z" fill="url(#paint9_linear_1410_7250)" />
    <path d="M25.901 28.9058C25.901 29.745 25.2215 30.4294 24.375 30.4294H19.8088C18.9682 30.4294 18.2827 29.7509 18.2827 28.9058C18.2827 28.0667 18.9623 27.3823 19.8088 27.3823H24.375C25.2215 27.3882 25.901 28.0667 25.901 28.9058Z" fill="url(#paint10_linear_1410_7250)" />
    <path d="M1.52605 21.2999C0.68553 21.2999 0 20.6215 0 19.7764V15.2176C0 14.3785 0.679569 13.6941 1.52605 13.6941C2.36657 13.6941 3.0521 14.3725 3.0521 15.2176V19.7764C3.0521 20.6215 2.36657 21.2999 1.52605 21.2999Z" fill="url(#paint11_linear_1410_7250)" />
    <path d="M6.09832 21.2999C5.2578 21.2999 4.57227 20.6215 4.57227 19.7764V15.2176C4.57227 14.3785 5.25184 13.6941 6.09832 13.6941C6.93884 13.6941 7.62437 14.3725 7.62437 15.2176V19.7764C7.62437 20.6215 6.93884 21.2999 6.09832 21.2999Z" fill="url(#paint12_linear_1410_7250)" />
    <path d="M6.09832 30.4293C5.2578 30.4293 4.57227 29.7509 4.57227 28.9058V24.347C4.57227 23.5079 5.25184 22.8235 6.09832 22.8235C6.93884 22.8235 7.62437 23.5019 7.62437 24.347V28.9058C7.62437 29.7449 6.93884 30.4293 6.09832 30.4293Z" fill="url(#paint13_linear_1410_7250)" />
    <path d="M1.52605 12.1765C2.36886 12.1765 3.0521 11.4944 3.0521 10.6529C3.0521 9.81151 2.36886 9.12939 1.52605 9.12939C0.683236 9.12939 0 9.81151 0 10.6529C0 11.4944 0.683236 12.1765 1.52605 12.1765Z" fill="url(#paint14_linear_1410_7250)" />
    <path d="M6.09832 12.1765C6.94113 12.1765 7.62437 11.4944 7.62437 10.6529C7.62437 9.81151 6.94113 9.12939 6.09832 9.12939C5.2555 9.12939 4.57227 9.81151 4.57227 10.6529C4.57227 11.4944 5.2555 12.1765 6.09832 12.1765Z" fill="url(#paint15_linear_1410_7250)" />
    <path d="M10.6706 12.1765C11.5134 12.1765 12.1966 11.4944 12.1966 10.6529C12.1966 9.81151 11.5134 9.12939 10.6706 9.12939C9.82777 9.12939 9.14453 9.81151 9.14453 10.6529C9.14453 11.4944 9.82777 12.1765 10.6706 12.1765Z" fill="url(#paint16_linear_1410_7250)" />
    <path d="M15.2428 21.3059C16.0857 21.3059 16.7689 20.6238 16.7689 19.7823C16.7689 18.9409 16.0857 18.2588 15.2428 18.2588C14.4 18.2588 13.7168 18.9409 13.7168 19.7823C13.7168 20.6238 14.4 21.3059 15.2428 21.3059Z" fill="url(#paint17_linear_1410_7250)" />
    <path d="M15.2428 35C16.0857 35 16.7689 34.3179 16.7689 33.4764C16.7689 32.635 16.0857 31.9529 15.2428 31.9529C14.4 31.9529 13.7168 32.635 13.7168 33.4764C13.7168 34.3179 14.4 35 15.2428 35Z" fill="url(#paint18_linear_1410_7250)" />
    <path d="M15.2428 3.05305C16.0857 3.05305 16.7689 2.37093 16.7689 1.5295C16.7689 0.688067 16.0857 0.00595093 15.2428 0.00595093C14.4 0.00595093 13.7168 0.688067 13.7168 1.5295C13.7168 2.37093 14.4 3.05305 15.2428 3.05305Z" fill="url(#paint19_linear_1410_7250)" />
    <path d="M19.8088 16.7412C20.6516 16.7412 21.3348 16.0591 21.3348 15.2176C21.3348 14.3762 20.6516 13.6941 19.8088 13.6941C18.966 13.6941 18.2827 14.3762 18.2827 15.2176C18.2827 16.0591 18.966 16.7412 19.8088 16.7412Z" fill="url(#paint20_linear_1410_7250)" />
    <path d="M19.8088 12.1765C20.6516 12.1765 21.3348 11.4944 21.3348 10.6529C21.3348 9.81151 20.6516 9.12939 19.8088 9.12939C18.966 9.12939 18.2827 9.81151 18.2827 10.6529C18.2827 11.4944 18.966 12.1765 19.8088 12.1765Z" fill="url(#paint21_linear_1410_7250)" />
    <path d="M19.8088 7.6118C20.6516 7.6118 21.3348 6.92968 21.3348 6.08825C21.3348 5.24681 20.6516 4.5647 19.8088 4.5647C18.966 4.5647 18.2827 5.24681 18.2827 6.08825C18.2827 6.92968 18.966 7.6118 19.8088 7.6118Z" fill="url(#paint22_linear_1410_7250)" />
    <path d="M28.9533 21.2999C29.7961 21.2999 30.4793 20.6178 30.4793 19.7764C30.4793 18.935 29.7961 18.2528 28.9533 18.2528C28.1105 18.2528 27.4272 18.935 27.4272 19.7764C27.4272 20.6178 28.1105 21.2999 28.9533 21.2999Z" fill="url(#paint23_linear_1410_7250)" />
    <path d="M28.9533 16.7352C29.7961 16.7352 30.4793 16.0531 30.4793 15.2117C30.4793 14.3703 29.7961 13.6881 28.9533 13.6881C28.1105 13.6881 27.4272 14.3703 27.4272 15.2117C27.4272 16.0531 28.1105 16.7352 28.9533 16.7352Z" fill="url(#paint24_linear_1410_7250)" />
    <path d="M28.9533 12.1765C29.7961 12.1765 30.4793 11.4944 30.4793 10.6529C30.4793 9.81151 29.7961 9.12939 28.9533 9.12939C28.1105 9.12939 27.4272 9.81151 27.4272 10.6529C27.4272 11.4944 28.1105 12.1765 28.9533 12.1765Z" fill="url(#paint25_linear_1410_7250)" />
    <path d="M19.8088 3.05305C20.6516 3.05305 21.3348 2.37093 21.3348 1.5295C21.3348 0.688067 20.6516 0.00595093 19.8088 0.00595093C18.966 0.00595093 18.2827 0.688067 18.2827 1.5295C18.2827 2.37093 18.966 3.05305 19.8088 3.05305Z" fill="url(#paint26_linear_1410_7250)" />
    <path d="M24.381 7.6118C25.2238 7.6118 25.9071 6.92968 25.9071 6.08825C25.9071 5.24681 25.2238 4.5647 24.381 4.5647C23.5382 4.5647 22.855 5.24681 22.855 6.08825C22.855 6.92968 23.5382 7.6118 24.381 7.6118Z" fill="url(#paint27_linear_1410_7250)" />
    <path d="M24.381 3.05305C25.2238 3.05305 25.9071 2.37093 25.9071 1.5295C25.9071 0.688067 25.2238 0.00595093 24.381 0.00595093C23.5382 0.00595093 22.855 0.688067 22.855 1.5295C22.855 2.37093 23.5382 3.05305 24.381 3.05305Z" fill="url(#paint28_linear_1410_7250)" />
    <path d="M10.6706 16.7412C11.5134 16.7412 12.1966 16.0591 12.1966 15.2176C12.1966 14.3762 11.5134 13.6941 10.6706 13.6941C9.82777 13.6941 9.14453 14.3762 9.14453 15.2176C9.14453 16.0591 9.82777 16.7412 10.6706 16.7412Z" fill="url(#paint29_linear_1410_7250)" />
    <path d="M10.6706 21.3059C11.5134 21.3059 12.1966 20.6238 12.1966 19.7823C12.1966 18.9409 11.5134 18.2588 10.6706 18.2588C9.82777 18.2588 9.14453 18.9409 9.14453 19.7823C9.14453 20.6238 9.82777 21.3059 10.6706 21.3059Z" fill="url(#paint30_linear_1410_7250)" />
    <path d="M1.52605 25.8646C2.36886 25.8646 3.0521 25.1825 3.0521 24.3411C3.0521 23.4997 2.36886 22.8175 1.52605 22.8175C0.683236 22.8175 0 23.4997 0 24.3411C0 25.1825 0.683236 25.8646 1.52605 25.8646Z" fill="url(#paint31_linear_1410_7250)" />
    <path d="M35.0454 16.8662V16.8126C35.0454 11.7539 38.8367 7.61179 44.2315 7.61179C46.7948 7.61179 48.5653 8.3081 50.0317 9.39125C50.6993 9.88521 50.8066 10.8434 50.2582 11.4683L50.1032 11.6468C49.6204 12.2003 48.7858 12.3074 48.1957 11.867C47.0452 11.016 45.7754 10.4863 44.2017 10.4863C40.8158 10.4863 38.324 13.2834 38.324 16.765V16.8186C38.324 20.3001 40.7919 23.1211 44.2017 23.1211C45.9245 23.1211 47.1525 22.5854 48.3447 21.6689C48.9229 21.2226 49.7396 21.2583 50.2523 21.776L50.2999 21.8296C50.8961 22.4307 50.8305 23.4008 50.1807 23.9423C48.601 25.2397 46.765 26.0194 44.1004 26.0194C38.9142 26.0134 35.0454 21.9724 35.0454 16.8662Z" fill="#3F4144" />
    <path d="M62.7232 12.2896C63.7604 12.2896 64.4758 13.331 64.1002 14.3011L59.534 26.0908C58.467 28.8344 57.2688 29.852 55.1049 29.852C54.4074 29.852 53.8053 29.7628 53.245 29.5961C52.5118 29.3759 52.1422 28.5606 52.4581 27.8702L52.4701 27.8464C52.7323 27.2751 53.3642 27.0073 53.9723 27.162C54.2346 27.2275 54.5028 27.2632 54.777 27.2632C55.6414 27.2632 56.1779 26.8823 56.6846 25.7396L51.9574 14.3844C51.5461 13.3906 52.2733 12.2955 53.3523 12.2955C53.9782 12.2955 54.5445 12.6823 54.7651 13.2715L58.1868 22.2581L61.3283 13.2834C61.5369 12.6883 62.0913 12.2896 62.7232 12.2896Z" fill="#3F4144" />
    <path d="M68.887 23.621V24.1685C68.887 25.0195 68.1955 25.7039 67.349 25.7039C66.4966 25.7039 65.811 25.0136 65.811 24.1685V8.69494C65.811 7.84389 66.5025 7.15948 67.349 7.15948C68.2014 7.15948 68.887 7.84984 68.887 8.69494V14.5273C69.8825 13.1287 71.2774 12.0098 73.4711 12.0098C76.6245 12.0098 79.7064 14.4975 79.7064 18.973V19.0265C79.7064 23.4722 76.6543 25.9896 73.4711 25.9896C71.2237 25.9896 69.8288 24.8708 68.887 23.621ZM76.5709 19.0265V18.973C76.5709 16.3782 74.8123 14.6761 72.7259 14.6761C70.6395 14.6761 68.8035 16.402 68.8035 18.973V19.0265C68.8035 21.5916 70.6336 23.3234 72.7259 23.3234C74.8421 23.3175 76.5709 21.6689 76.5709 19.0265Z" fill="#3F4144" />
    <path d="M80.6006 19.0503V18.6992C80.6006 15.0986 83.456 12.0634 87.0625 12.0158C91.4081 11.9563 93.6078 15.3367 93.6078 19.2348C93.6078 19.5145 93.5839 19.7942 93.5541 20.0977H83.6765C84.0103 22.2819 85.5602 23.5019 87.5453 23.5019C88.6839 23.5019 89.5781 23.1806 90.4066 22.5676C90.8895 22.2105 91.5512 22.2224 91.9983 22.6211C92.5765 23.1329 92.5527 24.0435 91.9565 24.5256C90.806 25.454 89.3515 26.0134 87.4976 26.0134C83.605 26.0134 80.6006 23.1925 80.6006 19.0503ZM90.5497 18.0564C90.347 16.0746 89.1727 14.5213 87.14 14.5213C85.2562 14.5213 83.9329 15.9675 83.6527 18.0564H90.5497Z" fill="#3F4144" />
    <path d="M96.7255 12.2896C97.5779 12.2896 98.2634 12.9799 98.2634 13.825V15.3128C98.8119 14.0035 99.6584 12.968 100.839 12.4324C101.864 11.9682 103.02 12.7657 103.02 13.8845C103.02 14.6344 102.49 15.2593 101.763 15.4259C99.706 15.902 98.2634 17.616 98.2634 20.5977V24.1685C98.2634 25.0195 97.572 25.7039 96.7255 25.7039C95.873 25.7039 95.1875 25.0136 95.1875 24.1685V13.825C95.1875 12.9799 95.879 12.2896 96.7255 12.2896Z" fill="#3F4144" />
    <path d="M105.804 7.92126C106.668 7.92126 107.372 8.62353 107.372 9.48647V15.3188H115.872V9.48647C115.872 8.62353 116.576 7.92126 117.44 7.92126C118.305 7.92126 119.008 8.62353 119.008 9.48647V24.1507C119.008 25.0136 118.305 25.7159 117.44 25.7159C116.576 25.7159 115.872 25.0136 115.872 24.1507V18.2171H107.372V24.1507C107.372 25.0136 106.668 25.7159 105.804 25.7159C104.94 25.7159 104.236 25.0136 104.236 24.1507V9.48052C104.242 8.61757 104.94 7.92126 105.804 7.92126Z" fill="#3F4144" />
    <path d="M121.697 20.8298V13.825C121.697 12.974 122.388 12.2896 123.235 12.2896C124.087 12.2896 124.773 12.9799 124.773 13.825V19.9133C124.773 21.9962 125.816 23.1687 127.646 23.1687C129.428 23.1687 130.674 21.9486 130.674 19.8656V13.825C130.674 12.974 131.366 12.2896 132.212 12.2896H132.236C133.089 12.2896 133.774 12.9799 133.774 13.825V24.1685C133.774 25.0195 133.083 25.7039 132.236 25.7039H132.212C131.36 25.7039 130.674 25.0136 130.674 24.1685V23.621C129.81 24.8886 128.564 25.9836 126.472 25.9836C123.449 25.9896 121.697 23.9542 121.697 20.8298Z" fill="#3F4144" />
    <path d="M139.18 23.621V24.1685C139.18 25.0195 138.489 25.7039 137.642 25.7039C136.79 25.7039 136.104 25.0136 136.104 24.1685V8.69494C136.104 7.84389 136.796 7.15948 137.642 7.15948C138.495 7.15948 139.18 7.84984 139.18 8.69494V14.5273C140.176 13.1287 141.571 12.0098 143.765 12.0098C146.918 12.0098 150 14.4975 150 18.973V19.0265C150 23.4722 146.948 25.9896 143.765 25.9896C141.523 25.9896 140.122 24.8708 139.18 23.621ZM146.87 19.0265V18.973C146.87 16.3782 145.112 14.6761 143.025 14.6761C140.939 14.6761 139.103 16.402 139.103 18.973V19.0265C139.103 21.5916 140.933 23.3234 143.025 23.3234C145.136 23.3175 146.87 21.6689 146.87 19.0265Z" fill="#3F4144" />
    <defs>
      <linearGradient id="paint0_linear_1410_7250" x1="33.2662" y1="-27.385" x2="-29.3993" y2="34.4026" gradientUnits="userSpaceOnUse">
        <stop offset="0.00506604" stopColor="#8DDD88" />
        <stop offset="1" stopColor="#13A7FF" />
      </linearGradient>
      <linearGradient id="paint1_linear_1410_7250" x1="35.5156" y1="-25.1037" x2="-27.1499" y2="36.6839" gradientUnits="userSpaceOnUse">
        <stop offset="0.00506604" stopColor="#8DDD88" />
        <stop offset="1" stopColor="#13A7FF" />
      </linearGradient>
      <linearGradient id="paint2_linear_1410_7250" x1="37.7651" y1="-22.8224" x2="-24.9004" y2="38.9652" gradientUnits="userSpaceOnUse">
        <stop offset="0.00506604" stopColor="#8DDD88" />
        <stop offset="1" stopColor="#13A7FF" />
      </linearGradient>
      <linearGradient id="paint3_linear_1410_7250" x1="46.7914" y1="-13.6677" x2="-15.8741" y2="48.12" gradientUnits="userSpaceOnUse">
        <stop offset="0.00506604" stopColor="#8DDD88" />
        <stop offset="1" stopColor="#13A7FF" />
      </linearGradient>
      <linearGradient id="paint4_linear_1410_7250" x1="51.4121" y1="-8.98158" x2="-11.2534" y2="52.806" gradientUnits="userSpaceOnUse">
        <stop offset="0.00506604" stopColor="#8DDD88" />
        <stop offset="1" stopColor="#13A7FF" />
      </linearGradient>
      <linearGradient id="paint5_linear_1410_7250" x1="43.436" y1="-17.071" x2="-19.2296" y2="44.7166" gradientUnits="userSpaceOnUse">
        <stop offset="0.00506604" stopColor="#8DDD88" />
        <stop offset="1" stopColor="#13A7FF" />
      </linearGradient>
      <linearGradient id="paint6_linear_1410_7250" x1="50.3268" y1="-10.0822" x2="-12.3387" y2="51.7054" gradientUnits="userSpaceOnUse">
        <stop offset="0.00506604" stopColor="#8DDD88" />
        <stop offset="1" stopColor="#13A7FF" />
      </linearGradient>
      <linearGradient id="paint7_linear_1410_7250" x1="54.7928" y1="-5.55267" x2="-7.8727" y2="56.2349" gradientUnits="userSpaceOnUse">
        <stop offset="0.00506604" stopColor="#8DDD88" />
        <stop offset="1" stopColor="#13A7FF" />
      </linearGradient>
      <linearGradient id="paint8_linear_1410_7250" x1="49.0865" y1="-11.34" x2="-13.5791" y2="50.4476" gradientUnits="userSpaceOnUse">
        <stop offset="0.00506604" stopColor="#8DDD88" />
        <stop offset="1" stopColor="#13A7FF" />
      </linearGradient>
      <linearGradient id="paint9_linear_1410_7250" x1="51.3592" y1="-9.03487" x2="-11.3064" y2="52.7527" gradientUnits="userSpaceOnUse">
        <stop offset="0.00506604" stopColor="#8DDD88" />
        <stop offset="1" stopColor="#13A7FF" />
      </linearGradient>
      <linearGradient id="paint10_linear_1410_7250" x1="55.9577" y1="-4.371" x2="-6.70785" y2="57.4166" gradientUnits="userSpaceOnUse">
        <stop offset="0.00506604" stopColor="#8DDD88" />
        <stop offset="1" stopColor="#13A7FF" />
      </linearGradient>
      <linearGradient id="paint11_linear_1410_7250" x1="40.1247" y1="-20.429" x2="-22.5408" y2="41.3585" gradientUnits="userSpaceOnUse">
        <stop offset="0.00506604" stopColor="#8DDD88" />
        <stop offset="1" stopColor="#13A7FF" />
      </linearGradient>
      <linearGradient id="paint12_linear_1410_7250" x1="42.3742" y1="-18.1477" x2="-20.2913" y2="43.6399" gradientUnits="userSpaceOnUse">
        <stop offset="0.00506604" stopColor="#8DDD88" />
        <stop offset="1" stopColor="#13A7FF" />
      </linearGradient>
      <linearGradient id="paint13_linear_1410_7250" x1="46.9443" y1="-13.5127" x2="-15.7212" y2="48.2749" gradientUnits="userSpaceOnUse">
        <stop offset="0.00506604" stopColor="#8DDD88" />
        <stop offset="1" stopColor="#13A7FF" />
      </linearGradient>
      <linearGradient id="paint14_linear_1410_7250" x1="36.6955" y1="-23.907" x2="-25.9701" y2="37.8806" gradientUnits="userSpaceOnUse">
        <stop offset="0.00506604" stopColor="#8DDD88" />
        <stop offset="1" stopColor="#13A7FF" />
      </linearGradient>
      <linearGradient id="paint15_linear_1410_7250" x1="38.9449" y1="-21.6257" x2="-23.7206" y2="40.1619" gradientUnits="userSpaceOnUse">
        <stop offset="0.00506604" stopColor="#8DDD88" />
        <stop offset="1" stopColor="#13A7FF" />
      </linearGradient>
      <linearGradient id="paint16_linear_1410_7250" x1="41.1943" y1="-19.3444" x2="-21.4712" y2="42.4432" gradientUnits="userSpaceOnUse">
        <stop offset="0.00506604" stopColor="#8DDD88" />
        <stop offset="1" stopColor="#13A7FF" />
      </linearGradient>
      <linearGradient id="paint17_linear_1410_7250" x1="48.0155" y1="-12.4263" x2="-14.65" y2="49.3613" gradientUnits="userSpaceOnUse">
        <stop offset="0.00506604" stopColor="#8DDD88" />
        <stop offset="1" stopColor="#13A7FF" />
      </linearGradient>
      <linearGradient id="paint18_linear_1410_7250" x1="54.8707" y1="-5.47369" x2="-7.79473" y2="56.3139" gradientUnits="userSpaceOnUse">
        <stop offset="0.00506604" stopColor="#8DDD88" />
        <stop offset="1" stopColor="#13A7FF" />
      </linearGradient>
      <linearGradient id="paint19_linear_1410_7250" x1="38.8737" y1="-21.6981" x2="-23.7918" y2="40.0895" gradientUnits="userSpaceOnUse">
        <stop offset="0.00506604" stopColor="#8DDD88" />
        <stop offset="1" stopColor="#13A7FF" />
      </linearGradient>
      <linearGradient id="paint20_linear_1410_7250" x1="47.9778" y1="-12.4643" x2="-14.6877" y2="49.3233" gradientUnits="userSpaceOnUse">
        <stop offset="0.00506604" stopColor="#8DDD88" />
        <stop offset="1" stopColor="#13A7FF" />
      </linearGradient>
      <linearGradient id="paint21_linear_1410_7250" x1="45.6928" y1="-14.7818" x2="-16.9728" y2="47.0058" gradientUnits="userSpaceOnUse">
        <stop offset="0.00506604" stopColor="#8DDD88" />
        <stop offset="1" stopColor="#13A7FF" />
      </linearGradient>
      <linearGradient id="paint22_linear_1410_7250" x1="43.4077" y1="-17.0993" x2="-19.2578" y2="44.6882" gradientUnits="userSpaceOnUse">
        <stop offset="0.00506604" stopColor="#8DDD88" />
        <stop offset="1" stopColor="#13A7FF" />
      </linearGradient>
      <linearGradient id="paint23_linear_1410_7250" x1="54.7601" y1="-5.58579" x2="-7.90549" y2="56.2018" gradientUnits="userSpaceOnUse">
        <stop offset="0.00506604" stopColor="#8DDD88" />
        <stop offset="1" stopColor="#13A7FF" />
      </linearGradient>
      <linearGradient id="paint24_linear_1410_7250" x1="52.475" y1="-7.9033" x2="-10.1905" y2="53.8843" gradientUnits="userSpaceOnUse">
        <stop offset="0.00506604" stopColor="#8DDD88" />
        <stop offset="1" stopColor="#13A7FF" />
      </linearGradient>
      <linearGradient id="paint25_linear_1410_7250" x1="50.19" y1="-10.2208" x2="-12.4756" y2="51.5668" gradientUnits="userSpaceOnUse">
        <stop offset="0.00506604" stopColor="#8DDD88" />
        <stop offset="1" stopColor="#13A7FF" />
      </linearGradient>
      <linearGradient id="paint26_linear_1410_7250" x1="41.1227" y1="-19.4168" x2="-21.5429" y2="42.3707" gradientUnits="userSpaceOnUse">
        <stop offset="0.00506604" stopColor="#8DDD88" />
        <stop offset="1" stopColor="#13A7FF" />
      </linearGradient>
      <linearGradient id="paint27_linear_1410_7250" x1="45.6572" y1="-14.818" x2="-17.0084" y2="46.9696" gradientUnits="userSpaceOnUse">
        <stop offset="0.00506604" stopColor="#8DDD88" />
        <stop offset="1" stopColor="#13A7FF" />
      </linearGradient>
      <linearGradient id="paint28_linear_1410_7250" x1="43.3721" y1="-17.1355" x2="-19.2934" y2="44.6521" gradientUnits="userSpaceOnUse">
        <stop offset="0.00506604" stopColor="#8DDD88" />
        <stop offset="1" stopColor="#13A7FF" />
      </linearGradient>
      <linearGradient id="paint29_linear_1410_7250" x1="43.4794" y1="-17.0269" x2="-19.1861" y2="44.7607" gradientUnits="userSpaceOnUse">
        <stop offset="0.00506604" stopColor="#8DDD88" />
        <stop offset="1" stopColor="#13A7FF" />
      </linearGradient>
      <linearGradient id="paint30_linear_1410_7250" x1="45.7644" y1="-14.7094" x2="-16.9011" y2="47.0782" gradientUnits="userSpaceOnUse">
        <stop offset="0.00506604" stopColor="#8DDD88" />
        <stop offset="1" stopColor="#13A7FF" />
      </linearGradient>
      <linearGradient id="paint31_linear_1410_7250" x1="43.5507" y1="-16.9544" x2="-19.1149" y2="44.8331" gradientUnits="userSpaceOnUse">
        <stop offset="0.00506604" stopColor="#8DDD88" />
        <stop offset="1" stopColor="#13A7FF" />
      </linearGradient>
    </defs>
  </svg>

);

export default CyberHubEnglishLogo;
