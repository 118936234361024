import { useTheme } from '@mui/styles';

const CommentIcon = (props) => {
  const { palette } = useTheme();

  const {
    fill = 'none',
    circleFill = palette.common.turquoiseSeaweedGreen,
    innerFill = palette.common.silverGrey,
    rectFill = palette.common.white,
    ...rest
  } = props;

  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill={fill} xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g clipPath="url(#clip0_3030_111363)">
        <path d="M20 3H4C2.9 3 2 3.9 2 5V23L6.00001 19H20C21.1 19 22 18.1 22 17V5C22 3.9 21.1 3 20 3ZM20 17H6.00001L4 19V5H20V17Z" fill={innerFill} />
      </g>
      <circle cx="20" cy="4.00001" r="4.00001" fill={circleFill} />
      <defs>
        <clipPath id="clip0_3030_111363">
          <rect width="24.0001" height="24.0001" fill={rectFill} transform="translate(0 1)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CommentIcon;
