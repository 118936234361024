import { createTheme } from '@mui/material/styles';
import typography from './typography';
import components from './components';
import overrides from './overrides';
import palette from './palette';

const baseTheme = {
  spacing: 4,
  shape: {
    borderRadius: 8,
  },
  overrides,
  palette,
  typography,
};

baseTheme.components = components(baseTheme);

const theme = createTheme({
  ...baseTheme,
  direction: 'ltr',
});

const rtlTheme = createTheme({
  ...baseTheme,
  direction: 'rtl',
  typography: {
    ...baseTheme.typography,
    fontFamily: 'DINNextLTW23Light, sans-serif',
  },
});

export {
  theme,
  rtlTheme,
};
