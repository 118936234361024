import React from 'react';
import { Link } from 'react-router-dom';
import { useInfiniteClubMembers } from 'reactQuery/queries';
import { capitalizeFirstLetter } from 'util/helpers';

import { CustomInfiniteScroll } from 'components/molecules';
import { Stack, AvatarGroup, Avatar } from '@mui/material';
import LeaderboardListItem from '../LeaderboardListItem/LeaderboardListItem';

const renderMembersListItem = (list) => list?.map((item) => (
  <LeaderboardListItem
    key={item.username}
    rank={item.clubRank}
    name={item.fullName}
    avatar={item.avatar}
    points={item.totalPoints}
    username={item.username}
    sx={{
      boxShadow: ({ palette }) => (`0px 0px 35px ${palette.common.boxShadow4}`),
    }}
  />
));

const ClubMembersList = (props) => {
  const {
    members = [],
    membersCount = 0,
    isFullView = false,
  } = props;

  const topThree = members?.slice(0, 3);
  const otherClubMembers = members?.slice(3, 6);

  const {
    data: { pages = [] } = {},
    isFetching = false,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteClubMembers();

  const fullMembersList = React.useMemo(() => pages?.map((page) => {
    const key = `club-members-page-${page.meta?.currentPage}`;
    return (
      <Stack
        spacing={5}
        pt={5}
        key={key}
      >
        {renderMembersListItem(page.items)}
      </Stack>
    );
  }), [pages]);

  const isRemainingAboveHundred = (membersCount - 6) > 99; // remaining members
  const remainingMembersCount = isRemainingAboveHundred ? (99 + 3) : membersCount - 3;

  const renderAbstractMemberList = () => (
    <>
      <Stack
        spacing={5}
        py={5}
      >
        {renderMembersListItem(topThree)}
      </Stack>
      <AvatarGroup
        max={4}
        total={remainingMembersCount}
        component={Link}
        to="members"
        sx={(theme) => ({
          textDecoration: 'none',
          justifyContent: 'flex-end',
          '.MuiAvatarGroup-avatar': {
            height: 36,
            width: 36,
            ...theme.typography.bodySmallMedium,
          },
        })}
      >
        {otherClubMembers?.map(((item) => (
          <Avatar
            key={item.username || ''}
            src={item.avatar || ''}
            alt={capitalizeFirstLetter(item.username)}
          />
        )))}
      </AvatarGroup>
    </>
  );

  return (
    isFullView
      ? (
        <CustomInfiniteScroll
          loadMore={fetchNextPage}
          hasMore={hasNextPage}
          isFetching={isFetching}
        >
          { fullMembersList }
        </CustomInfiniteScroll>
      )
      : renderAbstractMemberList()
  );
};
export default ClubMembersList;
