import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';

import {
  Box,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';

import {
  FormAvatar,
  FormTextField,
} from 'components/form';

import {
  useAuth,
  useLocale,
  useFastForm,
  useSnackbar,
} from 'util/hooks';

import { getDirtyObject, REGEX } from 'util/helpers';
import { InfoWithCircleIcon } from 'assets/icons';
import { updateOwnProfile } from 'services';

const ProfileSettingsForm = () => {
  const { t } = useLocale();
  const snack = useSnackbar();
  const navigate = useNavigate();
  const { currentUser, refreshUser } = useAuth();

  const {
    bio = '',
    mobile = '',
    github = '',
    avatar = '',
    linkedin = '',
    username = '',
    lastNameEn = '',
    firstNameEn = '',
    nafathVerified = '',
  } = currentUser || {};

  const nafathVerifiedInfo = (
    <Tooltip
      title={t('profileSettings.nameVerifiedWithNafath')}
      placement="bottom"
    >
      <Box sx={{ display: 'flex', width: 14, height: 14 }}>
        <InfoWithCircleIcon width="14" height="14" />
      </Box>
    </Tooltip>
  );

  const usernameValidationRules = [
    { rule: t('profileSettings.usernameValidation.rule1') },
    { rule: t('profileSettings.usernameValidation.rule2') },
    { rule: t('profileSettings.usernameValidation.rule3') },
  ];

  const showTooltip = nafathVerified && nafathVerifiedInfo;

  const onSubmit = async (values) => {
    const payload = {
      ...values,
      github: values.github || null,
      linkedin: values.linkedin || null,
    };

    try {
      const dirtyPayload = getDirtyObject(payload, defaultValues);

      const { username: updatedUsername } = await updateOwnProfile(dirtyPayload);

      refreshUser();

      snack({
        severity: 'success',
        message: t('editProfile.profileUpdated'),
      });

      navigate(`/profile/${updatedUsername}`);
    } catch (error) {
      error.errors?.forEach((err) => {
        setError(err.property, {
          type: 'api',
          message: err.message,
        });

        if (!err.property) {
          snack({
            severity: 'error',
            message: err.message || t('common.somethingWrong'),
          });
        }
      });

      if (!errors) { // Non-form errors
        snack({
          severity: 'error',
          message: error.message || t('common.somethingWrong'),
        });
      }
    }
  };

  const defaultValues = {
    avatar: avatar || '',
    firstNameEn: firstNameEn || '',
    lastNameEn: lastNameEn || '',
    username: username || '',
    bio: bio || '',
    mobile: mobile || '',
    github: github || '',
    linkedin: linkedin || '',
  };

  const validationSchema = Yup.object().shape(
    {
      avatar: Yup
        .string()
        .nullable(),
      firstNameEn: Yup
        .string()
        .trim()
        .matches(REGEX.alphabetEnWithSpace, t('registration.validation.enterEnglishLetters'))
        .required(t('registration.validation.enterFirstName')),
      lastNameEn: Yup
        .string()
        .trim()
        .matches(REGEX.alphabetEnWithSpace, t('registration.validation.enterEnglishLetters'))
        .required(t('registration.validation.enterLastName')),
      username: Yup
        .string()
        .trim()
        .min(6, t('username.minLength'))
        .max(35, t('username.maxLength'))
        .matches(REGEX.alphaNumericUnderscore, t('username.validation'))
        .required(t('registration.validation.enterUsername')),
      mobile: Yup
        .string()
        .trim()
        .matches(REGEX.saudiMobileNumberWZero, t('registration.validation.mobileValidation'))
        .min(10, t('registration.validation.mobileLengthValidation'))
        .max(10, t('registration.validation.mobileLengthValidation'))
        .required(t('registration.validation.enterMobile')),
      bio: Yup
        .string()
        .trim(),
      linkedin: Yup
        .string()
        .trim()
        .when('linkedin', {
          is: (exists) => !!exists,
          then: Yup.string().matches(REGEX.url, t('profileSettings.enterLink')),
          otherwise: Yup.string().notRequired(),
        }),
      github: Yup
        .string()
        .trim()
        .when('github', {
          is: (exists) => !!exists,
          then: Yup.string().matches(REGEX.url, t('profileSettings.enterLink')),
          otherwise: Yup.string().notRequired(),
        }),
    },
    [
      ['linkedin', 'linkedin'],
      ['github', 'github'],
    ],
  );

  const {
    control,
    setError,
    handleSubmit,
    formState: {
      errors,
      isDirty,
      isSubmitting,
    },
  } = useFastForm({
    defaultValues,
    validationSchema,
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={8}>
        <Box
          display="flex"
          justifyContent=" start"
        >
          <FormAvatar
            name="avatar"
            avatar={defaultValues.avatar}
            control={control}
            username={username}
            disabled={isSubmitting}
            uploadButtonLabel={t('common.uploadImage')}
            loadingButtonProps={{ variant: 'outlined' }}
            avatarProps={{ sx: { width: 120, height: 120 } }}
            deleteButtonProps={{
              color: 'secondary',
              sx: {
                ml: { xs: 0, sm: 2 },
                '&:disabled': {
                  borderColor: 'common.lightGrey',
                  color: 'common.lightGrey',
                },
              },
            }}
          />
        </Box>
        <Box>
          <Typography variant="h6">
            {t('platformSettings.generalSettings')}
          </Typography>
        </Box>
        <Box>
          <FormTextField
            name="firstNameEn"
            control={control}
            label={t('registration.firstName')}
            disabled={isSubmitting || nafathVerified}
            fullWidth
            InputProps={{
              endAdornment: showTooltip,
            }}
          />
        </Box>
        <Box>
          <FormTextField
            name="lastNameEn"
            control={control}
            label={t('registration.lastName')}
            disabled={isSubmitting || nafathVerified}
            fullWidth
            InputProps={{
              endAdornment: showTooltip,
            }}
          />
        </Box>
        <Box>
          <FormTextField
            name="username"
            control={control}
            label={t('common.username')}
            disabled={isSubmitting}
            fullWidth
            FormHelperTextProps={{ component: 'div' }}
            helperText={(
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
              >
                {usernameValidationRules.map((i) => (
                  <Box key={i.rule}>
                    <Typography
                      variant="bodySmallRegular"
                      color="common.gadgetGray"
                    >
                      <li>
                        {t(i.rule)}
                      </li>
                    </Typography>
                  </Box>
                ))}
              </Box>
              )}
          />
        </Box>
        <Box>
          <FormTextField
            name="mobile"
            control={control}
            label={t('common.mobile')}
            disabled={isSubmitting}
            fullWidth
          />
        </Box>
        <Box>
          <Typography variant="h6">
            {t('profileSettings.about')}
          </Typography>
        </Box>
        <Box>
          <FormTextField
            name="bio"
            control={control}
            label={t('profileSettings.bio')}
            disabled={isSubmitting}
            fullWidth
            multiline
            minRows={2}
            showCharCount
            inputProps={{ maxLength: 200 }}
          />
        </Box>
        <Box>
          <FormTextField
            name="linkedin"
            control={control}
            label={t('profileSettings.linkedInLink')}
            disabled={isSubmitting}
            fullWidth
          />
        </Box>
        <Box>
          <FormTextField
            name="github"
            control={control}
            label={t('profileSettings.githubLink')}
            disabled={isSubmitting}
            fullWidth
          />
        </Box>
      </Stack>
      <Box sx={{
        mt: 10,
        display: 'flex',
        justifyContent: 'center',
      }}
      >
        <LoadingButton
          type="submit"
          size="regular"
          variant="contained"
          loading={isSubmitting}
          disabled={isSubmitting || !isDirty}
        >
          {t('common.updateInformation')}
        </LoadingButton>
      </Box>
    </form>
  );
};

export default ProfileSettingsForm;
