import React from 'react';
import _ from 'lodash';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

import { POST_TYPES, POST_TYPES_DETAILS } from 'assets/constants/posts';
import { useLocale } from 'util/hooks';
import { Link } from '@mui/material';
import { PATHS } from 'routes';
import { UserAvatarGroup } from 'components/organisms';

const ProfilePostCard = (props) => {
  const { post = {} } = props;

  const { t, isAr } = useLocale();

  const {
    id,
    type,
    video,
    title,
    content,
    author = {},
    trimmedContent,
    topic: {
      nameEn: topicNameEn = '',
      nameAr: topicNameAr = '',
    } = {},
  } = post || {};

  const {
    avatar = '',
    fullName = '',
    type: role = '',
  } = author || {};

  const postType = React.useMemo(
    () => POST_TYPES_DETAILS
      .find((availableType) => availableType.key === type) || {},
    [type],
  );

  const renderText = (text) => (
    <Typography
      variant="bodySmallRegular"
      sx={{
        display: '-webkit-box',
        overflow: 'hidden',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 7,
      }}
    >
      {text}
    </Typography>
  );

  const renderCardContent = () => {
    switch (type) {
      case POST_TYPES.article:
        return (
          <Stack spacing={2}>
            <Typography variant="bodyStandardMedium">
              {title}
            </Typography>
            {renderText(trimmedContent)}
          </Stack>
        );

      case POST_TYPES.knowledgeShare:
        return renderText(content);

      case POST_TYPES.video:
        return (
          <>
            <CardMedia
              sx={{
                borderRadius: 1.5,
                backgroundColor: 'black',
              }}
              src={video}
              component="video"
              width="233"
              height="140"
              alt="No video found"
              controls
              autoPlay
              muted
            />
            <Box sx={{ mt: 4 }}>
              <Typography variant="bodyStandardMedium">
                {title}
              </Typography>
            </Box>
          </>
        );

      default:
        return '';
    }
  };

  const header = (
    <Stack
      spacing={3}
    >
      {!_.isEmpty(author)
        && (
        <UserAvatarGroup
          avatar={avatar}
          role={role}
          name={fullName}
          withProfileLink={false}
          avatarProps={{
            sx: {
              width: 32,
              height: 32,
            },
          }}
        />
        )}
      <Stack spacing={2} direction="row">
        {postType?.smallIcon}
        <Typography variant="bodySmallMedium">
          {`${t(`postsTypesList.${postType?.shortKey}`)}: ${isAr ? topicNameAr : topicNameEn}`}
        </Typography>
      </Stack>
    </Stack>
  );

  return (
    <Link to={`/${PATHS.posts}/${id}`}>
      <Card
        sx={{
          p: 5,
          minWidth: 270,
          height: 240,
          maxHeight: 240,
          boxShadow: ({ palette }) => (`0px 0px 35px ${palette.common.boxShadow4}`),
        }}
      >
        <Stack spacing={2}>
          {header}
          {renderCardContent()}
        </Stack>
      </Card>
    </Link>
  );
};

export default ProfilePostCard;
