import { useParams } from 'react-router-dom';

import { getClubMembers } from 'services';
import { useCustomInfiniteQuery } from 'reactQuery';

const useInfiniteClubMembers = (queryProps = {}) => {
  const { clubId } = useParams();

  const query = useCustomInfiniteQuery({
    queryKey: [`club-members-${clubId}`],
    queryFn: (options) => getClubMembers(clubId, options),
    ...queryProps,
  });

  return { ...query };
};

export default useInfiniteClubMembers;
