import { RecoilRoot } from 'recoil';
import RecoilNexus from 'recoil-nexus';

const RecoilProvider = (props) => {
  const { children } = props;

  return (
    <RecoilRoot>
      <RecoilNexus />
      {children}
    </RecoilRoot>
  );
};

export default RecoilProvider;
