import {
  Box, Button, Typography, useMediaQuery,
} from '@mui/material';
import { useLocale } from 'util/hooks';

const SponsorJoin = () => {
  const { t } = useLocale();
  const showOnMobile = useMediaQuery(({ breakpoints }) => breakpoints.down('sm'));
  const { REACT_APP_SUPPORT_EMAIL } = process.env;

  return (
    <Box sx={{
      boxShadow: ({ palette }) => (`0px 0px 35px ${palette.common.boxShadow4}`),
      backgroundColor: 'common.white',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 1,
      p: {
        xs: 5,
        md: 10,
      },
    }}
    >
      <Typography sx={{ alignSelf: 'start' }} variant={showOnMobile ? 'bodyStandardMedium' : 'h5'}>
        { t('landing.sponsorJoin.header') }
      </Typography>

      <Typography sx={{ alignSelf: 'start', mt: 2 }} variant={showOnMobile ? 'bodySmallRegular' : 'bodyStandardRegular'}>
        { t('landing.sponsorJoin.subHeader') }
      </Typography>

      <Button
        variant="contained"
        sx={{ mt: 8 }}
        size="fixed"
        onClick={(e) => {
          e.preventDefault();
          window.location = `mailto:${REACT_APP_SUPPORT_EMAIL}`;
        }}
      >
        { t('landing.sponsorJoin.buttonLabel') }
      </Button>

    </Box>
  );
};
export default SponsorJoin;
