import {
  Box,
  Card,
  Chip,
  Avatar,
  Button,
  Typography,
} from '@mui/material';

import {
  DateGreenIcon,
  LevelGreenIcon,
  OnlineGreenIcon,
  TopicRegularIcon,
  SessionGreenIcon,
  LanguageGreenIcon,
  CertificateGreenIcon,
  PointsWithShadowIcon,

} from 'assets/icons';
import { capitalizeFirstLetter, localizedDate } from 'util/helpers';

import { useAuth, useLocale, useModal } from 'util/hooks';
import { AttachmentFile, SessionCard } from 'components/organisms';
import { CardMediaWithFallback } from 'components/molecules';
import MODAL_KEYS from 'assets/constants/modalKeys';
import { STATUS_NAMES } from 'assets/constants/statuses';

const CourseDetailsCard = (props) => {
  const {
    courseDetails = {},
    refetchCourse = () => { },
    CardStyle = {},
  } = props;

  const {
    tags = [],
    cover = '',
    title = '',
    topic = {},
    level = {},
    points = 0,
    endAt = '',
    startAt = '',
    provider = {},
    sessions = [],
    description = '',
    managedById = '',
    attachments = [],
    sessionsCount = '',
    canRegister = false,
    hasCertificate = false,
    isStudentRegistered = false,
    isCourseCompleted = false,
    language: courseLanguage = '',
    registrant = {},
  } = courseDetails;

  const isStudentRejected = registrant?.status === STATUS_NAMES.rejected;
  const isStudentPending = registrant?.status === STATUS_NAMES.pending;

  const { addModal } = useModal();
  const { t, isAr, language } = useLocale();
  const {
    isAdmin,
    isStudent,
    isLoggedIn,
    isContentCreator,
  } = useAuth();

  const startDate = localizedDate(language, startAt);
  const endDate = localizedDate(language, endAt);
  const iconSize = { width: 33, height: 33 };

  const isManager = isAdmin || isContentCreator;

  const canRegisterToCourse = (isLoggedIn && isStudent
    && canRegister
    && !isStudentRegistered
    && !isCourseCompleted);

  const showWithdrawButton = (isLoggedIn && isStudent
    && (isStudentRegistered || isStudentPending)
    && !isCourseCompleted);

  const showRegistrationButton = !isCourseCompleted
  && canRegister
  && !isStudentRegistered
  && !isStudentPending
  && !isStudentRejected
  && !isManager;

  const courseInfo = [
    {
      id: 'date',
      icon: <DateGreenIcon {...iconSize} />,
      text: `${startDate} - ${endDate}`,
    },
    {
      id: 'sessions',
      icon: <SessionGreenIcon {...iconSize} />,
      text: `${sessionsCount} ${t('common.sessions')}`,
    },
    {
      id: 'online',
      icon: <OnlineGreenIcon {...iconSize} />,
      text: t('common.online'),
    },
    {
      id: 'level',
      icon: <LevelGreenIcon {...iconSize} />,
      text: isAr ? level?.nameAr : level?.nameEn,
    },
    {
      id: 'points',
      icon: <PointsWithShadowIcon {...iconSize} />,
      text: `${points} ${t('common.points')}`,
    },
    {
      id: 'language',
      icon: <LanguageGreenIcon {...iconSize} />,
      text: t(`common.${courseLanguage}`),
    },
  ];

  if (hasCertificate) {
    courseInfo.push({
      id: 'certificate',
      icon: <CertificateGreenIcon {...iconSize} />,
      text: t('common.professionalCertificate'),
    });
  }

  const handleRegisterToCourse = () => {
    if (canRegisterToCourse) {
      addModal({
        key: MODAL_KEYS.studentCourseActions,
        props: {
          type: 'register',
          courseDetails,
          refetchCourse,
        },
      });
    } else {
      addModal({
        key: MODAL_KEYS.joinUs,
      });
    }
  };

  const handleWithdrawFromCourse = () => {
    addModal({
      key: MODAL_KEYS.studentCourseActions,
      props: {
        type: 'withdraw',
        courseDetails,
        refetchCourse,
      },
    });
  };

  const registrationButton = (
    <Box sx={{ mt: 10 }}>
      <Button
        size="large"
        sx={{
          width: { xs: 1, md: 220 },
          display: 'flex',
          flexFlow: 'column wrap',
        }}
        variant="contained"
        onClick={handleRegisterToCourse}
      >
        <Typography variant="bodyStandardMedium">
          {t('common.registerNow')}
        </Typography>
        <Typography variant="bodySmallRegular">
          {`${t('common.starts')} ${localizedDate(language, startAt)}`}
        </Typography>
      </Button>
    </Box>
  );

  const withdrawalButton = (
    <Box sx={{ mt: 10 }}>
      <Button
        size="large"
        sx={{
          width: { xs: 1, md: 220 },
          color: 'common.pinkishRed',
          borderColor: 'common.pinkishRed',
          '&:hover': {
            borderColor: 'common.pinkishRed',
          },
        }}
        variant="outlined"
        onClick={handleWithdrawFromCourse}
      >
        {t('common.withdraw')}
      </Button>
    </Box>
  );

  return (
    <Box>
      <Card sx={{
        p: {
          xs: 4,
          sm: 10,
        },
        pb: 25,
        mt: {
          xs: 5,
          sm: 0,
        },
        display: 'flex',
        flexDirection: 'column',
        boxShadow: (theme) => `0px 4px 4px ${theme.palette.common.boxShadow}`,
        ...CardStyle,
      }}
      >
        <CardMediaWithFallback
          sx={{ borderRadius: 1 }}
          component="img"
          src={cover}
          height="400"
          alt={t('common.courseImage')}
        />
        <Avatar
          src={provider?.logo}
          alt={capitalizeFirstLetter(provider?.name)}
          sx={{
            mt: -15,
            ml: 6,
            width: 100,
            height: 100,
            border: '2px white solid',
            backgroundColor: provider?.logo ? 'common.white' : '',
            boxShadow: (theme) => `0 4px 4px 0px ${theme.palette.common.dropShadow}`,
          }}
        />
        <Box sx={{ pl: 5 }}>
          <Box sx={{
            mx: 2,
            display: 'flex',
            flexDirection: 'column',
          }}
          >
            <Typography
              variant="bodyLargeMedium"
              color="common.darkGray"
              sx={{ my: 4 }}
            >
              {title}
            </Typography>
            <Typography
              variant="bodyStandardRegular"
              color="common.black"
            >
              {provider?.name}
            </Typography>
            <Typography
              variant="bodyStandardRegular"
              color="common.black"
              sx={{ mt: 8, whiteSpace: 'pre-wrap', overflowWrap: 'anywhere' }}
            >
              {description}
            </Typography>
            {showRegistrationButton && registrationButton}
            {showWithdrawButton && withdrawalButton}
            <Box sx={{ my: 10, display: 'flex', alignItems: 'center' }}>
              <TopicRegularIcon />
              <Typography
                variant="bodySmallRegular"
                color="text.secondary"
                sx={{ ml: 2 }}
              >
                {isAr ? topic?.nameAr : topic?.nameEn}
              </Typography>
            </Box>
            <Box sx={{ mb: 6 }}>
              {tags?.map((tag) => (
                <Chip
                  key={tag.id}
                  label={isAr ? tag.nameAr : tag.nameEn}
                  color="tags"
                  sx={{ mr: 2, mb: 3 }}
                />
              ))}
            </Box>
            <Box sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              flexWrap: 'wrap',
            }}
            >
              {courseInfo?.map((info) => (
                <Box
                  key={info.id}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mr: 7,
                    mb: 3,
                  }}
                >
                  <Box sx={{ ...iconSize }}>
                    {info.icon}
                  </Box>
                  <Typography
                    variant="bodySmallRegular"
                    color="common.black"
                    sx={{ ml: 2 }}
                  >
                    {info.text}
                  </Typography>
                </Box>
              ))}
            </Box>
            {attachments.length > 0
              && (
                <>
                  <Typography
                    variant="bodyStandardMedium"
                    color="common.black"
                    sx={{ my: 8 }}
                  >
                    {t('common.attachments')}
                  </Typography>
                  <Box>
                    {attachments?.map((attachment) => (
                      <Box
                        key={attachment.id}
                      >
                        <AttachmentFile
                          fileName={attachment.fileOriginalName}
                          fileUrl={attachment.fileName}
                        />
                      </Box>
                    ))}
                  </Box>
                </>
              )}
            {sessions?.map((session, index) => (
              <Box
                key={session.id}
                sx={{ mt: 5 }}
              >
                <SessionCard
                  sessionDetails={{
                    ...session,
                    sessionNumber: index + 1,
                  }}
                  managedById={managedById}
                />
              </Box>
            ))}
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export default CourseDetailsCard;
