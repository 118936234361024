import { useParams } from 'react-router-dom';
import { useCustomQuery } from 'reactQuery';
import { getTopicDetails } from 'services';

const useTopicDetails = () => {
  const { topicId } = useParams();

  const query = useCustomQuery({
    queryKey: [`topic-details-${topicId}`],
    queryFn: () => getTopicDetails(topicId),
  });

  return { ...query };
};

export default useTopicDetails;
