import { LocalizationProvider, DesktopTimePicker as MUITimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TextField } from '@mui/material';
import { useState } from 'react';

const TimePicker = (props, ref) => {
  const {
    pickerProps = {
      onChange: () => { },
      InputAdornmentProps: {},
    },
    inputProps = {},
  } = props;

  const [open, setOpen] = useState(false);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MUITimePicker
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        renderInput={(params) => (
          <TextField
            autoComplete="off"
            onKeyDown={(e) => e.preventDefault()}
            onClick={(e) => setOpen(!open)}
            {...params}
            {...inputProps}
          />
        )}
        {...pickerProps}
      />
    </LocalizationProvider>
  );
};

export default TimePicker;
