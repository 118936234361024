import { getAllCertificates } from 'services';
import { useCustomQuery } from 'reactQuery';

const useCertificates = () => {
  const query = useCustomQuery({
    queryKey: ['certificates'],
    queryFn: getAllCertificates,
  });

  return { ...query };
};

export default useCertificates;
