import { useEffect, useState } from 'react';
import { ExpandMore } from '@mui/icons-material';
import {
  Box,
  Card,
  Button,
  Divider,
  Collapse,
  Checkbox,
  CardActions,
  CardContent,
  Typography,
} from '@mui/material';

import {
  CommentIcon,
  FilledCheckIcon,
  OutlinedCheckIcon,
} from 'assets/icons';
import { revisePost, toggleCommentStatus } from 'services';
import { useLocale, useSnackbar } from 'util/hooks';
import { usePostDetails } from 'reactQuery/queries';

const CommentCard = (props) => {
  const {
    commentStatus = false,
    withSubmission = false,
    isNotAdminOrLeader = false,
  } = props;

  const snack = useSnackbar();
  const { t, dir } = useLocale();
  const [expanded, setExpanded] = useState(false);
  const [checkedState, setCheckedState] = useState({});

  const {
    data: postDetails = {},
    refetch: refetchPostDetails = () => {},
  } = usePostDetails();

  const {
    comments = [],
    id: postId = '',
  } = postDetails || {};

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const onSubmit = async (values) => {
    try {
      await revisePost(values, postId);

      snack({
        message: t('posts.comments.revisedSuccessfully'),
        severity: 'success',
      });
      refetchPostDetails();
    } catch ({ errors }) {
      snack({
        message: errors?.[0]?.message || t('common.somethingWrong'),
        severity: 'error',
      });
    }
  };

  useEffect(() => {
    const initialValues = {};
    comments?.forEach((comment) => {
      initialValues[comment.id] = comment.status;
    });

    setCheckedState(initialValues);
  }, [comments]);

  const toggleStatus = async (commentId) => {
    try {
      const { id, status } = await toggleCommentStatus(commentId);
      setCheckedState((prev) => ({
        ...prev,
        [id]: status,
      }));
    } catch (errors) {
      snack({
        message: errors?.[0]?.message || t('common.somethingWrong'),
        severity: 'error',
      });
    }
  };

  const onToggleChange = (e) => {
    toggleStatus(e.target.id);
  };

  const commentsLength = comments?.length;
  const allComments = comments?.map((comment, index) => {
    const isLastItem = commentsLength - 1 !== index;

    return (
      <Box key={comment.id} sx={{ py: 2 }}>
        <Box sx={{
          pb: 3,
          display: 'flex',
          justifyContent: 'space-between',
        }}
        >
          <Box sx={{
            textDecoration: checkedState[comment.id] === 'resolved'
              ? 'line-through' : null,
            color: checkedState[comment.id] === 'resolved'
              ? 'common.metalGrey' : 'common.couchGrey',
            display: 'flex',
            alignItems: 'center',
            listStyle: 'none',
            px: 3,
          }}
          >
            <li>{comment.comment}</li>
          </Box>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
          }}
          >
            {
              !isNotAdminOrLeader && (
                <Checkbox
                  id={String(comment.id)}
                  onChange={onToggleChange}
                  defaultChecked={comment.status === 'resolved'}
                  icon={<OutlinedCheckIcon stroke="#ccc" />}
                  checkedIcon={<FilledCheckIcon />}
                />
              )
            }
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
            }}
            >
              {commentStatus
                ? (
                  <Typography
                    variant="bodySmallRegular"
                    dir={dir}
                    sx={{
                      color: comment.status === 'resolved'
                        ? 'common.turquoiseSeaweedGreen' : 'gray',
                    }}
                  >
                    {comment.status === 'resolved' ? t('posts.comments.resolved')
                      : t('posts.comments.unresolved')}
                  </Typography>
                )
                : (
                  <Typography
                    variant="bodySmallRegular"
                    dir={dir}
                    sx={{
                      color: checkedState[comment.id] === 'resolved'
                        ? 'common.turquoiseSeaweedGreen' : 'gray',
                      fontWeight: checkedState[comment.id] === 'resolved' ? 'bold' : 'regular',
                    }}
                  >
                    {t(`posts.comments.${checkedState[comment.id]}`)}
                  </Typography>
                )}
            </Box>
          </Box>
        </Box>
        <Box>
          {isLastItem && <Divider />}
        </Box>
      </Box>
    );
  });

  return (
    <Card>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        px: 2,
      }}
      >
        <CardContent>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
          }}
          >
            <CommentIcon />
            <Box sx={{ ml: 2 }}>
              <Typography
                variant="body2"
                color="text.secondary"
              >
                {t('posts.comments.comments')}
              </Typography>
            </Box>
          </Box>
        </CardContent>
        <CardActions disableSpacing>
          <ExpandMore
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          />
        </CardActions>
      </Box>
      <Collapse
        in={expanded}
        timeout="auto"
        unmountOnExit
      >
        <CardContent>
          <Typography
            variant="bodySmallRegular"
            sx={{ color: 'secondary.main' }}
          >
            {allComments}
          </Typography>
          {withSubmission
            && (
              <Box sx={{
                display: ' flex',
                justifyContent: 'flex-end',
                width: 1,
              }}
              >
                <Button
                  size="regular"
                  variant="outlined"
                  onClick={onSubmit}
                  sx={{
                    mt: 3,
                  }}
                >
                  {t('actions.return')}
                </Button>
              </Box>
            )}
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default CommentCard;
