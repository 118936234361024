import api from 'services/api';
import END_POINTS from 'services/endPoints';
import METHODS from 'services/methods';

const updateUserCertificate = (data, certificateId) => api(
  END_POINTS.userCertificate(certificateId),
  {
    method: METHODS.PUT,
    data,
  },
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default updateUserCertificate;
