import { Box, Typography } from '@mui/material';
import { ColoredBox } from 'components/molecules';

const CourseAlert = (props) => {
  const { header = 'header', desc = 'desc' } = props;

  return (
    <ColoredBox
      sx={{
        width: 1,
        height: 1,
        py: 4,
        px: 7,
      }}
      value={(
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          textAlign: 'left',
          gap: 1,
        }}
        >
          <Typography
            variant="bodyMediumRegular"
            color="common.winterDaysGray"
          >
            {header}
          </Typography>
          <Typography
            variant="bodySmallRegular"
            color="common.winterDaysGray"
          >
            {desc}
          </Typography>
        </Box>
        )}
    />
  );
};

export default CourseAlert;
