import {
  Box,
  TextField,
  Autocomplete,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { forwardRef, useEffect, useState } from 'react';
import { getAllTags } from 'services';
import { useLocale } from 'util/hooks';

const SelectTags = forwardRef((props, ref) => {
  const {
    name,
    error,
    required,
    ...rest
  } = props;

  const { dir, isAr } = useLocale();

  const [tags, setTags] = useState([]);

  useEffect(() => {
    certificatesTags();
  }, []);

  const certificatesTags = async () => {
    const filterOptions = {
      visibility: true,
    };
    const response = await getAllTags({ filterOptions });
    setTags(response.items);
  };

  const getOptionLabel = (option) => {
    if (!option) return '';

    return (isAr ? option.nameAr : option.nameEn);
  };

  const isOptionEqualToValue = (option, value) => {
    if (value === '') return true; // Prevent onClear warning

    if (typeof value === 'object') {
      return option.id === value.id;
    }

    const optionString = isAr ? option.nameAr : option.nameEn;
    return optionString === value;
  };

  return (
    <Autocomplete
      ref={ref}
      id={name}
      options={tags}
      fullWidth
      popupIcon={<ExpandMore />}
      autoHighlight
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={isOptionEqualToValue}
      renderOption={(optionProps, { nameAr, nameEn }) => (
        <Box dir={dir} {...optionProps}>
          {isAr ? nameAr : nameEn}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          required={required}
          error={error}
          label={rest.label || ''}
        />
      )}
      {...rest}
    />
  );
});

export default SelectTags;
