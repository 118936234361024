import { useCustomQuery } from 'reactQuery';
import getSessionDetails from 'services/courses/getSessionDetails';

const useSessionDetails = (queryProps = {}) => {
  const { courseId, sessionId } = queryProps;

  const query = useCustomQuery({
    queryKey: [`sessionDetails-${courseId}`],
    queryFn: () => getSessionDetails(courseId, sessionId),
    options: { refetchOnWindowFocus: false },
  });
  return { ...query };
};

export default useSessionDetails;
