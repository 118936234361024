import _ from 'lodash';
import {
  Box,
  Stack,
  Button,
  Typography,
} from '@mui/material';
import { courseContentPlaceholder } from 'assets/images';
import { OnDemandCourseDetails } from 'components/organisms';
import { useLocale, useModal } from 'util/hooks';
import MODAL_KEYS from 'assets/constants/modalKeys';

const OnDemandCourseContainer = (props) => {
  const {
    courseDetails = {},
    isFetched = false,
  } = props;

  const { isAr, t } = useLocale();
  const { addModal } = useModal();
  const showEmptyState = isFetched && _.isEmpty(courseDetails?.chapters);

  const onCreateCourse = () => {
    addModal({
      key: MODAL_KEYS.courseChapter,
      props: {
        createMode: true,
      },
    });
  };

  if (showEmptyState) {
    return (
      <Stack
        spacing={4}
        alignItems="center"
        justifyContent="center"
      >
        <Box
          sx={{
            width: 180,
            height: 143,
            transform: isAr ? 'scaleX(-1)' : 'none',
          }}
        >
          <img
            src={courseContentPlaceholder}
            alt="course-content-placeholder"
            width="180"
            height="143"
          />
        </Box>
        <Stack
          spacing={2}
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="bodyStandardMedium">
            {t('courses.oneMoreStep')}
          </Typography>
          <Typography variant="bodySmallRegular">
            {t('courses.uploadVideoNow')}
          </Typography>
        </Stack>
        <Button variant="contained" onClick={onCreateCourse}>
          {t('courses.createChapter')}
        </Button>
      </Stack>
    );
  }

  return <OnDemandCourseDetails courseDetails={courseDetails} />;
};

export default OnDemandCourseContainer;
