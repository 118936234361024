import { useParams } from 'react-router-dom';

import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { PATHS } from 'routes';
import { useAuth, useLocale } from 'util/hooks';

const EmptyPostsPlaceholder = () => {
  const { t } = useLocale();
  const { username } = useParams();
  const { currentUser = {} } = useAuth();

  const isOwnProfile = !!username && username === currentUser.username;

  return (
    <Stack
      spacing={5}
      alignItems="center"
      justifyContent="center"
    >
      <Stack
        spacing={2}
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="bodyStandardMedium">
          {isOwnProfile
            ? t('posts.placeholder.createPost')
            : t('posts.placeholder.noPosts')}
        </Typography>
        {isOwnProfile && (
          <Typography variant="bodySmallRegular">
            {t('posts.placeholder.createPostDesc')}
          </Typography>
        )}
      </Stack>
      {isOwnProfile && (
        <Button
          component={Link}
          to={`/${PATHS.createPost}`}
          variant="outlined"
          sx={{ borderRadius: 5 }}
        >
          {t('posts.createPost')}
        </Button>
      )}
    </Stack>
  );
};

export default EmptyPostsPlaceholder;
