import { atom } from 'recoil';
import { localStorageEffect } from 'recoil/effects';

const authAtom = atom({
  key: 'auth',
  default: {
    isLoggedIn: false,
    accessToken: '',
    user: {},
  },
  effects_UNSTABLE: [localStorageEffect('auth')],
});

export default authAtom;
