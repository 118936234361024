import { useTheme } from '@mui/styles';

const VideoPostIcon = (props) => {
  const { palette } = useTheme();

  const {
    fill = palette.common.pinkishRed,
    outerFill = palette.common.palePink,
    stroke = palette.common.white,
    ...rest
  } = props;

  return (
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <rect x="3" y="3" width="54" height="54" rx="11" fill={fill} stroke={outerFill} strokeWidth="6" />
      <g clipPath="url(#clip0_165_23272)">
        <path d="M30 41.3181C23.7584 41.3181 18.6797 36.2394 18.6797 29.9978C18.6797 23.7562 23.7584 18.6775 30 18.6775C36.2416 18.6775 41.3203 23.7562 41.3203 29.9978C41.3203 36.2394 36.2416 41.3181 30 41.3181ZM30 18.8181C23.8346 18.8181 18.8203 23.8324 18.8203 29.9978C18.8203 36.1632 23.8346 41.1775 30 41.1775C36.1654 41.1775 41.1797 36.1632 41.1797 29.9978C41.1797 23.8324 36.1654 18.8181 30 18.8181Z" stroke={stroke} strokeWidth="1.35938" />
        <path d="M27.44 25.5658L35.12 29.9999L27.44 34.4339V25.5658Z" stroke={stroke} strokeWidth="1.28" />
      </g>
    </svg>
  );
};
export default VideoPostIcon;
