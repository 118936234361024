import { useTheme } from '@mui/styles';

const SuccessIcon = (props) => {
  const { palette } = useTheme();

  const {
    fill = 'none',
    stroke = palette.common.turquoiseSeaweedGreen,
    ...rest
  } = props;

  return (
    <svg width="50" height="50" viewBox="0 0 50 50" fill={fill} xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g clipPath="url(#clip0_1737_14472)">
        <path d="M12.5 27.5475L17.6042 34.7913C17.7978 35.0805 18.0577 35.3193 18.3622 35.4878C18.6667 35.6562 19.0071 35.7496 19.3549 35.76C19.7028 35.7704 20.0481 35.6975 20.3622 35.5475C20.6762 35.3975 20.9499 35.1747 21.1604 34.8975L37.5 14.2246" stroke={stroke} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M25 48.4355C37.9442 48.4355 48.4375 37.9422 48.4375 24.998C48.4375 12.0539 37.9442 1.56055 25 1.56055C12.0558 1.56055 1.5625 12.0539 1.5625 24.998C1.5625 37.9422 12.0558 48.4355 25 48.4355Z" stroke={stroke} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_1737_14472">
          <rect width="50" height="50" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SuccessIcon;
