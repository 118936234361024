import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { useParams } from 'react-router-dom';

import {
  Box,
  Stack,
  Button,
  Typography,
} from '@mui/material';

import {
  useLocale,
  useSnackbar,
  useFastForm,
} from 'util/hooks';
import { REGEX, getDirtyObject } from 'util/helpers';
import { FormAutocomplete, FormTextField } from 'components/form';
import { createCourseMaterial, editCourseMaterial } from 'services';
import { useCourseChapters, useCourseDetails } from 'reactQuery/queries';
import { COURSE_MATERIAL_TYPES } from 'assets/constants/courses';

const CourseVideoDetailsForm = (props) => {
  const {
    chapterId = '',
    createMode = false,
    materialDetails = {},
    handleClickCancel = () => {},
  } = props;

  const { t } = useLocale();
  const snack = useSnackbar();
  const { courseId } = useParams();

  const {
    data: courseChapters = [],
  } = useCourseChapters();

  const chapter = courseChapters.find((ch) => ch.id === chapterId);

  const {
    title = '',
    vimeoId = '',
    id: materialId = '',
    description = '',
  } = materialDetails || {};

  const {
    refetch: refetchCourse = () => {},
  } = useCourseDetails();

  const onSubmit = async (values) => {
    let message = '';

    const payload = {
      ...values,
      chapter: { id: Number(values.chapter.id) },
      type: COURSE_MATERIAL_TYPES.video,
    };

    try {
      if (createMode) {
        await createCourseMaterial(payload, courseId, chapterId);
        message = t('courses.videoCreated');
      } else {
        const dirtyValues = getDirtyObject(payload, defaultValues);
        await editCourseMaterial(dirtyValues, courseId, chapterId, materialId);
        message = t('courses.videoUpdated');
      }

      handleClickCancel();
      refetchCourse();
      snack({
        severity: 'success',
        message,
      });
    } catch (error) {
      error.errors?.forEach((err) => {
        setError(err.property, {
          type: 'api',
          message: err.message,
        });

        if (!err.property) {
          snack({
            severity: 'error',
            message: err.message || t('common.somethingWrong'),
          });
        }
      });
    }
  };

  const defaultValues = {
    chapter,
    title,
    vimeoId,
    description,
  };

  const validationSchema = Yup.object().shape(
    {
      chapter: Yup
        .object()
        .nullable(true)
        .required(t('courses.chapterRequired')),
      title: Yup
        .string()
        .required(t('courses.videoTitleRequired')),
      description: Yup
        .string()
        .nullable(true)
        .max(4000, t('courses.maxDescription')),
      vimeoId: Yup
        .string()
        .matches(REGEX.digits, t('courses.validateVimeoId'))
        .required(t('courses.vimeoRequired')),
    },
  );

  const {
    control,
    setError,
    handleSubmit,
    formState: {
      isSubmitting,
      isDirty,
    },
  } = useFastForm({
    defaultValues,
    validationSchema,
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box
        sx={{
          mb: 12,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Typography variant="h5">
          {createMode ? t('courses.newVideo') : t('courses.updateVideo')}
        </Typography>
      </Box>
      <Stack spacing={7}>
        <Box>
          <FormAutocomplete
            name="chapter"
            control={control}
            label={t('common.chapter')}
            fullWidth
            withOptions
            options={courseChapters}
          />
        </Box>
        <Box>
          <FormTextField
            name="title"
            control={control}
            label={t('courses.videoTitle')}
            disabled={isSubmitting}
            fullWidth
          />
        </Box>
        <Box>
          <FormTextField
            name="description"
            control={control}
            label={t('courses.videoDescription')}
            disabled={isSubmitting}
            fullWidth
          />
        </Box>
        <Box>
          <FormTextField
            name="vimeoId"
            control={control}
            label={t('courses.videoId')}
            disabled={isSubmitting}
            fullWidth
          />
        </Box>
      </Stack>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          mt: 10,
        }}
      >
        <Button
          onClick={handleClickCancel}
          variant="outlined"
          size="regular"
          color="secondary"
        >
          {t('common.cancel')}
        </Button>
        <LoadingButton
          type="submit"
          size="regular"
          loading={isSubmitting}
          disabled={isSubmitting || !isDirty}
          variant="contained"
          sx={{ ml: 5 }}
        >
          {createMode ? t('common.create') : t('common.update')}
        </LoadingButton>
      </Box>
    </form>
  );
};

export default CourseVideoDetailsForm;
