import {
  Box,
  Button,
  Fab,
  Link,
} from '@mui/material';
import { PATHS } from 'routes';
import AddIcon from '@mui/icons-material/Add';
import { useLocale } from 'util/hooks';

const WritePostButton = () => {
  const { t } = useLocale();

  return (
    <Box>
      <Button
        component={Link}
        variant="contained"
        sx={{ display: { xs: 'none', sm: 'block', md: 'block' } }}
        to={`/${PATHS.createPost}`}

      >
        {t('posts.writePost')}
      </Button>
      <Fab
        component={Link}
        disableRipple
        color="primary"
        aria-label="add"
        sx={{
          display: { xs: 'flex', sm: 'none' },
          position: 'fixed',
          bottom: '12%',
          right: '10%',
          boxShadow: '0 4px 4px 2px rgba(0, 0, 0, 0.08)',
        }}
        to={`/${PATHS.createPost}`}
      >
        <AddIcon />
      </Fab>
    </Box>
  );
};

export default WritePostButton;
