import { useTheme } from '@mui/styles';

const DiagramNestedIcon = (props) => {
  const { palette } = useTheme();

  const {
    stroke = palette.common.gadgetGray,
    fill = palette.common.offWhite,
    ...rest
  } = props;

  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g clipPath="url(#clip0_1977_8910)">
        <rect width="32" height="32" rx="8" fill={fill} />
        <path fillRule="evenodd" clipRule="evenodd" d="M6.625 20.623C6.625 19.7946 7.29657 19.123 8.125 19.123H11.375C12.2034 19.123 12.875 19.7946 12.875 20.623V23.873C12.875 24.7015 12.2034 25.373 11.375 25.373H8.125C7.29657 25.373 6.625 24.7015 6.625 23.873V20.623Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path fillRule="evenodd" clipRule="evenodd" d="M12.875 8.12305C12.875 7.29462 13.5466 6.62305 14.375 6.62305H17.625C18.4534 6.62305 19.125 7.29462 19.125 8.12305V11.373C19.125 12.2015 18.4534 12.873 17.625 12.873H14.375C13.5466 12.873 12.875 12.2015 12.875 11.373V8.12305Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path fillRule="evenodd" clipRule="evenodd" d="M19.125 20.623C19.125 19.7946 19.7966 19.123 20.625 19.123H23.875C24.7034 19.123 25.375 19.7946 25.375 20.623V23.873C25.375 24.7015 24.7034 25.373 23.875 25.373H20.625C19.7966 25.373 19.125 24.7015 19.125 23.873V20.623Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M9.75 19.123V17.873C9.75 17.1827 10.3096 16.623 11 16.623H21C21.6904 16.623 22.25 17.1827 22.25 17.873V19.123" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M16 12.873V16.623" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </g>
    </svg>
  );
};
export default DiagramNestedIcon;
