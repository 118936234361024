import { getAllPublicCourses } from 'services';
import { useCustomQuery } from 'reactQuery';

const usePublicCourses = (queryProps = {}) => {
  const { queryKeyOptions = {}, ...rest } = queryProps;

  const query = useCustomQuery({
    queryKey: ['public-courses', queryKeyOptions],
    queryFn: getAllPublicCourses,
    ...rest,
  });

  return { ...query };
};

export default usePublicCourses;
