import {
  Box,
  Link,
  Stack,
  Avatar,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/styles';
import { LeaderboardBronzeIcon, LeaderboardGoldIcon, LeaderboardSilverIcon } from 'assets/icons';
import { useLocale } from 'util/hooks';

const ranks = [
  {
    order: 2,
    avatarBorder: ((palette) => `7.08px solid ${palette.leaderboardGold}`),
    icon: <LeaderboardGoldIcon />,
  },
  {
    order: 1,
    avatarBorder: ((palette) => `5.83px solid ${palette.leaderboardSilver}`),
    icon: <LeaderboardSilverIcon />,
  },
  {
    order: 3,
    avatarBorder: ((palette) => `5.83px solid ${palette.leaderboardBronze}`),
    icon: <LeaderboardBronzeIcon />,
  },
];

const LeaderboardTopThreeListItem = (props) => {
  const { t } = useLocale();
  const { palette } = useTheme();

  const {
    rank = 0,
    name = '',
    avatar = '',
    username = '',
    points = '',
    id = '',
    ...rest
  } = props;
  const isFirst = rank === 1;

  const sx = {
    mx: { sm: 2, md: 11 },
    my: 3,
    pt: { sm: isFirst ? 0 : 20 },
    alignItems: 'center',
    rowGap: 1,
    order: { xs: 0, sm: ranks[rank - 1].order },
    ...rest,
  };

  return (
    <Stack
      sx={sx}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar
          src={avatar}
          sx={{
            height: isFirst ? 170 : 140,
            width: isFirst ? 170 : 140,
            border: ranks[rank - 1].avatarBorder(palette.common),
          }}
        />
        <Box
          sx={{
            top: -16, position: 'relative', height: 30,
          }}
        >
          {ranks[rank - 1].icon}
        </Box>
      </Box>
      <Typography
        sx={{
          textAlign: 'center',
          overflowWrap: 'anywhere',
          width: { md: 214 },
        }}
        variant="bodyMediumMedium"
        color={palette.common.darkGray}
        component={Link}
        to={username ? `/profile/${username}` : `/club-profile/${id}`}
      >
        {name}
      </Typography>
      <Typography sx={{ color: palette.common.gadgetGray }}>
        {`${points} ${t('common.points').toLowerCase()}`}
      </Typography>
    </Stack>
  );
};

export default LeaderboardTopThreeListItem;
