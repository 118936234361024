import React from 'react';

import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

import { useLocale } from 'util/hooks';
import { NoPostsIcon } from 'assets/icons';
import { PostCardContainer } from 'components/organisms';
import { CustomInfiniteScroll, DataPlaceholder } from 'components/molecules';
import { useInfinitePublicPosts } from 'reactQuery/queries';

const HomePostsContainer = () => {
  const { t, language } = useLocale();

  const {
    data: { pages = [] } = {},
    refetch = () => { },
    isFetched = false,
    isFetching = false,
    hasData = true,
    hasNextPage,
    fetchNextPage,
  } = useInfinitePublicPosts({
    queryKeyOptions: {
      searchOptions: {
        withAnnouncements: true, // all announcements including certificate posts
      },
    },
  });

  React.useEffect(() => {
    refetch(); // Refetch when language changes due to BE localization
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  const posts = React.useMemo(() => pages?.map((page) => {
    const key = `home-posts-page-${page.meta?.currentPage}`;

    return (
      <div key={key}>
        {page.items?.map((post) => (
          <Box key={post.id} mt={2}>
            <PostCardContainer
              key={post.id}
              postDetails={post}
            />
          </Box>
        ))}
      </div>
    );
  }), [pages]);

  if (isFetched && !hasData) {
    return (
      <DataPlaceholder
        icon={<NoPostsIcon />}
        header={t('posts.placeholder.noPosts')}
      />
    );
  }
  return (
    <CustomInfiniteScroll
      loadMore={fetchNextPage}
      hasMore={hasNextPage}
      isFetching={isFetching}
    >
      <Box sx={{ height: 2 }}>
        {isFetching && <LinearProgress />}
      </Box>
      {posts}
    </CustomInfiniteScroll>
  );
};

export default HomePostsContainer;
