import END_POINTS from '../../endPoints';
import METHODS from '../../methods';
import api from '../../api';

const deleteTag = (id) => api(
  END_POINTS.tag(id),
  {
    method: METHODS.DELETE,
  },
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default deleteTag;
