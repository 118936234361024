import {
  Box,
  Card,
  Modal,
  Button,
  Typography,
} from '@mui/material';
import { useLocale, useSnackbar } from 'util/hooks';
import { NafathLogo } from 'assets/icons/logos';

const NafathModal = () => {
  const { REACT_APP_BASE_URL } = process.env;

  const { t, dir } = useLocale();
  const snack = useSnackbar();

  const NavigateToNafath = () => {
    try {
      window.open(`${REACT_APP_BASE_URL}/nafath/send`, '_blank');
    } catch (error) {
      snack({
        message: t('common.somethingWrong'),
        severity: 'error',
      });
    }
  };

  return (
    <Modal
      open
      dir={dir}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Card sx={{
        bgcolor: 'background.default',
        display: 'flex',
        maxWidth: 400,
        width: 400,
        height: 400,
        m: 3,
      }}
      >
        <Box sx={{
          px: 5,
          display: 'flex',
          flexFlow: 'column wrap',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        >
          <NafathLogo />
          <Box sx={{ mt: 3 }}>
            <Typography
              variant="bodyStandardMedium"
              color="common.couchGrey"
              sx={{ textAlign: 'center' }}
            >
              {t('nafath.nafathModalHeader')}
            </Typography>
          </Box>
          <Box sx={{ mt: 5, textAlign: 'center' }}>
            <Typography variant="bodyStandardRegular">
              {t('nafath.nafathModalDesc')}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: 10,
              mb: 2,
            }}
          >
            <Button
              onClick={NavigateToNafath}
              size="regular"
              variant="contained"
            >
              {t('nafath.verifyAccount')}
            </Button>
          </Box>
        </Box>
      </Card>
    </Modal>
  );
};

export default NafathModal;
