import END_POINTS from '../endPoints';
import api from '../api';

const getUserInfo = (username = '') => api(
  END_POINTS.userInfo(username),
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default getUserInfo;
