import { LeaderboardList } from 'components/organisms';
import { useTopClubs } from 'reactQuery/queries';

const ClubsLeaderboardContainer = (props) => {
  const {
    data = {},
  } = useTopClubs();

  return (
    <LeaderboardList
      topTen={data}
      {...props}
    />
  );
};

export default ClubsLeaderboardContainer;
