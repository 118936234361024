import { Typography, Box } from '@mui/material';
import { CourseCard } from 'components/organisms';
import { useLocation } from 'react-router-dom';
import { PATHS } from 'routes';
import { localizedDate } from 'util/helpers';
import { useLocale } from 'util/hooks';

const PublicCoursesList = (props) => {
  const {
    courses = [],
  } = props;

  const { t, language } = useLocale();
  const { pathname } = useLocation();
  const isMyCourses = pathname === `/${PATHS.myCourses}`;

  const groupedCourses = courses.reduce((group, course) => {
    const date = course.startAt;
    if (!group[date]) {
      // eslint-disable-next-line no-param-reassign
      group[date] = [];
    }
    group[date].push(course);
    return group;
  }, {});

  const newCoursesArray = Object.keys(groupedCourses).map((date) => ({
    date,
    courses: groupedCourses[date],
  }));

  const renderCoursesWithoutDate = () => (
    <Box
      sx={{
        width: 1,
        display: 'grid',
        mt: 8,
        mb: 12,
        rowGap: 8,
        columnGap: 6,
        gridTemplateColumns: {
          xs: 'repeat(1, 1fr)',
          sm: 'repeat(1, 1fr)',
          md: 'repeat(2, 1fr)',
          lg: 'repeat(3, 1fr)',
        },
      }}
    >
      {courses?.map((course) => (
        <CourseCard
          key={course.id}
          courseDetails={course}
        />
      ))}
    </Box>
  );

  const renderCoursesWithDate = newCoursesArray?.map((array) => (
    <Box key={array.date} sx={{ mb: 16 }}>
      <Typography
        color="common.cyan"
        variant="bodyStandardMedium"
      >
        {`${t('common.starts')} ${localizedDate(language, array.date)}`}
      </Typography>
      <Box sx={{
        mt: 8,
        mb: 12,
        rowGap: 8,
        columnGap: 6,
        display: 'grid',
        gridTemplateColumns: {
          xs: 'repeat(1, 1fr)',
          sm: 'repeat(1, 1fr)',
          md: 'repeat(2, 1fr)',
          lg: 'repeat(3, 1fr)',
        },
      }}
      >
        {array.courses.map((course) => (
          <CourseCard
            key={course.id}
            courseDetails={course}
          />
        ))}
      </Box>
    </Box>
  ));

  return isMyCourses ? renderCoursesWithDate : renderCoursesWithoutDate();
};

export default PublicCoursesList;
