import React from 'react';
import { LoadingButton } from '@mui/lab';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { useLocale, useSnackbar } from 'util/hooks';
import { deleteUserCertificate } from 'services';

const DeleteCertificateModal = (props) => {
  const {
    refetchCertificates = () => { },
    handleClose = () => { },
    certificateId = '',
  } = props;

  const { t } = useLocale();
  const snack = useSnackbar();
  const [isLoading, setIsLoading] = React.useState(false);

  const onSubmit = async () => {
    setIsLoading(true);
    try {
      await deleteUserCertificate(certificateId);
      handleClose();
      snack({
        message: t('certificates.deletedSuccessfully'),
        severity: 'success',
      });
      refetchCertificates();
    } catch (error) {
      snack({
        message: error?.message || error?.errors?.[0]?.message || t('common.somethingWrong'),
        severity: 'error',
      });
    }
    setIsLoading(false);
  };

  return (
    <Card sx={{
      bgcolor: 'background.default',
      minWidth: 360,
      p: {
        xs: 5,
        sm: 11,
      },
    }}
    >
      <Box sx={{
        display: 'flex',
        flexFlow: 'column wrap',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      >
        <Typography variant="h4">
          {t('certificates.deleteCertificate')}
        </Typography>
        <Box sx={{ mt: 12, textAlign: 'center' }}>
          <Typography variant="bodyMediumRegular">
            {t('certificates.deleteCertificateMsg')}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: 10,
            mb: 2,
          }}
        >
          <Button
            onClick={handleClose}
            variant="outlined"
            size="regular"
            color="secondary"
          >
            {t('common.cancel')}
          </Button>
          <LoadingButton
            onClick={onSubmit}
            loading={isLoading}
            size="regular"
            variant="contained"
            sx={{
              ml: 5,
            }}
          >
            {t('actions.yesDelete')}
          </LoadingButton>
        </Box>
      </Box>
    </Card>
  );
};

export default DeleteCertificateModal;
