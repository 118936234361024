import { Typography, Box } from '@mui/material';

const IconWithLabelContainer = (props) => {
  const { icon = null, text = '' } = props;
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {icon}
      <Typography
        variant="bodySmallRegular"
        color="text.secondary"
        sx={{ ml: 1 }}
      >
        {text}
      </Typography>
    </Box>
  );
};

export default IconWithLabelContainer;
