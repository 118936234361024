import { useTheme } from '@mui/styles';

const SessionDateIcon = (props) => {
  const { palette } = useTheme();

  const {
    fill = palette.common.cyanBlue,
    stroke = palette.common.softBlue,
  } = props;

  return (
    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect y="0.125977" width="33.686" height="33.7477" rx="16.843" fill={fill} fillOpacity="0.2" />
      <path fillRule="evenodd" clipRule="evenodd" d="M9.3457 12.9062C9.3457 12.1296 9.97526 11.5 10.7519 11.5H22.9395C23.7161 11.5 24.3457 12.1296 24.3457 12.9062V23.0938C24.3457 23.8704 23.7161 24.5 22.9395 24.5H10.7519C9.97526 24.5 9.3457 23.8704 9.3457 23.0938V12.9062Z" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.3457 15.5H24.3457" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M13.3457 13V9.5" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M20.3457 13V9.5" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default SessionDateIcon;
