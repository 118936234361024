import { useCustomInfiniteQuery } from 'reactQuery';
import { getBookmarkedPosts } from 'services';

const useInfiniteBookmarkedPosts = (queryProps = {}) => {
  const { queryKeyOptions = {}, ...rest } = queryProps;

  const query = useCustomInfiniteQuery({
    queryKey: ['infinite-bookmarked-posts', queryKeyOptions],
    queryFn: getBookmarkedPosts,
    ...rest,
  });

  return { ...query };
};

export default useInfiniteBookmarkedPosts;
