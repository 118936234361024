import React from 'react';
import Box from '@mui/material/Box';

import { useLocale } from 'util/hooks';
import { NoPostsWithCreateIcon } from 'assets/icons';
import { CustomInfiniteScroll, DataPlaceholder } from 'components/molecules';
import { PostCardContainer } from 'components/organisms';

import { useInfiniteMyPosts } from 'reactQuery/queries';

const MyPostsContainer = () => {
  const { t } = useLocale();

  const {
    data: { pages = [] } = {},
    isFetched = false,
    isFetching = false,
    hasData = true,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteMyPosts();

  const posts = React.useMemo(() => pages?.map((page) => {
    const key = `my-posts-page-${page.meta?.currentPage}`;

    return (
      <div key={key}>
        {page.items?.map((post) => (
          <Box key={post.id} mt={2}>
            <PostCardContainer
              key={post.id}
              postDetails={post}
            />
          </Box>
        ))}
      </div>
    );
  }), [pages]);

  if (isFetched && !hasData) {
    return (
      <DataPlaceholder
        icon={<NoPostsWithCreateIcon />}
        header={t('posts.placeholder.createPost')}
        description={t('posts.placeholder.createPostDesc')}
      />
    );
  }

  return (
    <CustomInfiniteScroll
      loadMore={fetchNextPage}
      hasMore={hasNextPage}
      isFetching={isFetching}
    >
      {posts}
    </CustomInfiniteScroll>
  );
};

export default MyPostsContainer;
