export const getRoutesForRole = (routes = [], userRole = null) => routes
  .filter((route) => hasPermission(route.auth, userRole))
  .map((route) => {
    const configuredRoute = { ...route };
    if (route.children) {
      const childrenToRender = [...route.children];

      // Find index child, if any
      const indexChild = childrenToRender.find((child) => child.index);
      if (indexChild) {
        // Append index to children (index can't have path)
        childrenToRender.unshift({ ...indexChild, path: undefined });
      }

      // Repeat for all children
      configuredRoute.children = getRoutesForRole(childrenToRender, userRole);
    }
    return configuredRoute;
  });

export const hasPermission = (authArr, userRole) => {
  // If auth array is not defined, allow
  if (!authArr) {
    return true;
  } if (Array.isArray(authArr) && authArr.length === 0) {
    // If auth array is empty (has no permissions), allow if logged-in
    return !userRole;
  }
  // If role is a string, verify user has authorization for this route
  return authArr.includes(userRole);
};

export const isPublicRoute = (routeAuth) => !routeAuth || !routeAuth.length;

export const isPrivateRoute = (routeAuth) => Array.isArray(routeAuth) && routeAuth.length > 0;
