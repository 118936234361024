import { useTheme } from '@mui/styles';

const LanguageIcon = (props) => {
  const { palette } = useTheme();

  const {
    fill = 'none',
    rectFill = palette.common.ghostlyGrey,
    innerFill = palette.text.secondary,
    ...rest
  } = props;

  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill={fill} xmlns="http://www.w3.org/2000/svg" {...rest}>
      <rect width="32" height="32" rx="10.6667" fill={rectFill} />
      <path d="M12.625 13.4687C12.28 13.4687 12 13.1887 12 12.8437V12.2187H10.75V12.8437C10.75 13.1887 10.47 13.4687 10.125 13.4687C9.78 13.4687 9.5 13.1887 9.5 12.8437V10.3437C9.5 9.30958 10.3408 8.46875 11.375 8.46875C12.4092 8.46875 13.25 9.30958 13.25 10.3437V12.8437C13.25 13.1887 12.97 13.4687 12.625 13.4687ZM12 10.9687V10.3437C12 9.99875 11.72 9.71875 11.375 9.71875C11.03 9.71875 10.75 9.99875 10.75 10.3437V10.9687H12Z" fill={innerFill} />
      <path d="M19.672 19.4608C19.296 19.4608 18.962 19.3968 18.67 19.2688C18.378 19.1408 18.15 18.9588 17.986 18.7228C17.822 18.4908 17.74 18.2168 17.74 17.9008C17.74 17.5808 17.822 17.3047 17.986 17.0728C18.15 16.8407 18.378 16.6607 18.67 16.5327C18.962 16.4047 19.296 16.3407 19.672 16.3407H20.746V17.4688H19.672C19.46 17.4688 19.308 17.5028 19.216 17.5708C19.128 17.6388 19.084 17.7488 19.084 17.9008C19.084 18.0528 19.128 18.1628 19.216 18.2308C19.308 18.2988 19.46 18.3328 19.672 18.3328C19.764 18.3328 19.878 18.3268 20.014 18.3148C20.15 18.3068 20.31 18.2928 20.494 18.2728L20.686 19.3408C20.514 19.3768 20.344 19.4048 20.176 19.4248C20.008 19.4488 19.84 19.4608 19.672 19.4608ZM18.676 17.2467C18.492 17.0427 18.358 16.8507 18.274 16.6707C18.194 16.4907 18.154 16.2967 18.154 16.0887C18.154 15.8367 18.204 15.6107 18.304 15.4107C18.408 15.2067 18.546 15.0327 18.718 14.8887C18.89 14.7407 19.084 14.6287 19.3 14.5527C19.52 14.4767 19.746 14.4388 19.978 14.4388C20.106 14.4388 20.232 14.4507 20.356 14.4747C20.484 14.4947 20.604 14.5247 20.716 14.5647L20.362 15.6267C20.302 15.6067 20.246 15.5927 20.194 15.5847C20.146 15.5727 20.094 15.5667 20.038 15.5667C19.922 15.5667 19.822 15.5887 19.738 15.6327C19.658 15.6727 19.598 15.7307 19.558 15.8067C19.518 15.8827 19.498 15.9687 19.498 16.0647C19.498 16.1447 19.512 16.2207 19.54 16.2927C19.572 16.3607 19.62 16.4307 19.684 16.5027L18.676 17.2467Z" fill={innerFill} />
      <path d="M22.875 26C22.7083 26 22.5508 25.935 22.4333 25.8167L18.8667 22.25H15.375C14.3408 22.25 13.5 21.4092 13.5 20.375V14.125C13.5 13.0908 14.3408 12.25 15.375 12.25H24.125C25.1592 12.25 26 13.0908 26 14.125V20.375C26 21.4092 25.1592 22.25 24.125 22.25H23.5V25.375C23.5 25.6283 23.3483 25.855 23.1142 25.9525C23.0383 25.9842 22.9575 26 22.875 26ZM15.375 13.5C15.03 13.5 14.75 13.78 14.75 14.125V20.375C14.75 20.72 15.03 21 15.375 21H19.125C19.29 21 19.4508 21.0667 19.5667 21.1833L22.25 23.8667V21.625C22.25 21.28 22.53 21 22.875 21H24.125C24.47 21 24.75 20.72 24.75 20.375V14.125C24.75 13.78 24.47 13.5 24.125 13.5H15.375Z" fill={innerFill} />
      <path d="M9.125 19.75C9.0425 19.75 8.96167 19.7342 8.88583 19.7025C8.65167 19.605 8.5 19.3783 8.5 19.125V16H7.875C6.84083 16 6 15.1592 6 14.125V7.875C6 6.84083 6.84083 6 7.875 6H16.625C17.6592 6 18.5 6.84083 18.5 7.875V10.375C18.5 10.72 18.22 11 17.875 11C17.53 11 17.25 10.72 17.25 10.375V7.875C17.25 7.53 16.97 7.25 16.625 7.25H7.875C7.53 7.25 7.25 7.53 7.25 7.875V14.125C7.25 14.47 7.53 14.75 7.875 14.75H9.125C9.47 14.75 9.75 15.03 9.75 15.375V17.6158L11.1833 16.1825C11.3008 16.065 11.4583 16 11.625 16C11.7917 16 11.9492 16.065 12.0667 16.1833C12.31 16.4267 12.31 16.8233 12.0667 17.0675L9.56667 19.5675C9.44917 19.685 9.29167 19.75 9.125 19.75Z" fill={innerFill} />
    </svg>
  );
};

export default LanguageIcon;
