import { useParams } from 'react-router-dom';
import { getCourseDetails } from 'services';
import { useCustomQuery } from 'reactQuery';
import { transofrmCourseDetails } from 'util/transformers';

const useCourseDetails = (queryProps = {}) => {
  const {
    options = {},
    ...rest
  } = queryProps;

  const { courseId } = useParams();

  const query = useCustomQuery({
    queryKey: [`course-details-${courseId}`],
    queryFn: () => getCourseDetails(courseId),
    options: {
      select: transofrmCourseDetails,
      ...options,
    },
    ...rest,
  });

  return { ...query };
};

export default useCourseDetails;
