import {
  Stepper as MuiStepper,
  Step,
  StepLabel,
  Box,
  StepButton,
  StepContent,
} from '@mui/material';
import PropTypes from 'prop-types';

/**
 * @USAGE in parent component:
 * 1. const [activeStep, setActiveStep] = useState(0);
 * 2. const steps = ['Steps labels'];
 * 3. const handleNextStep = () => {
 * setActiveStep((step) => step + 1);
 * };
 * 4. const renderPages = (index) => {
 *  switch (index) {
 *      case 0:
 *          return <Component1 handleNextStep={handleNextStep} />;
 *      case 1:
 *          return <Component2 handleNextStep={handleNextStep} />;
 *      default:
 *          return 'Not Found';
 *      }
 * };
 * 5. Then pass renderPages, steps,
   activeStep, setActiveStep and type to
   Stepper component. + pass handleNext
   function to rendered components/pages.
 */

const Stepper = (props) => {
  const {
    renderPages,
    type,
    steps,
    activeStep,
    setActiveStep,
    stepperProps,
    stepsProps,
    stepButtonProps,
    stepLabelProps,
    StepContentProps,
  } = props;

  const handleBack = () => {
    setActiveStep((step) => step - 1);
  };

  const handleStep = (step) => () => {
    if (activeStep - 1) {
      handleBack();
    }
    return setActiveStep(step);
  };

  return (
    <Box>
      <MuiStepper activeStep={activeStep} orientation={type} {...stepperProps}>
        {steps?.map((label, index) => (
          <Step key={label} {...stepsProps}>
            {(activeStep > 0)
              ? (
                <StepButton
                  onClick={handleStep(index)}
                  {...stepButtonProps}
                >
                  {label}
                </StepButton>
              )
              : <StepLabel {...stepLabelProps}>{label}</StepLabel>}
            {type === 'vertical' && (
            <StepContent {...StepContentProps}>
              {renderPages(activeStep)}
            </StepContent>
            )}
          </Step>
        ))}
      </MuiStepper>
      {type === 'horizontal' && renderPages(activeStep)}
    </Box>
  );
};

Stepper.propTypes = {
  renderPages: PropTypes.func,
  steps: PropTypes.arrayOf(PropTypes.string),
  type: PropTypes.string,
  stepperProps: PropTypes.oneOfType([PropTypes.object]),
  stepsProps: PropTypes.oneOfType([PropTypes.object]),
  stepButtonProps: PropTypes.oneOfType([PropTypes.object]),
  stepLabelProps: PropTypes.oneOfType([PropTypes.object]),
  StepContentProps: PropTypes.oneOfType([PropTypes.object]),
};

Stepper.defaultProps = {
  renderPages: () => {},
  steps: [],
  type: 'horizontal',
  stepperProps: {},
  stepsProps: {},
  stepButtonProps: {},
  stepLabelProps: {},
  StepContentProps: {},
};

export default Stepper;
