import { COURSE_TYPES, LIVE_COURSE_STATUSES } from 'assets/constants/courses';

const transofrmCourseDetails = (course) => {
  const {
    type = '',
    status = '',
  } = course;

  const isCourseUnderReview = status === LIVE_COURSE_STATUSES.UNDER_REVIEW;
  const isCoursePublished = status === LIVE_COURSE_STATUSES.PUBLISHED;
  const isCourseCompleted = status === LIVE_COURSE_STATUSES.COMPLETED;
  const isCourseCancelled = status === LIVE_COURSE_STATUSES.CANCELLED;
  const isCourseRejected = status === LIVE_COURSE_STATUSES.REJECTED;
  const isCourseRunning = status === LIVE_COURSE_STATUSES.RUNNING;
  const isCourseDraft = status === LIVE_COURSE_STATUSES.DRAFT;

  const isOnDemand = type === COURSE_TYPES.onDemand;

  return {
    ...course,
    isCourseUnderReview,
    isCourseCompleted,
    isCoursePublished,
    isCourseRejected,
    isCourseCancelled,
    isCourseRunning,
    isCourseDraft,
    isOnDemand,
  };
};

export default transofrmCourseDetails;
