import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import {
  Typography, Box,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import {
  FormCheckbox,
  FormNumberField,
  FormTextField,
} from 'components/form';
import { getDirtyObject, numberWithoutCommas, REGEX } from 'util/helpers';
import { useFastForm, useLocale, useSnackbar } from 'util/hooks';
import { createTopic, updateTopic } from 'services';
import { PATHS } from 'routes';

const TopicDetailsForm = (props) => {
  const {
    topicDetails,
    createMode,
  } = props;

  const { t } = useLocale();
  const snack = useSnackbar();
  const navigate = useNavigate();

  const onSubmit = async (values) => {
    const dirtyPayload = getDirtyObject(values, defaultValues);

    try {
      let message = '';
      if (createMode) {
        await createTopic(values);
        message = t('topics.form.topicAdded');
      } else {
        await updateTopic(dirtyPayload, topicDetails.id);
        message = t('topics.form.topicEdited');
      }

      snack({
        severity: 'success',
        message,
      });
      navigate(`/${PATHS.platformSettings}/${PATHS.topicsSettings}`);
    } catch (error) {
      error.errors?.forEach((err) => {
        setError(err.property, {
          type: 'api',
          message: err.message,
        });
        if (!err.property) {
          snack({
            severity: 'error',
            message: err.message || t('common.somethingWrong'),
          });
        }
      });
    }
  };

  const defaultValues = {
    nameEn: topicDetails?.nameEn || '',
    nameAr: topicDetails?.nameAr || '',
    knowledgeSharePoints: topicDetails?.knowledgeSharePoints || '',
    articlePoints: topicDetails?.articlePoints || '',
    videoPoints: topicDetails?.videoPoints || '',
    onDemandCoursePoints: topicDetails?.onDemandCoursePoints || '',
    certificatePoints: topicDetails?.certificatePoints || '',
    liveCoursePoints: topicDetails?.liveCoursePoints || '',
    visibility: topicDetails?.visibility || false,
  };

  const validationSchema = useMemo(() => Yup.object({
    nameEn: Yup
      .string()
      .trim()
      .required(t('topics.form.validation.enterTopicName'))
      .matches(REGEX.alphaNumericEnSpaceCommaParentheses, t('topics.form.validation.enterEnglishTopic'))
      .max(40, t('topics.form.validation.maxTopicLength')),
    nameAr: Yup
      .string()
      .trim()
      .required(t('topics.form.validation.enterTopicName'))
      .matches(REGEX.alphaNumericArSpaceCommaParentheses, t('topics.form.validation.enterArabicTopic'))
      .max(40, t('topics.form.validation.maxTopicLength')),
    knowledgeSharePoints: Yup
      .number()
      .typeError(t('topics.form.validation.enterNumbers'))
      .transform((o, v) => numberWithoutCommas(v))
      .required(t('topics.form.validation.enterShareKnowledge'))
      .max(999, t('topics.form.validation.maxPointLength')),
    articlePoints: Yup
      .number()
      .typeError(t('topics.form.validation.enterNumbers'))
      .transform((o, v) => numberWithoutCommas(v))
      .required(t('topics.form.validation.enterArticle'))
      .max(999, t('topics.form.validation.maxPointLength')),
    videoPoints: Yup
      .number()
      .typeError(t('topics.form.validation.enterNumbers'))
      .transform((o, v) => numberWithoutCommas(v))
      .required(t('topics.form.validation.enterVideoPoints'))
      .max(999, t('topics.form.validation.maxPointLength')),
    onDemandCoursePoints: Yup
      .number()
      .typeError(t('topics.form.validation.enterNumbers'))
      .transform((o, v) => numberWithoutCommas(v))
      .required(t('topics.form.validation.enterOnDemandCourse'))
      .max(999, t('topics.form.validation.maxPointLength')),
    liveCoursePoints: Yup
      .number()
      .typeError(t('topics.form.validation.enterNumbers'))
      .transform((o, v) => numberWithoutCommas(v))
      .required(t('topics.form.validation.enterLiveCoursePoints'))
      .max(999, t('topics.form.validation.maxPointLength')),
    certificatePoints: Yup
      .number()
      .typeError(t('topics.form.validation.enterNumbers'))
      .transform((o, v) => numberWithoutCommas(v))
      .required(t('topics.form.validation.enterCertificatePoints'))
      .max(999, t('topics.form.validation.maxPointLength')),

    visibility: Yup
      .bool(),
  }), [t]);

  const {
    control,
    handleSubmit,
    watch,
    setError,
    formState: {
      isSubmitting,
      isDirty,
      isValid,
    },
  } = useFastForm({
    defaultValues,
    validationSchema,
  });
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box>
        <Box sx={{
          display: 'grid',
          rowGap: 5,
        }}
        >
          <Typography
            variant="h6"
            sx={{
              display: 'flex',
              justifyContent: 'start',
              mt: 4,
            }}
          >
            {t('topics.form.topicName')}
          </Typography>
          <Box>
            <FormTextField
              name="nameEn"
              control={control}
              label={t('topics.form.topicNameEn')}
              disabled={isSubmitting}
              fullWidth
            />
          </Box>
          <Box>
            <FormTextField
              name="nameAr"
              control={control}
              label={t('topics.form.topicNameAr')}
              disabled={isSubmitting}
              fullWidth
            />
          </Box>
          <Typography
            variant="h6"
            sx={{
              display: 'flex',
              justifyContent: 'start',
              mt: 4,
            }}
          >
            {t('topics.form.activitiesPoints')}
          </Typography>
          <Box>
            <FormNumberField
              name="knowledgeSharePoints"
              control={control}
              label={t('topics.form.shareKnowledgePoints')}
              disabled={isSubmitting}
              fullWidth
              inputProps={{ maxLength: 4 }}
            />
          </Box>
          <Box>
            <FormNumberField
              name="articlePoints"
              control={control}
              autoComplete="username"
              label={t('topics.form.articlePoints')}
              disabled={isSubmitting}
              fullWidth
              inputProps={{ maxLength: 4 }}
            />
          </Box>
          <Box>
            <FormNumberField
              name="videoPoints"
              control={control}
              label={t('topics.form.videoPoints')}
              disabled={isSubmitting}
              fullWidth
              inputProps={{ maxLength: 4 }}
            />
          </Box>
          <Typography
            variant="h6"
            sx={{
              display: 'flex',
              justifyContent: 'start',
              mt: 4,
            }}
          >
            {t('topics.form.coursesPoints')}
          </Typography>
          <Box>
            <FormNumberField
              name="onDemandCoursePoints"
              control={control}
              label={t('topics.form.onDemandCoursePoints')}
              disabled={isSubmitting}
              fullWidth
              inputProps={{ maxLength: 4 }}
            />
          </Box>
          <Box>
            <FormNumberField
              name="liveCoursePoints"
              control={control}
              label={t('topics.form.liveCoursePoints')}
              disabled={isSubmitting}
              fullWidth
              inputProps={{ maxLength: 4 }}
            />
          </Box>
          <Typography
            variant="h6"
            sx={{ mt: 4 }}
          >
            {t('topics.form.certificatesPoints')}
          </Typography>
          <Box>
            <FormNumberField
              name="certificatePoints"
              control={control}
              label={t('topics.form.certificatesPoints')}
              disabled={isSubmitting}
              fullWidth
              inputProps={{ maxLength: 4 }}
            />
          </Box>
          <Box>
            <FormCheckbox
              name="visibility"
              control={control}
              disabled={isSubmitting}
              checkboxProps={{
                checked: watch('visibility'),
              }}
              label={(
                <div>
                  <Typography variant="bodyStandardRegular">
                    {t('topics.form.setAsVisible')}
                  </Typography>
                </div>
              )}
            />
          </Box>
        </Box>
        <Box sx={{
          my: 8,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        >
          <LoadingButton
            type="submit"
            variant="contained"
            size="regular"
            loading={isSubmitting}
            disabled={isSubmitting || !isDirty || !isValid}
          >
            {createMode ? t('platformSettings.buttonLabels.addTopic') : t('common.saveChanges')}
          </LoadingButton>
        </Box>
      </Box>
    </form>
  );
};

TopicDetailsForm.propTypes = {
  topicDetails: PropTypes.oneOfType([PropTypes.object]),
  createMode: PropTypes.bool,
};

TopicDetailsForm.defaultProps = {
  topicDetails: {},
  createMode: false,
};

export default TopicDetailsForm;
