import { useTranslation } from 'react-i18next';

const useLocale = () => {
  const translation = useTranslation();
  const { i18n } = translation;
  const { language, changeLanguage } = i18n;

  const dir = i18n.dir();
  const currentLangue = language.slice(0, 2);

  const isAr = currentLangue === 'ar';
  const isRtl = dir === 'rtl';

  return {
    ...translation,
    dir,
    isAr,
    isRtl,
    language: currentLangue,
    changeLanguage,
  };
};

export default useLocale;
