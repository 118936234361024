import METHODS from 'services/methods';
import END_POINTS from '../endPoints';
import api from '../api';

const rejectCourse = (id) => api(
  END_POINTS.rejectCourse(id),
  {
    method: METHODS.POST,
  },
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default rejectCourse;
