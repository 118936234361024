import {
  OnDemandCourseIcon,
  LiveCourseIcon,
} from 'assets/icons';
import { PATHS } from 'routes';

export const LIVE_COURSE_STATUSES = {
  DRAFT: 'draft',
  UNDER_REVIEW: 'underReview',
  PUBLISHED: 'published',
  REJECTED: 'rejected',
  CANCELLED: 'cancelled',
  RUNNING: 'running',
  COMPLETED: 'completed',
};

export const ON_DEMAND_COURSE_STATUSES = {
  DRAFT: 'draft',
  PUBLISHED: 'published',
  CANCELLED: 'cancelled',
};

export const COURSE_PUBLIC_STATUSES = [
  {
    id: 'published',
    name: LIVE_COURSE_STATUSES.PUBLISHED,
    itemLabelKey: 'statuses.published',
  },
  {
    id: 'running',
    name: LIVE_COURSE_STATUSES.RUNNING,
    itemLabelKey: 'statuses.running',
  },
  {
    id: 'completed',
    name: LIVE_COURSE_STATUSES.COMPLETED,
    itemLabelKey: 'statuses.completed',
  },
];

export const COURSE_TYPES = {
  onDemand: 'on-demand',
  live: 'live',
};

export const COURSE_TYPES_DETAILS = [
  {
    key: COURSE_TYPES.onDemand,
    shortKey: COURSE_TYPES.onDemand,
    icon: OnDemandCourseIcon,
    smallIcon: <OnDemandCourseIcon width="20" height="20" />,
    localizationKey: 'courseTypeList.onDemand',
    to: `/${PATHS.createOnDemandCourse}`,
    divider: true,
    description: [
      { text: 'courseTypeList.description.onDemand1' },
      { text: 'courseTypeList.description.onDemand2' },
    ],
  },
  {
    key: COURSE_TYPES.live,
    shortKey: COURSE_TYPES.live,
    icon: LiveCourseIcon,
    smallIcon: <LiveCourseIcon width="20" height="20" />,
    localizationKey: 'courseTypeList.live',
    to: `/${PATHS.createLiveCourse}`,
    divider: false,
    description: [
      { text: 'courseTypeList.description.live' },
    ],
  },
];

export const COURSE_MATERIAL_TYPES = {
  text: 'text',
  video: 'video',
  attachment: 'attachment',
};

export const COURSE_TRACK_COLORS = {
  defense: 'softBlue',
  foundational: 'dimMintLeafGreen',
  offense: 'pinkishRed',
};
