import { memo } from 'react';
import { useController } from 'react-hook-form';
import PropTypes from 'prop-types';
import { TextField } from 'components/molecules';
import { formatNumber, formatNumberCanStartWithZero } from 'util/helpers';

const FormNumberField = (props) => {
  const {
    commaSeparated,
    startWithZero,
    ...rest
  } = props;

  const { field, fieldState } = useController(props);
  const { onChange } = field;
  const { error } = fieldState;

  const hasErrored = Boolean(error);

  const onFieldChange = ({ target: { value: newValue = '' } }) => {
    if (startWithZero) {
      onChange(formatNumberCanStartWithZero(newValue));
    } else {
      onChange(formatNumber(newValue, commaSeparated));
    }
  };

  const calculateValue = (value) => {
    if (startWithZero) {
      return formatNumberCanStartWithZero(value);
    }
    return formatNumber(value, commaSeparated);
  };

  return (
    <TextField
      {...field}
      {...rest}
      error={hasErrored}
      helperText={(hasErrored && error?.message) || rest.helperText}
      value={calculateValue(String(field.value))}
      onChange={onFieldChange}
    />
  );
};

FormNumberField.propTypes = {
  commaSeparated: PropTypes.bool,
  startWithZero: PropTypes.bool,
};

FormNumberField.defaultProps = {
  commaSeparated: true,
  startWithZero: false,
};

export default memo(FormNumberField);
