import {
  GridOverlay,
} from '@mui/x-data-grid';
import {
  Box,
  Button,
  Stack,
  Typography,
  Link,
} from '@mui/material';
import { noRowsPlaceholder } from 'assets/images';
import { PATHS } from 'routes';
import { useLocale } from 'util/hooks';

const CustomNoRowsOverlay = (props) => {
  const { type = 'none', showButton = false } = props;
  const { t } = useLocale();

  const content = {
    none: {
      header: t('common.noData'),
      subHeader: '',
      cta: {
        to: '/',
        text: t('common.backToHome'),
      },
    },
    user: {
      header: t('users.noUsers'),
      subHeader: t('users.clickBelow'),
      cta: {
        to: `/${PATHS.invite}`,
        text: t('users.inviteMembers'),
      },
    },
    topic: {
      header: t('topics.noTopics'),
      subHeader: t('topics.clickBelow'),
      cta: {
        to: `/${PATHS.addTopic}`,
        text: t('topics.addTopic'),
      },
    },
    tag: {
      header: t('tags.noTags'),
      subHeader: t('tags.clickBelow'),
      cta: {
        to: `/${PATHS.addTag}`,
        text: t('tags.addTag'),
      },
    },
    interest: {
      header: t('interests.noInterest'),
      subHeader: t('interests.clickBelow'),
      cta: {
        to: `/${PATHS.addInterest}`,
        text: t('interests.addInterest'),
      },
    },
    certificate: {
      header: t('certificates.noCertificates'),
      subHeader: t('certificates.clickBelow'),
      cta: {
        to: `/${PATHS.addCertificate}`,
        text: t('certificates.addCertificate'),
      },
    },
    provider: {
      header: t('providers.noProviders'),
      subHeader: t('providers.clickBelow'),
      cta: {
        to: `/${PATHS.addProvider}`,
        text: t('providers.addProvider'),
      },
    },
    courseAttendance: {
      header: t('courses.placeholder.noCourseAttendance'),
    },
  };

  return (
    <GridOverlay sx={{ pointerEvents: 'auto' }}>
      <Stack
        spacing={4}
        sx={{
          height: 1,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box>
          <img
            src={noRowsPlaceholder}
            alt="no-rows-placeholder"
            height="53"
            width="173"
          />
        </Box>
        <Box>
          <Typography variant="bodyStandardBold">
            {content[type].header}
          </Typography>
        </Box>
        {showButton
          && (
            <>
              <Box>
                <Typography variant="bodyStandardRegular">
                  {content[type].subHeader}
                </Typography>
              </Box>
              <Box>
                <Button
                  variant="contained"
                  component={Link}
                  to={content[type].cta?.to}
                >
                  {content[type].cta.text}
                </Button>
              </Box>
            </>
          )}
      </Stack>
    </GridOverlay>
  );
};

export default CustomNoRowsOverlay;
