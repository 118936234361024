import { Box, Divider, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { safcspColoredLogo } from 'assets/images';
import { useLocale } from 'util/hooks';

const About = () => {
  const { t } = useLocale();
  const mediumScreen = useMediaQuery(({ breakpoints }) => breakpoints.down('md'));

  const content = (
    <>
      <Typography
        variant="h5"
        sx={{
          mb: 5,
          textAlign: 'left',
        }}
      >
        {t('landing.aboutSection.about')}
      </Typography>
      <Typography variant="bodyMediumRegular">
        {t('landing.aboutSection.aboutDesc')}
      </Typography>
    </>
  );

  const providedBy = (
    <>
      <img
        src={safcspColoredLogo}
        alt="safcsp-logo"
        width={203}
        height={62}
      />
      <Typography
        variant="bodySmallRegular"
      >
        {`${t('landing.aboutSection.providedBy')}: SAFCSP`}
      </Typography>
    </>
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: {
          md: 'row',
          xs: 'column',
        },
        alignItems: 'center',
        gap: { xs: 5, md: 12, lg: 22 },
        py: { xs: 12, md: 0 },
        minHeight: { xs: 417, md: 320 },
      }}
    >
      <Box>
        {content}
      </Box>
      <Divider
        orientation={mediumScreen ? 'horizontal' : 'vertical'}
        sx={{
          borderColor: 'common.gadgetGray',
          height: { md: '150px', xs: '20px' },
          width: { md: '20px', sm: '400px', xs: '280px' },
          my: { xs: 5, md: 0 },
        }}
      />
      <Box sx={{
        display: 'grid',
        textAlign: 'center',
        rowGap: 5,
      }}
      >
        {providedBy}
      </Box>
    </Box>
  );
};

export default About;
