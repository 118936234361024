import {
  spire,
  splunk,
  trendMicro,
  kaspersky,
  lookout,
  qualys,
  sans,
  cisco,
  paloaltoNetworks,
  comptia,
  tcc,
} from 'assets/images';

export const goldenPartnersLogos = [
  {
    key: 'spire',
    img: spire,
    alt: 'spire-logo',
  },
];

export const trainingPartnersLogos = [
  {
    key: 'splunk',
    img: splunk,
    alt: 'splunko-logo',
  },
  {
    key: 'trendMicro',
    img: trendMicro,
    alt: 'trendMicro-logo',
  },
  {
    key: 'kaspersky',
    img: kaspersky,
    alt: 'kaspersky-logo',
  },
  {
    key: 'lookout',
    img: lookout,
    alt: 'lookout-logo',
  },
  {
    key: 'qualys',
    img: qualys,
    alt: 'qualys-logo',
  },
  {
    key: 'sans',
    img: sans,
    alt: 'sans-logo',
  },
  {
    key: 'cisco',
    img: cisco,
    alt: 'cisco-logo',
  },
  {
    key: 'paloaltoNetworks',
    img: paloaltoNetworks,
    alt: 'paloaltoNetworks-logo',
  },
  {
    key: 'comptia',
    img: comptia,
    alt: 'comptia-logo',
  },
  {
    key: 'tcc',
    img: tcc,
    alt: 'tcc-logo',
  },
];
