import { useParams } from 'react-router-dom';
import { getClubProfilePostStats } from 'services';
import useCustomQuery from 'reactQuery/useCustomQuery';

const useClubProfilePostStats = (queryProps = {}) => {
  const { clubId } = useParams();

  const query = useCustomQuery({
    queryKey: [`club-profile-post-stats${clubId}`],
    queryFn: () => getClubProfilePostStats(clubId),
    ...queryProps,
  });

  return { ...query };
};

export default useClubProfilePostStats;
