import React from 'react';
import { useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/styles';

import { useLocale } from 'util/hooks';
import { POST_TYPES } from 'assets/constants/posts';
import {
  OpenEyeIcon,
  VideoPostIcon,
  ArticlePostIcon,
  KnowledgePostIcon,
  PointsWithShadowIcon,
} from 'assets/icons';

import {
  useMostViewedPosts,
  useProfilePostStats,
  useClubProfilePostStats,
  useClubProfileMostViewedPosts,
} from 'reactQuery/queries';

import { SuspenseWrapper } from 'components/render';
import EmptyPostsPlaceholder from './sections/EmptyPostsPlaceholder';
import ProfilePostCard from './helpers/ProfilePostCard';

const postCategories = [
  {
    id: POST_TYPES.article,
    labelKey: 'common.article',
    icon: ArticlePostIcon,
  },
  {
    id: POST_TYPES.video,
    labelKey: 'common.video',
    icon: VideoPostIcon,
  },
  {
    id: POST_TYPES.knowledgeShare,
    labelKey: 'common.knowledgeShare',
    icon: KnowledgePostIcon,
  },
];

const ProfilePostStats = () => {
  const theme = useTheme();
  const { t } = useLocale();
  const { username, clubId } = useParams();

  const {
    data: clubPostStats = {},
  } = useClubProfilePostStats({
    options: {
      enabled: !!clubId,
    },
  });

  const {
    data: studentPostStats = {},
  } = useProfilePostStats({
    options: {
      enabled: !!username,
    },
  });

  const stats = username ? studentPostStats : clubPostStats;
  const posts = [];
  const totalPosts = posts?.length || 0;

  const header = React.useMemo(() => (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    }}
    >
      <Box>
        <Typography variant="h6">
          {t('common.posts')}
        </Typography>
      </Box>
      {!username
      && (
      <Box>
        <Typography variant="bodySmallRegular">
          {t('profile.postCount.posts', { count: stats?.postsCount || 0 })}
        </Typography>
      </Box>
      )}
      {totalPosts
        ? (
          <Box>
            <Typography
              variant="bodySmallRegular"
              color="common.darkGray"
            >
              {`${totalPosts} ${t('common.postsNoThe')}`}
            </Typography>
          </Box>
        ) : null}
    </Box>
  ), [t, totalPosts, stats?.postsCount, username]);

  const statsPerCategory = React.useMemo(() => (
    <Stack
      direction="row"
      flexWrap="wrap"
      rowGap={2}
      columnGap={2}
    >
      {postCategories.map((postCategory) => (
        <Card
          key={postCategory.id}
          sx={{
            p: 3.5,
            flex: 1,
            minWidth: 200,
            minHeight: 130,
            border: ({ palette }) => `1px solid ${palette.common.silverGrey}`,
          }}
        >
          <Stack
            spacing={2}
            direction="row"
            alignItems="center"
          >
            <postCategory.icon
              width="55"
              height="55"
            />
            <Box sx={{
              mt: 2.5,
              mx: 'auto',
            }}
            >
              <Typography variant="bodyLargeMedium">
                {stats[postCategory.id] || 0}
              </Typography>
            </Box>
            <Typography
              variant="bodyStandardRegular"
              color="common.couchGrey"
            >
              {t(postCategory.labelKey)}
            </Typography>
          </Stack>
        </Card>
      ))}
    </Stack>
  ), [stats, t]);

  const totalStats = React.useMemo(() => (
    <Card
      sx={{
        p: 3.5,
        minHeight: 72,
        border: ({ palette }) => `1px solid ${palette.common.silverGrey}`,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Stack alignItems="center" mx="auto">
          <Typography variant="bodyStandardMedium">
            {t('profile.totalEarnedPoints')}
          </Typography>
          <Stack direction="row" spacing={2} alignItems="center">
            <PointsWithShadowIcon
              width="20"
              height="20"
              innerFill={theme.palette.common.vividOrange}
              rectFill={theme.palette.common.vividOrange}
            />
            <Typography variant="bodyStandardRegular">
              {stats?.totalPostPoints || 0}
            </Typography>
          </Stack>
        </Stack>
        <Divider
          orientation="vertical"
          sx={{ height: 15, mx: 2 }}
        />
        <Stack alignItems="center" mx="auto">
          <Typography variant="bodyStandardMedium">
            {t('profile.totalViews')}
          </Typography>
          <Stack direction="row" spacing={2} alignItems="center">
            <OpenEyeIcon width="20" height="20" />
            <Typography variant="bodyStandardRegular">
              {stats?.totalPostViews || 0}
            </Typography>
          </Stack>
        </Stack>
      </Box>
    </Card>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [stats?.totalPostPoints, stats?.totalPostViews, t]);

  return (
    <Stack spacing={5}>
      {header}
      {statsPerCategory}
      {totalStats}
    </Stack>
  );
};

const ProfileMostViewedPosts = () => {
  const { t } = useLocale();
  const { username, clubId } = useParams();

  const {
    data: topClubPosts = [],
    isFetched: isClubPostsFetched = false,
  } = useClubProfileMostViewedPosts({
    options: {
      enabled: !!clubId,
    },
  });

  const {
    data: topStudentPosts = [],
    isFetched: isStudentPostsFetched = false,
  } = useMostViewedPosts({
    options: {
      enabled: !!username,
    },
  });

  const topPosts = username ? topStudentPosts : topClubPosts;
  const isPostsFetched = username ? isStudentPostsFetched : isClubPostsFetched;

  const mostViewedPosts = React.useMemo(() => (
    <Stack spacing={2}>
      <Typography variant="bodySmallMedium">
        {t('profile.mostViewedPosts')}
      </Typography>
      <Stack
        direction="row"
        spacing={{ xs: 2.5, sm: 5 }}
        sx={{
          p: 6,
          width: 1,
          overflowX: 'scroll',
        }}
      >
        {topPosts?.map((post) => (
          <Box key={post.id} flex={1}>
            <ProfilePostCard post={post} />
          </Box>
        ))}
      </Stack>
    </Stack>
  ), [t, topPosts]);

  if (isPostsFetched && !topPosts?.length) {
    return <EmptyPostsPlaceholder />;
  }
  return mostViewedPosts;
};

const ProfilePosts = () => (
  <Card sx={{ p: 5, borderRadius: 0 }}>
    <Stack spacing={5}>
      <SuspenseWrapper>
        <ProfilePostStats />
      </SuspenseWrapper>
      <SuspenseWrapper>
        <ProfileMostViewedPosts />
      </SuspenseWrapper>
    </Stack>
  </Card>
);

export default ProfilePosts;
