import { useEffect, useState } from 'react';

import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useRecoilState } from 'recoil';
import { useDropzone } from 'react-dropzone';
import { useController } from 'react-hook-form';

import {
  Box,
  Avatar,
  CardMedia,
  IconButton,
  Typography,
  CircularProgress,
} from '@mui/material';

import { generatePresignedUrl, uploadFileToS3 } from 'services';
import { CancelIcon, FilledCheckIcon, UploadIcon } from 'assets/icons';
import { useLocale, useSnackbar } from 'util/hooks';
import MEDIA_TYPES from 'assets/constants/media';
import { MediaInfoAtom } from 'recoil/atoms';

const FormUploadMedia = (props) => {
  const {
    src,
    username,
    disabled = false,
    setError, // Need to pass manually, ex, setError={(msg) => setError('files', msg)}
    type,
    helperText = '',
    ...rest
  } = props;

  const { field, fieldState } = useController(rest);
  const { onChange } = field;
  const { error: fieldError } = fieldState;

  const { t } = useLocale();
  const snack = useSnackbar();

  const [mediaInfo, setMediaInfo] = useRecoilState(MediaInfoAtom);
  const [currentMedia, setCurrentMedia] = useState(src);
  const [isUploadLoading, setIsUploadLoading] = useState(false);

  const fileExtension = mediaInfo.name.split('.').pop();
  const isTypeImage = type === MEDIA_TYPES.image;
  const isTypeVideo = type === MEDIA_TYPES.video;
  const isTypeAttachment = type === MEDIA_TYPES.attachment;

  const hintText = helperText || (
    isTypeVideo
      ? t('posts.createArticle.uploadVideo')
      : t('posts.createArticle.uploadImage')
  );

  useEffect(() => () => {
    // Make sure to revoke the data uris to avoid memory leaks
    URL.revokeObjectURL(currentMedia?.preview);
  }, [currentMedia]);

  const uploadToS3 = async (newImage) => {
    setIsUploadLoading(true);
    let result = null;

    try {
      // Step 1 - get url and fileName
      const { uploadUrl, fileName } = await generatePresignedUrl();

      // Step 2 - upload to S3
      await uploadFileToS3(uploadUrl, newImage);

      // Step 3 - update form with fileName
      onChange(fileName);
      result = fileName;
    } catch (error) {
      setError({
        type: 'api',
        message: error.message,
      });
      snack({
        message: error.message || t('common.somethingWrong'),
        severity: 'error',
      });
      setIsUploadLoading(false);
      return false;
    }

    setIsUploadLoading(false);
    return result;
  };

  const deleteImage = () => {
    setCurrentMedia(null);
    onChange(null);
  };

  // ========= Dropzone =========
  const onDrop = async (acceptedFile, fileRejections) => {
    if (fileRejections?.[0]) {
      setError({
        type: 'dropzone',
        message: fileRejections?.[0]?.errors?.[0]?.message,
      });
    }
    const newImage = Object.assign(acceptedFile[0], {
      preview: URL.createObjectURL(acceptedFile[0]),
    });

    // returns boolean in case of video/image,
    // returns string (fileName) in case of attachment
    const uploadSuccessful = await uploadToS3(newImage);

    if (uploadSuccessful) {
      setCurrentMedia(newImage?.preview);
      setMediaInfo({
        name: acceptedFile[0]?.name,
        size: acceptedFile[0]?.size,
        image: currentMedia,
      });

      if (isTypeAttachment) {
        onChange({
          fileName: uploadSuccessful,
          preview: newImage.preview,
          fileSize: acceptedFile[0]?.size,
          fileOriginalName: acceptedFile[0]?.name,
          fileType: acceptedFile[0]?.type,
        });
      }
    }

    return undefined;
  };

  const handleFileFormat = () => {
    let format = {};

    if (isTypeVideo) {
      format = { 'video/*': [] };
    }
    if (isTypeImage) {
      format = { 'image/*': ['.jpeg', '.jpg', '.png'] };
    }

    return format;
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: handleFileFormat(),
    multiple: false,
    onDrop,
  });

  const cardWithImage = (
    <Box
      sx={{
        py: 6,
        px: 4,
        borderRadius: 2,
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'common.ghostlyGrey',
        justifyContent: 'space-between',
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <IconButton
          disableRipple
          onClick={deleteImage}
          aria-label="delete"
          disabled={disabled}
          sx={{ p: 0 }}
        >
          <CancelIcon />
        </IconButton>
        {isTypeVideo
          ? (
            <CardMedia
              component="video"
              src={currentMedia}
              height="40"
              controls
              sx={{ borderRadius: 1 }}
            />
          )
          : (
            <Avatar
              variant="square"
              alt="file-image"
              src={currentMedia}
              sx={{
                borderRadius: 1,
                width: 70,
                height: 50,
                mr: 0,
                ml: {
                  xs: 1,
                  sm: 3,
                },
              }}
            />
          )}
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          pl: 2,
        }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
            >
              <Typography variant="bodySmallMedium">
                {`${mediaInfo.name.slice(0, 10).concat('..')}${fileExtension}`}
              </Typography>
            </Box>
            <Typography variant="bodyMicroRegular">
              {`${mediaInfo.size} Bytes`}
            </Typography>
          </Box>
        </Box>
      </Box>
      <FilledCheckIcon />
    </Box>
  );

  const cardWithoutImage = (
    <Box sx={(theme) => ({
      border: `1px solid ${fieldError
        ? theme.palette.common.error
        : theme.palette.common.silverGrey}`,
      borderRadius: 2,
    })}
    >
      <Box {...getRootProps()}>
        <input
          {...getInputProps()}
        />
        <Box
          sx={(theme) => ({
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: 'common.white',
            borderRadius: '15px 15px 0 0',
            borderBottom: `1px solid ${theme.palette.common.silverGrey}`,
            p: 5,
          })}
        >
          <IconButton
            disableRipple
            sx={{ mx: 2 }}
            aria-label="upload"
            disabled={disabled}
          >
            {isUploadLoading ? <CircularProgress /> : <UploadIcon />}
          </IconButton>
        </Box>
        <Typography
          variant="bodySmallRegular"
          sx={{
            color: 'text.secondary',
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
            my: 2,
            p: 2,
          }}
        >
          {hintText}
        </Typography>
      </Box>
    </Box>
  );

  return isEmpty(currentMedia)
    ? cardWithoutImage
    : cardWithImage;
};

FormUploadMedia.propTypes = {
  src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  username: PropTypes.string,
  disabled: PropTypes.bool,
  setError: PropTypes.func,
  setUpdateError: PropTypes.func,
  type: PropTypes.string,
};

FormUploadMedia.defaultProps = {
  src: null,
  username: '',
  disabled: false,
  setError: () => { },
  setUpdateError: () => { },
  type: '',
};

export default FormUploadMedia;
