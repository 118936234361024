const HomeIconFilled = (props) => {
  const {
    fill = 'none',
    innerFill = 'black',
  } = props;

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M20 7.91678C19.9999 7.78945 19.9707 7.66382 19.9145 7.54956C19.8583 7.43529 19.7766 7.33542 19.6758 7.25762L10.5092 0.174285C10.3632 0.0613026 10.1838 0 9.99917 0C9.81455 0 9.63517 0.0613026 9.48917 0.174285L0.3225 7.25762C0.222003 7.33559 0.140688 7.43553 0.0847876 7.54979C0.0288869 7.66405 -0.000116261 7.78959 3.5025e-07 7.91678V19.1668C3.5025e-07 19.3878 0.0877977 19.5998 0.244078 19.756C0.400358 19.9123 0.61232 20.0001 0.833334 20.0001H7.5C7.61051 20.0001 7.71649 19.9562 7.79463 19.8781C7.87277 19.7999 7.91667 19.694 7.91667 19.5835V15.8335C7.91667 15.2809 8.13616 14.751 8.52686 14.3603C8.91756 13.9696 9.44746 13.7501 10 13.7501C10.5525 13.7501 11.0824 13.9696 11.4731 14.3603C11.8638 14.751 12.0833 15.2809 12.0833 15.8335V19.5835C12.0833 19.694 12.1272 19.7999 12.2054 19.8781C12.2835 19.9562 12.3895 20.0001 12.5 20.0001H19.1667C19.3877 20.0001 19.5996 19.9123 19.7559 19.756C19.9122 19.5998 20 19.3878 20 19.1668V7.91678Z" fill={innerFill} />
    </svg>
  );
};

export default HomeIconFilled;
