import { getGeneralSettings } from 'services';
import { useCustomQuery } from 'reactQuery';

const useGeneralSettings = (queryProps = {}) => {
  const query = useCustomQuery({
    queryKey: ['general-settings'],
    queryFn: getGeneralSettings,
    ...queryProps,
  });

  return { ...query };
};

export default useGeneralSettings;
