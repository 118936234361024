import { useParams } from 'react-router-dom';

import { getProfilePersonalInfo } from 'services';
import useCustomQuery from 'reactQuery/useCustomQuery';

const usePersonalInfo = (queryProps = {}) => {
  const { username } = useParams();

  const query = useCustomQuery({
    queryKey: [`personal-info-${username}`],
    queryFn: () => getProfilePersonalInfo(username),
    ...queryProps,
  });

  return { ...query };
};

export default usePersonalInfo;
