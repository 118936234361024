import { forwardRef } from 'react';
import { Link as RRLink } from 'react-router-dom';

// // https://mui.com/guides/routing/#global-theme-link
const Link = forwardRef((props, ref) => {
  const { href, ...rest } = props;

  return <RRLink ref={ref} to={href} {...rest} />;
});

export default Link;
