import { useTheme } from '@mui/styles';

const TextOutlinedIcon = (props) => {
  const { palette } = useTheme();

  const {
    fill = palette.common.darkGray,
    ...rest
  } = props;

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g clipPath="url(#clip0_772_36370)">
        <path d="M2 16C1.17267 16 0.5 15.3273 0.5 14.5V1.5C0.5 0.672667 1.17267 0 2 0H12.03C12.4247 0 12.796 0.151333 13.078 0.426L15.0473 2.348C15.3347 2.62867 15.5 3.02 15.5 3.422V14.5C15.5 15.3273 14.8273 16 14 16H2ZM2 1C1.724 1 1.5 1.224 1.5 1.5V14.5C1.5 14.776 1.724 15 2 15H14C14.276 15 14.5 14.776 14.5 14.5V3.422C14.5 3.288 14.4447 3.15733 14.3487 3.064L12.3793 1.142C12.2853 1.05067 12.162 1 12.0307 1H2Z" fill={fill} />
        <path d="M4.03516 6C3.75916 6 3.53516 5.776 3.53516 5.5C3.53516 5.224 3.75916 5 4.03516 5H12.0352C12.3112 5 12.5352 5.224 12.5352 5.5C12.5352 5.776 12.3105 6 12.0352 6H4.03516Z" fill={fill} />
        <path d="M4.03516 9C3.75916 9 3.53516 8.776 3.53516 8.5C3.53516 8.224 3.75916 8 4.03516 8H12.0352C12.3112 8 12.5352 8.224 12.5352 8.5C12.5352 8.776 12.3112 9 12.0352 9H4.03516Z" fill={fill} />
        <path d="M4.03516 12C3.75916 12 3.53516 11.776 3.53516 11.5C3.53516 11.224 3.75916 11 4.03516 11H8.03516C8.31049 11 8.53516 11.224 8.53516 11.5C8.53516 11.776 8.31049 12 8.03516 12H4.03516Z" fill={fill} />
      </g>
      <defs>
        <clipPath id="clip0_772_36370">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default TextOutlinedIcon;
