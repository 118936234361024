import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Card,
  Button,
  Typography,
} from '@mui/material';

import { PATHS } from 'routes';
import { useLocale } from 'util/hooks';

const JoinUsModal = (props) => {
  const {
    handleClose = () => { },
  } = props;

  const navigate = useNavigate();
  const { t } = useLocale();

  return (
    <Card sx={{
      bgcolor: 'background.default',
      maxWidth: 500,
      m: 3,
      p: {
        xs: 8,
        sm: 11,
      },
    }}
    >
      <Box sx={{
        display: 'flex',
        flexFlow: 'column wrap',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      >
        <Typography id="modal-title" variant="bodyLargeBold">
          {t('posts.joinUsCard.header')}
        </Typography>
        <Box sx={{
          mt: 12,
          textAlign: 'center',
        }}
        >
          <Typography id="modal-description" variant="bodyMediumRegular">
            {t('posts.joinUsCard.subHeader')}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: 12,
          }}
        >
          <Button
            onClick={handleClose}
            variant="outlined"
            color="secondary"
            size="regular"
            sx={{
              mr: 5,
            }}
          >
            {t('common.cancel')}
          </Button>
          <LoadingButton
            size="regular"
            variant="contained"
            onClick={() => {
              handleClose();
              navigate(`/${PATHS.register}`);
            }}
          >
            {t('posts.joinUsCard.buttonLabel')}
          </LoadingButton>
        </Box>
      </Box>
    </Card>
  );
};

export default JoinUsModal;
