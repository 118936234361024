import { useRecoilValue } from 'recoil';
import { snackbarAtom } from 'recoil/atoms';
import { Snackbar } from 'components/molecules';

const Toast = () => {
  const snackbarState = useRecoilValue(snackbarAtom);

  return snackbarState?.open ? <Snackbar {...snackbarState} /> : null;
};

export default Toast;
