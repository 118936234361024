import END_POINTS from 'services/endPoints';
import api from 'services/api';
import METHODS from 'services/methods';

const updateCourse = (data, id) => api(
  END_POINTS.course(id),
  {
    method: METHODS.PUT,
    data,
  },
)
  .then((response) => Promise.resolve(response))
  .catch((error) => Promise.reject(error));

export default updateCourse;
