import { useParams } from 'react-router-dom';
import { useCustomQuery } from 'reactQuery';
import { getPostDetails } from 'services';

const usePostDetails = () => {
  const { postId } = useParams();

  const query = useCustomQuery({
    queryKey: [`post-details-${postId}`],
    queryFn: () => getPostDetails(postId),
  });

  return { ...query };
};

export default usePostDetails;
