import { getAllTags } from 'services';
import { useCustomQuery } from 'reactQuery';

const useTags = () => {
  const query = useCustomQuery({
    queryKey: ['tags'],
    queryFn: getAllTags,
  });

  return { ...query };
};

export default useTags;
