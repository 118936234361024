import { Autocomplete, TextField } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { tz } from 'moment-timezone';
import PropTypes from 'prop-types';

const TimezoneSelect = (props) => {
  const {
    autoCompleteProps,
    textFieldProps,
  } = props;

  const timeZones = tz.names();
  const userTimezone = tz.guess();

  const isOptionEqualToValue = (option, value) => {
    if (value === '') return true; // Prevent onClear warning
    return option === value;
  };

  return (
    <Autocomplete
      id={autoCompleteProps.name}
      fullWidth
      popupIcon={<ExpandMore />}
      options={timeZones}
      isOptionEqualToValue={isOptionEqualToValue}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={textFieldProps.placeholder || userTimezone}
          {...textFieldProps}
        />
      )}
      {...autoCompleteProps}
    />
  );
};

TimezoneSelect.propTypes = {
  autoCompleteProps: PropTypes.oneOfType([PropTypes.object]),
  textFieldProps: PropTypes.oneOfType([PropTypes.object]),
};

TimezoneSelect.defaultProps = {
  autoCompleteProps: {},
  textFieldProps: {},
};

export default TimezoneSelect;
