import React from 'react';

const useShare = (props) => {
  const {
    title = '',
    text = '',
    url = window.location.href,
  } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpenMenu = React.useCallback((event) => {
    if (navigator.share) {
      const shareData = {
        title,
        text,
        url,
      };
      try {
        navigator.share(shareData);
      } catch (error) {
        setAnchorEl(event.currentTarget);
      }
    } else {
      setAnchorEl(event.currentTarget);
    }
  }, [title, text, url]);

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return {
    anchorEl,
    handleOpenMenu,
    handleCloseMenu,
  };
};

export default useShare;
