import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { FlagWithCircleIcon } from 'assets/icons';
import { useLocale } from 'util/hooks';

const EmptyCTFsPlaceholder = () => {
  const { t } = useLocale();

  return (
    <Stack
      spacing={8}
      alignItems="center"
      justifyContent="center"
    >
      <FlagWithCircleIcon
        width="105"
        height="102"
      />
      <Typography variant="bodyStandardMedium">
        {t('ctfs.noCTFs')}
      </Typography>
    </Stack>
  );
};

export default EmptyCTFsPlaceholder;
