import END_POINTS from '../../endPoints';
import api from '../../api';

const getProviders = (options) => api(
  END_POINTS.providers,
  options,
)
  .then((response) => Promise.resolve(response))
  .catch((error) => Promise.reject(error));

export default getProviders;
