import { useCallback, useMemo, useState } from 'react';
import {
  Typography,
  Box,
  Alert,
} from '@mui/material';
import * as Yup from 'yup';
import { FormPasswordField } from 'components/form';
import { useNavigate, useParams } from 'react-router-dom';
import { resetPasswordService } from 'services';
import { LoadingButton } from '@mui/lab';
import {
  useFastForm, useLocale, useRecaptcha, useSnackbar,
} from 'util/hooks';
import { PATHS } from 'routes';
import { AUTHENTICATION_SNACKBAR_POSITION } from 'assets/constants/snackbar';

const ResetPasswordForm = (props) => {
  const {
    onSubmitSuccess = () => { },
  } = props;

  const navigate = useNavigate();
  const { token } = useParams();
  const snack = useSnackbar();
  const { t } = useLocale();
  const { executeRecaptcha } = useRecaptcha({ action: 'RESET_PASSWORD' });
  const [resetPasswordError, setResetPasswordError] = useState(false);

  const onSubmit = useCallback(async (values) => {
    const valuesToSubmit = { ...values, token };
    try {
      const recaptchaToken = await executeRecaptcha();
      await resetPasswordService(valuesToSubmit, { recaptchaToken });
      snack({
        message: t('password.passwordResetMsg'),
        severity: 'success',
        snackbarProps: AUTHENTICATION_SNACKBAR_POSITION,
      });
      navigate(`/${PATHS.login}`, { replace: true });
      onSubmitSuccess();
    } catch (error) {
      const { errors } = error;
      setResetPasswordError(errors?.[0]?.message || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const defaultValues = {
    password: '',
  };

  const validationSchema = useMemo(() => (Yup.object({
    password: Yup.string().required(t('password.enterPassword')),
  })), [t]);

  const {
    control,
    handleSubmit,
    formState: {
      isSubmitting,
      isValid,
    },
  } = useFastForm({
    defaultValues,
    validationSchema,
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        py: 6,
        px: {
          sm: 20,
          xs: 6,
        },
      }}
      >
        <Typography
          sx={{
            marginBottom: 21,
          }}
          variant="h4"
        >
          {t('password.resetPassword')}
        </Typography>
        {resetPasswordError && (
          <Alert
            sx={{ width: 1, mb: 7 }}
            severity="error"
          >
            {resetPasswordError}
          </Alert>
        )}
        <Box sx={{ width: 1, mb: 7 }}>
          <FormPasswordField
            name="password"
            control={control}
            autoComplete="new-password"
            label={t('common.password')}
            fullWidth
          />
        </Box>
        <LoadingButton
          sx={{
            display: 'flex',
            alignSelf: 'flex-start',
          }}
          type="submit"
          variant="contained"
          size="fixed"
          loading={isSubmitting}
          disabled={isSubmitting || !isValid}
        >
          {t('password.reset')}
        </LoadingButton>
      </Box>
    </form>
  );
};

export default ResetPasswordForm;
