import END_POINTS from '../endPoints';
import api from '../api';

const getMyPosts = (options) => api(
  END_POINTS.privatePosts,
  options,
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default getMyPosts;
