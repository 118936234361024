import * as React from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  isEqual,
} from 'lodash';
import {
  MenuItem,
  Checkbox,
  ListItemText,
  Menu,
  ToggleButton,
  Chip,
  Stack,
  Box,
  Typography,
  Radio,
  Button,
  Divider,
} from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';
import { useDebounce, useLocale } from 'util/hooks';
import SuspenseLoader from 'components/render/SuspenseWrapper/SuspenseLoader';
import { capitalizeFirstLetter } from 'util/helpers';
import SearchField from '../SearchField/SearchField';

const DEFAULT_WIDTH = 'fit-content';

const toggleCheckbox = (arr, value) => {
  const index = arr.indexOf(value);
  if (index === -1) {
    return [...arr, value];
  }
  arr.splice(index, 1);
  return arr;
};
const toggleRadio = (arr, value) => {
  const index = arr.indexOf(value);
  if (index === -1) {
    return [value];
  }
  return [];
};

const FilterButton = (props) => {
  const {
    async = false,
    search = false,
    filterKey = '',
    labelKey = '',
    optionLabelKey = '', // used to render option label
    optionKey = 'name', // used to handle click of item, id each option uniquely
    items = [],
    getItems = () => { },
    buttonWidth = DEFAULT_WIDTH,
    type = 'checkbox',
  } = props;

  const typeRadio = type === 'radio';

  const { t, isAr } = useLocale();
  const [, startTransition] = React.useTransition();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  // menu search
  const [searchTerm, setSearchTerm] = React.useState('');
  const debouncedSearch = useDebounce({ value: searchTerm });

  const urlValue = searchParams.get(`filter.${filterKey}`);

  // eslint-disable-next-line no-nested-ternary
  const initialSelected = urlValue?.length
    ? (typeRadio
      ? [urlValue.split(',')[0]] // radio type can only have one value
      : urlValue.split(','))
    : [];

  const [selectedItems, setSelectedItems] = React.useState(initialSelected);
  const hasSelection = !!selectedItems?.length;
  const hasSearchParams = !!searchParams?.toString()?.length;

  const query = getItems({
    withUrlParams: false,
    options: {
      suspense: false,
      enabled: async,
      refetchOnWindowFocus: false,
    },
    queryOptions: {
      ...(debouncedSearch && { search: debouncedSearch }),
    },
  });

  const options = async
    ? query?.data
    : items;

  React.useEffect(() => {
    // Handle when url is updated by other component
    if (!isEqual(initialSelected, selectedItems)) {
      setSelectedItems(initialSelected);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialSelected]);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    // if (search) {
    //   setSearchTerm('');
    // }
  };

  const handleChange = (newValue) => {
    const newArr = typeRadio
      ? toggleRadio(selectedItems, newValue)
      : toggleCheckbox(selectedItems, newValue);
    setSelectedItems(newArr);

    startTransition(() => {
      if (newArr?.length) {
        searchParams.set(`filter.${filterKey}`, newArr.join(','));
      } else {
        searchParams.delete(`filter.${filterKey}`);
      }
      setSearchParams(searchParams, { replace: hasSearchParams });
    });
  };

  const handleClear = () => {
    searchParams.delete(`filter.${filterKey}`);
    setSearchParams(searchParams, { replace: hasSearchParams });
  };

  const handleSearch = (newValue) => {
    setSearchTerm(newValue);
  };

  const renderButtonLabel = () => {
    if (async && query?.isLoading) {
      return <SuspenseLoader size={20} />;
    }

    const { length } = selectedItems;

    const label = t(labelKey);

    if (!length) {
      return (
        <Typography variant="bodySmallRegular">
          {label}
        </Typography>
      );
    }

    return (
      <Stack direction="row" spacing={1} alignItems="center">
        <Box>
          <Typography variant="bodySmallRegular">
            {label}
          </Typography>
        </Box>
        <Chip
          size="small"
          color="primary"
          label={length}
        />
      </Stack>
    );
  };

  return (
    <>
      <ToggleButton
        size="small"
        color="primary"
        value={filterKey}
        selected={!!selectedItems.length}
        onChange={handleOpenMenu}
        sx={{
          width: buttonWidth,
          py: 2,
          px: 4,
          gap: 2, // gap between icon and label
          border: 0,
          textTransform: 'none',
          backgroundColor: 'background.paper',
          justifyContent: 'space-between',
        }}
      >
        {renderButtonLabel()}
        <KeyboardArrowDown
          sx={{
            stroke: 'none',
          }}
        />
      </ToggleButton>
      <Menu
        id={`${filterKey}-menu`}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        anchorEl={anchorEl}
        keepMounted
      >
        {search && (
          <SearchField
            value={searchTerm}
            onClear={handleSearch}
            onChange={handleSearch}
            onKeyDown={(e) => e.stopPropagation()}
            placeholder={t('common.search')}
            sx={{ p: 4 }}
            inputRef={(inputRef) => inputRef && inputRef.focus()}
            InputProps={{
              endAdornment: query?.isFetching
                ? <SuspenseLoader size={20} />
                : null,
            }}
          />
        )}
        {(async && query?.isLoading)
          ? <SuspenseLoader size={20} />
          : options?.map((option) => {
            const {
              id: optionId,
              itemLabelKey = '',
            } = option;

            const key = String(option[optionKey]); // url deals with strings only
            const checked = selectedItems.indexOf(key) > -1;
            const resolvedLabel = itemLabelKey
              ? t(itemLabelKey)
              : option[optionLabelKey] || (isAr ? option.nameAr : option.nameEn) || option.name;

            return (
              <MenuItem
                sx={{ py: 0 }}
                key={optionId || key}
                value={key}
                onClick={() => handleChange(key)}
              >
                {typeRadio
                  ? <Radio checked={checked} />
                  : <Checkbox checked={checked} />}
                <ListItemText primary={capitalizeFirstLetter(resolvedLabel)} />
              </MenuItem>
            );
          })}
        <Divider sx={{ py: 2 }} />
        <Button
          onClick={handleClear}
          variant="linkButton"
          disabled={!hasSelection}
          fullWidth
        >
          {t('common.clearFilter')}
        </Button>
      </Menu>
    </>
  );
};

export default React.memo(FilterButton);
