import { useTheme } from '@mui/styles';

const LiveCourseIcon = (props) => {
  const { palette } = useTheme();

  const {
    fill = palette.common.pinkishRed,
    ...rest
  } = props;

  return (
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <rect opacity="0.2" width="60" height="60" rx="13.5938" fill={fill} />
      <rect x="5" y="5" width="50" height="50" rx="11.3281" fill={fill} />
      <g clipPath="url(#clip0_705_37330)">
        <path d="M30 41.9971C23.3834 41.9971 18 36.6136 18 29.9971C18 23.3805 23.3834 17.9971 30 17.9971C36.6166 17.9971 42 23.3805 42 29.9971C42 36.6136 36.6166 41.9971 30 41.9971ZM30 18.5596C23.6926 18.5596 18.5625 23.6897 18.5625 29.9971C18.5625 36.3045 23.6926 41.4346 30 41.4346C36.3074 41.4346 41.4375 36.3045 41.4375 29.9971C41.4375 23.6897 36.3074 18.5596 30 18.5596Z" stroke="white" />
        <circle cx="30" cy="30" r="6" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_705_37330">
          <rect width="25" height="25" fill="white" transform="translate(17.5 17.5)" />
        </clipPath>
      </defs>
    </svg>

  );
};

export default LiveCourseIcon;
