import END_POINTS from '../endPoints';
import METHODS from '../methods';
import api from '../api';

const deleteUserCertificate = (id) => api(
  END_POINTS.userCertificate(id),
  {
    method: METHODS.DELETE,
  },
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default deleteUserCertificate;
