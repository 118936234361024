import { useTheme } from '@mui/styles';

const MenuIcon = (props) => {
  const { palette } = useTheme();

  const {
    stroke = palette.common.couchGrey,
  } = props;

  return (
    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <line x1="16.75" y1="14.75" x2="1.25" y2="14.75" stroke={stroke} strokeWidth="2.5" strokeLinecap="round" />
      <line x1="16.75" y1="8.25" x2="1.25" y2="8.25" stroke={stroke} strokeWidth="2.5" strokeLinecap="round" />
      <line x1="16.75" y1="1.25" x2="1.25" y2="1.25" stroke={stroke} strokeWidth="2.5" strokeLinecap="round" />
    </svg>
  );
};

export default MenuIcon;
