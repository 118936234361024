import { useTheme } from '@mui/styles';

const TrashIcon = (props) => {
  const { palette } = useTheme();

  const {
    fill = 'none',
    stroke = palette.common.pinkishRed,
    borderStroke = palette.common.pinkishRed,
    ...rest
  } = props;

  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill={fill} xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d="M16.7134 17.9468H8.14202L7.42773 7.94678H17.4277L16.7134 17.9468Z" stroke={stroke} strokeWidth="1.42857" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6 7.94678H18.8571" stroke={stroke} strokeWidth="1.42857" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11 10.8042V15.0899" stroke={stroke} strokeWidth="1.42857" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M13.8574 10.8042V15.0899" stroke={stroke} strokeWidth="1.42857" strokeLinecap="round" strokeLinejoin="round" />
      <circle cx="12" cy="12.9468" r="11.5" stroke={borderStroke} />
    </svg>
  );
};

export default TrashIcon;
