import { useTheme } from '@mui/styles';

const CertifiedCourses = (props) => {
  const { palette } = useTheme();

  const {
    stroke1 = palette.common.lightCyan,
    stroke2 = palette.common.emeraldGreen,
  } = props;

  return (
    <svg width="50" height="51" viewBox="0 0 50 51" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M10.0146 27.2546L1.5625 37.5838L9.375 39.1463L12.5 48.5213L20.7167 35.4838" stroke={stroke1} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M39.9833 27.2548L48.4354 37.584L40.6229 39.1465L37.4979 48.5215L29.2812 35.484" stroke={stroke1} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M42.1867 18.834C42.183 26.5257 37.0671 33.2775 29.6633 35.362C22.2595 37.4465 14.3725 34.3557 10.3562 27.7959C6.34003 21.2361 7.17358 12.8061 12.3967 7.15992C17.6199 1.51368 25.9595 0.0273809 32.8117 3.52154" stroke={stroke1} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M37.5 11.0209L26.1042 22.4167C25.8115 22.7098 25.4142 22.8745 25 22.8745C24.5858 22.8745 24.1885 22.7098 23.8958 22.4167L20.3125 18.8334" stroke={stroke2} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default CertifiedCourses;
