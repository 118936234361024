import { memo } from 'react';
import { useController } from 'react-hook-form';
import { FormControl, FormHelperText } from '@mui/material';
import PropTypes from 'prop-types';
import { Checkbox } from 'components/molecules';

const FormCheckbox = (props) => {
  const {
    checkboxProps,
    ...rest
  } = props;

  const { field, fieldState } = useController(rest);
  const { error } = fieldState;

  const hasErrored = Boolean(error);

  return (
    <FormControl error={hasErrored}>
      <Checkbox
        labelProps={rest}
        checkboxProps={{
          disableRipple: true,
          ...field,
          ...checkboxProps,
        }}
      />
      {hasErrored && <FormHelperText>{error?.message}</FormHelperText>}
    </FormControl>
  );
};

FormCheckbox.propTypes = {
  checkboxProps: PropTypes.oneOfType([PropTypes.object]),
};

FormCheckbox.defaultProps = {
  checkboxProps: {},
};

export default memo(FormCheckbox);
