import { useEffect } from 'react';
import { useAuth } from 'util/hooks';
import { storageKeys } from 'util/localStorage';

// ==============================|| AuthWatcher ||============================== //

const AuthWatcher = () => {
  const { logout } = useAuth();

  useEffect(() => {
    const checkToken = (e) => {
      if (e.key === storageKeys.accessToken && e.oldValue && !e.newValue) {
        // Other tab logged out
        logout();
      } else if (e.key === storageKeys.accessToken && !e.oldValue && e.newValue) {
        // Other tab logged in
        window.location.reload();
      }
    };

    window.addEventListener('storage', checkToken);

    return () => {
      window.removeEventListener('storage', checkToken);
    };
  }, [logout]);

  return null;
};

export default AuthWatcher;
