import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Box,
  Card,
  Switch,
  InputLabel,
  Typography,
} from '@mui/material';

import { PATHS } from 'routes';
import { createCourse, updateCourse } from 'services';
import { FormUploadMedia, FormUploadMultiMedia } from 'components/form';

import {
  useAuth,
  useLocale,
  useFastForm,
  useSnackbar,
} from 'util/hooks';
import { getDirtyObject } from 'util/helpers';
import MEDIA_TYPES from 'assets/constants/media';
import { COURSE_TYPES } from 'assets/constants/courses';

const CourseFormStepThree = (props) => {
  const {
    createMode = false,
    courseDetails = {},
    courseFormInfo = {},
    isOnDemandCourse = false,
    setCourseFormInfo = () => { },
  } = props;

  const { t } = useLocale();
  const snack = useSnackbar();
  const navigate = useNavigate();
  const { isAdmin, isContentCreator } = useAuth();

  const {
    isCourseDraft = false,
    isCourseRunning = false,
    isCourseCompleted = false,
    isCoursePublished = false,
  } = courseDetails || {};

  const [settingsState, setSettingsState] = useState({
    1: courseDetails?.isNomination || false,
    2: courseDetails?.hasCertificate || false,
    3: courseDetails?.isRecorded || false,
    4: courseDetails?.canRegister || false,
  });

  const switchCards = [];

  if (isOnDemandCourse) {
    switchCards.push({
      id: '2',
      name: 'hasCertificate',
      title: t('certificates.table.certificate'),
      description: t('course.certificatesDescription'),
      checked: settingsState[2],
    });
  }

  if (!isOnDemandCourse) {
    switchCards.push(
      {
        id: '1',
        name: 'isNomination',
        title: t('course.nominationStatus'),
        description: t('course.nominationDescription'),
        checked: settingsState[1],
      },
      {
        id: '2',
        name: 'hasCertificate',
        title: t('certificates.table.certificate'),
        description: t('course.certificatesDescription'),
        checked: settingsState[2],
      },
      {
        id: '3',
        name: 'isRecorded',
        title: t('course.recordingStatus'),
        description: t('course.recordingDescription'),
        checked: settingsState[3],
      },
      {
        id: '4',
        name: 'canRegister',
        title: t('course.registrationStatus'),
        description: t('course.registrationOpen'),
        checked: settingsState[4],
      },
    );
  }

  const onSubmit = async (values) => {
    const dirtyPayload = getDirtyObject({
      ...courseFormInfo,
      ...values,
      attachments: values.attachments.filter((i) => !i.fileName.includes('public/uploads')),
    }, courseDetails);

    let message = '';

    try {
      if (createMode) {
        const { id } = await createCourse({
          ...courseFormInfo,
          ...values,
          type: isOnDemandCourse ? COURSE_TYPES.onDemand : COURSE_TYPES.live,
        });

        isOnDemandCourse ? navigate(`/${PATHS.courses}/${id}/${PATHS.courseDetails}`)
          : navigate(`/${PATHS.courses}/${id}/${PATHS.sessions}`);
        message = t('course.validation.courseCreated');
      } else {
        await updateCourse(dirtyPayload, courseDetails?.id);

        isOnDemandCourse ? navigate(`/${PATHS.courses}/${courseDetails?.id}/${PATHS.courseDetails}`)
          : navigate(`/${PATHS.courses}/${courseDetails?.id}/${PATHS.sessions}`);
        message = t('course.validation.courseUpdated');
      }
      snack({
        severity: 'success',
        message,
      });
    } catch (error) {
      const { errors } = error;
      snack({
        severity: 'error',
        message: errors?.[0]?.message || t('common.somethingWrong'),
      });
    }
  };

  const courseAttachment = courseFormInfo?.attachments.length
    ? courseFormInfo?.attachments : courseDetails?.attachments;

  const defaultValues = {
    isNomination: courseFormInfo?.isNomination || courseDetails?.isNomination || false,
    hasCertificate: courseFormInfo?.hasCertificate || courseDetails?.hasCertificate || false,
    isRecorded: courseFormInfo?.isRecorded || courseDetails?.isRecorded || false,
    canRegister: courseFormInfo?.canRegister || courseDetails?.canRegister || false,
    cover: courseFormInfo?.cover || courseDetails?.cover || '',
    attachments: courseAttachment || [],
  };

  const validationSchema = Yup.object({
    isNomination: Yup.boolean(),
    hasCertificate: Yup.boolean(),
    isRecorded: Yup.boolean(),
    canRegister: Yup.boolean(),
    cover: Yup
      .string()
      .nullable()
      .required(t('posts.uploadCover')),
    attachments: Yup
      .array()
      .nullable(true),
  });

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    setError,
    formState: {
      isSubmitting,
      isValid,
      errors,
    },
  } = useFastForm({
    defaultValues,
    validationSchema,
  });

  const onToggleSwitch = (e) => {
    const switchId = e.target.id;
    const switchName = e.target.name;

    setSettingsState((prev) => ({
      ...prev,
      [switchId]: !settingsState[switchId],
    }));
    setValue(switchName, !settingsState[switchId]);
  };

  useEffect(() => {
    setCourseFormInfo({
      ...courseFormInfo,
      attachments: getValues('attachments'),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('attachments')]);

  const isUploadDisabled = isCourseCompleted
    || (isContentCreator && (isCourseRunning || isCoursePublished));

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: 4,
        py: 10,
        px: {
          sm: 15,
          xs: 5,
        },
        m: 'auto',
        width: 1,
        maxWidth: 682,
      }}
      >
        {switchCards?.map((card) => (
          <Card
            key={card.id}
            sx={{
              width: 1,
              maxWidth: 582,
              p: 4,
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: 2,
            }}
            >
              <Typography
                variant="bodyStandardRegular"
              >
                {card.title}
              </Typography>
              <Typography
                variant="bodySmallRegular"
              >
                {card.description}
              </Typography>
            </Box>
            <Switch
              id={card.id}
              name={card.name}
              checked={card.checked}
              onChange={onToggleSwitch}
              disabled={(isCourseCompleted && (!isAdmin || card.name !== 'isRecorded'))
              || (!createMode && !isCourseDraft && card.name === 'isNomination')}
            />
          </Card>
        ))}
        <Box sx={{ my: 2 }}>
          <InputLabel sx={(theme) => ({
            ...theme.typography.bodySmallRegular,
            color: 'secondary.main',
            mb: 3,
          })}
          >
            {t('posts.createArticle.coverImage')}
          </InputLabel>
          <>
            <FormUploadMedia
              name="cover"
              control={control}
              type={MEDIA_TYPES.image}
              setError={(errorProps) => setError('cover', errorProps)}
              src={getValues('cover')}
              disabled={isSubmitting || isUploadDisabled}
            />
            <Box sx={{ pl: 3 }}>
              <Typography
                variant="bodySmallRegular"
                sx={{ color: 'common.passionFruitRed' }}
              >
                {errors.cover && errors.cover.message}
              </Typography>
            </Box>
          </>
        </Box>
        <Box sx={{ mb: 2 }}>
          <InputLabel sx={(theme) => ({
            ...theme.typography.bodySmallRegular,
            color: 'secondary.main',
            mb: 3,
          })}
          >
            {t('common.optionalAttachments')}
          </InputLabel>
          <>
            <FormUploadMultiMedia
              name="attachments"
              disabled={isSubmitting}
              control={control}
              setError={(errorProps) => setError('attachments', errorProps)}
              attachments={getValues('attachments')}
              courseId={courseDetails?.id}
            />
            <Box sx={{ pl: 3 }}>
              <Typography
                variant="bodySmallRegular"
                sx={{ color: 'common.passionFruitRed' }}
              >
                {errors.attachments && errors.attachments.message}
              </Typography>
            </Box>
          </>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <LoadingButton
            sx={{ width: 1, maxWidth: 186 }}
            type="submit"
            variant="contained"
            loading={isSubmitting}
            disabled={!isValid || isSubmitting}
            size="large"
          >
            {createMode ? t('common.create') : t('common.update')}
          </LoadingButton>
        </Box>
      </Box>
    </form>
  );
};

export default CourseFormStepThree;
