import { atom } from 'recoil';
import { localStorageEffect } from 'recoil/effects';

const MediaInfoAtom = atom({
  key: 'mediaInfo',
  default: {
    name: '',
    size: '',
    image: null,
  },
  effects_UNSTABLE: [localStorageEffect('mediaInfo')],
});

export default MediaInfoAtom;
