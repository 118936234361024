import React from 'react';

import { useTheme } from '@mui/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useLocale } from 'util/hooks';
import { useProfileCTFs, useProfilePoints } from 'reactQuery/queries';
import { POINT_TYPES } from 'assets/constants/points';
import { PointsWithShadowIcon } from 'assets/icons';

import { Divider } from '@mui/material';
import { capitalizeFirstLetter } from 'util/helpers';
import EmptyCTFsPlaceholder from './sections/EmptyCTFsPlaceholder';

const PREVIEW_LIMIT = 2;

const ProfileCTFs = () => {
  const theme = useTheme();
  const { t } = useLocale();
  const [expanded, setExpanded] = React.useState(false);

  const {
    data = {},
    isFetched = false,
  } = useProfileCTFs();

  const { items: ctfs = [] } = data;
  const { data: { totalPoints: ctfPoints = 0 } = {} } = useProfilePoints({
    queryKeyOptions: {
      searchOptions: {
        type: POINT_TYPES.CTF,
      },
    },
    options: {
      suspense: false, // prevent points from holding render
    },
  });

  const totalCtfs = ctfs?.length || 0;
  const isEmpty = !totalCtfs && isFetched;

  const {
    previewList = [],
    remainingList = [],
  } = React.useMemo(() => {
    const all = ctfs?.map((singleCtf, index) => {
      const {
        id,
        team,
        rank,
        points,
        ctf,
      } = singleCtf;

      const isTeamCTF = !!team;
      const isLastElement = index === ctfs.length - 1;
      const resolvedType = isTeamCTF
        ? `${t('common.team')}: ${team}`
        : t('common.individual');

      const isTopThree = rank <= 3;
      const isCyberhubCTF = ctf.type === 'cyberhub';

      return (
        <Box
          key={id}
          sx={{ px: 4 }}
        >
          <Box mb={2}>
            <Typography variant="bodySmallRegular">
              {isCyberhubCTF ? `CTF #${id}` : `${capitalizeFirstLetter(ctf.type)} CTF`}
            </Typography>
          </Box>
          <Stack
            spacing={8}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack
              spacing={8}
              direction="row"
              alignItems="center"
            >
              <Box width={45}>
                <Typography
                  variant="bodyStandardMedium"
                  color={isTopThree ? 'common.vividOrange' : ''}
                >
                  {t('helpers.count', { count: rank, ordinal: true })}
                </Typography>
              </Box>
              <Stack>
                <Typography variant="bodySmallMedium">
                  {resolvedType}
                </Typography>
                <Typography variant="bodyMicroRegular" color="common.gadgetGray">
                  {singleCtf.ctf?.name}
                </Typography>
              </Stack>
            </Stack>
            <Stack
              width={60}
              spacing={2}
              direction="row"
              alignItems="center"
            >
              <PointsWithShadowIcon
                width="20"
                height="20"
                innerFill={theme.palette.common.vividOrange}
                rectFill={theme.palette.common.vividOrange}
              />
              <Typography variant="bodyStandardRegular">
                {points || 0}
              </Typography>
            </Stack>
          </Stack>
          {!isLastElement && <Divider sx={{ my: 6 }} />}
        </Box>
      );
    });

    const preview = all?.slice(0, PREVIEW_LIMIT);
    const remaining = all?.slice(PREVIEW_LIMIT);

    return {
      allList: all,
      previewList: preview,
      remainingList: remaining,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ctfs, t]);

  return (
    <Card sx={{ p: 5, borderRadius: 0 }}>
      <Stack spacing={4}>
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        >
          <Box>
            <Typography variant="h6">
              {t('common.ctfs')}
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="bodySmallRegular"
              color="common.darkGray"
            >
              {`${totalCtfs} ${t('common.ctfs')}`}
            </Typography>
          </Box>
        </Box>
        {isEmpty
          ? <EmptyCTFsPlaceholder />
          : (
            <>
              <Box>
                <Stack spacing={1}>
                  <Typography variant="bodySmallMedium">
                    {t('common.points')}
                  </Typography>
                  <Stack
                    spacing={2}
                    direction="row"
                    alignItems="center"
                  >
                    <PointsWithShadowIcon
                      width="20"
                      height="20"
                      innerFill={theme.palette.common.vividOrange}
                      rectFill={theme.palette.common.vividOrange}
                    />
                    <Typography variant="bodyStandardRegular">
                      {ctfPoints || 0}
                    </Typography>
                  </Stack>
                </Stack>
              </Box>
              <Box>
                {previewList}
                {remainingList?.length
                  ? (
                    <Box>
                      <Collapse in={expanded} timeout="auto" unmountOnExit>
                        {remainingList}
                      </Collapse>
                      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                        <Button
                          onClick={() => setExpanded((prev) => !prev)}
                          sx={{ color: 'common.softBlue' }}
                          endIcon={(
                            <ExpandMoreIcon
                              sx={{
                                transform: !expanded ? 'rotate(0deg)' : 'rotate(180deg)',
                                transition: ({ transitions }) => transitions.create('transform', {
                                  duration: transitions.duration.shortest,
                                }),
                              }}
                            />
                          )}
                        >
                          {expanded
                            ? t('common.showLess')
                            : t('common.showAll')}
                        </Button>
                      </Box>
                    </Box>
                  )
                  : null}
              </Box>
            </>
          )}
      </Stack>
    </Card>
  );
};

export default ProfileCTFs;
