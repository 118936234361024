import { useLocale, usePageTitle } from 'util/hooks';
import SuspenseWrapper from './SuspenseWrapper/SuspenseWrapper';

const { REACT_APP_NAME } = process.env;

const Page = ({ title, titleKey, children }) => {
  const { t } = useLocale();
  const resolvedTitle = title
    || (titleKey
      ? t(`pageTitles.${titleKey}`)
      : (REACT_APP_NAME || 'CyberHub'));

  usePageTitle(resolvedTitle);

  return (
    <SuspenseWrapper>
      {children}
    </SuspenseWrapper>
  );
};

export default Page;
