import {
  Box, Typography,
} from '@mui/material';

const AttachmentFile = (props) => {
  const {
    fileName = '',
    fileUrl = '',
  } = props;

  const fileExtention = fileName.split('.').pop();

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        mb: 6,
      }}
    >
      <a
        style={{
          textDecoration: 'none',
          display: 'flex',
          flexDirection: 'row',
        }}
        download
        href={fileUrl}
        target="_blank"
        title={fileName}
        rel="noreferrer"
      >
        <Box sx={{
          backgroundColor: 'common.silverGrey',
          borderRadius: 1,
          padding: 3,
          width: 70,
        }}
        >
          <Box sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
          >
            <Typography
              variant="bodyStandardRegular"
              color="common.pinkishRed"
            >
              {fileExtention.toUpperCase()}
            </Typography>
          </Box>
        </Box>
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          px: 4,
        }}
        >
          <Typography variant="bodyStandardRegular">
            {`${fileName.slice(0, 15).concat('..')}${fileExtention}`}
          </Typography>
        </Box>
      </a>
    </Box>
  );
};

export default AttachmentFile;
