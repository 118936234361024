import React from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { modalAtom } from 'recoil/atoms';

const useModal = () => {
  const [modals, setModals] = useRecoilState(modalAtom);
  const resetModals = useResetRecoilState(modalAtom);

  /**
   * Appends a modal to the modals array
   */
  const addModal = React.useCallback((newModal) => {
    setModals((prevModal) => ([
      ...prevModal,
      newModal,
    ]));
  }, [setModals]);

  /**
   * Removes the modal at the index given or 0 if no index is given
   */
  const shiftModal = React.useCallback((idx = 0) => {
    if (modals[idx] === undefined) {
      return;
    }

    const modalCopy = [...modals];
    modalCopy.splice(idx, 1);
    setModals(modalCopy);
  }, [modals, setModals]);

  /**
   * Removes a single modal element by modal key
   */
  const removeModal = React.useCallback((modalToRemove) => {
    const idx = modals?.findIndex((modal) => modal.key === modalToRemove);

    shiftModal(idx);
  }, [modals, shiftModal]);

  return {
    modals,
    setModals,
    resetModals,
    addModal,
    shiftModal,
    removeModal,
  };
};

export default useModal;
