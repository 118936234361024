import {
  Box,
  Typography,
  alpha,
  useTheme,
} from '@mui/material';
import { COURSE_TRACK_COLORS } from 'assets/constants/courses';
import { useLocale } from 'util/hooks';

const CourseCardTrackBar = (props) => {
  const {
    track = '',
  } = props;

  const { palette } = useTheme();
  const { t } = useLocale();

  const color = COURSE_TRACK_COLORS[track];

  return (
    <Box
      sx={{
        backgroundColor: alpha(palette.common[color], 0.20),
        pb: 1,
        position: 'absolute',
        bottom: 0,
        width: 1,
      }}
      textAlign="center"
    >
      <Typography variant="bodySmallMedium" color={`common.${color}`}>
        {t(`coursesTrack.${track}`)}
      </Typography>
    </Box>
  );
};

export default CourseCardTrackBar;
