const countries = [
  {
    id: 1,
    nameEn: 'British Indian Ocean Territory',
    nameAr: 'الإقليم البريطاني في المحيط الهندي',
    code: 'IO',
    nicCode: null,
    label: 'British Indian Ocean Territory',
    phone: '246',
  },
  {
    id: 2,
    nameEn: 'Western Sahara',
    nameAr: 'الصحراء الغربية',
    code: 'EH',
    nicCode: null,
    label: 'Western Sahara',
    phone: '212',
  },
  {
    id: 5,
    nameEn: 'French Southern Territories',
    nameAr: 'المقاطعات الجنوبية الفرنسية',
    code: 'TF',
    nicCode: null,
    label: 'French Southern Territories',
    phone: '262',
  },
  {
    id: 6,
    nameEn: 'Aruba',
    nameAr: 'آروبا',
    code: 'AW',
    nicCode: null,
    label: 'Aruba',
    phone: '297',
  },
  {
    id: 7,
    nameEn: 'Antarctica',
    nameAr: 'أنتاركتيكا',
    code: 'AQ',
    nicCode: null,
    label: 'Antarctica',
    phone: '672',
  },
  {
    id: 8,
    nameEn: 'Antigua and Barbuda',
    nameAr: 'أنتيغوا وبربودا',
    code: 'AG',
    nicCode: null,
    label: 'Antigua and Barbuda',
    phone: '1-268',
  },
  {
    id: 9,
    nameEn: 'Anguilla',
    nameAr: 'أنغويلا',
    code: 'AI',
    nicCode: null,
    label: 'Anguilla',
    phone: '1-264',
  },
  {
    id: 10,
    nameEn: 'Uruguay',
    nameAr: 'أورغواي',
    code: 'UY',
    nicCode: null,
    label: 'Uruguay',
    phone: '598',
  },
  {
    id: 11,
    nameEn: 'Papua New Guinea',
    nameAr: 'بابوا غينيا الجديدة',
    code: 'PG',
    nicCode: null,
    label: 'Papua New Guinea',
    phone: '675',
  },
  {
    id: 12,
    nameEn: 'Palau',
    nameAr: 'بالاو',
    code: 'PW',
    nicCode: null,
    label: 'Palau',
    phone: '680',
  },
  {
    id: 13,
    nameEn: 'Bermuda',
    nameAr: 'برمودا',
    code: 'BM',
    nicCode: null,
    label: 'Bermuda',
    phone: '1-441',
  },
  {
    id: 14,
    nameEn: 'Belize',
    nameAr: 'بليز',
    code: 'BZ',
    nicCode: null,
    label: 'Belize',
    phone: '501',
  },
  {
    id: 15,
    nameEn: 'Puerto Rico',
    nameAr: 'بورتوريكو',
    code: 'PR',
    nicCode: null,
    label: 'Puerto Rico',
    phone: '1',
  },
  {
    id: 16,
    nameEn: 'French Polynesia',
    nameAr: 'بولينيزيا الفرنسية',
    code: 'PF',
    nicCode: null,
    label: 'French Polynesia',
    phone: '689',
  },
  {
    id: 17,
    nameEn: 'Taiwan',
    nameAr: 'تايوان',
    code: 'TW',
    nicCode: null,
    label: 'Taiwan, Province of China',
    phone: '886',
  },
  {
    id: 19,
    nameEn: 'Tuvalu',
    nameAr: 'توفالو',
    code: 'TV',
    nicCode: null,
    label: 'Tuvalu',
    phone: '688',
  },
  {
    id: 20,
    nameEn: 'Tokelau',
    nameAr: 'توكيلو',
    code: 'TK',
    nicCode: null,
    label: 'Tokelau',
    phone: '690',
  },
  {
    id: 21,
    nameEn: 'Jordan',
    nameAr: 'الأردن',
    code: 'JO',
    nicCode: '102',
    label: 'Jordan',
    phone: '962',
  },
  {
    id: 22,
    nameEn: 'Bahrain',
    nameAr: 'البحرين',
    code: 'BH',
    nicCode: '103',
    label: 'Bahrain',
    phone: '973',
  },
  {
    id: 23,
    nameEn: 'Iraq',
    nameAr: 'العراق',
    code: 'IQ',
    nicCode: '105',
    label: 'Iraq',
    phone: '964',
  },
  {
    id: 24,
    nameEn: 'Palestinian',
    nameAr: 'فلسطين',
    code: 'PS',
    nicCode: '107',
    label: 'Palestine',
    phone: '970',
  },
  {
    id: 25,
    nameEn: 'Kuwait',
    nameAr: 'الكويت',
    code: 'KW',
    nicCode: '109',
    label: 'Kuwait',
    phone: '965',
  },
  {
    id: 26,
    nameEn: 'Saudi Arabia',
    nameAr: 'المملكة العربية السعودية',
    code: 'SA',
    nicCode: '113',
    label: 'Saudi Arabia',
    phone: '966',
  },
  {
    id: 27,
    nameEn: 'Tunisia',
    nameAr: 'تونس',
    code: 'TN',
    nicCode: '201',
    label: 'Tunisia',
    phone: '216',
  },
  {
    id: 28,
    nameEn: 'Algeria',
    nameAr: 'الجزائر',
    code: 'DZ',
    nicCode: '202',
    label: 'Algeria',
    phone: '213',
  },
  {
    id: 29,
    nameEn: 'Sudan',
    nameAr: 'السودان',
    code: 'SD',
    nicCode: '204',
    label: 'Sudan',
    phone: '249',
  },
  {
    id: 30,
    nameEn: 'Somalia',
    nameAr: 'الصومال',
    code: 'SO',
    nicCode: '205',
    label: 'Somalia',
    phone: '252',
  },
  {
    id: 31,
    nameEn: 'Afghanistan',
    nameAr: 'أفغانستان',
    code: 'AF',
    nicCode: '301',
    label: 'Afghanistan',
    phone: '93',
  },
  {
    id: 32,
    nameEn: 'Indonesia',
    nameAr: 'أندونيسيا',
    code: 'ID',
    nicCode: '302',
    label: 'Indonesia',
    phone: '62',
  },
  {
    id: 33,
    nameEn: 'Iran',
    nameAr: 'إيران',
    code: 'IR',
    nicCode: '303',
    label: 'Iran, Islamic Republic of',
    phone: '98',
  },
  {
    id: 34,
    nameEn: 'Pakistan',
    nameAr: 'باكستان',
    code: 'PK',
    nicCode: '304',
    label: 'Pakistan',
    phone: '92',
  },
  {
    id: 35,
    nameEn: 'Bangladesh',
    nameAr: 'بنجلاديش',
    code: 'BD',
    nicCode: '305',
    label: 'Bangladesh',
    phone: '880',
  },
  {
    id: 36,
    nameEn: 'Brunei',
    nameAr: 'بروناي',
    code: 'BN',
    nicCode: '306',
    label: 'Brunei Darussalam',
    phone: '673',
  },
  {
    id: 37,
    nameEn: 'Turkey',
    nameAr: 'تركيا',
    code: 'TR',
    nicCode: '309',
    label: 'Turkey',
    phone: '90',
  },
  {
    id: 38,
    nameEn: 'Philippines',
    nameAr: 'الفلبين',
    code: 'PH',
    nicCode: '315',
    label: 'Philippines',
    phone: '63',
  },
  {
    id: 39,
    nameEn: 'India',
    nameAr: 'الهند',
    code: 'IN',
    nicCode: '321',
    label: 'India',
    phone: '91',
  },
  {
    id: 40,
    nameEn: 'Japan',
    nameAr: 'اليابان',
    code: 'JP',
    nicCode: '323',
    label: 'Japan',
    phone: '81',
  },
  {
    id: 41,
    nameEn: 'Bhutan',
    nameAr: 'بوتان',
    code: 'BT',
    nicCode: '324',
    label: 'Bhutan',
    phone: '975',
  },
  {
    id: 42,
    nameEn: 'China',
    nameAr: 'الصين',
    code: 'CN',
    nicCode: '325',
    label: 'China',
    phone: '86',
  },
  {
    id: 43,
    nameEn: 'Turkmenistan',
    nameAr: 'تركمانستان',
    code: 'TM',
    nicCode: '338',
    label: 'Turkmenistan',
    phone: '993',
  },
  {
    id: 44,
    nameEn: 'Azerbaijan',
    nameAr: 'أذربيجان',
    code: 'AZ',
    nicCode: '343',
    label: 'Azerbaijan',
    phone: '994',
  },
  {
    id: 45,
    nameEn: 'Ethiopia',
    nameAr: 'إثيوبيا',
    code: 'ET',
    nicCode: '401',
    label: 'Ethiopia',
    phone: '251',
  },
  {
    id: 46,
    nameEn: 'Uganda',
    nameAr: 'أوغندا',
    code: 'UG',
    nicCode: '402',
    label: 'Uganda',
    phone: '256',
  },
  {
    id: 47,
    nameEn: 'Botswana',
    nameAr: 'بتسوانا',
    code: 'BW',
    nicCode: '403',
    label: 'Botswana',
    phone: '267',
  },
  {
    id: 48,
    nameEn: 'Burundi',
    nameAr: 'بوروندي',
    code: 'BI',
    nicCode: '404',
    label: 'Burundi',
    phone: '257',
  },
  {
    id: 49,
    nameEn: 'Chad',
    nameAr: 'تشاد',
    code: 'TD',
    nicCode: '405',
    label: 'Chad',
    phone: '235',
  },
  {
    id: 50,
    nameEn: 'Togo',
    nameAr: 'توجو',
    code: 'TG',
    nicCode: '407',
    label: 'Togo',
    phone: '228',
  },
  {
    id: 51,
    nameEn: 'Gabon',
    nameAr: 'الجابون',
    code: 'GA',
    nicCode: '408',
    label: 'Gabon',
    phone: '241',
  },
  {
    id: 52,
    nameEn: 'Benin',
    nameAr: 'بنين',
    code: 'BJ',
    nicCode: '413',
    label: 'Benin',
    phone: '229',
  },
  {
    id: 53,
    nameEn: 'Senegal',
    nameAr: 'السنغال',
    code: 'SN',
    nicCode: '419',
    label: 'Senegal',
    phone: '221',
  },
  {
    id: 54,
    nameEn: 'Cameroon',
    nameAr: 'الكاميرون',
    code: 'CM',
    nicCode: '425',
    label: 'Cameroon',
    phone: '237',
  },
  {
    id: 55,
    nameEn: 'Niger',
    nameAr: 'النيجر',
    code: 'NE',
    nicCode: '436',
    label: 'Niger',
    phone: '227',
  },
  {
    id: 56,
    nameEn: 'Angola',
    nameAr: 'أنغولا',
    code: 'AO',
    nicCode: '438',
    label: 'Angola',
    phone: '244',
  },
  {
    id: 57,
    nameEn: 'Eritrea',
    nameAr: 'أريتريا',
    code: 'ER',
    nicCode: '449',
    label: 'Eritrea',
    phone: '291',
  },
  {
    id: 58,
    nameEn: 'Cape Verde',
    nameAr: 'الرأس الأخضر',
    code: 'CV',
    nicCode: '454',
    label: 'Cape Verde',
    phone: '238',
  },
  {
    id: 59,
    nameEn: 'Spain',
    nameAr: 'إسبانيا',
    code: 'ES',
    nicCode: '501',
    label: 'Spain',
    phone: '34',
  },
  {
    id: 60,
    nameEn: 'Albania',
    nameAr: 'ألبانيا',
    code: 'AL',
    nicCode: '502',
    label: 'Albania',
    phone: '355',
  },
  {
    id: 61,
    nameEn: 'Germany',
    nameAr: 'ألمانيا',
    code: 'DE',
    nicCode: '503',
    label: 'Germany',
    phone: '49',
  },
  {
    id: 62,
    nameEn: 'Italy',
    nameAr: 'إيطاليا',
    code: 'IT',
    nicCode: '505',
    label: 'Italy',
    phone: '39',
  },
  {
    id: 63,
    nameEn: 'United Kingdom',
    nameAr: 'المملكة المتحدة',
    code: 'GB',
    nicCode: '506',
    label: 'United Kingdom',
    phone: '44',
  },
  {
    id: 64,
    nameEn: 'Portugal',
    nameAr: 'البرتغال',
    code: 'PT',
    nicCode: '507',
    label: 'Portugal',
    phone: '351',
  },
  {
    id: 65,
    nameEn: 'Bulgaria',
    nameAr: 'بلغاريا',
    code: 'BG',
    nicCode: '508',
    label: 'Bulgaria',
    phone: '359',
  },
  {
    id: 66,
    nameEn: 'Belgium',
    nameAr: 'بلجيكا',
    code: 'BE',
    nicCode: '509',
    label: 'Belgium',
    phone: '32',
  },
  {
    id: 67,
    nameEn: 'Poland',
    nameAr: 'بولندا',
    code: 'PL',
    nicCode: '510',
    label: 'Poland',
    phone: '48',
  },
  {
    id: 68,
    nameEn: 'Sweden',
    nameAr: 'السويد',
    code: 'SE',
    nicCode: '514',
    label: 'Sweden',
    phone: '46',
  },
  {
    id: 69,
    nameEn: 'Greece',
    nameAr: 'اليونان',
    code: 'GR',
    nicCode: '521',
    label: 'Greece',
    phone: '30',
  },
  {
    id: 70,
    nameEn: 'Andorra',
    nameAr: 'أندورا',
    code: 'AD',
    nicCode: '522',
    label: 'Andorra',
    phone: '376',
  },
  {
    id: 71,
    nameEn: 'Austria',
    nameAr: 'النمسا',
    code: 'AT',
    nicCode: '523',
    label: 'Austria',
    phone: '43',
  },
  {
    id: 72,
    nameEn: 'Montenegro',
    nameAr: 'الجبل الأسود',
    code: 'ME',
    nicCode: '524',
    label: 'Montenegro',
    phone: '382',
  },
  {
    id: 73,
    nameEn: 'Iceland',
    nameAr: 'أيسلندا',
    code: 'IS',
    nicCode: '526',
    label: 'Iceland',
    phone: '354',
  },
  {
    id: 74,
    nameEn: 'Vatican City',
    nameAr: 'الفاتيكان',
    code: 'VA',
    nicCode: '533',
    label: 'Holy See (Vatican City State)',
    phone: '379',
  },
  {
    id: 75,
    nameEn: 'Ukraine',
    nameAr: 'أوكرانيا',
    code: 'UA',
    nicCode: '536',
    label: 'Ukraine',
    phone: '380',
  },
  {
    id: 76,
    nameEn: 'Armenia',
    nameAr: 'أرمينيا',
    code: 'AM',
    nicCode: '539',
    label: 'Armenia',
    phone: '374',
  },
  {
    id: 77,
    nameEn: 'Estonia',
    nameAr: 'أستونيا',
    code: 'EE',
    nicCode: '543',
    label: 'Estonia',
    phone: '372',
  },
  {
    id: 78,
    nameEn: 'United States',
    nameAr: 'الولايات المتحدة',
    code: 'US',
    nicCode: '601',
    label: 'United States',
    phone: '1',
  },
  {
    id: 79,
    nameEn: 'Argentina',
    nameAr: 'الأرجنتين',
    code: 'AR',
    nicCode: '602',
    label: 'Argentina',
    phone: '54',
  },
  {
    id: 80,
    nameEn: 'Barbados',
    nameAr: 'بربادوس',
    code: 'BB',
    nicCode: '603',
    label: 'Barbados',
    phone: '1-246',
  },
  {
    id: 81,
    nameEn: 'Brazil',
    nameAr: 'البرازيل',
    code: 'BR',
    nicCode: '604',
    label: 'Brazil',
    phone: '55',
  },
  {
    id: 82,
    nameEn: 'Panama',
    nameAr: 'بنما',
    code: 'PA',
    nicCode: '605',
    label: 'Panama',
    phone: '507',
  },
  {
    id: 83,
    nameEn: 'Trinidad and Tobago',
    nameAr: 'ترينيداد وتوباغو',
    code: 'TT',
    nicCode: '606',
    label: 'Trinidad and Tobago',
    phone: '1-868',
  },
  {
    id: 84,
    nameEn: 'Bahamas',
    nameAr: 'الباهاما',
    code: 'BS',
    nicCode: '612',
    label: 'Bahamas',
    phone: '1-242',
  },
  {
    id: 85,
    nameEn: 'El Salvador',
    nameAr: 'السلفادور',
    code: 'SV',
    nicCode: '617',
    label: 'El Salvador',
    phone: '503',
  },
  {
    id: 86,
    nameEn: 'Mexico',
    nameAr: 'المكسيك',
    code: 'MX',
    nicCode: '622',
    label: 'Mexico',
    phone: '52',
  },
  {
    id: 87,
    nameEn: 'Bolivia',
    nameAr: 'بوليفيا',
    code: 'BO',
    nicCode: '626',
    label: 'Bolivia',
    phone: '591',
  },
  {
    id: 88,
    nameEn: 'Ecuador',
    nameAr: 'الإكوادور',
    code: 'EC',
    nicCode: '628',
    label: 'Ecuador',
    phone: '593',
  },
  {
    id: 89,
    nameEn: 'Peru',
    nameAr: 'بيرو',
    code: 'PE',
    nicCode: '630',
    label: 'Peru',
    phone: '51',
  },
  {
    id: 90,
    nameEn: 'Australia',
    nameAr: 'أستراليا',
    code: 'AU',
    nicCode: '701',
    label: 'Australia',
    phone: '61',
  },
  {
    id: 91,
    nameEn: 'Tonga',
    nameAr: 'تونغا',
    code: 'TO',
    nicCode: null,
    label: 'Tonga',
    phone: '676',
  },
  {
    id: 92,
    nameEn: 'Åland Islands',
    nameAr: 'جزر آلاند',
    code: 'AX',
    nicCode: null,
    label: 'Alland Islands',
    phone: '358',
  },
  {
    id: 93,
    nameEn: 'Turks and Caicos Islands',
    nameAr: 'جزر الترك وجايكوس',
    code: 'TC',
    nicCode: null,
    label: 'Turks and Caicos Islands',
    phone: '1-649',
  },
  {
    id: 94,
    nameEn: 'Cayman Islands',
    nameAr: 'جزر الكايمن',
    code: 'KY',
    nicCode: null,
    label: 'Cayman Islands',
    phone: '1-345',
  },
  {
    id: 96,
    nameEn: 'Marshall Islands',
    nameAr: 'جزر المارشال',
    code: 'MH',
    nicCode: null,
    label: 'Marshall Islands',
    phone: '692',
  },
  {
    id: 98,
    nameEn: 'Pitcairn Islands',
    nameAr: 'جزر بيتكيرن',
    code: 'PN',
    nicCode: null,
    label: 'Pitcairn',
    phone: '870',
  },
  {
    id: 99,
    nameEn: 'Solomon Islands',
    nameAr: 'جزر سليمان',
    code: 'SB',
    nicCode: null,
    label: 'Solomon Islands',
    phone: '677',
  },
  {
    id: 100,
    nameEn: 'US Virgin Islands',
    nameAr: 'جزر فرجين الأمريكية',
    code: 'VI',
    nicCode: null,
    label: 'US Virgin Islands',
    phone: '1-340',
  },
  {
    id: 101,
    nameEn: 'British Virgin Islands',
    nameAr: 'جزر فرجين البريطانية',
    code: 'VG',
    nicCode: null,
    label: 'British Virgin Islands',
    phone: '1-284',
  },
  {
    id: 102,
    nameEn: 'Falkland Islands',
    nameAr: 'جزر فوكلاند',
    code: 'FK',
    nicCode: null,
    label: 'Falkland Islands (Malvinas)',
    phone: '500',
  },
  {
    id: 103,
    nameEn: 'Cook Islands',
    nameAr: 'جزر كوك',
    code: 'CK',
    nicCode: null,
    label: 'Cook Islands',
    phone: '682',
  },
  {
    id: 104,
    nameEn: 'Cocos (Keeling) Islands',
    nameAr: 'جزر كوكوس',
    code: 'CC',
    nicCode: null,
    label: 'Cocos (Keeling) Islands',
    phone: '61',
  },
  {
    id: 105,
    nameEn: 'Northern Mariana Islands',
    nameAr: 'جزر ماريانا الشمالية',
    code: 'MP',
    nicCode: null,
    label: 'Northern Mariana Islands',
    phone: '1-670',
  },
  {
    id: 106,
    nameEn: 'Wallis and Futuna',
    nameAr: 'جزر والس وفوتونا',
    code: 'WF',
    nicCode: null,
    label: 'Wallis and Futuna',
    phone: '681',
  },
  {
    id: 108,
    nameEn: 'Christmas Island',
    nameAr: 'جزيرة الكريسماس',
    code: 'CX',
    nicCode: null,
    label: 'Christmas Island',
    phone: '61',
  },
  {
    id: 109,
    nameEn: 'Isle of Man',
    nameAr: 'جزيرة مان',
    code: 'IM',
    nicCode: null,
    label: 'Isle of Man',
    phone: '44',
  },
  {
    id: 110,
    nameEn: 'Norfolk Island',
    nameAr: 'جزيرة نورفوك',
    code: 'NF',
    nicCode: null,
    label: 'Norfolk Island',
    phone: '672',
  },
  {
    id: 111,
    nameEn: 'Guadeloupe',
    nameAr: 'جوادلوب',
    code: 'GP',
    nicCode: null,
    label: 'Guadeloupe',
    phone: '590',
  },
  {
    id: 167,
    nameEn: 'South Georgia and the South Sandwich Islands',
    nameAr: 'جورجيا الجنوبية وجزر ساندويتش الجنوبية',
    code: 'GS',
    nicCode: '541',
    label: 'South Georgia and the South Sandwich Islands',
    phone: '500',
  },
  {
    id: 113,
    nameEn: 'Jersey',
    nameAr: 'جيرسي',
    code: 'JE',
    nicCode: null,
    label: 'Jersey',
    phone: '44',
  },
  {
    id: 115,
    nameEn: 'Belarus',
    nameAr: 'روسيا البيضاء',
    code: 'BY',
    nicCode: null,
    label: 'Belarus',
    phone: '375',
  },
  {
    id: 117,
    nameEn: "Cote d'Ivoire",
    nameAr: 'ساحل العاج',
    code: 'CI',
    nicCode: null,
    label: "Cote d'Ivoire",
    phone: '225',
  },
  {
    id: 118,
    nameEn: 'Samoa',
    nameAr: 'ساموا',
    code: 'WS',
    nicCode: null,
    label: 'Samoa',
    phone: '685',
  },
  {
    id: 119,
    nameEn: 'American Samoa',
    nameAr: 'ساموا الأمريكية',
    code: 'AS',
    nicCode: null,
    label: 'American Samoa',
    phone: '1-684',
  },
  {
    id: 121,
    nameEn: 'Saint Pierre and Miquelon',
    nameAr: 'سانت بيير وميكولون',
    code: 'PM',
    nicCode: null,
    label: 'Saint Pierre and Miquelon',
    phone: '508',
  },
  {
    id: 122,
    nameEn: 'Saint Kitts and Nevis',
    nameAr: 'سانت كيتس ونيفيس',
    code: 'KN',
    nicCode: null,
    label: 'Saint Kitts and Nevis',
    phone: '1-869',
  },
  {
    id: 123,
    nameEn: 'Saint Martin',
    nameAr: 'سانت مارتن',
    code: 'MF',
    nicCode: null,
    label: 'Saint Martin (French part)',
    phone: '590',
  },
  {
    id: 124,
    nameEn: 'Saint Helena',
    nameAr: 'سانت هيلنا',
    code: 'SH',
    nicCode: null,
    label: 'Saint Helena',
    phone: '290',
  },
  {
    id: 125,
    nameEn: 'Svalbard and Jan Mayen',
    nameAr: 'سفالبارد وجان مايان',
    code: 'SJ',
    nicCode: null,
    label: 'Svalbard and Jan Mayen',
    phone: '47',
  },
  {
    id: 126,
    nameEn: 'Suriname',
    nameAr: 'سورينام',
    code: 'SR',
    nicCode: null,
    label: 'Suriname',
    phone: '597',
  },
  {
    id: 127,
    nameEn: 'Sint Maarten',
    nameAr: 'سينت مارتن',
    code: 'SX',
    nicCode: null,
    label: 'Sint Maarten (Dutch part)',
    phone: '1-721',
  },
  {
    id: 129,
    nameEn: 'Greenland',
    nameAr: 'غرينلاند',
    code: 'GL',
    nicCode: null,
    label: 'Greenland',
    phone: '299',
  },
  {
    id: 130,
    nameEn: 'Guam',
    nameAr: 'غوام',
    code: 'GU',
    nicCode: null,
    label: 'Guam',
    phone: '1-671',
  },
  {
    id: 131,
    nameEn: 'French Guiana',
    nameAr: 'غويانا الفرنسية',
    code: 'GF',
    nicCode: null,
    label: 'French Guiana',
    phone: '594',
  },
  {
    id: 132,
    nameEn: 'Guyana',
    nameAr: 'غيانا',
    code: 'GY',
    nicCode: null,
    label: 'Guyana',
    phone: '592',
  },
  {
    id: 133,
    nameEn: 'Guernsey',
    nameAr: 'غيرنزي',
    code: 'GG',
    nicCode: null,
    label: 'Guernsey',
    phone: '44',
  },
  {
    id: 134,
    nameEn: 'Vanuatu',
    nameAr: 'فانواتو',
    code: 'VU',
    nicCode: null,
    label: 'Vanuatu',
    phone: '678',
  },
  {
    id: 135,
    nameEn: 'Fiji',
    nameAr: 'فيجي',
    code: 'FJ',
    nicCode: null,
    label: 'Fiji',
    phone: '679',
  },
  {
    id: 136,
    nameEn: 'Oman',
    nameAr: 'عُمان',
    code: 'OM',
    nicCode: '106',
    label: 'Oman',
    phone: '968',
  },
  {
    id: 137,
    nameEn: 'Qatar',
    nameAr: 'قطر',
    code: 'QA',
    nicCode: '108',
    label: 'Qatar',
    phone: '974',
  },
  {
    id: 138,
    nameEn: 'Djibouti',
    nameAr: 'جيبوتي',
    code: 'DJ',
    nicCode: '203',
    label: 'Djibouti',
    phone: '253',
  },
  {
    id: 139,
    nameEn: 'Russia',
    nameAr: 'روسيا',
    code: 'RU',
    nicCode: '311',
    label: 'Russian Federation',
    phone: '7',
  },
  {
    id: 140,
    nameEn: 'Singapore',
    nameAr: 'سنغافورة',
    code: 'SG',
    nicCode: '312',
    label: 'Singapore',
    phone: '65',
  },
  {
    id: 141,
    nameEn: 'Sri Lanka',
    nameAr: 'سريلانكا',
    code: 'LK',
    nicCode: '313',
    label: 'Sri Lanka',
    phone: '94',
  },
  {
    id: 142,
    nameEn: 'Vietnam',
    nameAr: 'فيتنام',
    code: 'VN',
    nicCode: '316',
    label: 'Vietnam',
    phone: '84',
  },
  {
    id: 143,
    nameEn: 'Cyprus',
    nameAr: 'قبرص',
    code: 'CY',
    nicCode: '326',
    label: 'Cyprus',
    phone: '357',
  },
  {
    id: 144,
    nameEn: 'Tajikistan',
    nameAr: 'طاجكستان',
    code: 'TJ',
    nicCode: '339',
    label: 'Tajikistan',
    phone: '992',
  },
  {
    id: 145,
    nameEn: 'Kyrgyzstan',
    nameAr: 'قرغيزستان',
    code: 'KG',
    nicCode: '340',
    label: 'Kyrgyzstan',
    phone: '996',
  },
  {
    id: 146,
    nameEn: 'Gambia',
    nameAr: 'غامبيا',
    code: 'GM',
    nicCode: '409',
    label: 'Gambia',
    phone: '220',
  },
  {
    id: 147,
    nameEn: 'Comoros',
    nameAr: 'جزر القمر',
    code: 'KM',
    nicCode: '410',
    label: 'Comoros',
    phone: '269',
  },
  {
    id: 148,
    nameEn: 'South Africa',
    nameAr: 'جنوب أفريقيا',
    code: 'ZA',
    nicCode: '411',
    label: 'South Africa',
    phone: '27',
  },
  {
    id: 149,
    nameEn: 'Rwanda',
    nameAr: 'رواندا',
    code: 'RW',
    nicCode: '414',
    label: 'Rwanda',
    phone: '250',
  },
  {
    id: 150,
    nameEn: 'Zimbabwe',
    nameAr: 'زيمبابوي',
    code: 'ZW',
    nicCode: '415',
    label: 'Zimbabwe',
    phone: '263',
  },
  {
    id: 151,
    nameEn: 'Zambia',
    nameAr: 'زامبيا',
    code: 'ZM',
    nicCode: '417',
    label: 'Zambia',
    phone: '260',
  },
  {
    id: 152,
    nameEn: 'Sierra Leone',
    nameAr: 'سيراليون',
    code: 'SL',
    nicCode: '420',
    label: 'Sierra Leone',
    phone: '232',
  },
  {
    id: 153,
    nameEn: 'Ghana',
    nameAr: 'غانا',
    code: 'GH',
    nicCode: '421',
    label: 'Ghana',
    phone: '233',
  },
  {
    id: 154,
    nameEn: 'Guinea',
    nameAr: 'غينيا',
    code: 'GN',
    nicCode: '422',
    label: 'Guinea',
    phone: '224',
  },
  {
    id: 155,
    nameEn: 'Central African Republic',
    nameAr: 'جمهورية أفريقيا الوسطى',
    code: 'CF',
    nicCode: '437',
    label: 'Central African Republic',
    phone: '236',
  },
  {
    id: 156,
    nameEn: 'Equatorial Guinea',
    nameAr: 'غينيا الإستوائية',
    code: 'GQ',
    nicCode: '440',
    label: 'Equatorial Guinea',
    phone: '240',
  },
  {
    id: 157,
    nameEn: 'Seychelles',
    nameAr: 'سيشل',
    code: 'SC',
    nicCode: '443',
    label: 'Seychelles',
    phone: '248',
  },
  {
    id: 158,
    nameEn: 'Swaziland',
    nameAr: 'سوازيلاند',
    code: 'SZ',
    nicCode: '444',
    label: 'Swaziland',
    phone: '268',
  },
  {
    id: 160,
    nameEn: 'Romania',
    nameAr: 'رومانيا',
    code: 'RO',
    nicCode: '513',
    label: 'Romania',
    phone: '40',
  },
  {
    id: 161,
    nameEn: 'Switzerland',
    nameAr: 'سويسرا',
    code: 'CH',
    nicCode: '515',
    label: 'Switzerland',
    phone: '41',
  },
  {
    id: 162,
    nameEn: 'France',
    nameAr: 'فرنسا',
    code: 'FR',
    nicCode: '516',
    label: 'France',
    phone: '33',
  },
  {
    id: 163,
    nameEn: 'Finland',
    nameAr: 'فنلندا',
    code: 'FI',
    nicCode: '517',
    label: 'Finland',
    phone: '358',
  },
  {
    id: 164,
    nameEn: 'Serbia',
    nameAr: 'صربيا',
    code: 'RS',
    nicCode: '518',
    label: 'Serbia',
    phone: '381',
  },
  {
    id: 165,
    nameEn: 'San Marino',
    nameAr: 'سان مارينو',
    code: 'SM',
    nicCode: '532',
    label: 'San Marino',
    phone: '378',
  },
  {
    id: 166,
    nameEn: 'Gibraltar',
    nameAr: 'جبل طارق',
    code: 'GI',
    nicCode: '534',
    label: 'Gibraltar',
    phone: '350',
  },
  {
    id: 167,
    nameEn: 'Georgia',
    nameAr: 'جورجيا',
    code: 'GE',
    nicCode: '541',
    label: 'Georgia',
    phone: '995',
  },
  {
    id: 168,
    nameEn: 'Slovenia',
    nameAr: 'سلوفينيا',
    code: 'SI',
    nicCode: '547',
    label: 'Slovenia',
    phone: '386',
  },
  {
    id: 169,
    nameEn: 'Slovakia',
    nameAr: 'سلوفاكيا',
    code: 'SK',
    nicCode: '553',
    label: 'Slovakia',
    phone: '421',
  },
  {
    id: 170,
    nameEn: 'Faroe Islands',
    nameAr: 'جزر فارو',
    code: 'FO',
    nicCode: '554',
    label: 'Faroe Islands',
    phone: '298',
  },
  {
    id: 171,
    nameEn: 'Jamaica',
    nameAr: 'جامايكا',
    code: 'JM',
    nicCode: '607',
    label: 'Jamaica',
    phone: '1-876',
  },
  {
    id: 172,
    nameEn: 'Venezuela',
    nameAr: 'فنزويلا',
    code: 'VE',
    nicCode: '609',
    label: 'Venezuela',
    phone: '58',
  },
  {
    id: 173,
    nameEn: 'Dominica',
    nameAr: 'دومينيكا',
    code: 'DM',
    nicCode: '615',
    label: 'Dominica',
    phone: '1-767',
  },
  {
    id: 174,
    nameEn: 'Dominican Republic',
    nameAr: 'جمهورية الدومينيك',
    code: 'DO',
    nicCode: '616',
    label: 'Dominican Republic',
    phone: '1-809',
  },
  {
    id: 175,
    nameEn: 'Grenada',
    nameAr: 'غرينادا',
    code: 'GD',
    nicCode: '618',
    label: 'Grenada',
    phone: '1-473',
  },
  {
    id: 176,
    nameEn: 'Guatemala',
    nameAr: 'غواتيمالا',
    code: 'GT',
    nicCode: '619',
    label: 'Guatemala',
    phone: '502',
  },
  {
    id: 177,
    nameEn: 'Saint Vincent and the Grenadines',
    nameAr: 'سانت فنسنت وغرنادين',
    code: 'VC',
    nicCode: '625',
    label: 'Saint Vincent and the Grenadines',
    phone: '1-784',
  },
  {
    id: 178,
    nameEn: 'Chile',
    nameAr: 'شيلي',
    code: 'CL',
    nicCode: '627',
    label: 'Chile',
    phone: '56',
  },
  {
    id: 179,
    nameEn: 'New Caledonia',
    nameAr: 'كاليدونيا الجديدة',
    code: 'NC',
    nicCode: null,
    label: 'New Caledonia',
    phone: '687',
  },
  {
    id: 181,
    nameEn: 'Kosovo',
    nameAr: 'كوسوفو',
    code: 'XK',
    nicCode: null,
    label: 'Kosovo',
    phone: '383',
  },
  {
    id: 182,
    nameEn: 'Kiribati',
    nameAr: 'كيريباتي',
    code: 'KI',
    nicCode: null,
    label: 'Kiribati',
    phone: '686',
  },
  {
    id: 183,
    nameEn: 'Martinique',
    nameAr: 'مارتينيك',
    code: 'MQ',
    nicCode: null,
    label: 'Martinique',
    phone: '596',
  },
  {
    id: 184,
    nameEn: 'Mayotte',
    nameAr: 'مايوت',
    code: 'YT',
    nicCode: null,
    label: 'Mayotte',
    phone: '262',
  },
  {
    id: 185,
    nameEn: 'Madagascar',
    nameAr: 'مدغشقر',
    code: 'MG',
    nicCode: null,
    label: 'Madagascar',
    phone: '261',
  },
  {
    id: 186,
    nameEn: 'Montserrat',
    nameAr: 'مونتسرات',
    code: 'MS',
    nicCode: null,
    label: 'Montserrat',
    phone: '1-664',
  },
  {
    id: 187,
    nameEn: 'Micronesia',
    nameAr: 'ميكرونيزيا',
    code: 'FM',
    nicCode: null,
    label: 'Micronesia, Federated States of',
    phone: '691',
  },
  {
    id: 188,
    nameEn: 'Nauru',
    nameAr: 'ناورو',
    code: 'NR',
    nicCode: null,
    label: 'Nauru',
    phone: '674',
  },
  {
    id: 189,
    nameEn: 'Niue',
    nameAr: 'نيوي',
    code: 'NU',
    nicCode: null,
    label: 'Niue',
    phone: '683',
  },
  {
    id: 191,
    nameEn: 'United Arab Emirates',
    nameAr: 'الإمارات العربية المتحدة',
    code: 'AE',
    nicCode: '101',
    label: 'United Arab Emirates',
    phone: '971',
  },
  {
    id: 192,
    nameEn: 'Syria',
    nameAr: 'سوريا',
    code: 'SY',
    nicCode: '104',
    label: 'Syrian Arab Republic',
    phone: '963',
  },
  {
    id: 193,
    nameEn: 'Lebanon',
    nameAr: 'لبنان',
    code: 'LB',
    nicCode: '110',
    label: 'Lebanon',
    phone: '961',
  },
  {
    id: 194,
    nameEn: 'Yemen',
    nameAr: 'اليمن',
    code: 'YE',
    nicCode: '111',
    label: 'Yemen',
    phone: '967',
  },
  {
    id: 195,
    nameEn: 'Libya',
    nameAr: 'ليبيا',
    code: 'LY',
    nicCode: '206',
    label: 'Libya',
    phone: '218',
  },
  {
    id: 196,
    nameEn: 'Egypt',
    nameAr: 'مصر',
    code: 'EG',
    nicCode: '207',
    label: 'Egypt',
    phone: '20',
  },
  {
    id: 197,
    nameEn: 'Morocco',
    nameAr: 'المغرب',
    code: 'MA',
    nicCode: '208',
    label: 'Morocco',
    phone: '212',
  },
  {
    id: 198,
    nameEn: 'Mauritania',
    nameAr: 'موريتانيا',
    code: 'MR',
    nicCode: '209',
    label: 'Mauritania',
    phone: '222',
  },
  {
    id: 199,
    nameEn: 'Myanmar',
    nameAr: 'ميانمار',
    code: 'MM',
    nicCode: '307',
    label: 'Myanmar',
    phone: '95',
  },
  {
    id: 200,
    nameEn: 'Thailand',
    nameAr: 'تايلاند',
    code: 'TH',
    nicCode: '308',
    label: 'Thailand',
    phone: '66',
  },
  {
    id: 201,
    nameEn: 'Maldives',
    nameAr: 'جزر المالديف',
    code: 'MV',
    nicCode: '310',
    label: 'Maldives',
    phone: '960',
  },
  {
    id: 202,
    nameEn: 'Cambodia',
    nameAr: 'كمبوديا',
    code: 'KH',
    nicCode: '317',
    label: 'Cambodia',
    phone: '855',
  },
  {
    id: 203,
    nameEn: 'South Korea',
    nameAr: 'كوريا الجنوبية',
    code: 'KR',
    nicCode: '318',
    label: 'Korea, Republic of',
    phone: '82',
  },
  {
    id: 204,
    nameEn: 'Malaysia',
    nameAr: 'ماليزيا',
    code: 'MY',
    nicCode: '319',
    label: 'Malaysia',
    phone: '60',
  },
  {
    id: 205,
    nameEn: 'Nepal',
    nameAr: 'نيبال',
    code: 'NP',
    nicCode: '320',
    label: 'Nepal',
    phone: '977',
  },
  {
    id: 206,
    nameEn: 'Hong Kong',
    nameAr: 'هونغ كونغ الصينية',
    code: 'HK',
    nicCode: '322',
    label: 'Hong Kong',
    phone: '852',
  },
  {
    id: 207,
    nameEn: 'North Korea',
    nameAr: 'كوريا الشمالية',
    code: 'KP',
    nicCode: '328',
    label: "Korea, Democratic People's Republic of",
    phone: '850',
  },
  {
    id: 208,
    nameEn: 'Laos',
    nameAr: 'لاوس',
    code: 'LA',
    nicCode: '329',
    label: "Lao People's Democratic Republic",
    phone: '856',
  },
  {
    id: 209,
    nameEn: 'Mongolia',
    nameAr: 'منغوليا',
    code: 'MN',
    nicCode: '330',
    label: 'Mongolia',
    phone: '976',
  },
  {
    id: 211,
    nameEn: 'Kazakhstan',
    nameAr: 'كازاخستان',
    code: 'KZ',
    nicCode: '336',
    label: 'Kazakhstan',
    phone: '7',
  },
  {
    id: 212,
    nameEn: 'Uzbekistan',
    nameAr: 'أوزبكستان',
    code: 'UZ',
    nicCode: '337',
    label: 'Uzbekistan',
    phone: '998',
  },
  {
    id: 213,
    nameEn: 'Timor-Leste',
    nameAr: 'تيمور الشرقية',
    code: 'TL',
    nicCode: '349',
    label: 'Timor-Leste',
    phone: '670',
  },
  {
    id: 214,
    nameEn: 'Tanzania',
    nameAr: 'تانزانيا',
    code: 'TZ',
    nicCode: '406',
    label: 'United Republic of Tanzania',
    phone: '255',
  },
  {
    id: 215,
    nameEn: 'Namibia',
    nameAr: 'ناميبيا',
    code: 'NA',
    nicCode: '412',
    label: 'Namibia',
    phone: '264',
  },
  {
    id: 216,
    nameEn: 'Guinea-Bissau',
    nameAr: 'غينيا بيساو',
    code: 'GW',
    nicCode: '423',
    label: 'Guinea-Bissau',
    phone: '245',
  },
  {
    id: 217,
    nameEn: 'Burkina Faso',
    nameAr: 'بوركينا فاسو',
    code: 'BF',
    nicCode: '424',
    label: 'Burkina Faso',
    phone: '226',
  },
  {
    id: 218,
    nameEn: 'Kenya',
    nameAr: 'كينيا',
    code: 'KE',
    nicCode: '427',
    label: 'Kenya',
    phone: '254',
  },
  {
    id: 219,
    nameEn: 'Lesotho',
    nameAr: 'ليسوتو',
    code: 'LS',
    nicCode: '428',
    label: 'Lesotho',
    phone: '266',
  },
  {
    id: 220,
    nameEn: 'Liberia',
    nameAr: 'ليبيريا',
    code: 'LR',
    nicCode: '429',
    label: 'Liberia',
    phone: '231',
  },
  {
    id: 221,
    nameEn: 'Mali',
    nameAr: 'مالي',
    code: 'ML',
    nicCode: '430',
    label: 'Mali',
    phone: '223',
  },
  {
    id: 222,
    nameEn: 'Malawi',
    nameAr: 'ملاوي',
    code: 'MW',
    nicCode: '432',
    label: 'Malawi',
    phone: '265',
  },
  {
    id: 223,
    nameEn: 'Mauritius',
    nameAr: 'موريشيوس',
    code: 'MU',
    nicCode: '433',
    label: 'Mauritius',
    phone: '230',
  },
  {
    id: 224,
    nameEn: 'Mozambique',
    nameAr: 'موزمبيق',
    code: 'MZ',
    nicCode: '434',
    label: 'Mozambique',
    phone: '258',
  },
  {
    id: 225,
    nameEn: 'Nigeria',
    nameAr: 'نيجيريا',
    code: 'NG',
    nicCode: '436',
    label: 'Nigeria',
    phone: '234',
  },
  {
    id: 227,
    nameEn: 'Ireland',
    nameAr: 'أيرلندا',
    code: 'IE',
    nicCode: '504',
    label: 'Ireland',
    phone: '353',
  },
  {
    id: 228,
    nameEn: 'Denmark',
    nameAr: 'الدانمرك',
    code: 'DK',
    nicCode: '512',
    label: 'Denmark',
    phone: '45',
  },
  {
    id: 229,
    nameEn: 'Netherlands',
    nameAr: 'هولندا',
    code: 'NL',
    nicCode: '519',
    label: 'Netherlands',
    phone: '31',
  },
  {
    id: 230,
    nameEn: 'Hungary',
    nameAr: 'هنغاريا',
    code: 'HU',
    nicCode: '525',
    label: 'Hungary',
    phone: '36',
  },
  {
    id: 231,
    nameEn: 'Liechtenstein',
    nameAr: 'ليختنشتاين',
    code: 'LI',
    nicCode: '527',
    label: 'Liechtenstein',
    phone: '423',
  },
  {
    id: 232,
    nameEn: 'Luxembourg',
    nameAr: 'لوكسمبورغ',
    code: 'LU',
    nicCode: '528',
    label: 'Luxembourg',
    phone: '352',
  },
  {
    id: 233,
    nameEn: 'Malta',
    nameAr: 'مالطا',
    code: 'MT',
    nicCode: '529',
    label: 'Malta',
    phone: '356',
  },
  {
    id: 234,
    nameEn: 'Monaco',
    nameAr: 'موناكو',
    code: 'MC',
    nicCode: '530',
    label: 'Monaco',
    phone: '377',
  },
  {
    id: 235,
    nameEn: 'Norway',
    nameAr: 'النرويج',
    code: 'NO',
    nicCode: '531',
    label: 'Norway',
    phone: '47',
  },
  {
    id: 236,
    nameEn: 'Moldova',
    nameAr: 'مولدافيا',
    code: 'MD',
    nicCode: '540',
    label: 'Moldova, Republic of',
    phone: '373',
  },
  {
    id: 237,
    nameEn: 'Lithuania',
    nameAr: 'ليتوانيا',
    code: 'LT',
    nicCode: '542',
    label: 'Lithuania',
    phone: '370',
  },
  {
    id: 238,
    nameEn: 'Latvia',
    nameAr: 'لاتفيا',
    code: 'LV',
    nicCode: '544',
    label: 'Latvia',
    phone: '371',
  },
  {
    id: 239,
    nameEn: 'Bosnia and Herzegovina',
    nameAr: 'البوسنة والهرسك',
    code: 'BA',
    nicCode: '545',
    label: 'Bosnia and Herzegovina',
    phone: '387',
  },
  {
    id: 240,
    nameEn: 'Croatia',
    nameAr: 'كرواتيا',
    code: 'HR',
    nicCode: '546',
    label: 'Croatia',
    phone: '385',
  },
  {
    id: 241,
    nameEn: 'Macedonia',
    nameAr: 'مقدونيا',
    code: 'MK',
    nicCode: '549',
    label: 'Macedonia, the Former Yugoslav Republic of',
    phone: '389',
  },
  {
    id: 242,
    nameEn: 'Czech Republic',
    nameAr: 'جمهورية التشيك',
    code: 'CZ',
    nicCode: '552',
    label: 'Czech Republic',
    phone: '420',
  },
  {
    id: 243,
    nameEn: 'Canada',
    nameAr: 'كندا',
    code: 'CA',
    nicCode: '610',
    label: 'Canada',
    phone: '1',
  },
  {
    id: 244,
    nameEn: 'Colombia',
    nameAr: 'كولومبيا',
    code: 'CO',
    nicCode: '611',
    label: 'Colombia',
    phone: '57',
  },
  {
    id: 245,
    nameEn: 'Costa Rica',
    nameAr: 'كوستاريكا',
    code: 'CR',
    nicCode: '613',
    label: 'Costa Rica',
    phone: '506',
  },
  {
    id: 246,
    nameEn: 'Cuba',
    nameAr: 'كوبا',
    code: 'CU',
    nicCode: '614',
    label: 'Cuba',
    phone: '53',
  },
  {
    id: 247,
    nameEn: 'Haiti',
    nameAr: 'هايتي',
    code: 'HT',
    nicCode: '620',
    label: 'Haiti',
    phone: '509',
  },
  {
    id: 248,
    nameEn: 'Honduras',
    nameAr: 'هندوراس',
    code: 'HN',
    nicCode: '621',
    label: 'Honduras',
    phone: '504',
  },
  {
    id: 249,
    nameEn: 'Nicaragua',
    nameAr: 'نيكاراغوا',
    code: 'NI',
    nicCode: '623',
    label: 'Nicaragua',
    phone: '505',
  },
  {
    id: 250,
    nameEn: 'Saint Lucia',
    nameAr: 'سانت لوسيا',
    code: 'LC',
    nicCode: '624',
    label: 'Saint Lucia',
    phone: '1-758',
  },
  {
    id: 251,
    nameEn: 'Paraguay',
    nameAr: 'باراغواي',
    code: 'PY',
    nicCode: '629',
    label: 'Paraguay',
    phone: '595',
  },
  {
    id: 252,
    nameEn: 'New Zealand',
    nameAr: 'نيوزيلاندا',
    code: 'NZ',
    nicCode: '702',
    label: 'New Zealand',
    phone: '64',
  },
  {
    id: 261,
    nameEn: 'Congo',
    nameAr: 'الكونغو',
    code: 'CG',
    nicCode: '426',
    label: 'Congo',
    phone: '242',
  },
];

export default countries;
