import React from 'react';

import { useTheme } from '@mui/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useLocale } from 'util/hooks';
import { useProfileCourses, useProfilePoints } from 'reactQuery/queries';
import { POINT_TYPES } from 'assets/constants/points';
import { PointsWithShadowIcon } from 'assets/icons';

import { Divider } from '@mui/material';
import EmptyCoursesPlaceholder from './sections/EmptyCoursesPlaceholder';

const PREVIEW_LIMIT = 2;

const ProfileCourses = () => {
  const { t } = useLocale();
  const theme = useTheme();
  const [expanded, setExpanded] = React.useState(false);

  const {
    data = {},
    isFetched = false,
  } = useProfileCourses();
  const { items: courses = [] } = data;
  const { data: { totalPoints: coursePoints = 0 } = {} } = useProfilePoints({
    queryKeyOptions: {
      searchOptions: {
        type: POINT_TYPES.COMPLETE_COURSE_SESSION,
      },
    },
    options: {
      suspense: false, // prevent points from holding render
    },
  });

  const totalCourses = courses?.length || 0;
  const isEmpty = !totalCourses && isFetched;

  const {
    previewList = [],
    remainingList = [],
  } = React.useMemo(() => {
    const all = courses?.map((course, index) => {
      const isLastElement = index === courses.length - 1;

      return (
        <Box
          key={course.id}
          sx={{ px: 3.25 }}
        >
          <Stack spacing={2} direction="row" alignItems="center">
            <Avatar
              src={course.provider?.logo}
              sx={{
                width: 50,
                height: 50,
                boxShadow: ({ palette }) => `0 4px 4px 0px ${palette.common.dropShadow}`,
              }}
            />
            <Stack>
              <Typography variant="bodySmallMedium">
                {course.title}
              </Typography>
              <Typography variant="bodyMicroRegular">
                {course.provider?.name}
              </Typography>
            </Stack>
          </Stack>
          {!isLastElement && <Divider sx={{ my: 8 }} />}
        </Box>
      );
    });

    const preview = all?.slice(0, PREVIEW_LIMIT);
    const remaining = all?.slice(PREVIEW_LIMIT);

    return {
      allList: all,
      previewList: preview,
      remainingList: remaining,
    };
  }, [courses]);

  return (
    <Card sx={{ p: 5, borderRadius: 0 }}>
      <Stack spacing={5}>
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        >
          <Box>
            <Typography variant="h6">
              {t('common.attendedCourses')}
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="bodySmallRegular"
              color="common.darkGray"
            >
              {`${totalCourses} ${t('common.coursesNoThe')}`}
            </Typography>
          </Box>
        </Box>
        {isEmpty
          ? <EmptyCoursesPlaceholder />
          : (
            <>
              <Box>
                <Stack spacing={1}>
                  <Typography variant="bodySmallMedium">
                    {t('common.points')}
                  </Typography>
                  <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                  >
                    <PointsWithShadowIcon
                      width="20"
                      height="20"
                      innerFill={theme.palette.common.vividOrange}
                      rectFill={theme.palette.common.vividOrange}
                    />
                    <Typography variant="bodyStandardRegular">
                      {coursePoints || 0}
                    </Typography>
                  </Stack>
                </Stack>
              </Box>
              <Box>
                {previewList}
                {remainingList?.length
                  ? (
                    <Box>
                      <Collapse in={expanded} timeout="auto" unmountOnExit>
                        {remainingList}
                      </Collapse>
                      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                        <Button
                          onClick={() => setExpanded((prev) => !prev)}
                          sx={{ color: 'common.softBlue' }}
                          endIcon={(
                            <ExpandMoreIcon
                              sx={{
                                transform: !expanded ? 'rotate(0deg)' : 'rotate(180deg)',
                                transition: ({ transitions }) => transitions.create('transform', {
                                  duration: transitions.duration.shortest,
                                }),
                              }}
                            />
                          )}
                        >
                          {expanded
                            ? t('common.showLess')
                            : t('common.showAll')}
                        </Button>
                      </Box>
                    </Box>
                  )
                  : null}
              </Box>
            </>
          )}
      </Stack>
    </Card>
  );
};

export default ProfileCourses;
