import END_POINTS from '../../endPoints';
import METHODS from '../../methods';
import api from '../../api';

const toggleCertificateVisibility = (id) => api(
  END_POINTS.toggleCertificateVisibility(id),
  {
    method: METHODS.PUT,
  },
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default toggleCertificateVisibility;
