import { atom } from 'recoil';

const verifiedEmailAtom = atom({
  key: 'verifiedEmail',
  default: {
    email: '',
    fullEmail: '',
  },
});

export default verifiedEmailAtom;
