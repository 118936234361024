import { useParams } from 'react-router-dom';
import { getProfilePostStats } from 'services';
import { useCustomQuery } from 'reactQuery';

const useProfilePostStats = (queryProps = {}) => {
  const { username } = useParams();

  const query = useCustomQuery({
    queryKey: [`profile-posts-stats-${username}`],
    queryFn: () => getProfilePostStats(username),
    ...queryProps,
  });

  return { ...query };
};

export default useProfilePostStats;
