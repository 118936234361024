import END_POINTS from '../../endPoints';
import api from '../../api';

const getInterestDetails = (id) => api(
  END_POINTS.interest(id),
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default getInterestDetails;
