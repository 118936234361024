import { memo } from 'react';
import { useController } from 'react-hook-form';
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
} from '@mui/material';
import { Select } from 'components/molecules';

const FormSelect = (props) => {
  const {
    label = '',
    icon = null,
    ...rest
  } = props;

  const { field, fieldState } = useController(rest);
  const { onChange } = field;
  const { error } = fieldState;

  const hasErrored = Boolean(error);

  const onFieldChange = ({ target: { value: newValue = '' } }) => {
    onChange(newValue);
  };

  const textLabel = icon
    ? (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {icon}
        <Box sx={{ pl: 2 }}>
          {label}
        </Box>
      </Box>
    )
    : label;

  return (
    <FormControl
      sx={{ width: 1 }}
      error={hasErrored}
    >
      <InputLabel>{textLabel}</InputLabel>
      <Select
        {...field}
        {...rest}
        onChange={onFieldChange}
        error={hasErrored}
        label={textLabel}
      />
      {hasErrored && <FormHelperText>{error?.message}</FormHelperText>}
    </FormControl>
  );
};

export default memo(FormSelect);
