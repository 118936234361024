import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

const LandingLinkWrapper = ({ innerText = '', sx = {}, ...rest }) => (
  <Link
    sx={{
      alignSelf: 'center',
      justifyContent: 'end',
      pt: 5,
      display: 'flex',
      ...sx,
    }}
    {...rest}
  >
    <Typography variant="bodyStandardMedium" color="common.softBlue">
      {innerText}
    </Typography>
  </Link>
);

export default LandingLinkWrapper;
