import {
  Avatar, Typography, Box, Link,
} from '@mui/material';
import { AUTH_ROLES } from 'assets/constants/userRoles';
import PropTypes from 'prop-types';
import {
  capitalizeFirstLetter,
} from 'util/helpers';
import { useLocale } from 'util/hooks';

const UserAvatarGroup = (props) => {
  const {
    avatar,
    name,
    clubName,
    username,
    role,
    withProfileLink,
    avatarProps,
  } = props;

  const { t, isAr } = useLocale();

  const isStudent = role === AUTH_ROLES.student
    || role === AUTH_ROLES.member
    || role === AUTH_ROLES.leader;
  const linkToProfile = withProfileLink && isStudent;

  const handleClick = linkToProfile
    ? (e) => e.stopPropagation() : undefined;
  const wrapperComponent = linkToProfile
    ? Link : 'div';
  const to = linkToProfile
    ? `/profile/${username}` : undefined;

  return (
    <span
      onClick={handleClick}
      aria-hidden="true"
    >
      <Box
        component={wrapperComponent}
        to={to}
        sx={{
          display: 'flex',
          alignItems: 'center',
          color: 'common.darkGray',
        }}
      >
        <Avatar
          {...avatarProps}
          sx={{
            width: {
              xs: 40,
              sm: 48,
            },
            height: {
              xs: 40,
              sm: 48,
            },
            ...avatarProps?.sx,
          }}
          src={avatar}
          alt={capitalizeFirstLetter(name)}
        />
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          pl: 3,
        }}
        >
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
          >
            <Typography variant="bodySmallMedium">
              {name}
            </Typography>
          </Box>
          <Typography variant="bodyMicroRegular">
            {clubName
              && (
                <>
                  {isAr
                    ? `${t('common.studentClub')} ${clubName}`
                    : `${clubName} ${t('common.studentClub')}`}
                  &nbsp;
                  -
                  &nbsp;
                </>
              )}
            {t(`roles.${role}`)}
          </Typography>
        </Box>
      </Box>
    </span>
  );
};

UserAvatarGroup.propTypes = {
  avatar: PropTypes.string,
  name: PropTypes.string,
  clubName: PropTypes.string,
  role: PropTypes.string,
  username: PropTypes.string,
  withProfileLink: PropTypes.bool,
  avatarProps: PropTypes.oneOfType([PropTypes.object]),
};

UserAvatarGroup.defaultProps = {
  avatar: ' ',
  name: '',
  clubName: '',
  role: '',
  username: '',
  withProfileLink: true,
  avatarProps: {},
};

export default UserAvatarGroup;
