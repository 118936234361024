import END_POINTS from 'services/endPoints';
import api from 'services/api';

const getSessionDetails = (courseId, sessionId) => api(
  END_POINTS.getSession(courseId, sessionId),
)
  .then((response) => Promise.resolve(response))
  .catch((error) => Promise.reject(error));

export default getSessionDetails;
