import { useTheme } from '@mui/styles';

const NoPostsIcon = (props) => {
  const { palette } = useTheme();

  const {
    fill = 'none',
    stroke = palette.common.berylliumGrey,
    circleFill = palette.common.vividOrange,
  } = props;

  return (
    <svg width="148" height="136" viewBox="0 0 148 136" fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M26.9062 54.9062H71.9063" stroke={stroke} strokeWidth="10.9167" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M25.9062 78.9062H70.9063" stroke={stroke} strokeWidth="10.9167" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M26.9062 101.906H52.9063" stroke={stroke} strokeWidth="10.9167" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M93.6055 130H14.1818C12.0119 130 9.93079 129.125 8.3964 127.567C6.86201 126.009 6 123.896 6 121.692V30.3077C6 28.1044 6.86201 25.9913 8.3964 24.4333C9.93079 22.8753 12.0119 22 14.1818 22H72.1582C74.3266 22.0005 76.4062 22.875 77.94 24.4314L93.6055 40.3378C95.1383 41.8953 95.9995 44.0068 96 46.2086V130" stroke={stroke} strokeWidth="10.9167" strokeLinecap="round" strokeLinejoin="round" />
      <circle cx="92" cy="56" r="56" fill={circleFill} fillOpacity="0.2" />
    </svg>
  );
};

export default NoPostsIcon;
