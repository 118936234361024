import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Tabs } from 'components/molecules';
import {
  ClubsLeaderboardContainer, StudentsLeaderboardContainer,
} from 'components/organisms';
import { PATHS } from 'routes';
import { useLocale } from 'util/hooks';
import LandingLinkWrapper from '../helpers/LandingLinkWrapper';

const LandingLeaderboard = () => {
  const { t } = useLocale();

  const tabs = [{
    label: t('common.students'),
    component: <StudentsLeaderboardContainer isAbstract />,
  },
  {
    label: t('common.clubs'),
    component: <ClubsLeaderboardContainer isAbstract />,
  },
  ];

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
    }}
    >
      <Box sx={{
        pt: { xs: 6, sm: 7.5 },
        width: 1,
        justifyContent: 'center',
      }}
      >
        <Box
          sx={{
            mb: { xs: 4, sm: 6 },
            width: 1,
          }}
        >
          <Typography
            variant="h4"
          >
            {t('common.leaderboard')}
          </Typography>
        </Box>
        <Box>
          <Tabs tabs={tabs} sx={{ mb: 4 }} />
        </Box>
      </Box>

      <LandingLinkWrapper to={PATHS.leaderboard} innerText={t('common.exploreMore')} />
    </Box>
  );
};

export default LandingLeaderboard;
