import { getCourseProviders } from 'services';
import { useCustomQuery } from 'reactQuery';

const useCourseProviders = (queryProps = {}) => {
  const { queryKeyOptions = {}, ...rest } = queryProps;

  const query = useCustomQuery({
    queryKey: ['course-providers', queryKeyOptions],
    queryFn: getCourseProviders,
    ...rest,
  });

  return { ...query };
};

export default useCourseProviders;
