import { Button, Box } from '@mui/material';
import { LogoutIcon } from 'assets/icons';
import { useAuth, useLocale } from 'util/hooks';

const LogoutButton = () => {
  const { logout } = useAuth();
  const { t } = useLocale();

  return (
    <Button
      disableRipple
      onClick={logout}
      sx={(theme) => ({
        ...theme.typography.bodyStandardRegular,
        p: 0,
        color: 'common.pinkishRed',
        '&.MuiButton-text': {
          '&:hover': {
            backgroundColor: 'transparent',
            color: 'common.pinkishRed',
          },
        },
      })}
    >
      <Box sx={{
        mr: 2,
        display: 'flex',
        alignItems: 'center',
      }}
      >
        <LogoutIcon />
      </Box>
      {t('common.logout')}
    </Button>
  );
};

export default LogoutButton;
