import { getCourseRecordings } from 'services';
import { useCustomQuery } from 'reactQuery';

const useCourseRecordings = (props = {}) => {
  const { courseId, ...rest } = props;

  const query = useCustomQuery({
    queryKey: [`course-recordings-${courseId}`],
    queryFn: () => getCourseRecordings(courseId),
    ...rest,
  });

  return { ...query };
};

export default useCourseRecordings;
