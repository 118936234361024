import { useTheme } from '@mui/styles';

const NoSessionsIcon = (props) => {
  const { palette } = useTheme();

  const {
    fill = 'none',
    stroke = palette.common.berylliumGrey,
    circleFill = palette.common.vividOrange,
    rectFill = palette.common.white,
  } = props;

  return (
    <svg width="139" height="130" viewBox="0 0 139 130" fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="82.7261" cy="56.4316" r="56" fill={circleFill} fillOpacity="0.2" />
      <mask id="path-2-inside-1_5400_144719" fill={rectFill}>
        <rect x="0.273438" y="44.8633" width="113.906" height="18.2249" rx="2.27811" />
      </mask>
      <rect x="0.273438" y="44.8633" width="113.906" height="18.2249" rx="2.27811" stroke={stroke} strokeWidth="10" mask="url(#path-2-inside-1_5400_144719)" />
      <path d="M12.5 60.5H103.181V126.122H12.5V60.5Z" stroke={stroke} strokeWidth="5" />
      <line x1="0.273437" y1="126.931" x2="114.179" y2="126.931" stroke={stroke} strokeWidth="5" />
      <path d="M68.3661 95.4025L68.4233 95.3019L68.393 95.4202C68.5978 95.5327 68.7687 95.6981 68.8877 95.8993C69.0067 96.1004 69.0694 96.3298 69.0694 96.5635C69.0694 96.7972 69.0067 97.0266 68.8877 97.2277C68.7687 97.4288 68.5978 97.5943 68.393 97.7068L68.3769 97.7157L68.3609 97.7247L48.5646 108.979C48.3165 109.112 48.0398 109.184 47.758 109.188C47.4745 109.191 47.1944 109.126 46.9416 108.998C46.728 108.884 46.5488 108.715 46.4228 108.508C46.2962 108.3 46.2282 108.062 46.2261 107.818V85.3136C46.2282 85.07 46.2962 84.8316 46.4228 84.6235C46.5503 84.414 46.7324 84.2432 46.9497 84.1294L46.9497 84.1294L46.9576 84.1252C47.2045 83.9947 47.4801 83.9282 47.7594 83.9318C48.037 83.9353 48.3093 84.008 48.5517 84.1432L68.3661 95.4025Z" stroke={stroke} strokeWidth="5" />
    </svg>
  );
};
export default NoSessionsIcon;
