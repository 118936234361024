import PropTypes from 'prop-types';
import { useController } from 'react-hook-form';
import { DatePicker } from 'components/molecules';

const FormDatePicker = (props) => {
  const {
    helperText,
    InputAdornmentProps = {},
    pickerProps = {},
    inputProps = {},
    ...rest
  } = props;

  const { field, fieldState } = useController(rest);
  const { onChange } = field;
  const { error } = fieldState;

  const hasErrored = Boolean(error);

  const onFieldChange = (newValue = '') => onChange(newValue);

  return (
    <DatePicker
      pickerProps={{
        ...pickerProps,
        ...field,
        onChange: onFieldChange,
        InputAdornmentProps,
      }}
      inputProps={{
        error: hasErrored,
        helperText: (hasErrored && error?.message) || helperText,
        ...inputProps,
      }}
    />
  );
};

FormDatePicker.propTypes = {
  helperText: PropTypes.string,
};

FormDatePicker.defaultProps = {
  helperText: '',
};
export default FormDatePicker;
