import END_POINTS from '../endPoints';
import METHODS from '../methods';
import api from '../api';

const registerToCourse = (id) => api(
  END_POINTS.registerToCourse(id),
  {
    method: METHODS.POST,
  },
)
  .then((response) => Promise.resolve(response))
  .catch((error) => Promise.reject(error));

export default registerToCourse;
