import { Box, Typography, useMediaQuery } from '@mui/material';
import { KsaMap } from 'assets/maps';
import _ from 'lodash';

const Point = ({
  count = 0,
  sx = {},
  ...rest
}) => {
  const isMobileView = useMediaQuery(({ breakpoints }) => breakpoints.down('sm'));
  const size = isMobileView ? 25 : 37;
  const variant = isMobileView ? 'bodyMicroMedium' : 'bodyStandardMedium';

  return (
    <Box
      sx={{
        height: size,
        width: size,
        bgcolor: 'common.white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '100%',
        boxShadow: ({ palette: { common } }) => `
          0px 0px 0px white,
          -4px 0px 0px ${common.offWhiteTransparent},
          -8px 0px 0px ${common.offWhiteTransparent}`,
        ...sx,
      }}
      {...rest}
    >
      <Typography variant={variant}>
        {count}
      </Typography>
    </Box>
  );
};

const KsaInteractiveMap = ({
  sx = {},
  regions = [],
  selectedRegion,
  updateSelectedRegion,
  ...rest
}) => (
  <Box
    dir="ltr"
    sx={{
      position: 'relative',
      width: { xs: '100%', sm: 550 },
      maxWidth: { xs: 430, sm: 550 },
      ...sx,
    }}
    {...rest}
  >
    <KsaMap selectedRegion={selectedRegion} regions={regions} />

    {
        _.toArray(regions)?.map((region) => (
          <Point
            key={region.id}
            count={region.count}
            sx={{
              position: 'absolute',
              inset: region.position,
              cursor: 'pointer',
            }}
            onClick={() => updateSelectedRegion(region)}
          />
        ))
      }
  </Box>
);
export default KsaInteractiveMap;
