import END_POINTS from 'services/endPoints';
import api from 'services/api';

const discord = () => api(
  END_POINTS.discord,
)
  .then((response) => Promise.resolve(response))
  .catch((error) => Promise.reject(error));

export default discord;
