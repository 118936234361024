import {
  Box,
} from '@mui/material';
import { NoCoursesIcon } from 'assets/icons';

import { DataPlaceholder, UrlPagination } from 'components/molecules';
import { PublicCoursesList } from 'components/organisms';
import { useMyCourses } from 'reactQuery/queries';
import { useLocale } from 'util/hooks';

const MyCoursesContainer = () => {
  const { t } = useLocale();

  const {
    data = {},
    isFetched = false,
  } = useMyCourses();

  const {
    items: courses = [],
    meta = {},
  } = data || {};

  if (isFetched && !courses?.length) {
    return (
      <DataPlaceholder
        icon={<NoCoursesIcon />}
        header={t('courses.myCoursesPlaceholder')}
        description={t('courses.myCoursesPlaceholderContent')}
      />
    );
  }

  return (
    <Box sx={{
      p: 5,
      mt: 5,
      py: 10,
      borderRadius: 1,
      display: 'flex',
      flexDirection: 'column',
      width: 1,
      minWidth: 'fit-content',
      backgroundColor: 'common.offWhite',
      boxShadow: (theme) => `0px 4px 4px ${theme.palette.common.boxShadow}`,
    }}
    >
      <PublicCoursesList courses={courses} />
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
      }}
      >
        <UrlPagination count={meta?.totalPages} />
      </Box>
    </Box>
  );
};

export default MyCoursesContainer;
