import { Link } from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid';

import { PATHS } from 'routes';
import { Status } from 'components/molecules';
import { useLocale, useModal } from 'util/hooks';
import MODAL_KEYS from 'assets/constants/modalKeys';
import TABLE_ACTIONS from 'assets/constants/tableActions';
import PLATFORM_SETTINGS from 'assets/constants/platformSettings';

const useTableColumns = (props = {}) => {
  const { refetchTopics = () => { } } = props;

  const { t, dir, isAr } = useLocale();
  const { addModal } = useModal();

  const handleOpenModal = (
    settingType,
    actionType,
    detailsProps,
  ) => {
    addModal({
      key: MODAL_KEYS.platformSettings,
      props: {
        settingType,
        actionType,
        detailsProps,
        refetch: refetchTopics,
      },
    });
  };

  const columns = [
    {
      field: isAr ? 'nameAr' : 'nameEn',
      headerName: t('topics.table.topic'),
      flex: 1,
      minWidth: 350,
    },
    {
      field: 'numberOfUses',
      headerName: t('topics.table.numberOfUses'),
      flex: 1,
      minWidth: 130,
      valueGetter: (params) => params?.row?.numberOfUses || 0,
    },
    {
      field: 'visibility',
      headerName: t('topics.table.status'),
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        const isVisible = params?.row?.visibility;

        return (
          <Status
            size="small"
            status={(isVisible
              ? 'visible'
              : 'hidden') || ''}
            label={isVisible
              ? t('statuses.visible')
              : t('statuses.hidden')}
          />
        );
      },
    },
    {
      field: 'actions',
      headerName: t('common.actions'),
      type: 'actions',
      flex: 1,
      minWidth: 100,
      getActions: ({ row }) => {
        const { visibility, isUsed, id } = row;
        const isVisible = visibility === true;
        const isUnusedTopic = isUsed === false;

        const actions = [
          <GridActionsCellItem
            component={Link}
            dir={dir}
            label={t('actions.edit')}
            to={`/${PATHS.platformSettings}/${PATHS.topicsSettings}/edit/${id}`}
            sx={{ minWidth: 167 }}
            showInMenu
          />,
        ];

        if (isVisible) {
          actions.push(<GridActionsCellItem
            dir={dir}
            label={t('actions.hide')}
            onClick={() => handleOpenModal(
              PLATFORM_SETTINGS.topics,
              TABLE_ACTIONS.platformSettings.hide,
              { ...row, name: isAr ? row.nameAr : row.nameEn },
            )}
            showInMenu
          />);
        } else {
          actions.push(<GridActionsCellItem
            dir={dir}
            label={t('actions.makeVisible')}
            onClick={() => handleOpenModal(
              PLATFORM_SETTINGS.topics,
              TABLE_ACTIONS.platformSettings.makeVisible,
              { ...row, name: isAr ? row.nameAr : row.nameEn },
            )}
            showInMenu
          />);
        }

        if (isUnusedTopic) {
          actions.push(
            <GridActionsCellItem
              dir={dir}
              label={t('actions.delete')}
              onClick={() => handleOpenModal(
                PLATFORM_SETTINGS.topics,
                TABLE_ACTIONS.platformSettings.delete,
                { ...row, name: isAr ? row.nameAr : row.nameEn },
              )}
              showInMenu
            />,
          );
        }

        return actions;
      },
    },
  ];

  return columns;
};

export default useTableColumns;
