import './style/app.css';
import 'locales/i18n';
import { useEffect } from 'react';

import {
  AppHelpers,
  MUIProvider,
  ReactQueryProvider,
  RecoilProvider,
} from 'components/app';
import { Router } from 'routes';

const App = () => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      // eslint-disable-next-line no-console
      console.log(
        `%c
  
  ░█████╗░██╗░░░██╗██████╗░███████╗██████╗░██╗░░██╗██╗░░░██╗██████╗░
  ██╔══██╗╚██╗░██╔╝██╔══██╗██╔════╝██╔══██╗██║░░██║██║░░░██║██╔══██╗
  ██║░░╚═╝░╚████╔╝░██████╦╝█████╗░░██████╔╝███████║██║░░░██║██████╦╝
  ██║░░██╗░░╚██╔╝░░██╔══██╗██╔══╝░░██╔══██╗██╔══██║██║░░░██║██╔══██╗
  ╚█████╔╝░░░██║░░░██████╦╝███████╗██║░░██║██║░░██║╚██████╔╝██████╦╝
  ░╚════╝░░░░╚═╝░░░╚═════╝░╚══════╝╚═╝░░╚═╝╚═╝░░╚═╝░╚═════╝░╚═════╝░
    
  👀
    `,
        'font-family:monospace;color:#78D49D;font-size:10px;',
      );
    }
  }, []);

  return (
    <ReactQueryProvider>
      <RecoilProvider>
        <MUIProvider>
          <AppHelpers>
            <Router />
          </AppHelpers>
        </MUIProvider>
      </RecoilProvider>
    </ReactQueryProvider>
  );
};

export default App;
