import React from 'react';
import Modal from '@mui/material/Modal';

import { useLocale, useModal } from 'util/hooks';
import MODAL_KEYS from 'assets/constants/modalKeys';

import SuspenseWrapper from './SuspenseWrapper/SuspenseWrapper';

const MODALS = {
  [MODAL_KEYS.userStatus]: React.lazy(() => import('components/modals/UserStatusModal/UserStatusModal')),
  [MODAL_KEYS.userInvitation]: React.lazy(() => import('components/modals/InvitationModal/InvitationModal')),
  [MODAL_KEYS.platformSettings]: React.lazy(() => import('components/modals/PlatformSettingsModal/PlatformSettingsModal')),
  [MODAL_KEYS.joinUs]: React.lazy(() => import('components/modals/JoinUsModal/JoinUsModal')),
  [MODAL_KEYS.studentManagement]: React.lazy(() => import('components/modals/StudentManagementModal/StudentManagementModal')),
  [MODAL_KEYS.studentCourseActions]: React.lazy(() => import('components/modals/StudentCourseActionsModal/StudentCourseActionsModal')),
  [MODAL_KEYS.courseAction]: React.lazy(() => import('components/modals/CourseActionModal/CourseActionModal')),
  [MODAL_KEYS.courseAttendanceAction]: React.lazy(() => import('components/modals/CourseAttendanceModal/CourseAttendanceModal')),
  [MODAL_KEYS.rejectPost]: React.lazy(() => import('components/modals/RejectPostModal/RejectPostModal')),
  [MODAL_KEYS.revisePost]: React.lazy(() => import('components/modals/RevisePostModal/RevisePostModal')),
  [MODAL_KEYS.approvePost]: React.lazy(() => import('components/modals/ApprovePostModal/ApprovePostModal')),
  [MODAL_KEYS.sessionDetails]: React.lazy(() => import('components/modals/SessionDetailsModal/SessionDetailsModal')),
  [MODAL_KEYS.deleteSession]: React.lazy(() => import('components/modals/DeleteSessionModal/DeleteSessionModal')),
  [MODAL_KEYS.updateSessionRecording]: React.lazy(() => import('components/modals/UpdateSessionRecordingModal/UpdateSessionRecordingModal')),
  [MODAL_KEYS.confirmAddUserCertificate]: React.lazy(() => import('components/modals/ConfirmAddCertificateModal/ConfirmAddCertificateModal')),
  [MODAL_KEYS.deleteUserCertificate]: React.lazy(() => import('components/modals/DeleteUserCertificateModal/DeleteUserCertificateModal')),
  [MODAL_KEYS.verifyUserCertificate]: React.lazy(() => import('components/modals/VerifyUserCertificateModal/VerifyUserCertificateModal')),
  [MODAL_KEYS.rejectUserCertificate]: React.lazy(() => import('components/modals/RejectUserCertificateModal/RejectUserCertificateModal')),
  [MODAL_KEYS.cancelUserCertificate]: React.lazy(() => import('components/modals/CancelUserCertificateModal/CancelUserCertificateModal')),
  [MODAL_KEYS.courseChapter]: React.lazy(() => import('components/modals/CreateCourseChapterModal/CreateCourseChapterModal')),
  [MODAL_KEYS.deleteChapter]: React.lazy(() => import('components/modals/DeleteCourseChapterModal/DeleteCourseChapterModal')),
  [MODAL_KEYS.deleteMaterial]: React.lazy(() => import('components/modals/DeleteChapterMaterialModal/DeleteChapterMaterialModal')),
  [MODAL_KEYS.courseAttachment]: React.lazy(() => import('components/modals/CourseAttachmentModal/CourseAttachmentModal')),
  [MODAL_KEYS.courseVideo]: React.lazy(() => import('components/modals/CourseVideoModal/CourseVideoModal')),
  [MODAL_KEYS.courseText]: React.lazy(() => import('components/modals/CourseTextModal/CourseTextModal')),
  [MODAL_KEYS.courseRegistrantAction]: React.lazy(() => import('components/modals/CourseRegistrantActionModal/CourseRegistrantActionModal')),
  [MODAL_KEYS.clubStatus]: React.lazy(() => import('components/modals/ClubStatusModal/ClubStatusModal')),
};

const RootModal = () => {
  const {
    modals,
    shiftModal,
  } = useModal();

  const { dir } = useLocale();

  const currentModal = modals?.[0] || {};
  const ModalToRender = MODALS[currentModal?.key];

  if (!ModalToRender) {
    return null;
  }

  const handleClose = () => {
    currentModal?.onClose && currentModal?.onClose();
    shiftModal();
  };

  return (
    <div id="global-modal">
      <Modal
        open
        onClose={handleClose}
        dir={dir}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div id={currentModal?.key}>
          <SuspenseWrapper>
            <ModalToRender
              handleClose={handleClose}
              {...currentModal.props}
            />
          </SuspenseWrapper>
        </div>
      </Modal>
    </div>
  );
};

export default RootModal;
