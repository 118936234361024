import { Box, Stack } from '@mui/material';
import { LeaderboardTopThree, LeaderboardListItem } from 'components/organisms';

const LeaderboardList = (props) => {
  const {
    isAbstract = false,
    topTen = [],
  } = props;
  const topThree = topTen?.slice(0, 3);
  const otherSeven = topTen?.slice(3, 10);

  return (
    <Box>
      <LeaderboardTopThree
        topThree={topThree}
      />
      {!isAbstract && (
      <Stack spacing={5} px={4}>
        {otherSeven?.map(((item) => (
          <LeaderboardListItem
            key={item.username || item.name}
            {...item}
          />
        )))}
      </Stack>
      )}
    </Box>
  );
};

export default LeaderboardList;
