import { useTheme } from '@mui/styles';

const TopicRegularIcon = (props) => {
  const { palette } = useTheme();

  const {
    fill = 'none',
    stroke = palette.text.secondary,
  } = props;

  return (
    <svg width="20" height="20" viewBox="0 0 22 22" fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M18.5 15.918H13.5L6 20.918V15.918H3.5C2.83696 15.918 2.20107 15.6546 1.73223 15.1857C1.26339 14.7169 1 14.081 1 13.418V3.41797C1 2.75493 1.26339 2.11904 1.73223 1.6502C2.20107 1.18136 2.83696 0.917969 3.5 0.917969H18.5C19.163 0.917969 19.7989 1.18136 20.2678 1.6502C20.7366 2.11904 21 2.75493 21 3.41797V13.418C21 14.081 20.7366 14.7169 20.2678 15.1857C19.7989 15.6546 19.163 15.918 18.5 15.918Z" stroke={stroke} strokeWidth="1.45" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.9375 5.85547H15.8125" stroke={stroke} strokeWidth="1.45" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.9375 10.793H15.8125" stroke={stroke} strokeWidth="1.45" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default TopicRegularIcon;
