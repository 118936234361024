const CyberHubEnglishLogoMobile = (props) => (
  <svg width="28" height="39" viewBox="0 0 28 39" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M3.00197 6.56457C2.27771 6.56457 1.68701 5.979 1.68701 5.2496V1.31497C1.68701 0.590709 2.27258 0 3.00197 0C3.72622 0 4.31693 0.585572 4.31693 1.31497V5.2496C4.31693 5.979 3.72622 6.56457 3.00197 6.56457Z" fill="url(#paint0_linear_2260_92410)" />
    <path d="M6.93948 6.56457C6.21522 6.56457 5.62451 5.979 5.62451 5.2496V1.31497C5.62451 0.590709 6.21009 0 6.93948 0C7.66375 0 8.25446 0.585572 8.25446 1.31497V5.2496C8.25446 5.979 7.66375 6.56457 6.93948 6.56457Z" fill="url(#paint1_linear_2260_92410)" />
    <path d="M10.8804 6.56457C10.1561 6.56457 9.56543 5.979 9.56543 5.2496V1.31497C9.56543 0.590709 10.151 0 10.8804 0C11.6047 0 12.1954 0.585572 12.1954 1.31497V5.2496C12.1902 5.979 11.6047 6.56457 10.8804 6.56457Z" fill="url(#paint2_linear_2260_92410)" />
    <path d="M26.6851 6.56457C25.9608 6.56457 25.3701 5.979 25.3701 5.2496V1.31497C25.3701 0.590709 25.9557 0 26.6851 0C27.4093 0 28 0.585572 28 1.31497V5.2496C27.9949 5.979 27.4093 6.56457 26.6851 6.56457Z" fill="url(#paint3_linear_2260_92410)" />
    <path d="M14.8194 26.207C14.0951 26.207 13.5044 25.6215 13.5044 24.8921V20.9574C13.5044 20.2332 14.09 19.6425 14.8194 19.6425C15.5436 19.6425 16.1343 20.228 16.1343 20.9574V24.8921C16.1292 25.6215 15.5436 26.207 14.8194 26.207Z" fill="url(#paint4_linear_2260_92410)" />
    <path d="M14.8194 14.418C14.0951 14.418 13.5044 13.8324 13.5044 13.103V5.2543C13.5044 4.53004 14.09 3.93933 14.8194 3.93933C15.5436 3.93933 16.1343 4.5249 16.1343 5.2543V13.103C16.1292 13.8324 15.5436 14.418 14.8194 14.418Z" fill="url(#paint5_linear_2260_92410)" />
    <path d="M10.8804 30.1724C10.1561 30.1724 9.56543 29.5868 9.56543 28.8574V21.0087C9.56543 20.2844 10.151 19.6937 10.8804 19.6937C11.6047 19.6937 12.1954 20.2793 12.1954 21.0087V28.8574C12.1902 29.5868 11.6047 30.1724 10.8804 30.1724Z" fill="url(#paint6_linear_2260_92410)" />
    <path d="M17.4541 21.0036C17.4541 20.2793 18.0397 19.6886 18.7691 19.6886H26.6178C27.3421 19.6886 27.9328 20.2742 27.9328 21.0036C27.9328 21.7278 27.3472 22.3185 26.6178 22.3185H18.7691C18.0448 22.3134 17.4541 21.7278 17.4541 21.0036Z" fill="url(#paint7_linear_2260_92410)" />
    <path d="M22.6949 14.4491C21.9706 14.4491 21.3799 13.8635 21.3799 13.1341V9.19949C21.3799 8.47523 21.9655 7.88452 22.6949 7.88452C23.4191 7.88452 24.0098 8.47009 24.0098 9.19949V13.1341C24.0098 13.8584 23.4191 14.4491 22.6949 14.4491Z" fill="url(#paint8_linear_2260_92410)" />
    <path d="M24.0031 17.0225C24.0031 17.7467 23.4175 18.3375 22.6881 18.3375H18.7534C18.0292 18.3375 17.4385 17.7519 17.4385 17.0225C17.4385 16.2982 18.024 15.7075 18.7534 15.7075H22.6881C23.4175 15.7127 24.0031 16.2982 24.0031 17.0225Z" fill="url(#paint9_linear_2260_92410)" />
    <path d="M24.0031 24.948C24.0031 25.6723 23.4175 26.263 22.6881 26.263H18.7534C18.0292 26.263 17.4385 25.6774 17.4385 24.948C17.4385 24.2238 18.024 23.6331 18.7534 23.6331H22.6881C23.4175 23.6382 24.0031 24.2238 24.0031 24.948Z" fill="url(#paint10_linear_2260_92410)" />
    <path d="M3.00197 18.3841C2.27771 18.3841 1.68701 17.7986 1.68701 17.0692V13.1345C1.68701 12.4103 2.27258 11.8196 3.00197 11.8196C3.72622 11.8196 4.31693 12.4052 4.31693 13.1345V17.0692C4.31693 17.7986 3.72622 18.3841 3.00197 18.3841Z" fill="url(#paint11_linear_2260_92410)" />
    <path d="M6.93948 18.3841C6.21522 18.3841 5.62451 17.7986 5.62451 17.0692V13.1345C5.62451 12.4103 6.21009 11.8196 6.93948 11.8196C7.66375 11.8196 8.25446 12.4052 8.25446 13.1345V17.0692C8.25446 17.7986 7.66375 18.3841 6.93948 18.3841Z" fill="url(#paint12_linear_2260_92410)" />
    <path d="M6.93948 26.2634C6.21522 26.2634 5.62451 25.6778 5.62451 24.9485V21.0138C5.62451 20.2896 6.21009 19.6989 6.93948 19.6989C7.66375 19.6989 8.25446 20.2844 8.25446 21.0138V24.9485C8.25446 25.6727 7.66375 26.2634 6.93948 26.2634Z" fill="url(#paint13_linear_2260_92410)" />
    <path d="M3.00197 10.5093C3.7282 10.5093 4.31693 9.9206 4.31693 9.19436C4.31693 8.46813 3.7282 7.87939 3.00197 7.87939C2.27574 7.87939 1.68701 8.46813 1.68701 9.19436C1.68701 9.9206 2.27574 10.5093 3.00197 10.5093Z" fill="url(#paint14_linear_2260_92410)" />
    <path d="M6.93948 10.5093C7.66572 10.5093 8.25446 9.9206 8.25446 9.19436C8.25446 8.46813 7.66572 7.87939 6.93948 7.87939C6.21325 7.87939 5.62451 8.46813 5.62451 9.19436C5.62451 9.9206 6.21325 10.5093 6.93948 10.5093Z" fill="url(#paint15_linear_2260_92410)" />
    <path d="M10.8804 10.5093C11.6066 10.5093 12.1954 9.9206 12.1954 9.19436C12.1954 8.46813 11.6066 7.87939 10.8804 7.87939C10.1542 7.87939 9.56543 8.46813 9.56543 9.19436C9.56543 9.9206 10.1542 10.5093 10.8804 10.5093Z" fill="url(#paint16_linear_2260_92410)" />
    <path d="M14.8194 18.3887C15.5456 18.3887 16.1343 17.8 16.1343 17.0738C16.1343 16.3475 15.5456 15.7588 14.8194 15.7588C14.0931 15.7588 13.5044 16.3475 13.5044 17.0738C13.5044 17.8 14.0931 18.3887 14.8194 18.3887Z" fill="url(#paint17_linear_2260_92410)" />
    <path d="M14.8194 30.2082C15.5456 30.2082 16.1343 29.6195 16.1343 28.8932C16.1343 28.167 15.5456 27.5782 14.8194 27.5782C14.0931 27.5782 13.5044 28.167 13.5044 28.8932C13.5044 29.6195 14.0931 30.2082 14.8194 30.2082Z" fill="url(#paint18_linear_2260_92410)" />
    <path d="M14.8194 2.63421C15.5456 2.63421 16.1343 2.04548 16.1343 1.31924C16.1343 0.593003 15.5456 0.00427246 14.8194 0.00427246C14.0931 0.00427246 13.5044 0.593003 13.5044 1.31924C13.5044 2.04548 14.0931 2.63421 14.8194 2.63421Z" fill="url(#paint19_linear_2260_92410)" />
    <path d="M18.7534 14.4495C19.4797 14.4495 20.0684 13.8608 20.0684 13.1345C20.0684 12.4083 19.4797 11.8196 18.7534 11.8196C18.0272 11.8196 17.4385 12.4083 17.4385 13.1345C17.4385 13.8608 18.0272 14.4495 18.7534 14.4495Z" fill="url(#paint20_linear_2260_92410)" />
    <path d="M18.7534 10.5093C19.4797 10.5093 20.0684 9.9206 20.0684 9.19436C20.0684 8.46813 19.4797 7.87939 18.7534 7.87939C18.0272 7.87939 17.4385 8.46813 17.4385 9.19436C17.4385 9.9206 18.0272 10.5093 18.7534 10.5093Z" fill="url(#paint21_linear_2260_92410)" />
    <path d="M18.7534 6.56927C19.4797 6.56927 20.0684 5.98054 20.0684 5.2543C20.0684 4.52806 19.4797 3.93933 18.7534 3.93933C18.0272 3.93933 17.4385 4.52806 17.4385 5.2543C17.4385 5.98054 18.0272 6.56927 18.7534 6.56927Z" fill="url(#paint22_linear_2260_92410)" />
    <path d="M26.6323 18.3836C27.3586 18.3836 27.9473 17.7949 27.9473 17.0686C27.9473 16.3424 27.3586 15.7537 26.6323 15.7537C25.9061 15.7537 25.3174 16.3424 25.3174 17.0686C25.3174 17.7949 25.9061 18.3836 26.6323 18.3836Z" fill="url(#paint23_linear_2260_92410)" />
    <path d="M26.6323 14.4444C27.3586 14.4444 27.9473 13.8557 27.9473 13.1294C27.9473 12.4032 27.3586 11.8145 26.6323 11.8145C25.9061 11.8145 25.3174 12.4032 25.3174 13.1294C25.3174 13.8557 25.9061 14.4444 26.6323 14.4444Z" fill="url(#paint24_linear_2260_92410)" />
    <path d="M26.6323 10.5093C27.3586 10.5093 27.9473 9.9206 27.9473 9.19436C27.9473 8.46813 27.3586 7.87939 26.6323 7.87939C25.9061 7.87939 25.3174 8.46813 25.3174 9.19436C25.3174 9.9206 25.9061 10.5093 26.6323 10.5093Z" fill="url(#paint25_linear_2260_92410)" />
    <path d="M18.7534 2.63421C19.4797 2.63421 20.0684 2.04548 20.0684 1.31924C20.0684 0.593003 19.4797 0.00427246 18.7534 0.00427246C18.0272 0.00427246 17.4385 0.593003 17.4385 1.31924C17.4385 2.04548 18.0272 2.63421 18.7534 2.63421Z" fill="url(#paint26_linear_2260_92410)" />
    <path d="M22.6949 6.56927C23.4211 6.56927 24.0098 5.98054 24.0098 5.2543C24.0098 4.52806 23.4211 3.93933 22.6949 3.93933C21.9686 3.93933 21.3799 4.52806 21.3799 5.2543C21.3799 5.98054 21.9686 6.56927 22.6949 6.56927Z" fill="url(#paint27_linear_2260_92410)" />
    <path d="M22.6949 2.63421C23.4211 2.63421 24.0098 2.04548 24.0098 1.31924C24.0098 0.593003 23.4211 0.00427246 22.6949 0.00427246C21.9686 0.00427246 21.3799 0.593003 21.3799 1.31924C21.3799 2.04548 21.9686 2.63421 22.6949 2.63421Z" fill="url(#paint28_linear_2260_92410)" />
    <path d="M10.8804 14.4495C11.6066 14.4495 12.1954 13.8608 12.1954 13.1345C12.1954 12.4083 11.6066 11.8196 10.8804 11.8196C10.1542 11.8196 9.56543 12.4083 9.56543 13.1345C9.56543 13.8608 10.1542 14.4495 10.8804 14.4495Z" fill="url(#paint29_linear_2260_92410)" />
    <path d="M10.8804 18.3887C11.6066 18.3887 12.1954 17.8 12.1954 17.0738C12.1954 16.3475 11.6066 15.7588 10.8804 15.7588C10.1542 15.7588 9.56543 16.3475 9.56543 17.0738C9.56543 17.8 10.1542 18.3887 10.8804 18.3887Z" fill="url(#paint30_linear_2260_92410)" />
    <path d="M3.00197 22.3237C3.7282 22.3237 4.31693 21.7349 4.31693 21.0087C4.31693 20.2825 3.7282 19.6937 3.00197 19.6937C2.27574 19.6937 1.68701 20.2825 1.68701 21.0087C1.68701 21.7349 2.27574 22.3237 3.00197 22.3237Z" fill="url(#paint31_linear_2260_92410)" />
    <path d="M0 35.485V35.4721C0 34.2584 0.908171 33.2645 2.20046 33.2645C2.81447 33.2645 3.23857 33.4316 3.58984 33.6915C3.74977 33.81 3.77548 34.0399 3.6441 34.1898L3.60698 34.2327C3.49131 34.3655 3.2914 34.3912 3.15004 34.2855C2.87444 34.0813 2.57029 33.9542 2.19332 33.9542C1.38225 33.9542 0.785367 34.6254 0.785367 35.4607V35.4735C0.785367 36.3089 1.37653 36.9857 2.19332 36.9857C2.60599 36.9857 2.90015 36.8572 3.18574 36.6373C3.32425 36.5302 3.51987 36.5388 3.64268 36.663L3.6541 36.6759C3.79689 36.8201 3.78119 37.0528 3.62554 37.1828C3.24714 37.4941 2.80733 37.6811 2.16904 37.6811C0.926734 37.6797 0 36.7101 0 35.485Z" fill="#3F4144" />
    <path d="M6.62825 34.3865C6.8767 34.3865 7.04806 34.6364 6.9581 34.8691L5.86431 37.6979C5.60871 38.3561 5.32169 38.6003 4.80335 38.6003C4.63629 38.6003 4.49207 38.5789 4.35784 38.5389C4.18221 38.4861 4.09367 38.2905 4.16935 38.1248L4.17221 38.1191C4.23504 37.982 4.3864 37.9178 4.53205 37.9549C4.59488 37.9706 4.65913 37.9792 4.72482 37.9792C4.93187 37.9792 5.06038 37.8878 5.18176 37.6136L4.04941 34.8891C3.95088 34.6506 4.12509 34.3879 4.38354 34.3879C4.53348 34.3879 4.66913 34.4807 4.72196 34.6221L5.54159 36.7783L6.29411 34.6249C6.34409 34.4821 6.47689 34.3865 6.62825 34.3865Z" fill="#3F4144" />
    <path d="M8.10596 37.1057V37.237C8.10596 37.4412 7.94032 37.6055 7.73755 37.6055C7.53335 37.6055 7.36914 37.4398 7.36914 37.237V33.5244C7.36914 33.3202 7.53478 33.156 7.73755 33.156C7.94174 33.156 8.10596 33.3216 8.10596 33.5244V34.9238C8.34442 34.5882 8.67856 34.3198 9.20404 34.3198C9.95942 34.3198 10.6977 34.9167 10.6977 35.9905V36.0033C10.6977 37.07 9.96656 37.674 9.20404 37.674C8.66571 37.674 8.33157 37.4055 8.10596 37.1057ZM9.94657 36.0033V35.9905C9.94657 35.3679 9.52533 34.9595 9.02555 34.9595C8.52577 34.9595 8.08597 35.3736 8.08597 35.9905V36.0033C8.08597 36.6188 8.52434 37.0343 9.02555 37.0343C9.53246 37.0329 9.94657 36.6373 9.94657 36.0033Z" fill="#3F4144" />
    <path d="M10.9136 36.0095V35.9253C10.9136 35.0614 11.5976 34.3331 12.4615 34.3217C13.5024 34.3074 14.0293 35.1185 14.0293 36.0538C14.0293 36.1209 14.0236 36.188 14.0165 36.2609H11.6504C11.7304 36.7849 12.1016 37.0776 12.5771 37.0776C12.8499 37.0776 13.064 37.0005 13.2625 36.8534C13.3782 36.7678 13.5367 36.7706 13.6438 36.8663C13.7823 36.9891 13.7766 37.2076 13.6338 37.3232C13.3582 37.546 13.0098 37.6802 12.5657 37.6802C11.6333 37.6802 10.9136 37.0034 10.9136 36.0095ZM13.2968 35.7711C13.2482 35.2956 12.9669 34.9229 12.48 34.9229C12.0288 34.9229 11.7118 35.2699 11.6447 35.7711H13.2968Z" fill="#3F4144" />
    <path d="M14.7751 34.3865C14.9793 34.3865 15.1436 34.5521 15.1436 34.7549V35.1119C15.2749 34.7977 15.4777 34.5493 15.7604 34.4207C16.006 34.3094 16.2831 34.5007 16.2831 34.7692C16.2831 34.9491 16.156 35.099 15.9818 35.139C15.4891 35.2532 15.1436 35.6645 15.1436 36.3799V37.2366C15.1436 37.4408 14.9779 37.605 14.7751 37.605C14.571 37.605 14.4067 37.4394 14.4067 37.2366V34.7549C14.4067 34.5521 14.5724 34.3865 14.7751 34.3865Z" fill="#3F4144" />
    <path d="M16.9478 33.338C17.1549 33.338 17.3234 33.5065 17.3234 33.7136V35.1129H19.3596V33.7136C19.3596 33.5065 19.5281 33.338 19.7351 33.338C19.9422 33.338 20.1107 33.5065 20.1107 33.7136V37.232C20.1107 37.439 19.9422 37.6075 19.7351 37.6075C19.5281 37.6075 19.3596 37.439 19.3596 37.232V35.8083H17.3234V37.232C17.3234 37.439 17.1549 37.6075 16.9478 37.6075C16.7408 37.6075 16.5723 37.439 16.5723 37.232V33.7121C16.5737 33.5051 16.7408 33.338 16.9478 33.338Z" fill="#3F4144" />
    <path d="M20.7578 36.4356V34.7549C20.7578 34.5507 20.9234 34.3865 21.1262 34.3865C21.3304 34.3865 21.4946 34.5521 21.4946 34.7549V36.2157C21.4946 36.7154 21.7445 36.9967 22.1829 36.9967C22.6098 36.9967 22.9083 36.704 22.9083 36.2042V34.7549C22.9083 34.5507 23.0739 34.3865 23.2767 34.3865H23.2824C23.4866 34.3865 23.6508 34.5521 23.6508 34.7549V37.2366C23.6508 37.4408 23.4851 37.605 23.2824 37.605H23.2767C23.0725 37.605 22.9083 37.4394 22.9083 37.2366V37.1053C22.7012 37.4094 22.4028 37.6722 21.9016 37.6722C21.1776 37.6736 20.7578 37.1852 20.7578 36.4356Z" fill="#3F4144" />
    <path d="M24.9448 37.1057V37.237C24.9448 37.4412 24.7792 37.6055 24.5764 37.6055C24.3722 37.6055 24.208 37.4398 24.208 37.237V33.5244C24.208 33.3202 24.3736 33.156 24.5764 33.156C24.7806 33.156 24.9448 33.3216 24.9448 33.5244V34.9238C25.1833 34.5882 25.5174 34.3198 26.0429 34.3198C26.7983 34.3198 27.5365 34.9167 27.5365 35.9905V36.0033C27.5365 37.07 26.8054 37.674 26.0429 37.674C25.506 37.674 25.1704 37.4055 24.9448 37.1057ZM26.7869 36.0033V35.9905C26.7869 35.3679 26.3656 34.9595 25.8658 34.9595C25.3661 34.9595 24.9263 35.3736 24.9263 35.9905V36.0033C24.9263 36.6188 25.3646 37.0343 25.8658 37.0343C26.3713 37.0329 26.7869 36.6373 26.7869 36.0033Z" fill="#3F4144" />
    <defs>
      <linearGradient id="paint0_linear_2260_92410" x1="30.3516" y1="-23.6358" x2="-23.7333" y2="29.6036" gradientUnits="userSpaceOnUse">
        <stop offset="0.00506604" stopColor="#8DDD88" />
        <stop offset="1" stopColor="#13A7FF" />
      </linearGradient>
      <linearGradient id="paint1_linear_2260_92410" x1="32.2879" y1="-21.6669" x2="-21.7971" y2="31.5731" gradientUnits="userSpaceOnUse">
        <stop offset="0.00506604" stopColor="#8DDD88" />
        <stop offset="1" stopColor="#13A7FF" />
      </linearGradient>
      <linearGradient id="paint2_linear_2260_92410" x1="34.2273" y1="-19.6979" x2="-19.8577" y2="33.5422" gradientUnits="userSpaceOnUse">
        <stop offset="0.00506604" stopColor="#8DDD88" />
        <stop offset="1" stopColor="#13A7FF" />
      </linearGradient>
      <linearGradient id="paint3_linear_2260_92410" x1="42.0043" y1="-11.7966" x2="-12.0807" y2="41.4429" gradientUnits="userSpaceOnUse">
        <stop offset="0.00506604" stopColor="#8DDD88" />
        <stop offset="1" stopColor="#13A7FF" />
      </linearGradient>
      <linearGradient id="paint4_linear_2260_92410" x1="45.9854" y1="-7.75184" x2="-8.09956" y2="45.4876" gradientUnits="userSpaceOnUse">
        <stop offset="0.00506604" stopColor="#8DDD88" />
        <stop offset="1" stopColor="#13A7FF" />
      </linearGradient>
      <linearGradient id="paint5_linear_2260_92410" x1="39.1127" y1="-14.7343" x2="-14.9723" y2="38.5051" gradientUnits="userSpaceOnUse">
        <stop offset="0.00506604" stopColor="#8DDD88" />
        <stop offset="1" stopColor="#13A7FF" />
      </linearGradient>
      <linearGradient id="paint6_linear_2260_92410" x1="45.0515" y1="-8.70182" x2="-9.03344" y2="44.5382" gradientUnits="userSpaceOnUse">
        <stop offset="0.00506604" stopColor="#8DDD88" />
        <stop offset="1" stopColor="#13A7FF" />
      </linearGradient>
      <linearGradient id="paint7_linear_2260_92410" x1="48.8986" y1="-4.79246" x2="-5.18637" y2="48.4475" gradientUnits="userSpaceOnUse">
        <stop offset="0.00506604" stopColor="#8DDD88" />
        <stop offset="1" stopColor="#13A7FF" />
      </linearGradient>
      <linearGradient id="paint8_linear_2260_92410" x1="43.9831" y1="-9.78761" x2="-10.1018" y2="43.4524" gradientUnits="userSpaceOnUse">
        <stop offset="0.00506604" stopColor="#8DDD88" />
        <stop offset="1" stopColor="#13A7FF" />
      </linearGradient>
      <linearGradient id="paint9_linear_2260_92410" x1="45.9399" y1="-7.7981" x2="-8.14507" y2="45.4418" gradientUnits="userSpaceOnUse">
        <stop offset="0.00506604" stopColor="#8DDD88" />
        <stop offset="1" stopColor="#13A7FF" />
      </linearGradient>
      <linearGradient id="paint10_linear_2260_92410" x1="49.9024" y1="-3.77298" x2="-4.18259" y2="49.467" gradientUnits="userSpaceOnUse">
        <stop offset="0.00506604" stopColor="#8DDD88" />
        <stop offset="1" stopColor="#13A7FF" />
      </linearGradient>
      <linearGradient id="paint11_linear_2260_92410" x1="36.2614" y1="-17.6319" x2="-17.8235" y2="35.6075" gradientUnits="userSpaceOnUse">
        <stop offset="0.00506604" stopColor="#8DDD88" />
        <stop offset="1" stopColor="#13A7FF" />
      </linearGradient>
      <linearGradient id="paint12_linear_2260_92410" x1="38.1978" y1="-15.6629" x2="-15.8871" y2="37.5771" gradientUnits="userSpaceOnUse">
        <stop offset="0.00506604" stopColor="#8DDD88" />
        <stop offset="1" stopColor="#13A7FF" />
      </linearGradient>
      <linearGradient id="paint13_linear_2260_92410" x1="42.1358" y1="-11.6627" x2="-11.9492" y2="41.5774" gradientUnits="userSpaceOnUse">
        <stop offset="0.00506604" stopColor="#8DDD88" />
        <stop offset="1" stopColor="#13A7FF" />
      </linearGradient>
      <linearGradient id="paint14_linear_2260_92410" x1="33.3065" y1="-20.6341" x2="-20.7784" y2="32.6053" gradientUnits="userSpaceOnUse">
        <stop offset="0.00506604" stopColor="#8DDD88" />
        <stop offset="1" stopColor="#13A7FF" />
      </linearGradient>
      <linearGradient id="paint15_linear_2260_92410" x1="35.2429" y1="-18.6652" x2="-18.8421" y2="34.5749" gradientUnits="userSpaceOnUse">
        <stop offset="0.00506604" stopColor="#8DDD88" />
        <stop offset="1" stopColor="#13A7FF" />
      </linearGradient>
      <linearGradient id="paint16_linear_2260_92410" x1="37.1822" y1="-16.6962" x2="-16.9027" y2="36.5439" gradientUnits="userSpaceOnUse">
        <stop offset="0.00506604" stopColor="#8DDD88" />
        <stop offset="1" stopColor="#13A7FF" />
      </linearGradient>
      <linearGradient id="paint17_linear_2260_92410" x1="43.0587" y1="-10.7254" x2="-11.0263" y2="42.514" gradientUnits="userSpaceOnUse">
        <stop offset="0.00506604" stopColor="#8DDD88" />
        <stop offset="1" stopColor="#13A7FF" />
      </linearGradient>
      <linearGradient id="paint18_linear_2260_92410" x1="48.9656" y1="-4.72446" x2="-5.1193" y2="48.5149" gradientUnits="userSpaceOnUse">
        <stop offset="0.00506604" stopColor="#8DDD88" />
        <stop offset="1" stopColor="#13A7FF" />
      </linearGradient>
      <linearGradient id="paint19_linear_2260_92410" x1="35.1814" y1="-18.7284" x2="-18.9035" y2="34.511" gradientUnits="userSpaceOnUse">
        <stop offset="0.00506604" stopColor="#8DDD88" />
        <stop offset="1" stopColor="#13A7FF" />
      </linearGradient>
      <linearGradient id="paint20_linear_2260_92410" x1="43.0263" y1="-10.7575" x2="-11.0587" y2="42.4825" gradientUnits="userSpaceOnUse">
        <stop offset="0.00506604" stopColor="#8DDD88" />
        <stop offset="1" stopColor="#13A7FF" />
      </linearGradient>
      <linearGradient id="paint21_linear_2260_92410" x1="41.0573" y1="-12.7582" x2="-13.0277" y2="40.4818" gradientUnits="userSpaceOnUse">
        <stop offset="0.00506604" stopColor="#8DDD88" />
        <stop offset="1" stopColor="#13A7FF" />
      </linearGradient>
      <linearGradient id="paint22_linear_2260_92410" x1="39.0883" y1="-14.7587" x2="-14.9966" y2="38.4813" gradientUnits="userSpaceOnUse">
        <stop offset="0.00506604" stopColor="#8DDD88" />
        <stop offset="1" stopColor="#13A7FF" />
      </linearGradient>
      <linearGradient id="paint23_linear_2260_92410" x1="48.8694" y1="-4.82132" x2="-5.2156" y2="48.4181" gradientUnits="userSpaceOnUse">
        <stop offset="0.00506604" stopColor="#8DDD88" />
        <stop offset="1" stopColor="#13A7FF" />
      </linearGradient>
      <linearGradient id="paint24_linear_2260_92410" x1="46.9004" y1="-6.82101" x2="-7.18455" y2="46.4184" gradientUnits="userSpaceOnUse">
        <stop offset="0.00506604" stopColor="#8DDD88" />
        <stop offset="1" stopColor="#13A7FF" />
      </linearGradient>
      <linearGradient id="paint25_linear_2260_92410" x1="44.9315" y1="-8.82168" x2="-9.15354" y2="44.4177" gradientUnits="userSpaceOnUse">
        <stop offset="0.00506604" stopColor="#8DDD88" />
        <stop offset="1" stopColor="#13A7FF" />
      </linearGradient>
      <linearGradient id="paint26_linear_2260_92410" x1="37.1193" y1="-16.7594" x2="-16.9657" y2="36.4806" gradientUnits="userSpaceOnUse">
        <stop offset="0.00506604" stopColor="#8DDD88" />
        <stop offset="1" stopColor="#13A7FF" />
      </linearGradient>
      <linearGradient id="paint27_linear_2260_92410" x1="41.0282" y1="-12.7897" x2="-13.0568" y2="40.4503" gradientUnits="userSpaceOnUse">
        <stop offset="0.00506604" stopColor="#8DDD88" />
        <stop offset="1" stopColor="#13A7FF" />
      </linearGradient>
      <linearGradient id="paint28_linear_2260_92410" x1="39.0592" y1="-14.7904" x2="-15.0258" y2="38.4496" gradientUnits="userSpaceOnUse">
        <stop offset="0.00506604" stopColor="#8DDD88" />
        <stop offset="1" stopColor="#13A7FF" />
      </linearGradient>
      <linearGradient id="paint29_linear_2260_92410" x1="39.1512" y1="-14.6955" x2="-14.9337" y2="38.5445" gradientUnits="userSpaceOnUse">
        <stop offset="0.00506604" stopColor="#8DDD88" />
        <stop offset="1" stopColor="#13A7FF" />
      </linearGradient>
      <linearGradient id="paint30_linear_2260_92410" x1="41.1202" y1="-12.6959" x2="-12.9648" y2="40.5441" gradientUnits="userSpaceOnUse">
        <stop offset="0.00506604" stopColor="#8DDD88" />
        <stop offset="1" stopColor="#13A7FF" />
      </linearGradient>
      <linearGradient id="paint31_linear_2260_92410" x1="39.2135" y1="-14.6332" x2="-14.8714" y2="38.6062" gradientUnits="userSpaceOnUse">
        <stop offset="0.00506604" stopColor="#8DDD88" />
        <stop offset="1" stopColor="#13A7FF" />
      </linearGradient>
    </defs>
  </svg>
);

export default CyberHubEnglishLogoMobile;
