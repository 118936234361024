import END_POINTS from '../endPoints';
import api from '../api';

const getProfilePersonalInfo = (username, options) => api(
  END_POINTS.profilePersonalInfo(username),
  options,
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default getProfilePersonalInfo;
