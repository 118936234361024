import { Link } from 'components/core';
import DINNextLTW23Light from '../fonts/DIN/DINNextLTW23Light.woff';

const components = (theme) => {
  // Theme variables
  const {
    typography,
    palette,
    borderRadius,
    spacing,
  } = theme;

  const {
    appBar,
    drawer,
    alert,
    secondary,
    primary,
    text,
    common,
    background,
    pending,
  } = palette;

  const {
    button,
    bodySmallBold,
  } = typography;

  const MuiCssBaseline = {
    styleOverrides: `
    @font-face {
      font-weight: 400;
      font-style: "light"; 
        font-family: 'DINNextLTW23Light';
        font-display: swap;
        src: local('DINNextLTW23Light'),
             url(${DINNextLTW23Light}) format('woff');
      }
    `,
  };

  const MuiAppBar = {
    styleOverrides: {
      root: {
        ...appBar,
        boxShadow: '0 4px 4px 0px rgba(0, 0, 0, 0.06)',
      },
    },
  };

  const MuiMenuItem = {
    styleOverrides: {
      root: {
        '&:hover': {
          backgroundColor: common.ghostlyGrey,
          color: secondary.main,
        },
      },
    },
  };

  const MuiDrawer = {
    styleOverrides: {
      paper: {
        ...drawer.paper,
        borderRight: 'none',
        padding: '2px 24px',
      },
      root: {
        '& .MuiToolbar-root': {
          marginBottom: '40px',
        },
      },
    },
  };

  const MuiDataGrid = {
    styleOverrides: {
      root: {
        '& .MuiPaginationItem-root.Mui-selected': {
          backgroundColor: common.softBlue,
          '&:hover': {
            backgroundColor: common.softBlue,
          },
        },
        '& .MuiDataGrid-main': {
          backgroundColor: background.paper,
          borderRadius: 8,
          padding: 15,
          '.MuiDataGrid-overlay': {
            backgroundColor: background.paper,
          },
        },
        border: 0,
        '& .MuiDataGrid-virtualScroller': {
          minHeight: 552,
        },
        '& .MuiDataGrid-columnHeaders': {
          padding: 24,
          borderRadius: '6px 6px 0px 0px',
          borderBottom: 'none',
          '& .MuiDataGrid-columnSeparator': {
            visibility: 'hidden', // hide column header separator
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            ...bodySmallBold,
            color: secondary.main,
          },
        },
        '& .MuiDataGrid-row': {
          '&:hover': {
            backgroundColor: background.paper,
          },
        },
        '& .MuiDataGrid-columnHeader': {
          border: 0,
          padding: 0,
          outline: 'none',
          '&:focus': {
            border: 0,
            outline: 'none',
          },
          '&:focus-within': {
            border: 0,
            outline: 'none',
          },
        },
        '& .MuiDataGrid-cell': {
          borderBottom: `1px solid ${common.silverGrey}`,
          '&:focus': {
            outline: 'none',
          },
          '&:focus-within': {
            outline: 'none',
          },
        },
        '& .MuiDataGrid-sortIcon': {
          color: primary.main,
        },
        '& .MuiDataGrid-footerContainer': {
          marginTop: 18,
          marginBottom: 28,
          justifyContent: 'center',
        },
      },
    },
  };

  const MuiListItemButton = {
    styleOverrides: {
      root: {
        borderRadius: '8px',
        overflowWrap: 'break-word',
        '&.Mui-selected': {
          backgroundColor: background.default,
          '&:hover': {
            backgroundColor: background.default,
          },
          '& .MuiTypography-root': {
            color: text.secondary,
          },
        },
        '&:hover': {
          backgroundColor: background.default,
        },
      },
    },
  };

  const MuiListItemIcon = {
    styleOverrides: {
      root: {
        minWidth: '32.5px',
      },
    },
  };

  const MuiButton = {
    variants: [
      {
        props: { size: 'regular' },
        style: {
          padding: '8px 23.5px',
        },
      },
      {
        props: { size: 'fixed' },
        style: {
          minWidth: '168px',
          padding: '8px 0',
        },
      },
      {
        props: { variant: 'linkButton' },
        style: {
          color: primary.main,
          '&:disabled': {
            color: common.disabledGreenColor,
          },
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
      {
        props: { variant: 'dismiss' },
        style: {
          color: common.white,
          backgroundColor: common.transparentBlack,
          padding: '5px 5px',
          minWidth: 'auto',
          borderRadius: '100%',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.45)',
          },
        },
      },
    ],
    styleOverrides: {
      root: {
        ...button,
        textTransform: 'none',
        WebkitBoxShadow: 'none',
        '&.MuiButton-contained': {
          '&:hover': {
            backgroundColor: common.grassGreen,
            WebkitBoxShadow: 'none',
          },
        },
        '&.MuiButton-outlined': {
          '&.MuiButton-outlinedPrimary': {
            '&:disabled': {
              color: common.disabledGreenColor,
              borderColor: common.disabledGreenColor,
            },
          },
          '&.MuiButton-outlinedSecondary': {
            '&:disabled': {
              borderColor: common.metalGrey,
              color: common.metalGrey,
            },
          },
          '&:hover': {
            backgroundColor: 'transparent',
          },
          '&.MuiButton-outlinedError': {
            '&:disabled': {
              borderColor: 'rgba(211, 47, 47, 0.5)',
              color: 'rgba(211, 47, 47, 0.5)',
            },
          },
        },
      },
    },
  };

  const MuiLoadingButton = {
    styleOverrides: {
      root: {
        '&.MuiButton-outlined': {
          '& .MuiLoadingButton-loadingIndicator': {
            color: primary.main,
          },
        },
      },
    },
  };

  const MuiPagination = {
    styleOverrides: {
      root: {
        '& .MuiPaginationItem-root.Mui-selected': {
          backgroundColor: common.softBlue,
          color: common.white,
          '&:hover': {
            backgroundColor: common.softBlue,
            color: common.white,
          },
        },
      },
    },
  };

  const MuiOutlinedInput = {
    styleOverrides: {
      root: {
        backgroundColor: secondary.contrastText,
        color: text.couchGrey,
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: primary.main,
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: common.silverGrey,
        },
        '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
          borderColor: common.silverGrey,
        },
        '&.Mui-error': {
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: common.error,
          },
        },
      },
      input: {
        '&:-webkit-autofill': {
          WebkitTextFillColor: common.couchGrey,
          transition: '5000s ease-in-out',
        },
        '&.Mui-disabled': {
          backgroundColor: common.white,
          borderRadius: 8,
          WebkitTextFillColor: text.secondary,
        },
      },
    },
  };

  const MuiAlert = {
    variants: [
      {
        props: { severity: 'error' },
        style: {
          color: alert.error.main,
          backgroundColor: alert.error.background,
          '& .MuiAlert-icon': {
            color: alert.error.main,
          },
        },
      },
      {
        props: { severity: 'success' },
        style: {
          color: alert.success.main,
          backgroundColor: alert.success.background,
          '& .MuiAlert-icon': {
            color: alert.success.main,
          },
        },
      },
    ],
  };

  // https://mui.com/guides/routing/#global-theme-link
  const MuiLink = {
    defaultProps: {
      component: Link,
    },
    styleOverrides: {
      root: {
        ...button,
        textTransform: 'none',
        textDecoration: 'none',
        color: palette.primary.main,
      },
    },
  };

  const MuiChip = {
    styleOverrides: {
      root: {
        borderRadius,
        padding: '0px 12px',
      },
      label: {
        padding: '0px',
        textTransform: 'capitalize',
      },
    },
  };

  const MuiAutocomplete = {
    styleOverrides: {
      listbox: {
        '& .MuiAutocomplete-option[aria-selected="true"]': {
          backgroundColor: common.white,
          color: text.primary,
        },
        '& .MuiAutocomplete-option': {
          '&:hover': {
            backgroundColor: common.mistySpringGreen,
            color: primary.main,
          },
        },
      },
    },
  };

  const MuiTab = {
    styleOverrides: {
      root: {
        padding: 0,
        marginRight: spacing * 11,
        textTransform: 'none',
        '&.Mui-selected': {
          color: common.turquoiseSeaweedGreen,
        },
      },
    },
  };

  const MuiTabs = {
    styleOverrides: {
      indicator: {
        backgroundColor: text.turquoiseSeaweedGreen,
      },
    },
  };

  const MuiStepButton = {
    styleOverrides: {
      touchRipple: {
        display: 'none',
      },
    },
  };

  const MuiStep = {
    styleOverrides: {
      root: {
        '& .MuiStepConnector-root.Mui-active': {
          '.MuiStepConnector-line': {
            borderColor: primary.main,
          },
        },
        '& .MuiStepConnector-root.Mui-completed': {
          '.MuiStepConnector-line': {
            borderColor: primary.main,
          },
        },
        '& .MuiStepLabel-iconContainer': {
          '& .MuiSvgIcon-root': {
            color: common.silverGrey,
          },
          '& .MuiStepIcon-root.Mui-active': {
            color: primary.main,
          },
          '& .MuiStepIcon-root.Mui-completed': {
            color: primary.main,
          },
        },
      },
    },
  };

  const MuiBadge = {
    styleOverrides: {
      colorPrimary: {
        backgroundColor: pending.contrastText,
      },
    },
  };

  const MuiCard = {
    styleOverrides: {
      root: {
        boxShadow: 'none',
      },
    },
  };

  const MuiSwitch = {
    styleOverrides: {
      root: {
        padding: '5px',
        '& .Mui-checked': {
          '& .MuiSwitch-thumb': {
            backgroundColor: common.white,
          },
        },
        '& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
          opacity: 1,
        },
        '& .MuiSwitch-track': {
          borderRadius: '15px',
          backgroundColor: common.ghostlyGrey,
          opacity: 1,
        },
        '& .MuiSwitch-switchBase': {
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.5,
          },
        },
      },
    },
  };

  const MuiBottomNavigation = {
    styleOverrides: {
      root: {
        '& .MuiBottomNavigationAction-label': {
          marginTop: '4px',
        },
        '& .Mui-selected': {
          color: common.darkGray,
          '& .MuiBottomNavigationAction-label': {
            marginTop: '4px',
          },
        },
        '& .MuiTouchRipple-root': {
          display: 'none',
        },
      },
    },
  };

  const MuiButtonBase = {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        '&.MuiTabScrollButton-root': {
          '&.Mui-disabled': {
            opacity: 0.1,
          },
        },
      },
    },
  };

  return {
    MuiAppBar,
    MuiDrawer,
    MuiListItemButton,
    MuiListItemIcon,
    MuiButton,
    MuiOutlinedInput,
    MuiAlert,
    MuiLink,
    MuiChip,
    MuiCssBaseline,
    MuiAutocomplete,
    MuiTab,
    MuiMenuItem,
    MuiStepButton,
    MuiStep,
    MuiDataGrid,
    MuiLoadingButton,
    MuiTabs,
    MuiBadge,
    MuiSwitch,
    MuiBottomNavigation,
    MuiCard,
    MuiButtonBase,
    MuiPagination,
  };
};

export default components;
