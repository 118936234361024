import { getCourseTracks } from 'services';
import { useCustomQuery } from 'reactQuery';

const useCourseTracks = () => {
  const query = useCustomQuery({
    queryKey: ['course-tracks'],
    queryFn: getCourseTracks,
  });

  return { ...query };
};

export default useCourseTracks;
