import { useTheme } from '@mui/styles';

const AttachmentFilledIcon = (props) => {
  const { palette } = useTheme();

  const {
    fill = palette.common.silverGrey,
    innerFill = palette.common.darkGray,
    ...rest
  } = props;

  return (
    <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <rect opacity="0.2" x="0.5" width="30" height="30" rx="6.91016" fill={fill} />
      <rect x="3" y="2.5" width="25" height="25" rx="5.75846" fill={fill} />
      <g clipPath="url(#clip0_936_36755)">
        <path d="M14.0781 21.5C12.5703 21.5 11.3438 20.2734 11.3438 18.7656V12.9062C11.3438 10.7526 13.0964 9 15.25 9C17.4036 9 19.1562 10.7526 19.1562 12.9062V17.2026C19.1562 17.4182 18.9812 17.5932 18.7656 17.5932C18.55 17.5932 18.375 17.4182 18.375 17.2026V12.9062C18.375 11.1833 16.9729 9.78125 15.25 9.78125C13.5271 9.78125 12.125 11.1833 12.125 12.9062V18.7656C12.125 19.8427 13.001 20.7188 14.0781 20.7188C15.1552 20.7188 16.0312 19.8427 16.0312 18.7656V12.9062C16.0312 12.4755 15.6807 12.125 15.25 12.125C14.8193 12.125 14.4688 12.4755 14.4688 12.9062V17.9844C14.4688 18.2 14.2937 18.375 14.0781 18.375C13.8625 18.375 13.6875 18.2 13.6875 17.9844V12.9062C13.6875 12.0448 14.3885 11.3438 15.25 11.3438C16.1115 11.3438 16.8125 12.0448 16.8125 12.9062V18.7656C16.8125 20.2734 15.5859 21.5 14.0781 21.5Z" fill={innerFill} />
      </g>
      <defs>
        <clipPath id="clip0_936_36755">
          <rect width="12.5" height="12.5" fill="white" transform="translate(9 9)" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default AttachmentFilledIcon;
