import { useTheme } from '@mui/styles';

const MailIcon = (props) => {
  const { palette } = useTheme();

  const {
    fill = palette.common.darkGray,
    width,
    ...rest
  } = props;

  return (
    <svg width={width} height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d="M24.0005 8.28905C24.0006 8.20036 23.977 8.11328 23.932 8.03685C23.887 7.96043 23.8223 7.89746 23.7448 7.85452C23.6672 7.81157 23.5795 7.79021 23.4908 7.79267C23.4022 7.79513 23.3158 7.82131 23.2407 7.86849L14.4626 13.9745C13.7526 14.4153 12.9336 14.6488 12.0979 14.6488C11.2623 14.6488 10.4432 14.4153 9.73327 13.9745L0.955097 7.86849C0.880007 7.82131 0.79364 7.79513 0.704994 7.79267C0.616347 7.79021 0.528664 7.81157 0.451076 7.85452C0.373489 7.89746 0.308837 7.96043 0.263853 8.03685C0.21887 8.11328 0.195201 8.20036 0.195313 8.28905V16.6982C0.195313 17.4874 0.508818 18.2443 1.06686 18.8023C1.6249 19.3604 2.38177 19.6739 3.17096 19.6739H21.0249C21.8141 19.6739 22.5709 19.3604 23.129 18.8023C23.687 18.2443 24.0005 17.4874 24.0005 16.6982V8.28905Z" fill={fill} />
      <path d="M11.3044 11.4512C11.5408 11.5981 11.8136 11.676 12.092 11.676C12.3703 11.676 12.6431 11.5981 12.8795 11.4512L23.9251 3.92875C23.9474 3.91615 23.966 3.89803 23.9792 3.87614C23.9924 3.85425 23.9998 3.82933 24.0005 3.80378C24.0005 3.01458 23.687 2.25772 23.129 1.69967C22.5709 1.14163 21.8141 0.828125 21.0249 0.828125L3.17097 0.828125C2.38178 0.828125 1.62491 1.14163 1.06687 1.69967C0.508823 2.25772 0.195319 3.01458 0.195319 3.80378C0.195089 3.82884 0.201367 3.85352 0.213537 3.87543C0.225707 3.89734 0.243353 3.91571 0.26475 3.92875L11.3044 11.4512Z" fill={fill} />
    </svg>
  );
};

export default MailIcon;
