import { Chip } from '@mui/material';

const Tag = (props) => {
  const {
    sx,
    ...rest
  } = props;

  const styles = {
    maxWidth: 180,
    paddingRight: 0.5,
    borderRadius: 4,
    '& .MuiChip-deleteIcon': {
      mx: 1,
      '&:hover': {
        color: 'common.couchGrey',
      },
    },
    ...sx,
  };

  return (
    <Chip
      color="tags"
      {...rest}
      sx={styles}
    />
  );
};

export default Tag;
