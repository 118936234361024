import END_POINTS from '../../endPoints';
import METHODS from '../../methods';
import api from '../../api';

const deleteProvider = (id) => api(
  END_POINTS.provider(id),
  {
    method: METHODS.DELETE,
  },
)
  .then((response) => Promise.resolve(response))
  .catch((error) => Promise.reject(error));

export default deleteProvider;
