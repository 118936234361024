import useCustomQuery from 'reactQuery/useCustomQuery';
import { getLandingStatistics } from 'services';

const useStatistics = () => {
  const query = useCustomQuery({
    queryKey: ['landing-statistics'],
    queryFn: getLandingStatistics,
    options: { refetchOnWindowFocus: false },
  });

  return { ...query };
};

export default useStatistics;
