import { useParams } from 'react-router-dom';
import { useCustomQuery } from 'reactQuery';
import { getTag } from 'services';

const useTagDetails = () => {
  const { tagId } = useParams();

  const query = useCustomQuery({
    queryKey: [`tag-details-${tagId}`],
    queryFn: () => getTag(tagId),
  });

  return { ...query };
};

export default useTagDetails;
