import { camelCase } from 'lodash';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import {
  Link,
  Typography,
  Breadcrumbs as MUIBreadcrumbs,
} from '@mui/material';
import { useLocale } from 'util/hooks';

const Breadcrumbs = (props) => {
  const { t } = useLocale();
  const { pathname } = useLocation();
  const [crumbs, setCrumbs] = useState([]);

  const {
    includeLastCrumb = true,
    ...rest
  } = props;

  useEffect(() => {
    const pathCrumbs = pathname?.split('/').filter((path) => path);
    if (!includeLastCrumb) {
      pathCrumbs.pop();
    }
    setCrumbs(pathCrumbs);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const breadCrumbs = crumbs?.map((crumb, index) => {
    const isLastCrumb = (index === crumbs.length - 1);
    const linkToCrumb = `/${crumbs.slice(0, index + 1).join('/')}`;
    const localizedCrumb = t(`paths.${camelCase(crumb)}`);

    if (isLastCrumb) {
      return (
        <Typography
          key={crumb}
          variant="bodyStandardMedium"
          sx={{ color: 'common.darkGray' }}
        >
          {localizedCrumb}
        </Typography>
      );
    }
    return (
      <Link
        key={crumb}
        to={linkToCrumb}
      >
        <Typography
          variant="bodyStandardMedium"
          sx={{ color: 'text.secondary' }}
        >
          {localizedCrumb}
        </Typography>
      </Link>
    );
  });

  return (
    <MUIBreadcrumbs aria-label="breadcrumb" {...rest}>
      {breadCrumbs}
    </MUIBreadcrumbs>

  );
};

export default Breadcrumbs;
