import { CardMedia as MUICardMedia } from '@mui/material';
import { useLocale } from 'util/hooks';
import { courseDefaultImageAr, courseDefaultImageEn } from 'assets/images';

const CardMediaWithFallback = (props) => {
  const { src, ...rest } = props;
  const { isAr } = useLocale();

  const coverDefault = isAr ? courseDefaultImageAr : courseDefaultImageEn;

  return (
    <MUICardMedia
      src={src || coverDefault}
      onError={(e) => {
        if (e?.target?.nodeName === 'IMG') { // Ran into an infinite loop with videos
          e.target.src = coverDefault;
        }
      }}
      {...rest}
    />
  );
};

export default CardMediaWithFallback;
