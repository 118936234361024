import {
  Box,
  Typography,
} from '@mui/material';

import { useLocale } from 'util/hooks';

const CoursesHeader = () => {
  const { t } = useLocale();
  return (
    <Box sx={{
      mx: 2,
      display: 'flex',
      flexDirection: 'column',
      mb: 7,
    }}
    >
      <Typography
        variant="h3"
        color="common.white"
        sx={{ my: { xs: 2, sm: 4 }, maxWidth: 450 }}
      >
        {t('courses.coursesHeader')}
      </Typography>
      <Typography
        variant="bodyMediumRegular"
        color="common.white"
        sx={{
          my: { xs: 2, sm: 4 },
          maxWidth: 450,
          fontSize: {
            xs: '0.8125rem',
            sm: '1.25rem',
          },
        }}
      >
        {t('courses.coursesSubheader')}
      </Typography>
    </Box>
  );
};

export default CoursesHeader;
