import { useParams } from 'react-router-dom';
import { getClubProfileMostViewedPosts } from 'services';
import useCustomQuery from 'reactQuery/useCustomQuery';

const useClubProfileMostViewedPosts = (queryProps = {}) => {
  const { clubId } = useParams();

  const query = useCustomQuery({
    queryKey: [`club-profile-most-viewed-posts-${clubId}`],
    queryFn: () => getClubProfileMostViewedPosts(clubId),
    ...queryProps,
  });

  return { ...query };
};

export default useClubProfileMostViewedPosts;
