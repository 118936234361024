import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/styles';

import { useProfileRank } from 'reactQuery/queries';
import { useLocale } from 'util/hooks';
import {
  CyberhubMedalIcon,
  UniversityMedalIcon,
  PointsWithShadowIcon,
  CyberhubShieldIcon,
} from 'assets/icons';
import { UniversityLogoPlaceholder } from 'assets/images';

const containerStyle = {
  position: 'relative',
};

const onTopStyle = {
  position: 'absolute',
  left: 0,
  top: 0,
  right: 0,
  bottom: 20,
  display: 'block',
  margin: 'auto',
};

const StudentProfileRank = () => {
  const { t } = useLocale();
  const { palette } = useTheme();

  const {
    data = {},
  } = useProfileRank();

  const {
    user = {},
    club = {},
  } = data;

  const {
    rank = '',
    clubRank = '',
    totalPoints: { totalPoints = 0 } = {},
  } = user;
  const {
    logo: universityLogo = '',
    abbreviation = '',
  } = club;

  return (
    <Card sx={{ p: 5, borderRadius: 0 }}>
      <Stack
        spacing={5}
        maxWidth="sm"
        mx="auto"
      >
        <Box mx="auto">
          <Stack
            spacing={2}
            direction="row"
            alignItems="center"
          >
            <PointsWithShadowIcon
              width="47"
              height="47"
              innerFill={palette.common.vividOrange}
              rectFill={palette.common.lightVividOrange}
            />
            <Stack
              spacing={1}
              direction="row"
              alignItems="end"
            >
              <Typography variant="h3">
                {totalPoints || 0}
              </Typography>
              <Typography variant="bodyStandardRegular">
                {t('common.totalPoints')}
              </Typography>
            </Stack>
          </Stack>
        </Box>
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        >
          <Stack
            spacing={2}
            direction="row"
            alignItems="center"
          >
            <Box sx={containerStyle}>
              <UniversityMedalIcon
                width="54"
                height="83"
              />
              <img
                src={universityLogo || UniversityLogoPlaceholder}
                alt={`${abbreviation}-logo`}
                width="22"
                height="auto"
                style={onTopStyle}
              />
            </Box>
            <Stack alignItems="center">
              <Typography variant="bodyStandardMedium">
                {Number.isInteger(clubRank)
                  ? t('helpers.count', { count: clubRank, ordinal: true })
                  : t('common.unranked')}
              </Typography>
              <Typography variant="bodySmallRegular">
                {`${t('common.on')} ${abbreviation}`}
              </Typography>
            </Stack>
          </Stack>
          <Divider orientation="vertical" sx={{ mx: 2, height: 15 }} />
          <Stack
            spacing={2}
            direction="row"
            alignItems="center"
          >
            <Box sx={containerStyle}>
              <CyberhubMedalIcon
                width="54"
                height="83"
              />
              <CyberhubShieldIcon
                width="22"
                height="25"
                style={onTopStyle}
              />
            </Box>
            <Stack alignItems="center">
              <Typography variant="bodyStandardMedium">
                {Number.isInteger(rank)
                  ? t('helpers.count', { count: rank, ordinal: true })
                  : t('common.unranked')}
              </Typography>
              <Typography variant="bodySmallRegular">
                {`${t('common.on')} ${t('common.cyberHub')}`}
              </Typography>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Card>
  );
};

export default StudentProfileRank;
