import {
  Box,
  Card,
} from '@mui/material';

import { useLocale } from 'util/hooks';
import { CourseDetailsCard } from 'components/organisms';
import { Tabs } from 'components/molecules';
import RecordedSessions from './RecordedSessions';

const RecordedCourseDetailsWrapper = (props) => {
  const {
    courseDetails = {},
    refetchCourse = () => {},
  } = props;

  const { t } = useLocale();
  const tabs = [
    {
      label: t('courses.course'),
      component: <CourseDetailsCard
        courseDetails={courseDetails}
        refetchCourse={refetchCourse}
        CardStyle={{ boxShadow: 'none' }}
      />,
    },
    {
      label: t('courses.recordedSessions'),
      component: <RecordedSessions
        courseDetails={courseDetails}
      />,
    },

  ];

  return (
    <Box>
      <Card sx={{
        px: { xs: 0, md: 6 },
        py: 6,
        pb: 25,
        display: 'flex',
        flexDirection: 'column',
        boxShadow: (theme) => `0px 4px 4px ${theme.palette.common.boxShadow}`,
      }}
      >
        <Box>
          <Tabs
            tabs={tabs}
            sx={{
              '& .MuiTabs-scroller': {
                display: 'flex',
                justifyContent: 'center',
              },
              '& .MuiTab-root': {
                '&.Mui-selected': {
                  color: 'common.darkGray',
                },
              },
            }}
          />
        </Box>
      </Card>
    </Box>
  );
};

export default RecordedCourseDetailsWrapper;
