const TABLE_ACTIONS = {
  userManagement: {
    resend: 'resend',
    cancel: 'cancel',
    suspend: 'suspend',
    inactivateByAdmin: 'inactivateByAdmin',
    unverifyMobile: 'unverifyMobile',
    verifyMobile: 'verifyMobile',
    unverifyNafath: 'unverifyNafath',
    verifyNafath: 'verifyNafath',
    setAsUndergraduate: 'setAsUndergraduate',
    setAsGraduate: 'setAsGraduate',
    activateByAdmin: 'activateByAdmin',
    inactivateByClubManager: 'inactivateByClubManager',
    activateByClubManager: 'activateByClubManager',
    setAsUndergraduateByClubManager: 'setAsUndergraduateByClubManager',
    setAsGraduateByClubManager: 'setAsGraduateByClubManager',
    setAsLeader: 'setAsLeader',
    activate: 'activate',
    inactivate: 'inactivate',
  },
  platformSettings: {
    hide: 'hide',
    makeVisible: 'makeVisible',
    delete: 'delete',
  },
  courseAttendance: {
    revoke: 'revoke',
    setEnrolled: 'setEnrolled',
  },
};

export default TABLE_ACTIONS;
