import { useTheme } from '@mui/styles';

const LevelIcon = (props) => {
  const { palette } = useTheme();

  const {
    fill = 'none',
    innerFill = palette.common.couchGrey,
  } = props;

  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M17.5 4H20.5V20H17.5V4ZM5.5 14H8.5V20H5.5V14ZM11.5 9H14.5V20H11.5V9Z" fill={innerFill} />
    </svg>
  );
};

export default LevelIcon;
