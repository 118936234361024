import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import {
  Typography, Box,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FormCheckbox, FormTextField } from 'components/form';
import { getDirtyObject, REGEX } from 'util/helpers';
import { useFastForm, useLocale, useSnackbar } from 'util/hooks';
import { createInterest, updateInterest } from 'services';
import { PATHS } from 'routes';

const InterestDetailsForm = (props) => {
  const {
    interestDetails,
    createMode,
  } = props;

  const { t } = useLocale();
  const snack = useSnackbar();
  const navigate = useNavigate();

  const onSubmit = async (values) => {
    const dirtyPayload = getDirtyObject(values, defaultValues);

    try {
      let message = '';

      if (createMode) {
        await createInterest(values);
        message = t('interests.form.interestAdded');
      } else {
        await updateInterest(dirtyPayload, interestDetails.id);
        message = t('interests.form.interestEdited');
      }
      snack({
        severity: 'success',
        message,
      });
      navigate(`/${PATHS.platformSettings}/${PATHS.interestsSettings}`);
    } catch (error) {
      error.errors?.forEach((err) => {
        setError(err.property, {
          type: 'api',
          message: err.message,
        });
        if (!err.property) {
          snack({
            severity: 'error',
            message: err.message || t('common.somethingWrong'),
          });
        }
      });
    }
  };

  const defaultValues = {
    nameEn: interestDetails?.nameEn || '',
    nameAr: interestDetails?.nameAr || '',
    visibility: interestDetails?.visibility || false,
  };

  const validationSchema = useMemo(() => Yup.object({
    nameEn: Yup
      .string()
      .trim()
      .required(t('interests.form.validation.enterName'))
      .max(30, t('interests.form.validation.maxLength'))
      .matches(REGEX.alphaNumericEnSpace, t('interests.form.validation.enterNameEn')),
    nameAr: Yup
      .string()
      .trim()
      .required(t('interests.form.validation.enterName'))
      .max(30, t('interests.form.validation.validation.maxLength'))
      .matches(REGEX.alphaNumericArSpace, t('interests.form.validation.enterNameAr')),
    visibility: Yup
      .bool(),
  }), [t]);

  const {
    control,
    handleSubmit,
    watch,
    setError,
    formState: {
      isSubmitting,
      isDirty,
      isValid,
    },
  } = useFastForm({
    defaultValues,
    validationSchema,
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box>
        <Box sx={{
          display: 'grid',
          rowGap: 5,
        }}
        >
          <Box>
            <FormTextField
              name="nameEn"
              control={control}
              label={t('interests.form.interestNameEn')}
              disabled={isSubmitting}
              fullWidth
            />
          </Box>
          <Box>
            <FormTextField
              name="nameAr"
              control={control}
              label={t('interests.form.interestNameAr')}
              disabled={isSubmitting}
              fullWidth
            />
          </Box>
          <Box>
            <FormCheckbox
              name="visibility"
              control={control}
              disabled={isSubmitting}
              checkboxProps={{
                checked: watch('visibility'),
              }}
              label={(
                <div>
                  <Typography variant="bodyStandardRegular">
                    {t('interests.form.setAsVisible')}
                  </Typography>
                </div>
              )}
            />
          </Box>
        </Box>
        <Box sx={{
          my: 8,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        >
          <LoadingButton
            type="submit"
            variant="contained"
            size="fixed"
            loading={isSubmitting}
            disabled={isSubmitting || !isDirty || !isValid}
          >
            {createMode ? t('interests.form.addInterest') : t('common.saveChanges')}
          </LoadingButton>
        </Box>
      </Box>
    </form>
  );
};

InterestDetailsForm.propTypes = {
  interestDetails: PropTypes.oneOfType([PropTypes.object]),
  createMode: PropTypes.bool,
};

InterestDetailsForm.defaultProps = {
  interestDetails: {},
  createMode: false,
};

export default InterestDetailsForm;
