import { GridActionsCellItem } from '@mui/x-data-grid';
import { getStatusByName, STATUS_NAMES } from 'assets/constants/statuses';
import TABLE_ACTIONS from 'assets/constants/tableActions';
import MODAL_KEYS from 'assets/constants/modalKeys';
import { useLocale, useModal } from 'util/hooks';
import { Status } from 'components/molecules';

const useTableContentCreatorColumns = (props = {}) => {
  const { refetchUsers = () => { } } = props;
  const { addModal } = useModal();

  const { t, dir } = useLocale();

  const handleOpenModal = (type, username) => {
    addModal({
      key: MODAL_KEYS.userStatus,
      props: {
        type,
        username,
        refetchUsers,
      },
    });
  };

  return (
    [
      {
        field: 'fullName',
        headerName: t('common.name'),
        flex: 1,
        minWidth: 250,
      },
      {
        field: 'email',
        headerName: t('common.email'),
        flex: 1,
        minWidth: 325,
      },
      {
        field: 'providerName',
        headerName: t('common.provider'),
        flex: 1,
        minWidth: 250,
      },
      {
        field: 'status',
        headerName: t('common.status'),
        flex: 1,
        minWidth: 180,
        renderCell: (params) => (
          <Status
            status={params.row.status || ''}
            label={t(getStatusByName(params?.row?.status)?.localizationKey) || ''}
          />
        ),
      },
      {
        field: 'actions',
        headerName: t('common.actions'),
        type: 'actions',
        flex: 1,
        minWidth: 50,
        getActions: ({ row }) => {
          const { status, username } = row;
          const isActive = status === getStatusByName(STATUS_NAMES.active).name;
          const isPending = status === getStatusByName(STATUS_NAMES.pending).name;
          const isSuspended = status === getStatusByName(STATUS_NAMES.suspended).name;

          const actions = [];
          if (isActive) {
            actions.push(
              <GridActionsCellItem
                dir={dir}
                label={t('actions.suspendUser')}
                onClick={() => handleOpenModal(
                  TABLE_ACTIONS.userManagement.suspend,
                  username,
                )}
                showInMenu
              />,
            );
          } else if (isPending || isSuspended) {
            actions.push(
              <GridActionsCellItem
                dir={dir}
                label={t('actions.activateUser')}
                onClick={() => handleOpenModal(
                  TABLE_ACTIONS.userManagement.activateByAdmin,
                  username,
                )}
                showInMenu
              />,
            );
          }

          return actions;
        },
      },
    ]
  );
};

export default useTableContentCreatorColumns;
