import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';

import { LoadingButton } from '@mui/lab';
import {
  Typography, Box,
} from '@mui/material';

import { FormCheckbox, FormTextField } from 'components/form';
import { getDirtyObject, REGEX } from 'util/helpers';
import { useFastForm, useLocale, useSnackbar } from 'util/hooks';
import { updateTag, createTag } from 'services';
import { PATHS } from 'routes';

const TagDetailsForm = (props) => {
  const {
    tagDetails = {},
    createMode = false,
  } = props;

  const { tagId } = useParams();
  const { t } = useLocale();
  const snack = useSnackbar();
  const navigate = useNavigate();

  const onSubmit = async (values) => {
    const dirtyPayload = getDirtyObject(values, defaultValues);

    try {
      let message = '';

      if (createMode) {
        await createTag(values);
        message = t('tags.forms.tagAdded');
      } else {
        await updateTag(dirtyPayload, tagId);
        message = t('tags.forms.tagEdited');
      }

      snack({
        severity: 'success',
        message,
      });
      navigate(`/${PATHS.platformSettings}/${PATHS.tagsSettings}`);
    } catch (error) {
      error.errors?.forEach((err) => {
        setError(err.property, {
          type: 'api',
          message: err.message,
        });
        if (!err.property) {
          snack({
            severity: 'error',
            message: err.message || t('common.somethingWrong'),
          });
        }
      });
    }
  };

  const defaultValues = {
    nameEn: tagDetails?.nameEn || '',
    nameAr: tagDetails?.nameAr || '',
    visibility: tagDetails?.visibility || false,
  };

  const validationSchema = Yup.object({
    nameEn: Yup.string()
      .trim()
      .required(t('tags.forms.validation.enterNameEn'))
      .max(30, t('tags.forms.validation.maxLength'))
      .matches(REGEX.alphaNumericEnSpace, t('tags.forms.validation.enterNameEn')),
    nameAr: Yup.string()
      .trim()
      .required(t('tags.forms.validation.enterNameAr'))
      .max(30, t('tags.forms.validation.maxLength'))
      .matches(REGEX.alphaNumericArSpace, t('tags.forms.validation.enterNameAr')),
    visibility: Yup
      .bool(),
  });

  const {
    control,
    handleSubmit,
    watch,
    setError,
    formState: {
      isSubmitting,
      isDirty,
      isValid,
    },
  } = useFastForm({
    defaultValues,
    validationSchema,
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box>
        <Box sx={{
          display: 'grid',
          rowGap: 5,
        }}
        >
          <Box>
            <FormTextField
              name="nameEn"
              control={control}
              label={t('tags.forms.tagNameEn')}
              disabled={isSubmitting}
              fullWidth
            />
          </Box>
          <Box>
            <FormTextField
              name="nameAr"
              control={control}
              label={t('tags.forms.tagNameAr')}
              disabled={isSubmitting}
              fullWidth
            />
          </Box>
          <Box>
            <FormCheckbox
              name="visibility"
              control={control}
              disabled={isSubmitting}
              checkboxProps={{
                checked: watch('visibility'),
              }}
              label={(
                <div>
                  <Typography variant="bodyStandardRegular">
                    {t('tags.forms.setAsVisible')}
                  </Typography>
                </div>
              )}
            />
          </Box>
        </Box>
        <Box sx={{
          my: 8,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        >
          <LoadingButton
            type="submit"
            variant="contained"
            size="fixed"
            loading={isSubmitting}
            disabled={isSubmitting || !isDirty || !isValid}
          >
            {createMode ? t('tags.forms.addTag') : t('common.saveChanges')}
          </LoadingButton>
        </Box>
      </Box>
    </form>
  );
};

export default TagDetailsForm;
