import { useTheme } from '@mui/styles';

const LevelGreenIcon = (props) => {
  const { palette } = useTheme();

  const {
    fill = 'none',
    rectFill = palette.common.turquoiseSeaweedGreen,
    innerFill = palette.common.turquoiseSeaweedGreen,
  } = props;

  return (
    <svg width="33" height="33" viewBox="0 0 34 35" fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect x="0.244141" y="0.899902" width="33.686" height="33.7477" rx="16.843" fill={rectFill} fillOpacity="0.2" />
      <path d="M19.8999 13.0864H21.7748V22.4608H19.8999V13.0864ZM12.4004 18.9454H14.2753V22.4608H12.4004V18.9454ZM16.1501 16.0159H18.025V22.4608H16.1501V16.0159Z" fill={innerFill} />
      <rect x="19.9004" y="13.0864" width="1.87487" height="9.37436" fill="#E9E9E9" />
      <rect x="19.9004" y="13.0864" width="1.87487" height="9.37436" fill="#C4C4C4" />
      <rect x="16.1494" y="15.8989" width="1.87487" height="6.56205" fill="#E9E9E9" />
      <rect x="16.1494" y="15.8989" width="1.87487" height="6.56205" fill="#C4C4C4" />
    </svg>
  );
};

export default LevelGreenIcon;
