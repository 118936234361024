import { useTheme } from '@mui/styles';

const FlagyardIcon = (props) => {
  const { palette } = useTheme();

  const {
    fill = palette.common.flagyardRed,
    innerFill = palette.common.white,
    ...rest
  } = props;

  return (
    <svg width="116" height="30" viewBox="0 0 116 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d="M115.496 29.5L0.616302 23.8269L0.55957 5.9L115.496 0V29.5Z" fill={fill} />
      <path d="M0.50293 23.8274V5.84375H12.076V8.85048H3.90678V13.2188H10.9981V16.2255H3.90678V23.8274H0.50293Z" fill={innerFill} />
      <path d="M13.7783 23.8274V5.84375H17.1822V20.8207H24.2735V23.8274H13.7783Z" fill={innerFill} />
      <path d="M37.2067 23.8274L35.7884 19.2322H29.3779L27.9596 23.8274H24.499L30.5125 5.84375H34.7106L40.6673 23.8274H37.2067ZM32.6683 8.90721H32.5548L30.2856 16.2822H35.0509L32.6683 8.90721Z" fill={innerFill} />
      <path d="M52.6337 21.048H52.5203C52.2366 22.8067 50.5347 24.1682 47.8116 24.1682C43.6135 24.1682 40.3799 20.9346 40.3799 14.9211C40.3799 8.90766 43.6135 5.56055 48.3789 5.56055C51.5558 5.56055 53.8818 7.03555 55.1866 9.64516L52.4068 11.2904C51.7828 9.75862 50.478 8.56728 48.3789 8.56728C45.7126 8.56728 43.9539 10.2692 43.9539 13.3894V16.2827C43.9539 19.4029 45.7126 21.1048 48.4357 21.1048C50.5347 21.1048 52.2933 20.0836 52.2933 18.0413V16.9067H48.776V14.0702H55.5837V23.8846H52.6337V21.048Z" fill={innerFill} />
      <path d="M61.2051 23.8275V16.7361L55.0215 5.78711H58.8224L62.9638 13.389H63.0205L67.0484 5.78711H70.7359L64.5523 16.6794V23.7708H61.2051V23.8275Z" fill={innerFill} />
      <path d="M81.0602 23.8274L79.642 19.2322H73.2314L71.8131 23.8274H68.3525L74.366 5.84375H78.5641L84.5208 23.8274H81.0602ZM76.465 8.90721H76.3516L74.0823 16.2822H78.8477L76.465 8.90721Z" fill={innerFill} />
      <path d="M89.0611 23.8274H85.6572V5.84375H93.7697C97.0601 5.84375 99.1024 7.99952 99.1024 11.4034C99.1024 13.8995 97.9678 15.7716 95.7553 16.5091L99.4428 23.8274H95.6418L92.2947 16.9062H89.0611V23.8274ZM93.4293 14.013C94.7341 14.013 95.5284 13.2755 95.5284 11.9707V10.7793C95.5284 9.47452 94.7341 8.79375 93.4293 8.79375H89.0611V14.013H93.4293Z" fill={innerFill} />
      <path d="M101.201 5.84375H107.725C112.434 5.84375 115.497 8.85048 115.497 14.8639C115.497 20.8774 112.434 23.8841 107.725 23.8841H101.201V5.84375ZM107.725 20.8207C110.278 20.8207 111.867 19.3457 111.867 16.2822V13.4457C111.867 10.3822 110.221 8.90721 107.725 8.90721H104.605V20.8774H107.725V20.8207Z" fill={innerFill} />
    </svg>
  );
};

export default FlagyardIcon;
