import _ from 'lodash';
import DOMPurify from 'dompurify';
import { Box } from '@mui/material';
import parse from 'html-react-parser';
import { useParams } from 'react-router-dom';

import { useSessionRecordings } from 'reactQuery/queries';

const sanitizeOptions = {
  ALLOWED_TAGS: ['iframe'],
  ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'],
};

const ViewRecordedSessionsContainer = () => {
  const { courseId, sessionId } = useParams();

  const {
    data: sessionRecording = {},
  } = useSessionRecordings({
    courseId,
    sessionId,
  });

  return (
    !_.isEmpty(sessionRecording)
        && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {parse(DOMPurify.sanitize(sessionRecording?.embed?.html, sanitizeOptions))}
        </Box>
        )
  );
};

export default ViewRecordedSessionsContainer;
