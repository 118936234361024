import END_POINTS from '../../endPoints';
import api from '../../api';

const getAllTags = (options) => api(
  END_POINTS.tags,
  options,
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default getAllTags;
