import { useTheme } from '@mui/styles';

const CopyLinkIcon = (props) => {
  const { palette } = useTheme();

  const {
    fill = 'none',
    stroke = palette.common.darkGray,
  } = props;

  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M8.33833 14.7666L6.87883 16.2246C6.33714 16.7392 5.61346 17.0179 4.86653 16.9997C4.1196 16.9814 3.41041 16.6677 2.89451 16.1272L1.87332 15.106C1.33287 14.5901 1.0191 13.8809 1.00084 13.134C0.982584 12.3871 1.26132 11.6634 1.77591 11.1217L5.94287 6.95475C6.40699 6.51327 7.02739 6.27409 7.66776 6.28979C8.30812 6.30548 8.91607 6.57476 9.358 7.03845L10.16 7.84049" stroke={stroke} strokeOpacity="0.34" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.52963 3.36578L11.0607 1.84389C11.6027 1.30347 12.3369 1 13.1023 1C13.8677 1 14.6019 1.30347 15.1439 1.84389L16.1636 2.86356C16.704 3.40559 17.0075 4.13977 17.0075 4.90518C17.0075 5.67059 16.704 6.40477 16.1636 6.94681L11.7896 11.3146C11.5599 11.5447 11.2871 11.7273 10.9868 11.8518C10.6865 11.9763 10.3646 12.0404 10.0395 12.0404C9.71435 12.0404 9.39243 11.9763 9.09212 11.8518C8.7918 11.7273 8.51899 11.5447 8.28929 11.3146L7.48877 10.5187" stroke={stroke} strokeOpacity="0.34" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default CopyLinkIcon;
