import {
  Box,
  Link,
  Card,
  Button,
  CardActions,
  IconButton,
  Typography,
} from '@mui/material';

import { COURSE_MATERIAL_TYPES } from 'assets/constants/courses';
import MODAL_KEYS from 'assets/constants/modalKeys';
import {
  EditIcon,
  TrashIcon,
  TextFilledIcon,
  VideoFilledIcon,
  AttachmentFilledIcon,
} from 'assets/icons';
import { useAuth, useLocale, useModal } from 'util/hooks';
import DOMPurify from 'dompurify';

import { useCourseDetails } from 'reactQuery/queries';
import { toHoursAndMinutes } from 'util/helpers';

const ChapterMaterialCard = (props) => {
  const { isAr } = useLocale();
  const { addModal } = useModal();
  const { isAdmin, isContentCreator, currentUser } = useAuth();

  const {
    materialDetails = {},
  } = props;

  const {
    id = '',
    title = '',
    chapterId = '',
    materialNumber = '',
    type = '',
    createdById = '',
    managedById = '',
    duration = '',
    link = '',
    content = '',
  } = materialDetails;

  const isText = type === COURSE_MATERIAL_TYPES.text;
  const isAttachment = type === COURSE_MATERIAL_TYPES.attachment;
  const isVideo = type === COURSE_MATERIAL_TYPES.video;

  const isAuthor = currentUser.id === createdById;
  const isManagedBy = currentUser.id === managedById;

  const canManage = isAdmin || (isContentCreator && (isAuthor || isManagedBy));

  const localizedHour = isAr ? 'س' : 'h';
  const localizedMinute = isAr ? 'د' : 'm';

  const {
    refetch: refetchCourse = () => {},
  } = useCourseDetails();

  const getModalType = () => {
    let modalType = '';

    if (isText) {
      modalType = MODAL_KEYS.courseText;
    } if (isAttachment) {
      modalType = MODAL_KEYS.courseAttachment;
    } if (isVideo) {
      modalType = MODAL_KEYS.courseVideo;
    }

    return modalType;
  };

  const handleEditMaterial = () => {
    addModal({
      key: getModalType(),
      props: {
        chapterId,
        materialDetails,
      },
    });
  };

  const handleDeleteMaterial = () => {
    addModal({
      key: MODAL_KEYS.deleteMaterial,
      props: {
        chapterId,
        materialId: id,
        refetchCourse,
      },
    });
  };

  const materialTitle = (
    <Typography variant="bodyStandardMedium">{`${materialNumber}. ${title}`}</Typography>
  );

  const renderMaterialIcon = () => {
    switch (type) {
      case COURSE_MATERIAL_TYPES.video: return <VideoFilledIcon />;
      case COURSE_MATERIAL_TYPES.attachment: return <AttachmentFilledIcon />;
      case COURSE_MATERIAL_TYPES.text: return <TextFilledIcon />;
      default: return <TextFilledIcon />;
    }
  };

  const materialActions = (
    <CardActions sx={{
      display: 'flex',
      justifyContent: { xs: 'start', sm: 'end' },
      alignItems: 'center',
      p: 0,
    }}
    >
      <Box sx={{ p: 1 }}>
        <IconButton
          disableRipple
          onClick={handleDeleteMaterial}
          aria-label="delete"
          sx={{ p: 0 }}
        >
          <TrashIcon
            width="24"
            height="24"
          />
        </IconButton>
      </Box>
      <Box sx={{ p: 1 }}>
        <IconButton
          disableRipple
          onClick={handleEditMaterial}
          aria-label="edit"
          sx={{ p: 0 }}
        >
          <EditIcon
            width="24"
            height="24"
          />
        </IconButton>
      </Box>
    </CardActions>
  );

  const renderCardHeader = () => (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <Box
        display="flex"
        alignItems="center"
      >
        {renderMaterialIcon()}
        <Box marginLeft={3}>
          {!isText ? (
            <Box
              display="flex"
              alignItems="baseline"
            >
              <Button
                component={Link}
                to={link}
                sx={{ p: 0 }}
              >
                {materialTitle}
              </Button>
              {duration && (
              <Typography ml={3} variant="bodySmallRegular">
                {`• ${toHoursAndMinutes(duration, localizedHour, localizedMinute)}`}
              </Typography>
              )}
            </Box>
          ) : (
            materialTitle
          )}
        </Box>
      </Box>
      <Box>
        {canManage && materialActions}
      </Box>
    </Box>
  );

  return (
    <Card
      key={id}
      sx={{
        p: 5,
        border: 'solid 1px',
        borderColor: 'common.silverGrey',
      }}
    >
      {renderCardHeader()}
      {isText && (
      <Box
        mt={3}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(content),
        }}
      />
      )}
    </Card>
  );
};

export default ChapterMaterialCard;
