import { useTheme } from '@mui/styles';

const InfoWithCircleIcon = (props) => {
  const { palette } = useTheme();

  const {
    fill = palette.common.darkGray,
    ...rest
  } = props;

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d="M8 15.9971C3.58892 15.9971 0 12.4081 0 7.99707C0 3.58599 3.58892 -0.00292969 8 -0.00292969C12.4111 -0.00292969 16 3.58599 16 7.99707C16 12.4081 12.4111 15.9971 8 15.9971ZM8 1.22784C4.26749 1.22784 1.23077 4.26456 1.23077 7.99707C1.23077 11.7296 4.26749 14.7663 8 14.7663C11.7325 14.7663 14.7692 11.7296 14.7692 7.99707C14.7692 4.26456 11.7325 1.22784 8 1.22784Z" fill={fill} />
      <path d="M6.76773 12.3052C6.42804 12.3052 6.15234 12.0295 6.15234 11.6898C6.15234 11.3501 6.42804 11.0744 6.76773 11.0744H7.38311V7.38214H6.76773C6.42804 7.38214 6.15234 7.10644 6.15234 6.76675C6.15234 6.42706 6.42804 6.15137 6.76773 6.15137H7.9985C8.33819 6.15137 8.61388 6.42706 8.61388 6.76675V11.0744H9.22927C9.56896 11.0744 9.84465 11.3501 9.84465 11.6898C9.84465 12.0295 9.56896 12.3052 9.22927 12.3052H6.76773Z" fill={fill} />
      <path d="M8.0012 4.9233C8.511 4.9233 8.92428 4.51003 8.92428 4.00023C8.92428 3.49042 8.511 3.07715 8.0012 3.07715C7.4914 3.07715 7.07812 3.49042 7.07812 4.00023C7.07812 4.51003 7.4914 4.9233 8.0012 4.9233Z" fill={fill} />
    </svg>
  );
};
export default InfoWithCircleIcon;
