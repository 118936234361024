import {
  Box,
  TextField,
  Autocomplete as MUIAutocomplete,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { forwardRef, useEffect, useState } from 'react';
import { useLocale, useSnackbar } from 'util/hooks';
import { capitalizeFirstLetter } from 'util/helpers';

const Autocomplete = forwardRef((props, ref) => {
  const {
    name,
    error,
    label,
    icon,
    withOptions = false,
    selectFunc,
    required,
    ...rest
  } = props;

  const { t, dir, isAr } = useLocale();
  const snack = useSnackbar();

  const [selectOptions, setSelectOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const textLabel = icon
    ? (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {icon}
        <Box sx={{ pl: 2 }}>
          {label}
        </Box>
        {/* Workaround cuz marking field as required with custom label breaks  */}
        {required ? '*' : ''}
      </Box>
    )
    : label;

  useEffect(() => {
    const optionsCallFunc = async () => {
      setIsLoading(true);
      try {
        const { items = {} } = await selectFunc();
        setSelectOptions(items);
      } catch (err) {
        snack({
          message: err.message || t('common.somethingWrong'),
          severity: 'error',
        });
      }
      setIsLoading(false);
    };

    !withOptions && optionsCallFunc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectFunc]);

  const getOptionLabel = (option) => {
    if (!option) return '';

    return (isAr
      ? (option.nameAr || option.fullNameAr || option.firstNameAr || option.lastNameAr)
      : (option.nameEn || option.fullName || option.firstNameEn || option.lastNameEn))
      || option.name;
  };

  const isOptionEqualToValue = (option, value) => {
    if (value === '') return true; // Prevent onClear warning

    if (typeof value === 'object') {
      return option.id === value.id;
    }

    const optionString = (isAr
      ? (option.nameAr || option.fullNameAr || option.firstNameAr || option.lastNameAr)
      : (option.nameEn || option.fullName || option.firstNameEn || option.lastNameEn))
      || option.name;
    return optionString === value;
  };

  return (
    <MUIAutocomplete
      ref={ref}
      id={name}
      options={selectOptions}
      fullWidth
      popupIcon={<ExpandMore />}
      loading={isLoading}
      autoHighlight
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={isOptionEqualToValue}
      renderOption={(optionProps, {
        nameAr,
        nameEn,
        name: optionName,
        fullName,
        fullNameAr,
      }) => (
        <Box dir={dir} {...optionProps}>
          {(isAr
            ? (nameAr || fullNameAr)
            : (capitalizeFirstLetter(nameEn) || capitalizeFirstLetter(fullName)))
            || capitalizeFirstLetter(optionName)}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          error={error}
          label={textLabel || ''}
        />
      )}
      {...rest}
    />
  );
});

export default Autocomplete;
