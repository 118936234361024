import METHODS from 'services/methods';
import END_POINTS from 'services/endPoints';
import api from 'services/api';

const startSession = (courseId, sessionId) => api(
  END_POINTS.startSession(courseId, sessionId),
  {
    method: METHODS.POST,
  },
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default startSession;
