import { Box } from '@mui/material';
import { LeaderboardTopThreeListItem } from 'components/molecules';

const LeaderboardTopThree = (props) => {
  const {
    topThree = {},
  } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: { xs: 'column', sm: 'row' },
        px: 6,
        pb: 10,
      }}
    >
      {topThree?.map((item) => (
        <LeaderboardTopThreeListItem
          key={item.username || item.name}
          {...item}
        />
      )) }
    </Box>
  );
};

export default LeaderboardTopThree;
