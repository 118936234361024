import { useTheme } from '@mui/styles';

const SessionTimeIcon = (props) => {
  const { palette } = useTheme();

  const {
    fill = 'none',
    rectFill = palette.common.cyanBlue,
    stroke = palette.common.softBlue,
  } = props;

  return (
    <svg width="34" height="34" viewBox="0 0 34 34" fill={fill} xmlns="http://www.w3.org/2000/svg">
      <rect width="33.686" height="33.7477" rx="16.843" fill={rectFill} fillOpacity="0.2" />
      <g clipPath="url(#clip0_1630_9111)">
        <path d="M16.8438 23.8738C20.7098 23.8738 23.8438 20.7398 23.8438 16.8738C23.8438 13.0078 20.7098 9.87384 16.8438 9.87384C12.9778 9.87384 9.84375 13.0078 9.84375 16.8738C9.84375 20.7398 12.9778 23.8738 16.8438 23.8738Z" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M16.8438 16.8738V14.3738" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M16.8438 16.8738L19.9684 19.9992" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_1630_9111">
          <rect width="16" height="16" fill="white" transform="translate(8.84375 8.87384)" />
        </clipPath>
      </defs>
    </svg>

  );
};

export default SessionTimeIcon;
