import { atom } from 'recoil';
import { localStorageEffect } from 'recoil/effects';

const managementModeAtom = atom({
  key: 'managementMode',
  default: false,
  effects_UNSTABLE: [localStorageEffect('managementModeAtom')],
});

export default managementModeAtom;
