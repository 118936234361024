import { useTheme } from '@mui/styles';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import { useAuth, useLocale, useShare } from 'util/hooks';
import {
  ShareIcon,
  GithubIcon,
  PenEditIcon,
  LinkedInIcon,
} from 'assets/icons';
import { capitalizeFirstLetter } from 'util/helpers';
import ShareProfileMenu from '../ShareProfileMenu/ShareProfileMenu';

const SocialMediaAccounts = ({ link, icon }) => (
  <IconButton
    target="_blank"
    component="a"
    href={link}
    sx={{
      border: ({ palette }) => `1px solid ${palette.common.silverGrey}`,
      backgroundColor: 'common.white',
      '&:hover': {
        backgroundColor: 'common.white',
      },
    }}
  >
    {icon}
  </IconButton>
);

const StudentProfileHeader = (props) => {
  const { studentDetails = {} } = props;

  const { isAr } = useLocale();
  const { palette } = useTheme();
  const { currentUser } = useAuth();

  const {
    user = {},
    club = {},
  } = studentDetails || {};

  const {
    bio = '',
    role = {},
    avatar = '',
    username = '',
    fullName = '',
    github = '',
    linkedin = '',
  } = user || {};

  const {
    nameEn: clubNameEn = '',
    nameAr: clubNameAr = '',
    id: clubId = '',
  } = club || {};

  const {
    anchorEl,
    handleOpenMenu,
    handleCloseMenu,
  } = useShare({
    title: username,
    text: username,
  });

  const roleName = isAr ? role.nameAr : role.nameEn;
  const universityName = isAr ? clubNameAr : clubNameEn;
  const isOwnProfile = currentUser?.username === username;

  const linkedinButtonIcon = (
    linkedin && (
      <SocialMediaAccounts
        link={linkedin}
        icon={<LinkedInIcon height="16" width="16" />}
      />
    )
  );

  const githubButtonIcon = (
    github && (
      <SocialMediaAccounts
        link={github}
        icon={<GithubIcon height="17" width="17" />}
      />
    )
  );

  return (
    <Stack spacing={5}>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: { xs: 'center', sm: 'start' },
      }}
      >
        <Stack
          width={1}
          direction={{ xs: 'column', sm: 'row' }}
          alignItems="center"
          justifyContent="center"
          spacing={2.5}
        >
          <Avatar
            src={avatar}
            alt={capitalizeFirstLetter(username)}
            sx={{
              width: { xs: 110, sm: 55 },
              height: { xs: 110, sm: 55 },
            }}
          />
          <Stack width={1}>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: { xs: 'center', sm: 'space-between' },
            }}
            >
              <Typography variant="bodyStandardMedium">
                {fullName}
              </Typography>
              {isOwnProfile
                && (
                <Box>
                  <IconButton
                    disableRipple
                    component={Link}
                    to={`/profile/${username}/edit`}
                    sx={{
                      ml: { xs: 2, sm: 0 },
                      border: `1px solid ${palette.common.silverGrey}`,
                      backgroundColor: 'common.white',
                      '&:hover': {
                        backgroundColor: 'common.white',
                      },
                    }}
                  >
                    <PenEditIcon
                      width="12.5"
                      height="12.5"
                      fill={palette.common.darkGray}
                    />
                  </IconButton>
                </Box>
                )}
            </Box>
            <Typography
              variant="bodySmallRegular"
              color="common.carTireBlack"
              sx={{ textAlign: { xs: 'center', sm: 'start' } }}
            >
              {`@${username}`}
            </Typography>
          </Stack>
        </Stack>
      </Box>
      <Box sx={{ whiteSpace: 'pre-wrap', overflowWrap: 'anywhere' }}>
        <Typography variant="bodyStandardRegular">
          {bio}
        </Typography>
      </Box>
      <Box display="flex">
        <Link to={`/club-profile/${clubId}`}>
          <Typography variant="bodyStandardMedium">
            {`${universityName}`}
          </Typography>
        </Link>
        &nbsp;
        -
        &nbsp;
        <Typography variant="bodyStandardRegular">
          {roleName}
        </Typography>
      </Box>
      <Stack direction="row" spacing={2}>
        {linkedinButtonIcon}
        {githubButtonIcon}
        <IconButton
          onClick={handleOpenMenu}
          sx={{
            border: `1px solid ${palette.common.silverGrey}`,
            backgroundColor: 'common.white',
            '&:hover': {
              backgroundColor: 'common.white',
            },
          }}
        >
          <ShareIcon
            height="16"
            width="16"
            stroke={palette.common.darkGray}
          />
        </IconButton>
      </Stack>
      <ShareProfileMenu
        anchorEl={anchorEl}
        handleCloseMenu={handleCloseMenu}
      />
    </Stack>
  );
};

export default StudentProfileHeader;
