import React from 'react';

import {
  DataGrid as MUIDataGrid,
} from '@mui/x-data-grid';
import { MoreActionsIcon } from 'assets/icons';
import { useSearchParams } from 'react-router-dom';

import { LinearProgress } from '@mui/material';

import {
  CustomNoRowsOverlay,
  CustomPagination,
  SearchToolbar,
  CustomToolbar,
} from './helpers';

const DataGrid = (props) => {
  const {
    rows = [],
    columns = [],
    pagination = {},
    components = {},
    componentsProps = {},
    withSearch = true,
    withCustomToolbar = false,
    ...rest
  } = props;

  // =========================|| Pagination ||========================= //
  const [searchParams, setSearchParams] = useSearchParams();

  // Adjust page from 1-based index to MUIDataGrid's 0-based index
  const customPagination = {
    ...pagination,
    rowCount: pagination?.totalItems || 0,
    page: pagination?.currentPage ? pagination.currentPage - 1 : 0,
    pageSize: pagination?.itemsPerPage || 0,
  };

  // Adjust page from MUIDataGrid's 0-based index to 1-based index
  const customOnPageChange = (newPage) => {
    searchParams.set('page', newPage + 1);
    setSearchParams(searchParams);
  };

  const customOnPageSizeChange = (newLimit) => {
    searchParams.set('limit', newLimit);
    setSearchParams(searchParams);
  };

  return (
    <div style={{ height: 500, width: '100%' }}>
      <MUIDataGrid
        // -----Data-----
        rows={rows}
        columns={columns}
        // -----Components-----
        components={{
          LoadingOverlay: LinearProgress,
          MoreActionsIcon,
          Pagination: CustomPagination,
          NoRowsOverlay: CustomNoRowsOverlay, // No data
          // NoResultsOverlay: CustomNoResultsOverlay, // No filter/search data
          ...(withSearch && {
            Toolbar: SearchToolbar,
          }),
          ...(withCustomToolbar && {
            Toolbar: CustomToolbar,
          }),
          ...components,
        }}
        componentsProps={{
          ...componentsProps,
          ...((withSearch || withCustomToolbar) && {
            toolbar: {
              ...componentsProps.toolbar,
            },
          }),
        }}
        // -----Pagination-----
        paginationMode="server"
        // rowsPerPageOptions={[25, 50, 100]}
        {...customPagination}
        // -----Sorting-----
        // sortingMode="server"
        // -----Styles-----
        autoHeight
        rowHeight={45}
        // -----UI Options-----
        // checkboxSelection
        disableSelectionOnClick
        disableColumnFilter
        disableColumnSelector
        disableColumnMenu
        // -----Event Handlers-----
        onPageChange={customOnPageChange}
        onPageSizeChange={customOnPageSizeChange}
        {...rest}
      />
    </div>
  );
};

export default React.memo(DataGrid);
