import React from 'react';

import _ from 'lodash';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Menu from '@mui/material/Menu';
import Link from '@mui/material/Link';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { useTheme } from '@mui/styles';

import {
  useAuth, useDate, useLocale, useModal,
} from 'util/hooks';
import { CommentIcon, MoreActionsIcon, VerifiedIcon } from 'assets/icons';
import { capitalizeFirstLetter, withHttps } from 'util/helpers';
import { Status } from 'components/molecules';
import { STATUS_NAMES } from 'assets/constants/statuses';
import MODAL_KEYS from 'assets/constants/modalKeys';
import { Button, Stack } from '@mui/material';
import { AUTH_ROLES } from 'assets/constants/userRoles';
import UserAvatarGroup from '../UserAvatarGroup/UserAvatarGroup';

const CertificateCard = (props) => {
  const {
    certificate = {},
    refetchCertificates = () => { },
    cardProps = {},
  } = props;

  const { t } = useLocale();
  const { format, isBefore } = useDate();
  const navigate = useNavigate();
  const { palette } = useTheme();
  const { addModal } = useModal();
  const { isAdmin, isStudent, currentUser } = useAuth();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const {
    id = '',
    user = {},
    image = '',
    rejectionReason = '',
    status = '',
    issueDate = '',
    expiryDate = '',
    certificateLink = '',
    certificateNumber = '',
  } = certificate || {};

  const {
    name = '',
    provider = {},
  } = certificate?.certificate || {};

  // Status
  const formattedStatus = _.camelCase(status);
  const isVerified = status === STATUS_NAMES.verified;
  const isCancelled = status === STATUS_NAMES.cancelled;
  const isRejected = status === STATUS_NAMES.rejected;
  const isUnderReview = status === STATUS_NAMES.underReview;
  const isExpired = isBefore(expiryDate, new Date());

  // Link permissions
  const isOwner = user?.username === currentUser?.username;
  const canViewLink = isAdmin || isOwner;
  const showCertificateLink = certificateLink && canViewLink;

  // Action permissions
  const showActionButtons = isAdmin && isUnderReview;
  const canDelete = isOwner;
  const canCancel = isAdmin && isVerified;

  // Reason permissions
  const showReason = isRejected || isCancelled;

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    navigate(`certificates/edit/${id}`);
  };

  const handleDelete = () => {
    addModal({
      key: MODAL_KEYS.deleteUserCertificate,
      props: {
        certificateId: id,
        refetchCertificates,
      },
    });
  };

  const handleVerify = () => {
    addModal({
      key: MODAL_KEYS.verifyUserCertificate,
      props: {
        certificateId: id,
        refetchCertificates,
      },
    });
  };

  const handleReject = () => {
    addModal({
      key: MODAL_KEYS.rejectUserCertificate,
      props: {
        certificateId: id,
        refetchCertificates,
      },
    });
  };

  const handleCancel = () => {
    addModal({
      key: MODAL_KEYS.cancelUserCertificate,
      props: {
        certificateId: id,
        refetchCertificates,
      },
    });
  };

  const actionMenuItems = [
    isUnderReview && {
      id: 'edit',
      onClick: handleEdit,
      label: t('common.edit'),
    },
    canDelete && {
      id: 'delete',
      onClick: handleDelete,
      label: t('common.delete'),
      labelProps: {
        color: 'common.pinkishRed',
      },
    },
    canCancel && {
      id: 'cancel',
      onClick: handleCancel,
      label: t('actions.cancel'),
      labelProps: {
        color: 'common.pinkishRed',
      },
    },
  ]
    .filter((v) => v); // filter out null elements

  const actionMenu = (
    <Box>
      <IconButton
        id="menu-button"
        onClick={handleOpenMenu}
      >
        <MoreActionsIcon />
      </IconButton>
      <Menu
        sx={{
          '& .MuiPaper-root': {
            boxShadow: (theme) => `0 4px 4px 0px ${theme.palette.common.boxShadow2}`,
          },
        }}
        id="certificate-action-menu"
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleCloseMenu}
      >
        {actionMenuItems?.map((item) => (
          <Box key={item.id} sx={{ width: '170px' }}>
            <MenuItem onClick={item.onClick}>
              <Typography variant="bodySmallMedium" {...item.labelProps}>
                {item.label}
              </Typography>
            </MenuItem>
          </Box>
        ))}
      </Menu>
    </Box>
  );

  const certificateStatus = isVerified
    ? (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: 17.5, height: 17.5, mr: 1 }}>
          <VerifiedIcon width="17.5" height="17.5" />
        </Box>
        <Typography variant="bodySmallRegular">
          {t('certificates.verifiedByCyberHub')}
        </Typography>
      </Box>
    )
    : (
      <Status
        status={formattedStatus}
        label={t(`statuses.${formattedStatus}`)}
      />
    );

  const actionButtons = (
    <Stack
      direction="row"
      spacing={2}
    >
      <Button
        sx={({ typography }) => ({
          ...typography.bodySmallBold,
          color: 'common.pinkishRed',
        })}
        onClick={handleReject}
      >
        {t('actions.reject')}
      </Button>
      <Button
        variant="contained"
        sx={({ typography }) => ({
          ...typography.bodySmallBold,
        })}
        onClick={handleVerify}
      >
        {t('actions.verify')}
      </Button>
    </Stack>
  );

  const userAvatar = (
    <UserAvatarGroup
      name={user.name}
      avatar={user.avatar}
      username={user.username}
      clubName={user?.club?.abbreviation}
      role={AUTH_ROLES.student} // certificate cards is always for students
      avatarProps={{
        sx: {
          width: 40,
          height: 40,
        },
      }}
    />
  );

  const cardHeader = (
    <Stack
      width={1}
    >
      {(isStudent || (isAdmin && isVerified)) && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            width: 1,
            mb: isStudent ? 0 : 5,
          }}
        >
          {certificateStatus}
          {isOwner
            && <Box>{actionMenu}</Box>}
        </Stack>
      )}
      {showActionButtons && (
        <Box sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mb: 5,
        }}
        >
          {actionButtons}
        </Box>
      )}
      {isAdmin && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            width: 1,
            mb: 5,
          }}
        >
          {userAvatar}
          {isVerified
            ? actionMenu
            : certificateStatus}
        </Stack>
      )}
      {showReason && (
        <Box sx={{
          p: 1,
          width: 'fit-content',
          border: `1px solid ${palette.common.silverGrey}`,
          borderRadius: 1,
          maxWidth: { xs: 1, md: 0.7 },
        }}
        >
          <Box
            sx={{
              display: 'flex',
            }}
          >
            <Box
              sx={{
                width: 20,
                height: 20,
                mr: 1,
              }}
            >
              <CommentIcon
                circleFill="none"
                innerFill={palette.common.gadgetGray}
                width="20"
                height="20"
              />
            </Box>
            <Box>
              <Typography
                variant="bodySmallRegular"
                color="common.gadgetGray"
              >
                {`${t('common.reason')}:`}
                &nbsp;
              </Typography>
              <Typography
                variant="bodySmallRegular"
                sx={{ overflowWrap: 'break-word' }}
              >
                {rejectionReason}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </Stack>
  );

  const cardContent = (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          rowGap: 2,
        }}
      >
        <Box
          sx={{
            height: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box maxWidth={{ xs: 125, sm: 1 }}>
            <Typography
              variant="bodyStandardMedium"
              sx={{ overflowWrap: 'break-word' }}
            >
              {name}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              mt: 1,
            }}
          >
            <Avatar
              src={provider?.logo}
              alt={capitalizeFirstLetter(provider?.name)}
              sx={{
                mr: 1,
                width: 30,
                height: 30,
                boxShadow: (theme) => `0 4px 4px 0px ${theme.palette.common.dropShadow}`,
              }}
            />
            <Typography variant="bodySmallRegular">
              {provider?.name}
            </Typography>
          </Box>
        </Box>
        {showCertificateLink && (
          <Link
            component="a"
            target="_blank"
            href={withHttps(certificateLink)}
          >
            <Typography
              variant="bodySmallMedium"
              sx={{
                color: 'common.softBlue',
              }}
            >
              {t('certificates.certificateLink')}
            </Typography>
          </Link>

        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography variant="bodySmallRegular">
            {`${t('certificates.forms.certificateId')}: ${certificateNumber}`}
          </Typography>
          {issueDate && (
            <Stack direction="row" spacing={1}>
              <Typography variant="bodySmallRegular">
                {`${t('common.earned')}: ${format(issueDate)}`}
              </Typography>
              {expiryDate && (
                <>
                  <Typography variant="bodySmallRegular">
                    {'\u2022'}
                  </Typography>
                  <Typography
                    variant="bodySmallRegular"
                    color={isExpired
                      ? 'common.pinkishRed'
                      : 'common.darkGray'}
                  >
                    {` ${isExpired ? t('statuses.expired') : t('common.expires')}: ${format(expiryDate)}`}
                  </Typography>
                </>
              )}
            </Stack>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          width: { xs: 132, sm: 190 },
          height: { xs: 96, sm: 140 },
        }}
      >
        <Avatar
          src={image}
          variant="square"
          alt={`certificate-${id}`}
          sx={{
            width: { xs: 132, sm: 190 },
            height: { xs: 96, sm: 140 },
          }}
        />
      </Box>
    </>
  );

  return (
    <Card
      {...cardProps}
      sx={{
        minHeight: 170,
        display: 'flex',
        borderRadius: 0,
        flexDirection: 'column',
        p: 5,
        ...cardProps.sx,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 1,
        }}
      >

        {cardHeader}
      </Box>
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          columnGap: 2,
          mt: 2,
        }}
      >
        {cardContent}
      </Box>
    </Card>
  );
};

export default CertificateCard;
