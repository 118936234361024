import {
  Box,
  Menu,
  Button,
  MenuItem,
  Typography,
  IconButton,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { PATHS } from 'routes';
import { useAuth, useLocale, useModal } from 'util/hooks';

import { MoreActionsIcon } from 'assets/icons';
import { CustomBreadcrumbs, Status } from 'components/molecules';

import MODAL_KEYS from 'assets/constants/modalKeys';

const CourseManagementHeader = (props) => {
  const {
    courseDetails = {},
    fetchCourseDetails = () => { },
  } = props;

  const {
    id = '',
    title = '',
    status = '',
    createdById = '',
    managedById = '',
    isCourseUnderReview = false,
    isCoursePublished = false,
    isCourseCompleted = false,
    isCourseRunning = false,
    isCourseDraft = false,
  } = courseDetails || {};

  const { isAdmin, isContentCreator, currentUser } = useAuth();
  const { t, dir } = useLocale();
  const navigate = useNavigate();
  const { addModal } = useModal();

  const [anchorElUser, setAnchorElUser] = useState(null);
  const [actionItems, setActionItems] = useState([]);

  const isAuthor = currentUser.id === createdById;
  const isCourseManager = currentUser.id === managedById;
  const isManagerContentCreator = isContentCreator && isCourseManager;

  const canDoActions = (
    (isCourseCompleted && (isManagerContentCreator || isAdmin))
    || (isCourseUnderReview && isManagerContentCreator)
    || (isCoursePublished && (isManagerContentCreator || isAdmin))
    || (isCourseRunning && (isManagerContentCreator || isAdmin))
    || (isCourseDraft && (isAuthor || isManagerContentCreator)));

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleAddModal = (type) => {
    // close menu
    handleCloseUserMenu();

    // open modal
    addModal({
      key: MODAL_KEYS.courseAction,
      props: {
        type,
        refetchCourse: fetchCourseDetails,
      },
    });
  };

  useEffect(() => {
    actionsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, status]);

  const actionsList = () => {
    if (isCourseDraft && (isCourseManager || isAuthor)) {
      setActionItems([{
        id: 'edit',
        label: t('common.edit'),
        onClick: () => { navigate(`/${PATHS.courses}/${id}/${PATHS.edit}`); },
      },
      {
        id: 'delete',
        label: t('common.delete'),
        onClick: () => {
          handleAddModal('delete');
        },
        redColoredItem: { color: 'common.pinkishRed' },
      }]);
    }

    if (isCoursePublished && isAdmin) {
      setActionItems([{
        id: 'edit',
        label: t('common.edit'),
        onClick: () => { navigate(`/${PATHS.courses}/${id}/${PATHS.edit}`); },
      },
      {
        id: 'cancel',
        label: t('common.cancel'),
        onClick: () => {
          handleAddModal('cancel');
        },
      }]);
    }

    if (isCourseRunning && isAdmin) {
      setActionItems([{
        id: 'edit',
        label: t('common.edit'),
        onClick: () => { navigate(`/${PATHS.courses}/${id}/${PATHS.edit}`); },
      },
      {
        id: 'markAsCompleted',
        label: t('common.markAsCompleted'),
        onClick: () => {
          handleAddModal('markAsCompleted');
        },
      },
      {
        id: 'cancel',
        label: t('common.cancel'),
        onClick: () => {
          handleAddModal('cancel');
        },
      }]);
    }

    if (isCourseRunning && isManagerContentCreator) {
      setActionItems([
        {
          id: 'edit',
          label: t('common.edit'),
          onClick: () => { navigate(`/${PATHS.courses}/${id}/${PATHS.edit}`); },
        },
        {
          id: 'markAsCompleted',
          label: t('common.markAsCompleted'),
          onClick: () => {
            handleAddModal('markAsCompleted');
          },
        },
      ]);
    }

    if (isCourseCompleted || (isCourseUnderReview && isManagerContentCreator)
      || (isCoursePublished && isManagerContentCreator)) {
      setActionItems([{
        id: 'edit',
        label: t('common.edit'),
        onClick: () => { navigate(`/${PATHS.courses}/${id}/${PATHS.edit}`); },
      }]);
    }
  };

  const crumbs = [
    {
      text: t('common.courses'),
      link: `/${PATHS.courses}`,
    },
    {
      text: title,
      link: '#',
    },
  ];

  const renderActionsList = () => (
    <Box sx={{
      display: 'flex',
      justifyContent: 'end',
    }}
    >
      <IconButton
        id="menu-button"
        aria-controls={anchorElUser ? 'actions-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={anchorElUser ? 'true' : undefined}
        disableRipple
        onClick={handleOpenUserMenu}
      >
        <MoreActionsIcon />
      </IconButton>
      <Menu
        sx={{
          mt: 3,
          '& .MuiPaper-root': {
            boxShadow: (theme) => `0 4px 4px 0px ${theme.palette.common.boxShadow2}`,
          },
        }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {actionItems?.map((item) => (
          <Box key={item.id} sx={{ width: '170px' }}>
            <MenuItem onClick={item.onClick} dir={dir}>
              <Typography variant="bodySmallMedium" sx={{ color: item.redColoredItem && 'common.pinkishRed' }}>
                {item.label}
              </Typography>
            </MenuItem>
          </Box>
        ))}
      </Menu>
    </Box>
  );

  const renderAdminActions = () => (
    <Box sx={{
      display: 'flex',
      justifyContent: 'end',
      mt: { xs: 3, md: 0 },
    }}
    >
      <Button
        disableRipple
        sx={{
          mx: 2,
          color: 'common.pinkishRed',
          '&.MuiButton-text': {
            '&:hover': {
              color: 'common.pinkishRed',
            },
          },
        }}
        onClick={() => {
          handleAddModal('reject');
        }}
      >
        {t('actions.reject')}
      </Button>
      <Button
        variant="outlined"
        size="regular"
        sx={{ mx: 2 }}
        onClick={() => { navigate(`/${PATHS.courses}/${id}/${PATHS.edit}`); }}
      >
        {t('common.update')}
      </Button>
      <Button
        variant="contained"
        size="regular"
        sx={{ mx: 2 }}
        onClick={() => {
          handleAddModal('approve');
        }}
      >
        {t('actions.approve')}
      </Button>
    </Box>
  );

  return (
    <>
      <Box sx={{
        display: 'flex',
        flexDirection: {
          xs: 'column',
          md: 'row',
        },
        justifyContent: 'space-between',
        alignItems: {
          xs: 'start',
          md: 'center',
        },
        mb: 12,
      }}
      >
        <CustomBreadcrumbs
          separator="›"
          crumbs={crumbs}
        />
        {(isCourseUnderReview && isAdmin) && renderAdminActions()}
      </Box>
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        mb: 7,
      }}
      >
        <Status
          status={status}
          label={t(`statuses.${status}`)}
        />
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
        }}
        >
          {canDoActions && renderActionsList()}
          {isCourseDraft && isAuthor
            && (
              <Button
                variant="contained"
                size="regular"
                sx={{ ml: 4 }}
                onClick={() => {
                  handleAddModal(isAdmin ? 'publish' : 'sendForApproval');
                }}
              >
                {isAdmin ? t('common.publish') : t('common.sendForApproval')}
              </Button>
            )}
        </Box>
      </Box>
    </>
  );
};

export default CourseManagementHeader;
