import api from 'services/api';
import END_POINTS from 'services/endPoints';

const getContentCreators = (providerID = 0) => api(
  END_POINTS.contentCreators(providerID),
)
  .then((response) => Promise.resolve(response))
  .catch((error) => Promise.reject(error));

export default getContentCreators;
