import { useTheme } from '@mui/styles';

const FailIcon = (props) => {
  const { palette } = useTheme();

  const {
    fill = 'none',
    stroke = palette.common.pinkishRed,
    ...rest
  } = props;

  return (
    <svg width="54" height="54" viewBox="0 0 54 54" fill={fill} xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d="M15.3301 38.6673L38.6634 15.334" stroke={stroke} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M38.6634 38.6673L15.3301 15.334" stroke={stroke} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M27 52C40.8071 52 52 40.8071 52 27C52 13.1929 40.8071 2 27 2C13.1929 2 2 13.1929 2 27C2 40.8071 13.1929 52 27 52Z" stroke={stroke} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  );
};

export default FailIcon;
