import { useTheme } from '@mui/styles';

const ArticlePostIcon = (props) => {
  const { palette } = useTheme();

  const {
    fill = palette.common.lightCyan,
    outerFill = palette.common.brightLightBlue,
    stroke = palette.common.white,
  } = props;

  return (
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect x="3" y="3" width="54" height="54" rx="11" fill={fill} stroke={outerFill} strokeWidth="6" />
      <g clipPath="url(#clip0_165_23254)">
        <path d="M40.63 32.8704L33 40.5004L29.25 41.2504L30 37.5004L37.63 29.8704C37.8263 29.6737 38.0595 29.5177 38.3162 29.4112C38.5729 29.3048 38.8481 29.25 39.126 29.25C39.4039 29.25 39.6791 29.3048 39.9358 29.4112C40.1925 29.5177 40.4257 29.6737 40.622 29.8704L40.63 29.8784C40.8267 30.0747 40.9827 30.3079 41.0891 30.5646C41.1956 30.8213 41.2504 31.0965 41.2504 31.3744C41.2504 31.6522 41.1956 31.9274 41.0891 32.1841C40.9827 32.4408 40.8267 32.674 40.63 32.8704V32.8704Z" stroke={stroke} strokeWidth="1.35938" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M21.75 24.75H32.25" stroke={stroke} strokeWidth="1.35938" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M21.75 29.25H32.25" stroke={stroke} strokeWidth="1.35938" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M21.75 33.75H27" stroke={stroke} strokeWidth="1.35938" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M25.5 38.25H20.25C19.8522 38.25 19.4706 38.092 19.1893 37.8107C18.908 37.5294 18.75 37.1478 18.75 36.75V20.25C18.75 19.8522 18.908 19.4706 19.1893 19.1893C19.4706 18.908 19.8522 18.75 20.25 18.75H30.879C31.2765 18.7501 31.6578 18.908 31.939 19.189L34.811 22.061C35.092 22.3422 35.2499 22.7235 35.25 23.121V26.25" stroke={stroke} strokeWidth="1.35938" strokeLinecap="round" strokeLinejoin="round" />
      </g>
    </svg>
  );
};
export default ArticlePostIcon;
