import { getTopics } from 'services';
import { useCustomQuery } from 'reactQuery';

const useTopics = () => {
  const query = useCustomQuery({
    queryKey: ['topics'],
    queryFn: getTopics,
  });

  return { ...query };
};

export default useTopics;
