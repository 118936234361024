import { useTheme } from '@mui/styles';

const PostsIcon = (props) => {
  const { palette } = useTheme();

  const {
    fill = 'none',
    rectFill = palette.common.offWhite,
    stroke = palette.text.secondary,
    ...rest
  } = props;

  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill={fill} xmlns="http://www.w3.org/2000/svg" {...rest}>
      <rect width="32" height="32" rx="10.6667" fill={rectFill} />
      <path d="M13.4497 12.1731C13.202 12.1731 13 11.9793 13 11.7378V10.4353C13 10.1955 13.2003 10 13.4497 10C13.6974 10 13.8994 10.1938 13.8994 10.4353V11.7378C13.8994 11.9793 13.6974 12.1731 13.4497 12.1731Z" fill="#909090" />
      <path d="M14.7974 12.1731C14.5497 12.1731 14.3477 11.9793 14.3477 11.7378V10.4353C14.3477 10.1955 14.5479 10 14.7974 10C15.0451 10 15.2471 10.1938 15.2471 10.4353V11.7378C15.2471 11.9793 15.0451 12.1731 14.7974 12.1731Z" fill="#909090" />
      <path d="M16.1441 12.1731C15.8964 12.1731 15.6943 11.9793 15.6943 11.7378V10.4353C15.6943 10.1955 15.8946 10 16.1441 10C16.3918 10 16.5938 10.1938 16.5938 10.4353V11.7378C16.592 11.9793 16.3918 12.1731 16.1441 12.1731Z" fill="#909090" />
      <path d="M21.5503 12.1731C21.3026 12.1731 21.1006 11.9793 21.1006 11.7378V10.4353C21.1006 10.1955 21.3009 10 21.5503 10C21.798 10 22 10.1938 22 10.4353V11.7378C21.9983 11.9793 21.798 12.1731 21.5503 12.1731Z" fill="#909090" />
      <path d="M17.4917 18.6751C17.244 18.6751 17.042 18.4812 17.042 18.2398V16.9373C17.042 16.6975 17.2423 16.502 17.4917 16.502C17.7394 16.502 17.9414 16.6958 17.9414 16.9373V18.2398C17.9397 18.4812 17.7394 18.6751 17.4917 18.6751Z" fill="#909090" />
      <path d="M17.4917 14.7735C17.244 14.7735 17.042 14.5797 17.042 14.3382V11.74C17.042 11.5002 17.2423 11.3047 17.4917 11.3047C17.7394 11.3047 17.9414 11.4985 17.9414 11.74V14.3382C17.9397 14.5797 17.7394 14.7735 17.4917 14.7735Z" fill="#909090" />
      <path d="M16.1441 19.9874C15.8964 19.9874 15.6943 19.7935 15.6943 19.5521V16.9539C15.6943 16.7141 15.8946 16.5186 16.1441 16.5186C16.3918 16.5186 16.5938 16.7124 16.5938 16.9539V19.5521C16.592 19.7935 16.3918 19.9874 16.1441 19.9874Z" fill="#909090" />
      <path d="M18.3926 16.9539C18.3926 16.7141 18.5928 16.5186 18.8423 16.5186H21.5266C21.7743 16.5186 21.9763 16.7124 21.9763 16.9539C21.9763 17.1936 21.776 17.3892 21.5266 17.3892H18.8423C18.5946 17.3875 18.3926 17.1936 18.3926 16.9539Z" fill="#909090" />
      <path d="M20.1851 14.7835C19.9374 14.7835 19.7354 14.5896 19.7354 14.3482V13.0457C19.7354 12.8059 19.9356 12.6104 20.1851 12.6104C20.4328 12.6104 20.6348 12.8042 20.6348 13.0457V14.3482C20.6348 14.5879 20.4328 14.7835 20.1851 14.7835Z" fill="#909090" />
      <path d="M20.6338 15.6345C20.6338 15.8743 20.4335 16.0698 20.184 16.0698H18.8384C18.5907 16.0698 18.3887 15.876 18.3887 15.6345C18.3887 15.3948 18.5889 15.1992 18.8384 15.1992H20.184C20.4335 15.2009 20.6338 15.3948 20.6338 15.6345Z" fill="#909090" />
      <path d="M20.6338 18.2605C20.6338 18.5003 20.4335 18.6958 20.184 18.6958H18.8384C18.5907 18.6958 18.3887 18.502 18.3887 18.2605C18.3887 18.0207 18.5889 17.8252 18.8384 17.8252H20.184C20.4335 17.8269 20.6338 18.0207 20.6338 18.2605Z" fill="#909090" />
      <path d="M13.4497 16.0842C13.202 16.0842 13 15.8904 13 15.6489V14.3464C13 14.1067 13.2003 13.9111 13.4497 13.9111C13.6974 13.9111 13.8994 14.105 13.8994 14.3464V15.6489C13.8994 15.8904 13.6974 16.0842 13.4497 16.0842Z" fill="#909090" />
      <path d="M14.7974 16.0842C14.5497 16.0842 14.3477 15.8904 14.3477 15.6489V14.3464C14.3477 14.1067 14.5479 13.9111 14.7974 13.9111C15.0451 13.9111 15.2471 14.105 15.2471 14.3464V15.6489C15.2471 15.8904 15.0451 16.0842 14.7974 16.0842Z" fill="#909090" />
      <path d="M14.7974 18.6936C14.5497 18.6936 14.3477 18.4998 14.3477 18.2583V16.9558C14.3477 16.7161 14.5479 16.5205 14.7974 16.5205C15.0451 16.5205 15.2471 16.7144 15.2471 16.9558V18.2583C15.2471 18.4981 15.0451 18.6936 14.7974 18.6936Z" fill="#909090" />
      <path d="M13.4497 13.479C13.6981 13.479 13.8994 13.2841 13.8994 13.0437C13.8994 12.8033 13.6981 12.6084 13.4497 12.6084C13.2013 12.6084 13 12.8033 13 13.0437C13 13.2841 13.2013 13.479 13.4497 13.479Z" fill="#909090" />
      <path d="M14.7974 13.479C15.0458 13.479 15.2471 13.2841 15.2471 13.0437C15.2471 12.8033 15.0458 12.6084 14.7974 12.6084C14.549 12.6084 14.3477 12.8033 14.3477 13.0437C14.3477 13.2841 14.549 13.479 14.7974 13.479Z" fill="#909090" />
      <path d="M16.1441 13.479C16.3924 13.479 16.5938 13.2841 16.5938 13.0437C16.5938 12.8033 16.3924 12.6084 16.1441 12.6084C15.8957 12.6084 15.6943 12.8033 15.6943 13.0437C15.6943 13.2841 15.8957 13.479 16.1441 13.479Z" fill="#909090" />
      <path d="M17.4917 16.0874C17.7401 16.0874 17.9414 15.8925 17.9414 15.6521C17.9414 15.4117 17.7401 15.2168 17.4917 15.2168C17.2433 15.2168 17.042 15.4117 17.042 15.6521C17.042 15.8925 17.2433 16.0874 17.4917 16.0874Z" fill="#909090" />
      <path d="M17.4917 19.9995C17.7401 19.9995 17.9414 19.8046 17.9414 19.5642C17.9414 19.3238 17.7401 19.1289 17.4917 19.1289C17.2433 19.1289 17.042 19.3238 17.042 19.5642C17.042 19.8046 17.2433 19.9995 17.4917 19.9995Z" fill="#909090" />
      <path d="M17.4917 10.8726C17.7401 10.8726 17.9414 10.6777 17.9414 10.4373C17.9414 10.1968 17.7401 10.002 17.4917 10.002C17.2433 10.002 17.042 10.1968 17.042 10.4373C17.042 10.6777 17.2433 10.8726 17.4917 10.8726Z" fill="#909090" />
      <path d="M18.8384 14.7817C19.0868 14.7817 19.2881 14.5868 19.2881 14.3464C19.2881 14.106 19.0868 13.9111 18.8384 13.9111C18.59 13.9111 18.3887 14.106 18.3887 14.3464C18.3887 14.5868 18.59 14.7817 18.8384 14.7817Z" fill="#909090" />
      <path d="M18.8384 13.479C19.0868 13.479 19.2881 13.2841 19.2881 13.0437C19.2881 12.8033 19.0868 12.6084 18.8384 12.6084C18.59 12.6084 18.3887 12.8033 18.3887 13.0437C18.3887 13.2841 18.59 13.479 18.8384 13.479Z" fill="#909090" />
      <path d="M18.8384 12.1753C19.0868 12.1753 19.2881 11.9804 19.2881 11.74C19.2881 11.4996 19.0868 11.3047 18.8384 11.3047C18.59 11.3047 18.3887 11.4996 18.3887 11.74C18.3887 11.9804 18.59 12.1753 18.8384 12.1753Z" fill="#909090" />
      <path d="M21.5318 16.0855C21.7801 16.0855 21.9815 15.8906 21.9815 15.6501C21.9815 15.4097 21.7801 15.2148 21.5318 15.2148C21.2834 15.2148 21.082 15.4097 21.082 15.6501C21.082 15.8906 21.2834 16.0855 21.5318 16.0855Z" fill="#909090" />
      <path d="M21.5318 14.7817C21.7801 14.7817 21.9815 14.5868 21.9815 14.3464C21.9815 14.106 21.7801 13.9111 21.5318 13.9111C21.2834 13.9111 21.082 14.106 21.082 14.3464C21.082 14.5868 21.2834 14.7817 21.5318 14.7817Z" fill="#909090" />
      <path d="M21.5318 13.479C21.7801 13.479 21.9815 13.2841 21.9815 13.0437C21.9815 12.8033 21.7801 12.6084 21.5318 12.6084C21.2834 12.6084 21.082 12.8033 21.082 13.0437C21.082 13.2841 21.2834 13.479 21.5318 13.479Z" fill="#909090" />
      <path d="M18.8384 10.8726C19.0868 10.8726 19.2881 10.6777 19.2881 10.4373C19.2881 10.1968 19.0868 10.002 18.8384 10.002C18.59 10.002 18.3887 10.1968 18.3887 10.4373C18.3887 10.6777 18.59 10.8726 18.8384 10.8726Z" fill="#909090" />
      <path d="M20.1851 12.1753C20.4334 12.1753 20.6348 11.9804 20.6348 11.74C20.6348 11.4996 20.4334 11.3047 20.1851 11.3047C19.9367 11.3047 19.7354 11.4996 19.7354 11.74C19.7354 11.9804 19.9367 12.1753 20.1851 12.1753Z" fill="#909090" />
      <path d="M20.1851 10.8726C20.4334 10.8726 20.6348 10.6777 20.6348 10.4373C20.6348 10.1968 20.4334 10.002 20.1851 10.002C19.9367 10.002 19.7354 10.1968 19.7354 10.4373C19.7354 10.6777 19.9367 10.8726 20.1851 10.8726Z" fill="#909090" />
      <path d="M16.1441 14.7817C16.3924 14.7817 16.5938 14.5868 16.5938 14.3464C16.5938 14.106 16.3924 13.9111 16.1441 13.9111C15.8957 13.9111 15.6943 14.106 15.6943 14.3464C15.6943 14.5868 15.8957 14.7817 16.1441 14.7817Z" fill="#909090" />
      <path d="M16.1441 16.0874C16.3924 16.0874 16.5938 15.8925 16.5938 15.6521C16.5938 15.4117 16.3924 15.2168 16.1441 15.2168C15.8957 15.2168 15.6943 15.4117 15.6943 15.6521C15.6943 15.8925 15.8957 16.0874 16.1441 16.0874Z" fill="#909090" />
      <path d="M13.4497 17.3892C13.6981 17.3892 13.8994 17.1943 13.8994 16.9539C13.8994 16.7134 13.6981 16.5186 13.4497 16.5186C13.2013 16.5186 13 16.7134 13 16.9539C13 17.1943 13.2013 17.3892 13.4497 17.3892Z" fill="#909090" />
      <path d="M20.375 25.375H9.125C8.79348 25.375 8.47554 25.2433 8.24112 25.0089C8.0067 24.7745 7.875 24.4565 7.875 24.125V10.375" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M23.2917 6.625H11.2083C10.7481 6.625 10.375 6.9981 10.375 7.45833V22.0417C10.375 22.5019 10.7481 22.875 11.2083 22.875H23.2917C23.7519 22.875 24.125 22.5019 24.125 22.0417V7.45833C24.125 6.9981 23.7519 6.625 23.2917 6.625Z" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default PostsIcon;
