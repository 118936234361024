import { Box, IconButton, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { formatLargeNumber } from 'util/helpers';
import { getPostActionByType } from 'assets/constants/posts';

const ActionIconWithCount = (props) => {
  const {
    count,
    filled,
    type,
    onClick,
    disabled,
    ...rest
  } = props;

  const formattedCount = formatLargeNumber(count);

  const {
    iconShapeFilled,
    iconShapeOutlined,
  } = getPostActionByType(type);

  const renderIcon = () => (filled ? iconShapeFilled : iconShapeOutlined);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: {
          xs: 'center',
          sm: 'flex-start',
        },
        width: 1,
      }}
      {...rest}
    >
      <IconButton
        aria-label="toggle"
        onClick={onClick}
        disabled={disabled}
        sx={{
          mr: 1,
        }}
      >
        {renderIcon()}
      </IconButton>
      <Typography variant="bodySmallRegular" color="common.berylliumGrey">{formattedCount}</Typography>
    </Box>
  );
};

ActionIconWithCount.propTypes = {
  count: PropTypes.number,
  filled: PropTypes.bool,
  type: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

ActionIconWithCount.defaultProps = {
  count: 0,
  filled: false,
  type: '',
  onClick: () => { },
  disabled: false,
};

export default ActionIconWithCount;
