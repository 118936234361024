import { getProfilePoints } from 'services';
import { useCustomQuery } from 'reactQuery';
import { useParams } from 'react-router-dom';

const useProfilePoints = (queryProps = {}) => {
  const { queryKeyOptions = {}, ...rest } = queryProps;
  const { username } = useParams();

  const query = useCustomQuery({
    queryKey: [`profile-points-${username}`, queryKeyOptions],
    queryFn: () => getProfilePoints(
      username,
      {
        searchOptions: queryKeyOptions.searchOptions,
      },
    ),
    ...rest,
  });

  return { ...query };
};

export default useProfilePoints;
