import { useTheme } from '@mui/styles';

const UniversityMedalIcon = (props) => {
  const { palette } = useTheme();

  const {
    fill = palette.common.vividOrange,
    stroke = palette.common.vividOrange,
    ...rest
  } = props;

  return (
    <svg width="54" height="72" viewBox="0 0 54 72" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d="M10 72V36H43.4286V72L26.7143 60.4286L10 72Z" fill={fill} />
      <rect x="0.5" y="0.5" width="53" height="53" rx="26.5" fill="white" />
      <rect x="0.5" y="0.5" width="53" height="53" rx="26.5" stroke={stroke} />
    </svg>
  );
};

export default UniversityMedalIcon;
