import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';
import { snackbarAtom } from 'recoil/atoms';

const useSnackbar = () => {
  const setSnackbar = useSetRecoilState(snackbarAtom);
  const onClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ open: false });
  };

  const snack = useCallback((props) => setSnackbar({
    open: true,
    ...props,
    snackbarProps: {
      onClose,
      ...props?.snackbarProps,
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), []);

  return snack;
};

export default useSnackbar;
