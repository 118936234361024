import END_POINTS from '../endPoints';
import api from '../api';

const getClubManager = (clubId) => api(
  END_POINTS.clubManager(clubId),
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default getClubManager;
