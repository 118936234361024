import END_POINTS from '../../endPoints';
import METHODS from '../../methods';
import api from '../../api';

const updateCertificate = (data, id) => api(
  END_POINTS.certificate(id),
  {
    method: METHODS.PUT,
    data,
  },
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default updateCertificate;
