import { GridActionsCellItem } from '@mui/x-data-grid';
import MODAL_KEYS from 'assets/constants/modalKeys';
import { getStatusByName } from 'assets/constants/statuses';
import TABLE_ACTIONS from 'assets/constants/tableActions';
import { getRoleByName } from 'assets/constants/userRoles';
import { Status } from 'components/molecules';
import { useAuth, useLocale, useModal } from 'util/hooks';

const useTableAdminColumns = (props = {}) => {
  const { refetchUsers = () => { } } = props;
  const { currentUser } = useAuth();
  const { addModal } = useModal();

  const { t, dir } = useLocale();

  const handleOpenModal = (type, username) => {
    addModal({
      key: MODAL_KEYS.userStatus,
      props: {
        type,
        username,
        refetchUsers,
      },
    });
  };

  return (
    [
      {
        field: 'fullName',
        headerName: t('common.name'),
        flex: 1,
        minWidth: 235,
      },
      {
        field: 'email',
        headerName: t('common.email'),
        flex: 1,
        minWidth: 340,
      },
      {
        field: 'type',
        headerName: t('common.role'),
        flex: 1,
        minWidth: 185,
        valueGetter: (params) => t(getRoleByName(params?.row?.type)?.localizationKey),
      },
      {
        field: 'status',
        headerName: t('common.status'),
        flex: 1,
        minWidth: 185,
        renderCell: (params) => (
          <Status
            status={params.row.status || ''}
            label={t(getStatusByName(params?.row?.status)?.localizationKey) || ''}
          />
        ),
      },
      {
        field: 'actions',
        headerName: t('common.actions'),
        type: 'actions',
        flex: 1,
        minWidth: 50,
        getActions: ({ row }) => {
          const { status, username } = row;
          const isActive = status === getStatusByName('active').name;
          const isPending = status === getStatusByName('pending').name;
          const isSuspended = status === getStatusByName('suspended').name;
          const isCurrentUser = username === currentUser.username;

          const actions = [];
          if (isActive && !isCurrentUser) {
            actions.push(
              <GridActionsCellItem
                dir={dir}
                label={t('actions.suspendUser')}
                onClick={() => handleOpenModal(
                  TABLE_ACTIONS.userManagement.suspend,
                  username,
                )}
                showInMenu
              />,
            );
          } else if (isPending || isSuspended) {
            actions.push(
              <GridActionsCellItem
                dir={dir}
                label={t('actions.activateUser')}
                onClick={() => handleOpenModal(
                  TABLE_ACTIONS.userManagement.activateByAdmin,
                  username,
                )}
                showInMenu
              />,
            );
          }

          return actions;
        },
      },
    ]
  );
};

export default useTableAdminColumns;
