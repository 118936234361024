import PropTypes from 'prop-types';

import {
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';

import ClearIcon from '@mui/icons-material/Clear';
import { SearchIcon } from 'assets/icons';

const SearchField = (props) => {
  const {
    value,
    onClear,
    ...rest
  } = props;

  const searchIcon = (
    <InputAdornment position="start">
      <SearchIcon
        aria-label="search-icon"
        width="20"
        height="20"
      />
    </InputAdornment>
  );

  const clearIcon = (
    <IconButton
      title="Clear"
      aria-label="Clear"
      size="small"
      style={{ visibility: value ? 'visible' : 'hidden' }}
      onClick={onClear}
    >
      <ClearIcon sx={{ color: 'secondary.main' }} fontSize="small" />
    </IconButton>
  );

  return (
    <TextField
      size="small"
      value={value}
      InputProps={{
        startAdornment: searchIcon,
        endAdornment: clearIcon,
      }}
      {...rest}
    />
  );
};

SearchField.propTypes = {
  value: PropTypes.string,
  onClear: PropTypes.func,
};

SearchField.defaultProps = {
  value: '',
  onClear: () => { },
};

export default SearchField;
