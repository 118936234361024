import { useTheme } from '@mui/styles';

const BookWithCircleIcon = (props) => {
  const { palette } = useTheme();

  const {
    stroke = palette.common.dimMintLeafGreen,
    fill = palette.common.dimMintLeafGreen,
    ...rest
  } = props;

  return (
    <svg width="106" height="103" viewBox="0 0 106 103" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <circle cx="71.69" cy="33.7916" r="33.565" fill={fill} fillOpacity="0.2" />
      <path d="M45.4583 97.5811C53.834 90.4898 64.0898 85.9849 74.9783 84.6144C76.2615 84.4453 77.4394 83.8155 78.2924 82.8422C79.1455 81.8689 79.6156 80.6186 79.615 79.3244V29.1111C79.6161 28.3418 79.4522 27.5813 79.1342 26.8808C78.8162 26.1804 78.3517 25.5563 77.7719 25.0507C77.1921 24.5452 76.5105 24.1699 75.7733 23.9503C75.036 23.7307 74.2603 23.6719 73.4983 23.7777C63.1541 25.3797 53.4519 29.8029 45.4583 36.5611C44.5036 37.2984 43.3313 37.6984 42.125 37.6984C40.9187 37.6984 39.7464 37.2984 38.7917 36.5611C30.7946 29.8036 21.0887 25.3827 10.7417 23.7844C9.98029 23.6786 9.20508 23.7373 8.46831 23.9565C7.73155 24.1757 7.05035 24.5504 6.47066 25.0552C5.89097 25.56 5.42626 26.1833 5.10788 26.8829C4.78949 27.5826 4.62483 28.3424 4.625 29.1111V79.3244C4.62443 80.6186 5.09449 81.8689 5.94757 82.8422C6.80065 83.8155 7.97853 84.4453 9.26167 84.6144C20.1537 85.9831 30.4132 90.488 38.7917 97.5811C39.7457 98.32 40.9182 98.7211 42.125 98.7211C43.3318 98.7211 44.5043 98.32 45.4583 97.5811V97.5811Z" stroke={stroke} strokeWidth="6" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M42.125 37.6875V98.7242" stroke={stroke} strokeWidth="6" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default BookWithCircleIcon;
