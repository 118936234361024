import { useTheme } from '@mui/styles';

const LanguageGreenIcon = (props) => {
  const { palette } = useTheme();

  const {
    fill = 'none',
    innerFill = palette.common.turquoiseSeaweedGreen,
    rectFill = palette.common.turquoiseSeaweedGreen,
  } = props;

  return (
    <svg width="33" height="33" viewBox="0 0 34 35" fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect y="0.422363" width="33.686" height="33.7477" rx="16.843" fill={rectFill} fillOpacity="0.2" />
      <path d="M14.3115 15.3979C14.0528 15.3979 13.8428 15.1879 13.8428 14.9292V14.4604H12.9053V14.9292C12.9053 15.1879 12.6953 15.3979 12.4365 15.3979C12.1778 15.3979 11.9678 15.1879 11.9678 14.9292V13.0542C11.9678 12.2786 12.5984 11.6479 13.374 11.6479C14.1496 11.6479 14.7803 12.2786 14.7803 13.0542V14.9292C14.7803 15.1879 14.5703 15.3979 14.3115 15.3979ZM13.8428 13.5229V13.0542C13.8428 12.7954 13.6328 12.5854 13.374 12.5854C13.1153 12.5854 12.9053 12.7954 12.9053 13.0542V13.5229H13.8428Z" fill={innerFill} />
      <path d="M21.999 24.7964C21.874 24.7964 21.7559 24.7476 21.6678 24.6589L18.9928 21.9839H16.374C15.5984 21.9839 14.9678 21.3533 14.9678 20.5776V15.8901C14.9678 15.1145 15.5984 14.4839 16.374 14.4839H22.9365C23.7121 14.4839 24.3428 15.1145 24.3428 15.8901V20.5776C24.3428 21.3533 23.7121 21.9839 22.9365 21.9839H22.4678V24.3276C22.4678 24.5176 22.354 24.6876 22.1784 24.7608C22.1215 24.7845 22.0609 24.7964 21.999 24.7964ZM16.374 15.4214C16.1153 15.4214 15.9053 15.6314 15.9053 15.8901V20.5776C15.9053 20.8364 16.1153 21.0464 16.374 21.0464H19.1865C19.3103 21.0464 19.4309 21.0964 19.5178 21.1839L21.5303 23.1964V21.5151C21.5303 21.2564 21.7403 21.0464 21.999 21.0464H22.9365C23.1953 21.0464 23.4053 20.8364 23.4053 20.5776V15.8901C23.4053 15.6314 23.1953 15.4214 22.9365 15.4214H16.374Z" fill={innerFill} />
      <path d="M15.3428 15.0464H23.5928V21.0464L21.7178 21.4214V23.6714L19.4678 21.4214L15.3428 21.0464V15.0464Z" fill={innerFill} />
      <path d="M19.5968 20.6419C19.3148 20.6419 19.0643 20.5939 18.8453 20.4979C18.6263 20.4019 18.4553 20.2654 18.3323 20.0884C18.2093 19.9144 18.1478 19.7089 18.1478 19.4719C18.1478 19.2319 18.2093 19.0249 18.3323 18.8509C18.4553 18.6769 18.6263 18.5419 18.8453 18.4459C19.0643 18.3499 19.3148 18.3019 19.5968 18.3019H20.4023V19.1479H19.5968C19.4378 19.1479 19.3238 19.1734 19.2548 19.2244C19.1888 19.2754 19.1558 19.3579 19.1558 19.4719C19.1558 19.5859 19.1888 19.6684 19.2548 19.7194C19.3238 19.7704 19.4378 19.7959 19.5968 19.7959C19.6658 19.7959 19.7513 19.7914 19.8533 19.7824C19.9553 19.7764 20.0753 19.7659 20.2133 19.7509L20.3573 20.5519C20.2283 20.5789 20.1008 20.5999 19.9748 20.6149C19.8488 20.6329 19.7228 20.6419 19.5968 20.6419ZM18.8498 18.9814C18.7118 18.8284 18.6113 18.6844 18.5483 18.5494C18.4883 18.4144 18.4583 18.2689 18.4583 18.1129C18.4583 17.9239 18.4958 17.7544 18.5708 17.6044C18.6488 17.4514 18.7523 17.3209 18.8813 17.2129C19.0103 17.1019 19.1558 17.0179 19.3178 16.9609C19.4828 16.9039 19.6523 16.8754 19.8263 16.8754C19.9223 16.8754 20.0168 16.8844 20.1098 16.9024C20.2058 16.9174 20.2958 16.9399 20.3798 16.9699L20.1143 17.7664C20.0693 17.7514 20.0273 17.7409 19.9883 17.7349C19.9523 17.7259 19.9133 17.7214 19.8713 17.7214C19.7843 17.7214 19.7093 17.7379 19.6463 17.7709C19.5863 17.8009 19.5413 17.8444 19.5113 17.9014C19.4813 17.9584 19.4663 18.0229 19.4663 18.0949C19.4663 18.1549 19.4768 18.2119 19.4978 18.2659C19.5218 18.3169 19.5578 18.3694 19.6058 18.4234L18.8498 18.9814Z" fill="#D6F7EC" />
      <path d="M11.6865 20.1089C11.6246 20.1089 11.564 20.097 11.5071 20.0733C11.3315 20.0001 11.2178 19.8301 11.2178 19.6401V17.2964H10.749C9.9734 17.2964 9.34277 16.6658 9.34277 15.8901V11.2026C9.34277 10.427 9.9734 9.79639 10.749 9.79639H17.3115C18.0871 9.79639 18.7178 10.427 18.7178 11.2026V13.0776C18.7178 13.3364 18.5078 13.5464 18.249 13.5464C17.9903 13.5464 17.7803 13.3364 17.7803 13.0776V11.2026C17.7803 10.9439 17.5703 10.7339 17.3115 10.7339H10.749C10.4903 10.7339 10.2803 10.9439 10.2803 11.2026V15.8901C10.2803 16.1489 10.4903 16.3589 10.749 16.3589H11.6865C11.9453 16.3589 12.1553 16.5689 12.1553 16.8276V18.5083L13.2303 17.4333C13.3184 17.3451 13.4365 17.2964 13.5615 17.2964C13.6865 17.2964 13.8046 17.3451 13.8928 17.4339C14.0753 17.6164 14.0753 17.9139 13.8928 18.097L12.0178 19.972C11.9296 20.0601 11.8115 20.1089 11.6865 20.1089Z" fill={innerFill} />
    </svg>
  );
};
export default LanguageGreenIcon;
