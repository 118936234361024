import { useTheme } from '@mui/styles';

const BookmarksIcon = (props) => {
  const { palette } = useTheme();

  const {
    stroke = palette.text.secondary,
    rectFill = palette.common.ghostlyGrey,
    ...rest
  } = props;

  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <rect width="32" height="32" rx="10.6667" fill={rectFill} />
      <path d="M19.75 9.41016C19.0596 9.41016 18.5 9.9698 18.5 10.6602V24.5352C18.4999 24.7622 18.3767 24.9714 18.1781 25.0815C17.9795 25.1916 17.7368 25.1853 17.5442 25.0652L13.5 22.5352L9.45583 25.0627C9.26356 25.1826 9.02142 25.1891 8.82301 25.0796C8.62459 24.9701 8.501 24.7618 8.5 24.5352V10.6602C8.5 8.58909 10.1789 6.91016 12.25 6.91016H19.75C21.8211 6.91016 23.5 8.58909 23.5 10.6602V13.7852C23.5 14.1303 23.2202 14.4102 22.875 14.4102H18.5" stroke={stroke} strokeWidth="1.30435" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default BookmarksIcon;
