import { useTheme } from '@mui/styles';

const SessionDatePickerIcon = (props) => {
  const { palette } = useTheme();

  const {
    fill = 'none',
    stroke = palette.common.darkGray,
    ellipseFill = palette.common.turquoiseSeaweedGreen,
  } = props;

  return (
    <svg width="23" height="22" viewBox="0 0 23 22" fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M1.62695 5.59717C1.62695 4.76874 2.29853 4.09717 3.12695 4.09717H18.877C19.7054 4.09717 20.377 4.76874 20.377 5.59717V18.8472C20.377 19.6756 19.7054 20.3472 18.877 20.3472H3.12695C2.29853 20.3472 1.62695 19.6756 1.62695 18.8472V5.59717Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M1.62695 9.09717H20.377" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.62695 5.97217V1.59717" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M15.377 5.97217V1.59717" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <ellipse cx="20.166" cy="4.30566" rx="2.5" ry="2.5" fill={ellipseFill} />
    </svg>
  );
};

export default SessionDatePickerIcon;
