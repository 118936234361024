import { useTheme } from '@mui/styles';

const TextFilledIcon = (props) => {
  const { palette } = useTheme();

  const {
    fill = palette.common.silverGrey,
    innerFill = palette.common.darkGray,
    ...rest
  } = props;

  return (
    <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <rect opacity="0.2" x="0.5" width="30" height="30" rx="6.91016" fill={fill} />
      <rect x="3" y="2.5" width="25" height="25" rx="5.75846" fill={fill} />
      <g clipPath="url(#clip0_772_36257)">
        <path d="M11.5 21C10.8795 21 10.375 20.4955 10.375 19.875V10.125C10.375 9.5045 10.8795 9 11.5 9H19.0225C19.3185 9 19.597 9.1135 19.8085 9.3195L21.2855 10.761C21.501 10.9715 21.625 11.265 21.625 11.5665V19.875C21.625 20.4955 21.1205 21 20.5 21H11.5ZM11.5 9.75C11.293 9.75 11.125 9.918 11.125 10.125V19.875C11.125 20.082 11.293 20.25 11.5 20.25H20.5C20.707 20.25 20.875 20.082 20.875 19.875V11.5665C20.875 11.466 20.8335 11.368 20.7615 11.298L19.2845 9.8565C19.214 9.788 19.1215 9.75 19.023 9.75H11.5Z" fill={innerFill} />
        <path d="M13.0234 13.5C12.8164 13.5 12.6484 13.332 12.6484 13.125C12.6484 12.918 12.8164 12.75 13.0234 12.75H19.0234C19.2304 12.75 19.3984 12.918 19.3984 13.125C19.3984 13.332 19.2299 13.5 19.0234 13.5H13.0234Z" fill={innerFill} />
        <path d="M13.0234 15.75C12.8164 15.75 12.6484 15.582 12.6484 15.375C12.6484 15.168 12.8164 15 13.0234 15H19.0234C19.2304 15 19.3984 15.168 19.3984 15.375C19.3984 15.582 19.2304 15.75 19.0234 15.75H13.0234Z" fill={innerFill} />
        <path d="M13.0234 18C12.8164 18 12.6484 17.832 12.6484 17.625C12.6484 17.418 12.8164 17.25 13.0234 17.25H16.0234C16.2299 17.25 16.3984 17.418 16.3984 17.625C16.3984 17.832 16.2299 18 16.0234 18H13.0234Z" fill={innerFill} />
      </g>
      <defs>
        <clipPath id="clip0_772_36257">
          <rect width="12" height="12" fill="white" transform="translate(10 9)" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default TextFilledIcon;
