import React from 'react';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';

const { REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID } = process.env;

ReactGA.initialize(REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID);

const GoogleAnalytics = () => {
  const { pathname, search } = useLocation();
  const resolvedPath = pathname + search;

  React.useEffect(() => {
    // Google analytics
    ReactGA.pageview(resolvedPath);
  }, [resolvedPath]);

  return null;
};

export default GoogleAnalytics;
