import { useTheme } from '@mui/styles';

const CancelIcon = (props) => {
  const { palette } = useTheme();

  const {
    fill = palette.common.darkGray,
  } = props;

  return (
    <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M13.1326 22C7.47407 22 2.88721 17.523 2.88721 12C2.88721 6.477 7.47407 2 13.1326 2C18.7911 2 23.378 6.477 23.378 12C23.378 17.523 18.7911 22 13.1326 22ZM13.1326 10.586L10.2352 7.757L8.78548 9.172L11.6839 12L8.78548 14.828L10.2352 16.243L13.1326 13.414L16.03 16.243L17.4797 14.828L14.5813 12L17.4797 9.172L16.03 7.757L13.1326 10.586Z" fill={fill} fillOpacity="0.34" />
    </svg>
  );
};

export default CancelIcon;
