import { useTheme } from '@mui/styles';

const SessionGreenIcon = (props) => {
  const { palette } = useTheme();

  const {
    fill = 'none',
    innerFill = palette.common.turquoiseSeaweedGreen,
    rectFill = palette.common.turquoiseSeaweedGreen,
  } = props;

  return (
    <svg width="33" height="33" viewBox="0 0 34 35" fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect y="0.899902" width="33.686" height="33.7477" rx="16.843" fill={rectFill} fillOpacity="0.2" />
      <path d="M17.5105 25.0459C19.1859 23.6275 21.2373 22.7265 23.4153 22.4524C23.672 22.4186 23.9076 22.2926 24.0782 22.0979C24.2489 21.9032 24.3429 21.6532 24.3428 21.3943V11.3509C24.343 11.197 24.3102 11.0449 24.2466 10.9048C24.183 10.7647 24.0901 10.6399 23.9741 10.5388C23.8581 10.4377 23.7218 10.3626 23.5743 10.3187C23.4269 10.2748 23.2717 10.263 23.1193 10.2842C21.0502 10.6046 19.1094 11.4893 17.5105 12.841C17.3196 12.9885 17.0851 13.0685 16.8438 13.0685C16.6025 13.0685 16.368 12.9885 16.177 12.841C14.5774 11.4894 12.636 10.6052 10.5663 10.2855C10.414 10.2643 10.2589 10.2761 10.1115 10.3199C9.96417 10.3638 9.82791 10.4387 9.71195 10.5397C9.596 10.6406 9.50305 10.7653 9.43936 10.9052C9.37568 11.0452 9.34274 11.1972 9.34277 11.3509V21.3943C9.34266 21.6532 9.43668 21.9032 9.60732 22.0979C9.77796 22.2926 10.0136 22.4186 10.2702 22.4524C12.4489 22.7261 14.5011 23.6272 16.177 25.0459C16.3678 25.1937 16.6024 25.2739 16.8438 25.2739C17.0852 25.2739 17.3197 25.1937 17.5105 25.0459Z" stroke={innerFill} strokeWidth="1.20715" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
export default SessionGreenIcon;
