import { useLocale } from 'util/hooks';
import { DataGrid } from 'components/core';
import { useInvitations } from 'reactQuery/queries';
import { ROLE_IDS } from 'assets/constants/userRoles';

import useTableAdminColumns from './useTableAdminColumns';
import useTableClubManagerColumns from './useTableClubManagerColumns';
import useTableContentCreatorColumns from './useTableContentCreatorColumns';

const InvitationTable = (props) => {
  const {
    roles = [],
  } = props;

  const filterOptions = {
    role: roles,
  };

  const {
    data = { items: [], meta: {} },
    isFetching = true,
    refetch: refetchInvitations = () => { },
  } = useInvitations({
    queryKeyOptions: { filterOptions },
  });

  const {
    items: rows = [],
    meta = {},
  } = data || {};

  // Columns
  const adminColumns = useTableAdminColumns({ refetchInvitations });
  const clubManagerColumns = useTableClubManagerColumns({ refetchInvitations });
  const contentCreatorsColumns = useTableContentCreatorColumns({ refetchInvitations });

  const { t } = useLocale();

  const getUserTableColumns = () => {
    let userColumns = null;
    const role = roles[0];
    if (role === ROLE_IDS.admin) {
      userColumns = adminColumns;
    } if (role === ROLE_IDS.clubManager) {
      userColumns = clubManagerColumns;
    } if (role === ROLE_IDS.contentCreator) {
      userColumns = contentCreatorsColumns;
    }
    return userColumns;
  };

  const columns = getUserTableColumns();

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      loading={isFetching}
      pagination={meta}
      componentsProps={{
        noRowsOverlay: { type: 'user', showButton: true },
        toolbar: {
          placeholder: t('users.searchUsersPlaceholder'),
        },
      }}
    />
  );
};

export default InvitationTable;
