import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/styles';

import { useClubProfileRank } from 'reactQuery/queries';
import { useLocale } from 'util/hooks';
import {
  CyberhubMedalIcon,
  PointsWithShadowIcon,
  CyberhubShieldIcon,
} from 'assets/icons';

const containerStyle = {
  position: 'relative',
};

const onTopStyle = {
  position: 'absolute',
  left: 0,
  top: 0,
  right: 0,
  bottom: 20,
  display: 'block',
  margin: 'auto',
};

const ClubProfileRank = () => {
  const { t } = useLocale();
  const { palette } = useTheme();

  const {
    data = {},
  } = useClubProfileRank();

  const {
    points = '',
    rank = '',
  } = data;

  return (
    <Card sx={{ p: 5, borderRadius: 0 }}>
      <Stack
        spacing={5}
        maxWidth="sm"
        mx="auto"
      >
        <Box mx="auto">
          <Stack
            spacing={2}
            direction="row"
            alignItems="center"
          >
            <PointsWithShadowIcon
              width="47"
              height="47"
              innerFill={palette.common.vividOrange}
              rectFill={palette.common.lightVividOrange}
            />
            <Stack
              spacing={1}
              direction="row"
              alignItems="end"
            >
              <Typography variant="h3">
                {points || 0}
              </Typography>
              <Typography variant="bodyStandardRegular">
                {t('common.totalPoints')}
              </Typography>
            </Stack>
          </Stack>
        </Box>
        <Box display="flex" justifyContent="center">
          <Stack
            spacing={2}
            direction="row"
            alignItems="center"
          >
            <Box sx={containerStyle}>
              <CyberhubMedalIcon
                width="54"
                height="83"
              />
              <CyberhubShieldIcon
                width="22"
                height="25"
                style={onTopStyle}
              />
            </Box>
            <Stack alignItems="center">
              <Typography variant="bodyStandardMedium">
                {Number.isInteger(rank)
                  ? t('helpers.count', { count: rank, ordinal: true })
                  : t('common.unranked')}
              </Typography>
              <Typography variant="bodySmallRegular">
                {`${t('common.on')} ${t('common.cyberHub')}`}
              </Typography>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Card>
  );
};

export default ClubProfileRank;
