import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { LoadingButton } from '@mui/lab';
import {
  Typography, Box,
} from '@mui/material';

import { FormAvatar, FormCheckbox, FormTextField } from 'components/form';
import { getDirtyObject, REGEX } from 'util/helpers';
import { useFastForm, useLocale, useSnackbar } from 'util/hooks';
import { updateProvider, createProvider } from 'services';
import { PATHS } from 'routes';

const ProviderDetailsForm = (props) => {
  const {
    providerDetails,
    createMode,
  } = props;

  const { t } = useLocale();
  const snack = useSnackbar();
  const navigate = useNavigate();

  const onSubmit = async (values) => {
    const dirtyPayload = getDirtyObject(values, defaultValues);

    try {
      let message = '';

      if (createMode) {
        await createProvider(values);
        message = t('providers.form.providerAdded');
      } else {
        await updateProvider(dirtyPayload, providerDetails?.id);
        message = t('providers.form.providerEdited');
      }

      snack({
        severity: 'success',
        message,
      });
      navigate(`/${PATHS.platformSettings}/${PATHS.providersSettings}`);
    } catch (error) {
      error.errors?.forEach((err) => {
        setError(err.property, {
          type: 'api',
          message: err.message,
        });
        if (!err.property) {
          snack({
            severity: 'error',
            message: err.message || t('common.somethingWrong'),
          });
        }
      });
    }
  };

  const defaultValues = {
    name: providerDetails?.name || '',
    logo: providerDetails?.logo || null,
    visibility: providerDetails?.visibility || false,
  };

  const validationSchema = Yup.object({
    name: Yup
      .string()
      .trim()
      .required(t('providers.form.validation.enterName'))
      .matches(REGEX.alphaNumericEnSpace, t('providers.form.validation.enterNameEn')),
    logo: Yup
      .string()
      .nullable(true),
    visibility: Yup
      .bool(),
  });

  const {
    control,
    handleSubmit,
    watch,
    setError,
    formState: {
      isSubmitting,
      isDirty,
      isValid,
    },
  } = useFastForm({
    defaultValues,
    validationSchema,
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box>
        <Box sx={{
          display: 'grid',
          rowGap: 5,
        }}
        >
          <Box>
            <FormAvatar
              name="logo"
              avatar={providerDetails?.logo}
              disabled={isSubmitting}
              control={control}
              uploadButtonLabel={t('common.upload')}
            />
          </Box>
          <Box>
            <FormTextField
              name="name"
              control={control}
              label={t('providers.form.providerName')}
              disabled={isSubmitting}
              fullWidth
            />
          </Box>
          <Box>
            <FormCheckbox
              name="visibility"
              control={control}
              disabled={isSubmitting}
              checkboxProps={{
                checked: watch('visibility'),
              }}
              label={(
                <div>
                  <Typography variant="bodyStandardRegular">
                    {t('providers.form.setAsVisible')}
                  </Typography>
                </div>
              )}
            />
          </Box>
        </Box>
        <Box sx={{
          my: 8,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        >
          <LoadingButton
            type="submit"
            variant="contained"
            size="fixed"
            loading={isSubmitting}
            disabled={isSubmitting || !isDirty || !isValid}
          >
            {createMode ? t('providers.form.addProvider') : t('common.saveChanges')}
          </LoadingButton>
        </Box>
      </Box>
    </form>
  );
};

ProviderDetailsForm.propTypes = {
  providerDetails: PropTypes.oneOfType([PropTypes.object]),
  createMode: PropTypes.bool,
};

ProviderDetailsForm.defaultProps = {
  providerDetails: {},
  createMode: false,
};

export default ProviderDetailsForm;
