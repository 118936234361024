import END_POINTS from 'services/endPoints';
import api from 'services/api';
import METHODS from 'services/methods';

const deleteChapterMaterial = (courseId, chapterId, materialId) => api(
  END_POINTS.courseChapterMaterial(courseId, chapterId, materialId),
  {
    method: METHODS.DELETE,
  },
)
  .then((response) => Promise.resolve(response))
  .catch((error) => Promise.reject(error));

export default deleteChapterMaterial;
