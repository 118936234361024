import { useParams } from 'react-router-dom';
import { useCustomQuery } from 'reactQuery';
import { getCertificate } from 'services';

const useCertificateDetails = () => {
  const { certificateId } = useParams();

  const query = useCustomQuery({
    queryKey: [`certificate-details-${certificateId}`],
    queryFn: () => getCertificate(certificateId),
  });

  return { ...query };
};

export default useCertificateDetails;
