import api from 'services/api';
import END_POINTS from 'services/endPoints';

const getProfileCertificates = (username) => api(
  END_POINTS.profileCertificates(username),
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default getProfileCertificates;
