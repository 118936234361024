import {
  Box, IconButton,
} from '@mui/material';
import { useTheme } from '@mui/styles';
import { MailIcon } from 'assets/icons';
import { UserAvatarGroup } from 'components/organisms';

const ClubAdminsContactInfo = (props) => {
  const { palette } = useTheme();
  const {
    username = '',
    avatar = '',
    name = '',
    role = '',
    email = '',
  } = props;

  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    }}
    >
      <UserAvatarGroup
        avatar={avatar}
        name={name}
        role={role}
        username={username}
      />
      <IconButton
        sx={{
          border: `1px solid ${palette.common.silverGrey}`,
          width: 32,
          height: 32,
        }}
        onClick={(e) => {
          e.preventDefault();
          window.open(`mailto:${email}`);
        }}
      >
        <MailIcon height="12" />
      </IconButton>
    </Box>
  );
};

export default ClubAdminsContactInfo;
