import END_POINTS from '../../endPoints';
import api from '../../api';

const getTag = (id) => api(
  END_POINTS.tag(id),
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default getTag;
