import {
  Box,
  CircularProgress,
} from '@mui/material';
import InfiniteScroll from 'react-infinite-scroller';

const CustomInfiniteScroll = (props) => {
  const {
    hasMore = false, // is there more items to be loaded?
    loadMore = () => { }, // to request more items
    isFetching = false,
    children,
    ...rest
  } = props;

  const handleLoadMore = () => {
    if (!isFetching) { // Check if it's fetching to avoid double loads
      loadMore();
    }
  };

  return (
    <InfiniteScroll
      pageStart={0}
      hasMore={hasMore}
      loadMore={handleLoadMore}
      loader={(
        <Box
          key={0} // Since loader is a child, React will warn against duplicate keys
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: 5,
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {...rest}
    >
      {children}
    </InfiniteScroll>
  );
};

export default CustomInfiniteScroll;
