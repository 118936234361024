import {
  Typography,
  Box,
  Link,
} from '@mui/material';
import { useLocale } from 'util/hooks';

export const RecaptchaNotice = (props) => {
  const { t } = useLocale();

  return (
    <Box
      sx={{
        display: 'flex',
        flexFlow: 'row wrap',
        textAlign: 'center',
        justifyContent: 'center',
        mt: 10,
      }}
    >
      <Typography
        variant="bodyStandardRegular"
        sx={{
          fontSize: 11.5,
        }}
      >
        {t('recaptcha.recaptchaPolicy')}
        &nbsp;
      </Typography>
      <Link
        component="a"
        href="https://policies.google.com/privacy"
        sx={{
          fontSize: 11.5,
        }}
      >
        {t('recaptcha.privacyPolicy')}
      </Link>
      <Typography
        variant="bodyStandardRegular"
        sx={{
          fontSize: 11.5,
        }}
      >
        &nbsp;
        {t('common.and')}
        &nbsp;
      </Typography>
      <Link
        component="a"
        href="https://policies.google.com/terms"
        sx={{
          fontSize: 11.5,
        }}
      >
        {t('recaptcha.termsOfService')}
      </Link>
    </Box>
  );
};

export default RecaptchaNotice;
