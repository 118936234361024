import { useCustomQuery } from 'reactQuery';
import { getInterests } from 'services';

const useInterests = () => {
  const query = useCustomQuery({
    queryKey: ['interests'],
    queryFn: getInterests,
  });

  return { ...query };
};

export default useInterests;
