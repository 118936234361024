import { forwardRef, useState } from 'react';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { OpenEyeIcon, CloseEyeIcon } from 'assets/icons';

const PasswordField = forwardRef((props, ref) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const renderPasswordEye = () => (
    <InputAdornment position="end">
      <IconButton
        aria-label="toggle password visibility"
        onClick={handleClickShowPassword}
        edge="end"
      >
        {showPassword ? <CloseEyeIcon /> : <OpenEyeIcon />}
      </IconButton>
    </InputAdornment>
  );

  return (
    <TextField
      {...props}
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        ref,
        endAdornment: renderPasswordEye(),
      }}
    />
  );
});

export default PasswordField;
