import { Link } from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid';

import { Status } from 'components/molecules';
import { useLocale, useModal } from 'util/hooks';
import MODAL_KEYS from 'assets/constants/modalKeys';
import { STATUS_NAMES } from 'assets/constants/statuses';
import { PATHS } from 'routes';
import TABLE_ACTIONS from 'assets/constants/tableActions';

const useTableColumns = (props) => {
  const { refetchClubs = () => { } } = props;

  const { t, dir, isAr } = useLocale();
  const { addModal } = useModal();

  const handleOpenModal = (type, row) => {
    addModal({
      key: MODAL_KEYS.clubStatus,
      props: {
        type,
        clubDetails: row,
        refetch: refetchClubs,
      },
    });
  };

  const columns = [
    {
      field: 'nameEn',
      headerName: t('common.nameEn'),
      flex: 1,
      minWidth: 300,
    },
    {
      field: 'nameAr',
      headerName: t('common.nameAr'),
      flex: 1,
      minWidth: 300,
    },
    {
      field: 'abbreviation',
      headerName: t('common.abbreviation'),
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'city',
      headerName: t('common.city'),
      flex: 1,
      minWidth: 200,
      valueGetter: ({ row = {} }) => (isAr ? row?.city.nameAr : row?.city.nameEn),
    },
    {
      field: 'status',
      headerName: t('common.status'),
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        const isActive = params?.row?.status === STATUS_NAMES.active;

        return (
          <Status
            size="small"
            status={(isActive
              ? STATUS_NAMES.active
              : STATUS_NAMES.inactive) || ''}
            label={isActive
              ? t('statuses.active')
              : t('statuses.inactive')}
          />
        );
      },
    },
    {
      field: 'actions',
      headerName: t('common.actions'),
      type: 'actions',
      flex: 0.5,
      minWidth: 100,
      getActions: ({ row }) => {
        const {
          id: clubId = '',
          status = '',
        } = row;
        const isActive = status === STATUS_NAMES.active;

        const actions = [
          <GridActionsCellItem
            label={t('common.updateInformation')}
            component={Link}
            to={`/${PATHS.manageClubs}/${clubId}/edit`}
            dir={dir}
            showInMenu
          />,
        ];

        if (isActive) {
          actions.push(<GridActionsCellItem
            dir={dir}
            label={t('actions.inactivate')}
            onClick={() => handleOpenModal(TABLE_ACTIONS.userManagement.inactivate, row)}
            showInMenu
          />);
        } else {
          actions.push(<GridActionsCellItem
            dir={dir}
            label={t('actions.activate')}
            onClick={() => handleOpenModal(TABLE_ACTIONS.userManagement.activate, row)}
            showInMenu
          />);
        }

        return actions;
      },
    },
  ];

  return columns;
};

export default useTableColumns;
