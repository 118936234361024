import { CourseCard } from 'components/organisms';
import { useLocale } from 'util/hooks';
import {
  Box, Grid,
} from '@mui/material';
import { CustomInfiniteScroll, DataPlaceholder } from 'components/molecules';
import { NoCoursesIcon } from 'assets/icons';
import { useInfinitePublicCourses } from 'reactQuery/queries';
import { useMemo } from 'react';

const PublicCoursesContainer = () => {
  const { t } = useLocale();

  const {
    data: { pages = [] } = {},
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetched,
    isFetchingNextPage,
  } = useInfinitePublicCourses(
    {
      options: {
        withFilterParams: true,
      },
    },
  );

  const publicCoursesList = useMemo(() => pages?.map((page) => {
    const { items = [], meta = {} } = page;

    return (
      <Box
        key={meta.currentPage}
        sx={{
          width: 1,
          display: 'grid',
          mt: 8,
          rowGap: 8,
          columnGap: 6,
          gridTemplateColumns: {
            xs: 'repeat(1, 1fr)',
            sm: 'repeat(1, 1fr)',
            md: 'repeat(2, 1fr)',
            lg: 'repeat(3, 1fr)',
          },
        }}
      >
        {items?.map((course) => (
          <CourseCard
            key={course.id}
            courseDetails={course}
          />
        ))}
      </Box>
    );
  }), [pages]);

  const renderEmpty = () => (
    <DataPlaceholder
      icon={<NoCoursesIcon />}
      header={t('common.noResults')}
    />
  );

  if (isFetched && !isFetching && !pages?.[0]?.items?.length) {
    return renderEmpty();
  }

  return (
    <Box>
      <CustomInfiniteScroll
        loadMore={fetchNextPage}
        hasMore={hasNextPage}
        isFetching={isFetchingNextPage}
      >
        <Grid
          container
        >
          {publicCoursesList}
        </Grid>
      </CustomInfiniteScroll>
    </Box>
  );
};

export default PublicCoursesContainer;
