import Pagination from '@mui/material/Pagination';
import { useSearchParams } from 'react-router-dom';

const UrlPagination = (props) => {
  const {
    count = 0,
    ...rest
  } = props;

  const [searchParams, setSearchParams] = useSearchParams();

  const page = Number(searchParams.get('page') || 1);
  const handleChange = (event, newPage) => {
    searchParams.set('page', newPage);
    setSearchParams(searchParams);
  };

  return (
    <Pagination
      count={count}
      page={page}
      onChange={handleChange}
      {...rest}
    />
  );
};

export default UrlPagination;
