export const postStatus = {
  DRAFT: 'draft',
  UNDER_REVIEW: 'underReview',
  PUBLISHED: 'published',
  UNPUBLISHED: 'unpublished',
  REVISED: 'revised',
  REJECTED: 'rejected',
  PENDING: 'pending',
};

export const postType = {
  ARTICLE: 'article',
  VIDEO: 'video',
  KNOWLEDGE: 'knowledgeShare',
};
