import { useCustomQuery } from 'reactQuery';
import { getPublicPosts } from 'services';

const usePublicPosts = (queryProps = {}) => {
  const { queryKeyOptions = {}, ...rest } = queryProps;

  const query = useCustomQuery({
    queryKey: ['public-posts', queryKeyOptions],
    queryFn: getPublicPosts,
    ...rest,
  });

  return { ...query };
};

export default usePublicPosts;
