import { useTheme } from '@mui/styles';

const PlayVideoIcon = (props) => {
  const { palette } = useTheme();
  const {
    fill = palette.common.white,
  } = props;

  return (
    <svg width="87" height="92" viewBox="0 0 87 92" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g filter="url(#filter0_d_4380_137293)">
        <path d="M75.2691 31.193L22.5901 1.41254C20.9744 0.509732 19.157 0.0243856 17.304 0.000895258C15.4511 -0.0225951 13.6218 0.416521 11.9835 1.27807C10.3375 2.13614 8.95746 3.4239 7.99141 5.00316C7.02535 6.58243 6.50977 8.39359 6.5 10.2423V69.7903C6.50977 71.639 7.02535 73.4502 7.99141 75.0294C8.95746 76.6087 10.3375 77.8965 11.9835 78.7545C13.6281 79.5962 15.4552 80.0236 17.3044 79.999C19.1537 79.9744 20.9687 79.4987 22.5901 78.6137L75.2562 48.8269C76.844 47.9593 78.1684 46.6833 79.0909 45.1323C80.0133 43.5812 80.5 41.8121 80.5 40.0099C80.5 38.2077 80.0133 36.4386 79.0909 34.8875C78.1684 33.3365 76.844 32.0605 75.2562 31.193H75.2691Z" fill={fill} />
      </g>
      <defs>
        <filter id="filter0_d_4380_137293" x="0.964872" y="0" width="85.0703" height="91.0703" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="5.53513" />
          <feGaussianBlur stdDeviation="2.76756" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4380_137293" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4380_137293" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

export default PlayVideoIcon;
