import { useMediaQuery } from '@mui/material';
import Stack from '@mui/material/Stack';

const HorizontalScrollContainer = ({ children, sx = {}, ...rest }) => {
  const isSmallView = useMediaQuery('(max-width:1050px)');
  return (
    <Stack
      direction="row"
      spacing={{ xs: 6, sm: 8.5 }}
      sx={{
        overflowX: isSmallView ? 'auto' : 'visible',
        width: 1,
        pb: 4,
        px: { xs: 4, lg: 0 },
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Stack>
  );
};

export default HorizontalScrollContainer;
