import PropTypes from 'prop-types';
import { useController } from 'react-hook-form';
import { TimePicker } from 'components/molecules';

const FormTimePicker = (props) => {
  const {
    helperText,
    InputAdornmentProps = {},
    ...rest
  } = props;

  const { field, fieldState } = useController(rest);
  const { onChange } = field;
  const { error } = fieldState;

  const hasErrored = Boolean(error);

  const onFieldChange = (newValue = '') => onChange(newValue);

  return (
    <TimePicker
      pickerProps={{
        ...field,
        ...rest,
        onChange: onFieldChange,
        InputAdornmentProps,
      }}
      inputProps={{
        ...field,
        ...rest,
        error: !!error,
        helperText: (hasErrored && error?.message) || helperText,
      }}
    />
  );
};

FormTimePicker.propTypes = {
  helperText: PropTypes.string,
};

FormTimePicker.defaultProps = {
  helperText: '',
};
export default FormTimePicker;
