import {
  Box, Stack, Switch, Typography,
} from '@mui/material';
import { useLocale } from 'util/hooks';

const SettingSwitchWrapper = (props) => {
  const { isAr } = useLocale();

  const {
    onChange = () => { },
    settingDetails = {},
  } = props;

  const {
    key = '',
    nameEn = '',
    nameAr = '',
    falseEn = '',
    falseAr = '',
    truthEn = '',
    truthAr = '',
    value = false,
  } = settingDetails;

  return (
    <Box
      sx={{
        mb: 23,
      }}
    >
      <Typography variant="h6">{isAr ? nameAr : nameEn}</Typography>
      <Stack
        direction="row"
        spacing={4}
        alignItems="center"
        sx={{
          mt: 4,
        }}
      >
        <Typography>{isAr ? falseAr : falseEn}</Typography>
        <Switch
          id={String(key)}
          name={key}
          checked={value}
          onChange={onChange}
        />
        <Typography>{isAr ? truthAr : truthEn}</Typography>
      </Stack>
    </Box>
  );
};

export default SettingSwitchWrapper;
