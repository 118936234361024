import { GridActionsCellItem } from '@mui/x-data-grid';
import MODAL_KEYS from 'assets/constants/modalKeys';
import { getStatusByName } from 'assets/constants/statuses';

import TABLE_ACTIONS from 'assets/constants/tableActions';
import { getRoleById } from 'assets/constants/userRoles';
import { useLocale, useModal } from 'util/hooks';
import { Status } from 'components/molecules';

const useTableClubManagerColumns = (props = {}) => {
  const { refetchInvitations = () => { } } = props;

  const { t, dir } = useLocale();
  const { addModal } = useModal();

  const handleOpenModal = (type, invitationProps) => {
    addModal({
      key: MODAL_KEYS.userInvitation,
      props: {
        type,
        invitationProps,
        refetchInvitations,
      },
    });
  };

  return (
    [
      {
        field: 'email',
        headerName: t('common.email'),
        flex: 1,
        minWidth: 180,
      },
      {
        field: 'clubName',
        headerName: t('common.university'),
        flex: 1,
        minWidth: 180,
      },
      {
        field: 'role',
        headerName: t('common.role'),
        flex: 1,
        minWidth: 100,
        valueGetter: (params) => t(getRoleById(params?.row?.role?.id)?.localizationKey),
      },
      {
        field: 'status',
        headerName: t('common.status'),
        flex: 1,
        minWidth: 100,
        renderCell: (params) => (
          <Status
            status={params.row.status || ''}
            label={t(getStatusByName(params?.row?.status)?.localizationKey) || ''}
          />
        ),
      },
      {
        field: 'actions',
        headerName: t('common.actions'),
        type: 'actions',
        flex: 1,
        minWidth: 50,
        getActions: ({ row }) => {
          const { status } = row;
          const isPending = status === getStatusByName('pending').name;

          const actions = [];
          if (isPending) {
            actions.push(
              <GridActionsCellItem
                dir={dir}
                label={t('actions.resendInvitation')}
                onClick={() => handleOpenModal(
                  TABLE_ACTIONS.userManagement.resend,
                  row,
                )}
                showInMenu
              />,
              <GridActionsCellItem
                dir={dir}
                label={t('actions.cancelInvitation')}
                onClick={() => handleOpenModal(
                  TABLE_ACTIONS.userManagement.cancel,
                  row,
                )}
                showInMenu
              />,
            );
          }

          return actions;
        },
      },
    ]
  );
};

export default useTableClubManagerColumns;
