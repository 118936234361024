import END_POINTS from 'services/endPoints';
import api from 'services/api';

const getSessionRecording = (courseId, sessionId) => api(
  END_POINTS.sessionRecording(courseId, sessionId),
)
  .then((response) => Promise.resolve(response))
  .catch((error) => Promise.reject(error));

export default getSessionRecording;
