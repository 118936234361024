import { useParams } from 'react-router-dom';

import { getClubManager } from 'services';
import useCustomQuery from 'reactQuery/useCustomQuery';

const useClubManager = (queryProps = {}) => {
  const { clubId } = useParams();

  const query = useCustomQuery({
    queryKey: [`club-manager-${clubId}`],
    queryFn: () => getClubManager(clubId),
    ...queryProps,
  });

  return { ...query };
};

export default useClubManager;
