import axios from 'axios';
import { forOwn } from 'lodash';
import { setRecoil } from 'recoil-nexus';

import { getItem, removeItem, storageKeys } from 'util/localStorage';
import {
  REGEX,
} from 'util/helpers';

import { authAtom, managementModeAtom } from 'recoil/atoms';
import END_POINTS from './endPoints';

const { REACT_APP_BASE_URL } = process.env;

/**
 *
 * @param {string} path
 * @param {object = {
    requireAuth: true,
    method: 'GET',
    data: null,
    pagination: null,
    filterOptions: null,
    relationFilterOptions: null,
    searchOptions: null,
    searchFields: null,
  }} options
 * @returns
 */
const api = async (
  path,
  options = {},
) => {
  const {
    data = options.data || null,
    method = options.method || 'GET',
    requireAuth = options.requireAuth || true,
    // Options
    pagination = options.pagination || null,
    searchOptions = options.searchOptions || null,
    filterOptions = options.filterOptions || null,
    relationFilterOptions = options.relationFilterOptions || null,
    signal = options.signal || null,
    recaptchaToken = options.recaptchaToken || undefined,
    // searchFields: null,
  } = options;

  const token = getItem(storageKeys.accessToken) || '';
  const selectedLanguage = getItem(storageKeys.i18nextLng);

  const buildUrl = () => {
    let complexUrl = '';

    complexUrl += REACT_APP_BASE_URL + path;

    if (!path.includes('?')
      && (pagination || searchOptions || filterOptions || relationFilterOptions)) {
      complexUrl += '?';
    }

    // Search
    if (searchOptions) {
      const searchArray = [];
      forOwn(searchOptions, (value, key) => (value ? searchArray.push(`&${key}=${value}`) : null));
      complexUrl += searchArray.join('');
    }

    // Filter
    if (filterOptions) {
      const filterArray = [];
      Object.entries(filterOptions).forEach(([key, value], index) => {
        if (value) {
          filterArray.push(`&filters[${index}]=${key},${value}`);
        }
      });
      complexUrl += filterArray.join('');
    }

    // Relation filter
    if (relationFilterOptions) {
      const filterArray = [];
      Object.entries(relationFilterOptions).forEach(([key, value], index) => {
        if (value) {
          filterArray.push(`&relationFilters[${index}]=User,${key},${value}`);
        }
      });
      complexUrl += filterArray.join('');
    }

    // Pagination
    if (pagination) {
      if (pagination.page) {
        complexUrl += `&page=${pagination.page}`;
      }

      if (pagination.limit) {
        complexUrl += `&limit=${pagination.limit}`;
      }
    }

    // Replace ?& with ?
    complexUrl = complexUrl.replace(REGEX.questionAnd, '?');

    return complexUrl;
  };

  const config = {
    method,
    url: buildUrl(),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...(requireAuth && { Authorization: `Bearer ${token}` }),
      'Accept-Language': selectedLanguage,
      recaptcha: recaptchaToken,
    },
    ...(data && { data: JSON.stringify(data) }),
    signal,
  };

  return axios(config)
    .then((response) => {
      if (!response.statusText === 'OK') {
        throw response;
      }
      return response.data;
    })
    .then((result) => result.data ?? result)
    .catch((error) => {
      const { response } = error;
      if (response.status === 401) {
        if (path === END_POINTS.logout) {
          return null;
        }
        // logout logic
        removeItem(storageKeys.accessToken);
        setRecoil(authAtom, {
          isLoggedIn: false,
          accessToken: '',
          user: {},
        });
        setRecoil(managementModeAtom, false);
      }
      return Promise.reject(response?.data || response || error);
    });
};

export default api;
