import { forwardRef } from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select as MUISelect,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { useTheme } from '@mui/styles';
import { t } from 'i18next';
import Status from '../Status/Status';

const SelectStatus = forwardRef((props, ref) => {
  const {
    options = [],
    ...rest
  } = props;

  const theme = useTheme();

  return (
    <FormControl sx={{
      m: 1,
      width: {
        xs: 1,
        sm: '334px',
      },
    }}
    >
      <InputLabel id="status-label">{t('common.status')}</InputLabel>
      <MUISelect
        IconComponent={ExpandMore}
        MenuProps={{
          dir: theme.direction,
          PaperProps: {
            sx: {
              backgroundColor: 'secondary.contrastText',
              my: 1.5,
            },
          },
        }}
        variant="outlined"
        {...rest}
        ref={ref}
      >
        {options && options.map((option) => (
          <MenuItem
            key={option}
            value={option}
            sx={{ color: theme.palette[option].contrastText }}
          >
            <Status
              status={option}
              label={t(`statuses.${option}`)}
            />
          </MenuItem>
        ))}
      </MUISelect>
    </FormControl>
  );
});

export default SelectStatus;
