import { CourseCard } from 'components/organisms';
import { Box } from '@mui/material';
import { DataPlaceholder } from 'components/molecules';
import { NoCoursesIcon } from 'assets/icons';
import { useLocale } from 'util/hooks';

const CoursesCardsContainer = (props) => {
  const { courses = [] } = props;

  const { t } = useLocale();

  const renderCourses = courses?.map((course) => (
    <CourseCard
      key={course.id}
      courseDetails={course}
      sx={{ m: 5 }}
    />
  ));

  return (
    courses?.length > 0
      ? (
        <Box
          sx={{
            width: 1,
            display: 'flex',
            justifyContent: { xs: 'center', md: 'start' },
            flexWrap: 'wrap',
          }}
        >
          {renderCourses}
        </Box>
      )
      : (
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        >
          <DataPlaceholder
            icon={<NoCoursesIcon />}
            header={t('common.noData')}
          />
        </Box>
      )
  );
};

export default CoursesCardsContainer;
