import {
  Box,
  Typography,
  InputAdornment,
} from '@mui/material';

import {
  useAuth,
  useLocale,
  useSnackbar,
  useFastForm,
} from 'util/hooks';

import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { FormTextField } from 'components/form';
import { verifiedEmailAtom } from 'recoil/atoms';
import { useRecoilState } from 'recoil';
import { sendEmailVerificationCode } from 'services';
import { PATHS } from 'routes';
import { useNavigate } from 'react-router-dom';
import { REGEX } from 'util/helpers';
import { AUTHENTICATION_SNACKBAR_POSITION } from 'assets/constants/snackbar';

const EmailAuthenticationForm = () => {
  const { currentUser, isStudent } = useAuth();
  const { t, isAr } = useLocale();
  const navigate = useNavigate();
  const snack = useSnackbar();

  const [verifiedEmail, setVerifiedEmail] = useRecoilState(verifiedEmailAtom);
  const resolvedDomain = isStudent
    ? currentUser?.clubDetails?.studentDomains
    : currentUser?.clubDetails?.managerDomains;

  const onConfirm = async (values) => {
    const userEmail = {
      email: `${values.email}@${resolvedDomain}`,
    };

    try {
      await sendEmailVerificationCode(userEmail);
      setVerifiedEmail({
        ...verifiedEmail,
        email: values.email,
        fullEmail: userEmail.email,
      });

      navigate(`/${PATHS.emailVerification}`);
      snack({
        message: t('verifyEmail.codeHasBeenSent'),
        severity: 'success',
        snackbarProps: AUTHENTICATION_SNACKBAR_POSITION,
      });
    } catch (error) {
      error.errors?.forEach((err) => {
        setError(err.property, {
          type: 'api',
          message: err.message,
        });
        if (!err.property) {
          snack({
            message: err.message || t('common.somethingWrong'),
            severity: 'error',
            snackbarProps: AUTHENTICATION_SNACKBAR_POSITION,
          });
        }
      });
    }
  };

  const defaultValues = {
    email: verifiedEmail.email || '',
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .matches(REGEX.alphaNumericEnDashDotUnderscore, t('registration.validation.validateEmail'))
      .required(t('verifyEmail.emailValidation')),
  });

  const {
    control,
    setError,
    handleSubmit,
  } = useFastForm({
    defaultValues,
    validationSchema,
  });

  const emailInputAdornment = (
    <InputAdornment
      position={isAr ? 'start' : 'end'}
    >
      {isAr
        ? `${resolvedDomain}@`
        : `@${resolvedDomain}`}
    </InputAdornment>
  );

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      py: 8,
    }}
    >
      <form onSubmit={handleSubmit(onConfirm)}>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Typography variant="h5">
            {t('verifyEmail.verifyYourEmail')}
          </Typography>
        </Box>
        <Box sx={{
          mt: 12,
          mb: 7,
          display: 'flex',
          justifyContent: 'start',
          maxWidth: { xs: 300, sm: 380 },
        }}
        >
          <Typography variant="bodyStandardRegular">
            {t('verifyEmail.enterUniversityEmail')}
          </Typography>
        </Box>
        <Box
          sx={{
            maxWidth: { xs: 300, sm: 380 },
          }}
        >
          <FormTextField
            label={t('common.email')}
            name="email"
            fullWidth
            control={control}
            autoFocus
            InputProps={{
              startAdornment: isAr && emailInputAdornment,
              endAdornment: !isAr && emailInputAdornment,
            }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: 10,
            mb: 2,
          }}
        >
          <LoadingButton
            type="submit"
            size="regular"
            variant="contained"
            sx={{ borderRadius: 1.5 }}
          >
            {t('common.send')}
          </LoadingButton>
        </Box>
      </form>
    </Box>
  );
};

export default EmailAuthenticationForm;
