export const ROLE_IDS = {
  admin: 1,
  member: 2,
  leader: 3,
  clubManager: 4,
  contentCreator: 5,
};

export const AUTH_ROLES = {
  admin: 'admin',
  member: 'member',
  leader: 'leader',
  clubManager: 'clubManager',
  contentCreator: 'contentCreator',
  student: 'student',
};

export const USER_ROLES = [
  {
    id: ROLE_IDS.admin,
    name: AUTH_ROLES.admin,
    localizationKey: 'roles.admin',
    invitable: true,
    dependents: [
      ROLE_IDS.admin,
      ROLE_IDS.member,
      ROLE_IDS.leader,
      ROLE_IDS.clubManager,
      ROLE_IDS.contentCreator,
    ],
  },
  {
    id: ROLE_IDS.clubManager,
    name: AUTH_ROLES.clubManager,
    localizationKey: 'roles.clubManager',
    invitable: true,
    dependents: [
      ROLE_IDS.member,
      ROLE_IDS.leader,
    ],
  },
  {
    id: ROLE_IDS.leader,
    name: AUTH_ROLES.leader,
    localizationKey: 'roles.leader',
    invitable: false,
    dependents: [],
  },
  {
    id: ROLE_IDS.member,
    name: AUTH_ROLES.member,
    localizationKey: 'roles.member',
    invitable: false,
    dependents: [],
  },
  {
    id: ROLE_IDS.contentCreator,
    name: AUTH_ROLES.contentCreator,
    localizationKey: 'roles.contentCreator',
    invitable: true,
    dependents: [],
  },
];

export const getRoleById = (id) => USER_ROLES.find((role) => role.id === Number(id));
export const getRoleByName = (name) => USER_ROLES.find((role) => role.name === String(name));
export const getRoleByKey = (key, value) => USER_ROLES.find((role) => role[key] === value);
export const getInvitableRoles = () => USER_ROLES.filter((role) => role.invitable);
export const getDependentsForRole = (id) => getRoleById(id)?.dependents
  .map((dependentId) => getRoleById(dependentId));
export const getDependentsForRoleByName = (name) => getRoleByName(name)?.dependents
  .map((dependentId) => getRoleById(dependentId));
