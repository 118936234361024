import { Box, Typography, useMediaQuery } from '@mui/material';
import { WhiteArabicLogo, CheckCircleOutline, WhiteEnglishLogo } from 'assets/icons';
import { useTheme } from '@mui/styles';
import { Link } from 'react-router-dom';
import { PATHS } from 'routes';
import { Fragment } from 'react';
import { useLocale } from 'util/hooks';

const SplitContainer = (props) => {
  const { component } = props;
  const theme = useTheme();
  const { t, isAr } = useLocale();
  const showOnMobile = useMediaQuery('(max-width:899px)');

  const features = [
    {
      title: t('features.title.cybersecuritySpace'),
      description: t('features.description.cybersecuritySpace'), // temp
    },
    {
      title: t('features.title.courses'),
      description: t('features.description.courses'),
    },
    {
      title: t('features.title.articlesAndMore'),
      description: t('features.description.articlesAndMore'),
    },
  ];

  const displayFeatures = features.map((feature) => (
    <Fragment key={feature.title}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CheckCircleOutline
            width="22.5"
            height="22.5"
            stroke={theme.palette.primary.contrastText}
          />
        </Box>
        <Typography
          variant="bodyStandardMedium"
          sx={{
            mx: 4,
            color: 'common.white',
          }}
        >
          {feature.title}
        </Typography>
      </Box>
      <Typography
        variant="bodySmallRegular"
        sx={{
          ml: 10,
          color: 'common.white',
          mb: 15,
        }}
      >
        {feature.description}
      </Typography>
    </Fragment>
  ));

  const fullPageStyle = (
    <Box
      sx={{
        display: {
          xs: 'none',
          sm: 'none',
          md: 'flex',
          lg: 'flex',
          xl: 'flex',
        },
        justifyContent: 'center',
        alignItems: 'start',
        height: '100vh',
        width: '40%',
        padding: 8,
        overflowY: 'scroll',
        backgroundImage: (isAr
          ? 'linear-gradient(-155.58deg, #8DDD88 -12.74%, #13A7FF 86.94%)'
          : 'linear-gradient(155.58deg, #8DDD88 -12.74%, #13A7FF 86.94%)'),
      }}
    >
      <Box sx={{
        flexDirection: 'column',
        alignItems: 'start',
      }}
      >
        <Box sx={{ my: 28 }}>
          <Link to={PATHS.root}>
            {isAr
              ? (
                <WhiteArabicLogo
                  width="250"
                  height="100"
                />
              ) : (
                <WhiteEnglishLogo
                  width="250"
                  height="100"
                />
              )}
          </Link>
        </Box>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
        >
          {displayFeatures}
        </Box>
      </Box>
    </Box>
  );

  const mobilePageStyle = (
    <Box
      sx={{
        display: {
          xs: 'flex',
          sm: 'flex',
          md: 'none',
          lg: 'none',
          xl: 'none',
        },
        height: 115,
        width: '100%',
        position: 'fixed',
        justifyContent: 'center',
        alignItems: 'center',
        top: 0,
        left: 0,
        zIndex: 2,
        backgroundImage: (isAr
          ? 'linear-gradient(-155.58deg, #8DDD88 -12.74%, #13A7FF 86.94%)'
          : 'linear-gradient(155.58deg, #8DDD88 -12.74%, #13A7FF 86.94%)'),
      }}
    >
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        mt: 10,

      }}
      >
        <Link to={PATHS.root}>
          {isAr
            ? (
              <WhiteArabicLogo
                width="150"
                height="70"
              />
            ) : (
              <WhiteEnglishLogo
                width="150"
                height="70"
              />
            )}
        </Link>
      </Box>
    </Box>
  );

  return (
    <Box sx={{
      display: 'flex',
      height: '100vh',
      width: '100vw',
      overflow: 'hidden',
    }}
    >
      {!showOnMobile
        ? fullPageStyle
        : mobilePageStyle}
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'start',
        width: {
          xs: '100%',
          sm: '100%',
          md: '60%',
          lg: '60%',
          xl: '60%',
        },
        overflowY: 'scroll',
        pt: {
          xs: 40,
          sm: 40,
          md: 35,
          lg: 35,
          xl: 35,
        },
      }}
      >
        {component}
      </Box>
    </Box>
  );
};

export default SplitContainer;
