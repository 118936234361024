import END_POINTS from '../endPoints';
import downloadApi from '../downloadApi';

const exportAttendanceList = (id, courseName) => downloadApi({
  path: END_POINTS.exportAttendanceList(id),
  fileName: `CyberHub_Attendance_List#${courseName}.csv`,
})
  .then((response) => Promise.resolve(response))
  .catch((error) => Promise.reject(error));

export default exportAttendanceList;
