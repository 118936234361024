import {
  Box, Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import {
  FormAutocomplete,
  FormTextField,
} from 'components/form';
import PropTypes from 'prop-types';
import { transferUniversityForStudent, transferUniversityForClubManager, getClubs } from 'services';
import * as Yup from 'yup';
import { useFastForm, useLocale, useSnackbar } from 'util/hooks';
import { REGEX } from 'util/helpers';
import { useCallback, useMemo } from 'react';
import { AUTH_ROLES } from 'assets/constants/userRoles';

const TransferUniversityForm = (props) => {
  const {
    user,
  } = props;

  const { t } = useLocale();
  const snack = useSnackbar();

  const userName = user.username;
  const isStudent = (user.roleName === AUTH_ROLES.member
    || user.roleName === AUTH_ROLES.leader);

  const onSaveForm = useCallback(async (dirtyValues) => {
    try {
      let payload = {
        email: dirtyValues.email,
        club: { id: Number(dirtyValues.club.id) },
      };
      if (isStudent) {
        payload = {
          ...dirtyValues,
          club: { id: Number(dirtyValues.club.id) },
        };
        await transferUniversityForStudent(payload, userName);
      } else {
        await transferUniversityForClubManager(payload, userName);
      }
      snack({
        message: t('editProfile.universityChanged'),
        severity: 'success',
      });
    } catch (error) {
      snack({
        message: error.errors[0].message || t('common.somethingWrong'),
        severity: 'error',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, userName]);

  const defaultValues = {
    club: '',
    email: '',
    studentId: '',
  };

  const validationSchema = useMemo(() => Yup.object({
    club: Yup
      .object()
      .nullable()
      .required(t('editProfile.validation.enterUniversity')),
    studentId: Yup.string()
      .when('isStudent', {
        is: (value) => isStudent,
        then: Yup.string().required(t('editProfile.validation.enterStudentId')),
      }),
    email: Yup
      .string()
      .required(t('editProfile.validation.enterEmail'))
      .matches(REGEX.mail, t('registration.validation.validateEmail')),
  }), [isStudent, t]);

  const {
    control,
    handleSubmit,
    formState: {
      isSubmitting,
      isDirty,
    },
  } = useFastForm({
    defaultValues,
    validationSchema,
  });

  const personalInformationForm = (
    <form onSubmit={handleSubmit(onSaveForm)}>
      <Box>
        <Box sx={{ mb: 7 }}>
          <FormAutocomplete
            name="club"
            label={t('common.club')}
            control={control}
            fullWidth
            selectFunc={getClubs}
          />
        </Box>
        <Box sx={{ mb: 7 }}>
          <FormTextField
            name="email"
            type="email"
            label={t('common.email')}
            disabled={isSubmitting}
            fullWidth
            control={control}
          />
        </Box>
        {isStudent
          && (
            <Box sx={{ mb: 7 }}>
              <FormTextField
                label={t('registration.studentId')}
                name="studentId"
                disabled={isSubmitting}
                fullWidth
                control={control}
              />
            </Box>
          )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <LoadingButton
          type="submit"
          variant="contained"
          size="medium"
          disabled={!isDirty || isSubmitting}
          loading={isSubmitting}
        >
          {t('editProfile.transferUniversity')}
        </LoadingButton>
      </Box>
    </form>
  );

  return (
    <>
      <Typography
        variant="bodyStandardMedium"
        sx={{
          color: 'common.darkGray',
          display: 'flex',
          justifyContent: 'center',
          my: 7,
        }}
      >
        {t('editProfile.transferUniversity')}
      </Typography>
      {personalInformationForm}
    </>
  );
};

TransferUniversityForm.propTypes = {
  user: PropTypes.oneOfType([PropTypes.object]),
};

TransferUniversityForm.defaultProps = {
  user: {},
};

export default TransferUniversityForm;
