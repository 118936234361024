import { useTheme } from '@mui/styles';

const XPlatformIcon = (props) => {
  const { palette } = useTheme();

  const {
    fill = palette.common.darkGray,
    ...rest
  } = props;

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d="M11.9593 8.46864L19.2446 0H17.5182L11.1924 7.3532L6.13991 0H0.3125L7.9528 11.1193L0.3125 20H2.03899L8.71928 12.2348L14.055 20H19.8825L11.9593 8.46864ZM9.59461 11.2173L8.82049 10.1101L2.66107 1.29968H5.31287L10.2836 8.40994L11.0577 9.51718L17.5191 18.7594H14.8673L9.59461 11.2173Z" fill={fill} />
    </svg>

  );
};

export default XPlatformIcon;
