import { useTheme } from '@mui/styles';

const FlagWithCircleIcon = (props) => {
  const { palette } = useTheme();
  const {
    fill = palette.common.dimMintLeafGreen,
    ...rest
  } = props;

  return (
    <svg width="96" height="115" viewBox="0 0 96 115" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <circle cx="61.575" cy="33.5633" r="33.5633" fill={fill} fillOpacity="0.2" />
      <g filter="url(#filter0_d_195_23801)">
        <path d="M9.42328 101.712C8.01766 101.712 6.87686 100.571 6.87686 99.1655V73.9288C6.87007 73.8575 6.86328 73.7896 6.86328 73.7183C6.86328 73.647 6.86668 73.5757 6.87686 73.5078V28.0763C6.86668 28.005 6.86328 27.9371 6.86328 27.8658C6.86328 27.7945 6.86668 27.7232 6.87686 27.6553V22.773C6.87686 21.3674 8.01766 20.2266 9.42328 20.2266C10.8289 20.2266 11.9697 21.3674 11.9697 22.773V25.3194H81.8977C83.2592 25.3194 84.5392 25.8524 85.5 26.8133C86.4575 27.7741 86.9871 29.0541 86.9871 30.4122C86.9837 31.1218 86.8411 31.8077 86.5593 32.4494L78.421 50.7938L86.5695 69.0974C87.1229 70.3367 87.1569 71.7219 86.6714 72.9951C86.1825 74.2649 85.2318 75.2699 83.9892 75.8233C83.3339 76.1153 82.6379 76.2647 81.9181 76.2647H11.9765V99.1655C11.9697 100.571 10.8255 101.712 9.42328 101.712ZM81.9113 71.1719L73.7627 52.8682C73.1788 51.5543 73.1788 50.0468 73.7627 48.7329L81.8977 30.3987L11.9697 30.4122V71.1719H81.9113Z" fill={fill} />
      </g>
      <defs>
        <filter id="filter0_d_195_23801" x="0.293195" y="20.2266" width="93.2886" height="94.6255" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="6.57009" />
          <feGaussianBlur stdDeviation="3.28504" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_195_23801" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_195_23801" result="shape" />
        </filter>
      </defs>
    </svg>

  );
};

export default FlagWithCircleIcon;
