import { getAllCourses } from 'services';
import { useCustomQuery } from 'reactQuery';

const useCourses = (queryProps = {}) => {
  const { queryKeyOptions = {}, ...rest } = queryProps;

  const query = useCustomQuery({
    queryKey: ['courses', queryKeyOptions],
    queryFn: getAllCourses,
    ...rest,
  });

  return { ...query };
};

export default useCourses;
