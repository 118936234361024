import { Box } from '@mui/material';

const SponsorCriteriaWrapper = (props) => {
  const { children, sx = {}, ...rest } = props;
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: {
          xs: 'column',
          md: 'row',
        },
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Box>
  );
};

export default SponsorCriteriaWrapper;
