import { LinkedIn } from '@mui/icons-material';
import {
  Divider, Box,
  ListItemIcon,
  Menu, MenuItem, Typography,
} from '@mui/material';
import { CopyLinkIcon, XPlatformIcon } from 'assets/icons';
import { useAuth, useLocale, useSnackbar } from 'util/hooks';
import { TwitterShareButton, LinkedinShareButton } from 'react-share';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useParams } from 'react-router-dom';
import { useTheme } from '@mui/styles';

const ShareProfileMenu = (props) => {
  const {
    anchorEl,
    handleCloseMenu = () => { },
  } = props;

  const { t } = useLocale();
  const snack = useSnackbar();
  const { palette } = useTheme();
  const { username } = useParams();
  const { currentUser } = useAuth();

  const profileLink = window.location.href;
  const isOwner = currentUser.username === username;

  return (
    <Menu
      id="share-profile-menu"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleCloseMenu}
      sx={{
        '& .MuiPaper-root': {
          boxShadow: '0 4px 4px 0px rgba(0, 0, 0, 0.05)',
        },
      }}
    >
      <Box sx={{ width: '180px' }}>
        <MenuItem disableRipple>
          <CopyToClipboard
            text={profileLink}
            onCopy={() => {
              handleCloseMenu();
              snack({
                message: t('shareMenu.copiedSuccessfully'),
                severity: 'success',
              });
            }}
          >
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
            >
              <ListItemIcon>
                <CopyLinkIcon />
              </ListItemIcon>
              <Typography variant="bodySmallRegular">
                {t('shareMenu.copyLink')}
              </Typography>
            </Box>
          </CopyToClipboard>
        </MenuItem>
        <Divider variant="middle" />
        <Box sx={{ pl: 4, my: 2 }}>
          <Typography variant="bodySmallMedium">
            {t('shareMenu.shareOn')}
          </Typography>
        </Box>
        <MenuItem disableRipple>
          <TwitterShareButton
            title={isOwner ? t('profile.shareProfileLink.shareMyProfile') : t('profile.shareProfileLink.shareProfile')}
            url={profileLink}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <XPlatformIcon width="18" height="18" fill={palette.common.berylliumGrey} />
              <Box sx={{ pl: 2 }}>
                <Typography variant="bodySmallRegular">
                  {t('shareMenu.X')}
                </Typography>
              </Box>
            </Box>
          </TwitterShareButton>
        </MenuItem>
        <MenuItem disableRipple>
          <LinkedinShareButton
            title={isOwner ? t('profile.shareProfileLink.shareMyProfile') : t('profile.shareProfileLink.shareProfile')}
            url={profileLink}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <LinkedIn sx={{ color: 'common.berylliumGrey' }} />
              <Box sx={{ pl: 2 }}>
                <Typography variant="bodySmallRegular">
                  {t('shareMenu.linkedIn')}
                </Typography>
              </Box>
            </Box>
          </LinkedinShareButton>
        </MenuItem>
      </Box>
    </Menu>
  );
};

export default ShareProfileMenu;
