import END_POINTS from 'services/endPoints';
import api from 'services/api';

const getPublicCourse = (id) => api(
  END_POINTS.publicCourse(id),
)
  .then((response) => Promise.resolve(response))
  .catch((error) => Promise.reject(error));

export default getPublicCourse;
