import {
  Typography,
  Box,
  Link,
  Button,
} from '@mui/material';
import { RegistrationClosedAr, RegistrationClosedEn } from 'assets/images';
import { PATHS } from 'routes';
import { useLocale } from 'util/hooks';

const RegistrationClosed = () => {
  const { t, isAr } = useLocale();

  const src = isAr ? RegistrationClosedAr : RegistrationClosedEn;

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      px: 2,
    }}
    >
      <Box
        width="416"
        height="297"
      >
        <img
          src={src}
          alt="Registration Closed"
          width="416"
          height="297"
        />
      </Box>

      <Typography
        variant="bodyMediumMedium"
        sx={{ mt: 7 }}
      >
        {t('registration.registrationClosed')}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexFlow: 'row wrap',
          justifyContent: 'center',
          textAlign: 'center',
          mt: 8,
        }}
      >
        <Typography variant="bodyStandardRegular">
          {t('common.haveAccount')}
          &nbsp;
          <Link
            to={`/${PATHS.login}`}
          >
            {t('common.login')}
          </Link>
        </Typography>
      </Box>
      <Button
        component={Link}
        to={PATHS.root}
        variant="contained"
        sx={{
          my: 8,
        }}
      >
        {t('common.homePage')}
      </Button>
    </Box>
  );
};

export default RegistrationClosed;
