import END_POINTS from '../endPoints';
import METHODS from '../methods';
import api from '../api';

const deleteSession = (courseId, id) => api(
  END_POINTS.updateSession(courseId, id),
  {
    method: METHODS.DELETE,
  },
)
  .then((response) => Promise.resolve(response))
  .catch((error) => Promise.reject(error));

export default deleteSession;
