import { useTheme } from '@mui/styles';

const ProfileIcon = (props) => {
  const { palette } = useTheme();

  const {
    fill = 'none',
    rectFill = palette.common.ghostlyGrey,
    innerFill = palette.text.secondary,
    ...rest
  } = props;

  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill={fill} xmlns="http://www.w3.org/2000/svg" {...rest}>
      <rect width="32" height="32" rx="10.6667" fill={rectFill} />
      <g clipPath="url(#clip0_2356_94610)">
        <path d="M16 15.4111C18.4162 15.4111 20.375 13.4524 20.375 11.0361C20.375 8.61989 18.4162 6.66113 16 6.66113C13.5838 6.66113 11.625 8.61989 11.625 11.0361C11.625 13.4524 13.5838 15.4111 16 15.4111Z" stroke={innerFill} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7.875 25.4111C7.875 23.2562 8.73102 21.1896 10.2548 19.6659C11.7785 18.1422 13.8451 17.2861 16 17.2861C18.1549 17.2861 20.2215 18.1422 21.7452 19.6659C23.269 21.1896 24.125 23.2562 24.125 25.4111" stroke={innerFill} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      </g>
    </svg>
  );
};
export default ProfileIcon;
