import { Divider, Box } from '@mui/material';
import { LogoutButton } from 'components/molecules';

const LogoutWithDivider = () => (
  <Box>
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Divider sx={{ width: { xs: 250, sm: 360 } }} />
    </Box>
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Box sx={{
        width: { xs: 250, sm: 360 },
        mt: 9,
      }}
      >
        <LogoutButton />
      </Box>
    </Box>
  </Box>
);

export default LogoutWithDivider;
