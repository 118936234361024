import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';

import {
  Box,
  Button,
  Typography,
  InputAdornment,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import {
  useLocale,
  useFastForm,
  useSnackbar,
  useDate,
} from 'util/hooks';
import {
  FormDatePicker,
  FormNumberField,
  FormTextField,
  FormTimePicker,
} from 'components/form';
import { PATHS } from 'routes';
import { useCourseDetails } from 'reactQuery/queries';
import { createSession, updateSession } from 'services';
import { convertDateToStringWithDash, getDirtyObject, localizedDate } from 'util/helpers';
import { SessionTimePickerIcon, DurationIcon, SessionDatePickerIcon } from 'assets/icons';

const SessionDetailsForm = (props) => {
  const {
    createMode = false,
    sessionDetails = {},
    handleClickCancel,
  } = props;

  const snack = useSnackbar();
  const { isBefore } = useDate();
  const navigate = useNavigate();
  const { t, language } = useLocale();

  const {
    data: courseDetails = {},
    refetch: fetchCourseDetails,
  } = useCourseDetails();

  const hasEnded = isBefore(sessionDetails?.endAt, new Date());
  const combineDateAndTime = (date, time) => `${convertDateToStringWithDash(date)} ${time.toLocaleTimeString()}`;

  const handleEditSuccess = () => {
    fetchCourseDetails();
    handleClickCancel();
  };

  const onSubmit = async (values) => {
    let message = '';

    try {
      if (createMode) {
        const payload = {
          ...values,
          startAt: combineDateAndTime(values.sessionDate, values.startAt),
        };
        await createSession(payload, courseDetails?.id);
        message = t('sessions.sessionAdded');
      } else {
        const dirtyPayload = getDirtyObject(values, defaultValues);
        if (dirtyPayload.sessionDate) {
          dirtyPayload.startAt = combineDateAndTime(dirtyPayload.sessionDate, dirtyPayload.startAt);
        }
        await updateSession(dirtyPayload, courseDetails?.id, sessionDetails?.id);
        message = t('sessions.sessionUpdated');
      }

      snack({
        severity: 'success',
        message,
      });
      navigate(`/${PATHS.courses}/${courseDetails?.id}/${PATHS.courseSessions}`);
      handleEditSuccess();
    } catch (error) {
      error.errors?.forEach((err) => {
        setError(err.property, {
          type: 'api',
          message: err.message,
        });
        if (!err.property || err.property === 'startAt') {
          snack({
            severity: 'error',
            message: err.message || t('common.somethingWrong'),
          });
        }
      });
    }
  };

  const defaultValues = {
    courseStartDate: courseDetails?.startAt || null,
    courseEndDate: courseDetails?.endAt || null,
    sessionDate: sessionDetails?.startAt || null,
    startAt: sessionDetails?.startAt || null,
    duration: sessionDetails?.duration || '',
    title: sessionDetails?.title || '',
    description: sessionDetails?.description || '',
    recordingId: sessionDetails.recordingId
      ? sessionDetails.recordingId
      : '',
  };

  const validationSchema = Yup.object({
    sessionDate: Yup
      .date()
      .typeError(t('common.enterValidDate'))
      .nullable()
      .when('courseStartDate', (courseStartDate, schema) => {
        if (createMode) {
          return schema.min(courseStartDate || new Date(), `${t('sessions.sessionDateValidation')}: ${localizedDate(language, courseDetails?.startAt)} - ${localizedDate(language, courseDetails?.endAt)}`);
        }
        return Yup
          .date()
          .typeError(t('common.enterValidDate'));
      })
      .when('courseEndDate', (courseEndDate, schema) => {
        if (createMode) {
          return schema.max(courseEndDate, `${t('sessions.sessionDateValidation')}: ${localizedDate(language, courseDetails?.startAt)} - ${localizedDate(language, courseDetails?.endAt)}`);
        }
        return Yup
          .date()
          .typeError(t('common.enterValidDate'));
      })
      .required(t('sessions.enterSessionDate')),
    startAt: Yup
      .date()
      .nullable()
      .required(t('sessions.enterStartTime')),
    duration: Yup
      .number()
      .required(t('sessions.enterDuration'))
      .max(1440, t('sessions.maxDuration'))
      .typeError(t('topics.form.validation.enterNumbers')),
    title: Yup
      .string()
      .required(t('sessions.enterSessionTitle')),
    description: Yup
      .string()
      .required(t('sessions.enterDescription')),
    recordingId: Yup
      .string(t('sessions.invalidRecordingId'))
      .nullable(),
  });

  const {
    control,
    handleSubmit,
    setError,
    formState: {
      isSubmitting,
      isDirty,
    },
  } = useFastForm({
    defaultValues,
    validationSchema,
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box>
        <Typography
          sx={{
            mb: 10,
            display: 'flex',
            justifyContent: 'center',
          }}
          variant="h5"
        >
          {createMode ? t('common.confirmation') : t('sessions.update')}
        </Typography>
        <Box sx={{ mb: 7 }}>
          <FormDatePicker
            name="sessionDate"
            control={control}
            inputProps={{
              fullWidth: true,
              label: t('sessions.sessionDate'),
            }}
            components={{
              OpenPickerIcon: SessionDatePickerIcon,
            }}
            InputAdornmentProps={{ position: 'start' }}
          />
        </Box>
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
        >
          <Box sx={{ mr: 2 }}>
            <FormTimePicker
              name="startAt"
              control={control}
              label={t('sessions.startTime')}
              fullWidth
              components={{
                OpenPickerIcon: SessionTimePickerIcon,
              }}
              InputAdornmentProps={{ position: 'start' }}
            />
          </Box>
          <Box sx={{
            mb: 5,
            ml: 2,
          }}
          >
            <FormNumberField
              name="duration"
              label={t('sessions.timeDuration')}
              helperText={t('sessions.inMinutes')}
              control={control}
              commaSeparated={false}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Box sx={{
                      mr: 1,
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    >
                      <DurationIcon />
                    </Box>
                  </InputAdornment>),
              }}
            />
          </Box>
        </Box>
        <Box sx={{ mb: 7 }}>
          <FormTextField
            name="title"
            control={control}
            label={t('sessions.sessionTitle')}
            fullWidth
          />
        </Box>
        <Box>
          <FormTextField
            name="description"
            control={control}
            label={t('common.description')}
            multiline
            minRows={3}
            maxRows={3}
            fullWidth
          />
        </Box>
        {hasEnded && (
          <Box sx={{ mt: 7 }}>
            <FormTextField
              name="recordingId"
              control={control}
              label={t('sessions.recordingId')}
              fullWidth
            />
          </Box>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          mt: 10,
          mb: 2,
        }}
      >
        <Button
          onClick={handleClickCancel}
          variant="outlined"
          size="regular"
          color="secondary"
        >
          {t('common.cancel')}
        </Button>
        <LoadingButton
          size="regular"
          type="submit"
          loading={isSubmitting}
          disabled={isSubmitting || !isDirty}
          variant="contained"
          sx={{
            ml: 5,
          }}
        >
          {createMode ? t('sessions.addSession') : t('sessions.update')}
        </LoadingButton>
      </Box>
    </form>
  );
};

export default SessionDetailsForm;
