import React from 'react';
import {
  Observers,
  GoogleRecaptcha,
} from 'components/helpers';

import { Toast } from 'components/render';
import { useLocale } from 'util/hooks';

import useDateDefaults from './useDateDefaults';

const AppHelpers = (props) => {
  const { children } = props;
  const { dir } = useLocale();

  React.useEffect(() => {
    // This will make sure things outside the body tag
    // (like Menus and modals) get the current direction
    document.dir = dir;
  }, [dir]);

  useDateDefaults(); // set date defaults

  return (
    <>
      <Observers />
      <GoogleRecaptcha />
      <div dir={dir}>
        <Toast />
        {children}
      </div>
    </>
  );
};

export default AppHelpers;
