import { useTheme } from '@mui/styles';

const OpenEyeIcon = (props) => {
  const { palette } = useTheme();

  const {
    fill = palette.common.couchGrey,
    rectFill = palette.common.white,
  } = props;

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_2855_676)">
        <path d="M19.7692 9.04809C17.8114 5.31047 14.16 2.8125 10 2.8125C5.8382 2.8125 2.18765 5.31227 0.23078 9.04805C0.079686 9.33649 0 9.66518 0 9.99998C0 10.3348 0.079686 10.6635 0.23078 10.9519C2.18862 14.6896 5.84 17.1875 10 17.1875C14.1618 17.1875 17.8123 14.6877 19.7692 10.9519C19.9203 10.6635 20 10.3348 20 10C20 9.66521 19.9203 9.33653 19.7692 9.04809ZM14.4444 8.90625C14.4444 11.5814 12.4546 13.75 10 13.75C7.54542 13.75 5.55556 11.5814 5.55556 8.90625C5.55556 6.23113 7.54542 4.0625 10 4.0625C12.4546 4.0625 14.4444 6.23113 14.4444 8.90625ZM18.8119 10.3173C17.0082 13.7608 13.6759 15.9375 10 15.9375C6.30934 15.9375 2.984 13.7459 1.18807 10.3173C1.13772 10.2212 1.11117 10.1116 1.11117 10C1.11117 9.88842 1.13772 9.77885 1.18807 9.6827C2.19751 7.75562 3.73463 6.15008 5.6207 5.15586C4.89046 6.17934 4.44445 7.48313 4.44445 8.90625C4.44445 12.2585 6.9174 15 10 15C13.0823 15 15.5555 12.2587 15.5555 8.90625C15.5555 7.48395 15.1099 6.17992 14.3793 5.15586C16.246 6.1398 17.7916 7.7348 18.812 9.6827C18.8623 9.77885 18.8888 9.88841 18.8888 10C18.8888 10.1116 18.8623 10.2212 18.8119 10.3173ZM7.77778 8.75C7.77778 8.32434 7.87246 7.92359 8.03924 7.57277V7.57355C8.03924 8.14207 8.44892 8.60297 8.95427 8.60297C9.45961 8.60297 9.8693 8.14207 9.8693 7.57355C9.8693 7.00504 9.45961 6.54414 8.95427 6.54414H8.95357C9.26541 6.35652 9.62163 6.25 10 6.25C11.2273 6.25 12.2222 7.3693 12.2222 8.75C12.2222 10.1307 11.2273 11.25 10 11.25C8.77271 11.25 7.77778 10.1307 7.77778 8.75Z" />
      </g>
      <defs>
        <clipPath id="clip0_2855_676">
          <rect width="20" height="20" fill={rectFill} />
        </clipPath>
      </defs>
    </svg>
  );
};
export default OpenEyeIcon;
