import { useState } from 'react';
import * as Yup from 'yup';
import { useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Typography,
  IconButton,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import {
  useLocale,
  useSnackbar,
  useFastForm,
  useAuth,
} from 'util/hooks';

import { FormOTPInput } from 'components/form';
import { verifiedEmailAtom } from 'recoil/atoms';
import {
  sendEmailVerificationCode,
  verifyEmailVerificationCode,
} from 'services';
import { PenEditIcon } from 'assets/icons';

import { PATHS } from 'routes';
import { AUTHENTICATION_SNACKBAR_POSITION } from 'assets/constants/snackbar';

const EmailVerificationForm = () => {
  const { t } = useLocale();
  const snack = useSnackbar();
  const navigate = useNavigate();
  const { refreshUser } = useAuth();

  const verifiedEmail = useRecoilValue(verifiedEmailAtom);
  const [tokenError, setTokenError] = useState('');

  const onConfirm = async (values) => {
    try {
      await verifyEmailVerificationCode(values);
      await refreshUser();

      snack({
        message: t('verifyEmail.emailVerified'),
        severity: 'success',
        snackbarProps: AUTHENTICATION_SNACKBAR_POSITION,
      });

      navigate(`/${PATHS.posts}`);
    } catch (error) {
      setTokenError(error?.message || error?.errors?.[0]?.message || t('common.somethingWrong'));
    }
  };

  const resendCode = async () => {
    const payload = {
      email: verifiedEmail.fullEmail,
    };

    try {
      await sendEmailVerificationCode(payload);
      snack({
        message: t('verifyEmail.codeHasBeenSent'),
        severity: 'success',
        snackbarProps: AUTHENTICATION_SNACKBAR_POSITION,
      });
    } catch (error) {
      snack({
        message: error?.errors?.[0]?.message || t('common.somethingWrong'),
        severity: 'error',
      });
    }
  };

  const defaultValues = {
    token: '',
  };

  const validationSchema = Yup.object({
    token: Yup.string()
      .required(t('verifyEmail.codeValidation')),
  });

  const {
    control,
    handleSubmit,
  } = useFastForm({
    defaultValues,
    validationSchema,
  });

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      py: 8,
    }}
    >
      <form onSubmit={handleSubmit(onConfirm)}>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Typography variant="h5">
            {t('verifyEmail.verifyYourEmail')}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexFlow: 'column wrap',
            alignSelf: 'start',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            mt: 12,
            mb: 5,
          }}
        >
          <Box>
            <Typography variant="bodyStandardRegular">
              {t('verifyEmail.enterCode')}
              &nbsp;
            </Typography>
          </Box>
          <Box sx={{ display: 'flex' }}>
            <Typography variant="bodyStandardMedium">
              {verifiedEmail.fullEmail}
            </Typography>
            <Box sx={{ mr: 1 }}>
              <IconButton
                disableRipple
                onClick={() => navigate(`/${PATHS.emailAuthentication}`)}
                aria-label="edit"
              >
                <PenEditIcon />
              </IconButton>
            </Box>
          </Box>
        </Box>
        <Box sx={{
          width: 1,
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
        >
          <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            flexFlow: 'row wrap',
          }}
          >
            <FormOTPInput
              name="token"
              control={control}
              length={4}
              TextFieldsProps={{
                sx: {
                  width: 60,
                  height: 56,
                },
              }}
            />
          </Box>
          <Box sx={{
            mt: 4,
            display: 'flex',
            justifyContent: 'center',
          }}
          >
            {tokenError
              && (
                <Typography
                  variant="bodySmallRegular"
                  sx={{ color: 'common.passionFruitRed' }}
                >
                  {tokenError}
                </Typography>
              )}
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexFlow: 'row wrap',
            justifyContent: 'center',
            alignSelf: 'start',
            alignItems: 'center',
            my: 5,
          }}
        >
          <Box>
            <Typography variant="bodyStandardRegular">
              {t('verifyEmail.didNotReceiveCode')}
              &nbsp;
            </Typography>
          </Box>
          <Button
            disableRipple
            onClick={resendCode}
            sx={(theme) => ({
              ...theme.typography.bodyStandardMedium,
              p: 0,
              color: 'common.grey5',
              '&.MuiButton-text': {
                '&:hover': {
                  backgroundColor: 'transparent',
                  color: 'common.grey5',
                },
              },
            })}
          >
            {t('verifyEmail.resendCode')}
          </Button>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: 10,
            mb: 2,
          }}
        >
          <LoadingButton
            type="submit"
            size="regular"
            variant="contained"
            sx={{ borderRadius: 1.5 }}
          >
            {t('verifyEmail.verify')}
          </LoadingButton>
        </Box>
      </form>
    </Box>
  );
};

export default EmailVerificationForm;
