import { useTheme } from '@mui/styles';

const OutlinedLanguageIcon = (props) => {
  const { palette } = useTheme();

  const {
    fill = 'none',
    innerFill = palette.common.couchGrey,
  } = props;

  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M7.125 7.46875C6.78 7.46875 6.5 7.18875 6.5 6.84375V6.21875H5.25V6.84375C5.25 7.18875 4.97 7.46875 4.625 7.46875C4.28 7.46875 4 7.18875 4 6.84375V4.34375C4 3.30958 4.84083 2.46875 5.875 2.46875C6.90917 2.46875 7.75 3.30958 7.75 4.34375V6.84375C7.75 7.18875 7.47 7.46875 7.125 7.46875ZM6.5 4.96875V4.34375C6.5 3.99875 6.22 3.71875 5.875 3.71875C5.53 3.71875 5.25 3.99875 5.25 4.34375V4.96875H6.5Z" fill={innerFill} />
      <path d="M17.375 20C17.2083 20 17.0508 19.935 16.9333 19.8167L13.3667 16.25H9.875C8.84083 16.25 8 15.4092 8 14.375V8.125C8 7.09083 8.84083 6.25 9.875 6.25H18.625C19.6592 6.25 20.5 7.09083 20.5 8.125V14.375C20.5 15.4092 19.6592 16.25 18.625 16.25H18V19.375C18 19.6283 17.8483 19.855 17.6142 19.9525C17.5383 19.9842 17.4575 20 17.375 20ZM9.875 7.5C9.53 7.5 9.25 7.78 9.25 8.125V14.375C9.25 14.72 9.53 15 9.875 15H13.625C13.79 15 13.9508 15.0667 14.0667 15.1833L16.75 17.8667V15.625C16.75 15.28 17.03 15 17.375 15H18.625C18.97 15 19.25 14.72 19.25 14.375V8.125C19.25 7.78 18.97 7.5 18.625 7.5H9.875Z" fill={innerFill} />
      <path d="M8.5 7H19.5V15L17 15.5V18.5L14 15.5L8.5 15V7Z" fill={innerFill} />
      <path d="M14.172 13.4608C13.796 13.4608 13.462 13.3968 13.17 13.2688C12.878 13.1408 12.65 12.9588 12.486 12.7228C12.322 12.4908 12.24 12.2168 12.24 11.9008C12.24 11.5808 12.322 11.3047 12.486 11.0728C12.65 10.8407 12.878 10.6607 13.17 10.5327C13.462 10.4047 13.796 10.3407 14.172 10.3407H15.246V11.4688H14.172C13.96 11.4688 13.808 11.5028 13.716 11.5708C13.628 11.6388 13.584 11.7488 13.584 11.9008C13.584 12.0528 13.628 12.1628 13.716 12.2308C13.808 12.2988 13.96 12.3328 14.172 12.3328C14.264 12.3328 14.378 12.3268 14.514 12.3148C14.65 12.3068 14.81 12.2928 14.994 12.2728L15.186 13.3408C15.014 13.3768 14.844 13.4048 14.676 13.4248C14.508 13.4488 14.34 13.4608 14.172 13.4608ZM13.176 11.2467C12.992 11.0427 12.858 10.8507 12.774 10.6707C12.694 10.4907 12.654 10.2967 12.654 10.0887C12.654 9.83675 12.704 9.61075 12.804 9.41075C12.908 9.20675 13.046 9.03275 13.218 8.88875C13.39 8.74075 13.584 8.62875 13.8 8.55275C14.02 8.47675 14.246 8.43875 14.478 8.43875C14.606 8.43875 14.732 8.45075 14.856 8.47475C14.984 8.49475 15.104 8.52475 15.216 8.56475L14.862 9.62675C14.802 9.60675 14.746 9.59275 14.694 9.58475C14.646 9.57275 14.594 9.56675 14.538 9.56675C14.422 9.56675 14.322 9.58875 14.238 9.63275C14.158 9.67275 14.098 9.73075 14.058 9.80675C14.018 9.88275 13.998 9.96875 13.998 10.0647C13.998 10.1447 14.012 10.2207 14.04 10.2927C14.072 10.3607 14.12 10.4307 14.184 10.5027L13.176 11.2467Z" fill="white" />
      <path d="M3.625 13.75C3.5425 13.75 3.46167 13.7342 3.38583 13.7025C3.15167 13.605 3 13.3783 3 13.125V10H2.375C1.34083 10 0.5 9.15917 0.5 8.125V1.875C0.5 0.840833 1.34083 0 2.375 0H11.125C12.1592 0 13 0.840833 13 1.875V4.375C13 4.72 12.72 5 12.375 5C12.03 5 11.75 4.72 11.75 4.375V1.875C11.75 1.53 11.47 1.25 11.125 1.25H2.375C2.03 1.25 1.75 1.53 1.75 1.875V8.125C1.75 8.47 2.03 8.75 2.375 8.75H3.625C3.97 8.75 4.25 9.03 4.25 9.375V11.6158L5.68333 10.1825C5.80083 10.065 5.95833 10 6.125 10C6.29167 10 6.44917 10.065 6.56667 10.1833C6.81 10.4267 6.81 10.8233 6.56667 11.0675L4.06667 13.5675C3.94917 13.685 3.79167 13.75 3.625 13.75Z" fill={innerFill} />
    </svg>
  );
};

export default OutlinedLanguageIcon;
