import { Link } from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid';

import { PATHS } from 'routes';
import { Status } from 'components/molecules';
import { useLocale, useModal } from 'util/hooks';
import MODAL_KEYS from 'assets/constants/modalKeys';
import TABLE_ACTIONS from 'assets/constants/tableActions';
import PLATFORM_SETTINGS from 'assets/constants/platformSettings';

const useTableColumns = (props = {}) => {
  const { refetchTags = () => { } } = props;

  const { t, dir, isAr } = useLocale();
  const { addModal } = useModal();

  const handleOpenModal = (
    settingType,
    actionType,
    detailsProps,
  ) => {
    addModal({
      key: MODAL_KEYS.platformSettings,
      props: {
        settingType,
        actionType,
        detailsProps,
        refetch: refetchTags,
      },
    });
  };

  const columns = [
    {
      field: isAr ? 'nameAr' : 'nameEn',
      headerName: t('tags.table.tag'),
      flex: 1,
      minWidth: 500,
    },
    {
      field: 'visibility',
      headerName: t('tags.table.visibilityStatus'),
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        const isVisible = params?.row?.visibility;
        return (
          <Status
            status={(isVisible
              ? 'visible'
              : 'hidden') || ''}
            label={isVisible
              ? t('statuses.visible')
              : t('statuses.hidden')}
          />
        );
      },
    },
    {
      field: 'actions',
      headerName: t('common.actions'),
      type: 'actions',
      flex: 1,
      minWidth: 100,
      getActions: ({ row }) => {
        const isVisible = row.visibility === true;
        const isUnused = row.isUsed === false;

        const actions = [
          <GridActionsCellItem
            component={Link}
            dir={dir}
            label={t('actions.edit')}
            to={`/${PATHS.platformSettings}/${PATHS.tagsSettings}/edit/${row?.id}`}
            sx={{ minWidth: 167 }}
            showInMenu
          />,
        ];

        if (isVisible) {
          actions.push(
            <GridActionsCellItem
              dir={dir}
              label={t('actions.hide')}
              onClick={() => handleOpenModal(
                PLATFORM_SETTINGS.tags,
                TABLE_ACTIONS.platformSettings.hide,
                { ...row, name: isAr ? row.nameAr : row.nameEn },
              )}
              showInMenu
            />,
          );
        } else {
          actions.push(
            <GridActionsCellItem
              dir={dir}
              label={t('actions.makeVisible')}
              onClick={() => handleOpenModal(
                PLATFORM_SETTINGS.tags,
                TABLE_ACTIONS.platformSettings.makeVisible,
                { ...row, name: isAr ? row.nameAr : row.nameEn },
              )}
              showInMenu
            />,
          );
        }

        if (isUnused) {
          actions.push(
            <GridActionsCellItem
              dir={dir}
              label={t('actions.delete')}
              onClick={() => handleOpenModal(
                PLATFORM_SETTINGS.tags,
                TABLE_ACTIONS.platformSettings.delete,
                { ...row, name: isAr ? row.nameAr : row.nameEn },
              )}
              showInMenu
            />,
          );
        }

        return actions;
      },
    },
  ];

  return columns;
};

export default useTableColumns;
