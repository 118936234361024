import { useLocale } from 'util/hooks';
import {
  Box,
  Stack,
  Avatar,
  Typography,
} from '@mui/material';

import {
  goldenPartnersLogos,
  trainingPartnersLogos,
} from 'assets/constants/partnersLogos';
import LandingSectionWrapper from '../../helpers/LandingSectionWrapper';
import SponsorCriteriaWrapper from './SponsorCriteriaWrapper';

const SuccessPartners = () => {
  const { t, dir } = useLocale();

  const header = (
    <LandingSectionWrapper sx={{ mx: 'auto' }}>
      <Box>
        <Typography variant="h5">
          {t('landing.successPartnersSection.successPartners')}
        </Typography>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Typography variant="bodyStandardRegular">
          {t('landing.successPartnersSection.successPartnersDesc')}
        </Typography>
      </Box>
    </LandingSectionWrapper>
  );

  const renderSponsorList = () => (
    <Stack
      dir={dir}
      columnGap={2.5}
      rowGap={4}
      sx={{
        flexDirection: {
          xs: 'column',
          sm: 'row',
        },
        alignItems: {
          xs: 'center',
          sm: 'baseline',
        },
      }}
    >
      <Stack>
        {goldenPartnersLogos.map(({ key, img, alt }) => (
          <Avatar
            key={key}
            sx={{
              width: 120,
              height: 120,
              backgroundColor: 'common.white',
              border: ({ palette }) => `6px solid ${palette.common.vividOrange}`,
            }}
          >
            <img
              src={img}
              alt={alt}
            />
          </Avatar>
        ))}
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        flexWrap="wrap"
        columnGap={2.5}
        rowGap={4}
        sx={{
          justifyContent: {
            xs: 'center',
            sm: 'start',
          },
        }}
      >
        {trainingPartnersLogos.map(({ key, img, alt }) => (
          <Avatar
            key={key}
            sx={{
              width: 80,
              height: 80,
              backgroundColor: 'common.white',
              border: ({ palette }) => `2px solid ${palette.common.silverGrey}`,
            }}
          >
            <img
              src={img}
              alt={alt}
            />
          </Avatar>
        ))}
      </Stack>
    </Stack>
  );

  return (
    <>
      {header}
      <Box sx={{ mt: 10, display: 'flex' }}>
        <LandingSectionWrapper sx={{ mx: 'auto', width: 1, px: 3 }}>
          <SponsorCriteriaWrapper sx={{ mb: { xs: 4, sm: 4.5 } }}>
            {renderSponsorList()}
          </SponsorCriteriaWrapper>
        </LandingSectionWrapper>
      </Box>
    </>
  );
};

export default SuccessPartners;
