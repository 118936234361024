import {
  Box,
  Button,
  Link,
  Typography,
} from '@mui/material';
import { hero } from 'assets/images';
import { Image } from 'mui-image';
import { useLocale } from 'util/hooks';
import { PATHS } from 'routes';

const Hero = () => {
  const { t } = useLocale();

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: { xs: 'column', md: 'row' },
      gap: {
        xs: 5,
        sm: 10,
        md: 26,
        lg: 46,
      },
      alignItems: { xs: 'center', md: 'start' },
      backgroundColor: 'common.sharkGray',
    }}
    >
      <Box sx={{
        maxWidth: { md: 420 },
        mb: { xs: 6, md: 0 },
        // mr: { xs: 0, md: 'auto' },
        overflowWrap: 'break-word',
      }}
      >
        <Typography variant="h1" color="common.black">
          {t('landing.heroSection.header')}
        </Typography>

        <Box sx={{ mt: 5 }}>
          <Typography variant="bodyMediumRegular">
            {t('landing.heroSection.description')}
          </Typography>
        </Box>

        <Box sx={{ mt: 10 }}>
          <Button
            variant="contained"
            component={Link}
            to={`/${PATHS.posts}`}
          >
            {t('landing.heroSection.action')}
          </Button>
        </Box>
      </Box>

      <Box sx={{ width: 1, maxWidth: '280px' }}>
        <Image
          src={hero}
          alt="hero"
          duration={0}
        />
      </Box>
    </Box>
  );
};

export default Hero;
