import { useTheme } from '@mui/styles';

const MyPostIcon = (props) => {
  const { palette } = useTheme();

  const {
    fill = 'none',
    rectFill = palette.common.ghostlyGrey,
    stroke = palette.text.secondary,
    ...rest
  } = props;

  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill={fill} xmlns="http://www.w3.org/2000/svg" {...rest}>
      <rect width="32" height="32" rx="10.6667" fill={rectFill} />
      <g clipPath="url(#clip0_2000_85016)">
        <path d="M20.375 25.4111H9.125C8.79348 25.4111 8.47554 25.2794 8.24112 25.045C8.0067 24.8106 7.875 24.4927 7.875 24.1611V10.4111" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M23.2917 6.66113H11.2083C10.7481 6.66113 10.375 7.03423 10.375 7.49447V22.0778C10.375 22.538 10.7481 22.9111 11.2083 22.9111H23.2917C23.7519 22.9111 24.125 22.538 24.125 22.0778V7.49447C24.125 7.03423 23.7519 6.66113 23.2917 6.66113Z" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      </g>
    </svg>
  );
};

export default MyPostIcon;
