import { atom } from 'recoil';

const snackbarAtom = atom({
  key: 'snackbar',
  default: {
    open: false,
  },
});

export default snackbarAtom;
