import { getProfileCTFs } from 'services';
import { useCustomQuery } from 'reactQuery';
import { useParams } from 'react-router-dom';

const useProfileCTFs = () => {
  const { username } = useParams();

  const query = useCustomQuery({
    queryKey: [`profile-ctfs-${username}`],
    queryFn: () => getProfileCTFs(username),
  });

  return { ...query };
};

export default useProfileCTFs;
