import {
  Typography,
  Box,
  Alert,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import { forgotPasswordService } from 'services';
import { useCallback, useMemo, useState } from 'react';
import { FormTextField } from 'components/form';
import { useFastForm, useLocale, useRecaptcha } from 'util/hooks';

const ForgotPasswordForm = (props) => {
  const { onSubmitSuccess = () => { } } = props;

  const { t } = useLocale();
  const [forgotError, setForgotError] = useState('');
  const { executeRecaptcha } = useRecaptcha({ action: 'FORGOT_PASSWORD' });

  const forgotPassword = useCallback(async (userEmail) => {
    try {
      const recaptchaToken = await executeRecaptcha();
      await forgotPasswordService(userEmail, { recaptchaToken });
      const { email } = userEmail;
      onSubmitSuccess(email);
    } catch (error) {
      const { errors } = error;
      setForgotError(errors?.[0]?.message || t('common.somethingWrong'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const defaultValues = {
    email: '',
  };

  const validationSchema = useMemo(() => (Yup.object({
    email: Yup.string()
      .email(t('email.validation'))
      .required(t('email.enterEmail')),
  })), [t]);

  const {
    control,
    handleSubmit,
    formState: {
      isSubmitting,
      errors,
    },
  } = useFastForm({
    defaultValues,
    validationSchema,
  });

  return (
    <form onSubmit={handleSubmit(forgotPassword)}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        py: 6,
        px: {
          sm: 20,
          xs: 6,
        },
      }}
      >
        <Typography
          sx={{
            mb: 21,
          }}
          variant="h4"
        >
          {t('password.resetPassword')}
        </Typography>
        {forgotError
          && (
            <Alert
              severity="error"
              sx={{ width: 1, mb: 8 }}
            >
              {forgotError}
            </Alert>
          )}
        <Typography
          sx={{
            mb: 8,
          }}
          variant="bodyStandardRegular"
        >
          {t('password.forgotPasswordDescription')}
        </Typography>
        <Box sx={{ width: 1, mb: 7 }}>
          <FormTextField
            name="email"
            control={control}
            label={t('common.email')}
            disabled={isSubmitting}
            fullWidth
            autoFocus
          />
        </Box>
        <LoadingButton
          sx={{
            display: 'flex',
            alignSelf: 'start',
          }}
          type="submit"
          variant="contained"
          size="regular"
          loading={isSubmitting}
          disabled={!errors}
        >
          {t('common.sendReset')}
        </LoadingButton>
      </Box>
    </form>
  );
};

export default ForgotPasswordForm;
