import { useState } from 'react';
import {
  Box,
  Alert,
  Container,
  Typography,
} from '@mui/material';
import { Stepper } from 'components/molecules';
import { useAuth, useLocale } from 'util/hooks';

import { LiveCourseIcon, OnDemandCourseIcon } from 'assets/icons';
import CourseFormStepOne from './CourseFormStepOne';
import CourseFormStepThree from './CourseFormStepThree';
import CourseFormStepTwo from './CourseFormStepTwo';

const CourseForm = (props) => {
  const {
    courseDetails = {},
    createMode = false,
    createOnDemand = false,
  } = props;

  const { t } = useLocale();
  const { isAdmin } = useAuth();

  const isOnDemandCourse = createOnDemand || courseDetails?.isOnDemand;

  const [activeStep, setActiveStep] = useState(0);
  const [courseFormInfo, setCourseFormInfo] = useState(
    {
      title: '',
      topic: '',
      level: '',
      language: '',
      description: '',
      tags: [],
      provider: '',
      managedBy: '',
      startAt: null,
      endAt: null,
      hasCertificate: false,
      isRecorded: false,
      canRegister: false,
      cover: '',
      attachments: [],
    },
  );

  const liveCourseSteps = [
    t('course.defineCourse'),
    t('course.dateRange'),
    t('course.completeInformation')];

  const onDemandCourseSteps = [t('course.content'), t('course.setup')];

  const steps = isOnDemandCourse ? onDemandCourseSteps : liveCourseSteps;

  const handleNextStep = () => {
    setActiveStep((step) => step + 1);
  };

  const getPages = (index) => {
    let currentPage = (
      <CourseFormStepOne
        courseFormInfo={courseFormInfo}
        setCourseFormInfo={setCourseFormInfo}
        handleNextStep={handleNextStep}
        courseDetails={courseDetails}
        isOnDemandCourse={isOnDemandCourse}
      />
    );
    if (index === 1) {
      currentPage = (
        isOnDemandCourse
          ? (
            <CourseFormStepThree
              courseFormInfo={courseFormInfo}
              courseDetails={courseDetails}
              createMode={createMode}
              setCourseFormInfo={setCourseFormInfo}
              isOnDemandCourse={isOnDemandCourse}
            />
          )
          : (
            <CourseFormStepTwo
              courseFormInfo={courseFormInfo}
              setCourseFormInfo={setCourseFormInfo}
              handleNextStep={handleNextStep}
              courseDetails={courseDetails}
            />
          ));
    }
    if (index === 2) {
      currentPage = (
        <CourseFormStepThree
          courseFormInfo={courseFormInfo}
          courseDetails={courseDetails}
          createMode={createMode}
          setCourseFormInfo={setCourseFormInfo}
        />
      );
    }
    return currentPage;
  };

  const formHeader = () => {
    if (createMode) {
      return (
        <Box display="flex" justifyContent="center">
          <Box mr={2}>
            {isOnDemandCourse ? <OnDemandCourseIcon width="36" height="36" />
              : <LiveCourseIcon width="36" height="36" />}
          </Box>
          {isOnDemandCourse ? t('course.createOnDemand') : t('course.createLive')}
        </Box>
      );
    }

    return (
      <Box display="flex" justifyContent="center">
        {t('course.updateCourse')}
      </Box>
    );
  };

  return (
    <Container maxWidth="md">
      {courseDetails?.isCourseCompleted
        && (
          <Alert
            severity="info"
            sx={{ width: 1, mb: 8 }}
          >
            {isAdmin ? t('courses.courseCompletedAlertAdmin')
              : t('courses.courseCompletedAlertContentCreator')}
          </Alert>
        )}
      <Typography
        variant="h4"
        sx={{ mb: 9 }}
      >
        {formHeader()}
      </Typography>
      <Stepper
        steps={steps}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        renderPages={getPages}
        stepperProps={{
          alternativeLabel: true,
          sx: { mb: 10 },
        }}
      />
    </Container>
  );
};

export default CourseForm;
