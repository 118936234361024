import {
  Typography,
  Box,
  Button,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { PATHS } from 'routes';
import { useLocale } from 'util/hooks';

const ResetPasswordError = (props) => {
  const {
    errorMessage = '',
  } = props;

  const { t } = useLocale();
  const navigate = useNavigate();

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      py: 6,
      px: {
        sm: 20,
        xs: 6,
      },
    }}
    >
      <Typography
        sx={{
          marginBottom: 21,
        }}
        variant="h4"
      >
        {t('password.resetPassword')}
      </Typography>
      <Typography
        sx={{
          marginBottom: 6,
          alignSelf: 'flex-start',
        }}
        variant="bodyMediumBold"
      >
        {t('common.somethingWrong')}
      </Typography>
      <Typography
        sx={{
          marginBottom: 6,
          alignSelf: 'flex-start',
        }}
        variant="bodyStandardRegular"
      >
        {errorMessage}
      </Typography>
      <Button
        sx={{
          alignSelf: 'flex-start',
        }}
        variant="contained"
        size="regular"
        onClick={() => { navigate(`/${PATHS.login}`); }}
      >
        {t('common.backToLogin')}
      </Button>
    </Box>
  );
};

export default ResetPasswordError;
