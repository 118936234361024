import { Box, useMediaQuery } from '@mui/material';

const LayoutContainer = (props) => {
  const {
    children,
    sx = {}, ...rest
  } = props;

  const isMobileView = useMediaQuery(({ breakpoints }) => breakpoints.down('md'));

  return (
    <Box
      sx={{
        width: 1,
        maxWidth: 1000,
        mx: isMobileView ? 0 : 'auto',
        px: { xs: 2, lg: 0 },
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Box>
  );
};
export default LayoutContainer;
