import END_POINTS from '../endPoints';
import METHODS from '../methods';
import api from '../api';

const setAsLeader = (clubId, userId) => api(
  END_POINTS.setAsLeader(clubId, userId),
  {
    method: METHODS.POST,
  },
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default setAsLeader;
