import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';

import { LoadingButton } from '@mui/lab';
import {
  Typography, Box,
  IconButton,
  Button,
  InputAdornment,
} from '@mui/material';

import { FormAutocomplete, FormAvatar, FormTextField } from 'components/form';
import { getDirtyObject, REGEX } from 'util/helpers';
import {
  useAuth, useFastForm, useLocale, useSnackbar,
} from 'util/hooks';
import { updateClub, createClub, getCities } from 'services';
import { PATHS } from 'routes';
import { TrashIcon } from 'assets/icons';
import { useFieldArray } from 'react-hook-form';

const domainInputAdornment = (<InputAdornment position="start"> @ </InputAdornment>);

const ClubDetailsForm = (props) => {
  const {
    clubDetails = {},
    createMode = false,
  } = props;

  const { clubId } = useParams();
  const { t } = useLocale();
  const snack = useSnackbar();
  const navigate = useNavigate();
  const { isAdmin, isClubManager } = useAuth();

  const onSubmit = async (values) => {
    const dirtyPayload = getDirtyObject(values, defaultValues);

    try {
      let message = '';

      if (createMode) {
        await createClub(values);
        message = t('clubs.forms.clubAdded');
      } else {
        await updateClub(dirtyPayload, clubId);
        message = t('clubs.forms.clubEdited');
      }

      snack({
        severity: 'success',
        message,
      });
      if (isAdmin) {
        navigate(`/${PATHS.manageClubs}`);
      } else if (isClubManager) {
        navigate(-1);
      }
    } catch (error) {
      error.errors?.forEach((err) => {
        setError(err.property, {
          type: 'api',
          message: err.message,
        });
        if (!err.property) {
          snack({
            severity: 'error',
            message: err.message || t('common.somethingWrong'),
          });
        }
      });
    }
  };

  const defaultValues = {
    logo: clubDetails?.logo || null,
    nameEn: clubDetails?.nameEn || '',
    nameAr: clubDetails?.nameAr || '',
    abbreviation: clubDetails?.abbreviation || '',
    city: clubDetails?.city || '',
    discordRoleId: clubDetails?.discordRoleId || '',
    twitter: clubDetails?.twitter || '',
    linkedin: clubDetails?.linkedin || '',
    studentDomains: clubDetails?.studentDomains || [''],
    managerDomains: clubDetails?.managerDomains || [''],
  };

  const validationSchema = Yup.object({
    logo: Yup.string().nullable(true),
    nameEn: Yup.string()
      .trim()
      .required(t('clubs.forms.validation.enterNameEn'))
      .matches(REGEX.alphaNumericEnSpace, t('clubs.forms.validation.enterNameEn')),
    nameAr: Yup.string()
      .trim()
      .required(t('clubs.forms.validation.enterNameAr'))
      .matches(REGEX.alphaNumericArSpace, t('clubs.forms.validation.enterNameAr')),
    abbreviation: Yup.string().required(t('clubs.forms.validation.enterAbbreviation')),
    city: Yup.object().nullable(true).required(t('clubs.forms.validation.selectCity')),
    discordRoleId: Yup.string().required(t('clubs.forms.validation.enterDiscord')),
    twitter: Yup.string().nullable(true),
    linkedin: Yup.string().nullable(true),
    studentDomains: Yup.array().of(Yup.string()
      .required(t('clubs.forms.validation.enterDomain'))
      .matches(REGEX.domain, t('clubs.forms.validation.enterValidDomain'))),
    managerDomains: Yup.array().of(Yup.string()
      .required(t('clubs.forms.validation.enterDomain'))
      .matches(REGEX.domain, t('clubs.forms.validation.enterValidDomain'))),
  });

  const {
    control,
    handleSubmit,
    setError,
    formState: {
      isSubmitting,
      isDirty,
      isValid,
    },
  } = useFastForm({
    defaultValues,
    validationSchema,
  });

  const {
    fields: studentDomainFields,
    append: appendStudentDomain,
    remove: removeStudentDomain,
  } = useFieldArray({
    control,
    name: 'studentDomains',
  });

  const {
    fields: managerDomainFields,
    append: appendManagerDomain,
    remove: removeManagerDomain,
  } = useFieldArray({
    control,
    name: 'managerDomains',
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box>
        <Box sx={{
          display: 'grid',
          rowGap: 5,
        }}
        >
          <Typography
            variant="h5"
            mt={5}
          >
            {t('clubs.forms.generalInfo')}
          </Typography>
          <Box>
            <FormAvatar
              name="logo"
              avatar={defaultValues?.logo}
              disabled={isSubmitting}
              control={control}
              uploadButtonLabel={t('common.upload')}
            />
          </Box>
          <Box>
            <FormTextField
              name="nameEn"
              control={control}
              label={t('clubs.forms.clubNameEn')}
              disabled={isSubmitting}
              fullWidth
            />
          </Box>
          <Box>
            <FormTextField
              name="nameAr"
              control={control}
              label={t('clubs.forms.clubNameAr')}
              disabled={isSubmitting}
              fullWidth
            />
          </Box>
          <Box>
            <FormTextField
              name="abbreviation"
              control={control}
              label={t('common.abbreviation')}
              disabled={isSubmitting}
              fullWidth
            />
          </Box>
          <Box>
            <FormAutocomplete
              name="city"
              control={control}
              label={t('common.city')}
              fullWidth
              selectFunc={getCities}
              required
              disabled={isSubmitting}
            />
          </Box>
          <Box>
            <FormTextField
              name="discordRoleId"
              control={control}
              label={t('clubs.forms.discord')}
              disabled={isSubmitting}
              fullWidth
            />
          </Box>
          <Box>
            <FormTextField
              name="twitter"
              control={control}
              label={`${t('clubs.forms.X')} (${t('common.optional')})`}
              disabled={isSubmitting}
              fullWidth
            />
          </Box>
          <Box>
            <FormTextField
              name="linkedin"
              control={control}
              label={`${t('clubs.forms.linkedIn')} (${t('common.optional')})`}
              disabled={isSubmitting}
              fullWidth
            />
          </Box>
          <Typography
            variant="h5"
            mt={8}
          >
            {t('clubs.forms.domain')}
          </Typography>
          <Box sx={{ mb: 8 }}>
            <Box sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 2,
            }}
            >
              <Typography
                variant="bodySmallRegular"
              >
                {`${t('clubs.forms.studentDomain')}`}
              </Typography>
              <Button
                disableRipple
                color="primary"
                size="regular"
                onClick={() => appendStudentDomain('')}
                sx={(theme) => ({
                  ...theme.typography.bodySmallRegular,
                  p: 0,
                })}
              >
                {`+ ${t('clubs.forms.addDomain')}`}
              </Button>
            </Box>
            <Box>

              {studentDomainFields.length === 0 ? (
                <Box
                  sx={{
                    display: 'flex',
                    flexFlow: 'column wrap',
                  }}
                >
                  <FormTextField
                    InputProps={{
                      startAdornment:
                      domainInputAdornment,
                    }}
                    name="studentDomains[0]"
                    label={`${t('clubs.forms.studentDomain')} (1)`}
                    control={control}
                    sx={{ my: 2 }}
                  />
                </Box>
              ) : (studentDomainFields.map((domainField, index) => {
                const fieldName = `studentDomains[${index}]`;
                return (
                  <Box
                    key={domainField.id}
                    sx={{
                      display: 'flex',
                      flexFlow: 'column wrap',
                    }}
                  >
                    {index !== 0
                            && (
                              <Box sx={{
                                display: 'flex',
                                justifyContent: 'end',
                              }}
                              >
                                <IconButton
                                  aria-label="delete"
                                  disableRipple
                                  onClick={() => removeStudentDomain(index)}
                                >
                                  <TrashIcon width="30" height="30" />
                                </IconButton>
                              </Box>
                            )}
                    <FormTextField
                      InputProps={{
                        startAdornment:
                        domainInputAdornment,
                      }}
                      name={fieldName}
                      label={`${t('clubs.forms.studentDomain')} (${index + 1})`}
                      control={control}
                      sx={{ my: 2 }}
                    />
                  </Box>
                );
              }))}
            </Box>
          </Box>
          <Box sx={{ mb: 8 }}>
            <Box sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 2,
            }}
            >
              <Typography
                variant="bodySmallRegular"
              >
                {`${t('clubs.forms.managerDomain')}`}
              </Typography>
              <Button
                disableRipple
                color="primary"
                size="regular"
                onClick={() => appendManagerDomain('')}
                sx={(theme) => ({
                  ...theme.typography.bodySmallRegular,
                  p: 0,
                })}
              >
                {`+ ${t('clubs.forms.addDomain')}`}
              </Button>
            </Box>
            <Box>
              {managerDomainFields.length === 0 ? (
                <Box
                  sx={{
                    display: 'flex',
                    flexFlow: 'column wrap',
                  }}
                >
                  <FormTextField
                    InputProps={{
                      startAdornment:
                      domainInputAdornment,
                    }}
                    name="managerDomains[0]"
                    label={`${t('clubs.forms.managerDomain')} (1)`}
                    control={control}
                    sx={{ my: 2 }}
                  />
                </Box>
              ) : (
                managerDomainFields.map((domainField, index) => {
                  const fieldName = `managerDomains[${index}]`;
                  return (
                    <Box
                      key={domainField.id}
                      sx={{
                        display: 'flex',
                        flexFlow: 'column wrap',
                      }}
                    >
                      {index !== 0
                            && (
                              <Box sx={{
                                display: 'flex',
                                justifyContent: 'end',
                              }}
                              >
                                <IconButton
                                  aria-label="delete"
                                  disableRipple
                                  onClick={() => removeManagerDomain(index)}
                                >
                                  <TrashIcon width="30" height="30" />
                                </IconButton>
                              </Box>
                            )}
                      <FormTextField
                        InputProps={{
                          startAdornment:
                          domainInputAdornment,
                        }}
                        name={fieldName}
                        label={`${t('clubs.forms.managerDomain')} (${index + 1})`}
                        control={control}
                        sx={{ my: 2 }}
                      />
                    </Box>
                  );
                }))}
            </Box>
          </Box>
        </Box>
        <Box sx={{
          my: 8,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        >
          <LoadingButton
            type="submit"
            variant="contained"
            size="fixed"
            loading={isSubmitting}
            disabled={isSubmitting || !isDirty || !isValid}
          >
            {createMode ? t('common.submit') : t('common.update')}
          </LoadingButton>
        </Box>
      </Box>
    </form>
  );
};

export default ClubDetailsForm;
