import { getMyCourses } from 'services';
import { useCustomQuery } from 'reactQuery';

const useMyCourses = (queryProps = {}) => {
  const { queryKeyOptions = {}, ...rest } = queryProps;

  const query = useCustomQuery({
    queryKey: ['my-courses', queryKeyOptions],
    queryFn: getMyCourses,
    ...rest,
  });

  return { ...query };
};

export default useMyCourses;
