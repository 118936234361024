import _ from 'lodash';

export const REGEX = {
  alphabet: /^[a-zA-Zء-ي ]+$/,
  alphabetEn: /^[A-Za-z]+$/,
  alphabetEnWithSpace: /^[A-Za-z ]+$/,
  alphabetArWithSpace: /^[ء-ي ]+$/,
  alphabetAr: /^[ء-ي]+$/,
  alphaNumericAr: /^[\u0600-\u06FF\u0750-\u077F٠١٢٣٤٥٦٧٨٩]*$/,
  alphaNumeric: /^[a-zA-Z0-9]*$/,
  alphaNumericEnSpace: /^[a-zA-Z0-9 ]*$/,
  alphaNumericArSpace: /^[\u0600-\u06FF\u0750-\u077F٠١٢٣٤٥٦٧٨٩ ]*$/,
  alphaNumericUnderscore: /^[a-zA-Z0-9_]*$/,
  alphaNumericArEn: /^[a-zA-Z0-9\u0600-\u06FF\u0750-\u077F٠١٢٣٤٥٦٧٨٩ ]*$/,
  alphaNumericArEnComma: /^[a-zA-Z0-9\u0600-\u06FF\u0750-\u077F٠١٢٣٤٥٦٧٨٩, ]*$/,
  alphaNumericArEnCommaDashDot: /^[a-zA-Z0-9\u0600-\u06FF\u0750-\u077F٠١٢٣٤٥٦٧٨٩,-. ]*$/,
  alphaNumericEnDashDotUnderscore: /^[a-zA-Z0-9-_.]*$/,
  alphaNumericEnSpaceCommaParentheses: /^[a-zA-Z0-9 ,()]*$/,
  alphaNumericArSpaceCommaParentheses: /^[\u0600-\u06FF\u0750-\u077F٠١٢٣٤٥٦٧٨٩ ،()]*$/,
  alphaNumericArEnCommaDashDotNewLine: /^[a-zA-Z0-9\u0600-\u06FF\u0750-\u077F٠١٢٣٤٥٦٧٨٩,-.\n ]*$/,
  alphaNumericEnCommaDash: /^[a-zA-Z0-9_-]*$/,
  alphaNumericEnCommaDashDot: /^[a-zA-Z0-9\s.-]*$/,
  notAlphaNumeric: /[^\w]/g,
  saudiMobileNumberWZero: /^(0)(5)(5|0|3|6|4|9|1|8|7)([0-9]{7})/g,
  saudiMobileNumber: /^(5)(5|0|3|6|4|9|1|8|7)([0-9]{7})/g,
  saudiMobileNumberWCountryCode: /^(966)(5)(5|0|3|6|4|9|1|8|7)([0-9]{7})/g,
  nationalIdIqama: /^(1|2)([0-9]{9})/g,
  typeSaudiMobileNumber: /^(5)/,
  typeNationalIdIqama: /^(1|2)/,
  thousandSeparator: /(\d)(?=(\d{3})+(?!\d))/g,
  digits: /^[0-9]*$/,
  domain: /^[\w-.]+(?:\.[\w-]+)*\.[\w-]{2,4}$/,
  numbers: /\d/,
  allNumbers: /^\d+$/,
  lowercase: /[a-z]/,
  uppercase: /[A-Z]/,
  specialCharacter: /[-!$%^&*()_+|~=`{}[:;<>?,.@#\]]/,
  decimalDigits: /^\d{0,15}\.?(\d{1,4})?$/,
  arabicDigits: /[٠١٢٣٤٥٦٧٨٩]/g,
  arabicCharacters: /[\u0600-\u06FF\u0750-\u077F]/,
  whiteSpace: /\s/g,
  noWhiteSpace: /^\s*\S.*$/,
  mail: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
  password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-!$%^&*()_+|~=`{}[:;<>?,.@#\]])(?=.{8,})/,
  url: /^(http[s]?:\/\/(www\.)?|ftp:\/\/(www\.)?|www\.){0,1}([0-9A-Za-z-*.@:%_+~#=]+)+((\.[a-zA-Z]{2,3})+)(\/(.)*)?(\?(.)*)?/,
  ip: /^(?!.*\.$)((?!0\d)(1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/,
  iban: /^([A-Z]{2})([0-9]{2})([A-Z0-9]{9,30})$/,
  questionAnd: /\?&/,
  creditCard: /(^4[0-9]{12}(?:[0-9]{3})?$)|(^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}$)|(3[47][0-9]{13})|(^3(?:0[0-5]|[68][0-9])[0-9]{11}$)|(^6(?:011|5[0-9]{2})[0-9]{12}$)|(^(?:2131|1800|35\d{3})\d{11}$)/,
  notNumber: /[^0-9]/g,
  alphaNumericSpaceSpecialCharacter: /^[a-zA-Z0-9-!$%^&*()_+|~=`{}[:;<>?,.@#\] ]*$/,
};

export const testRegex = (pattern, text = '') => {
  if (!text || !text.length) {
    return false;
  }
  if (typeof pattern === 'string') {
    const escapedPattern = pattern.replace(/\\/g, '\\\\');
    return new RegExp(escapedPattern).test(text);
  }
  return new RegExp(pattern).test(text);
};

export const convertArNumbersToEn = (number) => (number
  .replace(/[٠١٢٣٤٥٦٧٨٩]/g, (d) => d.charCodeAt(0) - 1632)
  .replace(/[۰۱۲۳۴۵۶۷۸۹]/g, (d) => d.charCodeAt(0) - 1776));

export const numberWithCommas = (number) => {
  if (number || number === 0) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return number;
};

export const numberWithoutCommas = (number) => number && number.toString() && parseFloat(number.toString().replace(/,/g, ''));

export const formatNumber = (numericValue, commaSeparated = 'true') => {
  if (testRegex(REGEX.numbers, numericValue) || testRegex(REGEX.arabicDigits, numericValue)) {
    const convertedNumber = convertArNumbersToEn(numericValue);
    const filteredNumber = numberWithoutCommas(convertedNumber);
    const clearNumber = Number.isNaN(filteredNumber) ? '' : filteredNumber;
    return commaSeparated
      ? numberWithCommas(clearNumber)
      : clearNumber.toString();
  }
  return '';
};

export const formatNumberCanStartWithZero = (numericValue) => {
  if (testRegex(REGEX.allNumbers, numericValue) || testRegex(REGEX.arabicDigits, numericValue)) {
    const convertedNumber = convertArNumbersToEn(numericValue);
    return convertedNumber;
  }
  return numericValue.replace(REGEX.notNumber, '');
};

export const capitalizeFirstLetter = (string = '') => string && string.charAt(0).toUpperCase() + string.slice(1);

/**
 *
 * @param {object} newValues
 * @param {object} initialValues
 *
 * @returns An object containing the keys and values
 * that has changed in newValues since initialValues
 */
export const getDirtyObject = (newValues = {}, initialValues = {}) => Object
  .entries(newValues)
  .reduce((acc, [key, value]) => {
    // account for dates of different formats
    const hasChanged = _.isDate(value)
      ? !_.isEqual(new Date(initialValues[key]), new Date(value))
      : !_.isEqual(initialValues[key], value);
    if (hasChanged) {
      acc[key] = value;
    }
    return acc;
  }, {});

export function formatLargeNumber(num) {
  const formatter = Intl.NumberFormat('en', { notation: 'compact' });
  return formatter.format(num);
}
export const localizedDate = (language, date) => new Date(date).toLocaleString(language, { month: 'short', year: 'numeric', day: 'numeric' });

export const localizedTime = (date) => new Date(date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

export const convertDateToStringWithDash = (date) => date.toLocaleDateString('en-UK')
  .replaceAll('/', '-').split('-').reverse()
  .join('-');

export const withHttps = (url) => (!/^https?:\/\//i.test(url) ? `https://${url}` : url);

// Source: https://stackoverflow.com/questions/14333568/find-links-and-make-them-clickable
export const replaceURLWithHTMLLinks = (text = '') => {
  const exp = /(\b(https?|ftp|file|www.):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/ig;
  return text.replace(exp, "<a href='$1'>$1</a>");
};

export const toHoursAndMinutes = (totalSeconds, localizedHour, localizedMinute) => {
  const totalMinutes = Math.floor(totalSeconds / 60);

  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  return `${hours}${localizedHour}${minutes > 0 ? ` ${minutes}${localizedMinute}` : ''}`;
};
