import { useTheme } from '@mui/styles';

const VideoOutlinedIcon = (props) => {
  const { palette } = useTheme();

  const {
    fill = palette.common.darkGray,
    stroke = palette.common.darkGray,
    ...rest
  } = props;

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g clipPath="url(#clip0_772_36355)">
        <path d="M8 15.6785C3.7654 15.6785 0.32 12.2331 0.32 7.99853C0.32 3.76393 3.7654 0.318535 8 0.318535C12.2346 0.318535 15.68 3.76393 15.68 7.99853C15.68 12.2331 12.2346 15.6785 8 15.6785ZM8 0.678535C3.96327 0.678535 0.68 3.9618 0.68 7.99853C0.68 12.0353 3.96327 15.3185 8 15.3185C12.0367 15.3185 15.32 12.0353 15.32 7.99853C15.32 3.9618 12.0367 0.678535 8 0.678535Z" stroke={stroke} strokeWidth="0.64" />
        <path d="M11.8398 8.00064L6.07984 11.3262L6.07984 4.67511L11.8398 8.00064Z" fill={fill} />
      </g>
      <defs>
        <clipPath id="clip0_772_36355">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default VideoOutlinedIcon;
