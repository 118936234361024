import { useState } from 'react';

import { exportStudents } from 'services';
import { DataGrid } from 'components/core';
import { useUsers } from 'reactQuery/queries';
import { useLocale, useSnackbar } from 'util/hooks';
import { ROLE_IDS } from 'assets/constants/userRoles';
import { getStatusByName } from 'assets/constants/statuses';

import { LoadingButton } from '@mui/lab';
import { isEmpty } from 'lodash';
import useTableColumns from './useTableColumns';

const StudentsTable = (props) => {
  const {
    roles = [],
    statuses = [],
  } = props;

  const { t } = useLocale();
  const snack = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const filterOptions = {
    status: statuses.map((s) => getStatusByName(s)?.name),
    role: roles.map((roleName) => ROLE_IDS[roleName]),
  };

  const handleExport = async () => {
    setIsLoading(true);
    const options = {
      filterOptions: {
        role: roles.map((role) => ROLE_IDS[role]),
      },
    };
    try {
      await exportStudents(options);
    } catch (error) {
      snack({
        message: error.message || t('common.somethingWrong'),
        severity: 'error',
      });
    }
    setIsLoading(false);
  };

  const {
    data = { items: [], meta: {} },
    isFetching = true,
    refetch: refetchStudents = () => { },
  } = useUsers({
    queryKeyOptions: { filterOptions },
  });

  const {
    items: rows = [],
    meta = {},
  } = data || {};

  const exportButton = (
    <LoadingButton
      loading={isLoading}
      variant="outlined"
      onClick={handleExport}
      disabled={isEmpty(rows)}
      sx={{
        minWidth: 130,
        height: 56,
        '&:disabled': {
          borderColor: 'common.berylliumGrey',
          color: 'common.berylliumGrey',
        },
      }}
    >
      {t('common.exportAll')}
    </LoadingButton>
  );

  const columns = useTableColumns({ refetchStudents });

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      loading={isFetching}
      pagination={meta}
      withSearch={false}
      withCustomToolbar
      componentsProps={{
        noRowsOverlay: { type: 'user' },
        toolbar: {
          placeholder: t('users.searchUsersPlaceholder'),
          component: exportButton,
        },
      }}
    />
  );
};

export default StudentsTable;
