import { useTheme } from '@mui/styles';

const NoBookmarksIcon = (props) => {
  const { palette } = useTheme();

  const {
    fill = 'none',
    circleFill = palette.common.vividOrange,
    stroke = palette.common.berylliumGrey,
  } = props;

  return (
    <svg width="135" height="150" viewBox="0 0 135 150" fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="79" cy="56" r="56" fill={circleFill} fillOpacity="0.2" />
      <path d="M77.75 44.979C73.2857 44.979 69.6667 48.556 69.6667 52.9685V141.652C69.6661 143.103 68.8691 144.44 67.5849 145.144C66.3008 145.847 64.7314 145.807 63.4856 145.039L37.3333 128.869L11.1811 145.023C9.93771 145.79 8.37186 145.831 7.08878 145.132C5.80571 144.432 5.00646 143.1 5 141.652V52.9685C5 39.731 15.8571 29 29.25 29H77.75C91.1429 29 102 39.731 102 52.9685V72.9422C102 75.1484 100.19 76.9369 97.9583 76.9369H69.6667" stroke={stroke} strokeWidth="8.24121" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
export default NoBookmarksIcon;
