import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import * as Yup from 'yup';
import {
  FormSelect, FormAutocomplete, FormTextField,
} from 'components/form';
import { LoadingButton } from '@mui/lab';
import { getClubs, getProviders, inviteUser } from 'services';
import {
  getDependentsForRoleByName, ROLE_IDS,
} from 'assets/constants/userRoles';
import { useFastForm, useLocale, useSnackbar } from 'util/hooks';

const InviteUserForm = (props) => {
  const { user = {} } = props;

  const { t } = useLocale();
  const snack = useSnackbar();
  const naviagte = useNavigate();
  const roleOptions = getDependentsForRoleByName(user?.roleName)
    ?.filter((dependent) => dependent.invitable)
    ?.map((option) => ({ ...option, label: t(option.localizationKey) }));

  const onSubmit = async (values) => {
    let payload = {
      email: values.email,
      role: { id: Number(values.role) },
    };
    if (values.role === ROLE_IDS.clubManager.toString()) {
      payload = {
        ...payload,
        club: { id: Number(values.club.id) },
      };
    }
    if (values.role === ROLE_IDS.contentCreator.toString()) {
      payload = {
        ...payload,
        provider: { id: Number(values.provider.id) },
      };
    }

    try {
      await inviteUser(payload);
      snack({
        message: `${t('invitation.inviteMessage.invitation')}
           ${values.email} ${t('invitation.inviteMessage.confirm')}`,
        severity: 'success',
      });

      naviagte(-1);
    } catch (error) {
      error.errors?.forEach((err) => {
        setError(err.property, {
          type: 'api',
          message: err.message,
        });
      });

      if (!error.errors) { // Non-form errors
        snack({
          message: (error.errors[0].message || t('common.somethingWrong')),
          severity: 'error',
        });
      }
    }
  };

  const defaultValues = {
    email: '',
    role: '',
    club: '',
    provider: '',
  };

  const validationSchema = Yup.object({
    email: Yup
      .string()
      .email(t('email.validation'))
      .required(t('invitation.enterEmail')),
    role: Yup
      .string()
      .required(t('invitation.enterRole')),
    club: Yup.object()
      .nullable()
      .when('role', {
        is: (role) => role === ROLE_IDS.clubManager.toString(),
        then: Yup.object().nullable()
          .required(t('invitation.enterClub')),
      }),
    provider: Yup.object()
      .nullable()
      .when('role', {
        is: (role) => role === ROLE_IDS.contentCreator.toString(),
        then: Yup.object().nullable()
          .required(t('invitation.enterProvider')),
      }),
  });

  const {
    control,
    handleSubmit,
    watch,
    setError,
    formState: {
      isSubmitting,
      isDirty,
      isValid,
    },
  } = useFastForm({
    defaultValues,
    validationSchema,
  });

  const invitationForm = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{
        display: 'grid',
        rowGap: 7,
      }}
      >
        <Box>
          <FormTextField
            name="email"
            control={control}
            type="email"
            label={t('common.email')}
            disabled={isSubmitting}
            fullWidth
          />
        </Box>
        <Box>
          <FormSelect
            name="role"
            control={control}
            options={roleOptions}
            label={t('common.role')}
            disabled={isSubmitting}
            fullWidth
          />
        </Box>
        {watch('role') === ROLE_IDS.clubManager && (
          <Box>
            <FormAutocomplete
              name="club"
              control={control}
              label={t('common.club')}
              fullWidth
              selectFunc={getClubs}
            />
          </Box>
        )}
        {watch('role') === ROLE_IDS.contentCreator && (
          <Box>
            <FormAutocomplete
              name="provider"
              control={control}
              label={t('common.provider')}
              fullWidth
              selectFunc={getProviders}
            />
          </Box>
        )}
      </Box>
      <Box sx={{
        my: 7,
        display: 'flex',
        justifyContent: 'center',
      }}
      >
        <LoadingButton
          type="submit"
          variant="contained"
          size="regular"
          loading={isSubmitting}
          disabled={!isValid || !isDirty || isSubmitting}
        >
          {t('invitation.sendInvitation')}
        </LoadingButton>
      </Box>
    </form>
  );

  return (
    <Box
      sx={{
        py: 8,
      }}
    >
      {invitationForm}
    </Box>
  );
};

export default InviteUserForm;
