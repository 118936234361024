import React from 'react';
import { useRoutes } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { managementModeAtom } from 'recoil/atoms';
import { useAuth } from 'util/hooks';
import {
  mainRouteConfigs,
  managementLayoutRouteConfigs,
  unverifiedStudentRouteConfigs,
} from './routeConfigs';
import { getRoutesForRole } from './routeUtils';

const Router = () => {
  const {
    isLoggedIn,
    currentUser: { userRole = '', emailVerifyAt = '' },
  } = useAuth();

  const managementMode = useRecoilValue(managementModeAtom);
  const [routeConfigs, setRouteConfigs] = React.useState(mainRouteConfigs);

  React.useEffect(() => {
    if (managementMode) {
      setRouteConfigs(managementLayoutRouteConfigs);
    } else if (isLoggedIn && !emailVerifyAt) {
      setRouteConfigs(unverifiedStudentRouteConfigs);
    } else {
      setRouteConfigs(mainRouteConfigs);
    }
  }, [managementMode, isLoggedIn, emailVerifyAt]);

  const routes = useRoutes(getRoutesForRole(routeConfigs, userRole));

  return routes;
};

export default Router;
