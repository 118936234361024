import END_POINTS from '../endPoints';
import METHODS from '../methods';
import api from '../api';

const acceptCourseRegistrant = (data, courseId) => api(
  END_POINTS.acceptCourseRegistrant(courseId),
  {
    method: METHODS.POST,
    data,
  },
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default acceptCourseRegistrant;
