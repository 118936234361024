import useAuth from './useAuth';
/**
 *
 * @param {String} permissionKey - the permission key to search for
 * @returns true if the current user has permissionKey in their permissions array
 */
const usePermission = (permissionKey = '') => {
  const { currentUser: { rolePermission = [] } } = useAuth();

  const hasPermission = rolePermission?.find((p) => p.nameKey === permissionKey);

  return !!hasPermission;
};

export default usePermission;
