import { useTheme } from '@mui/styles';

const CTFPrizes = (props) => {
  const { palette } = useTheme();

  const {
    stroke1 = palette.common.lightCyan,
    stroke2 = palette.common.emeraldGreen,
  } = props;

  return (
    <svg width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M18.5 19.5135V37.047" stroke={stroke2} strokeWidth="1.77447" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M18.5 28.6041L20.7891 27.8411C21.7339 27.5259 22.7382 27.4308 23.7254 27.5631C24.7126 27.6953 25.6565 28.0514 26.4851 28.6041C27.3139 29.1563 28.2578 29.5121 29.2449 29.6443C30.232 29.7765 31.2362 29.6817 32.1811 29.3671L34.4702 28.6041V16.1828L32.1811 16.9458C31.2362 17.2604 30.232 17.3553 29.2449 17.223C28.2578 17.0908 27.3139 16.735 26.4851 16.1828C25.6565 15.6301 24.7126 15.274 23.7254 15.1418C22.7382 15.0096 21.7339 15.1046 20.7891 15.4198L18.5 16.1828V28.6041Z" stroke={stroke2} strokeWidth="1.77447" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M26.5 1.08392L33.9623 8.06827L44.1777 8.40625L44.5156 18.6216L51.5 26.0839L44.5156 33.5462L44.1777 43.7616L33.9623 44.0996L26.5 51.0839L19.0377 44.0996L8.82233 43.7616L8.48435 33.5462L1.5 26.0839L8.48435 18.6216L8.82233 8.40625L19.0377 8.06827L26.5 1.08392Z" stroke={stroke1} strokeWidth="2" strokeLinejoin="round" />
    </svg>
  );
};

export default CTFPrizes;
