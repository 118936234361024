import { Box, Typography } from '@mui/material';
import { KsaMap } from 'assets/maps';
import { useLocale } from 'util/hooks';

const CityUniversityName = ({ universityName, ...rest }) => (
  <Box
    sx={{
      '::before': {
        content: '"•"',
        px: 2,
      },
    }}
    {...rest}
  >
    <Typography variant="bodySmallRegular">
      {universityName}
    </Typography>
  </Box>
);

const LandingMapInfoCard = ({
  title = '',
  regions = [],
  region = {},
  sx = {},
  ...rest
}) => {
  const { t, isAr } = useLocale();
  const nameKey = isAr ? 'nameAr' : 'nameEn';

  const cardHeight = 463;
  const headerHeight = 46;
  const bodyHeight = cardHeight - headerHeight;

  const header = (
    <Box sx={{
      backgroundImage: 'linear-gradient(170deg, #93D29E 0%, #72C0C3 97.91%)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: headerHeight,
    }}
    >
      <Typography variant="bodyMediumRegular" color="common.white">
        {title}
      </Typography>
    </Box>
  );

  const bodyHeaderSection = (
    <Box
      dir="ltr"
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 100,
      }}
    >
      <Box sx={{ maxWidth: 100 }}>
        <KsaMap
          regions={regions}
          highlightedRegion={region?.name}
          withOpacity
        />
      </Box>

      <Typography variant="bodyStandardMedium">
        {t(`landing.clubsSection.map.${region?.name}`)}
      </Typography>
    </Box>
  );

  const bodyCitiesList = region?.cities?.map((city) => (
    <Box key={city?.[nameKey]}>
      <Typography variant="bodySmallMedium">
        {city?.[nameKey]}
      </Typography>
      {city?.universities?.map((university) => (
        <CityUniversityName
          key={university[nameKey]}
          universityName={university[nameKey]}
        />
      ))}
    </Box>
  ));

  const body = (
    <Box sx={{ maxHeight: bodyHeight, overflowY: 'auto' }}>
      <Box sx={{ py: 5, px: 8 }}>
        {bodyHeaderSection}

        <Box sx={{ mt: 5 }}>
          {bodyCitiesList}
        </Box>
      </Box>
    </Box>
  );

  return (
    <Box
      sx={{
        bgcolor: 'common.white',
        borderRadius: 1,
        overflow: 'hidden',
        width: 334,
        height: cardHeight,
        ...sx,
      }}
      {...rest}
    >
      {header}
      {body}
    </Box>
  );
};

export default LandingMapInfoCard;
