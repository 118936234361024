import { useTheme } from '@mui/styles';

const CoursesIcon = (props) => {
  const { palette } = useTheme();

  const {
    fill = 'none',
    stroke = palette.text.secondary,
    rectFill = palette.common.offWhite,
    ...rest
  } = props;

  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill={fill} xmlns="http://www.w3.org/2000/svg" {...rest}>
      <rect width="32" height="32" rx="10.6667" fill={rectFill} />
      <g clipPath="url(#clip0_1992_85027)">
        <path d="M16.8333 25.0886C18.9273 23.3158 21.4912 22.1896 24.2133 21.847C24.5341 21.8047 24.8286 21.6472 25.0419 21.4039C25.2551 21.1606 25.3726 20.848 25.3725 20.5245V7.97113C25.3728 7.77881 25.3318 7.58868 25.2523 7.41356C25.1728 7.23845 25.0567 7.08243 24.9117 6.95604C24.7668 6.82965 24.5964 6.73584 24.4121 6.68094C24.2278 6.62604 24.0338 6.61132 23.8433 6.63779C21.2573 7.03828 18.8317 8.14408 16.8333 9.83363C16.5946 10.018 16.3016 10.118 16 10.118C15.6984 10.118 15.4053 10.018 15.1667 9.83363C13.1674 8.14427 10.7409 7.03902 8.15417 6.63946C7.96382 6.61301 7.77002 6.62768 7.58583 6.68249C7.40164 6.73729 7.23134 6.83096 7.08641 6.95716C6.94149 7.08337 6.82532 7.23918 6.74572 7.41409C6.66612 7.58901 6.62496 7.77895 6.625 7.97113V20.5245C6.62486 20.848 6.74237 21.1606 6.95564 21.4039C7.16891 21.6472 7.46338 21.8047 7.78417 21.847C10.5072 22.1891 13.072 23.3154 15.1667 25.0886C15.4052 25.2734 15.6983 25.3736 16 25.3736C16.3017 25.3736 16.5948 25.2734 16.8333 25.0886V25.0886Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M16 10.1152V25.3744" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </g>
    </svg>
  );
};

export default CoursesIcon;
