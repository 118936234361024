import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { NoPostsIcon } from 'assets/icons';
import { DataPlaceholder, HorizontalScrollContainer } from 'components/molecules';
import { LandingPostCard } from 'components/organisms';
import { isEmpty } from 'lodash';
import { usePublicPosts } from 'reactQuery/queries';
import { PATHS } from 'routes';
import { useLocale } from 'util/hooks';
import LandingLinkWrapper from '../helpers/LandingLinkWrapper';
import LandingSectionWrapper from '../helpers/LandingSectionWrapper';

const RecentPosts = () => {
  const { t } = useLocale();
  const translationKey = 'landing.recentPostsSection';

  const {
    data = { items: [], meta: {} },
  } = usePublicPosts({
    queryKeyOptions: { pagination: { limit: 3 } },
  });

  const { items: posts } = data;

  const header = (
    <Typography variant="h5">
      {t(`${translationKey}.header`)}
    </Typography>
  );

  const subHeader = (
    <Typography
      variant="bodyStandardRegular"
      sx={{ pt: 2 }}
    >
      {t(`${translationKey}.subHeader`)}
    </Typography>
  );

  const postCards = (
    posts?.length
      ? (
        <HorizontalScrollContainer sx={{ py: 6 }}>
          {posts?.map((post) => <LandingPostCard key={post.id} post={post} />)}
        </HorizontalScrollContainer>
      )
      : (
        <DataPlaceholder
          icon={<NoPostsIcon />}
          header={t('posts.placeholder.noPosts')}
        />
      )
  );

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
    }}
    >
      <LandingSectionWrapper sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
      >
        {header}
        {subHeader}
      </LandingSectionWrapper>

      <Box sx={{
        pt: { xs: 6, sm: 7.5 },
        width: 1,
        display: 'flex',
        justifyContent: 'center',
      }}
      >
        {postCards}
      </Box>

      {
        !isEmpty(posts) && <LandingLinkWrapper to={PATHS.posts} innerText={t('common.exploreMore')} />
      }
    </Box>
  );
};

export default RecentPosts;
