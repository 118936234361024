import { useTheme } from '@mui/styles';

const VideoFilledIcon = (props) => {
  const { palette } = useTheme();

  const {
    fill = palette.common.silverGrey,
    innerFill = palette.common.darkGray,
    stroke = palette.common.darkGray,
    ...rest
  } = props;

  return (
    <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <rect opacity="0.2" x="0.5" width="30" height="30" rx="6.91016" fill={fill} />
      <rect x="3" y="2.5" width="25" height="25" rx="5.75846" fill={fill} />
      <g clipPath="url(#clip0_772_36236)">
        <path d="M15.5 20.9939C12.194 20.9939 9.50417 18.304 9.50417 14.998C9.50417 11.6921 12.194 9.00221 15.5 9.00221C18.806 9.00221 21.4958 11.6921 21.4958 14.998C21.4958 18.304 18.806 20.9939 15.5 20.9939ZM15.5 9.27513C12.344 9.27513 9.77708 11.842 9.77708 14.998C9.77708 18.154 12.344 20.721 15.5 20.721C18.656 20.721 21.2229 18.154 21.2229 14.998C21.2229 11.842 18.656 9.27513 15.5 9.27513Z" stroke={stroke} strokeWidth="0.508333" />
        <path d="M18.5 15L14 17.5981V12.4019L18.5 15Z" fill={innerFill} />
      </g>
      <defs>
        <clipPath id="clip0_772_36236">
          <rect width="12.5" height="12.5" fill="white" transform="translate(9.25 8.75)" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default VideoFilledIcon;
