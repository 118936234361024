import { useTheme } from '@mui/styles';

const MoreActionsIcon = (props) => {
  const { palette } = useTheme();

  const {
    fill = palette.common.couchGrey,
    stroke = palette.common.silverGrey,
    ...rest
  } = props;

  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d="M8.625 13.5C7.59375 13.5 6.75 14.3147 6.75 15.3103C6.75 16.306 7.59375 17.1207 8.625 17.1207C9.65625 17.1207 10.5 16.306 10.5 15.3103C10.5 14.3147 9.65625 13.5 8.625 13.5ZM21.75 13.5C20.7188 13.5 19.875 14.3147 19.875 15.3103C19.875 16.306 20.7188 17.1207 21.75 17.1207C22.7812 17.1207 23.625 16.306 23.625 15.3103C23.625 14.3147 22.7812 13.5 21.75 13.5ZM15.1875 13.5C14.1562 13.5 13.3125 14.3147 13.3125 15.3103C13.3125 16.306 14.1562 17.1207 15.1875 17.1207C16.2188 17.1207 17.0625 16.306 17.0625 15.3103C17.0625 14.3147 16.2188 13.5 15.1875 13.5Z" fill={fill} />
      <circle cx="15" cy="15" r="14.625" stroke={stroke} strokeWidth="0.75" />
    </svg>
  );
};
export default MoreActionsIcon;
