import END_POINTS from '../../endPoints';
import METHODS from '../../methods';
import api from '../../api';

const updateProvider = (data, id) => api(
  END_POINTS.provider(id),
  {
    method: METHODS.PUT,
    data,
  },
)
  .then((response) => Promise.resolve(response))
  .catch((error) => Promise.reject(error));

export default updateProvider;
