import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Box,
  Button,
  Avatar,
  Typography,
  IconButton,
  Link,
} from '@mui/material';
import { NoSessionsIcon, PlayIcon } from 'assets/icons';
import { DataPlaceholder } from 'components/molecules';
import { useCourseRecordings } from 'reactQuery/queries';
import { useAuth, useLocale } from 'util/hooks';
import { PATHS } from 'routes';

const RecordedSessions = (props) => {
  const {
    courseDetails = {},
  } = props;

  const {
    cover = '',
    id = '',
  } = courseDetails;

  const { t } = useLocale();
  const { isLoggedIn } = useAuth();
  const { pathname } = useLocation();

  const placeholderHeader = isLoggedIn
    ? t('sessions.noSessionsHeader')
    : t('sessions.noSessionsHeaderForGuest');
  const placeholderDescription = isLoggedIn
    ? t('sessions.sessionsPlaceholder')
    : t('sessions.sessionsPlaceholderForGuest');

  const {
    data = {},
    isFetched = false,
  } = useCourseRecordings({
    courseId: id,
    options: {
      enabled: isLoggedIn,
    },
  });

  const { items: courseRecordings = [] } = data || {};

  const handleViewRecordedSession = (recording = {}) => {
    window.open(`/${PATHS.courses}/${id}/recorded-session/${recording.session?.id}`, 'noopener,noreferrer');
  };

  const recordedSessions = useMemo(() => courseRecordings?.map((recording) => (
    <Box
      key={recording?.session?.id}
      sx={{ mb: 8 }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ display: 'flex' }}>
          <Avatar
            sx={{
              borderRadius: 1,
              width: 87,
              height: 67,
            }}
            variant="square"
            alt={recording?.session?.title}
            src={cover}
          />
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            px: 4,
          }}
          >
            <Typography variant="bodyStandardMedium">
              {recording?.session?.title}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'end' }}>

          <IconButton
            aria-label="play"
            disableRipple
            onClick={() => handleViewRecordedSession(recording)}
          >
            <PlayIcon />
          </IconButton>
        </Box>
      </Box>
      <Box sx={{ mt: 3 }}>
        <Typography
          color="common.carTireBlack"
          variant="bodySmallRegular"
        >
          {recording?.session?.description}
        </Typography>
      </Box>
    </Box>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  )), [courseRecordings, cover]);

  if ((!isLoggedIn || isFetched) && !courseRecordings?.length) {
    return (
      <>
        <DataPlaceholder
          icon={<NoSessionsIcon />}
          header={placeholderHeader}
          description={placeholderDescription}
        />
        {!isLoggedIn
          && (
            <Box sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: 6,
            }}
            >
              <Button
                component={Link}
                variant="outlined"
                to={`/${PATHS.login}?redirectUrl=${pathname}`}
              >
                {t('common.login')}
              </Button>
            </Box>
          )}
      </>
    );
  }

  return (
    <Box>
      <Box sx={{ mt: 12, p: 4 }}>
        {recordedSessions}
      </Box>
    </Box>
  );
};

export default RecordedSessions;
