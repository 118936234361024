import { useMemo } from 'react';
import {
  Box,
  Grid,
} from '@mui/material';

import {
  FilterButton, URLSearchField,
} from 'components/molecules';
import {
  useAuth,
  useLocale,
} from 'util/hooks';

const SearchAndFilter = (props) => {
  const {
    filters = [],
    children,
    searchFieldPlaceholder = '',
  } = props;

  const { t } = useLocale();
  const { userRole } = useAuth();

  const filterList = useMemo(() => filters
    .filter((filter) => (filter.roles
      ? filter.roles?.includes(userRole)
      : true))
    .map((filter) => (
      <Grid key={filter.filterKey} item>
        <FilterButton {...filter} />
      </Grid>
    )), [userRole, filters]);

  return (
    <Grid
      container
      gap={4}
    >
      <Grid
        item
        xl={3}
        lg={4}
        sm={6}
        xs={12}
      >
        <Box sx={{
          borderRadius: 1,
          backgroundColor: 'background.paper',
          '& fieldset': {
            border: 'none', // no border
          },
        }}
        >
          <URLSearchField placeholder={searchFieldPlaceholder || t('common.search')} />
        </Box>
      </Grid>
      {filterList}
      {children}
    </Grid>
  );
};

export default SearchAndFilter;
