import { Card } from '@mui/material';
import { toggleGeneralSettings } from 'services';
import { useLocale, useSnackbar } from 'util/hooks';
import { useGeneralSettings } from 'reactQuery/queries';
import SettingSwitchWrapper from './SettingSwitchWrapper';

const GeneralSettingsCard = () => {
  const { t } = useLocale();
  const snack = useSnackbar();

  const {
    data = [],
    refetch: refetchGeneralSettings = () => { },
  } = useGeneralSettings();

  const toggleSettingStatus = async (settingKey) => {
    try {
      await toggleGeneralSettings(settingKey);

      refetchGeneralSettings();
      snack({
        message: t('generalSettings.successfullyUpdated'),
        severity: 'success',
      });
    } catch (errors) {
      snack({
        message: errors?.[0]?.message || t('common.somethingWrong'),
        severity: 'error',
      });
    }
  };

  const onToggleChange = (e) => {
    toggleSettingStatus(e.target.id);
  };

  return (
    <Card sx={{
      mt: 9,
      px: 8,
      py: 8.5,
    }}
    >
      {data?.map((setting) => (
        <SettingSwitchWrapper
          key={setting.id}
          settingDetails={setting}
          onChange={onToggleChange}
        />
      ))}
    </Card>
  );
};
export default GeneralSettingsCard;
