import {
  TextField as MUITextField,
} from '@mui/material';
import { forwardRef } from 'react';

const TextField = forwardRef((props, ref) => (
  <MUITextField
    {...props}
    inputRef={ref}
  />
));

export default TextField;
