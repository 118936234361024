import { useCustomInfiniteQuery } from 'reactQuery';
import { getAllPublicCourses } from 'services';

const useInfinitePublicCourses = (queryProps = {}) => {
  const { queryKeyOptions = {}, ...rest } = queryProps;

  const query = useCustomInfiniteQuery({
    queryKey: ['infinite-public-courses', queryKeyOptions],
    queryFn: getAllPublicCourses,
    ...rest,
  });

  return { ...query };
};

export default useInfinitePublicCourses;
