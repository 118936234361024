import {
  Box,
  Typography,
  CardContent,
  useMediaQuery,
} from '@mui/material';
import DOMPurify from 'dompurify';
import { POST_TYPES } from 'assets/constants/posts';
import { CardMediaWithFallback } from 'components/molecules';

const ViewArticleAndVideoCard = (props) => {
  const {
    type,
    content,
    cover,
    video,
    title,
  } = props;

  const showOnMobile = useMediaQuery('(max-width:599px)');

  const isArticle = (type === POST_TYPES.article);

  const articleAndVideoWebView = () => (
    <Box sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'start',
      mb: 3,
    }}
    >
      <CardContent sx={{
        display: 'flex',
        flexFlow: 'column wrap',
        px: 0,
      }}
      >
        <Typography
          variant="bodyStandardBold"
          sx={{ mb: 2, mr: 3 }}
        >
          {title}
        </Typography>
        <Typography
          variant="bodySmallRegular"
          sx={{
            overflowWrap: 'anywhere',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 3,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            lineHeight: '1.5rem',
            mr: 10,
          }}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(content),
          }}
        />
      </CardContent>
      {isArticle
        ? (
          <CardMediaWithFallback
            sx={{
              borderRadius: 1.5,
              minWidth: 350,
              width: 350,
            }}
            component="img"
            src={cover}
            height="183"
            alt="No image found"
          />
        )
        : (
          <CardMediaWithFallback
            sx={{
              borderRadius: 1.5,
              minWidth: 350,
              width: 350,
              backgroundColor: '#000',
            }}
            component="video"
            src={video}
            height="183"
            alt="No video found"
            controls
            autoPlay
            muted
          />
        )}
    </Box>
  );

  const articleAndVideoMobileView = () => (
    <>
      {isArticle
        ? (
          <CardMediaWithFallback
            sx={{ borderRadius: 1.5, mt: 5, mb: 2 }}
            component="img"
            src={cover}
            height="200"
            alt="No image found"
          />
        )
        : (
          <CardMediaWithFallback
            sx={{
              mt: 5,
              mb: 2,
              borderRadius: 1.5,
              backgroundColor: '#000',
            }}
            component="video"
            src={video}
            height="200"
            controls
            autoPlay
            muted
          />
        )}
      <CardContent sx={{ px: 1 }}>
        <Box sx={{ display: 'flex', flexFlow: 'column wrap' }}>
          <Typography
            variant="bodyStandardBold"
            sx={{ mt: 2, mb: 1 }}
          >
            {title}
          </Typography>
          <Typography
            variant="bodySmallRegular"
            sx={{
              overflowWrap: 'anywhere',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              minHeight: '4rem',
              lineHeight: '1.5rem',
              mr: 3,
            }}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(content),
            }}
          />
        </Box>
      </CardContent>
    </>
  );

  return (
    showOnMobile
      ? articleAndVideoMobileView()
      : articleAndVideoWebView()
  );
};

export default ViewArticleAndVideoCard;
