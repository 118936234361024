import PropTypes from 'prop-types';
import { useMemo, useEffect, useState } from 'react';
import { numberWithCommas } from 'util/helpers';

const CounterUp = (props) => {
  const {
    startNumber,
    endNumber,
    duration,
  } = props;

  const [counter, setCount] = useState(startNumber);

  const counterFinalValue = useMemo(() => {
    const endWithCommas = numberWithCommas(endNumber);
    const commaIndex = endWithCommas.indexOf(',');
    return commaIndex === -1
      ? endNumber
      : parseInt(endWithCommas.substring(0, commaIndex), 10);
  }, [endNumber]);

  const incrementFactor = useMemo(
    () => (duration / counterFinalValue),
    [duration, counterFinalValue],
  );

  const reachedFinalValue = counter >= counterFinalValue;

  useEffect(() => {
    const timer = setInterval(() => {
      if (reachedFinalValue) {
        clearInterval(timer);
        return;
      }

      setCount((prev) => prev + 1);
    }, 1000 * incrementFactor);

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [endNumber, incrementFactor, reachedFinalValue]);

  return (counter);
};

CounterUp.propTypes = {
  startNumber: PropTypes.number,
  endNumber: PropTypes.number,
  duration: PropTypes.number,
};

CounterUp.defaultProps = {
  startNumber: 0,
  endNumber: 0,
  duration: 2,
};

export default CounterUp;
