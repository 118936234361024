import api from 'services/api';
import END_POINTS from 'services/endPoints';
import METHODS from 'services/methods';

const updateOwnProfile = (data) => api(
  END_POINTS.updateOwnProfile,
  {
    method: METHODS.PUT,
    data,
  },
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default updateOwnProfile;
