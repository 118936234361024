import { useTheme } from '@mui/styles';

const PlayIcon = (props) => {
  const { palette } = useTheme();
  const {
    fill = palette.common.turquoiseSeaweedGreen,
    innerFill = palette.common.white,
    ...rest
  } = props;

  return (

    <svg width="41" height="41" viewBox="0 0 41 41" fill={fill} xmlns="http://www.w3.org/2000/svg" {...rest}>
      <circle cx="20.6318" cy="20.4224" r="20" fill={fill} />
      <path d="M27.1069 18.2789L16.7846 12.4135C16.468 12.2356 16.1119 12.1401 15.7488 12.1354C15.3858 12.1308 15.0273 12.2173 14.7063 12.387C14.3838 12.556 14.1134 12.8096 13.9241 13.1207C13.7348 13.4317 13.6338 13.7884 13.6318 14.1525V25.8809C13.6338 26.245 13.7348 26.6018 13.9241 26.9128C14.1134 27.2239 14.3838 27.4775 14.7063 27.6465C15.0286 27.8123 15.3866 27.8964 15.7489 27.8916C16.1113 27.8868 16.4669 27.7931 16.7846 27.6187L27.1043 21.752C27.4155 21.5812 27.675 21.3298 27.8557 21.0244C28.0365 20.7189 28.1318 20.3704 28.1318 20.0155C28.1318 19.6605 28.0365 19.3121 27.8557 19.0066C27.675 18.7011 27.4155 18.4498 27.1043 18.2789H27.1069Z" fill={innerFill} />
    </svg>
  );
};

export default PlayIcon;
