import { useParams } from 'react-router-dom';
import { getCourseRegistrants } from 'services';
import { useCustomQuery } from 'reactQuery';

const useCourseRegistrants = (queryProps = {}) => {
  const { courseId } = useParams();
  const { queryKeyOptions = {}, ...rest } = queryProps;

  const query = useCustomQuery({
    queryKey: [`course-attendance-${courseId}`, queryKeyOptions],
    queryFn: (options) => getCourseRegistrants(courseId, options),
    ...rest,
  });

  return { ...query };
};

export default useCourseRegistrants;
