import {
  Card, CardActions,
  CardContent, Collapse,
  Box, Typography,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { useState } from 'react';
import { useLocale } from 'util/hooks';

const RejectAlertMessage = (props) => {
  const { comments = [] } = props;

  const { t } = useLocale();
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: 'rgba(255, 76, 97, 0.2)',
      }}
      >
        <CardContent sx={{
          pt: 2,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'end',
          '& .MuiCardContent-root': {
            paddingBottom: 0,
          },
        }}
        >
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
          }}
          >
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'baseline',
              padding: 0,
            }}
            >
              <Typography
                variant="bodySmallRegular"
                sx={{ color: 'common.pinkishRed' }}
              >
                {t('posts.comments.rejectMessage')}
              </Typography>
              <Box sx={{ pl: 1 }}>
                <Typography
                  variant="bodySmallBold"
                  sx={{ color: 'common.pinkishRed' }}
                >
                  {t('posts.comments.seeWhy')}
                </Typography>
              </Box>
            </Box>
          </Box>
        </CardContent>
        <CardActions
          disableSpacing
          sx={{ color: 'common.pinkishRed' }}
        >
          <ExpandMore
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          />
        </CardActions>
      </Box>
      <Collapse
        in={expanded}
        sx={{ backgroundColor: 'common.transparentRed' }}
        timeout="auto"
        unmountOnExit
      >
        <CardContent>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
          >
            <Typography
              variant="bodySmallMedium"
              sx={{ color: 'secondary.main', mb: 2 }}
            >
              {t('posts.comments.comments')}
            </Typography>
            <Typography
              variant="bodySmallRegular"
              sx={{ color: 'secondary.main' }}
            >
              {comments?.map((c) => (
                <Box key={c.id} sx={{ pl: 2 }}>
                  <li>{c.comment}</li>
                </Box>
              ))}
            </Typography>
          </Box>
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default RejectAlertMessage;
