import { Box, Button } from '@mui/material';
import { FormDatePicker } from 'components/form';
import { useAuth, useFastForm, useLocale } from 'util/hooks';
import * as Yup from 'yup';
import { convertDateToStringWithDash } from 'util/helpers';

const CourseFormStepTwo = (props) => {
  const {
    courseFormInfo = {},
    setCourseFormInfo = () => { },
    handleNextStep = () => { },
    courseDetails = {},
  } = props;

  const { t } = useLocale();
  const { isContentCreator } = useAuth();

  const {
    isCourseRunning = false,
    isCourseCompleted = false,
    isCoursePublished = false,
  } = courseDetails || {};

  const disableEndDate = isCourseCompleted || (isContentCreator && isCoursePublished);
  const disableStartDate = disableEndDate || isCourseRunning;

  const currentDate = new Date();
  const today = new Date(currentDate.toDateString());
  const tomorrow = new Date(currentDate.toDateString());
  tomorrow.setDate(today.getDate() + 1);

  const onSubmit = async (values) => {
    setCourseFormInfo({
      ...courseFormInfo,
      startAt: convertDateToStringWithDash(values.startAt),
      endAt: convertDateToStringWithDash(values.endAt),
    }, handleNextStep());
  };

  const defaultValues = {
    disableStartDate,
    startAt: courseFormInfo?.startAt || courseDetails?.startAt || today,
    endAt: courseFormInfo?.endAt || courseDetails?.endAt || tomorrow,
  };

  const validationSchema = Yup.object({
    disableStartDate: Yup
      .boolean(),
    startAt: Yup.date()
      .when('disableStartDate', {
        is: false,
        then: Yup.date().test({
          name: 'dateNotInThePast',
          message: t('course.validation.startDateValidation'),
          test: (startAtValue) => startAtValue >= today,
        })
          .required(t('course.validation.startDateRequired')),
      }),
    endAt: Yup.date()
      .when('startAt', (startAt, schema) => startAt
        && schema.min(startAt, t('course.validation.endDateValidation')))
      .required(t('course.validation.endDateRequired')),
  });

  const {
    control,
    handleSubmit,
  } = useFastForm({
    defaultValues,
    validationSchema,
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: 9,
        py: 10,
        px: {
          sm: 15,
          xs: 5,
        },
        m: 'auto',
        width: 1,
        maxWidth: 682,
      }}
      >
        <Box sx={{
          display: 'flex',
          columnGap: 4,
        }}
        >
          <FormDatePicker
            name="startAt"
            control={control}
            inputProps={{
              fullWidth: true,
              label: t('common.startDate'),
            }}
            disabled={disableStartDate}
          />
          <FormDatePicker
            name="endAt"
            control={control}
            inputProps={{
              fullWidth: true,
              label: t('common.endDate'),
            }}
            disabled={disableEndDate}
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            sx={{ width: 1, maxWidth: 186 }}
            type="submit"
            variant="contained"
            size="large"
          >
            {t('common.next')}
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default CourseFormStepTwo;
