import { atom } from 'recoil';
import { localStorageEffect } from 'recoil/effects';

const registrationInfo = atom({
  key: 'registrationInfo',
  default: {
    club: '',
    email: '',
    verificationId: '',
  },
  effects_UNSTABLE: [localStorageEffect('registrationInfoAtom')],
});
export default registrationInfo;
