import { useCustomQuery } from 'reactQuery';
import { getUsers } from 'services';

const useUsers = (queryProps) => {
  const { queryKeyOptions = {}, ...rest } = queryProps;

  const query = useCustomQuery({
    queryKey: [
      'users',
      queryKeyOptions,
    ],
    queryFn: getUsers,
    ...rest,
  });

  return { ...query };
};

export default useUsers;
