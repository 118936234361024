import { useParams } from 'react-router-dom';
import { useCustomQuery } from 'reactQuery';
import { getUserCertificateDetails } from 'services';

const useUserCertificateDetails = () => {
  const { certificateId } = useParams();

  const query = useCustomQuery({
    queryKey: [`user-certificate-details-${certificateId}`],
    queryFn: () => getUserCertificateDetails(certificateId),
  });

  return { ...query };
};

export default useUserCertificateDetails;
