export const STATUSES = [
  {
    id: 1,
    name: 'active',
    localizationKey: 'statuses.active',
  },
  {
    id: 2,
    name: 'pending',
    localizationKey: 'statuses.pending',
  },
  {
    id: 3,
    name: 'expired',
    localizationKey: 'statuses.expired',
  },
  {
    id: 4,
    name: 'cancelled',
    localizationKey: 'statuses.cancelled',
  },
  {
    id: 5,
    name: 'suspended',
    localizationKey: 'statuses.suspended',
  },
  {
    id: 6,
    name: 'accepted',
    localizationKey: 'statuses.accepted',
  },
  {
    id: 7,
    name: 'visible',
    localizationKey: 'statuses.suspended',
  },
  {
    id: 8,
    name: 'hidden',
    localizationKey: 'statuses.accepted',
  },
  {
    id: 9,
    name: 'failed',
    localizationKey: 'statuses.failed',
  },
  {
    id: 10,
    name: 'in-progress',
    localizationKey: 'statuses.inProgress',
  },
  {
    id: 11,
    name: 'under-review',
    localizationKey: 'statuses.underReview',
  },
  {
    id: 12,
    name: 'rejected',
    localizationKey: 'statuses.rejected',
  },
];

export const STATUS_NAMES = {
  active: 'active',
  pending: 'pending',
  expired: 'expired',
  cancelled: 'cancelled',
  suspended: 'suspended',
  accepted: 'accepted',
  visible: 'visible',
  hidden: 'hidden',
  failed: 'failed',
  inProgress: 'in-progress',
  underReview: 'under-review',
  verified: 'verified',
  rejected: 'rejected',
  inactive: 'inactive',
};

export const getStatusById = (id) => STATUSES.find((status) => status.id === Number(id));
export const getStatusByName = (name) => STATUSES.find((status) => status.name === String(name));
export const getStatusByKey = (key, value) => STATUSES.find((status) => status[key] === value);
