import {
  Box,
  Card,
  Typography,
  Avatar,
  Link,
} from '@mui/material';

import { useLocale } from 'util/hooks';
import { EventsIconOutlined, PointsIcon, CourseSessionIcon } from 'assets/icons';
import { localizedDate } from 'util/helpers';
import { PATHS } from 'routes';
import { CardMediaWithFallback } from 'components/molecules';
import IconWithLabelContainer from './IconWithLabelContainer';
import CourseCardTrackBar from './CourseCardTrackBar';

const CourseCard = (props) => {
  const {
    courseDetails = {},
    sx = {},
  } = props;

  const { t, language } = useLocale();

  const {
    id = 0,
    cover = '',
    title = '',
    points = 0,
    startAt = '',
    endAt = '',
    sessionsCount = 0,
    provider = {},
    canRegister = false,
    isCoursePublished = false,
    track = {},
  } = courseDetails;

  const courseRoute = `/${PATHS.courses}/${id}`;
  const startDate = localizedDate(language, startAt);
  const endDate = localizedDate(language, endAt);

  const iconDimensions = { width: '15', height: '15' };

  return (
    <Box sx={{ position: 'relative' }}>
      <Link to={courseRoute}>
        <Card
          sx={{
            height: { sm: 470 },
            width: { xs: 300, sm: 310 },
            display: 'flex',
            flexDirection: 'column',
            boxShadow: ({ palette }) => (`0px 0px 35px ${palette.common.boxShadow4}`),
            transition: 'transform 0.2s ease-in-out',
            ':hover': {
              transform: { lg: 'scale(1.1)' },
            },
            position: 'relative',
            ...sx,
          }}
        >
          <Box
            sx={{
              p: 4,
            }}
          >
            <Box sx={{ position: 'relative' }}>
              <CardMediaWithFallback
                sx={{
                  borderRadius: 1,
                  height: {
                    xs: '160px',
                    sm: '200px',
                  },
                }}
                component="img"
                src={cover}
                alt="course cover"
              />
            </Box>
            {(canRegister && isCoursePublished)
            && (
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                position: 'absolute',
                backgroundColor: 'common.boxShadow3',
                borderRadius: 2,
                py: 1,
                px: 2,
                ml: 2,
                mt: 2,
              }}
              >
                <Box sx={{
                  width: 10,
                  height: 10,
                  borderRadius: '50%',
                  backgroundColor: 'common.turquoiseSeaweedGreen',
                }}
                />
                <Typography
                  variant="bodySmallRegular"
                  color="common.white"
                  sx={{ ml: 1 }}
                >
                  {t('common.registrationOpen')}
                </Typography>
              </Box>
            )}
            <Avatar
              src={provider?.logo}
              sx={(theme) => ({
                mt: -5,
                ml: 2,
                width: {
                  xs: 50,
                  sm: 60,
                },
                height: {
                  xs: 50,
                  sm: 60,
                },
                backgroundColor: provider?.logo ? 'common.white' : '',
                border: `2px solid ${theme.palette.common.white}`,
                boxShadow: `0 4px 4px 0px ${theme.palette.common.dropShadow}`,
              })}
            />
            <Box sx={{
              mx: 2,
              display: 'flex',
              flexDirection: 'column',
              height: 180,
              justifyContent: 'space-between',
            }}
            >
              <Typography
                variant="bodyStandardMedium"
                color="common.darkGray"
                sx={{
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 2,
                  overflow: 'hidden',
                  lineHeight: '1.5rem',
                  mt: {
                    xs: 2,
                    sm: 4,
                  },
                }}
              >
                {title}
              </Typography>
              <Typography
                variant="bodySmallRegular"
                color="common.black"
                sx={{ mt: 1 }}
              >
                {provider?.name}
              </Typography>
              <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                mt: {
                  xs: 7,
                  sm: 8,
                },
                mb: {
                  xs: 1,
                  sm: 4,
                },
              }}
              >
                <IconWithLabelContainer
                  icon={<CourseSessionIcon {...iconDimensions} />}
                  text={`${sessionsCount} ${t('common.sessions')}`}
                />
                <IconWithLabelContainer
                  icon={<PointsIcon {...iconDimensions} />}
                  text={`${points} ${t('common.points')}`}
                />
              </Box>
              <Box sx={{ mt: 2, mb: 6 }}>
                <IconWithLabelContainer
                  icon={<EventsIconOutlined {...iconDimensions} />}
                  text={`${startDate} - ${endDate}`}
                />
              </Box>
            </Box>
          </Box>
          {track && <CourseCardTrackBar track={track?.nameEn} />}
        </Card>
      </Link>
    </Box>
  );
};

export default CourseCard;
