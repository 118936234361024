import { useTheme } from '@mui/styles';

const LogoutIcon = (props) => {
  const { palette } = useTheme();

  const {
    fill = 'none',
    rectFill = palette.common.ghostlyGrey,
    stroke = palette.common.pinkishRed,
    ...rest
  } = props;

  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill={fill} xmlns="http://www.w3.org/2000/svg" {...rest}>
      <rect width="32" height="32" rx="10.6667" fill={rectFill} />
      <path d="M12.25 16.0391H25.375" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M22.25 19.1641L25.375 16.0391L22.25 12.9141" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M19.125 19.7861V23.5361C19.1393 23.8524 19.0277 24.1615 18.8147 24.3958C18.6017 24.63 18.3046 24.7704 17.9884 24.7861H7.76087C7.44479 24.7702 7.14789 24.6297 6.93505 24.3955C6.72222 24.1613 6.61076 23.8523 6.62504 23.5361V8.53613C6.61054 8.21991 6.72192 7.91083 6.9348 7.67656C7.14768 7.44228 7.44471 7.30189 7.76087 7.28613H17.9884C18.3046 7.3019 18.6017 7.44225 18.8147 7.6765C19.0277 7.91076 19.1393 8.21983 19.125 8.53613V12.2861" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
export default LogoutIcon;
