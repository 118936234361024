import React from 'react';
import { Box } from '@mui/material';

import { URLSearchField } from 'components/molecules';

const SearchToolbar = (props) => {
  const { sx, ...rest } = props;

  return (
    <Box sx={{ mb: 6, ...sx }}>
      <URLSearchField {...rest} />
    </Box>
  );
};

export default React.memo(SearchToolbar);
