import { Box } from '@mui/material';

const GradientBackground = () => (
  <Box sx={{
    position: 'absolute',
    top: -5,
    left: 0,
    width: '100%',
    minHeight: '750px',
    transform: 'scaleX(2)',
    backgroundImage: 'linear-gradient(155.58deg, #8DDD88 -12.74%, #13A7FF 86.94%)',
    filter: 'blur(20px)',
    overflowY: 'hidden',
    zIndex: -1,
    '::before': {
      content: '""',
      borderRadius: '100%',
      width: '65%',
      height: '70%',
      position: 'absolute',
      left: '50%',
      transform: 'translateX(-50%)',
      bottom: '-40%',
      backgroundColor: 'white',
      filter: 'blur(90px)',
    },
  }}
  />
);

export default GradientBackground;
