import END_POINTS from '../endPoints';
import METHODS from '../methods';
import api from '../api';

const resendInvitation = (id) => api(
  END_POINTS.resendInvitation(id),
  {
    method: METHODS.POST,
  },
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default resendInvitation;
