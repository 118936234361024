import { Button, Typography, Box } from '@mui/material';
import { PATHS } from 'routes';
import { useNavigate } from 'react-router-dom';
import { useLocale } from 'util/hooks';

const ForgotPasswordMessage = (props) => {
  const { userEmail } = props;
  const navigate = useNavigate();
  const { t } = useLocale();

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      py: 6,
      px: {
        sm: 20,
        xs: 6,
      },
    }}
    >
      <Typography
        sx={{
          marginBottom: 21,
        }}
        variant="h4"
      >
        {t('password.resetPassword')}
      </Typography>
      <Typography
        sx={{
          marginBottom: 3,
          alignSelf: 'start',
        }}
        variant="bodyStandardRegular"
      >
        {t('password.passwordForgotSuccess')}
      </Typography>
      <Typography
        sx={{
          marginBottom: 7,
          alignSelf: 'start',
        }}
        variant="bodyStandardMedium"
      >
        {userEmail}
      </Typography>
      <Button
        sx={{
          alignSelf: 'start',
        }}
        variant="contained"
        size="regular"
        onClick={() => navigate(`/${PATHS.login}`)}
      >
        {t('common.backToLogin')}
      </Button>
    </Box>
  );
};

export default ForgotPasswordMessage;
