import { useTheme } from '@mui/styles';

const LikeOutlinedIcon = (props) => {
  const { palette } = useTheme();

  const {
    fill = palette.common.berylliumGrey,
  } = props;

  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M8.91877 17.4417L1.28875 9.76251C-0.520812 7.61714 -0.426183 4.3991 1.58176 2.37738C2.54466 1.4083 3.83603 0.841495 5.19682 0.790673C6.5576 0.739851 7.88716 1.20877 8.91877 2.10336C9.94934 1.20877 11.2777 0.739187 12.6378 0.78871C13.9978 0.838233 15.289 1.4032 16.2527 2.37041C17.2163 3.33761 17.7812 4.6356 17.8341 6.00427C17.887 7.37293 17.4241 8.71117 16.538 9.75081L8.91877 17.4417ZM15.2788 8.66226C15.8921 7.94246 16.2125 7.01601 16.1757 6.06852C16.139 5.12104 15.7479 4.22251 15.0808 3.55298C14.4136 2.88345 13.5198 2.49237 12.5782 2.45811C11.6367 2.42384 10.7171 2.74891 10.0037 3.36819L8.91877 4.31054L7.83387 3.36819C7.12042 2.74891 6.20081 2.42384 5.2593 2.45811C4.31778 2.49237 3.4239 2.88345 2.75677 3.55298C2.08964 4.22251 1.69853 5.12104 1.66181 6.06852C1.62509 7.01601 1.94547 7.94246 2.55876 8.66226L2.65339 8.77253L8.91877 15.0791L15.1842 8.77253L15.2788 8.66226Z" fill={fill} />
    </svg>
  );
};
export default LikeOutlinedIcon;
