import { Typography, Box } from '@mui/material';
import { getPostTypeByKey } from 'assets/constants/posts';
import { postStatus } from 'assets/data';
import { Status, ActionIconWithCount } from 'components/molecules';
import { useDate, useLocale } from 'util/hooks';
import UserAvatarGroup from '../UserAvatarGroup/UserAvatarGroup';

const PostHeader = (props) => {
  const {
    postDetails = {},
    isCertificateAnnouncement = false,
  } = props;
  const { t, isAr } = useLocale();
  const { fromNow } = useDate();

  const {
    status = '',
    author = {},
    topic = '',
    type = '',
    createdAt = '',
    views = 0,
  } = postDetails;

  const isPublished = status === postStatus.PUBLISHED;

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        mt: 8,
        mb: 6,
      }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {getPostTypeByKey(type)?.smallIcon}
          <Typography
            variant="bodySmallMedium"
            sx={{
              px: 2,
              color: 'common.couchGrey',
            }}
          >
            {topic
              ? `${t(`common.${type}`)}: ${isAr ? topic.nameAr : topic.nameEn}`
              : t(`common.${type}`)}
          </Typography>
        </Box>
        <Typography
          variant="bodySmallRegular"
          color="common.berylliumGrey"
        >
          {fromNow(createdAt)}
        </Typography>
      </Box>
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'baseline',
        my: 4,
      }}
      >
        <UserAvatarGroup
          clubName={author.clubAbbreviation || ''}
          avatar={author.avatar}
          name={author.fullName}
          role={author.type}
          username={author.username}
        />
        <Box sx={{ display: 'flex', alignSelf: 'center' }}>
          {!isPublished
            ? (
              <Status
                status={status}
                label={t(`statuses.${status}`)}
              />
            )
            : (
              !isCertificateAnnouncement && (
                <ActionIconWithCount
                  count={views}
                  type="view"
                  disabled
                />
              )
            )}
        </Box>
      </Box>
    </Box>
  );
};

export default PostHeader;
