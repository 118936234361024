import { useTheme } from '@mui/styles';

const CertificatePostHeaderIcon = (props) => {
  const { palette } = useTheme();

  const {
    fill = 'none',
    stroke = palette.common.white,
    rectFill = palette.common.dimMintLeafGreen,
    ...rest
  } = props;

  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill={fill} xmlns="http://www.w3.org/2000/svg" {...rest}>
      <rect width="18" height="18" rx="6" fill={rectFill} />
      <path d="M6.60233 9.84717L5.25 11.4998L6.5 11.7498L7 13.2498L8.354 10.863" stroke={stroke} strokeWidth="0.562462" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11.3954 9.84717L12.7478 11.4998L11.4978 11.7498L10.9978 13.2498L9.60156 10.863" stroke={stroke} strokeWidth="0.562462" strokeLinecap="round" strokeLinejoin="round" />
      <circle cx="9.10156" cy="7.86304" r="2.968" stroke={stroke} strokeWidth="0.564" />
      <circle cx="9.10156" cy="7.86304" r="1.468" stroke={stroke} strokeWidth="0.564" />
    </svg>
  );
};

export default CertificatePostHeaderIcon;
