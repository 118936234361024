import { useTheme } from '@mui/styles';

const CertificateIcon = (props) => {
  const { palette } = useTheme();

  const {
    fill = 'none',
    stroke = palette.common.gadgetGray,
    rectFill = palette.common.offWhite,
    ...rest
  } = props;

  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill={fill} xmlns="http://www.w3.org/2000/svg" {...rest}>
      <rect width="32" height="32" rx="10.6667" fill={rectFill} />
      <path d="M10.2988 17.8916L6.91797 22.0233L10.043 22.6483L11.293 26.3983L14.678 20.4313" stroke={stroke} strokeWidth="1.40615" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M22.2874 17.8916L25.6683 22.0233L22.5433 22.6483L21.2933 26.3983L17.8027 20.4313" stroke={stroke} strokeWidth="1.40615" strokeLinecap="round" strokeLinejoin="round" />
      <circle cx="16.5527" cy="12.9316" r="7.42" stroke={stroke} strokeWidth="1.41" />
      <circle cx="16.5527" cy="12.9316" r="3.67" stroke={stroke} strokeWidth="1.41" />
    </svg>

  );
};

export default CertificateIcon;
