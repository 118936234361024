import { memo } from 'react';
import PropTypes from 'prop-types';
import { useController } from 'react-hook-form';

import { PasswordField, PasswordHelper } from 'components/molecules';

const FormPasswordField = (props) => {
  const {
    showHelper,
    ...rest
  } = props;

  const { field, fieldState } = useController(rest);
  const { error } = fieldState;

  const hasErrored = Boolean(error);

  return (
    <>
      <PasswordField
        {...field}
        {...rest}
        error={hasErrored}
        helperText={hasErrored && error?.message}
      />
      {showHelper && field.value && (
        <PasswordHelper password={field.value} />
      )}
    </>
  );
};

FormPasswordField.propTypes = {
  showHelper: PropTypes.bool,
};

FormPasswordField.defaultProps = {
  showHelper: true,
};

export default memo(FormPasswordField);
