import { useTheme } from '@mui/styles';

const LocationIcon = (props) => {
  const { palette } = useTheme();

  const {
    fill = 'none',
    stroke = palette.common.couchGrey,
    ...rest
  } = props;

  return (
    <svg width="12" height="16" viewBox="0 0 12 16" fill={fill} xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d="M5.76417 6.05469C5.84269 6.05469 5.91944 6.07797 5.98473 6.12159C6.05001 6.16521 6.10089 6.22721 6.13094 6.29975C6.16099 6.37229 6.16885 6.45211 6.15353 6.52912C6.13821 6.60613 6.10041 6.67687 6.04489 6.73239C5.98937 6.7879 5.91863 6.82572 5.84162 6.84103C5.76461 6.85635 5.68479 6.84849 5.61225 6.81844C5.53971 6.7884 5.47771 6.73751 5.43409 6.67223C5.39047 6.60694 5.36719 6.53019 5.36719 6.45167C5.36719 6.34639 5.40901 6.24541 5.48346 6.17096C5.55791 6.09651 5.65889 6.05469 5.76417 6.05469" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.76384 1.6875C7.02729 1.6875 8.23899 2.1894 9.13238 3.0828C10.0258 3.97619 10.5277 5.18789 10.5277 6.45134C10.5277 8.48391 7.68208 12.3543 6.38949 14.0078C6.31523 14.1029 6.22029 14.1798 6.11189 14.2326C6.00348 14.2855 5.88445 14.313 5.76384 14.313C5.64322 14.313 5.5242 14.2855 5.41579 14.2326C5.30738 14.1798 5.21245 14.1029 5.13819 14.0078C3.8456 12.3543 1 8.48497 1 6.45134C1 5.18789 1.5019 3.97619 2.3953 3.0828C3.28869 2.1894 4.50039 1.6875 5.76384 1.6875V1.6875Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default LocationIcon;
