import { useTheme } from '@mui/styles';

const SignInIcon = (props) => {
  const theme = useTheme();

  const {
    fill = theme.palette.common.ghostlyGrey,
    stroke = theme.palette.text.secondary,
  } = props;

  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="32" height="32" rx="10.6667" fill={fill} />
      <path d="M12.25 16.0386H25.375" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M15.125 13L12 16.125L15.125 19.25" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M19.1243 19.7861V23.5361C19.1386 23.8524 19.027 24.1615 18.814 24.3958C18.601 24.63 18.3039 24.7704 17.9876 24.7861H7.76014C7.44406 24.7702 7.14715 24.6297 6.93432 24.3955C6.72148 24.1613 6.61003 23.8523 6.6243 23.5361V8.53613C6.6098 8.21991 6.72119 7.91083 6.93407 7.67656C7.14695 7.44228 7.44398 7.30189 7.76014 7.28613H17.9876C18.3039 7.3019 18.601 7.44225 18.814 7.6765C19.027 7.91076 19.1386 8.21983 19.1243 8.53613V12.2861" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  );
};

export default SignInIcon;
