import { useTheme } from '@mui/styles';

const HomeIconOutlined = (props) => {
  const { palette } = useTheme();

  const {
    fill = 'none',
    innerFill = palette.text.secondary,
  } = props;

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M20 7.91712C19.9999 7.78978 19.9707 7.66416 19.9145 7.54989C19.8583 7.43562 19.7766 7.33575 19.6758 7.25795L10.5092 0.174618C10.3633 0.0620402 10.1843 0.000976563 10 0.000976562C9.81575 0.000976562 9.63669 0.0620402 9.49083 0.174618L0.324167 7.25795C0.223363 7.33575 0.14173 7.43562 0.0855354 7.54989C0.0293411 7.66416 8.03176e-05 7.78978 0 7.91712L0 19.1671C0 19.3881 0.0877974 19.6001 0.244078 19.7564C0.400358 19.9127 0.61232 20.0004 0.833333 20.0004H7.5C7.72101 20.0004 7.93297 19.9127 8.08926 19.7564C8.24554 19.6001 8.33333 19.3881 8.33333 19.1671V15.0005C8.33333 14.5584 8.50893 14.1345 8.82149 13.8219C9.13405 13.5094 9.55797 13.3338 10 13.3338C10.442 13.3338 10.866 13.5094 11.1785 13.8219C11.4911 14.1345 11.6667 14.5584 11.6667 15.0005V19.1671C11.6667 19.3881 11.7545 19.6001 11.9107 19.7564C12.067 19.9127 12.279 20.0004 12.5 20.0004H19.1667C19.3877 20.0004 19.5996 19.9127 19.7559 19.7564C19.9122 19.6001 20 19.3881 20 19.1671V7.91712ZM18.3333 17.9171C18.3333 18.0276 18.2894 18.1336 18.2113 18.2117C18.1332 18.2899 18.0272 18.3338 17.9167 18.3338H13.75C13.6395 18.3338 13.5335 18.2899 13.4554 18.2117C13.3772 18.1336 13.3333 18.0276 13.3333 17.9171V15.0005C13.3333 14.1164 12.9821 13.2685 12.357 12.6434C11.7319 12.0183 10.8841 11.6671 10 11.6671C9.11594 11.6671 8.2681 12.0183 7.64298 12.6434C7.01786 13.2685 6.66667 14.1164 6.66667 15.0005V17.9171C6.66667 18.0276 6.62277 18.1336 6.54463 18.2117C6.46649 18.2899 6.36051 18.3338 6.25 18.3338H2.08333C1.97283 18.3338 1.86685 18.2899 1.78871 18.2117C1.71057 18.1336 1.66667 18.0276 1.66667 17.9171V8.53045C1.66608 8.46619 1.68037 8.40266 1.70842 8.34483C1.73646 8.287 1.7775 8.23645 1.82833 8.19712L9.745 2.07962C9.818 2.02313 9.90769 1.99247 10 1.99247C10.0923 1.99247 10.182 2.02313 10.255 2.07962L18.1717 8.19712C18.2225 8.23645 18.2635 8.287 18.2916 8.34483C18.3196 8.40266 18.3339 8.46619 18.3333 8.53045V17.9171Z" fill={innerFill} />
    </svg>
  );
};

export default HomeIconOutlined;
