const TabPanel = (props) => {
  const {
    children,
    currentTab,
    value,
  } = props;

  return (value === currentTab && children);
};

export default TabPanel;
