import api from 'services/api';
import END_POINTS from 'services/endPoints';

const getFlagyardUserInfo = (username) => api(
  END_POINTS.flagyardUserInfo(username),
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default getFlagyardUserInfo;
