import { useTheme } from '@mui/styles';

const OnlineGreenIcon = (props) => {
  const { palette } = useTheme();

  const {
    fill = 'none',
    rectFill = palette.common.turquoiseSeaweedGreen,
    circleFill = palette.common.turquoiseSeaweedGreen,
  } = props;

  return (
    <svg width="33" height="33" viewBox="0 0 34 35" fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect x="0.0722656" y="0.899902" width="33.686" height="33.7477" rx="16.843" fill={rectFill} fillOpacity="0.21" />
      <circle cx="16.9151" cy="17.7735" r="5.62462" fill={circleFill} />
    </svg>
  );
};
export default OnlineGreenIcon;
