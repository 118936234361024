import {
  Box,
  Card,
  CardActions,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { useAuth, useLocale, useModal } from 'util/hooks';
import Collapse from '@mui/material/Collapse';
import { useState } from 'react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { TrashIcon, EditIcon } from 'assets/icons';
import _ from 'lodash';
import MODAL_KEYS from 'assets/constants/modalKeys';
import { useCourseDetails } from 'reactQuery/queries';
import AddCourseMaterialCard from '../AddCourseMaterialCard/AddCourseMaterialCard';
import ChapterMaterialCard from '../ChapterMaterialCard/ChapterMaterialCard';

const ChapterCard = (props) => {
  const { t } = useLocale();
  const { addModal } = useModal();

  const [expanded, setExpanded] = useState(false);
  const { isAdmin, isContentCreator, currentUser } = useAuth();

  const {
    createdById = '',
    managedById = '',
    chapterDetails = {},
  } = props;

  const isAuthor = currentUser.id === createdById;
  const isManagedBy = currentUser.id === managedById;

  const canManage = isAdmin || (isContentCreator && (isAuthor || isManagedBy));

  const {
    id: chapterId = '',
    chapterNumber = 0,
    name: chapterTitle = '',
    materialsCount = 0,
    materialsDuration = 0,
    materials = [],
  } = chapterDetails || {};

  const {
    refetch: refetchCourse = () => {},
  } = useCourseDetails();

  const onToggleExpand = () => {
    setExpanded(!expanded);
  };

  const handleEditChapter = () => {
    addModal({
      key: MODAL_KEYS.courseChapter,
      props: {
        chapterDetails,
      },
    });
  };

  const handleDeleteChapter = () => {
    addModal({
      key: MODAL_KEYS.deleteChapter,
      props: {
        chapterId,
        refetchCourse,
      },
    });
  };

  const actionsList = [
    {
      label: 'delete',
      onClick: handleDeleteChapter,
      Icon: TrashIcon,
    },
    {
      label: 'edit',
      onClick: handleEditChapter,
      Icon: EditIcon,
    },
  ];

  const chapterActions = (
    <CardActions sx={{
      display: 'flex',
      justifyContent: { xs: 'start', sm: 'end' },
      alignItems: 'center',
      p: 0,
    }}
    >
      {actionsList.map(({ label, Icon, onClick }) => (
        <Box sx={{ p: 1 }} key={label}>
          <IconButton
            disableRipple
            onClick={onClick}
            aria-label={label}
            sx={{ p: 0 }}
          >
            <Icon
              width="24"
              height="24"
            />
          </IconButton>
        </Box>
      ))}
    </CardActions>
  );

  const renderChapterCardHeader = () => (
    <Box sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    }}
    >
      <Box>
        <Box>
          <Typography
            variant="bodyStandardRegular"
            color="common.couchGrey"
          >
            {`${t('common.chapter')} ${chapterNumber}:`}
          &nbsp;
          </Typography>
          <Typography
            variant="bodyStandardMedium"
            color="common.couchGrey"
          >
            {chapterTitle}
          </Typography>
        </Box>
        <Box>
          {materialsCount > 0 && (
          <Typography
            variant="bodySmallRegular"
            color="common.couchGrey"
          >
            {t('courses.materialsWithCount', { count: materialsCount })}
          </Typography>
          )}
          {/* To be updated when BE is ready - materialsDuration */}
          {materialsDuration > 0 && (
          <Typography
            variant="bodySmallRegular"
            color="common.couchGrey"
          >
            {` | ${materialsDuration}`}
          </Typography>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'end',
        }}
      >
        {canManage && chapterActions}
        <IconButton
          onClick={onToggleExpand}
          disableRipple
          sx={{
            padding: 0,
          }}
        >
          {expanded ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </Box>
    </Box>
  );

  const renderChapterCardContent = () => (
    <Stack
      pt={3}
      spacing={5}
    >
      {!_.isEmpty(materials) && materials?.map((material, index) => (
        <Box
          key={material.id}
        >
          <ChapterMaterialCard
            materialDetails={{
              ...material,
              materialNumber: index + 1,
            }}
          />
        </Box>
      ))}
      <AddCourseMaterialCard chapterId={chapterId} />
    </Stack>
  );

  return (
    <Card
      id={chapterId}
      sx={{
        p: 5,
        border: 'solid 1px',
        borderColor: 'common.silverGrey',
      }}
    >
      {renderChapterCardHeader()}
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {renderChapterCardContent()}
      </Collapse>
    </Card>
  );
};

export default ChapterCard;
