import { useTheme } from '@mui/styles';

const BackArrowIcon = (props) => {
  const { palette } = useTheme();

  const {
    fill = 'none',
    stroke = 'black',
    rectFill = palette.common.white,
  } = props;

  return (
    <svg width="18" height="16" viewBox="0 0 18 16" fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M1.5 8.00049H16.5" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.5 15.0005L16.5 8.00049L9.5 1.00049" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <g clipPath="url(#clip0_1244_6785)">
        <path d="M1.5 8.00049H16.5" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M9.5 15.0005L16.5 8.00049L9.5 1.00049" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_1244_6785">
          <rect width="16" height="16" fill={rectFill} transform="translate(17 16.0005) rotate(180)" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default BackArrowIcon;
