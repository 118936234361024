import { useLocale } from 'util/hooks';
import { Typography, Box } from '@mui/material';
import {
  CTFPrizes,
  // SkillRefinement,
  // StudentSupport,
  // ClubEncouragement,
  JobOpportunities,
  CertifiedCourses,
} from 'assets/icons/landing';

const WeProvide = () => {
  const { t } = useLocale();

  const weProvideList = [
    // {
    //   id: 'student-support',
    //   icon: StudentSupport,
    //   titleAndDescription: 'studentSupport',
    // },
    {
      id: 'challenges-and-prizes',
      icon: CTFPrizes,
      titleAndDescription: 'challengesAndPrizes',
    },
    // {
    //   id: 'skill-refinement',
    //   icon: SkillRefinement,
    //   titleAndDescription: 'skillRefinement',
    // },
    // {
    //   id: 'club-encouragement',
    //   icon: ClubEncouragement,
    //   titleAndDescription: 'clubEncouragement',
    // },
    {
      id: 'certified-courses',
      icon: CertifiedCourses,
      titleAndDescription: 'certifiedCourses',
    },
    {
      id: 'job-opportunities',
      icon: JobOpportunities,
      titleAndDescription: 'jobOpportunities',
    },
  ];

  const renderWeProvideList = weProvideList.map((item) => (
    <Box
      key={item.id}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      <Box>
        <item.icon />
      </Box>
      <Typography variant="bodyStandardMedium">
        {t(`landing.weProvideSection.title.${item.titleAndDescription}`)}
      </Typography>
      <Typography variant="bodySmallRegular" mt={2}>
        {t(`landing.weProvideSection.description.${item.titleAndDescription}`)}
      </Typography>
    </Box>
  ));

  return (
    <Box sx={{
      width: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      justifyContent: 'start',
    }}
    >
      <Typography variant="h5">
        {t('landing.weProvideSection.cyberHubProvides')}
      </Typography>

      <Box
        width={1}
        sx={{
          mt: 8,
          display: 'grid',
          justifyContent: {
            xs: 'center',
            sm: 'space-around',
            md: 'space-between',
          },
          rowGap: 10,
          gridTemplateColumns: {
            xs: 'repeat(1, minmax(auto, 220px))',
            sm: 'repeat(2, minmax(auto, 220px))',
            md: 'repeat(3, minmax(auto, 220px))',
          },
        }}
      >
        {renderWeProvideList}
      </Box>
    </Box>
  );
};

export default WeProvide;
