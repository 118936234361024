import { Typography } from '@mui/material';
import { useLocale } from 'util/hooks';

const ComingSoon = () => {
  const { t } = useLocale();

  return (
    <Typography
      variant="h1"
      style={{
        background: '-webkit-linear-gradient(130.16deg, #93D29E 0%, #72C0C3 97.91%)',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
      }}
    >
      {t('common.comingSoon')}
    </Typography>
  );
};

export default ComingSoon;
