import React, { useState } from 'react';

import {
  format as _format,
  formatDistance as _formatDistance,
  add as _add,
  isAfter as _isAfter,
  isBefore as _isBefore,
} from 'date-fns';

const defaults = {
  format: 'dd/MM/yyyy',
};

/**
 * Dates and date manipulation without the hassle of external imports
 * https://date-fns.org/v2.29.1/docs/Getting-Started
 */
const useDate = (props = {}) => {
  const {
    initialDate = new Date(),
    clockMode = false, // enables live time mode
    clockPeriod = 1000, // updates time every ...(ms)
  } = props;

  const [now, setNow] = useState(initialDate || new Date());

  // Updates now
  React.useEffect(() => {
    let interval;
    if (clockMode) {
      interval = setInterval(() => {
        setNow(new Date());
      }, clockPeriod);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [clockMode, clockPeriod]);

  const format = React.useCallback((
    date = now,
    fo = defaults.format,
    options = {},
  ) => _format(new Date(date), fo, options), [now]);

  // Calculates diff in time between date and baseDate, defaults to now
  const diff = React.useCallback((
    date = now,
    baseDate = now,
    options = {},
  ) => _formatDistance(new Date(date), new Date(baseDate), options), [now]);

  // Calculates diff in time between date given and now, now being the base date
  const fromNow = React.useCallback((
    date = now,
    options = {},
  ) => {
    const combinedOptions = {
      addSuffix: true, // show 'in' and 'ago'
      ...options,
    };
    return diff(
      new Date(date),
      now,
      combinedOptions,
    );
  }, [diff, now]);

  /**
   * Add any duration, defaults to now and 0
   * duration = {
      years: 0,
      months: 0,
      weeks: 0,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    }
 */
  const add = React.useCallback((
    date = now,
    duration = {},
  ) => _add(new Date(date), duration), [now]);

  // Is the first date after the second one?
  const isAfter = React.useCallback((
    date,
    dateToCompare = now,
  ) => _isAfter(new Date(date), new Date(dateToCompare)), [now]);

  // Is the first date before the second one?
  const isBefore = React.useCallback((
    date,
    dateToCompare = now,
  ) => _isBefore(new Date(date), new Date(dateToCompare)), [now]);

  return {
    yesterday: add(now, { days: -1 }),
    now,
    tomorrow: add(now, { days: 1 }),
    format,
    diff,
    fromNow,
    add,
    isAfter,
    isBefore,
  };
};

export default useDate;
