import { useTheme } from '@mui/styles';

const LeaderboardIcon = (props) => {
  const { palette } = useTheme();

  const {
    fill = palette.common.ghostlyGrey,
    stroke = palette.text.secondary,
    ...rest
  } = props;

  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <rect width="32" height="32" rx="10.6667" fill={fill} />
      <path d="M7.25 14.3333H10.5833V23.5H7.25C7.02899 23.5 6.81703 23.4122 6.66074 23.2559C6.50446 23.0996 6.41667 22.8877 6.41667 22.6667V15.1667C6.41667 14.9457 6.50446 14.7337 6.66074 14.5774C6.81702 14.4211 7.02899 14.3333 7.25 14.3333Z" stroke={stroke} strokeWidth="1.25" />
      <path d="M21.4167 17.6667H24.75C24.971 17.6667 25.183 17.7545 25.3393 17.9107L25.6339 17.6161L25.3393 17.9107C25.4955 18.067 25.5833 18.279 25.5833 18.5V22.6667C25.5833 22.8877 25.4955 23.0996 25.3393 23.2559C25.183 23.4122 24.971 23.5 24.75 23.5H21.4167V17.6667Z" stroke={stroke} strokeWidth="1.25" />
      <path d="M13.5 8.5H18.5C18.721 8.5 18.933 8.5878 19.0893 8.74408L19.3839 8.44945L19.0893 8.74408C19.2455 8.90036 19.3333 9.11232 19.3333 9.33334V23.5H12.6667V9.33334C12.6667 9.11232 12.7545 8.90036 12.9107 8.74408C13.067 8.5878 13.279 8.5 13.5 8.5Z" stroke={stroke} strokeWidth="1.25" />
    </svg>
  );
};

export default LeaderboardIcon;
