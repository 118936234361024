import END_POINTS from '../endPoints';
import METHODS from '../methods';
import api from '../api';

const editCourseMaterial = (data, courseId, chapterId, materialId) => api(
  END_POINTS.courseMaterial(courseId, chapterId, materialId),
  {
    method: METHODS.PUT,
    data,
  },
)
  .then((response) => Promise.resolve(response))
  .catch((error) => Promise.reject(error));

export default editCourseMaterial;
