import { useTheme } from '@mui/styles';

const UsersFilledIcon = (props) => {
  const { palette } = useTheme();

  const {
    fill = 'none',
    rectFill = palette.common.offWhite,
    innerFill = palette.text.secondary,
  } = props;

  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="32" height="32" rx="10.6667" fill={rectFill} />
      <g clipPath="url(#clip0_1992_84978)">
        <path d="M8.8125 20.3926C7.26167 20.3926 6 19.1317 6 17.5801C6 16.0292 7.26167 14.7676 8.8125 14.7676C10.3633 14.7676 11.625 16.0292 11.625 17.5801C11.625 19.1317 10.3633 20.3926 8.8125 20.3926ZM8.8125 16.0184C7.95083 16.0184 7.25 16.7192 7.25 17.5809C7.25 18.4426 7.95083 19.1434 8.8125 19.1434C9.67417 19.1434 10.375 18.4417 10.375 17.5801C10.375 16.7192 9.67417 16.0184 8.8125 16.0184Z" fill={innerFill} />
        <path d="M23.1875 20.3926C21.6367 20.3926 20.375 19.1309 20.375 17.5801C20.375 16.0292 21.6367 14.7676 23.1875 14.7676C24.7383 14.7676 26 16.0292 26 17.5801C26 19.1317 24.7383 20.3926 23.1875 20.3926ZM23.1875 16.0184C22.3258 16.0184 21.625 16.7192 21.625 17.5809C21.625 18.4426 22.3258 19.1434 23.1875 19.1434C24.0492 19.1434 24.75 18.4417 24.75 17.5801C24.75 16.7192 24.0492 16.0184 23.1875 16.0184Z" fill={innerFill} />
        <path d="M20.9972 26.0184C20.6864 26.0184 20.4214 25.7851 20.3797 25.4759C20.0855 23.2917 18.2047 21.6451 16.003 21.6451C15.8064 21.6451 15.6072 21.6584 15.4105 21.6851C13.443 21.9501 11.8839 23.5092 11.6189 25.4767C11.5772 25.7859 11.3114 26.0184 10.9997 26.0184C10.9714 26.0184 10.9422 26.0167 10.913 26.0126C10.7505 25.9909 10.6039 25.9059 10.5022 25.7726C10.4014 25.6401 10.3572 25.4759 10.3797 25.3101C10.513 24.3209 10.9064 23.3867 11.5214 22.5951C10.9689 21.6334 9.92385 21.0217 8.80635 21.0217C8.38552 21.0217 7.97469 21.1067 7.58469 21.2734C7.37552 21.3634 7.17552 21.4759 6.98969 21.6092C6.88219 21.6859 6.75635 21.7267 6.62469 21.7267C6.42385 21.7267 6.23469 21.6292 6.11719 21.4667C6.01969 21.3309 5.98052 21.1651 6.00719 21.0001C6.03385 20.8351 6.12385 20.6909 6.25969 20.5934C6.51802 20.4076 6.79802 20.2492 7.09219 20.1234C7.63885 19.8892 8.21635 19.7701 8.80719 19.7701C10.2605 19.7701 11.613 20.4909 12.423 21.6742C13.2372 21.0076 14.2047 20.5851 15.2439 20.4451C15.4955 20.4109 15.7514 20.3934 16.0047 20.3934C17.3114 20.3934 18.5664 20.8442 19.578 21.6692C20.0647 20.9542 20.743 20.4101 21.558 20.0834C22.0814 19.8742 22.6289 19.7676 23.1855 19.7676C23.7764 19.7676 24.3555 19.8867 24.9055 20.1226C25.1997 20.2484 25.4797 20.4067 25.7389 20.5926C26.018 20.7934 26.0822 21.1851 25.8814 21.4651C25.7647 21.6284 25.5747 21.7251 25.3739 21.7251C25.2422 21.7251 25.1164 21.6842 25.0089 21.6076C24.8239 21.4751 24.6239 21.3617 24.4139 21.2717C24.0205 21.1034 23.6072 21.0184 23.1855 21.0184C22.788 21.0184 22.3964 21.0942 22.0222 21.2442C21.3689 21.5059 20.8264 21.9809 20.4772 22.5917C21.0914 23.3809 21.4839 24.3159 21.618 25.3084C21.6639 25.6501 21.4239 25.9651 21.0822 26.0109C21.0547 26.0159 21.0255 26.0184 20.9972 26.0184Z" fill={innerFill} />
        <path d="M16 20.3927C14.1042 20.3927 12.5625 18.8502 12.5625 16.9552C12.5625 15.0602 14.1042 13.5186 16 13.5186C17.895 13.5186 19.4375 15.0602 19.4375 16.9561C19.4375 18.8519 17.895 20.3927 16 20.3927ZM16 14.7686C14.7942 14.7686 13.8125 15.7494 13.8125 16.9561C13.8125 18.1627 14.7942 19.1436 16 19.1436C17.2067 19.1436 18.1875 18.1627 18.1875 16.9561C18.1875 15.7494 17.2058 14.7686 16 14.7686Z" fill={innerFill} />
      </g>
      <defs>
        <clipPath id="clip0_1992_84978">
          <rect width="20" height="20" fill="white" transform="translate(6 6)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default UsersFilledIcon;
