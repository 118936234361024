import {
  Typography, Box,
} from '@mui/material';
import { Stepper } from 'components/molecules';
import {
  RegistrationStepTwo,
  RegistrationStepOne,
  RegistrationStepThree,
} from 'components/organisms';
import { useState } from 'react';
import { useRecoilState } from 'recoil';
import { registrationInfo } from 'recoil/atoms';
import { useLocale } from 'util/hooks';

const RegistrationForms = (props) => {
  const [userInfo, setUserInfo] = useRecoilState(registrationInfo);
  const [activeStep, setActiveStep] = useState(0);
  const { t } = useLocale();

  const steps = [
    t('registration.stepperSteps.university'),
    t('registration.stepperSteps.verifyEmail'),
    t('registration.stepperSteps.completeInformation')];

  const handleNextStep = () => {
    setActiveStep((step) => step + 1);
  };
  const getPages = (index) => {
    let currentPage = (
      <RegistrationStepOne
        userInfo={userInfo}
        setUserInfo={setUserInfo}
        handleNextStep={handleNextStep}
      />
    );
    if (index === 1) {
      currentPage = (
        <RegistrationStepTwo
          userInfo={userInfo}
          setUserInfo={setUserInfo}
          handleNextStep={handleNextStep}
        />
      );
    }
    if (index === 2) {
      currentPage = (
        <RegistrationStepThree
          userInfo={userInfo}
        />
      );
    }
    return currentPage;
  };
  return (
    <>
      <Typography
        variant="h4"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          mb: 8,
          py: 6,
        }}
      >
        {t('registration.signupMessage')}
      </Typography>
      <Box>
        <Stepper
          steps={steps}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          renderPages={getPages}
          stepperProps={{
            alternativeLabel: true,
            sx: { mb: 10 },
          }}
        />
      </Box>
    </>
  );
};

export default RegistrationForms;
