import api from 'services/api';
import END_POINTS from 'services/endPoints';

const getUserCertificateDetails = (certificateId) => api(
  END_POINTS.userCertificate(certificateId),
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default getUserCertificateDetails;
