import { useState } from 'react';
import { isEmpty } from 'lodash';

import {
  Box, Stack, Typography,
} from '@mui/material';

import { CourseCard } from 'components/organisms';
import { useLocale } from 'util/hooks';
import { PATHS } from 'routes';
import { DataPlaceholder, HorizontalScrollContainer } from 'components/molecules';
import { NoCoursesIcon } from 'assets/icons';
import { usePublicCourses } from 'reactQuery/queries';

import { managementModeAtom } from 'recoil/atoms';
import { useSetRecoilState } from 'recoil';
import LandingSectionWrapper from '../helpers/LandingSectionWrapper';
import LandingLinkWrapper from '../helpers/LandingLinkWrapper';

const UpcomingCourses = () => {
  const setManagementMode = useSetRecoilState(managementModeAtom);

  const { t } = useLocale();
  const [coursesState, setCoursesState] = useState({
    sectionHeader: 'landing.upcomingCourseSection.headerUpcoming',
    searchOptions: {
      upcoming: true,
      isRecorded: false,
    },
  });

  const {
    data: { items: upcomingCourses = [] } = {},
    isFetched = false,
  } = usePublicCourses({
    queryKeyOptions: {
      searchOptions: coursesState.searchOptions,
      pagination: {
        limit: 3,
      },
    },
  });

  // If there are no upcoming courses, fetch recorded ones
  if (isFetched && coursesState.searchOptions.upcoming && !upcomingCourses?.length) {
    setCoursesState({
      sectionHeader: 'landing.upcomingCourseSection.headerRecent',
      searchOptions: {
        upcoming: false,
        isRecorded: true,
      },
    });

    return null;
  }

  const renderCourseList = () => {
    if (!upcomingCourses.length) {
      return (
        <DataPlaceholder
          icon={<NoCoursesIcon />}
          header={t('courses.noCourses')}
        />
      );
    }

    const courses = upcomingCourses?.map((course) => (
      <CourseCard
        key={course.id}
        courseDetails={course}
      />
    ));

    return (
      <HorizontalScrollContainer>
        {courses}
      </HorizontalScrollContainer>
    );
  };

  return (
    <Stack>
      <LandingSectionWrapper width={1}>
        <Typography variant="h5">
          {t(coursesState.sectionHeader)}
        </Typography>

        <Box sx={{ pt: 4 }}>
          <Typography variant="bodyStandardSmall">
            {t('landing.upcomingCourseSection.subHeader')}
          </Typography>
        </Box>
      </LandingSectionWrapper>

      <Box sx={{
        pt: { xs: 6, sm: 7.5 },
        width: 1,
        display: 'flex',
        justifyContent: 'center',
      }}
      >
        {renderCourseList()}
      </Box>

      {
        !isEmpty(upcomingCourses) && (
          <LandingLinkWrapper
            onClick={() => setManagementMode(false)}
            to={PATHS.courses}
            innerText={t('common.exploreMore')}
          />
        )
      }
    </Stack>
  );
};

export default UpcomingCourses;
