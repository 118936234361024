import {
  BookmarkFilledIcon,
  BookmarkOutlinedIcon,
  LikeFilledIcon,
  LikeOutlinedIcon,
  OpenEyeIcon,
  ShareIcon,
  ArticlePostIcon,
  KnowledgePostIcon,
  VideoPostIcon,
  CertificatePostHeaderIcon,
} from 'assets/icons';

import { PATHS } from 'routes';

export const POST_ACTIONS = [
  {
    key: 'bookmark',
    type: 'bookmark',
    iconShapeFilled: <BookmarkFilledIcon />,
    iconShapeOutlined: <BookmarkOutlinedIcon />,
  },
  {
    key: 'like',
    type: 'like',
    iconShapeFilled: <LikeFilledIcon />,
    iconShapeOutlined: <LikeOutlinedIcon />,
  },
  {
    key: 'share',
    type: 'share',
    iconShapeOutlined: <ShareIcon />,
  },
  {
    key: 'view',
    type: 'view',
    iconShapeOutlined: <OpenEyeIcon fill="#C8C8C8" />,
  },

];

export const getPostActionByType = (type) => POST_ACTIONS
  .find((action) => action.type === String(type));

export const POST_STATUSES = {
  DRAFT: 'draft',
  UNDER_REVIEW: 'underReview',
  PUBLISHED: 'published',
  UNPUBLISHED: 'unpublished',
  REVISED: 'revised',
  REJECTED: 'rejected',
};

export const POST_TYPES = {
  article: 'article',
  video: 'video',
  knowledgeShare: 'knowledgeShare',
  certificate: 'certificate',
};

export const POST_TYPES_DETAILS = [
  {
    key: POST_TYPES.article,
    shortKey: POST_TYPES.article,
    icon: ArticlePostIcon,
    smallIcon: <ArticlePostIcon width="20" height="20" />,
    localizationKey: 'posts.article',
    to: `/${PATHS.article}`,
    divider: true,
    creatable: true,
    description: [
      { text: 'postsTypesList.description.article1' },
      { text: 'postsTypesList.description.article2' },
    ],
  },
  {
    key: POST_TYPES.video,
    shortKey: POST_TYPES.video,
    icon: VideoPostIcon,
    smallIcon: <VideoPostIcon width="20" height="20" />,
    localizationKey: 'posts.video',
    to: `/${PATHS.video}`,
    divider: true,
    creatable: true,
    description: [
      { text: 'postsTypesList.description.video1' },
      { text: 'postsTypesList.description.video2' },
      { text: 'postsTypesList.description.video3' },
    ],
  },
  {
    key: POST_TYPES.knowledgeShare,
    shortKey: 'knowledge',
    icon: KnowledgePostIcon,
    smallIcon: <KnowledgePostIcon width="20" height="20" />,
    localizationKey: 'posts.knowledge',
    to: `/${PATHS.shareKnowledge}`,
    divider: false,
    creatable: true,
    description:
      [
        { text: 'postsTypesList.description.knowledge1' },
        { text: 'postsTypesList.description.knowledge2' },
      ],
  },
  {
    key: POST_TYPES.certificate,
    shortKey: 'certificate',
    icon: CertificatePostHeaderIcon,
    smallIcon: <CertificatePostHeaderIcon width="20" height="20" />,
    localizationKey: 'posts.certificate',
    to: undefined,
    divider: false,
    creatable: false,
    description: [],
  },
];

export const getPostTypeByKey = (key) => POST_TYPES_DETAILS
  .find((postType) => postType.key === String(key));
