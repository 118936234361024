import { useTheme } from '@mui/styles';

const UsersIcon = (props) => {
  const { palette } = useTheme();

  const {
    fill = palette.common.couchGrey,
  } = props;

  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M2.25 8.49951C1.00933 8.49951 0 7.49085 0 6.24951C0 5.00885 1.00933 3.99951 2.25 3.99951C3.49067 3.99951 4.5 5.00885 4.5 6.24951C4.5 7.49085 3.49067 8.49951 2.25 8.49951ZM2.25 5.00018C1.56067 5.00018 1 5.56085 1 6.25018C1 6.93951 1.56067 7.50018 2.25 7.50018C2.93933 7.50018 3.5 6.93885 3.5 6.24951C3.5 5.56085 2.93933 5.00018 2.25 5.00018Z" fill={fill} />
      <path d="M13.75 8.49951C12.5093 8.49951 11.5 7.49018 11.5 6.24951C11.5 5.00885 12.5093 3.99951 13.75 3.99951C14.9907 3.99951 16 5.00885 16 6.24951C16 7.49085 14.9907 8.49951 13.75 8.49951ZM13.75 5.00018C13.0607 5.00018 12.5 5.56085 12.5 6.25018C12.5 6.93951 13.0607 7.50018 13.75 7.50018C14.4393 7.50018 15 6.93885 15 6.24951C15 5.56085 14.4393 5.00018 13.75 5.00018Z" fill={fill} />
      <path d="M11.9985 13.0002C11.7499 13.0002 11.5379 12.8135 11.5045 12.5662C11.2692 10.8188 9.76453 9.50151 8.0032 9.50151C7.84586 9.50151 7.68653 9.51218 7.5292 9.53351C5.9552 9.74551 4.70787 10.9928 4.49587 12.5668C4.46253 12.8142 4.24987 13.0002 4.00053 13.0002C3.97787 13.0002 3.95453 12.9988 3.9312 12.9955C3.8012 12.9782 3.68387 12.9102 3.60253 12.8035C3.52187 12.6975 3.48653 12.5662 3.50453 12.4335C3.6112 11.6422 3.92587 10.8948 4.41787 10.2615C3.97587 9.49218 3.13987 9.00284 2.24587 9.00284C1.9092 9.00284 1.58053 9.07084 1.26853 9.20418C1.1012 9.27618 0.941198 9.36618 0.792532 9.47285C0.706532 9.53418 0.605865 9.56684 0.500532 9.56684C0.339865 9.56684 0.188532 9.48884 0.0945317 9.35884C0.0165317 9.25018 -0.0148016 9.11751 0.00653174 8.98551C0.0278651 8.85351 0.0998651 8.73818 0.208532 8.66018C0.415198 8.51151 0.639198 8.38484 0.874532 8.28418C1.31187 8.09685 1.77386 8.00151 2.24653 8.00151C3.4092 8.00151 4.4912 8.57818 5.1392 9.52484C5.79053 8.99151 6.56453 8.65351 7.39587 8.54151C7.5972 8.51418 7.80186 8.50018 8.00453 8.50018C9.04986 8.50018 10.0539 8.86084 10.8632 9.52084C11.2525 8.94884 11.7952 8.51351 12.4472 8.25218C12.8659 8.08484 13.3039 7.99951 13.7492 7.99951C14.2219 7.99951 14.6852 8.09485 15.1252 8.28351C15.3605 8.38418 15.5845 8.51084 15.7919 8.65951C16.0152 8.82018 16.0665 9.13351 15.9059 9.35751C15.8125 9.48818 15.6605 9.56551 15.4999 9.56551C15.3945 9.56551 15.2939 9.53284 15.2079 9.47151C15.0599 9.36551 14.8999 9.27485 14.7319 9.20284C14.4172 9.06818 14.0865 9.00018 13.7492 9.00018C13.4312 9.00018 13.1179 9.06084 12.8185 9.18084C12.2959 9.39018 11.8619 9.77018 11.5825 10.2588C12.0739 10.8902 12.3879 11.6382 12.4952 12.4322C12.5319 12.7055 12.3399 12.9575 12.0665 12.9942C12.0445 12.9982 12.0212 13.0002 11.9985 13.0002Z" fill={fill} />
      <path d="M8 8.49933C6.48333 8.49933 5.25 7.26533 5.25 5.74933C5.25 4.23333 6.48333 3 8 3C9.516 3 10.75 4.23333 10.75 5.75C10.75 7.26667 9.516 8.49933 8 8.49933ZM8 4C7.03533 4 6.25 4.78467 6.25 5.75C6.25 6.71533 7.03533 7.5 8 7.5C8.96533 7.5 9.75 6.71533 9.75 5.75C9.75 4.78467 8.96467 4 8 4Z" fill={fill} />
    </svg>
  );
};

export default UsersIcon;
