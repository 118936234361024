import React, { forwardRef } from 'react';
import {
  Menu,
  Button,
  MenuItem,
} from '@mui/material';

import { ExpandMore } from '@mui/icons-material';
import { Controller } from 'react-hook-form';

const ClubDomainsList = forwardRef((props, ref) => {
  const {
    clubDomains = [],
    onChange = () => { },
    ...rest
  } = props;

  const defaultDomain = clubDomains[0];
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleChange = (domain) => {
    onChange(domain);
    handleCloseMenu();
  };

  return (
    <Controller
      name={rest.name}
      control={rest.control}
      render={({ field: { value } }) => (
        <>
          <Button
            aria-controls={anchorEl ? 'menu' : undefined}
            aria-haspopup="true"
            onClick={handleOpenMenu}
            sx={{
              p: 0,
              gap: 2,
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
          >
            {`@${value?.domain || defaultDomain}`}
            <ExpandMore sx={{ color: 'grey.600' }} />
          </Button>
          <Menu
            id="menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
            sx={{ '& .MuiPaper-root': { width: 200 } }}
            TransitionProps={{
              timeout: 0,
            }}
          >
            {clubDomains?.map((domain) => (
              <MenuItem
                ref={ref}
                dir="ltr"
                key={domain}
                value={domain}
                onClick={() => handleChange(domain)}
              >
                {`@${domain}`}
              </MenuItem>
            ))}
          </Menu>
        </>
      )}
    />
  );
});

export default ClubDomainsList;
