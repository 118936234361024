import api from 'services/api';
import END_POINTS from 'services/endPoints';
import METHODS from 'services/methods';

const transferUniversityForStudent = (data, username) => api(
  END_POINTS.transferUniversityForStudent(username),
  {
    method: METHODS.PUT,
    data,
  },
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default transferUniversityForStudent;
