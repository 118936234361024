import { getSessionRecording } from 'services';
import { useCustomQuery } from 'reactQuery';

const useSessionRecordings = (queryProps = {}) => {
  const { courseId, sessionId, ...rest } = queryProps;

  const enabled = !!courseId && !!sessionId;

  const query = useCustomQuery({
    queryKey: [`session-recordings-course${courseId}-session${sessionId}`],
    queryFn: () => getSessionRecording(courseId, sessionId),
    options: { enabled },
    ...rest,
  });

  return { ...query };
};

export default useSessionRecordings;
