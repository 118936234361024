import {
  Avatar, Box, Link, Stack, Typography,
} from '@mui/material';
import { useTheme } from '@mui/styles';
import { PointsWithShadowIcon } from 'assets/icons';

const LeaderboardListItem = (props) => {
  const { palette } = useTheme();
  const {
    rank = '-',
    name = '',
    avatar = '',
    points = '',
    username = '',
    id = '',
    sx = {},
    ...rest
  } = props;

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        backgroundColor: 'common.white',
        borderRadius: 1,
        px: 6,
        py: 3,
        ...sx,
      }}
      {...rest}
    >
      <Stack
        spacing={7}
        direction="row"
        alignItems="center"
      >
        <Box minWidth={17}>
          <Typography variant="bodyStandardMedium">
            {Number.isInteger(rank)
              ? rank
              : '-'}
          </Typography>
        </Box>
        <Stack
          direction="row"
          alignItems="center"
        >
          <Avatar
            src={avatar}
            sx={{ mr: 2, width: 32, height: 32 }}
          />
          <Typography
            variant="bodyStandardMedium"
            color={palette.common.darkGray}
            component={Link}
            to={username ? `/profile/${username}` : `/club-profile/${id}`}
          >
            {name}
          </Typography>
        </Stack>
      </Stack>
      <Stack
        minWidth={60}
        spacing={2}
        direction="row"
        alignItems="center"
        sx={{
          textAlign: 'right',
        }}
      >
        <PointsWithShadowIcon
          width="20"
          height="20"
          innerFill={palette.common.vividOrange}
          rectFill={palette.common.vividOrange}
        />
        <Typography>
          {points || 0}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default LeaderboardListItem;
