import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { useParams } from 'react-router-dom';

import {
  Box,
  Stack,
  Button,
  Typography,
  InputLabel,
} from '@mui/material';

import {
  FormAutocomplete,
  FormTextField,
  FormUploadMedia,
} from 'components/form';

import {
  useLocale,
  useSnackbar,
  useFastForm,
} from 'util/hooks';
import { createCourseMaterial, editCourseMaterial } from 'services';
import MEDIA_TYPES from 'assets/constants/media';
import { useCourseChapters, useCourseDetails } from 'reactQuery/queries';
import { COURSE_MATERIAL_TYPES } from 'assets/constants/courses';
import { getDirtyObject } from 'util/helpers';

const CourseAttachmentDetailsForm = (props) => {
  const {
    chapterId = '',
    createMode = false,
    materialDetails = {},
    handleClickCancel = () => {},
  } = props;

  const { t } = useLocale();
  const snack = useSnackbar();
  const { courseId } = useParams();

  const {
    data: courseChapters = [],
  } = useCourseChapters();

  const chapter = courseChapters.find((ch) => ch.id === chapterId);

  const {
    title = '',
    attachment = {},
    id: materialId = '',
  } = materialDetails || {};

  const {
    refetch: refetchCourse = () => {},
  } = useCourseDetails();

  const onSubmit = async (values) => {
    let message = '';

    const payload = {
      ...values,
      chapter: { id: Number(values.chapter.id) },
      type: COURSE_MATERIAL_TYPES.attachment,
    };

    try {
      if (createMode) {
        await createCourseMaterial(payload, courseId, chapterId);
        message = t('courses.attachmentCreated');
      } else {
        const dirtyValues = getDirtyObject(payload, defaultValues);

        await editCourseMaterial(dirtyValues, courseId, chapterId, materialId);
        message = t('courses.attachmentUpdated');
      }

      handleClickCancel();
      refetchCourse();
      snack({
        severity: 'success',
        message,
      });
    } catch (error) {
      error.errors?.forEach((err) => {
        setError(err.property, {
          type: 'api',
          message: err.message,
        });

        if (!err.property) {
          snack({
            severity: 'error',
            message: err.message || t('common.somethingWrong'),
          });
        }
      });
    }
  };

  const defaultValues = {
    chapter,
    title,
    attachment,
  };

  const validationSchema = Yup.object().shape(
    {
      chapter: Yup
        .object()
        .nullable(true)
        .required(t('courses.chapterRequired')),
      title: Yup
        .string()
        .required(t('courses.attachmentTitleRequired')),
      attachment: Yup
        .object()
        .nullable()
        .required(t('courses.attachmentRequired')),
    },
  );

  const {
    control,
    setError,
    getValues,
    handleSubmit,
    formState: {
      errors,
      isDirty,
      isSubmitting,
    },
  } = useFastForm({
    defaultValues,
    validationSchema,
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box
        sx={{
          mb: 12,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Typography variant="h5">
          {createMode ? t('courses.newAttachment')
            : t('courses.updateAttachment') }
        </Typography>
      </Box>
      <Stack spacing={7}>
        <Box>
          <FormAutocomplete
            name="chapter"
            control={control}
            label={t('common.chapter')}
            fullWidth
            withOptions
            options={courseChapters}
          />
        </Box>
        <Box>
          <FormTextField
            name="title"
            control={control}
            label={t('courses.attachmentTitle')}
            disabled={isSubmitting}
            fullWidth
          />
        </Box>
        <Box>
          <InputLabel sx={(theme) => ({
            ...theme.typography.bodySmallRegular,
            color: 'secondary.main',
            mb: 2,
            pl: 3,
          })}
          >
            {t('courses.uploadAttachment')}
          </InputLabel>
          <FormUploadMedia
            name="attachment"
            control={control}
            setError={(errorProps) => setError('attachment', errorProps)}
            src={getValues('attachment')?.fileName}
            type={MEDIA_TYPES.attachment}
            disabled={isSubmitting}
            helperText={(
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
              }}
              >
                <Typography variant="bodySmallRegular">
                  {t('common.attachmentDescription')}
                </Typography>
              </Box>
            )}
          />
          <Box sx={{ pl: 3 }}>
            <Typography
              variant="bodySmallRegular"
              color="common.passionFruitRed"
            >
              {errors?.attachment && errors?.attachment?.message}
            </Typography>
          </Box>
        </Box>
      </Stack>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          mt: 10,
        }}
      >
        <Button
          onClick={handleClickCancel}
          variant="outlined"
          size="regular"
          color="secondary"
        >
          {t('common.cancel')}
        </Button>
        <LoadingButton
          type="submit"
          size="regular"
          loading={isSubmitting}
          disabled={isSubmitting || !isDirty}
          variant="contained"
          sx={{ ml: 5 }}
        >
          {createMode ? t('common.create') : t('common.update')}
        </LoadingButton>
      </Box>
    </form>
  );
};

export default CourseAttachmentDetailsForm;
