import END_POINTS from 'services/endPoints';
import api from 'services/api';
import METHODS from 'services/methods';

const cancelCourse = (id) => api(
  END_POINTS.cancelCourse(id),
  {
    method: METHODS.POST,
  },
)
  .then((response) => Promise.resolve(response))
  .catch((error) => Promise.reject(error));

export default cancelCourse;
