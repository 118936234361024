import { getProfileCertificates } from 'services';
import { useCustomQuery } from 'reactQuery';
import { useParams } from 'react-router-dom';

const useProfileCertificates = () => {
  const { username } = useParams();

  const query = useCustomQuery({
    queryKey: [`user-profile-certificates-${username}`],
    queryFn: () => getProfileCertificates(username),
  });

  return { ...query };
};

export default useProfileCertificates;
