import { useTheme } from '@mui/styles';

const ArrowIcon = (props) => {
  const { palette } = useTheme();

  const {
    fill = palette.text.secondary,
  } = props;
  return (
    <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M1.20413 13.4007C1.09528 13.4007 0.992425 13.3582 0.915689 13.2809C0.756775 13.1219 0.756775 12.8627 0.915689 12.7032L6.74979 6.86732L0.915689 1.03087C0.838409 0.954107 0.795959 0.851207 0.795959 0.742318C0.795959 0.633429 0.838409 0.530529 0.915689 0.453762C0.992425 0.376451 1.09528 0.333984 1.20413 0.333984C1.31297 0.333984 1.41583 0.376451 1.49257 0.453762L7.32666 6.29021C7.64449 6.60816 7.64504 7.12593 7.32721 7.44388L1.49257 13.2809C1.41583 13.3582 1.31297 13.4007 1.20413 13.4007Z" fill={fill} />
    </svg>
  );
};

export default ArrowIcon;
