import { useAuth } from 'util/hooks';
import { NafathModal } from 'components/modals';
import { useGeneralSettings } from 'reactQuery/queries';

import SETTINGS_KEYS from 'assets/constants/settingsKeys';

const NafathContainer = () => {
  const {
    currentUser,
    isStudent,
  } = useAuth();

  const {
    data = [],
  } = useGeneralSettings({
    options: {
      suspense: false, // Don't suspense to avoid loading overhead
    },
  });

  const nafath = data?.find((setting) => setting.key === SETTINGS_KEYS.nafathVerification);
  const isForceNafath = nafath?.value || false;

  const showNafathModal = isStudent && (!currentUser.nafathVerified && isForceNafath);

  return showNafathModal && <NafathModal />;
};

export default NafathContainer;
