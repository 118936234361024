import React from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  Box,
  Tab,
  Tabs,
} from '@mui/material';

import { useLocale } from 'util/hooks';
import { useCourseTracks } from 'reactQuery/queries';
import { capitalizeFirstLetter } from 'util/helpers';

const mapKeyToTracks = (value) => {
  if (value === 'all') {
    return '';
  }
  return value;
};

const PublicCoursesTabs = () => {
  const { t, isAr } = useLocale();
  const [, startTransition] = React.useTransition();
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    data = [],
  } = useCourseTracks();

  const quickFilter = searchParams.get('_quickFilter');
  const initialSelected = mapKeyToTracks(quickFilter)
    ? quickFilter
    : 'all';
  const [selected, setSelected] = React.useState(initialSelected);

  const handleChange = (event, newValue) => {
    const hasSearchParams = !!searchParams?.toString()?.length;
    setSelected(newValue);

    startTransition(() => {
      const track = mapKeyToTracks(newValue);

      if (!track) {
        searchParams.delete('_quickFilter');
        searchParams.delete('filter.track.id');
      } else {
        searchParams.set('_quickFilter', newValue);
        searchParams.set('filter.track.id', track);
      }

      setSearchParams(searchParams, { replace: hasSearchParams });
    });
  };

  return (
    <Box sx={{
      borderBottom: 1,
      borderColor: 'divider',
      maxWidth: {
        xs: 320,
        sm: 1,
      },
    }}
    >
      <Tabs
        onChange={handleChange}
        value={selected}
        variant="scrollable"
        allowScrollButtonsMobile
      >
        <Tab
          key="all"
          value="all"
          label={t('common.all')}
        />
        {data?.map((tab) => (
          <Tab
            key={tab.id}
            value={tab.id.toString()}
            label={isAr ? tab.nameAr : capitalizeFirstLetter(tab.nameEn)}
          />
        ))}
      </Tabs>
    </Box>
  );
};

export default PublicCoursesTabs;
