const MODAL_KEYS = {
  userStatus: 'userStatus',
  userInvitation: 'userInvitation',
  joinUs: 'joinUs',
  platformSettings: 'platformSettings',
  studentManagement: 'studentManagement',
  courseAction: 'courseAction',
  rejectPost: 'rejectPost',
  approvePost: 'approvePost',
  revisePost: 'revisePost',
  deleteChapter: 'deleteChapter',
  deleteMaterial: 'deleteMaterial',
  courseAttachment: 'courseAttachment',
  courseVideo: 'courseVideo',
  courseText: 'courseText',
  sessionDetails: 'sessionDetails',
  deleteSession: 'deleteSession',
  studentCourseActions: 'studentCourseActions',
  updateSessionRecording: 'updateSessionRecording',
  courseAttendanceAction: 'courseAttendanceAction',
  confirmAddUserCertificate: 'confirmAddUserCertificate',
  deleteUserCertificate: 'deleteUserCertificate',
  verifyUserCertificate: 'verifyUserCertificate',
  rejectUserCertificate: 'rejectUserCertificate',
  cancelUserCertificate: 'cancelUserCertificate',
  courseChapter: 'courseChapter',
  courseRegistrantAction: 'courseRegistrantAction',
  clubStatus: 'clubStatus',
};

export default MODAL_KEYS;
