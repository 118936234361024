import * as yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { useFieldArray } from 'react-hook-form';
import { useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  Box,
  Alert,
  Button,
  IconButton,
  InputLabel,
  Typography,
} from '@mui/material';

import {
  FormTags,
  FormTextField,
  FormUploadMedia,
  FormAutocomplete,
} from 'components/form';

import {
  getTopics,
  createPost,
  updatePost,
  uploadFileToS3,
  generatePresignedUrl,
} from 'services';

import {
  useAuth,
  useLocale,
  useRecaptcha,
  useSnackbar,
  useFastForm,
} from 'util/hooks';

import {
  TrashIcon,
  VideoPostIcon,
  ArticlePostIcon,
  KnowledgePostIcon,
} from 'assets/icons';

// react-quill
import DOMPurify from 'dompurify';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './EditorToolbar.css';

import { postStatus } from 'assets/data';
import MEDIA_TYPES from 'assets/constants/media';
import { CommentCard } from 'components/organisms';
import { getDirtyObject, REGEX } from 'util/helpers';
import { useGeneralSettings } from 'reactQuery/queries';
import SETTINGS_KEYS from 'assets/constants/settingsKeys';
import { POST_TYPES, POST_TYPES_DETAILS } from 'assets/constants/posts';

const { REACT_APP_S3_URL } = process.env;

const PostDetailsForm = (props) => {
  const {
    postDetails = {},
    createMode = false,
  } = props;

  const { comments } = postDetails;

  const { t } = useLocale();
  const editorRef = useRef();
  const snack = useSnackbar();
  const navigate = useNavigate();
  const { executeRecaptcha } = useRecaptcha({ action: 'POST_DETAILS' });
  const { isMember, isClubManager, isContentCreator } = useAuth();
  const { pathname } = useLocation();

  const [imageFileName, setFileNameArray] = useState([]);

  const {
    data = [],
  } = useGeneralSettings();

  const isSkipPostReview = data?.find((setting) => setting.key
    === SETTINGS_KEYS.skipPostReview).value || false;

  const isPostRevised = postDetails?.status === postStatus.REVISED;
  const isPostUnderReview = postDetails?.status === postStatus.UNDER_REVIEW;
  const isPostPublishedOrRejected = postDetails?.status === postStatus.PUBLISHED
    || postDetails?.status === postStatus.REJECTED;

  const isVideo = pathname.includes(POST_TYPES.video)
    || postDetails?.type === POST_TYPES.video;
  const isArticle = pathname.includes(POST_TYPES.article)
    || postDetails?.type === POST_TYPES.article;
  const isKnowledgeShare = pathname.includes(POST_TYPES_DETAILS[2].shortKey)
    || postDetails?.type === POST_TYPES.knowledgeShare;

  const isNotAdminOrLeader = isMember || isClubManager || isContentCreator;

  const canViewComments = comments?.length > 0
    && isPostRevised && isNotAdminOrLeader;

  // editor custom upload
  const imageHandler = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();
    input.onchange = async () => {
      const file = input.files[0];

      // save current cursor state
      const range = editorRef.current.getEditor().getSelection();

      // upload image
      const { uploadUrl, fileName, fileTempUrl } = await generatePresignedUrl();

      imageFileName.push(fileName);
      setFileNameArray(imageFileName);

      // add placeholder image
      editorRef.current.getEditor().insertEmbed(
        range.index || 0,
        'image',
        'https://community.shopify.com/c/image/serverpage/image-id/13053i786D43DEC307EAB0/image-size/small?v=v2&px=200',
      );

      await uploadFileToS3(uploadUrl, file);

      // insert actual image src
      editorRef.current.getEditor().deleteText(range.index, 1);
      editorRef.current.getEditor().insertEmbed(range.index || 0, 'image', fileTempUrl);
    };
  };

  // toolbar functionality
  const modules = useMemo(() => ({
    toolbar: {
      container: [
        [{ header: '1' }, { header: '2' }],
        [{ size: [] }],
        ['bold', 'italic', 'underline',
          'strike', 'blockquote', 'code-block'],
        [
          { list: 'ordered' },
          { list: 'bullet' },
          { align: [] },
          { direction: 'rtl' },
        ],
        [{ color: [] }, { background: [] }],
        ['link', 'image', 'video'],
      ],
      handlers: {
        image: imageHandler,
      },
      history: {
        delay: 500,
        maxStack: 100,
        userOnly: true,
      },
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), []);

  // toolbar items
  const formats = [
    'header',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'code-block',
    'ordered',
    'bullet',
    'list',
    'align',
    'direction',
    'link',
    'image',
    'video',
    'color',
    'background',
  ];

  const postTypes = () => {
    if (isArticle) {
      return POST_TYPES.article;
    } if (isVideo) {
      return POST_TYPES.video;
    }
    return POST_TYPES.knowledgeShare;
  };

  const renderPostIcons = () => {
    if (isArticle) {
      return <ArticlePostIcon width="20" height="20" />;
    } if (isVideo) {
      return <VideoPostIcon width="20" height="20" />;
    }
    return <KnowledgePostIcon width="20" height="20" />;
  };

  const onSubmit = async (values, isSaveAsDraftButton = false) => {
    const isReferencesEmpty = values.references?.[0]?.url === '' || values.references?.[0]?.label === '';
    const fixedContentImageURLS = values.content.replace(/REACT_APP_S3_URL\/public\/temp/g, `${REACT_APP_S3_URL}/public/uploads`);

    const createPostPayload = {
      ...values,
      uploads: imageFileName,
      content: isArticle ? DOMPurify.sanitize(fixedContentImageURLS) : values.content,
      references: isReferencesEmpty ? [] : values.references,
      topic: { id: Number(values.topic.id) },
      status: isSaveAsDraftButton ? postStatus.DRAFT : postStatus.UNDER_REVIEW,
    };

    const updatePostPayload = {
      ...createPostPayload,
      type: postTypes(),
      uploads: imageFileName,
      content: isArticle ? DOMPurify.sanitize(fixedContentImageURLS) : values.content,
      references: isReferencesEmpty ? [] : values.references,
      status: (!isSaveAsDraftButton && (postDetails?.status === postStatus.REVISED
        || postDetails?.status === postStatus.DRAFT)
        ? postStatus.UNDER_REVIEW : postStatus.DRAFT),
    };

    const dirtyPayload = getDirtyObject(updatePostPayload, defaultValues);

    try {
      let message = '';
      const recaptchaToken = await executeRecaptcha();

      if (createMode) {
        const { id } = await createPost(
          { ...createPostPayload, type: postTypes() },
          { recaptchaToken },
        );
        isSaveAsDraftButton
          ? message = t('posts.postSavedAsDraft')
          : (message = (isMember && !isSkipPostReview) ? t('posts.postSentForApproval')
            : t('posts.postIsPublished'));
        navigate(`/posts/${id}`);
      } else {
        await updatePost(dirtyPayload, postDetails.id, { recaptchaToken });
        isSaveAsDraftButton
          ? message = t('posts.postSavedAsDraft')
          : message = t('posts.postUpdated');
        navigate(`/posts/${postDetails.id}`);
      }

      snack({
        severity: 'success',
        message,
      });
    } catch (error) {
      error.errors?.forEach((err) => {
        setError(err.property, {
          type: 'api',
          message: err.message,
        });
        if (!err.property) {
          snack({
            severity: 'error',
            message: err.message || t('common.somethingWrong'),
          });
        }
      });

      if (!error.errors) {
        snack({
          severity: 'error',
          message: error.message || t('common.somethingWrong'),
        });
      }
    }
  };

  const defaultValues = {
    isArticle,
    isVideo,
    isKnowledgeShare,
    uploads: [],
    cover: postDetails?.cover || null,
    video: postDetails?.video || null,
    topic: postDetails?.topic || '',
    title: postDetails?.title || '',
    content: postDetails?.content || '',
    tags: postDetails?.tags || [],
    references: postDetails?.references || [{
      label: '',
      url: '',
    }],
  };

  const validationSchema = useMemo(() => (yup.object({
    isArticle: yup
      .boolean(),
    isVideo: yup
      .boolean(),
    isKnowledgeShare: yup
      .boolean(),
    uploads: yup
      .array(),
    cover: yup
      .string()
      .nullable()
      .when('isArticle', {
        is: true,
        then: yup
          .string()
          .nullable()
          .required(t('posts.uploadCover')),
      }),
    video: yup
      .string()
      .nullable()
      .when('isVideo', {
        is: true,
        then: yup.string().nullable()
          .required(t('posts.uploadVideo')),
      }),
    title: yup
      .string()
      .trim()
      .when('isKnowledgeShare', {
        is: false,
        then: yup.string()
          .required(t('posts.enterTitle'))
          .max(50, t('posts.maxTitle')),
      }),
    content: yup
      .string()
      .nullable()
      .when('isArticle', {
        is: true,
        then: yup.string()
          .max(20000, t('posts.maxContent')),
      })
      .required(t('posts.enterContent')),
    topic: yup
      .object()
      .nullable(true)
      .required(t('certificates.forms.validation.selectTopic')),
    tags: yup
      .array()
      .required(t('certificates.forms.validation.selectTag'))
      .min(1, t('certificates.forms.validation.selectTag'))
      .max(5, t('posts.maxTags')),
    references: yup
      .array()
      .of(yup.object().shape(
        {
          label: yup
            .string()
            .when('isArticle', {
              is: true,
              then: yup.string(),
            }),
          url: yup
            .string()
            .when('isArticle', {
              is: true,
              then: yup.string()
                .matches(
                  REGEX.url,
                  t('posts.createArticle.enterCorrectUrl'),
                ),
            }),
        },
      )),
  })), [t]);

  const {
    control,
    handleSubmit,
    setError,
    setValue,
    watch,
    formState: {
      isSubmitting,
      errors,
    },
  } = useFastForm({
    defaultValues,
    validationSchema,
  });

  const {
    fields: referenceFields,
    append: appendReference,
    remove: removeReference,
  } = useFieldArray({
    control,
    name: 'references',
  });

  const onEditorStateChange = (editorState) => {
    setValue('content', editorState);
  };

  const editorContent = watch('content');

  const editorLength = editorRef?.current?.getEditor()?.getLength() || 0;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{
        py: { xs: 12, md: 15 },
        px: {
          sm: 5,
          xs: 4,
        },
      }}
      >
        {isPostUnderReview
          && (
            <Alert
              severity="info"
              sx={{ width: 1, mb: 8 }}
            >
              {t('posts.postUnderReviewMessage')}
            </Alert>
          )}
        {isPostPublishedOrRejected
          && (
            <Alert
              severity="error"
              sx={{ width: 1, mb: 8 }}
            >
              {t('posts.postRejectedOrPublishedMessage')}
            </Alert>
          )}
        {canViewComments
          && (
            <Box sx={{ mb: 10 }}>
              <CommentCard
                isNotAdminOrLeader={isNotAdminOrLeader}
                commentStatus
              />
            </Box>
          )}
        <Box>
          <Box>
            {!isKnowledgeShare
              && (
                <Box sx={{ mb: 8 }}>
                  <InputLabel
                    sx={(theme) => ({
                      ...theme.typography.bodySmallRegular,
                      color: 'secondary.main',
                      mb: 3,
                    })}
                    required
                  >
                    {isArticle ? t('posts.createArticle.coverImage') : t('posts.video')}
                  </InputLabel>
                  {isArticle
                    ? (
                      <>
                        <FormUploadMedia
                          name="cover"
                          src={postDetails?.cover}
                          disabled={isSubmitting}
                          control={control}
                          setError={(errorProps) => setError('cover', errorProps)}
                          type={MEDIA_TYPES.image}
                          required
                        />
                        <Box sx={{ pl: 3 }}>
                          <Typography
                            variant="bodySmallRegular"
                            sx={{ color: 'common.passionFruitRed' }}
                          >
                            {errors.cover && errors.cover.message}
                          </Typography>
                        </Box>
                      </>
                    )
                    : (
                      <>
                        <FormUploadMedia
                          name="video"
                          src={postDetails?.video}
                          disabled={isSubmitting}
                          control={control}
                          setError={(errorProps) => setError('video', errorProps)}
                          type={MEDIA_TYPES.video}
                        />
                        <Box>
                          <Typography
                            variant="bodySmallRegular"
                            sx={{ color: 'common.passionFruitRed' }}
                          >
                            {errors.video && errors.video.message}
                          </Typography>
                        </Box>
                      </>
                    )}
                </Box>
              )}
            <Box sx={{ mb: 8 }}>
              <FormAutocomplete
                name="topic"
                control={control}
                icon={renderPostIcons()}
                label={t('certificates.forms.topic')}
                fullWidth
                selectFunc={getTopics}
                required
              />
            </Box>
            {!isKnowledgeShare
              && (
                <Box sx={{ mb: 8 }}>
                  <FormTextField
                    name="title"
                    control={control}
                    label={t('common.title')}
                    disabled={isSubmitting}
                    fullWidth
                    required
                  />
                </Box>
              )}
            <Box sx={{ mb: 8 }}>
              {isArticle
                ? (
                  <>
                    <InputLabel
                      sx={(theme) => ({
                        ...theme.typography.bodySmallRegular,
                        color: 'secondary.main',
                        mb: 3,
                      })}
                      required
                    >
                      {t('posts.content')}
                    </InputLabel>
                    <Box sx={{
                      position: 'relative',
                    }}
                    >
                      <ReactQuill
                        ref={editorRef}
                        value={editorContent}
                        onChange={onEditorStateChange}
                        placeholder={t('common.typeHere')}
                        modules={modules}
                        formats={formats}
                      />
                      <Box sx={{ position: 'absolute', bottom: 8, right: 16 }}>
                        <Typography variant="bodySmallRegular" color="common.gadgetGray">
                          {t('common.charactersWithCount', { count: editorLength - 1 })}
                        </Typography>
                      </Box>
                    </Box>
                    <Box sx={{ pl: 3 }}>
                      <Typography
                        variant="bodySmallRegular"
                        sx={{ color: 'common.passionFruitRed' }}
                      >
                        {errors.content && errors.content.message}
                      </Typography>
                    </Box>
                  </>
                )
                : (
                  <FormTextField
                    name="content"
                    control={control}
                    label={t('posts.content')}
                    disabled={isSubmitting}
                    fullWidth
                    multiline
                    required
                    minRows={3}
                    maxRows={3}
                    showCharCount
                    inputProps={{ maxLength: 140 }}
                  />
                )}
            </Box>
            <Box sx={{ mb: 8 }}>
              <FormTags
                name="tags"
                control={control}
                label={`${t('certificates.forms.tags')}*`}
                fullWidth
                setError={(message) => setError('tags', {
                  type: 'manual',
                  message,
                })}
              />
            </Box>
            {isArticle
              && (
                <Box sx={{ mb: 8 }}>
                  <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 2,
                  }}
                  >
                    <Typography
                      variant="bodySmallRegular"
                    >
                      {t('posts.createArticle.references')}
                    </Typography>
                    <Button
                      disableRipple
                      color="primary"
                      size="regular"
                      onClick={() => appendReference({ url: '', label: '' })}
                      sx={(theme) => ({
                        ...theme.typography.bodySmallRegular,
                        p: 0,
                      })}
                    >
                      {`+ ${t('posts.createArticle.addReference')}`}
                    </Button>
                  </Box>
                  <Box>
                    {referenceFields.map((refField, index) => {
                      const fieldName = `references[${index}]`;
                      return (
                        <Box
                          key={refField.id}
                          sx={{
                            display: 'flex',
                            flexFlow: 'column wrap',
                          }}
                        >
                          {index !== 0
                            && (
                              <Box sx={{
                                display: 'flex',
                                justifyContent: 'end',
                              }}
                              >
                                <IconButton
                                  aria-label="delete"
                                  disableRipple
                                  onClick={() => removeReference(index)}
                                >
                                  <TrashIcon width="30" height="30" />
                                </IconButton>
                              </Box>
                            )}
                          <FormTextField
                            name={`${fieldName}.label`}
                            label={t('posts.createArticle.referenceLabel')}
                            control={control}
                            sx={{ my: 2 }}
                          />
                          <FormTextField
                            name={`${fieldName}.url`}
                            label={t('posts.createArticle.url')}
                            control={control}
                            sx={{ my: 2 }}
                          />
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              )}
          </Box>
        </Box>
        <Box sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: 'end',
          mt: 16,
        }}
        >
          <LoadingButton
            disableRipple
            loading={isSubmitting}
            onClick={handleSubmit((values) => onSubmit(values, true))}
            color="primary"
            size="regular"
            variant="linkButton"
            disabled={isPostUnderReview || isPostPublishedOrRejected}
            sx={{
              mr: 3,
              '&.MuiButton-root:hover': {
                backgroundColor: 'transparent',
              },
            }}
          >
            {t('common.saveAsDraft')}
          </LoadingButton>
          <LoadingButton
            variant="contained"
            size="regular"
            loading={isSubmitting}
            disabled={isSubmitting || isPostUnderReview || isPostPublishedOrRejected}
            onClick={handleSubmit((values) => onSubmit(values, false))}
            sx={{ mt: { xs: 3, sm: 0 } }}
          >
            {(isMember && !isSkipPostReview) ? t('common.sendForApproval') : t('common.send')}
          </LoadingButton>
        </Box>
      </Box>
    </form>
  );
};

export default PostDetailsForm;
