const CyberhubShieldIcon = (props) => {
  const {
    ...rest
  } = props;

  return (
    <svg width="22" height="26" viewBox="0 0 22 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d="M1.17297 6.28051C0.5721 6.28051 0.0820312 5.80373 0.0820312 5.20984V2.00621C0.0820312 1.41651 0.567838 0.935547 1.17297 0.935547C1.77383 0.935547 2.2639 1.41233 2.2639 2.00621V5.20984C2.2639 5.80373 1.77383 6.28051 1.17297 6.28051Z" fill="url(#paint0_linear_9476_96001)" />
      <path d="M4.44055 6.28051C3.83968 6.28051 3.34961 5.80373 3.34961 5.20984V2.00621C3.34961 1.41651 3.83542 0.935547 4.44055 0.935547C5.04141 0.935547 5.53148 1.41233 5.53148 2.00621V5.20984C5.53148 5.80373 5.04141 6.28051 4.44055 6.28051Z" fill="url(#paint1_linear_9476_96001)" />
      <path d="M7.71008 6.28051C7.10921 6.28051 6.61914 5.80373 6.61914 5.20984V2.00621C6.61914 1.41651 7.10495 0.935547 7.71008 0.935547C8.31094 0.935547 8.80101 1.41233 8.80101 2.00621V5.20984C8.79675 5.80373 8.31094 6.28051 7.71008 6.28051Z" fill="url(#paint2_linear_9476_96001)" />
      <path d="M20.8214 6.28051C20.2205 6.28051 19.7305 5.80373 19.7305 5.20984V2.00621C19.7305 1.41651 20.2163 0.935547 20.8214 0.935547C21.4223 0.935547 21.9123 1.41233 21.9123 2.00621V5.20984C21.9081 5.80373 21.4223 6.28051 20.8214 6.28051Z" fill="url(#paint3_linear_9476_96001)" />
      <path d="M10.9757 22.2805C10.3748 22.2805 9.88477 21.8037 9.88477 21.2098V18.0062C9.88477 17.4165 10.3706 16.9355 10.9757 16.9355C11.5766 16.9355 12.0666 17.4123 12.0666 18.0062V21.2098C12.0624 21.8037 11.5766 22.2805 10.9757 22.2805Z" fill="url(#paint4_linear_9476_96001)" />
      <path d="M10.9757 12.6803C10.3748 12.6803 9.88477 12.2035 9.88477 11.6096V5.2191C9.88477 4.6294 10.3706 4.14844 10.9757 4.14844C11.5766 4.14844 12.0666 4.62522 12.0666 5.2191V11.6096C12.0624 12.2035 11.5766 12.6803 10.9757 12.6803Z" fill="url(#paint5_linear_9476_96001)" />
      <path d="M7.71008 25.5055C7.10921 25.5055 6.61914 25.0287 6.61914 24.4348V18.0443C6.61914 17.4546 7.10495 16.9736 7.71008 16.9736C8.31094 16.9736 8.80101 17.4504 8.80101 18.0443V24.4348C8.79675 25.0287 8.31094 25.5055 7.71008 25.5055Z" fill="url(#paint6_linear_9476_96001)" />
      <path d="M13.1641 18.0375C13.1641 17.4478 13.6499 16.9668 14.255 16.9668H20.7665C21.3674 16.9668 21.8575 17.4436 21.8575 18.0375C21.8575 18.6272 21.3717 19.1081 20.7665 19.1081H14.255C13.6541 19.1039 13.1641 18.6272 13.1641 18.0375Z" fill="url(#paint7_linear_9476_96001)" />
      <path d="M17.5089 12.6965C16.908 12.6965 16.418 12.2197 16.418 11.6259V8.42223C16.418 7.83253 16.9038 7.35156 17.5089 7.35156C18.1098 7.35156 18.5998 7.82834 18.5998 8.42223V11.6259C18.5998 12.2156 18.1098 12.6965 17.5089 12.6965Z" fill="url(#paint8_linear_9476_96001)" />
      <path d="M18.5926 14.7904C18.5926 15.3801 18.1068 15.8611 17.5017 15.8611H14.2374C13.6366 15.8611 13.1465 15.3843 13.1465 14.7904C13.1465 14.2007 13.6323 13.7197 14.2374 13.7197H17.5017C18.1068 13.7239 18.5926 14.2007 18.5926 14.7904Z" fill="url(#paint9_linear_9476_96001)" />
      <path d="M18.5926 21.2504C18.5926 21.8401 18.1068 22.321 17.5017 22.321H14.2374C13.6366 22.321 13.1465 21.8442 13.1465 21.2504C13.1465 20.6606 13.6323 20.1797 14.2374 20.1797H17.5017C18.1068 20.1839 18.5926 20.6606 18.5926 21.2504Z" fill="url(#paint10_linear_9476_96001)" />
      <path d="M1.17297 15.9016C0.5721 15.9016 0.0820312 15.4248 0.0820312 14.8309V11.6273C0.0820312 11.0376 0.567839 10.5566 1.17297 10.5566C1.77383 10.5566 2.2639 11.0334 2.2639 11.6273V14.8309C2.2639 15.4248 1.77383 15.9016 1.17297 15.9016Z" fill="url(#paint11_linear_9476_96001)" />
      <path d="M4.44055 15.9016C3.83968 15.9016 3.34961 15.4248 3.34961 14.8309V11.6273C3.34961 11.0376 3.83542 10.5566 4.44055 10.5566C5.04141 10.5566 5.53148 11.0334 5.53148 11.6273V14.8309C5.53148 15.4248 5.04141 15.9016 4.44055 15.9016Z" fill="url(#paint12_linear_9476_96001)" />
      <path d="M4.44055 22.3186C3.83968 22.3186 3.34961 21.8418 3.34961 21.2479V18.0443C3.34961 17.4546 3.83542 16.9736 4.44055 16.9736C5.04141 16.9736 5.53148 17.4504 5.53148 18.0443V21.2479C5.53148 21.8376 5.04141 22.3186 4.44055 22.3186Z" fill="url(#paint13_linear_9476_96001)" />
      <path d="M1.17297 9.49289C1.77547 9.49289 2.2639 9.01354 2.2639 8.42223C2.2639 7.83092 1.77547 7.35156 1.17297 7.35156C0.57046 7.35156 0.0820312 7.83092 0.0820312 8.42223C0.0820312 9.01354 0.57046 9.49289 1.17297 9.49289Z" fill="url(#paint14_linear_9476_96001)" />
      <path d="M4.44055 9.49289C5.04305 9.49289 5.53148 9.01354 5.53148 8.42223C5.53148 7.83092 5.04305 7.35156 4.44055 7.35156C3.83804 7.35156 3.34961 7.83092 3.34961 8.42223C3.34961 9.01354 3.83804 9.49289 4.44055 9.49289Z" fill="url(#paint15_linear_9476_96001)" />
      <path d="M7.71008 9.49289C8.31258 9.49289 8.80101 9.01354 8.80101 8.42223C8.80101 7.83092 8.31258 7.35156 7.71008 7.35156C7.10757 7.35156 6.61914 7.83092 6.61914 8.42223C6.61914 9.01354 7.10757 9.49289 7.71008 9.49289Z" fill="url(#paint16_linear_9476_96001)" />
      <path d="M10.9757 15.9031C11.5782 15.9031 12.0666 15.4237 12.0666 14.8324C12.0666 14.2411 11.5782 13.7617 10.9757 13.7617C10.3732 13.7617 9.88477 14.2411 9.88477 14.8324C9.88477 15.4237 10.3732 15.9031 10.9757 15.9031Z" fill="url(#paint17_linear_9476_96001)" />
      <path d="M10.9757 25.532C11.5782 25.532 12.0666 25.0526 12.0666 24.4613C12.0666 23.87 11.5782 23.3906 10.9757 23.3906C10.3732 23.3906 9.88477 23.87 9.88477 24.4613C9.88477 25.0526 10.3732 25.532 10.9757 25.532Z" fill="url(#paint18_linear_9476_96001)" />
      <path d="M10.9757 3.07688C11.5782 3.07688 12.0666 2.59753 12.0666 2.00621C12.0666 1.4149 11.5782 0.935547 10.9757 0.935547C10.3732 0.935547 9.88477 1.4149 9.88477 2.00621C9.88477 2.59753 10.3732 3.07688 10.9757 3.07688Z" fill="url(#paint19_linear_9476_96001)" />
      <path d="M14.2374 12.698C14.8399 12.698 15.3284 12.2186 15.3284 11.6273C15.3284 11.036 14.8399 10.5566 14.2374 10.5566C13.6349 10.5566 13.1465 11.036 13.1465 11.6273C13.1465 12.2186 13.6349 12.698 14.2374 12.698Z" fill="url(#paint20_linear_9476_96001)" />
      <path d="M14.2374 9.49289C14.8399 9.49289 15.3284 9.01354 15.3284 8.42223C15.3284 7.83092 14.8399 7.35156 14.2374 7.35156C13.6349 7.35156 13.1465 7.83092 13.1465 8.42223C13.1465 9.01354 13.6349 9.49289 14.2374 9.49289Z" fill="url(#paint21_linear_9476_96001)" />
      <path d="M14.2374 6.28977C14.8399 6.28977 15.3284 5.81041 15.3284 5.2191C15.3284 4.62779 14.8399 4.14844 14.2374 4.14844C13.6349 4.14844 13.1465 4.62779 13.1465 5.2191C13.1465 5.81041 13.6349 6.28977 14.2374 6.28977Z" fill="url(#paint22_linear_9476_96001)" />
      <path d="M20.7745 15.9031C21.377 15.9031 21.8655 15.4237 21.8655 14.8324C21.8655 14.2411 21.377 13.7617 20.7745 13.7617C20.172 13.7617 19.6836 14.2411 19.6836 14.8324C19.6836 15.4237 20.172 15.9031 20.7745 15.9031Z" fill="url(#paint23_linear_9476_96001)" />
      <path d="M20.7745 12.698C21.377 12.698 21.8655 12.2186 21.8655 11.6273C21.8655 11.036 21.377 10.5566 20.7745 10.5566C20.172 10.5566 19.6836 11.036 19.6836 11.6273C19.6836 12.2186 20.172 12.698 20.7745 12.698Z" fill="url(#paint24_linear_9476_96001)" />
      <path d="M20.7745 9.49289C21.377 9.49289 21.8655 9.01354 21.8655 8.42223C21.8655 7.83092 21.377 7.35156 20.7745 7.35156C20.172 7.35156 19.6836 7.83092 19.6836 8.42223C19.6836 9.01354 20.172 9.49289 20.7745 9.49289Z" fill="url(#paint25_linear_9476_96001)" />
      <path d="M14.2374 3.07688C14.8399 3.07688 15.3284 2.59753 15.3284 2.00621C15.3284 1.4149 14.8399 0.935547 14.2374 0.935547C13.6349 0.935547 13.1465 1.4149 13.1465 2.00621C13.1465 2.59753 13.6349 3.07688 14.2374 3.07688Z" fill="url(#paint26_linear_9476_96001)" />
      <path d="M17.5089 6.28977C18.1114 6.28977 18.5998 5.81041 18.5998 5.2191C18.5998 4.62779 18.1114 4.14844 17.5089 4.14844C16.9064 4.14844 16.418 4.62779 16.418 5.2191C16.418 5.81041 16.9064 6.28977 17.5089 6.28977Z" fill="url(#paint27_linear_9476_96001)" />
      <path d="M17.5089 3.07688C18.1114 3.07688 18.5998 2.59753 18.5998 2.00621C18.5998 1.4149 18.1114 0.935547 17.5089 0.935547C16.9064 0.935547 16.418 1.4149 16.418 2.00621C16.418 2.59753 16.9064 3.07688 17.5089 3.07688Z" fill="url(#paint28_linear_9476_96001)" />
      <path d="M7.71008 12.698C8.31258 12.698 8.80101 12.2186 8.80101 11.6273C8.80101 11.036 8.31258 10.5566 7.71008 10.5566C7.10757 10.5566 6.61914 11.036 6.61914 11.6273C6.61914 12.2186 7.10757 12.698 7.71008 12.698Z" fill="url(#paint29_linear_9476_96001)" />
      <path d="M7.71008 15.9031C8.31258 15.9031 8.80101 15.4237 8.80101 14.8324C8.80101 14.2411 8.31258 13.7617 7.71008 13.7617C7.10757 13.7617 6.61914 14.2411 6.61914 14.8324C6.61914 15.4237 7.10757 15.9031 7.71008 15.9031Z" fill="url(#paint30_linear_9476_96001)" />
      <path d="M1.17297 19.115C1.77547 19.115 2.2639 18.6356 2.2639 18.0443C2.2639 17.453 1.77547 16.9736 1.17297 16.9736C0.57046 16.9736 0.0820312 17.453 0.0820312 18.0443C0.0820312 18.6356 0.57046 19.115 1.17297 19.115Z" fill="url(#paint31_linear_9476_96001)" />
      <defs>
        <linearGradient id="paint0_linear_9476_96001" x1="23.8632" y1="-18.3091" x2="-20.1788" y2="25.8655" gradientUnits="userSpaceOnUse">
          <stop offset="0.00506604" stopColor="#8DDD88" />
          <stop offset="1" stopColor="#13A7FF" />
        </linearGradient>
        <linearGradient id="paint1_linear_9476_96001" x1="25.4703" y1="-16.7059" x2="-18.5717" y2="27.4687" gradientUnits="userSpaceOnUse">
          <stop offset="0.00506604" stopColor="#8DDD88" />
          <stop offset="1" stopColor="#13A7FF" />
        </linearGradient>
        <linearGradient id="paint2_linear_9476_96001" x1="27.0793" y1="-15.1027" x2="-16.9627" y2="29.0719" gradientUnits="userSpaceOnUse">
          <stop offset="0.00506604" stopColor="#8DDD88" />
          <stop offset="1" stopColor="#13A7FF" />
        </linearGradient>
        <linearGradient id="paint3_linear_9476_96001" x1="33.5308" y1="-8.66937" x2="-10.5112" y2="35.5053" gradientUnits="userSpaceOnUse">
          <stop offset="0.00506604" stopColor="#8DDD88" />
          <stop offset="1" stopColor="#13A7FF" />
        </linearGradient>
        <linearGradient id="paint4_linear_9476_96001" x1="36.8322" y1="-5.36926" x2="-7.20977" y2="38.8054" gradientUnits="userSpaceOnUse">
          <stop offset="0.00506604" stopColor="#8DDD88" />
          <stop offset="1" stopColor="#13A7FF" />
        </linearGradient>
        <linearGradient id="paint5_linear_9476_96001" x1="31.1303" y1="-11.0559" x2="-12.9117" y2="33.1188" gradientUnits="userSpaceOnUse">
          <stop offset="0.00506604" stopColor="#8DDD88" />
          <stop offset="1" stopColor="#13A7FF" />
        </linearGradient>
        <linearGradient id="paint6_linear_9476_96001" x1="36.0593" y1="-6.14643" x2="-7.98265" y2="38.0282" gradientUnits="userSpaceOnUse">
          <stop offset="0.00506604" stopColor="#8DDD88" />
          <stop offset="1" stopColor="#13A7FF" />
        </linearGradient>
        <linearGradient id="paint7_linear_9476_96001" x1="39.2513" y1="-2.96595" x2="-4.79054" y2="41.2087" gradientUnits="userSpaceOnUse">
          <stop offset="0.00506604" stopColor="#8DDD88" />
          <stop offset="1" stopColor="#13A7FF" />
        </linearGradient>
        <linearGradient id="paint8_linear_9476_96001" x1="35.1702" y1="-7.03734" x2="-8.87173" y2="37.1373" gradientUnits="userSpaceOnUse">
          <stop offset="0.00506604" stopColor="#8DDD88" />
          <stop offset="1" stopColor="#13A7FF" />
        </linearGradient>
        <linearGradient id="paint9_linear_9476_96001" x1="36.792" y1="-5.41889" x2="-7.24996" y2="38.7557" gradientUnits="userSpaceOnUse">
          <stop offset="0.00506604" stopColor="#8DDD88" />
          <stop offset="1" stopColor="#13A7FF" />
        </linearGradient>
        <linearGradient id="paint10_linear_9476_96001" x1="40.0794" y1="-2.13468" x2="-3.96252" y2="42.0399" gradientUnits="userSpaceOnUse">
          <stop offset="0.00506604" stopColor="#8DDD88" />
          <stop offset="1" stopColor="#13A7FF" />
        </linearGradient>
        <linearGradient id="paint11_linear_9476_96001" x1="28.7662" y1="-13.4232" x2="-15.2758" y2="30.7515" gradientUnits="userSpaceOnUse">
          <stop offset="0.00506604" stopColor="#8DDD88" />
          <stop offset="1" stopColor="#13A7FF" />
        </linearGradient>
        <linearGradient id="paint12_linear_9476_96001" x1="30.3733" y1="-11.82" x2="-13.6687" y2="32.3547" gradientUnits="userSpaceOnUse">
          <stop offset="0.00506604" stopColor="#8DDD88" />
          <stop offset="1" stopColor="#13A7FF" />
        </linearGradient>
        <linearGradient id="paint13_linear_9476_96001" x1="33.6404" y1="-8.56135" x2="-10.4016" y2="35.6133" gradientUnits="userSpaceOnUse">
          <stop offset="0.00506604" stopColor="#8DDD88" />
          <stop offset="1" stopColor="#13A7FF" />
        </linearGradient>
        <linearGradient id="paint14_linear_9476_96001" x1="26.3147" y1="-15.8646" x2="-17.7273" y2="28.3101" gradientUnits="userSpaceOnUse">
          <stop offset="0.00506604" stopColor="#8DDD88" />
          <stop offset="1" stopColor="#13A7FF" />
        </linearGradient>
        <linearGradient id="paint15_linear_9476_96001" x1="27.9218" y1="-14.2614" x2="-16.1203" y2="29.9133" gradientUnits="userSpaceOnUse">
          <stop offset="0.00506604" stopColor="#8DDD88" />
          <stop offset="1" stopColor="#13A7FF" />
        </linearGradient>
        <linearGradient id="paint16_linear_9476_96001" x1="29.5308" y1="-12.6582" x2="-14.5112" y2="31.5164" gradientUnits="userSpaceOnUse">
          <stop offset="0.00506604" stopColor="#8DDD88" />
          <stop offset="1" stopColor="#13A7FF" />
        </linearGradient>
        <linearGradient id="paint17_linear_9476_96001" x1="34.4041" y1="-7.80208" x2="-9.6379" y2="36.3726" gradientUnits="userSpaceOnUse">
          <stop offset="0.00506604" stopColor="#8DDD88" />
          <stop offset="1" stopColor="#13A7FF" />
        </linearGradient>
        <linearGradient id="paint18_linear_9476_96001" x1="39.3047" y1="-2.91067" x2="-4.73723" y2="41.264" gradientUnits="userSpaceOnUse">
          <stop offset="0.00506604" stopColor="#8DDD88" />
          <stop offset="1" stopColor="#13A7FF" />
        </linearGradient>
        <linearGradient id="paint19_linear_9476_96001" x1="27.8688" y1="-14.3168" x2="-16.1732" y2="29.8578" gradientUnits="userSpaceOnUse">
          <stop offset="0.00506604" stopColor="#8DDD88" />
          <stop offset="1" stopColor="#13A7FF" />
        </linearGradient>
        <linearGradient id="paint20_linear_9476_96001" x1="34.3748" y1="-7.82601" x2="-9.66727" y2="36.3486" gradientUnits="userSpaceOnUse">
          <stop offset="0.00506604" stopColor="#8DDD88" />
          <stop offset="1" stopColor="#13A7FF" />
        </linearGradient>
        <linearGradient id="paint21_linear_9476_96001" x1="32.7413" y1="-9.45187" x2="-11.3008" y2="34.7227" gradientUnits="userSpaceOnUse">
          <stop offset="0.00506604" stopColor="#8DDD88" />
          <stop offset="1" stopColor="#13A7FF" />
        </linearGradient>
        <linearGradient id="paint22_linear_9476_96001" x1="31.1077" y1="-11.0758" x2="-12.9342" y2="33.0988" gradientUnits="userSpaceOnUse">
          <stop offset="0.00506604" stopColor="#8DDD88" />
          <stop offset="1" stopColor="#13A7FF" />
        </linearGradient>
        <linearGradient id="paint23_linear_9476_96001" x1="39.2232" y1="-2.99073" x2="-4.81885" y2="41.1839" gradientUnits="userSpaceOnUse">
          <stop offset="0.00506604" stopColor="#8DDD88" />
          <stop offset="1" stopColor="#13A7FF" />
        </linearGradient>
        <linearGradient id="paint24_linear_9476_96001" x1="37.5897" y1="-4.6166" x2="-6.4523" y2="39.558" gradientUnits="userSpaceOnUse">
          <stop offset="0.00506604" stopColor="#8DDD88" />
          <stop offset="1" stopColor="#13A7FF" />
        </linearGradient>
        <linearGradient id="paint25_linear_9476_96001" x1="35.9561" y1="-6.24669" x2="-8.08591" y2="37.928" gradientUnits="userSpaceOnUse">
          <stop offset="0.00506604" stopColor="#8DDD88" />
          <stop offset="1" stopColor="#13A7FF" />
        </linearGradient>
        <linearGradient id="paint26_linear_9476_96001" x1="29.4742" y1="-12.7137" x2="-14.5678" y2="31.4609" gradientUnits="userSpaceOnUse">
          <stop offset="0.00506604" stopColor="#8DDD88" />
          <stop offset="1" stopColor="#13A7FF" />
        </linearGradient>
        <linearGradient id="paint27_linear_9476_96001" x1="32.7187" y1="-9.47259" x2="-11.3233" y2="34.7021" gradientUnits="userSpaceOnUse">
          <stop offset="0.00506604" stopColor="#8DDD88" />
          <stop offset="1" stopColor="#13A7FF" />
        </linearGradient>
        <linearGradient id="paint28_linear_9476_96001" x1="31.0852" y1="-11.1105" x2="-12.9568" y2="33.0642" gradientUnits="userSpaceOnUse">
          <stop offset="0.00506604" stopColor="#8DDD88" />
          <stop offset="1" stopColor="#13A7FF" />
        </linearGradient>
        <linearGradient id="paint29_linear_9476_96001" x1="31.1643" y1="-11.0324" x2="-12.8778" y2="33.1423" gradientUnits="userSpaceOnUse">
          <stop offset="0.00506604" stopColor="#8DDD88" />
          <stop offset="1" stopColor="#13A7FF" />
        </linearGradient>
        <linearGradient id="paint30_linear_9476_96001" x1="32.7978" y1="-9.40645" x2="-11.2442" y2="34.7682" gradientUnits="userSpaceOnUse">
          <stop offset="0.00506604" stopColor="#8DDD88" />
          <stop offset="1" stopColor="#13A7FF" />
        </linearGradient>
        <linearGradient id="paint31_linear_9476_96001" x1="31.2154" y1="-10.9759" x2="-12.8266" y2="33.1988" gradientUnits="userSpaceOnUse">
          <stop offset="0.00506604" stopColor="#8DDD88" />
          <stop offset="1" stopColor="#13A7FF" />
        </linearGradient>
      </defs>
    </svg>

  );
};

export default CyberhubShieldIcon;
