import { setDefaultOptions } from 'date-fns';
import arSA from 'date-fns/locale/ar-SA'; // Arabic - SA
import enUS from 'date-fns/locale/en-US'; // English - US

import { useLocale } from 'util/hooks';

const localeMap = {
  ar: arSA,
  en: enUS,
};

const useDateDefaults = () => {
  const { language } = useLocale();

  setDefaultOptions({
    locale: localeMap[language] || enUS, // Based on app language
    weekStartsOn: 0, // Sunday
    firstWeekContainsDate: 1, // Jan 1
  });
};

export default useDateDefaults;
