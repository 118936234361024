import { useTheme } from '@mui/styles';

const CertificateGreenIcon = (props) => {
  const { palette } = useTheme();

  const {
    fill = 'none',
    stroke = palette.common.turquoiseSeaweedGreen,
    rectFill = palette.common.turquoiseSeaweedGreen,
  } = props;
  return (
    <svg width="33" height="33" viewBox="0 0 35 35" fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect x="0.900391" y="0.899902" width="33.686" height="33.7477" rx="16.843" fill={rectFill} fillOpacity="0.2" />
      <path d="M13.2483 18.4248L10.7129 21.5233L13.0565 21.9921L13.9939 24.8044L16.4587 20.8934" stroke={stroke} strokeWidth="1.40615" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M22.2392 18.4248L24.7747 21.5233L22.4311 21.9921L21.4936 24.8044L19.0288 20.8934" stroke={stroke} strokeWidth="1.40615" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M22.8992 15.8988C22.898 18.2061 21.3634 20.2315 19.1424 20.8568C16.9214 21.4821 14.5555 20.5549 13.3507 18.5871C12.1459 16.6193 12.3959 14.0905 13.9628 12.3968C15.5296 10.703 18.0313 10.2571 20.0868 11.3053" stroke={stroke} strokeWidth="1.40615" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M21.4933 13.5552L18.0748 16.9737C17.987 17.0616 17.8678 17.111 17.7436 17.111C17.6193 17.111 17.5001 17.0616 17.4123 16.9737L16.3374 15.8988" stroke={stroke} strokeWidth="1.40615" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default CertificateGreenIcon;
