import { Box, LinearProgress } from '@mui/material';

import { useLocale } from 'util/hooks';
import { NoCoursesIcon } from 'assets/icons';
import { useCourses } from 'reactQuery/queries';
import { CoursesCardsContainer } from 'components/organisms';
import { DataPlaceholder, UrlPagination, URLSearchField } from 'components/molecules';

const ManageCoursesContainer = (props) => {
  const { status = '', type = '' } = props;

  const { t } = useLocale();

  const filterOptions = {
    status,
    type,
  };

  const {
    data = {},
    isFetching = false,
  } = useCourses({
    queryKeyOptions: { filterOptions },

  });

  const {
    items: courses = [],
    meta = {},
  } = data || {};

  const hasCourses = courses?.length > 0;

  return (
    <Box>
      <Box sx={{ mb: 9 }}>
        <URLSearchField />
      </Box>
      <Box sx={{
        height: 2,
        mt: -5,
        mb: 2,
      }}
      >
        {isFetching && <LinearProgress sx={{ borderRadius: 8 }} />}
      </Box>
      {hasCourses
        ? (<CoursesCardsContainer courses={courses} />)
        : (
          <DataPlaceholder
            icon={<NoCoursesIcon />}
            header={t('courses.placeholder.noCourses')}
            description={t('courses.placeholder.noCoursesDescription')}
          />
        )}
      {hasCourses
        && (
          <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: 5,
          }}
          >
            <UrlPagination count={meta?.totalPages} />
          </Box>
        )}
    </Box>
  );
};

export default ManageCoursesContainer;
