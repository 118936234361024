import { GridActionsCellItem } from '@mui/x-data-grid';
import { useLocale, useModal } from 'util/hooks';
import { Status } from 'components/molecules';
import { localizedDate } from 'util/helpers';
import MODAL_KEYS from 'assets/constants/modalKeys';
import TABLE_ACTIONS from 'assets/constants/tableActions';

const useTableColumns = (props = {}) => {
  const { addModal } = useModal();

  const {
    refetchCourseEnrollments,
  } = props;

  const {
    t, dir, language,
  } = useLocale();

  const handleAddModal = (type, studentId) => {
    addModal({
      key: MODAL_KEYS.courseAttendanceAction,
      props: {
        type,
        studentId,
        refetchCourseEnrollments,
      },
    });
  };

  const columns = [
    {
      field: 'name',
      headerName: t('common.name'),
      flex: 1,
      minWidth: 200,
      valueGetter: (params) => params?.row?.student?.name,
    },
    {
      field: 'club',
      headerName: t('courses.attendanceTable.club'),
      flex: 1,
      minWidth: 250,
      valueGetter: (params) => params?.row?.student?.club,
    },
    {
      field: 'email',
      headerName: t('courses.attendanceTable.email'),
      flex: 1,
      minWidth: 250,
      valueGetter: (params) => params?.row?.student?.email,
    },
    {
      field: 'createdAt',
      headerName: t('courses.attendanceTable.joiningDate'),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => `${localizedDate(language, params?.row?.createdAt)}`,
    },
    {
      field: 'attendedSessions',
      headerName: t('courses.attendanceTable.attendance'),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => `${params?.row?.student?.attendedSessions}/${params?.row?.course?.sessionsCount}`,
    },
    {
      field: 'enrollmentStatus',
      headerName: t('courses.attendanceTable.enrollmentStatus'),
      flex: 1,
      minWidth: 100,
      renderCell: (params) => (
        <Status
          status={params.row.status || ''}
          label={t(`statuses.${params?.row?.status}`)}
        />
      ),
    },

    {
      field: 'actions',
      headerName: t('common.actions'),
      type: 'actions',
      flex: 1,
      minWidth: 100,
      getActions: (params) => {
        const { row: { status } } = params;
        const isEnrolled = status === 'enrolled';
        const isRevoked = status === 'revoked';
        const actions = [];

        if (isEnrolled) {
          actions.push(
            <GridActionsCellItem
              dir={dir}
              label={t('actions.revoke')}
              onClick={() => handleAddModal(
                TABLE_ACTIONS.courseAttendance.revoke,
                params?.row?.student?.id,
              )}
              showInMenu
            />,
          );
        } else if (isRevoked) {
          actions.push(
            <GridActionsCellItem
              dir={dir}
              label={t('actions.setEnrolled')}
              onClick={() => handleAddModal(
                TABLE_ACTIONS.courseAttendance.setEnrolled,
                params?.row?.student?.id,
              )}
              showInMenu
            />,
          );
        }
        return actions;
      },
    },
  ];

  return columns;
};

export default useTableColumns;
