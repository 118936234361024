import END_POINTS from '../../endPoints';
import api from '../../api';

const getInterests = (options) => api(
  END_POINTS.interests,
  options,
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default getInterests;
