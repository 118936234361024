import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';

import {
  Box,
  Stack,
  Button,
  Typography,
  InputLabel,
} from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './TextEditorToolbar.css';

import {
  useLocale,
  useSnackbar,
  useFastForm,
} from 'util/hooks';
import { FormAutocomplete, FormTextField } from 'components/form';
import { createCourseMaterial, editCourseMaterial } from 'services';
import { useParams } from 'react-router-dom';
import { useCourseChapters, useCourseDetails } from 'reactQuery/queries';
import { COURSE_MATERIAL_TYPES } from 'assets/constants/courses';
import { useMemo, useRef } from 'react';
import { getDirtyObject } from 'util/helpers';
import DOMPurify from 'dompurify';
import { Controller } from 'react-hook-form';

const CourseTextDetailsForm = (props) => {
  const {
    createMode = false,
    handleClickCancel = () => {},
    chapterId = '',
    materialDetails = {},
  } = props;

  const { t } = useLocale();
  const editorRef = useRef();
  const snack = useSnackbar();
  const { courseId } = useParams();

  const {
    title = '',
    content = '',
    id: materialId = '',
  } = materialDetails || {};

  const {
    data: courseChapters = [],
  } = useCourseChapters();

  const chapter = courseChapters.find((ch) => ch.id === chapterId);

  const {
    refetch: refetchCourse = () => {},
  } = useCourseDetails();

  // toolbar functionality
  const modules = useMemo(() => ({
    toolbar: {
      container: [
        [{ header: '1' }, { header: '2' }],
        [{ size: [] }],
        ['bold', 'italic', 'underline',
          'strike', 'blockquote', 'code-block'],
        [
          { list: 'ordered' },
          { list: 'bullet' },
          { align: [] },
          { direction: 'rtl' },
        ],
        [{ color: [] }, { background: [] }],
        ['link'],
      ],
      history: {
        delay: 500,
        maxStack: 100,
        userOnly: true,
      },
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), []);

  const formats = [
    'header',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'code-block',
    'ordered',
    'bullet',
    'list',
    'align',
    'direction',
    'link',
    'color',
    'background',
  ];

  const onSubmit = async (values) => {
    let message = '';

    const payload = {
      ...values,
      chapter: { id: Number(values.chapter.id) },
      content: DOMPurify.sanitize(values.content),
      type: COURSE_MATERIAL_TYPES.text,
    };

    try {
      if (createMode) {
        await createCourseMaterial(payload, courseId, chapterId);
        message = t('courses.textCreated');
      } else {
        const dirtyValues = getDirtyObject(payload, defaultValues);
        await editCourseMaterial(dirtyValues, courseId, chapterId, materialId);
        message = t('courses.textUpdated');
      }

      handleClickCancel();
      refetchCourse();
      snack({
        severity: 'success',
        message,
      });
    } catch (error) {
      error.errors?.forEach((err) => {
        setError(err.property, {
          type: 'api',
          message: err.message,
        });

        if (!err.property) {
          snack({
            severity: 'error',
            message: err.message || t('common.somethingWrong'),
          });
        }
      });
    }
  };

  const defaultValues = {
    chapter,
    title,
    content: DOMPurify.sanitize(content),
  };

  const validationSchema = Yup.object().shape(
    {
      chapter: Yup
        .object()
        .nullable(true)
        .required(t('courses.chapterRequired')),
      title: Yup
        .string()
        .required(t('courses.textTitleRequired')),
      content: Yup
        .string()
        .required(t('courses.textContentRequired')),
    },
  );

  const {
    control,
    setError,
    handleSubmit,
    setValue,
    watch,
    formState: {
      isSubmitting,
      isDirty,
      errors,
    },
  } = useFastForm({
    defaultValues,
    validationSchema,
  });

  const onEditorStateChange = (editorState) => {
    setValue('content', editorState);
  };

  const editorContent = watch('content');

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box
        sx={{
          mb: 12,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Typography variant="h5">
          {createMode ? t('courses.newText') : t('courses.updateText')}
        </Typography>
      </Box>
      <Stack spacing={7}>
        <Box>
          <FormAutocomplete
            name="chapter"
            control={control}
            label={t('common.chapter')}
            fullWidth
            withOptions
            options={courseChapters}
          />
        </Box>
        <Box>
          <FormTextField
            name="title"
            control={control}
            label={t('courses.textTitle')}
            disabled={isSubmitting}
            fullWidth
          />
        </Box>
        <Box>
          <InputLabel
            sx={(theme) => ({
              ...theme.typography.bodySmallRegular,
              color: 'secondary.main',
              mb: 3,
            })}
          >
            {t('courses.text')}
          </InputLabel>
          <Controller
            control={control}
            name="content"
            render={({
              field: {
                onChange = onEditorStateChange,
                value = editorContent,
                ref = editorRef,
              },
            }) => (
              <Box sx={{
                position: 'relative',
              }}
              >
                <ReactQuill
                  ref={ref}
                  value={value}
                  onChange={onChange}
                  placeholder={t('courses.text')}
                  modules={modules}
                  formats={formats}
                />
              </Box>
            )}
          />
          <Box sx={{ pl: 3 }}>
            <Typography
              variant="bodySmallRegular"
              sx={{ color: 'common.passionFruitRed' }}
            >
              {errors.content && errors.content.message}
            </Typography>
          </Box>
        </Box>
      </Stack>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          mt: 10,
        }}
      >
        <Button
          onClick={handleClickCancel}
          variant="outlined"
          size="regular"
          color="secondary"
        >
          {t('common.cancel')}
        </Button>
        <LoadingButton
          type="submit"
          size="regular"
          loading={isSubmitting}
          disabled={isSubmitting || !isDirty}
          variant="contained"
          sx={{ ml: 5 }}
        >
          {createMode ? t('common.create') : t('common.update')}
        </LoadingButton>
      </Box>
    </form>
  );
};

export default CourseTextDetailsForm;
