import {
  Typography,
  Box,
  Alert,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import { acceptInvitation } from 'services';
import { useState } from 'react';
import { FormPasswordField, FormTextField } from 'components/form';
import { useSnackbar, useFastForm, useLocale } from 'util/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { PATHS } from 'routes';
import { REGEX } from 'util/helpers';
import { AUTHENTICATION_SNACKBAR_POSITION } from 'assets/constants/snackbar';

const AcceptInvitationForm = (props) => {
  const { userRole } = props;
  const navigate = useNavigate();
  const { token } = useParams();
  const snack = useSnackbar();
  const { t } = useLocale();

  const [invitationError, setInvitationError] = useState('');

  const onSubmit = async (values) => {
    const payload = {
      ...values,
      firstNameEn: values.firstName,
      lastNameEn: values.lastName,
      token,
    };
    try {
      await acceptInvitation(payload);
      snack({
        message: t('registration.successfulSignup'),
        severity: 'success',
        snackbarProps: AUTHENTICATION_SNACKBAR_POSITION,
      });
      navigate(`/${PATHS.login}`, { replace: true });
    } catch (error) {
      const { errors } = error;
      errors?.forEach((err) => {
        setError(err.property, {
          type: 'api',
          message: err.message,
        });
        if (err.property === 'token') {
          setInvitationError(errors?.[0]?.message || t('common.somethingWrong'));
        }
      });
    }
  };

  const defaultValues = {
    username: '',
    firstName: '',
    lastName: '',
    password: '',
  };

  const validationSchema = Yup.object({
    username: Yup.string()
      .matches(REGEX.alphaNumericUnderscore, t('username.validation'))
      .min(6, t('username.minLength'))
      .required(t('registration.validation.enterUsername')),
    firstName: Yup.string()
      .matches(REGEX.alphabetEnWithSpace, t('registration.validation.enterEnglishLetters'))
      .required(t('registration.validation.enterFirstName'))
      .min(3, t('registration.validation.minFirstName')),
    lastName: Yup.string()
      .matches(REGEX.alphabetEnWithSpace, t('registration.validation.enterEnglishLetters'))
      .required(t('registration.validation.enterLastName')).min(3, t('registration.validation.minLastName'))
      .min(3, t('registration.validation.minLastName')),
    password: Yup.string()
      .matches(REGEX.password, t('password.weakPassword'))
      .required(t('password.enterPassword')),
  });

  const {
    control,
    handleSubmit,
    setError,
    formState: {
      isSubmitting,
      isDirty,
      isValid,
    },
  } = useFastForm({
    defaultValues,
    validationSchema,
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{
        py: 6,
        px: {
          sm: 20,
          xs: 6,
        },
      }}
      >
        <Box
          sx={{
            mb: 21,
            display: 'flex',
            flexFlow: 'column wrap',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="h4"
            sx={{
              mb: 4,
            }}
          >
            {t('invitation.acceptInvitation')}
          </Typography>
        </Box>
        {invitationError
          && (
            <Alert
              severity="error"
              sx={{ width: 1, mb: 8 }}
            >
              {invitationError}
            </Alert>
          )}
        <Typography
          sx={{
            display: 'flex',
            justifyContent: 'start',
            mb: 7,
          }}
          variant="bodyStandardRegular"
        >
          {`${t('invitation.acceptAs')}
              ${t(`roles.${userRole}`)}`}
          {` ${t('invitation.completeRegistration')}`}
        </Typography>
        <Box sx={{
          display: 'grid',
          rowGap: 7,
        }}
        >
          <Box>
            <FormTextField
              name="username"
              control={control}
              label={t('common.username')}
              disabled={isSubmitting}
              fullWidth
            />
          </Box>
          <Box>
            <FormTextField
              name="firstName"
              control={control}
              label={t('registration.firstName')}
              disabled={isSubmitting}
              fullWidth
            />
          </Box>
          <Box>
            <FormTextField
              name="lastName"
              control={control}
              label={t('registration.lastName')}
              disabled={isSubmitting}
              fullWidth
            />
          </Box>
          <Box>
            <FormPasswordField
              name="password"
              control={control}
              autoComplete="current-password"
              label={t('common.password')}
              disabled={isSubmitting}
              fullWidth
            />
          </Box>
        </Box>
        <LoadingButton
          sx={{
            my: 6,
            display: 'flex',
            alignSelf: 'flex-start',
          }}
          type="submit"
          variant="contained"
          size="fixed"
          loading={isSubmitting}
          disabled={isSubmitting || !isDirty || !isValid}
        >
          {t('common.register')}
        </LoadingButton>
      </Box>
    </form>
  );
};

export default AcceptInvitationForm;
