import {
  Box, Link, Typography, useMediaQuery,
} from '@mui/material';
import { MailIcon, XPlatformIcon } from 'assets/icons';
import {
  stcLogo,
  safcspColoredLogo,
  ministryOfEducationLogo,
} from 'assets/images';
import { useDate, useLocale } from 'util/hooks';

const SocialMediaIcon = ({ url, children }) => (
  <Link
    to="#"
    onClick={(e) => {
      e.preventDefault();
      window.open(url, '_blank');
    }}
    sx={{
      p: 1,
      '& path': {
        transition: 'all .2s ease-in-out',
      },
      '&:hover path': {
        fill: ({ palette }) => palette.common.grassGreen,
        fillOpacity: 1,
      },
    }}
  >
    {children}
  </Link>
);

const Footer = () => {
  const { t } = useLocale();
  const { now } = useDate();

  const showOnMobile = useMediaQuery(({ breakpoints }) => breakpoints.down('sm'));
  const flexSpaceBetween = { display: 'flex', justifyContent: 'space-between' };
  const { REACT_APP_SUPPORT_EMAIL } = process.env;

  const socialMediaIconSection = (
    <Box sx={{ width: { xs: 70, sm: 85 }, mt: { xs: 8, sm: 0 }, ...flexSpaceBetween }}>
      <SocialMediaIcon url="https://twitter.com/cyberhubsa">
        <XPlatformIcon width={showOnMobile ? 16 : 23} />
      </SocialMediaIcon>

      <SocialMediaIcon url={`mailto:${REACT_APP_SUPPORT_EMAIL}`}>
        <MailIcon width={showOnMobile ? 16 : 23} />
      </SocialMediaIcon>
    </Box>
  );

  const footerUpperSection = (
    <Box sx={{
      display: 'flex',
      flexDirection: {
        xs: 'column',
        sm: 'row',
      },
      justifyContent: {
        xs: 'center',
        sm: 'space-between',
      },
      alignItems: { xs: 'center' },
    }}
    >
      <Box sx={{
        display: 'flex',
        alignItems: 'baseline',
        gap: 8,
        ...flexSpaceBetween,
      }}
      >
        <Box>
          <img
            src={safcspColoredLogo}
            alt="cyberhub-logo"
            width={showOnMobile ? 95 : 150}
          />
        </Box>
        <Box sx={{ mx: { xs: 3, sm: 6 } }}>
          <img
            src={ministryOfEducationLogo}
            alt="ministry-of-education-logo"
            width={showOnMobile ? 70 : 90}
          />
        </Box>
        <Box>
          <img
            src={stcLogo}
            alt="stc-logo"
            width={showOnMobile ? 75 : 120}
          />
        </Box>
      </Box>

      {socialMediaIconSection}
    </Box>
  );

  const footerLowerSection = (
    <Box sx={{
      mt: { xs: 8, sm: 15 },
      mx: 'auto',
      maxWidth: { sm: 307 },
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
    >
      <Box sx={{ minWidth: 105 }}>
        <Typography color="gadgetGray" variant="bodySmallRegular">
          {t('landing.footer.poweredBy')}
          ©
          {now.getFullYear()}
          &nbsp;
          SAFCSP.
          &nbsp;
          {t('landing.footer.rightsReserved')}
        </Typography>
      </Box>
    </Box>
  );

  return (
    <Box sx={{
      pt: 10,
      pb: { xs: 8, sm: 5 },
      mx: 'auto',
    }}
    >
      {footerUpperSection}
      {footerLowerSection}
    </Box>
  );
};

export default Footer;
