import { useTheme } from '@mui/styles';

const GithubIcon = (props) => {
  const { palette } = useTheme();

  const {
    fill = palette.common.charlestonGreen,
    ...rest
  } = props;

  return (
    <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g clipPath="url(#clip0_544_29379)">
        <path fillRule="evenodd" clipRule="evenodd" d="M7.12454 0C3.18485 0 0 3.20833 0 7.17748C0 10.3502 2.04065 13.0359 4.87156 13.9864C5.2255 14.0579 5.35515 13.832 5.35515 13.642C5.35515 13.4756 5.34348 12.9052 5.34348 12.311C3.3616 12.7388 2.9489 11.4554 2.9489 11.4554C2.6304 10.6235 2.15848 10.4097 2.15848 10.4097C1.50981 9.97004 2.20573 9.97004 2.20573 9.97004C2.92527 10.0176 3.30283 10.7068 3.30283 10.7068C3.93969 11.8 4.96592 11.4911 5.37877 11.3009C5.43769 10.8375 5.62654 10.5166 5.82706 10.3384C4.24638 10.172 2.58329 9.55413 2.58329 6.79715C2.58329 6.01285 2.86621 5.37119 3.3145 4.87215C3.24377 4.69394 2.996 3.95704 3.38538 2.97077C3.38538 2.97077 3.98694 2.7806 5.34333 3.70752C5.92405 3.55041 6.52294 3.47049 7.12454 3.46981C7.7261 3.46981 8.33933 3.55308 8.9056 3.70752C10.2621 2.7806 10.8637 2.97077 10.8637 2.97077C11.2531 3.95704 11.0052 4.69394 10.9344 4.87215C11.3945 5.37119 11.6658 6.01285 11.6658 6.79715C11.6658 9.55413 10.0027 10.1601 8.41021 10.3384C8.66979 10.5642 8.89379 10.9919 8.89379 11.6693C8.89379 12.6318 8.88212 13.4043 8.88212 13.6418C8.88212 13.832 9.01192 14.0579 9.36571 13.9866C12.1966 13.0358 14.2373 10.3502 14.2373 7.17748C14.2489 3.20833 11.0524 0 7.12454 0Z" fill={fill} />
      </g>
    </svg>
  );
};

export default GithubIcon;
