import { memo } from 'react';
import PropTypes from 'prop-types';
import { useController } from 'react-hook-form';
import { TimezoneSelect } from 'components/molecules';

const FormTimezoneSelect = (props) => {
  const {
    autoCompleteProps,
    textFieldProps,
    ...rest
  } = props;

  const { field, fieldState } = useController(rest);
  const { onChange } = field;
  const { error } = fieldState;

  const hasErrored = Boolean(error);

  const onFieldChange = (event, newValue) => {
    onChange(newValue || '');
  };

  return (
    <TimezoneSelect
      autoCompleteProps={{
        ...field,
        onChange: onFieldChange,
        ...autoCompleteProps,
      }}
      textFieldProps={{
        error: hasErrored,
        helperText: error?.message,
        ...textFieldProps,
      }}
    />
  );
};

FormTimezoneSelect.propTypes = {
  autoCompleteProps: PropTypes.oneOfType([PropTypes.object]),
  textFieldProps: PropTypes.oneOfType([PropTypes.object]),
};

FormTimezoneSelect.defaultProps = {
  autoCompleteProps: {},
  textFieldProps: {},
};

export default memo(FormTimezoneSelect);
