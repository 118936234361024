import { MuiOtpInput } from 'mui-one-time-password-input';
import { forwardRef } from 'react';

const OTPInput = forwardRef((props, ref) => (
  <MuiOtpInput
    {...props}
    ref={ref}
  />
));

export default OTPInput;
