import 'locales/i18n';

import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';

import {
  CssBaseline,
  responsiveFontSizes,
  ThemeProvider,
} from '@mui/material';

import { theme, rtlTheme } from 'style/theme/theme';
import { useLocale } from 'util/hooks';

const MUIProvider = (props) => {
  const { children } = props;

  // Localization
  const { isRtl } = useLocale();
  const localizedTheme = isRtl
    ? rtlTheme
    : theme;

  const responsiveTheme = responsiveFontSizes(localizedTheme);

  // Create rtl cache
  // https://mui.com/guides/right-to-left/#3-install-the-rtl-plugin
  const cacheLtr = createCache({
    key: 'muiltr',
    prepend: true,
  });
  const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [rtlPlugin],
    prepend: true,
  });

  return (
    <CacheProvider value={isRtl ? cacheRtl : cacheLtr}>
      <ThemeProvider theme={responsiveTheme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </CacheProvider>
  );
};

export default MUIProvider;
