import { getTopClubs } from 'services';
import { useCustomQuery } from 'reactQuery';

const useTopClubs = (queryProps = {}) => {
  const query = useCustomQuery({
    queryKey: ['topClubs'],
    queryFn: getTopClubs,
    ...queryProps,
  });

  return { ...query };
};

export default useTopClubs;
