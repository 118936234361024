import { useCustomInfiniteQuery } from 'reactQuery';
import { getUserCertificates } from 'services';

const useInfiniteUserCertificates = (queryProps = {}) => {
  const { queryKeyOptions = {}, ...rest } = queryProps;

  const query = useCustomInfiniteQuery({
    queryKey: ['infinite-user-certificates', queryKeyOptions],
    queryFn: getUserCertificates,
    ...rest,
  });

  return { ...query };
};

export default useInfiniteUserCertificates;
