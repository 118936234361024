import React from 'react';
import { InputAdornment, TextField } from '@mui/material';
import { useController } from 'react-hook-form';
import { useLocale } from 'util/hooks';
import ClubDomainsList from './ClubDomainsList';

const FormClubDomainField = React.forwardRef((props, ref) => {
  const {
    name,
    control,
    clubDomains = [],
    ...rest
  } = props;

  const { t } = useLocale();

  const {
    field,
    fieldState,
  } = useController({ control, name });

  const {
    value,
    onChange,
  } = field;

  const { email = '' } = value || {};

  const { error } = fieldState;

  const handleChangeEmail = (e) => {
    onChange({ ...value, email: e.target.value });
  };

  const handleChangeDomain = (domain) => {
    onChange({ ...value, domain });
  };

  const hasErrored = Boolean(error);
  const helperText = hasErrored ? error?.message : rest.helperText;

  return (
    <TextField
      {...rest}
      inputRef={ref}
      value={email}
      onChange={handleChangeEmail}
      placeholder={t('common.email')}
      helperText={helperText}
      error={hasErrored}
      InputLabelProps={{ shrink: true }}
      InputProps={{
        endAdornment: (
          <InputAdornment dir="ltr" position="end">
            <ClubDomainsList
              name={name}
              control={control}
              clubDomains={clubDomains}
              onChange={handleChangeDomain}
            />
          </InputAdornment>
        ),
      }}
    />
  );
});

export default FormClubDomainField;
