import { useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { certificatesLogo } from 'assets/images';
import { useAuth, useLocale } from 'util/hooks';
import { PATHS } from 'routes';

const EmptyCertificatesPlaceholder = () => {
  const { t } = useLocale();
  const { username } = useParams();
  const { currentUser = {} } = useAuth();

  const isOwnProfile = username === currentUser.username;

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }}
    >
      <Box sx={{
        width: 90,
        height: 100,
        mt: 7.5,
        mb: 6,
      }}
      >
        <img
          src={certificatesLogo}
          alt="certificates-logo"
          width="90"
          height="100"
        />
      </Box>
      <Box>
        <Typography variant="h6">
          {isOwnProfile
            ? t('certificates.youHaveNoCertificates')
            : t('certificates.noCertificates')}
        </Typography>
      </Box>
      {isOwnProfile && (
        <Box>
          <Typography variant="bodyStandardRegular">
            {t('certificates.showingCertificatesProof')}
          </Typography>
        </Box>
      )}
      {isOwnProfile && (
        <Box sx={{ mt: 4 }}>
          <Button
            component={Link}
            to={PATHS.addProfileCertificate}
            variant="outlined"
            sx={{ borderRadius: 5 }}
          >
            {t('certificates.addCertificate')}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default EmptyCertificatesPlaceholder;
