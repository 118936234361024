const CheckCircleOutline = (props) => {
  const {
    fill = 'none',
  } = props;

  return (
    <svg width="100%" height="100%" viewBox="0 0 32 32" fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M8 17.6307L11.2667 22.2667C11.3906 22.4518 11.5569 22.6046 11.7518 22.7124C11.9467 22.8202 12.1645 22.88 12.3872 22.8866C12.6098 22.8933 12.8308 22.8467 13.0318 22.7507C13.2328 22.6547 13.4079 22.512 13.5427 22.3347L24 9.104" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M16 30.9987C24.2843 30.9987 31 24.2829 31 15.9987C31 7.71439 24.2843 0.998657 16 0.998657C7.71573 0.998657 1 7.71439 1 15.9987C1 24.2829 7.71573 30.9987 16 30.9987Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default CheckCircleOutline;
