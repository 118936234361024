import { useParams } from 'react-router-dom';
import useCustomQuery from 'reactQuery/useCustomQuery';
import { getPublicCourse } from 'services';

const usePublicCourse = (queryProps = {}) => {
  const { courseId } = useParams();

  const query = useCustomQuery({
    queryKey: [`public-course-details-${courseId}`],
    queryFn: () => getPublicCourse(courseId),
    ...queryProps,
  });

  return { ...query };
};

export default usePublicCourse;
