import {
  Box,
  Typography,
} from '@mui/material';
import { ClubAdminsList, ClubMembersList } from 'components/organisms';
import { useLocale } from 'util/hooks';
import { useClubMembers } from 'reactQuery/queries';

const ClubProfileMembersContainer = (props) => {
  const { t } = useLocale();

  const {
    data = {},
  } = useClubMembers();

  const {
    items: members = [],
    meta = {},
  } = data || {};

  return (
    <Box
      sx={{
        backgroundColor: 'common.white',
        px: 7,
        py: 3,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h6">
          {t('common.members')}
        </Typography>
        <Typography variant="bodySmallRegular">
          {t('profile.studentCount.students', { count: meta.totalItems || 0 })}
        </Typography>
      </Box>
      <Box>
        <ClubAdminsList />
      </Box>
      <Box>
        <ClubMembersList
          members={members}
          membersCount={meta.totalItems}
          {...props}
        />
      </Box>
    </Box>
  );
};

export default ClubProfileMembersContainer;
