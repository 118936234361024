import { useCallback, useState } from 'react';
import {
  Stack,
  Button,
} from '@mui/material';
import { useTheme } from '@mui/styles';

import { DataGrid } from 'components/core';
import { useCourseRegistrants } from 'reactQuery/queries';
import { useLocale, useModal } from 'util/hooks';
import MODAL_KEYS from 'assets/constants/modalKeys';
import useTableColumns from './useTableColumns';

const CourseRegistrantsTable = (props) => {
  const {
    rowSelectionModel,
    setRowSelectionModel,
  } = props;

  const { t } = useLocale();
  const { palette } = useTheme();
  const { addModal } = useModal();

  const columns = useTableColumns({ });
  const noRowSelected = !rowSelectionModel.length;

  const [queryOptions, setQueryOptions] = useState({});

  const handleSortModelChange = useCallback((sortModel) => {
    // save the data from the sort model
    setQueryOptions(sortModel[0]);
  }, []);

  const {
    data = { items: [], meta: {} },
    isFetching = true,
    refetch: refetchCourseRegistrants = () => { },
  } = useCourseRegistrants(
    {
      queryKeyOptions: {
        pagination: { limit: 25 },
        searchOptions: {
          order: queryOptions.sort || 'desc',
          orderBy: queryOptions.field || 'user.totalPoints',
        },
      },
    },
  );

  const {
    items: rows = [],
    meta = {},
  } = data || {};

  const openModal = (type) => {
    addModal({
      key: MODAL_KEYS.courseRegistrantAction,
      props: {
        type,
        selectedStudents: rowSelectionModel,
        refetch: refetchCourseRegistrants,
      },
    });
  };

  const tableActions = (
    <Stack flexDirection="row" gap={3}>
      <Button
        variant="outlined"
        onClick={() => openModal('reject')}
        size="regular"
        sx={{
          color: palette.common.pinkishRed,
          borderColor: palette.common.pinkishRed,
          ':hover': {
            borderColor: palette.common.pinkishRed,
          },
        }}
      >
        {t('courses.rejectAll')}
      </Button>
      <Button
        variant="outlined"
        size="regular"
        disabled={noRowSelected}
        onClick={() => openModal('accept')}
      >
        {t('common.accept')}
      </Button>
    </Stack>
  );

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      loading={isFetching}
      pagination={meta}
      withCustomToolbar
      checkboxSelection
      sortingMode="server"
      onSortModelChange={handleSortModelChange}
      rowSelectionModel={rowSelectionModel}
      onSelectionModelChange={(newSelectionModel) => {
        // Convert ids to row object
        const selectedIDs = new Set(newSelectionModel);
        const selectedRowData = rows.filter((row) => selectedIDs.has(row.id));
        setRowSelectionModel(selectedRowData);
      }}
      componentsProps={{
        noRowsOverlay: { type: 'courseAttendance', showButton: false },
        toolbar: {
          component: tableActions,
          withTableHeader: true,
          tableHeaderProps: {
            title: t('courses.registeredStudents'),
            count: t('profile.studentCount.students', { count: meta.totalItems || 0 }),
          },
        },
      }}
    />
  );
};

export default CourseRegistrantsTable;
