import { Button, Typography, Box } from '@mui/material';
import { PATHS } from 'routes';
import { useNavigate } from 'react-router-dom';
import { useLocale } from 'util/hooks';

const AcceptInvitationError = (props) => {
  const { errorMessage } = props;
  const navigate = useNavigate();
  const { t } = useLocale();

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      py: 6,
      px: {
        sm: 20,
        xs: 6,
      },
    }}
    >
      <Typography
        sx={{
          marginBottom: 21,
        }}
        variant="h4"
      >
        {t('invitation.acceptInvitation')}
      </Typography>
      <Typography
        sx={{
          marginBottom: 6,
          alignSelf: 'flex-start',
        }}
        variant="bodyMediumRegular"
      >
        {t('common.somethingWrong')}
      </Typography>
      <Typography
        sx={{
          marginBottom: 6,
          alignSelf: 'flex-start',
        }}
        variant="bodyStandardBold"
      >
        {errorMessage}
      </Typography>
      <Button
        sx={{
          alignSelf: 'start',
        }}
        variant="contained"
        size="regular"
        onClick={() => navigate(`/${PATHS.login}`)}
      >
        {t('common.backToLogin')}
      </Button>
    </Box>
  );
};

export default AcceptInvitationError;
