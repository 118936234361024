import { Box, Typography } from '@mui/material';
import { KsaInteractiveMap } from 'components/organisms';
import { useEffect, useState } from 'react';
import { useLocale } from 'util/hooks';
import { regions } from 'assets/data';
import LandingMapInfoCard from './LandingMapInfoCard';

const Clubs = () => {
  const { t } = useLocale();
  const [selectedRegion, setSelectedRegion] = useState(regions.center);
  const [isAnimationPaused, setIsAnimationPaused] = useState(false);

  const secondsToCycle = 3;

  useEffect(
    () => {
      const interval = setInterval(() => {
        if (!isAnimationPaused) {
          setSelectedRegion((prev) => regions[prev.next] || regions.center);
        }
      }, secondsToCycle * 1000);

      return () => clearInterval(interval);
    },
    [isAnimationPaused],
  );

  const updateSelectedRegion = (newRegion) => setSelectedRegion(newRegion);
  const pauseAnimation = () => setIsAnimationPaused(true);
  const resumeAnimation = () => setIsAnimationPaused(false);

  return (
    <>
      <Box>
        <Typography variant="h5">
          {t('landing.clubsSection.header')}
        </Typography>

        <Box sx={{ mt: 2 }}>
          <Typography variant="bodyStandardRegular">
            {t('landing.clubsSection.subHeader')}
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          mt: 8,
          px: { xs: 4, md: 0 },
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
        onMouseEnter={pauseAnimation}
        onMouseLeave={resumeAnimation}
      >
        <KsaInteractiveMap
          regions={regions}
          selectedRegion={selectedRegion?.name}
          sx={{ flex: 1, mr: { md: 10 } }}
          updateSelectedRegion={updateSelectedRegion}
        />

        <LandingMapInfoCard
          sx={{
            mt: { xs: 6, md: 0 },
            boxShadow: ({ palette }) => (`0px 0px 35px ${palette.common.boxShadow4}`),
          }}
          regions={regions}
          region={selectedRegion}
          title={t('landing.clubsSection.universities', { count: selectedRegion.count })}
        />
      </Box>
    </>
  );
};

export default Clubs;
