import { useTheme } from '@mui/styles';

const NoCoursesIcon = (props) => {
  const { palette } = useTheme();

  const {
    fill = 'none',
    circleFill = palette.common.vividOrange,
    stroke = palette.common.berylliumGrey,
    rectFill = palette.common.white,
  } = props;

  return (
    <svg width="148" height="154" viewBox="0 0 148 154" fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="92" cy="56" r="56" fill={circleFill} fillOpacity="0.2" />
      <g clipPath="url(#clip0_5225_155730)">
        <path d="M72.0417 147.939C85.9663 136.15 103.016 128.661 121.119 126.382C123.252 126.101 125.21 125.054 126.628 123.436C128.047 121.818 128.828 119.739 128.827 117.588V34.108C128.829 32.8291 128.556 31.5647 128.028 30.4002C127.499 29.2357 126.727 28.1981 125.763 27.3577C124.799 26.5172 123.666 25.8933 122.44 25.5282C121.215 25.1631 119.925 25.0653 118.658 25.2413C101.461 27.9045 85.3309 35.2581 72.0417 46.4936C70.4544 47.7195 68.5055 48.3844 66.5 48.3844C64.4945 48.3844 62.5456 47.7195 60.9583 46.4936C47.6632 35.2594 31.5272 27.9095 14.3252 25.2524C13.0594 25.0765 11.7706 25.1741 10.5458 25.5385C9.32088 25.903 8.18839 26.5259 7.22466 27.3651C6.26092 28.2044 5.48835 29.2405 4.95904 30.4037C4.42972 31.5669 4.15597 32.83 4.15625 34.108V117.588C4.1553 119.739 4.93677 121.818 6.35502 123.436C7.77326 125.054 9.73149 126.101 11.8647 126.382C29.9728 128.658 47.0291 136.147 60.9583 147.939C62.5444 149.168 64.4938 149.835 66.5 149.835C68.5062 149.835 70.4556 149.168 72.0417 147.939V147.939Z" stroke={stroke} strokeWidth="9.975" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M66.5 48.3662V149.84" stroke={stroke} strokeWidth="9.975" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_5225_155730">
          <rect width="133" height="133" fill={rectFill} transform="translate(0 21)" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default NoCoursesIcon;
